import { GET_PRODUCTS } from "../../products/products";
import { useQuery } from "@apollo/client";
import { Spinner } from "flowbite-react";
import ProductionStatuses from "./productionStatuses";

export default function ProductionStatusWrapper() {
  const { data, error, loading } = useQuery(GET_PRODUCTS);
  if (error) {
    console.log(error);
  }

  const products = data?.products;

  return (
    <div>
      {loading && !products && (
        <div className="flex flex-row justify-center mt-4 mb-6">
          <Spinner color="purple" size="xl" />
        </div>
      )}
      {products && (
        <>
          <ProductionStatuses />
          {products.map(product => (
            <ProductionStatuses key={product.id} product={product} />
          ))}
        </>
      )}
    </div>
  );
}
