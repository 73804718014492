import { sort } from "fast-sort";
import { ProductionType } from "../../shared/productions/types";
import ListItem from "./listItem";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import { useScheme } from "../../../../store/schemeStore";
import { useNavStore } from "../../../../store/navStore";
import { useIsMounted } from "usehooks-ts";

interface props {
  production: ProductionType[];
}

export default function ProductionLists({ production }: props) {
  const sortedProduction = sort(production).asc([
    p => p.team.name,
    p => p.productionStatus.priority,
    p => p.due,
    p => p.created_at,
  ]);

  const length = sortedProduction.length;

  const { scheme } = useScheme();

  const renderRow: ListRowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style} className="row pb-[10px]">
        <ListItem production={sortedProduction[index]} />
      </div>
    );
  };

  const { containerRef } = useNavStore();
  const mounted = useIsMounted();

  return (
    <section className="px-6 h-[calc(100vh-62px)] mt-1">
      <AutoSizer className={scheme}>
        {({ width, height }) => (
          <List
            onScroll={() => {
              if (containerRef && mounted()) {
                containerRef.scrollTo({
                  behavior: "smooth",
                  top: containerRef.scrollHeight,
                });
              }
            }}
            height={height}
            width={width}
            rowCount={length}
            rowHeight={133.77 + 10}
            rowRenderer={renderRow}
            overscanRowCount={3}
          />
        )}
      </AutoSizer>
    </section>
  );
}
