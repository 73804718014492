import { Badge, Table } from "flowbite-react";
import { RecordType } from "../shared/record/types";
import { useNavigate } from "react-router-dom";
import getContrast from "../../../utils/getContrast";
import dayjs from "dayjs";
import TotalH from "../shared/record/totalH";
import RevGeocoded from "./revGeocoded";

interface props {
  record: RecordType;
}

export default function TableItem({ record }: props) {
  const navigate = useNavigate();
  const goToDetail = () => {
    navigate("/records/detail/" + record.id);
  };

  return (
    <Table.Row
      onClick={goToDetail}
      className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
    >
      <Table.Cell className="capitalize">
        {record.user.firstName} {record.user.sirName}
      </Table.Cell>
      <Table.Cell>{dayjs(record.start).format("DD/MM ddd")}</Table.Cell>
      <Table.Cell className="hidden @md:table-cell supports-[not(container-type:inline-size)]:md:table-cell">
        {dayjs(record.start).format("HH:mm")}
      </Table.Cell>
      <Table.Cell className="hidden @md:table-cell supports-[not(container-type:inline-size)]:md:table-cell">
        {record.end ? `${dayjs(record.end).format("HH:mm")}` : "pending"}
      </Table.Cell>
      <Table.Cell className="hidden @lg:table-cell supports-[not(container-type:inline-size)]:lg:table-cell">
        {Math.round(((record.break || 0) / 60) * 10) / 10}
      </Table.Cell>
      <Table.Cell className="hidden @sm:table-cell supports-[not(container-type:inline-size)]:sm:table-cell">
        <TotalH
          startTime={record.start}
          endTime={record.end}
          brk={record.break}
        />
      </Table.Cell>
      <Table.Cell className="hidden @lg:table-cell supports-[not(container-type:inline-size)]:lg:table-cell">
        {record.endLoc ? <RevGeocoded coord={record.endLoc} /> : "unknown"}
      </Table.Cell>
      <Table.Cell className="hidden @xl:table-cell supports-[not(container-type:inline-size)]:xl:table-cell">
        {record.logs && record.logs.length > 0 && record.logs.length}
      </Table.Cell>
      <Table.Cell className="hidden @xl:table-cell supports-[not(container-type:inline-size)]:xl:table-cell">
        {record?.desc}
      </Table.Cell>
      <Table.Cell className="hidden @sm:table-cell supports-[not(container-type:inline-size)]:sm:table-cell">
        <Badge
          style={{
            color: record ? getContrast(record.type.color) : "white",
            backgroundColor: record?.type.color || "black",
          }}
          className="w-min hover:brightness-125 cursor-pointer"
        >
          {record.type.name}
        </Badge>
      </Table.Cell>
      <Table.Cell className="hidden @xs:table-cell supports-[not(container-type:inline-size)]:xs:table-cell">
        <Badge
          style={{
            color: record ? getContrast(record.status.color) : "white",
            backgroundColor: record?.status.color || "black",
          }}
          className="w-min hover:brightness-125 cursor-pointer"
        >
          {record.status.name}
        </Badge>
      </Table.Cell>
    </Table.Row>
  );
}
