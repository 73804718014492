import { sort } from "fast-sort";
import { item, location } from "../../types";
import { column, optionColumn, workorderCalc } from "./types";
import { ProductSetOrderPreset } from "../../../types";
import { create, all } from "mathjs";
import useScopeConcat from "../ordering/options/scopeConcat";

interface itemRowProps {
  itemNum: number;
  item: item;
  columns: column[];
  locations?: location[];
  locationName: string;
  openingName: number | false;
  presetColumnName: string;
  allOptionColumns: optionColumn[];
  calcs?: workorderCalc[];
  selectedOrderPreset?: ProductSetOrderPreset;
}

export default function ItemRow({
  selectedOrderPreset,
  itemNum,
  item,
  columns,
  locations,
  locationName,
  openingName,
  presetColumnName,
  allOptionColumns,
  calcs,
}: itemRowProps) {
  const presets = item.options?.filter(
    o => o.source == "deductionPreset" && !o.noCalc
  );

  const math = create(all);

  math.SymbolNode.onUndefinedSymbol = () => {
    return 0;
  };

  const { evaluate } = math;

  const scopeConcat = useScopeConcat();

  if (selectedOrderPreset) {
    const { condition } = selectedOrderPreset;
    if (condition && condition !== "") {
      // console.log({ condition, item });
      const scopes = item.options
        ?.filter(fo => !fo.noCalc)
        .reduce<any>(
          (prv, cur) => {
            const scopeName = cur.name.replaceAll(" ", "");
            let appended = {
              ...prv,
            };
            appended = scopeConcat({
              prvScopes: appended,
              scopeName,
              option: cur,
            });

            return appended;
          },
          { W: item.width, H: item.height }
        );

      let check = true;

      try {
        const _check = evaluate(condition, scopes);
        if (_check === false) {
          check = false;
        }
      } catch (error) {
        console.log(error);
        console.log(condition, scopes);
        check = true;
      }

      if (!check) {
        return null;
      }
    }
  }

  return (
    <>
      {presets.map((p, i) => {
        return (
          <tr key={i} className="border-b-[1px] border-black">
            {i == 0 && (
              <>
                <td
                  rowSpan={presets.length}
                  className="py-[4px] px-1 border-r-[1px] border-black"
                >
                  {itemNum}
                </td>
                <td
                  rowSpan={presets.length}
                  className="py-[4px] px-1 border-r-[1px] border-black"
                >{`${
                  locations && locations.length > 1 ? locationName + "." : ""
                }${openingName}.${item.index !== undefined && item.index}`}</td>
              </>
            )}

            {columns.find(c => c.name == "OW") && (
              <td className="py-[4px] px-1 border-r-[1px] border-black">
                {item.width}
              </td>
            )}
            {columns.find(c => c.name == "OH") && (
              <td className="py-[4px] px-1 border-r-[1px] border-black">
                {item.height}
              </td>
            )}

            {presetColumnName !== p.name && (
              <td className="py-[4px] px-1 border-r-[1px] border-black">
                {p.displayName || p.name}
              </td>
            )}

            <td
              // rowSpan={presetColumnName == p.name ? 2 : 1}
              colSpan={presetColumnName == p.name ? 2 : 1}
              className="py-[4px] px-1 border-r-[1px] border-black"
            >
              {p.displayValue || p.value}
            </td>

            {allOptionColumns
              ?.filter(
                oc =>
                  columns.find(
                    col =>
                      col.name == (oc.displayName || oc.name) ||
                      (col.isGroup && col.name == oc.group)
                  ) && oc.name.toLowerCase() !== "note"
              )
              .map(oc => {
                const option = item.options.find(o => {
                  return (
                    (o.group || o.displayName || o.name) ==
                      (oc.group || oc.displayName || oc.name) &&
                    !o.noCalc &&
                    (!oc.group || o.name.includes(p.name))
                  );
                });

                return (
                  <td
                    key={oc.id}
                    className="py-[4px] px-1 border-r-[1px] border-black"
                  >
                    {option?.displayValue || option?.value || ""}
                  </td>
                );
              })}

            {calcs &&
              sort(calcs)
                .asc("priority")
                ?.filter(c =>
                  columns.find(col => col.name == (c.displayName || c.name))
                )
                .map(column => {
                  const calc = item.calcs.find(
                    c =>
                      (c.displayName || c.name) ==
                        (column.displayName || column.name) &&
                      c.optionId == p.id
                  );
                  // col.name == (c.displayName || c.name) && c.optionId == p.id
                  return (
                    <td
                      className="py-[4px] px-1 border-r-[1px] border-black"
                      // key={calc.optionId + calc.name}
                      key={column.name}
                    >
                      {calc?.size || ""}
                    </td>
                  );
                })}
            {columns.find(col => col.name == "note") && (
              <td className="py-[4px] px-1 border-r-[1px] border-black">
                {item.note || ""}
              </td>
            )}
          </tr>
        );
      })}
      {(!presets || presets.length < 1) && (
        <tr className="border-b-[1px] border-black">
          <td
            rowSpan={presets.length}
            className="py-[4px] px-1 border-r-[1px] border-black"
          >{`${
            locations && locations.length > 1 ? locationName + "." : ""
          }${openingName}.${item.index !== undefined && item.index}`}</td>

          {columns.find(c => c.name == "OW") && (
            <td className="py-[4px] px-1 border-r-[1px] border-black">
              {item.width}
            </td>
          )}
          {columns.find(c => c.name == "OH") && (
            <td className="py-[4px] px-1 border-r-[1px] border-black">
              {item.height}
            </td>
          )}
          <td
            colSpan={2}
            className="py-[4px] px-1 border-r-[1px] border-black"
          ></td>

          {allOptionColumns
            ?.filter(
              oc =>
                columns.find(
                  col =>
                    col.name == (oc.displayName || oc.name) ||
                    (col.isGroup && col.name == oc.group)
                ) && oc.name.toLowerCase() !== "note"
            )
            .map(oc => {
              const option = item.options.find(
                o =>
                  (o.group || o.displayName || o.name) ==
                    (oc.group || oc.displayName || oc.name) && !o.noCalc
              );

              return (
                <td
                  key={oc.id}
                  className="py-[4px] px-1 border-r-[1px] border-black"
                >
                  {option?.displayValue || option?.value || ""}
                </td>
              );
            })}

          {calcs &&
            sort(calcs)
              .asc("priority")
              ?.filter(c =>
                columns.find(col => col.name == (c.displayName || c.name))
              )
              .map(column => {
                const calc = item.calcs.find(
                  c =>
                    (c.displayName || c.name) ==
                    (column.displayName || column.name)
                );
                // col.name == (c.displayName || c.name) && c.optionId == p.id
                return (
                  <td
                    className="py-[4px] px-1 border-r-[1px] border-black"
                    // key={calc.optionId + calc.name}
                    key={column.name}
                  >
                    {calc?.size || ""}
                  </td>
                );
              })}
          {columns.find(col => col.name == "note") && (
            <td className="py-[4px] px-1 border-r-[1px] border-black">
              {item.note || ""}
            </td>
          )}
        </tr>
      )}
    </>
  );
}
