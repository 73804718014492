import { Outlet, useRoutes } from "react-router-dom";
import { useSearchStore } from "../../../store/searchStore";
import { useOrderSearch } from "./orderSearch";
import { useEffect } from "react";
import OrderList from "./orderList/orderList";
import EditOrder from "./createOrder/editOrder";
import { useOrderStorePopulate } from "../../../store/orderStore";
import OrderDetail from "./orderDetail/orderDetail";
import { usePopulateQuoteStore } from "../../../store/quoteStore";
import NoteConversion from "./noteConversion";

const OrderWrapper = () => {
  const { setSearchFunction, clearSearchFunction, setSearchResults } =
    useSearchStore();

  const searchOrder = useOrderSearch();

  useEffect(() => {
    setSearchFunction(str => {
      if (!str) {
        return;
      }
      searchOrder(str);
    });
    return () => {
      clearSearchFunction();
      setSearchResults([]);
    };
  }, []);

  const isLoading = useOrderStorePopulate();
  const isQuoteParamsLoading = usePopulateQuoteStore();

  if (isLoading || isQuoteParamsLoading) {
    return null;
  }

  return <Outlet />;
};

export default function Order() {
  const orderRoutes = useRoutes([
    {
      path: "/",
      element: <OrderWrapper />,
      children: [
        {
          path: "/",
          element: <OrderList />,
        },
        {
          path: "/detail/:id",
          element: <OrderDetail />,
        },
        {
          path: "/edit/:processId/:orderId",
          element: <EditOrder />,
        },
        // {
        //   path: "/edit/:processId/",
        //   element: <EditOrder />,
        // },
        {
          path: "/edit/:processId/assignment/:assignmentId",
          element: <EditOrder />,
        },
        {
          path: "/new/:type?/:processId?",
          element: <EditOrder />,
        },
        {
          path: "/noteConversion",
          element: <NoteConversion />,
        },
      ],
    },
  ]);
  return orderRoutes;
}
