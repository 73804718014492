import { Badge, Label, ListGroup } from "flowbite-react";
import getContrast from "../../../utils/getContrast";
import { useRef, useState } from "react";
import { RecordStatusType } from "../shared/record/types";
import { useQuery } from "@apollo/client";
import { GET_RECORD_STATUS } from "../shared/record/gqls";
import { sort } from "fast-sort";
import { useOnClickOutside } from "usehooks-ts";

interface props {
  canApprove: boolean;
  status: RecordStatusType;
  changeStat: (id: number) => void;
}

export default function RecordStatus({
  canApprove,
  status,
  changeStat,
}: props) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    canApprove && status?.id !== inStatus?.id && setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const [updating, setUpdating] = useState(false);

  const update = async (id: number) => {
    setUpdating(true);
    changeStat(id);
    toggleOpen();

    setUpdating(false);
  };

  const { data: data_status } = useQuery(GET_RECORD_STATUS);
  const statuses = data_status?.recordStatus
    ? sort(data_status?.recordStatus).asc(stat => stat.priority)
    : [];

  const inStatus = statuses.find(stat => stat.name == "in");

  return (
    <div ref={ref} className="flex flex-row justify-between items-center my-1">
      <Label value="Status" />
      <div className="relative">
        <Badge
          style={{
            color: getContrast(status.color),
            backgroundColor: status.color,
          }}
          size="sm"
          onClick={toggleOpen}
          className={`w-fit ${updating && "opacity-50"} ${
            canApprove && "hover:brightness-125 cursor-pointer"
          }`}
        >
          {status.name}
        </Badge>
        {open && (
          <ListGroup className="absolute right-0 z-10 mt-2">
            {statuses
              ?.filter(stat => stat.id !== inStatus?.id)
              .map(stat => (
                <ListGroup.Item
                  onClick={() => {
                    update(stat.id);
                  }}
                  key={stat.id}
                >
                  <Badge
                    style={{
                      backgroundColor: stat.color,
                      color: getContrast(stat.color),
                    }}
                    className={`${stat.id == status.id && "opacity-50"}`}
                  >
                    {stat.name}
                  </Badge>
                </ListGroup.Item>
              ))}
          </ListGroup>
        )}
      </div>
    </div>
  );
}
