export default function getDimensions(image: string) {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    var img = new Image();
    img.src = image;

    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
  });
}
