import { Button, Modal, Badge, Spinner } from "flowbite-react";
import {
  DevicePhoneMobileIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useScheme } from "../../../store/schemeStore";
import { DeviceType } from "./devices";
import { Map, Marker } from "pigeon-maps";
import { useAuthStore } from "../../../store/authStore";
import { useMutation, gql } from "@apollo/client";
import { addAlert } from "../../../store/alertStore";
import { useLogOut } from "../../../store/authStore";

const DELETE_DEVICE = gql`
  mutation DELETE_DEVICE($id: Int!) {
    delete_devices_by_pk(id: $id) {
      id
    }
  }
`;

const Device = ({ device }: { device: DeviceType }) => {
  const { user } = useAuthStore();
  const isCurrent = user?.sessionId == device.id;

  const isPc = device.device.includes("pc");

  let lat, lng;

  if (device.location) {
    [lat, lng] = device.location.split(", ");
    lat = Number(lat);
    lng = Number(lng);
  }

  const [show, setShow] = useState(false);

  const { scheme } = useScheme();

  const toggleModal = () => {
    setShow(!show);
  };

  const [delete_device, { loading, error }] = useMutation(DELETE_DEVICE);

  const logOut = useLogOut();

  if (error) {
    addAlert({
      message: "something went wrong could not log out session",
      type: "failure",
    });
    console.log(error);
  }

  const deleteSession = async () => {
    const message = isCurrent
      ? "you are trying to delete your current session, deleting it will log you out, do you want to proceed?"
      : "Do you really want to log out this device?";
    const proceed = confirm(message);

    if (!proceed) return;

    await delete_device({ variables: { id: device.id } });
    setShow(false);

    if (isCurrent) {
      logOut();
    }
  };

  return (
    <>
      <Button className="w-full relative" color="purple" onClick={toggleModal}>
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row gap-2 items-center ">
            {isCurrent && (
              <div className="absolute w-3 h-3 bg-green-400 rounded-full left-4" />
            )}
            <div className="w-6">
              {isPc ? <ComputerDesktopIcon /> : <DevicePhoneMobileIcon />}
            </div>
            <span className="capitalize">{device.device}</span>
          </div>
          <div className="hidden md:block ">{device.ip}</div>
        </div>
      </Button>
      {/* Detail Modal */}
      <Modal
        show={show}
        onClose={toggleModal}
        className={`${
          scheme === "dark" ? "dark" : ""
        } !h-screen md:h-[calc(100% - 2rem)]`}
      >
        <Modal.Header>Device Activity</Modal.Header>
        <Modal.Body>
          <div className="space-y-6 text-base leading-relaxed text-gray-500 dark:text-gray-400">
            {/* Device */}
            <section className="flex flex-row items-center gap-2 flex-wrap">
              <div className="w-6">
                {isPc ? <ComputerDesktopIcon /> : <DevicePhoneMobileIcon />}
              </div>
              <h2 className="capitalize">{device.device}</h2>
              <p>{device.ip || "ip unknown"}</p>
              {isCurrent && <Badge>current</Badge>}
            </section>
            {/* Location */}
            <section className="saturate-50">
              {lat && lng ? (
                <Map height={300} defaultCenter={[lat, lng]} defaultZoom={11}>
                  <Marker width={50} anchor={[lat, lng]} />
                </Map>
              ) : (
                "activity location unknown"
              )}
            </section>
            {/* Last Session */}
            <section>
              <h3>Last Session</h3>
              {device.lastActive
                ? new Date(device.lastActive).toLocaleString()
                : " unknown"}
            </section>
            {/* Session Expiry */}
            <section>
              <h3>Session expires at</h3>
              {device.expiresAt
                ? new Date(device.expiresAt).toLocaleString()
                : " unknown"}
            </section>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button
            gradientDuoTone="purpleToBlue"
            size="sm"
            onClick={toggleModal}
          >
            Close
          </Button>
          <Button
            gradientDuoTone="purpleToBlue"
            size="sm"
            outline
            onClick={deleteSession}
          >
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Delete Session
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Device;
