import { useAuthStore } from "../../../../store/authStore";
import { paymentSchema, NewPaymentType } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useMutation, gql, useQuery } from "@apollo/client";
import { PAYMENT_LOGS_CORE_FIELDS } from "./fragments";
import { Button, Label, Spinner } from "flowbite-react";
import { GET_PAYMENT_METHODS } from "../../settings/payment/paymentMethods/paymentMethods";
import { ListGroup } from "flowbite-react";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { PaymentMethodType } from "../../settings/payment/paymentMethods/types";
import DynamicHeroIcon from "../../../../comps/hIcon";
import dayjs from "dayjs";
import { addAlert } from "../../../../store/alertStore";

const INSERT_PAYMENT = gql`
  ${PAYMENT_LOGS_CORE_FIELDS}
  mutation INSERT_PAYMENT(
    $received_by: Int!
    $comment: String
    $processID: Int!
    $received_at: timestamptz
    $methodID: Int!
    $amount: Float!
  ) {
    insert_paymentLogs_one(
      object: {
        received_by: $received_by
        comment: $comment
        processID: $processID
        received_at: $received_at
        methodID: $methodID
        amount: $amount
      }
    ) {
      ...PaymentLogsCoreFields
    }
  }
`;

interface props {
  processID: number;
}

export default function AddPayment({ processID }: props) {
  const { user } = useAuthStore();

  const [insert_payment, { loading }] = useMutation(INSERT_PAYMENT);

  const [selecting, setSelecting] = useState(false);
  const toggleSelecting = () => {
    setSelecting(!selecting);
  };

  const ref = useRef<HTMLFormElement>(null);

  useOnClickOutside(ref, () => {
    setSelecting(false);
  });

  const {
    data: data_payMethods,
    loading: loading_payMethods,
    error,
  } = useQuery(GET_PAYMENT_METHODS, {
    onCompleted(data) {
      const meth = data.paymentMethods[0];
      if (meth) {
        setValue("methodID", meth.id);
      }
    },
  });
  const paymentMethods = data_payMethods?.paymentMethods;

  const defaultValues: NewPaymentType = {
    methodID: 0,

    received_at: dayjs(new Date()).format("YYYY-MM-DD"),
    received_by: user?.id || 0,
    comment: "",
    amount: 0,
    processID,
  };

  const { register, watch, setValue, reset, handleSubmit } = useForm({
    resolver: zodResolver(paymentSchema),
    defaultValues,
  });

  const method = watch("methodID");
  const changeMethod = (id: number) => {
    setValue("methodID", id);
    setSelecting(false);
  };

  const onSubmit = handleSubmit(async data => {
    if (data.amount == 0) {
      return addAlert({ message: "please enter amount", type: "warning" });
    }
    insert_payment({
      variables: {
        ...data,
      },
      update(cache, { data: { insert_paymentLogs_one: newPayment } }) {
        cache.modify({
          fields: {
            paymentLogs(existingPayments = []) {
              const newPaymentRef = cache.writeFragment({
                data: newPayment,
                fragment: PAYMENT_LOGS_CORE_FIELDS,
                fragmentName: "PaymentLogsCoreFields",
              });
              return [...existingPayments, newPaymentRef];
            },
          },
        });
      },
      onCompleted() {
        reset({
          ...defaultValues,
          methodID: data.methodID,
        });
      },
    });
  });

  return (
    <form
      ref={ref}
      className={`${
        paymentMethods ? "" : "opacity-50 pointer-events-none"
      } w-full gap-2 flex flex-col items-start flex-wrap shadow-md border-[1px] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 p-2 rounded-md`}
      onSubmit={onSubmit}
    >
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center justify-between gap-2">
          <Label value="Date" />
          <input
            type="date"
            {...register("received_at")}
            className="dark:bg-gray-500 rounded-md outline-none p-[5px] dark:border-none font-normal text-sm min-w-[140px]"
          />
        </div>

        <div className="flex flex-row items-center justify-between gap-2">
          <Label value="Amount" />
          <input
            {...register("amount")}
            className="dark:bg-gray-500 rounded-md p-[5px] px-[8px] dark:border-none w-28 border-[1px] border-gray-500 text-center min-w-[140px]"
            placeholder="Set amount"
          />
        </div>
      </div>

      <div className="flex flex-row items-center justify-between gap-2 w-full">
        <div className="flex flex-row items-center gap-2 relative">
          <div
            className="font-semibold cursor-pointer uppercase"
            onClick={toggleSelecting}
          >
            {!method && <Spinner light className="w-4" />}
            <Method methods={paymentMethods || []} id={method} />
          </div>
          {selecting && (
            <ListGroup className="absolute top-[100%] mt-5 left-[-9px] z-10 shadow-md">
              {paymentMethods
                ?.filter(meth => meth.id !== method)
                .map(method => (
                  <ListGroup.Item
                    key={method.id}
                    onClick={() => {
                      changeMethod(method.id);
                    }}
                  >
                    <Method methods={paymentMethods || []} id={method.id} />
                  </ListGroup.Item>
                ))}
            </ListGroup>
          )}
        </div>
        <div className="flex flex-row gap-2 flex-1 justify-end items-center">
          <input
            {...register("comment")}
            placeholder="comments"
            className="w-full bg-transparent outline-none border-none focus:outline-none text-right"
          />
          <Button
            type="submit"
            size="xs"
            outline
            gradientDuoTone="purpleToBlue"
          >
            {loading && <Spinner size="xs" light className="mr-1" />}
            Add
          </Button>
        </div>
      </div>
    </form>
  );
}

const Method = ({
  id,
  methods,
}: {
  id: number;
  methods: PaymentMethodType[];
}) => {
  const method = methods?.find(meth => meth.id == id);
  if (!method) {
    return null;
  }
  return (
    <div className="flex flex-row items-center gap-2 uppercase">
      <DynamicHeroIcon class="w-5" icon={method.iconName} />
      <span>{method.name}</span>
    </div>
  );
};
