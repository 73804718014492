import { EllipsisVerticalIcon, FolderIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { FileType } from "./type";
import { default as config } from "../../../../config";
import Menu from "./menu";

interface props {
  path: string[];
  folder: FileType;
  setCurrentPath: React.Dispatch<React.SetStateAction<string[]>>;
  clipboard: {
    path: string[];
    copy: boolean;
  };
  setClipboard: React.Dispatch<
    React.SetStateAction<{
      path: string[];
      copy: boolean;
    }>
  >;
}

export default function Dir({
  path,
  folder,
  setCurrentPath,
  clipboard,
  setClipboard,
}: props) {
  const currentPath = path.concat(folder.name);

  const move = () => {
    setCurrentPath(currentPath);
  };
  const [showMenu, setShowMenu] = useState(false);
  const toggleShow = () => {
    setShowMenu(!showMenu);
  };

  const mandatoryDirs = config.mandatoryDirs;
  const canEdit = !mandatoryDirs.some(dir => dir.includes(folder.name));

  return (
    <div className="group relative flex flex-row gap-2 items-center justify-between">
      <div
        onClick={move}
        className="hover:text-plum cursor-pointer flex flex-row gap-2 items-center flex-1"
      >
        <FolderIcon className="w-6" />
        <div className="flex-1">{folder.name}</div>
      </div>
      {canEdit && (
        <EllipsisVerticalIcon
          className="w-5 @md:hidden @md:group-hover:block hover:text-plum cursor-pointer"
          onClick={toggleShow}
        />
      )}
      {showMenu && (
        <Menu
          path={currentPath}
          file={folder}
          setShowMenu={setShowMenu}
          setCurrentPath={setCurrentPath}
          clipboard={clipboard}
          setClipboard={setClipboard}
        />
      )}
    </div>
  );
}
