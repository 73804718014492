import { sort } from "fast-sort";
import { orderSummaryColumn } from "../../../types";
import { option } from "../../types";

interface props {
  tdStyle: string;
  column: orderSummaryColumn;
  presetOptionsLength: number;
  preset?: option;
  isOpening: boolean;
  options: option[];
}

export default function Column({
  tdStyle,
  column,
  presetOptionsLength,
  preset,
  isOpening,
  options,
}: props) {
  const includedOptions = sort(
    options.filter(o => {
      if (
        !isOpening &&
        presetOptionsLength > 1 &&
        o.group &&
        o.presetID !== preset?.id &&
        o.id !== preset?.id
      ) {
        return false;
      }
      return column.options.includes(o.id) && !o.noCalc;
    })
  ).asc(["group", "position"]);

  const value = includedOptions
    .map(
      o => o.displayValue || JSON.stringify(o.value).replaceAll('"', "") || ""
    )
    .join(" ");

  return (
    <td className={tdStyle} key={column.id}>
      {includedOptions.length == 0 && column.default}
      {value}
    </td>
  );
}
