import { groupBy } from "../../../utils/arrayObjMethods";
import { RecordType } from "../shared/record/types";
import ListGroup from "./listGroup";
import { range, recordGroup } from "./types";

interface props {
  records: RecordType[];
  range: range;
  dates: Date[];
}

export default function RecordListView({ records, range, dates }: props) {
  let userGroup = Object.values(groupBy(records, i => i.user.id));

  const groups = userGroup.map<recordGroup>(ug => {
    return {
      user: ug[0].user,
      records: ug,
    };
  });

  return (
    <div className="flex flex-col gap-2">
      {groups.map(g => (
        <ListGroup key={g.user.id} group={g} range={range} dates={dates} />
      ))}
    </div>
  );
}
