import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";
import { NewContactType, contactSchema, ContactType } from "../types";
import { CONTACT_CORE_FIELDS } from "../fragments";
import {
  Badge,
  Breadcrumb,
  Button,
  Label,
  ListGroup,
  Select,
  Spinner,
  TextInput,
  ToggleSwitch,
} from "flowbite-react";
import { useNavigate } from "react-router-dom";
import {
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
  EnvelopeIcon,
  HomeIcon,
  IdentificationIcon,
  MagnifyingGlassIcon,
  MapIcon,
  PhoneIcon,
  TagIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import AddContactMaps from "./addContactMaps";
import {
  Autocomplete,
  AutocompleteProps,
  LoadScriptProps,
  useLoadScript,
} from "@react-google-maps/api";
import { default as config } from "../../../../config";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebounce } from "usehooks-ts";
import { SEARCH_CONTACT } from "../../process/addProcess/contact";
import { GET_CONTACT_TYPES } from "../../settings/contact/contactTypes/contactTypes";
import { useAuthStore } from "../../../../store/authStore";
import { addAlert } from "../../../../store/alertStore";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];
const companyLoc = config.companyLoc;

export const ADD_CONTACT = gql`
  ${CONTACT_CORE_FIELDS}
  mutation ADD_CONTACT(
    $createdBy: Int
    $typeID: Int!
    $name: String!
    $number: String
    $mail: String
    $address: String
    $description: String
    $role: String
    $organisationID: Int
    $isOrganisation: Boolean
  ) {
    insert_contacts_one(
      object: {
        createdBy: $createdBy
        typeID: $typeID
        name: $name
        number: $number
        mail: $mail
        address: $address
        description: $description
        role: $role
        organisationID: $organisationID
        isOrganisation: $isOrganisation
      }
      on_conflict: {
        constraint: contacts_name_number_address_key
        update_columns: [name, number, mail, address, description, typeID]
      }
    ) {
      ...ContactCoreFields
    }
  }
`;

export default function AddContact() {
  const [insert_contact, { loading }] = useMutation(ADD_CONTACT);
  const { user } = useAuthStore();

  const navigate = useNavigate();

  const { data, error } = useQuery(GET_CONTACT_TYPES);
  const types = data?.contactType;
  if (error) {
    console.log(error);
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const autoCompleteLoaded = isLoaded
    ? (autocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(autocomplete);
      }
    : () => {};

  const defaultValues = {
    name: "",
    address: "",
    number: "",
    mail: "",
    isOrganisation: false,
    typeID: undefined,
    organisation: undefined,
    description: "",
    role: "",
  };

  const {
    register,
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<NewContactType>({
    resolver: zodResolver(contactSchema),
    defaultValues,
  });

  const address = watch("address");

  const [search, setSearch] = useState("");
  const [results, setResults] = useState<ContactType[]>([]);
  const debouncedSearch = useDebounce(search, 500);
  const [search_contact] = useLazyQuery(SEARCH_CONTACT);

  useEffect(() => {
    if (search.trim() == "") {
      setResults([]);
    }
    search_contact({
      variables: { Str: debouncedSearch },
      fetchPolicy: "network-only",
      onCompleted(data) {
        const res = data.search_contacts?.filter(c => c.isOrganisation);
        if (res) {
          setResults(res);
        }
      },
    });
  }, [debouncedSearch]);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const { formatted_address: address, address_components } = place;

      const postCode = address_components
        ? address_components[address_components.length - 1].long_name
        : null;

      if (address) {
        setValue(
          "address",
          address
            .replace(", Australia", "")
            .replace(postCode || "", "")
            .trim()
        );
      }
    }
  };

  const autocompleteOptions: AutocompleteProps["options"] = {
    componentRestrictions: { country: "au" },
    bounds: {
      north: companyLoc.lat + 3,
      south: companyLoc.lat - 3,
      east: companyLoc.lng + 3,
      west: companyLoc.lng - 3,
    },
  };

  const formIsOrganisation = watch("isOrganisation");
  const toggleOrganisation = () => {
    setValue("isOrganisation", !formIsOrganisation, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const formOrganisation = watch("organisation");
  const setOrganisation = (org: ContactType) => {
    setValue("organisation", org, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };
  const clearOrganisation = () => {
    setValue("organisation", undefined, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onSubmit = handleSubmit(async data => {
    insert_contact({
      variables: {
        ...data,
        organisation: undefined,
        createdBy: user?.id,
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot add contact",
          type: "failure",
        });
      },
      onCompleted(data) {
        const contact = data.insert_contacts_one;
        if (!contact) {
          return navigate("../");
        }
        navigate("/contacts/detail/" + contact.id);
      },
      update(cache, { data: { insert_contacts_one: newContact } }) {
        cache.modify({
          fields: {
            contacts(existing = []) {
              const newContactRef = cache.writeFragment({
                data: newContact,
                fragment: CONTACT_CORE_FIELDS,
                fragmentName: "ContactCoreFields",
              });
              return [...existing, newContactRef];
            },
          },
        });
      },
    });
  });

  const cancel = () => {
    reset();
    setResults([]);
    setSearch("");
    navigate("../");
  };

  return (
    <main className="px-6 pb-10 select-none flex flex-col h-full">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("../");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            Contact
          </Breadcrumb.Item>
          <Breadcrumb.Item className="truncate">Add Contact</Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      <h1 className="mt-3">Add Contact</h1>

      <form
        onSubmit={onSubmit}
        className="grid grid-cols-1 
        @lg:grid-cols-3 supports-[not(container-type:inline-size)]:lg:grid-cols-3
        gap-4 mt-2"
      >
        {/* Map */}
        <AddContactMaps address={address || ""} />
        {/* Details */}
        <div className="flex flex-col gap-2 flex-1">
          <h2>Details</h2>
          <div className="col-span-1 shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
            {/* Name */}
            <div className="flex flex-col gap-2">
              <Label className="flex-1" value="Name" />
              <TextInput
                icon={UserIcon}
                sizing="sm"
                {...register("name")}
                color={errors.name?.message ? "failure" : undefined}
                placeholder="Enter name"
              />
            </div>
            {/* Address */}
            <div className="flex flex-col gap-2">
              <Label className="flex-1" value="Address" />
              {isLoaded && (
                <Autocomplete
                  onLoad={autoCompleteLoaded}
                  onPlaceChanged={onPlaceChanged}
                  options={autocompleteOptions}
                  fields={[
                    "formatted_address",
                    "geometry.location",
                    "address_component",
                  ]}
                >
                  <TextInput
                    icon={MapIcon}
                    sizing="sm"
                    {...register("address")}
                    color={errors.address?.message ? "failure" : undefined}
                    placeholder="Enter address"
                  />
                </Autocomplete>
              )}
            </div>
            {/* Type */}
            <div className="flex flex-col gap-2">
              <Label className="flex-1" value="Type" />
              <Select
                icon={TagIcon}
                sizing="sm"
                {...register("typeID")}
                color={errors.typeID?.message ? "failure" : undefined}
              >
                {types?.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </div>
            {/* Number */}
            <div className="flex flex-col gap-2">
              <Label className="flex-1" value="Number" />
              <TextInput
                icon={PhoneIcon}
                sizing="sm"
                {...register("number")}
                color={errors.number?.message ? "failure" : undefined}
                placeholder="Enter Number"
              />
            </div>
            {/* Email */}
            <div className="flex flex-col gap-2">
              <Label className="flex-1" value="Mail" />
              <TextInput
                icon={EnvelopeIcon}
                sizing="sm"
                {...register("mail")}
                color={errors.mail?.message ? "failure" : undefined}
                placeholder="Enter Email"
              />
            </div>
            {/* Role */}
            <div className="flex flex-col gap-2">
              <Label className="flex-1" value="Role" />
              <TextInput
                icon={IdentificationIcon}
                sizing="sm"
                {...register("role")}
                color={errors.role?.message ? "failure" : undefined}
                placeholder="Enter role"
              />
            </div>
            {/* Desc */}
            <div className="flex flex-col gap-2">
              <Label className="flex-1" value="Description" />
              <TextInput
                icon={ChatBubbleLeftEllipsisIcon}
                sizing="sm"
                {...register("description")}
                color={errors.description?.message ? "failure" : undefined}
                placeholder="Enter description"
              />
            </div>
            {/* Organisation */}
            <div className="flex flex-col gap-2">
              <Label className="flex-1" value="Organisation" />
              <TextInput
                icon={MagnifyingGlassIcon}
                sizing="sm"
                style={{ opacity: 1 }}
                onChange={e => {
                  setSearch(e.target.value);
                }}
                value={search}
                placeholder="Search contacts"
              />
              <ListGroup>
                {results?.map(res => (
                  <ListGroup.Item
                    key={res.id}
                    onClick={() => {
                      setOrganisation(res);
                    }}
                  >
                    <div className="flex flex-row gap-1">
                      {res.id == formOrganisation?.id && (
                        <CheckIcon className="w-4" />
                      )}
                      {res.name} - {res.mail}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              <Badge
                color="purple"
                className="w-min cursor-pointer"
                onClick={clearOrganisation}
              >
                <div className="flex flex-row gap-2">
                  {formOrganisation?.name || "Nill"}
                  {formOrganisation && <TrashIcon className="w-4" />}
                </div>
              </Badge>
            </div>
            <div className="flex flex-row justify-end mt-2 gap-2 ">
              <ToggleSwitch
                checked={formIsOrganisation ? true : false}
                label="Is Organisation"
                onChange={toggleOrganisation}
                //@ts-expect-error
                color="purple"
              />
            </div>
          </div>
          <div className="flex justify-end flex-row items-center gap-2 mt-2">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              Add
            </Button>
          </div>
        </div>
      </form>
    </main>
  );
}
