import dayjs from "dayjs";
import { dateGroup } from "./types";
import AgendaItem from "./agendaItem";
import { CalendarProps } from "react-big-calendar";

interface props {
  index: number;
  group: dateGroup;
  onDrillDown: CalendarProps["onDrillDown"];
  onSelectEvent: CalendarProps["onSelectEvent"];
}

export default function DateGroup({
  index,
  group,
  onDrillDown,
  onSelectEvent,
}: props) {
  const isToday = dayjs(group.date).isSame(dayjs(), "date");
  const onDateClick = () => {
    const date = dayjs(group.date).toDate();
    if (onDrillDown) {
      onDrillDown(date, "day");
    }
  };
  return (
    <div
      id={group.date}
      className={`py-2 px-1 border-y-[1px] ${
        index == 0 && "border-t-0"
      } dark:border-gray-600 flex flex-row`}
    >
      <div
        className={`pr-1 md:w-[115px] md:h-[30px] ${
          isToday && "text-grass font-semibold"
        }`}
      >
        <div
          onClick={onDateClick}
          className="flex flex-col md:flex-row gap-1 items-center rounded-md cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600"
        >
          <div className="md:hidden">{dayjs(group.date).format("MMM")}</div>
          <div className="text-lg text-center item w-[28px]">
            {dayjs(group.date).format("D")}
          </div>
          <div className="leading-[0] hidden md:block">
            {dayjs(group.date).format("MMM, ddd")}
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col gap-2">
        {group.events.map(event => (
          <AgendaItem
            key={event.resource.id}
            event={event}
            onSelectEvent={onSelectEvent}
          />
        ))}
      </div>
    </div>
  );
}
