import { Breadcrumb } from "flowbite-react";
import Roles from "./roles/roles";
import Permissions from "./permissions/permissions";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/solid";

export default function Auth() {
  return (
    <div className="mb-1 flex-grow  mx-auto">
      <section className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-4">
        <nav className="w-full">
          <Breadcrumb className="w-full">
            <Link to="../">
              <Breadcrumb.Item>
                <HomeIcon className="w-5 mr-2" />
                Settings
              </Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Auths</Breadcrumb.Item>
          </Breadcrumb>
        </nav>
        <main className="space-y-4">
          <Roles />
          <Permissions />
        </main>
      </section>
    </div>
  );
}
