import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  StampedMessagePayload,
  useNotificationStore,
} from "../../../store/notificationStore";
import { useState } from "react";

interface props {
  notification: StampedMessagePayload;
}

const NotificationItem = ({ notification }: props) => {
  const { removeNotification } = useNotificationStore();

  const [show, setShow] = useState(true);

  const dismiss = () => {
    setShow(!show);
    setTimeout(() => {
      removeNotification(notification.messageId);
    }, 200);
  };

  return (
    <div
      className={`w-full flex flex-col transition-all gap-1 ${
        !show && "translate-x-[120%]"
      }`}
    >
      <div className="w-full flex flex-row justify-between">
        <div className="flex flex-row gap-2 items-center">
          <div className="p-1 bg-grass rounded-full" />
          <div className="font-semibold">{notification.data?.title}</div>
        </div>
        <XMarkIcon
          className="w-4 cursor-pointer hover:text-plum"
          onClick={dismiss}
        />
      </div>
      <div className="">{notification.data?.body}</div>
    </div>
  );
};

export default NotificationItem;
