import { Button, Spinner, Table, TextInput } from "flowbite-react";
import Heading from "../../../../comps/heading";
import { useState } from "react";
import { ProcessHistory } from "../types";
import { ProcessProductType } from "../types";
import { TypedDocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import { GET_SOURCES } from "../../settings/process/sources/sources";
import { GET_TAGS } from "../../settings/process/tags/tags";
import CsvDownloadButton from "react-json-to-csv";
import { addAlert } from "../../../../store/alertStore";
import { NewPaymentType } from "../../shared/paymentLogs/types";
import { NewProductionType } from "../../shared/productions/types";
import { GET_TEAMS } from "../../settings/teams/teamList";
import { GET_PRODUCTION_STATUSES_NO_PID } from "../../settings/production/productionStatuses/productionStatuses";

interface InsertedProcess {
  insert_process_one: {
    id: number;
  };
}

const INSERT_PROCESS: TypedDocumentNode<InsertedProcess> = gql`
  mutation INSERT_PROCESS($object: process_insert_input!) {
    insert_process_one(object: $object) {
      id
    }
  }
`;

const INSERT_PAYMENT = gql`
  mutation INSERT_PAYMENT($object: paymentLogs_insert_input!) {
    insert_paymentLogs_one(object: $object) {
      id
    }
  }
`;

const INSERT_PRODUCTION = gql`
  mutation INSERT_PRODUCTION($object: productions_insert_input!) {
    insert_productions_one(object: $object) {
      id
    }
  }
`;

interface ObjectId {
  $oid: string;
}

interface MongoContact {
  id: ObjectId;
  primary: boolean;
}

interface MongoSchedule {
  _id: ObjectId;
  link: string;
  calId: string;
  id: string;
}

interface MongoProduct {
  _id: ObjectId;
  num: number;
  qty: number;
  desc: string;
}

interface MongoProduction {
  _id: ObjectId;
  released: MongoDate;
  team: string;
  status: {
    num: number;
    name: string;
  };
  comment?: string;
  due?: MongoDate;
}

interface MongoHistory {
  _id: ObjectId;
  status: {
    num: number;
    name: string;
  };
  type: string;
  message: string;
  date: MongoDate;
  by: ObjectId;
}

interface MongoPaymentHistory {
  _id: ObjectId;
  amount: number;
  date: MongoDate;
  desc: string;
}

interface MongoPayment {
  history: MongoPaymentHistory[];
  status: number;
}

interface MongoDate {
  $date: string;
}

interface MongoItem {
  _id: ObjectId;
  address: string;
  client: string;
  contacts: MongoContact[];
  createdBy: ObjectId;
  date: MongoDate;
  description: string;
  from?: MongoDate;
  due?: MongoDate;
  number: number;
  salesRep?: ObjectId;
  status: {
    is: {
      name: string;
      num: number;
    };
  };
  type: string;
  year: number;
  returning?: boolean;
  schedule?: MongoSchedule[];
  tag?: string[];
  source?: string[];
  products?: MongoProduct[];
  production?: MongoProduction[];
  history?: MongoHistory[];
  netWorth?: number;
  payment?: MongoPayment;
}

interface ConnectorID {
  oldId: string;
  id: number;
}

interface InsertError {
  id: string;
  error: string;
  data: any;
}

interface Converted {
  oldId: string;
  createdBy: number | undefined;
  year: number;
  number: number;
  typeId: number;
  name: string;
  address: string | null;
  description: string | null;
  returning: boolean;
  salesRep: number | null | undefined;
  status: number;
  created_at: string | null;
  from: string | null;
  due: string | null;
  histories: ProcessHistory[];
  products: ProcessProductType[];
  value: number;
  payStatus: number | null;
  contacts: (number | undefined)[];
  sources: number[];
  tags: number[];
  postCode: number | null;
  schedules: string[];
  deleted: boolean;
  oldItem: MongoItem;
}

export default function Converter() {
  const [items, setItems] = useState<MongoItem[]>([]);
  const [convertedList, setConvertedList] = useState<Converted[]>([]);
  const [errors, setErrors] = useState<InsertError[]>([]);
  const [progress, setProgress] = useState(0);

  const [contacts, setContacts] = useState<ConnectorID[]>([]);
  const [users, setUsers] = useState<ConnectorID[]>([]);
  const [statuses, setStatuses] = useState<ConnectorID[]>([]);
  const [productList, setProducts] = useState<ConnectorID[]>([]);
  const [paymentStatuses, setPaymentStatuses] = useState<ConnectorID[]>([]);

  const { data: data_sources } = useQuery(GET_SOURCES);
  const sources = data_sources?.sources;
  const { data: data_tags } = useQuery(GET_TAGS);
  const tags = data_tags?.tags;
  const { data: data_teams } = useQuery(GET_TEAMS);
  const teams = data_teams?.teams;
  const { data: data_production_statuses } = useQuery(
    GET_PRODUCTION_STATUSES_NO_PID
  );
  const productionStatuses = data_production_statuses?.productionStatus;

  const [insert_process, { loading }] = useMutation(INSERT_PROCESS);
  const [insert_payment] = useMutation(INSERT_PAYMENT);
  const [insert_production] = useMutation(INSERT_PRODUCTION);

  const readJsonFile = (file: Blob) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = event => {
        if (event.target) {
          resolve(JSON.parse(event.target.result as string));
        }
      };

      fileReader.onerror = error => reject(error);
      fileReader.readAsText(file);
    });

  const onChangeItems = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setItems(parsedData as MongoItem[]);
    }
  };

  const onChangeContacts = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setContacts(parsedData as ConnectorID[]);
    }
  };

  const onChangeUsers = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setUsers(parsedData as ConnectorID[]);
    }
  };

  const onChangeStatuses = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setStatuses(parsedData as ConnectorID[]);
    }
  };

  const onChangeProducts = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setProducts(parsedData as ConnectorID[]);
    }
  };

  const onChangePayStatus = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setPaymentStatuses(parsedData as ConnectorID[]);
    }
  };

  const findId = (array: ConnectorID[], oid: string) => {
    return array.find(arr => arr.oldId == oid)?.id;
  };

  const convertType = (typeStr: string) => {
    const type = typeStr.toLowerCase();

    switch (type) {
      case "commercial":
        return 3;
      case "project":
        return 3;
      case "wholesale":
        return 2;
      case "domestic":
        return 1;
      default:
        console.log("no type match");
        return -1;
    }
  };

  const convertHistories = (histories: MongoHistory[]) => {
    const newHistories: ProcessHistory[] = histories.map(history => ({
      by: findId(users, history.by.$oid) || -1,
      date: convertDate(history.date.$date) || new Date().toISOString(),
      message: history.message,
      status: findId(statuses, history.status.name) || -1,
      type: history.type == "status" ? "status" : "detail",
    }));

    return newHistories;
  };

  const convertProducts = (products: MongoProduct[]) => {
    const newProducts: ProcessProductType[] = products.map(product => ({
      productId: findId(productList, product.num.toString()) || -1,
      qty: product.qty,
    }));

    return newProducts;
  };

  const convertContacts = (oldContacts: MongoContact[]) => {
    const newContacts = oldContacts.map(contact =>
      findId(contacts, contact.id.$oid)
    );
    return newContacts;
  };

  const convertSources = (oldSources: string[]) => {
    return oldSources.map(
      source => sources?.find(s => s.name == source)?.id || -1
    );
  };

  const convertTags = (oldTags: string[]) => {
    return oldTags.map(tag => tags?.find(t => t.name == tag)?.id || -1);
  };

  const getPostCode = (address: string) => {
    const addressArray = address.split(" ");
    const postCode = addressArray.find(str => /\d{4}/.test(str));

    return postCode ? Number(postCode) : null;
  };

  const convertSchedules = (schedules: MongoSchedule[]) => {
    return schedules.map(schedule => `${schedule.calId}::${schedule.id}`);
  };

  const convertDate = (date: Date | string) => {
    try {
      return new Date(date).toISOString();
    } catch (error) {
      return null;
    }
  };

  const convert = (item: MongoItem, index?: number) => {
    const converted: Converted = {
      oldId: item._id.$oid,
      createdBy: findId(users, item.createdBy.$oid),
      year: item.year + 2000,
      number: item.number,
      typeId: convertType(item.type),
      name: item.client,
      address: item.address || null,
      description: item.description || null,
      returning: item.returning || false,
      salesRep: item.salesRep && (findId(users, item.salesRep?.$oid) || null),
      status: findId(statuses, item.status.is.name) || -1,
      created_at: convertDate(item.date.$date),
      from: item.from ? convertDate(item.from.$date) : null,
      due: item.due ? convertDate(item.due.$date) : null,
      histories: item.history ? convertHistories(item.history) : [],
      products: item.products ? convertProducts(item.products) : [],
      value: item.netWorth || 0,
      payStatus: item.payment
        ? findId(paymentStatuses, item.payment.status.toString()) || 0
        : null,
      contacts: convertContacts(item.contacts) || [],
      sources: item.source ? convertSources(item.source) : [],
      tags: item.tag ? convertTags(item.tag) || [] : [],
      postCode: getPostCode(item.address),
      schedules: item.schedule ? convertSchedules(item.schedule) : [],
      deleted: false,
      oldItem: item,
    };

    return converted;
  };

  const insertOne = async (oldItem: MongoItem) => {
    console.log(oldItem);
    const item = convert(oldItem);

    const proceed = confirm("insert this item?");

    if (!proceed) {
      return;
    }

    await insert_process({
      variables: { object: { ...item, oldItem: undefined } },
      onError(error) {
        const err: InsertError = {
          data: item,
          error: error.message,
          id: item.oldId,
        };

        setErrors(errs => errs.concat(err));
      },
      onCompleted(data) {
        const insertedId = data.insert_process_one.id;

        const paymentLog = item.oldItem.payment?.history;

        if (paymentLog) {
          for (const log of paymentLog) {
            const payment: NewPaymentType = {
              amount: log.amount,
              comment: log.desc || "",
              methodID: 4,
              processID: insertedId,
              received_by: item.createdBy,
              received_at:
                convertDate(log.date.$date) || new Date().toISOString(),
            };
            insert_payment({
              variables: { object: payment },
              onError(error) {
                console.log(error);
              },
            });
          }
        }

        const productions = item.oldItem.production;
        if (productions) {
          for (const production of productions) {
            const team = teams?.find(
              t => t.name.toLowerCase() == production.team.toLowerCase()
            );
            if (!team) {
              return;
            }
            const product = team.products && team.products[0];
            if (!product) {
              return;
            }
            const qty = item.products.find(pd => pd.productId == product)?.qty;

            const productionStat = productionStatuses?.find(
              ps => ps.name == production.status?.name
            )?.id;

            const newProduction: NewProductionType = {
              badge: "",
              createdBy: item.createdBy || -1,
              description: production.comment || "",
              due: production.due?.$date
                ? convertDate(production.due.$date)
                : null,
              processID: insertedId,
              productID: product,
              qty: qty || 1,
              statusID: productionStat || 5,
              teamID: team.id,
              created_at: convertDate(production.released.$date) || undefined,
            };

            insert_production({
              variables: { object: newProduction },
              onError(error) {
                console.log(error);
              },
            });
          }
        }
      },
    });
  };

  const insertAll = async () => {
    setErrors([]);
    const converted = items.map(item => convert(item));
    const proceed = confirm("insert all items?");

    if (!proceed) {
      return;
    }

    const totalLength = converted.length;
    let count = 0;

    for await (const item of converted) {
      await insert_process({
        variables: { object: { ...item, oldItem: undefined } },
        onError(error) {
          const err: InsertError = {
            data: item,
            error: error.message,
            id: item.oldId,
          };

          setErrors(errs => errs.concat(err));
        },
        onCompleted(data) {
          count++;
          setProgress(Math.round((count / totalLength) * 100));
          const insertedId = data.insert_process_one.id;

          const paymentLog = item.oldItem.payment?.history;
          if (paymentLog) {
            for (const log of paymentLog) {
              const payment: NewPaymentType = {
                amount: log.amount,
                comment: log.desc || "",
                methodID: 4,
                processID: insertedId,
                received_by: item.createdBy,
                received_at:
                  convertDate(log.date.$date) || new Date().toISOString(),
              };
              insert_payment({
                variables: { object: payment },
                onError(error) {
                  const err: InsertError = {
                    data: item,
                    error: error.message,
                    id: item.oldId,
                  };

                  setErrors(errs => errs.concat(err));
                },
              });
            }
          }

          const productions = item.oldItem.production;
          if (productions) {
            for (const production of productions) {
              const team = teams?.find(
                t => t.name.toLowerCase() == production.team.toLowerCase()
              );
              if (!team) {
                return;
              }
              const product = team.products && team.products[0];
              if (!product) {
                return;
              }
              const qty = item.products.find(
                pd => pd.productId == product
              )?.qty;

              const productionStat = productionStatuses?.find(
                ps => ps.name == production.status?.name
              )?.id;

              const newProduction: NewProductionType = {
                badge: "",
                createdBy: item.createdBy || -1,
                description: production.comment || "",
                due: production.due?.$date
                  ? convertDate(production.due.$date)
                  : null,
                processID: insertedId,
                productID: product,
                qty: qty || 1,
                statusID: productionStat || 5,
                teamID: team.id,
                created_at: convertDate(production.released.$date) || undefined,
              };

              insert_production({
                variables: { object: newProduction },
                onError(error) {
                  const err: InsertError = {
                    data: item,
                    error: error.message,
                    id: item.oldId,
                  };

                  setErrors(errs => errs.concat(err));
                },
              });
            }
          }
        },
      });
    }

    addAlert({
      message: `${count}/${totalLength} inserted`,
      type: "info",
      persist: true,
    });
  };

  const convertAll = () => {
    const converted = items.map((item, i) => convert(item, i));
    setConvertedList(converted);
  };

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6">
        <div className="flex flew-row h-8">
          <Heading name="Process converter" />
        </div>
        <div className="flex flex-row text-red-500">
          WARNING, do not proceed if not 100% sure !!!
        </div>
      </div>
      <section className="p-6 gap-4 flex flex-col mt-1">
        <h2>Contacts</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          onChange={onChangeContacts}
        />
        {contacts.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>oldId</Table.HeadCell>
              <Table.HeadCell>id</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {contacts.slice(0, 10).map(contact => (
                <Table.Row
                  key={contact.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{contact.oldId}</Table.Cell>
                  <Table.Cell>{contact.id}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <h2>Users</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          disabled={contacts.length == 0}
          onChange={onChangeUsers}
        />
        {users.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>oldId</Table.HeadCell>
              <Table.HeadCell>id</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {users.slice(0, 10).map(user => (
                <Table.Row
                  key={user.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{user.oldId}</Table.Cell>
                  <Table.Cell>{user.id}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <h2>Statuses</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          disabled={users.length == 0}
          onChange={onChangeStatuses}
        />
        {statuses.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>oldId</Table.HeadCell>
              <Table.HeadCell>id</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {statuses.slice(0, 10).map(stat => (
                <Table.Row
                  key={stat.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{stat.oldId}</Table.Cell>
                  <Table.Cell>{stat.id}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <h2>Products</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          disabled={statuses.length == 0}
          onChange={onChangeProducts}
        />
        {productList.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>oldId</Table.HeadCell>
              <Table.HeadCell>id</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {productList.slice(0, 10).map(product => (
                <Table.Row
                  key={product.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{product.oldId}</Table.Cell>
                  <Table.Cell>{product.id}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <h2>Payment Statuses</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          disabled={productList.length == 0}
          onChange={onChangePayStatus}
        />
        {paymentStatuses.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>oldId</Table.HeadCell>
              <Table.HeadCell>id</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {paymentStatuses.slice(0, 10).map((stat, i) => (
                <Table.Row
                  key={`${stat.id}-${i}`}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{stat.oldId}</Table.Cell>
                  <Table.Cell>{stat.id}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <h2>Items</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          disabled={paymentStatuses.length == 0}
          onChange={onChangeItems}
        />
        {items.length > 0 && (
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell>name</Table.HeadCell>
              <Table.HeadCell>type</Table.HeadCell>
              <Table.HeadCell>year</Table.HeadCell>
              <Table.HeadCell>number</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {items.slice(0, 10).map(item => (
                <Table.Row
                  onClick={() => {
                    insertOne(item);
                  }}
                  key={item._id.$oid}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{item.client}</Table.Cell>
                  <Table.Cell>{item.type}</Table.Cell>
                  <Table.Cell>{item.year}</Table.Cell>
                  <Table.Cell>{item.number}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <div className="flex flex-row justify-end items-center gap-4">
          {loading && (
            <div className="flex flex-row items-center gap-2">
              <Spinner size="sm" />
              <span>{progress}%</span>
            </div>
          )}
          <Button
            size="sm"
            onClick={insertAll}
            disabled={items.length == 0 || loading}
            gradientDuoTone="purpleToBlue"
          >
            Convert and Insert All
          </Button>
          <Button
            outline
            size="sm"
            onClick={convertAll}
            disabled={items.length == 0}
            gradientDuoTone="purpleToBlue"
          >
            Convert All
          </Button>
          {convertedList.length > 0 && (
            <div className="p-[6px] bg-plum rounded-md border-[1px] dark:border-gray-500">
              <CsvDownloadButton delimiter="," data={convertedList} />
            </div>
          )}
        </div>
        {errors.length > 0 && (
          <>
            <h2>Errors</h2>
            {errors.map((err, i) => (
              <div
                onClick={() => {
                  console.log(err.data);
                }}
                key={i}
              >
                {err.id} - {err.error}
              </div>
            ))}
          </>
        )}
      </section>
    </main>
  );
}
