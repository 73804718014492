import { Sidebar, Avatar, Tooltip } from "flowbite-react";
import SidebarItem from "./SidebarItem";
import { useLogOut } from "../../../store/authStore";
import { useAuthStore } from "../../../store/authStore";
import {
  ArrowLeftOnRectangleIcon,
  HomeIcon,
  QueueListIcon,
  ListBulletIcon,
  DevicePhoneMobileIcon,
  TruckIcon,
  ClockIcon,
  CubeIcon,
  RocketLaunchIcon,
  CalendarIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useNavStore } from "../../../store/navStore";
import logo from "../../../logo.png";
import checkAuth from "../../../utils/checkAuth";
import isNullish from "../../../utils/isNullish";
import { useQuery } from "@apollo/client";
import { GET_ORDER_TYPES } from "../workorder/gqls";

export default function Nav() {
  const { user } = useAuthStore();
  const noCheckin = user?.team?.type?.name == "dealer";

  const _logOut = useLogOut();

  const { show, folded, setFolded } = useNavStore();

  const navigate = useNavigate();

  const canSeeScheduleItem =
    checkAuth(["schedule_view_all", "schedule_view_team"]) ||
    !isNullish(user?.calId);

  const { data } = useQuery(GET_ORDER_TYPES);
  const orderTypes = data?.workorderType;

  const orderRouteName = () => {
    const filteredTypes = orderTypes?.filter(ot => {
      if (ot.permission == null) {
        return false;
      }
      return checkAuth(ot.permission);
    });

    if (filteredTypes?.length === 1) {
      return filteredTypes[0].name;
    }

    return "ProMeasure";
  };

  return (
    <nav
      className={`h-screen transition-[width] duration-300  ${
        !show ? "w-1" : folded ? "w-[62px]" : "w-[256px]"
      }`}
    >
      <Sidebar aria-label="Sidebar" id="MySidebar">
        {/* Logo */}
        <Tooltip content="minimize / expand menu">
          <Sidebar.Logo
            href="#"
            img={logo}
            imgAlt="logo"
            onClick={e => {
              e.preventDefault();
              setFolded(!folded);
            }}
            className="ml-[-4px] select-none animate-[pulse_7s_ease-in-out_infinite]"
          >
            {folded ? "" : "ezi-crm"}
          </Sidebar.Logo>
        </Tooltip>

        <Sidebar.Items className="select-none">
          {/* Divider */}

          <Sidebar.ItemGroup />
          {/* Profile */}
          <section
            className="cursor-pointer"
            onClick={() => {
              navigate("profile");
            }}
          >
            <Avatar
              img={user?.avatarUrl}
              rounded
              size={folded ? "sm" : "lg"}
              className={`${
                show ? (folded ? "w-10" : "w-full navH:hidden") : "w-0"
              } overflow-hidden transition-all duration-300`}
            />
            {/* Name */}
            {show && !folded && (
              <div>
                <div className="flex flex-row justify-center navH:justify-start navH:pl-1 items-center gap-2 mt-2">
                  {show && !folded && (
                    <Avatar
                      img={user?.avatarUrl}
                      rounded
                      size="sm"
                      className="hidden navH:block"
                    />
                  )}
                  <div className="text-center font-bold text-xl capitalize truncate ">
                    {user?.firstName + " " + user?.sirName}
                  </div>
                </div>
                <div className="text-center text-sm capitalize truncate text-plum navH:hidden">
                  {user?.auth?.name}
                </div>
              </div>
            )}
          </section>
          {/* Routes */}
          <Sidebar.ItemGroup>
            {user?.auth?.name !== "dealer" && (
              <SidebarItem
                name="Dashboard"
                icon={HomeIcon}
                route={["dashboard", "#"]}
              />
            )}
            {canSeeScheduleItem && (
              <SidebarItem
                name="Schedules"
                icon={CalendarIcon}
                route={["schedule"]}
              />
            )}
            <SidebarItem
              name="Contacts"
              icon={DevicePhoneMobileIcon}
              route={["contacts"]}
              auths={["contacts_access"]}
            />
            <SidebarItem
              name="Process"
              icon={QueueListIcon}
              route={["process"]}
              auths={["process_access_all", "process_access"]}
            />
            <SidebarItem
              name="Assignment"
              icon={RocketLaunchIcon}
              route={["assignment"]}
              auths={["assignment_access"]}
            />
            <SidebarItem
              name={orderRouteName()}
              icon={CubeIcon}
              route={["order"]}
              auths={["order_access"]}
            />
            <SidebarItem
              name="Production"
              icon={TruckIcon}
              route={["production"]}
              auths={["production_access"]}
            />
            <SidebarItem name="To Do" icon={ListBulletIcon} route={["todo"]} />
            {!noCheckin && (
              <SidebarItem
                name="Records"
                icon={ClockIcon}
                route={["records"]}
              />
            )}
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <SidebarItem
              name="Sign out"
              onClick={_logOut}
              icon={ArrowLeftOnRectangleIcon}
            />
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </nav>
  );
}
