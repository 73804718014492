import { vacancyList, vacancyType } from "../../../../../utils/calendarMethods";
import { UserType } from "../../../settings/users/types";
import { sort } from "fast-sort";
import getContrast from "../../../../../utils/getContrast";
import dayjs from "dayjs";
import { Avatar, Spinner } from "flowbite-react";
// @ts-expect-error
import md5 from "md5";

interface props {
  vacancies: vacancyList[];
  vacancy?: vacancyType;
  setVacancy: (vacancy?: vacancyType) => void;
  user: UserType;
  preference: boolean;
  loading: boolean;
}

export default function VacancyList({
  user,
  vacancy,
  vacancies,
  setVacancy,
  preference,
  loading,
}: props) {
  const vacancyList = vacancies.filter(v => v.user.id == user.id)[0]
    ? vacancies.filter(v => v.user.id == user.id)[0].vacancies
    : [];

  const sortParams = preference
    ? [
        { asc: (v: vacancyType) => v.start },
        { asc: (v: vacancyType) => v.efficiency },
      ]
    : [
        { asc: (v: vacancyType) => v.efficiency },
        { asc: (v: vacancyType) => v.start },
      ];

  const sortedVacancies = sort(vacancyList).by(sortParams);

  const avatarUrl = `https://www.gravatar.com/avatar/${md5(user.email)}?d=mp`;

  return (
    <div className="w-full flex flex-row gap-2">
      <div
        className="p2 ring-1 ring-gray-200 dark:ring-gray-600 flex flex-col justify-center items-center w-24 rounded-md"
        style={{
          backgroundColor: user.color || "#CCCCCC",
        }}
      >
        <Avatar img={avatarUrl} rounded className="mt-2" />
        <div
          className="capitalize  text-lg flex justify-center items-center  text-center font-bold "
          style={{
            color: getContrast(user.color || "#CCCCCC"),
          }}
        >
          {user.firstName}.{user.sirName[0]}
        </div>
      </div>
      <div className="flex-1 flex flex-row flex-wrap gap-2">
        {loading && (!vacancyList || vacancyList?.length < 1) && (
          <div className="flex flex-1 justify-center items-center text-center">
            <Spinner size="lg" color="purple" />
          </div>
        )}
        {sortedVacancies.map(v => (
          // Add qty of schedules per day?
          <div
            onClick={() => {
              if (vacancy?.id == v.id && vacancy.user.id == v.user.id) {
                setVacancy(undefined);
              } else {
                setVacancy(v);
              }
            }}
            className={`${
              vacancy?.id == v.id && vacancy.user.id == v.user.id
                ? "bg-plum text-white"
                : "bg-gray-100 dark:bg-gray-700"
            } p-2 ring-1 ring-gray-200 dark:ring-gray-600 flex-1 rounded-md flex flex-col hover:animate-pulse cursor-pointer`}
            key={v.id}
          >
            <div className="text-sm font-semibold w-max">
              {dayjs(v.date).format("ddd DD/MM")}
            </div>
            <div className="text-sm">
              {dayjs(v.start).format("H:mm")}~{dayjs(v.end).format("H:mm")}
            </div>
            <div className="flex flex-row justify-between gap-1 items-center">
              <div className="text-xs">
                🕓{v.efficiency ? (v.efficiency * 60).toFixed() : 0}m
              </div>
              <div className="text-xs">🍪{v.existings}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
