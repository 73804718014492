import {
  ArchiveBoxXMarkIcon,
  DocumentDuplicateIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import { Checkbox, Tooltip } from "flowbite-react";
import { memo, useEffect } from "react";
import {
  Controller,
  FieldValues,
  UseFieldArrayInsert,
  useFormContext,
  useWatch,
} from "react-hook-form";
import Options from "../options/options";
import MaterialCalcs from "./materialCalcs";
import { itemWarning, layoutCode, option } from "../../../types";
import { ProductSetType } from "../../../../types";
import uuid from "react-uuid";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_IMAGES_BY_PID } from "../../../../gqls";
import Calcs from "../calcs";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Price from "./price";
import QuoteOptions from "../quoteOptions";
import Note from "../note";
import Width from "./width";
import Height from "./height";

interface props {
  id: string;
  index: number;
  length: number;
  locationIndex: number;
  openingIndex: number;
  remove: (index: number) => void;
  addRow: () => void;
  insert: UseFieldArrayInsert<FieldValues, any>;
  product?: ProductSetType;
}

const Item = ({
  id,
  index,
  length,
  locationIndex,
  openingIndex,
  remove,
  addRow,
  product,
  insert,
}: props) => {
  const { register, control, setValue, getValues } = useFormContext();

  const openingCoord = `locations.${locationIndex}.openings.${openingIndex}`;
  const itemCoord = `locations.${locationIndex}.openings.${openingIndex}.items.${index}`;

  const itemId = getValues(`${itemCoord}.id`);

  const showCalcs: boolean = useWatch({
    name: "showCalcs",
    control,
    exact: true,
  });

  const showPrice: boolean = useWatch({
    name: "showPrice",
    control,
    exact: true,
  });

  const warnings: itemWarning[] = useWatch({
    name: `${itemCoord}.warnings`,
    control,
    exact: true,
  });

  const heightWarninsgMessages = warnings?.filter(w => w.option == "Height");
  const widthWarningMessages = warnings?.filter(w => w.option == "Width");

  const layoutCoord = `locations.${locationIndex}.openings.${openingIndex}.layoutCodes`;
  const layoutCodes: layoutCode[] = useWatch({
    name: layoutCoord,
    control,
    exact: true,
  });

  const myLayoutCode = layoutCodes?.find(l => l.scope.includes(itemId));
  const myCodeIndex = myLayoutCode?.scope.findIndex(s => s == itemId);
  const myCode = myLayoutCode?.layout.config.find(c => c.num == myCodeIndex);

  useEffect(() => {
    if (!myCode) {
      return;
    }
    for (const optionConfig of myCode.config) {
      const options: option[] = getValues(`${itemCoord}.options`);
      const optionIndex = options.findIndex(
        o => o.id === Number(optionConfig.option)
      );

      setValue(`${itemCoord}.options.${optionIndex}.value`, optionConfig.value);
    }
  }, [myCode]);

  const removeItem = () => {
    remove(index);
    if (myLayoutCode) {
      const newLayouts = layoutCodes.filter(l => !l.scope.includes(itemId));
      setValue(layoutCoord, newLayouts);
    }
  };

  const copyItem = () => {
    const item = getValues(itemCoord);
    insert(index, { ...item, index: undefined, id: uuid() });
  };

  const removeLayout = () => {
    const proceed = confirm("delete layout?");
    if (!proceed) {
      return;
    }
    const newLayouts = layoutCodes.filter(l => !l.scope.includes(itemId));
    setValue(layoutCoord, newLayouts);
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const sizeLimits = product?.sizeLimit;

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex flex-row gap-1 items-center group"
    >
      {length !== 1 && (
        // Row Id
        <div className="min-w-[40px] flex flex-row gap-1 items-start self-stretch">
          {/* Layout link indicator */}
          <div className="w-4 self-stretch flex flex-col">
            {/* Checkbox */}
            {!myLayoutCode && (
              <Checkbox
                {...register(`${itemCoord}.selected`)}
                className="checked:bg-plum dark:checked:bg-plum bg-gray-50 mt-[13px]"
              />
            )}
            {/* lines */}
            {myCodeIndex !== undefined && (
              <div
                className="relative group/line cursor-pointer h-full flex"
                onClick={removeLayout}
              >
                {myCodeIndex == 0 && (
                  <div className="flex flex-col">
                    <div className="h-[21px]" />
                    <div className="w-3 flex-1 self-end border-t-2 border-l-2 border-grass" />
                  </div>
                )}
                {myCodeIndex > 0 &&
                  myCodeIndex + 1 !== myLayoutCode?.scope.length && (
                    <div className="w-3 h-full border-l-2 border-grass" />
                  )}
                {myCodeIndex + 1 == myLayoutCode?.scope.length && (
                  <div className="w-3 h-[23px] border-b-2 border-l-2 border-grass" />
                )}
                <div className="hidden absolute group-hover/line:block top-1 -left-5">
                  <ArchiveBoxXMarkIcon className="w-4 text-red-500" />
                </div>
              </div>
            )}
          </div>
          {/* index */}
          <div className="text-grass font-semibold text-lg mt-2 ml-[2px]">
            {index + 1}
          </div>
        </div>
      )}
      <div className="flex flex-row flex-wrap relative">
        <div
          className="absolute w-[40px] left-0 h-full"
          {...attributes}
          {...listeners}
          tabIndex={-1}
        />
        <Controller
          control={control}
          name={`${itemCoord}.width`}
          render={({ field }) => (
            <Width
              field={field}
              sizeLimits={sizeLimits}
              warnings={widthWarningMessages}
            />
          )}
        />
        <Controller
          control={control}
          name={`${itemCoord}.height`}
          render={({ field }) => (
            <Height
              field={field}
              sizeLimits={sizeLimits}
              warnings={heightWarninsgMessages}
            />
          )}
        />
        <Options
          locationIndex={locationIndex}
          openingIndex={openingIndex}
          itemIndex={index}
          layoutFull={myLayoutCode}
          layout={myCode}
          warnings={warnings}
        />
        {showCalcs && (
          <Calcs
            locationIndex={locationIndex}
            openingIndex={openingIndex}
            itemIndex={index}
          />
        )}
        <QuoteOptions
          coord={itemCoord}
          showPrice={showPrice}
          productSetId={product?.id || 0}
        />
        <Note coord={itemCoord} />
        <Price
          openingCoord={openingCoord}
          itemCoord={itemCoord}
          showPrice={showPrice}
        />

        <div className="flex flex-row justify-center items-center ml-1 gap-1">
          {length === 1 && (
            <Tooltip content="add item">
              <PlusCircleIcon
                className="w-5 text-grass cursor-pointer"
                onClick={addRow}
              />
            </Tooltip>
          )}
          <Tooltip content="copy item">
            <DocumentDuplicateIcon
              className="w-5 text-plum cursor-pointer invisible group-hover:visible"
              onClick={copyItem}
            />
          </Tooltip>

          <Tooltip content="delete item">
            {length > 1 && (
              <MinusCircleIcon
                className="w-5 text-red-500 cursor-pointer invisible group-hover:visible"
                onClick={removeItem}
              />
            )}
          </Tooltip>
        </div>
      </div>
      <ItemImage itemCoord={itemCoord} productId={product?.id} />
      <MaterialCalcs itemCoord={itemCoord} />
    </div>
  );
};

interface itemImage {
  itemCoord: string;
  productId?: number;
}

const ItemImage = ({ itemCoord, productId }: itemImage) => {
  const { data: data_image } = useQuery(GET_PRODUCT_IMAGES_BY_PID, {
    variables: {
      id: productId,
    },
  });

  const { control, setValue } = useFormContext();
  const optionsCoord = `${itemCoord}.options`;
  const options: option[] = useWatch({
    name: optionsCoord,
    control,
  });

  const myImage = data_image?.productImages?.find(p => {
    const optionTest = p.options.every(o => {
      const where = o.option;
      const values = o.values?.map(v => v.toString());
      if (!where || !values) {
        return false;
      }
      return values.includes(
        options?.find(o => o.id == where)?.value?.toString()
      );
    });

    return optionTest;
  });

  useEffect(() => {
    setValue(`${itemCoord}.image`, myImage);
  }, [myImage]);

  return null;
};

export default memo(Item);
