import { useQuery } from "@apollo/client";
import { useOrderStore } from "../../../../../store/orderStore";
import { GET_QUOTE_PARAMS } from "../gqls";
import ProductInstallParams from "./productInstallParams";
import MinInstallCharge from "./minInstallCharge";
import MeasureCharge from "./measureCharge";
import CustomProductInstallParams from "./customProductInstallParams";

export default function InstallationPriceFactors() {
  const { productSets } = useOrderStore();
  const { data } = useQuery(GET_QUOTE_PARAMS);
  const params = data?.quoteParams;
  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {params && <MinInstallCharge params={params} />}
        {params && <MeasureCharge params={params} />}
      </div>
      {params && (
        <>
          {productSets.map(ps => (
            <ProductInstallParams key={ps.id} productSet={ps} params={params} />
          ))}
          <CustomProductInstallParams params={params} />
        </>
      )}
    </div>
  );
}
