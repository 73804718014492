import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  // onMessage,
  Messaging,
  // MessagePayload,
} from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgfByKYx2Y5HdVM0DIakCC-gmmRe_tw-Q",
  authDomain: "airy-ceremony-285205.firebaseapp.com",
  projectId: "airy-ceremony-285205",
  storageBucket: "airy-ceremony-285205.appspot.com",
  messagingSenderId: "180203686563",
  appId: "1:180203686563:web:4e077fad9af14f8a06cd16",
};

// Initialize Firebase
let firebaseApp;
let messaging: Messaging;

try {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
} catch (error) {
  console.log(error);
}

export const getFCMToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BOxJhzdCi5S0UN-c_xe6Rmf6PvoEJrKI6zfxCZIdaKqKXtzT2MG7H9Qa_cnTUrg8KYuDDNd-dVRgR_g67hMPhVQ",
    });
    console.log(token);
    return token;
  } catch (error) {
    console.log("token cannot be retreived", error);
    return undefined;
  }
};

export const getMyMessaging = () => {
  if (messaging) {
    return messaging;
  }
};
