import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Spinner, Textarea } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { processDetailSubProps } from "../types";
import { ProcessHistory } from "../types";
import { PencilIcon } from "@heroicons/react/24/solid";
import { addAlert } from "../../../../store/alertStore";

const resolver = z.object({
  description: z.string().optional(),
});

// TODO: CheckAuth, can edit status
export default function processDescription({
  process,
  baseHistory,
}: processDetailSubProps) {
  const { description } = process;
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const defaultValues = useCallback(
    () => ({
      description,
    }),
    [process]
  );

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: defaultValues(),
  });

  useEffect(() => {
    reset(defaultValues());
  }, [process]);

  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);

  const onSubmit = handleSubmit(async data => {
    const { description } = data;

    if (!isDirty) {
      addAlert({ message: "nothing changed", type: "warning" });
      return;
    }

    const history: ProcessHistory = {
      ...baseHistory,
      message: `description updated`,
      type: "detail",
    };

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(history)
      : [history];

    update_process({
      variables: {
        id: process.id,
        set: {
          description,
          histories,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update description",
          type: "failure",
        });
      },
      onCompleted() {
        reset({ description });
        toggleEditing();
      },
    });
  });

  const cancel = () => {
    reset();
    toggleEditing();
  };

  return (
    <form
      onSubmit={onSubmit}
      className="col-span-1 
      @md:col-span-2 supports-[not(container-type:inline-size)]:md:col-span-2
      @lg:col-span-1 supports-[not(container-type:inline-size)]:lg:col-span-1
      @xl:col-span-3 supports-[not(container-type:inline-size)]:xl:col-span-3
      @xl:order-last supports-[not(container-type:inline-size)]:xl:order-last
      @2xl:col-span-2 supports-[not(container-type:inline-size)]:2xl:col-span-2
      @2xl:order-none supports-[not(container-type:inline-size)]:2xl:order-none
      space-y-2 flex flex-col"
    >
      <div className="flex flex-row items-center gap-2 justify-between @md:justify-start">
        <h2>Description</h2>
        {!editing && (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 cursor-pointer hover:text-grass"
          />
        )}
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
        {editing ? (
          <Textarea
            {...register("description")}
            className="resize-none text-sm h-40"
          />
        ) : (
          <div className="whitespace-pre-wrap max-h-40 overflow-y-auto md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700 select-text">
            {description || "none"}
          </div>
        )}

        {editing && (
          <div className="flex justify-end flex-row items-center gap-2 mt-2">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              update
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
