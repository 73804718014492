import { useQuery } from "@apollo/client";
import { GET_QUOTE_PARAMS } from "../gqls";
import { useOrderStore } from "../../../../../store/orderStore";
import { Card, Tooltip } from "flowbite-react";
import ProductSetMarginControl from "./productSetMarginControl";
import DefaultMarginControl from "./defaultMarginControl";
import ProductSetSqmControl from "./productSetSqmControl";
import PricingFormula from "./pricingFormula";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

const compulsoryParamNames = ["margin", "sqmPrice", "formula"];

export default function BasePriceFactors() {
  const { productSets } = useOrderStore();

  const { data, loading } = useQuery(GET_QUOTE_PARAMS);
  const params = data?.quoteParams;

  const compulsoryParams =
    params?.filter(param => compulsoryParamNames.includes(param.name)) || [];

  const defaultMarginParam = compulsoryParams.find(
    p => p.name == "margin" && !p.productSetId
  );

  const defaultMargin = defaultMarginParam?.value || 0;

  if (loading) {
    return null;
  }

  return (
    <div
      className={`grid grid-cols-1
    @md:grid-cols-2 supports-[not(container-type:inline-size)]:md:grid-cols-2 
    gap-4 mt-2`}
    >
      <DefaultMarginControl defaultMarginParam={defaultMarginParam} />
      <Card className="col-span-1">
        <div className="flex flex-col">
          <h2>Per Product Margin (wholesale/retail)</h2>
          {productSets.map(ps => (
            <ProductSetMarginControl
              compulsoryParams={compulsoryParams}
              defaultMargin={defaultMargin}
              productSet={ps}
              key={ps.id}
            />
          ))}
        </div>
      </Card>
      <Card className="col-span-1">
        <div className="flex flex-col">
          <h2>Per Product Sqm Price (wholesale/retail)</h2>
          {productSets.map(ps => (
            <ProductSetSqmControl
              compulsoryParams={compulsoryParams}
              productSet={ps}
              key={ps.id}
            />
          ))}
        </div>
      </Card>
      <Card className="col-span-1">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <h2>Pricing Formula</h2>
            <Tooltip content="available : production, margin, sqm, sqmPrice, tablePrice">
              <InformationCircleIcon className="w-5 text-plum cursor-pointer" />
            </Tooltip>
          </div>
          {productSets.map(ps => (
            <PricingFormula
              compulsoryParams={compulsoryParams}
              productSet={ps}
              key={ps.id}
            />
          ))}
        </div>
      </Card>
    </div>
  );
}
