import { useAuthStore } from "../../../../store/authStore";
import { taskSchema, NewTaskType } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useMutation, gql, useQuery } from "@apollo/client";
import { TASK_CORE_FIELDS } from "./fragments";
import { Badge, Button, ListGroup, Spinner } from "flowbite-react";
import { GET_TASK_STATUSES } from "../../settings/tasks/taskStatuses/taskStatuses";
import { sort } from "fast-sort";
import { ClockIcon, UserIcon } from "@heroicons/react/24/solid";
import getContrast from "../../../../utils/getContrast";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import checkAuth from "../../../../utils/checkAuth";
import { GET_USERS } from "../../settings/users/gql";
import { push } from "../../../../utils/pushAPI";

const INSERT_TASK = gql`
  ${TASK_CORE_FIELDS}
  mutation INSERT_TASK(
    $processID: Int
    $description: String!
    $assignedTo: Int!
    $assignedBy: Int!
    $statusID: Int!
    $due: date
    $schedules: jsonb
  ) {
    insert_tasks_one(
      object: {
        processID: $processID
        description: $description
        assignedTo: $assignedTo
        assignedBy: $assignedBy
        statusID: $statusID
        due: $due
        schedules: $schedules
      }
    ) {
      ...TaskCoreFields
    }
  }
`;

interface props {
  processID?: number;
}

export default function AddTask({ processID }: props) {
  const { user } = useAuthStore();
  const userID = user?.id || 0;

  const ref = useRef<HTMLFormElement>(null);
  useOnClickOutside(ref, () => {
    cancel();
  });

  const defaultValues: NewTaskType = {
    processID,
    description: "",
    assignedTo: userID,
    assignedBy: userID,
    due: undefined,
    statusID: 0,
    schedules: [],
  };

  const { register, reset, handleSubmit, setValue, watch } = useForm({
    resolver: zodResolver(taskSchema),
    defaultValues,
  });
  const { data: data_users } = useQuery(GET_USERS);

  const users = data_users?.users.filter(
    u =>
      u.active &&
      checkAuth([
        {
          permission: "task_add_others",
          checkGroup: "userTeam",
          conditionGroup: [u.team?.id || -1],
        },
      ]) &&
      u.id !== userID
  );

  const { data } = useQuery(GET_TASK_STATUSES, {
    onCompleted(data) {
      const statuses = sort(data?.taskStatus).asc(s => s.priority);
      setValue("statusID", statuses[0].id);
    },
  });
  const statuses = data?.taskStatus
    ? sort(data?.taskStatus).asc(s => s.priority)
    : [];

  const status = statuses.find(s => s.id == watch("statusID"));

  const [insert_task, { loading }] = useMutation(INSERT_TASK);

  const onSubmit = handleSubmit(async data => {
    insert_task({
      variables: {
        ...data,
        due: data.due ? new Date(data.due) : null,
      },
      onCompleted() {
        reset({ ...defaultValues, statusID: data.statusID });
        setEditing(null);
        if (data.assignedBy !== data.assignedTo) {
          push({
            title: "New Task added",
            body: data.description,
            ids: [data.assignedTo],
          });
        }
      },
      update(cache, { data: { insert_tasks_one: newTask } }) {
        cache.modify({
          fields: {
            tasks(existing = []) {
              const newTaskRef = cache.writeFragment({
                data: newTask,
                fragment: TASK_CORE_FIELDS,
                fragmentName: "TaskCoreFields",
              });
              return [...existing, newTaskRef];
            },
          },
        });
      },
    });
  });

  const [editing, setEditing] = useState<null | string>(null);
  const toggleEditing = (str: string) => {
    if (editing == str) {
      setEditing(null);
    } else {
      setEditing(str);
    }
  };

  const changeStatus = (id: number) => {
    setValue("statusID", id, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
    setEditing(null);
  };

  const cancel = () => {
    setEditing(null);
  };

  return (
    <form
      ref={ref}
      className="w-full gap-2 flex flex-col shadow-md relative border-[1px] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 p-2 rounded-md"
      onSubmit={onSubmit}
    >
      <div className="flex flex-row gap-2 items-center justify-between">
        <div className="relative flex-1 flex flex-row gap-1 items-center">
          <ClockIcon
            className="w-5 cursor-pointer hover:text-plum"
            onClick={() => {
              toggleEditing("due");
            }}
          />
          {editing == "due" && (
            <div className="absolute left-7 mt-1 items-center flex flex-row gap-2 bg-white dark:bg-gray-800 p-2 rounded-md border-[1px] dark:border-gray-700 z-10">
              <input
                className="dark:bg-gray-500 rounded-md outline-none p-[5px] dark:border-none font-normal text-sm"
                type="date"
                {...register("due")}
                autoFocus
              />

              <Button onClick={cancel} size="xs" gradientDuoTone="purpleToBlue">
                OK
              </Button>
            </div>
          )}
          <input
            {...register("description")}
            placeholder="Enter new Task"
            className="flex-1 w-0 p-1 bg-transparent outline-none border-none focus:outline-none"
          />
        </div>
        <div className="relative">
          <Badge
            style={{
              backgroundColor: status?.color || "unset",
              color: status?.color ? getContrast(status?.color) : "unset",
            }}
            className="cursor-pointer"
            onClick={() => {
              toggleEditing("status");
            }}
          >
            {status?.name}
          </Badge>
          {editing == "status" && (
            <ListGroup className="absolute top-[100%] mt-2 right-0 z-10">
              {statuses?.map(status => (
                <ListGroup.Item
                  onClick={() => {
                    changeStatus(status.id);
                  }}
                  key={status.id}
                >
                  <Badge
                    style={{
                      backgroundColor: status.color,
                      color: getContrast(status.color),
                    }}
                  >
                    {status.name}
                  </Badge>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-end items-center">
        <UserIcon className="w-4 mr-2" />
        <select
          className="text-sm p-0 bg-transparent border-none outline-none capitalize"
          {...register("assignedTo")}
        >
          <option value={userID}>
            {user?.firstName} {user?.sirName}
          </option>
          {users &&
            sort(users)
              .asc(u => u.firstName.toLowerCase())
              ?.map(u => (
                <option
                  key={u.id}
                  value={u.id}
                  className="capitalize dark:bg-gray-700"
                >
                  {u.firstName} {u.sirName}
                </option>
              ))}
        </select>
        <Button type="submit" size="xs" outline gradientDuoTone="purpleToBlue">
          {loading && <Spinner size="xs" light className="mr-1" />}
          Add
        </Button>
      </div>
    </form>
  );
}
