import { z } from "zod";

type HEX = `#${string}`;

export const contactTypeSchema = z.object({
  id: z.coerce.number().optional(),
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  color: z.string(),
});

export interface NewContactTypeType {
  name: string;
  color: HEX;
}

export interface ContactTypeType extends NewContactTypeType {
  id: number;
}

export interface ContactTypeArrayType {
  contactType: ContactTypeType[];
}
