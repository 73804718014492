import { useMutation, useQuery, gql } from "@apollo/client";
import { AuthType } from "./types";
import { Badge, Button, Spinner, Textarea } from "flowbite-react";
import {
  PencilIcon,
  TrashIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { GET_PERMISSIONS } from "../permissions/permissions";
import { authSchema } from "./types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AUTH_CORE_FIELDS } from "./fragments";
import checkAuth from "../../../../../utils/checkAuth";

const UPDATE_AUTH = gql`
  ${AUTH_CORE_FIELDS}
  mutation UPDATE_AUTH(
    $id: Int!
    $name: String!
    $description: String!
    $permissions: jsonb
  ) {
    update_auths_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        description: $description
        permissions: $permissions
      }
    ) {
      ...AuthCoreFields
    }
  }
`;

const DELETE_AUTH = gql`
  mutation DELETE_AUTH($id: Int!) {
    delete_auths_by_pk(id: $id) {
      id
    }
  }
`;

export default function Role({ auth }: { auth: AuthType }) {
  const [update_auth, { loading, error }] = useMutation(UPDATE_AUTH);

  if (error) {
    console.log(error);
  }

  const [delete_auth, { loading: delete_loading, error: delete_error }] =
    useMutation(DELETE_AUTH);

  if (delete_error) {
    console.log(delete_error);
  }

  const {
    data,
    error: errorLoading,
    loading: permissionsLoading,
  } = useQuery(GET_PERMISSIONS);

  if (errorLoading) {
    console.log();
  }

  const [editing, setEditing] = useState(false);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(authSchema),
    defaultValues: {
      ...auth,
    },
  });

  const onSubmit = handleSubmit(async data => {
    update_auth({
      variables: { ...data, id: auth.id },
      onCompleted: () => {
        toggleEdit();
      },
    });
  });

  const deleteAuth = () => {
    const res = confirm(
      "you are trying to delete a role which may cause significant errors, are you sure?"
    );
    if (res) {
      delete_auth({
        variables: { id: auth.id },
        update(cache, { data: { delete_auths_by_pk: deletedAuth } }) {
          const normalizedId = cache.identify({
            id: deletedAuth.id,
            __typename: "auths",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    }
  };

  const cancel = () => {
    reset();
    toggleEdit();
  };

  const deletePermission = (id: number) => {
    const permissions = getValues("permissions")?.filter(p => p !== id);
    setValue("permissions", permissions);
  };

  const addPermission = (id: number) => {
    const permissions = getValues("permissions")?.concat(id);
    setValue("permissions", permissions);
  };

  const permissions = editing ? watch("permissions") : auth.permissions;

  const excludedPermissions = data?.permissions?.filter(
    p => !permissions?.includes(p.id)
  );

  return (
    <form
      onSubmit={onSubmit}
      className="rounded-md p-3 dark:bg-gray-700 bg-gray-100"
    >
      <div className="flex flex-row justify-between">
        <h3 className="capitalize">{auth.name}</h3>
        {checkAuth("setting_auth_edit") && (
          <>
            {!editing && (
              <PencilIcon className="w-4 cursor-pointer" onClick={toggleEdit} />
            )}
          </>
        )}
      </div>
      {editing ? (
        <Textarea
          {...register("description")}
          className="mt-2 mb-4"
          color={errors.description?.message ? "failure" : undefined}
          helperText={errors.description?.message || ""}
        />
      ) : (
        <p className="text-gray-400 mb-3">{auth.description}</p>
      )}

      <div className="flex flex-row flex-wrap gap-2 mb-2">
        {permissions && data?.permissions ? (
          permissions.map(ap => {
            const permission = data.permissions.find(p => p.id == ap);
            if (permission) {
              return (
                <div key={ap} className="relative">
                  <Badge color="purple">{permission.name}</Badge>
                  {editing && (
                    <MinusCircleIcon
                      onClick={() => {
                        deletePermission(permission.id);
                      }}
                      className="w-5 cursor-pointer text-red-500 absolute top-[-8px] right-[-8px]"
                    />
                  )}
                </div>
              );
            }
          })
        ) : (
          <>
            {permissionsLoading ? (
              <Spinner />
            ) : (
              <Badge>no innate permissions</Badge>
            )}
          </>
        )}
      </div>

      {editing && (
        <>
          {excludedPermissions && excludedPermissions.length > 0 && (
            <div className="flex flex-row flex-wrap p-3 mb-2 rounded-md border-[1px] border-gray-300 dark:border-gray-500 gap-2">
              {excludedPermissions.map(permission => (
                <div key={permission.id} className="relative">
                  <Badge>{permission.name}</Badge>

                  <PlusCircleIcon
                    onClick={() => {
                      addPermission(permission.id);
                    }}
                    className="w-5 cursor-pointer text-green-500 absolute top-[-8px] right-[-8px]"
                  />
                </div>
              ))}
            </div>
          )}

          <div className="flex flex-row justify-end gap-2">
            <TrashIcon
              className="w-5 text-red-500 cursor-pointer"
              onClick={deleteAuth}
            />
            <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
              Cancel
            </Button>
            <Button
              gradientDuoTone="purpleToBlue"
              outline
              size="sm"
              type="submit"
            >
              {loading && (
                <div className="mr-3">
                  <Spinner size="sm" light={true} />
                </div>
              )}
              Save
            </Button>
          </div>
        </>
      )}
    </form>
  );
}
