import {
  ADD_MATERIALS,
  DELETE_MATERIALS,
  GET_MATERIALS,
  GET_MATERIALS_CONDITIONAL,
  UPDATE_MATERIALS_BY_PK,
} from "../../materials/gqls";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  DocumentDuplicateIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  Badge,
  Button,
  Checkbox,
  FileInput,
  Label,
  Modal,
  Select,
  Table,
  TextInput,
} from "flowbite-react";
import { Fragment, useEffect, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { GET_PRODUCTS } from "../products/products";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  MaterialsType,
  materialSchema,
  newMaterialSchema,
} from "../../materials/types";
import { MATERIALS_CORE_FIELDS } from "../../materials/fragments";
import { sort } from "fast-sort";
import { useScheme } from "../../../../store/schemeStore";
import {
  ADD_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
  GET_DEDUCTIONS_CONDITIONAL,
  GET_DEDUCTION_PRESETS_CONDITIONAL,
  GET_PRODUCT_IMAGES,
  GET_PRODUCT_OPTIONS_CONDITIONAL,
  GET_PRODUCT_SETS,
  GET_PRODUCT_SET_BY_ID,
  GET_PRODUCT_SET_CONDITIONAL,
  UPDATE_PRODUCT_IMAGE_BY_PK,
} from "../../workorder/gqls";
import { createPortal } from "react-dom";
import {
  ProductImageType,
  newProductImageType,
  optionValues,
  productImageScheme,
} from "../../workorder/types";
import { PRODUCT_IMAGES_CORE_FIELDS } from "../../workorder/fragments";
import ValuePopulate from "../../workorder/ValuePopulate";
import getBase64 from "../../../../utils/getBase64";
import { useOrderStore } from "../../../../store/orderStore";

export default function ProductImages() {
  const { data: data_images, loading } = useQuery(GET_PRODUCT_IMAGES);

  const [filter, setFilter] = useState("");
  const productImages = data_images
    ? data_images.productImages.filter(
        image =>
          filter.trim() == "" ||
          `${image.desc} ${image.productSet.name}`
            .toLowerCase()
            .includes(filter.trim().toLocaleLowerCase())
      )
    : [];

  const [insert, { loading: inserting }] = useMutation(ADD_PRODUCT_IMAGE);

  const defaultValues: newProductImageType = {
    image: "",
    invert: true,
    options: [],
    productSetId: undefined,
    desc: "",
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(productImageScheme),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  // console.log(errors);

  const productSetId: number | undefined = useWatch({
    name: "productSetId",
    control,
  });

  const { data: productSet_data } = useQuery(GET_PRODUCT_SETS);

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const onSubmit = handleSubmit(async data => {
    insert({
      variables: {
        object: {
          ...data,
        },
      },
      onCompleted() {
        reset();
        setAdding(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            productImages(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_productImages_one,
                fragment: PRODUCT_IMAGES_CORE_FIELDS,
                fragmentName: "ProductImagesCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const cancel = () => {
    reset();
    setAdding(!adding);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-end items-center gap-2">
        <TextInput
          onChange={e => setFilter(e.target.value)}
          value={filter}
          placeholder="search"
          type="search"
          name="searchMaterial"
          autoComplete="off"
          icon={MagnifyingGlassIcon}
          sizing="sm"
        />
        {!adding && (
          <Button
            gradientDuoTone="purpleToBlue"
            outline
            size="xs"
            onClick={toggleAdding}
          >
            new
          </Button>
        )}
      </div>
      {adding && (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className="flex flex-col mb-2 gap-2">
            <div className="flex flex-row flex-wrap gap-2 items-center">
              <Select {...register("productSetId")} sizing="sm">
                <option value={""}>Select product</option>
                {productSet_data?.productSets.map(p => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </Select>
              <TextInput {...register("desc")} sizing="sm" placeholder="Desc" />
              <div className="flex flex-row items-center gap-2">
                <Label value="Invert On Dark?" />
                <Checkbox
                  className="checked:bg-plum dark:checked:bg-plum"
                  {...register("invert")}
                />
              </div>
            </div>
            <hr />
            {productSetId && (
              <>
                <h4 className="font-semibold dark:text-white">Image</h4>
                <Image />
                <hr />
                <h4 className="font-semibold dark:text-white">
                  Option Conditions
                </h4>
                <Parent productSetID={productSetId} />
                <hr />
              </>
            )}

            <div className="flex flex-row justify-end items-center gap-2">
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                outline
                type="submit"
              >
                Add
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      <Table striped className="select-text">
        <Table.Head>
          <Table.HeadCell className="px-2">id</Table.HeadCell>
          <Table.HeadCell className="px-2">product</Table.HeadCell>
          <Table.HeadCell className="px-2">desc</Table.HeadCell>
          <Table.HeadCell className="px-2">image</Table.HeadCell>
          <Table.HeadCell className="px-2">invert</Table.HeadCell>
          <Table.HeadCell className="px-2">option conditions</Table.HeadCell>
          <Table.HeadCell className="p-0" />
        </Table.Head>
        <Table.Body className="text-black dark:text-white">
          {productImages &&
            sort(productImages)
              .by([{ asc: d => d.productSet.name }, { asc: d => d.desc }])
              .map(item => <ProductImage key={item.id} item={item} />)}
        </Table.Body>
      </Table>
    </div>
  );
}

interface productImage {
  item: ProductImageType;
}

const ProductImage = ({ item }: productImage) => {
  const [update, { loading: updating }] = useMutation(
    UPDATE_PRODUCT_IMAGE_BY_PK
  );
  const { scheme } = useScheme();

  const defaultValues: newProductImageType = { ...item };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(productImageScheme),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, isDirty },
  } = methods;

  // if (!isValid && errors) {
  //   console.log(errors);
  // }

  const [remove, { loading: deleting }] = useMutation(DELETE_PRODUCT_IMAGE);

  const _delete = (id: number) => {
    const proceed = confirm("delete product image?");
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: "productImages",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const productSetId: number | undefined = useWatch({
    name: "productSetId",
    control,
  });

  const { data: productSet_data } = useQuery(GET_PRODUCT_SET_BY_ID, {
    variables: {
      id: productSetId,
    },
  });

  const productSetOptions =
    productSet_data?.productSets_by_pk?.productSetOptions;

  const optionConditions = item.options && sort(item.options).asc("option");

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const [insert, { loading: inserting }] = useMutation(ADD_PRODUCT_IMAGE);

  const copy = handleSubmit(data => {
    const proceed = confirm(
      `copy current material${isDirty ? " with modified values" : ""}?`
    );
    if (!proceed) {
      return;
    }

    insert({
      variables: {
        object: {
          ...data,
          id: undefined,
          productSet: undefined,
        },
      },
      onCompleted() {
        reset();
        setEditing(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            productImages(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_productImages_one,
                fragment: PRODUCT_IMAGES_CORE_FIELDS,
                fragmentName: "ProductImagesCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const save = handleSubmit(data => {
    update({
      variables: {
        id: item.id,
        set: {
          ...data,
          productSet: undefined,
        },
      },
      onCompleted() {
        setEditing(false);
      },
    });
  });

  const cancelEdit = () => {
    reset();
    setEditing(!editing);
  };

  const [preview, setPreview] = useState(false);
  const togglePreview = () => {
    setPreview(!preview);
  };

  return (
    <FormProvider {...methods}>
      <Table.Row key={item.id}>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500 w-4">
          {item.id}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.productSet?.name}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.desc}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          <div
            className="flex flex-row justify-center cursor-pointer"
            onClick={togglePreview}
          >
            <img
              className={`transition-all w-20 ${
                item.invert ? "dark:invert" : ""
              }`}
              src={item.image}
            />
          </div>
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          <Checkbox
            className="checked:bg-plum dark:checked:bg-plum"
            checked={item.invert}
            readOnly
          />
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          <div className="flex flex-row gap-2 w-full flex-wrap">
            {item.options?.map(option => (
              <Badge key={option.option} color="purple">
                <div className="flex flex-row gap-1 items-center">
                  {productSetOptions?.find(o => o.id == option.option)?.name}
                  {option.values && (
                    <>
                      :
                      {option.values?.map(val => (
                        <Fragment key={val}>
                          <ValuePopulate
                            source={
                              productSetOptions?.find(
                                o => o.id == option.option
                              )?.source
                            }
                            value={val}
                          />
                          ,
                        </Fragment>
                      ))}
                    </>
                  )}
                </div>
              </Badge>
            ))}
          </div>
        </Table.Cell>

        <Table.Cell className="py-[5px] w-10">
          <div className="flex flex-row gap-3 w-full">
            <PencilIcon
              onClick={toggleEditing}
              className="w-5 text-grass cursor-pointer"
            />
            <DocumentDuplicateIcon
              onClick={copy}
              className="w-5 text-plum cursor-pointer"
            />
          </div>
        </Table.Cell>
      </Table.Row>
      {/* Edit Deduction */}
      {editing && (
        <>
          {createPortal(
            <div
              className={`${scheme} absolute w-screen h-screen top-0 left-0 p-10 backdrop-blur-xl flex flex-col gap-2 overflow-x-hidden`}
            >
              <h3>Edit Product Image</h3>
              <div className="flex flex-row flex-wrap gap-2 items-center">
                <Select disabled sizing="sm">
                  <option value={""}>{item.productSet.name}</option>
                </Select>
                <TextInput
                  {...register("desc")}
                  sizing="sm"
                  placeholder="Desc"
                />

                <div className="flex flex-row items-center gap-2">
                  <Label value="Invert On Dark?" />
                  <Checkbox
                    className="checked:bg-plum dark:checked:bg-plum"
                    {...register("invert")}
                  />
                </div>
              </div>
              <hr />
              {productSetId && (
                <>
                  <h4 className="font-semibold dark:text-white">Image</h4>
                  <Image />
                  <hr />
                  <h4 className="font-semibold dark:text-white">
                    Option Conditions
                  </h4>
                  <Parent productSetID={productSetId} />
                  <hr />
                </>
              )}

              <hr />
              <div className="flex flex-row justify-between items-center gap-2">
                <Button
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                  outline
                  onClick={copy}
                >
                  Copy
                </Button>
                <div className="flex flex-row items-center gap-2">
                  <TrashIcon
                    onClick={() => {
                      _delete(item.id);
                    }}
                    className="w-5 text-red-500 cursor-pointer"
                  />
                  <Button size="xs" color="purple" onClick={cancelEdit}>
                    cancel
                  </Button>
                  <Button
                    size="xs"
                    gradientDuoTone="purpleToBlue"
                    outline
                    onClick={save}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>,
            document.body
          )}
        </>
      )}
      <Modal
        show={preview}
        onClose={() => {
          setPreview(false);
        }}
        className={`${scheme}`}
      >
        <Modal.Header>Image Preview</Modal.Header>
        <Modal.Body>
          <div className="flex flex-row justify-center">
            <img
              src={item.image}
              className={`transition-all w-96 ${
                item.invert ? "dark:invert" : ""
              }`}
            />
          </div>
        </Modal.Body>
      </Modal>
    </FormProvider>
  );
};

const Image = () => {
  const {
    control,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useFormContext();

  const imageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors("image");

    const files = event.target.files;

    if (files) {
      const file = files[0];
      if (!file) {
        return;
      }
      const { size } = file;

      if (size / 1000 > 50) {
        setError("image", { type: "custom", message: "file size too big" });
        event.target.value = "";
        return;
      }

      const base64 = (await getBase64(file)) as string;
      setValue("image", base64);
    }
  };

  const image = useWatch({
    name: "image",
    control,
  });

  const clear = () => {
    setValue("image", null);
  };

  return (
    <div className="flex flex-col gap-2">
      {image && (
        <div className="p-10 flex flex-row justify-center relative">
          <img src={image} className="w-100 max-w-full dark:invert" />
          <TrashIcon
            className="absolute w-5 text-red-500 cursor-pointer right-0 top-0"
            onClick={clear}
          />
        </div>
      )}
      <div>
        <FileInput
          id="image"
          accept="image/*"
          color={errors.image?.message ? "failure" : undefined}
          helperText={
            errors.image?.message?.toString() ||
            "upload product image less than 50kb size"
          }
          className="mt-2 text-sm"
          onChange={imageHandler}
        />
      </div>
    </div>
  );
};

interface config {
  productSetID: number;
}

const Parent = ({ productSetID }: config) => {
  const { scheme } = useScheme();
  const { control } = useFormContext();
  const { append, remove } = useFieldArray({
    name: "options",
    control,
  });
  const addedOptions: optionValues[] = useWatch({
    name: "options",
    control,
  });

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: Number(productSetID) },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const defaultValues: optionValues = {
    option: 0,
    type: "",
    values: [],
  };

  const [newParentOption, setNewParentOption] = useState(defaultValues);

  const optionTypes = ["select"];
  const options = data_options
    ? sort(data_options.productSetOptions)
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const nullishValues = ["", null, 0, undefined];

  const selectedOption = options?.find(o => o.id == newParentOption.option);
  const [selectables, setSelectables] = useState<any[]>([]);

  const { presets, deductions, materials } = useOrderStore();

  useEffect(() => {
    if (selectedOption?.source == "deductionPreset") {
      const _presets = presets.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_presets);
    }
    if (selectedOption?.source == "deductions") {
      const _deductions = deductions.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_deductions);
    }
    if (selectedOption?.source == "inventory") {
      const _materials = materials.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_materials);
    }
    if (!selectedOption?.source && selectedOption?.type == "select") {
      // @ts-ignore
      setSelectables(selectedOption.values);
    }
  }, [selectedOption]);

  const add = () => {
    if (addedOptions.find(c => c.option == newParentOption.option)) {
      return;
    }

    append(newParentOption);
    setNewParentOption(defaultValues);
  };

  const removeValue = (val: number | string) => {
    setNewParentOption({
      ...newParentOption,
      values: newParentOption.values.filter(c => c !== val),
    });
  };

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Config */}
      <div className="flex flex-row gap-2 items-center">
        <Select
          addon="Option"
          sizing="sm"
          disabled={newParentOption.values.length > 0 ? true : false}
          value={newParentOption.option?.toString()}
          onChange={e => {
            setNewParentOption({
              ...newParentOption,
              option: Number(e.target.value),
            });
          }}
        >
          <option value="">select option</option>
          {options
            ?.filter(
              o =>
                !addedOptions.find(c => c.option.toString() == o.id.toString())
            )
            .map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </Select>

        {selectedOption && selectedOption.type == "select" && (
          <Select
            addon="Value"
            sizing="sm"
            onChange={e => {
              if (nullishValues.includes(e.target.value)) {
                return;
              }
              setNewParentOption({
                ...newParentOption,
                values: newParentOption.values.concat(e.target.value),
              });
            }}
          >
            <option value="">select option</option>
            {selectables
              ?.filter(
                option =>
                  !newParentOption.values.includes(
                    option?.id?.toString() || option.toString()
                  )
              )
              .map((option, i) => (
                <option key={option.id || i} value={option.id || option}>
                  {selectedOption.source == "deductionPreset"
                    ? `${option.desc} `
                    : ""}
                  {option.name || option}
                </option>
              ))}
          </Select>
        )}
      </div>
      <div className="flex flex-row flex-wrap items-center gap-2 justify-between">
        <div className="flex flex-row flex-wrap gap-2 items-center">
          {newParentOption.values.map((c, i) => {
            const option = options.find(o => o.id == newParentOption.option);
            const source = option?.source;
            const value = c;
            return (
              <Badge
                key={c}
                color="purple"
                className="cursor-pointer"
                onClick={() => {
                  removeValue(c);
                }}
              >
                <div className="flex flex-row gap-1 items-center">
                  <ValuePopulate
                    showDesc={option?.source == "deductionPreset"}
                    source={source}
                    value={value}
                  />
                  <XMarkIcon className="w-4" />
                </div>
              </Badge>
            );
          })}
        </div>

        <Button onClick={add} outline size="xs" gradientDuoTone="greenToBlue">
          add condition
        </Button>
      </div>
      {/* Added Parent Options */}
      {addedOptions.length > 0 && (
        <div className="flex flex-col dark:text-white">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-t-md flex flex-row gap-2 items-center">
            <div className="px-2 py-[5px] w-20">Option</div>
            <div className="px-2 py-[5px] flex-1">Values</div>
            <div className="px-2 py-[5px] w-10" />
          </div>
          {addedOptions.map((item, index) => {
            const option = options.find(o => o.id == item.option);
            const source = option?.source;
            return (
              <div
                key={item.option}
                className="rounded-md flex flex-row gap-2 items-center"
              >
                <div className="px-2 py-[5px] w-20 overflow-clip">
                  {options.find(o => o.id == item.option)?.name}
                </div>

                <div className="px-2 py-[5px] flex-1 flex flex-row gap-2 justify-start">
                  {item.values?.map(value => (
                    <Badge
                      key={value}
                      color="purple"
                      className="cursor-pointer"
                    >
                      <ValuePopulate
                        showDesc={option?.source == "deductionPreset"}
                        source={source}
                        value={value}
                      />
                    </Badge>
                  ))}
                </div>

                <div className="px-2 py-[5px] w-10 flex flex-row gap-2">
                  <MinusCircleIcon
                    className="text-red-500 w-5 cursor-pointer"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
