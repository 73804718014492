import { Avatar, Button, Modal, Tooltip } from "flowbite-react";
import { useState } from "react";
import { useScheme } from "../../../../../store/schemeStore";
import { UserType } from "../types";
// @ts-expect-error
import md5 from "md5";

export default function NameAvatar({ user }: { user: UserType }) {
  const [show, setShow] = useState(false);
  const { scheme } = useScheme();

  const toggle = () => {
    setShow(!show);
  };

  let avatarUrl = `https://www.gravatar.com/avatar/${md5(user.email)}?d=mp`;

  return (
    <>
      <Tooltip content="click to see instruction to change profile photo">
        <Avatar
          img={avatarUrl}
          rounded
          size="lg"
          onClick={toggle}
          className="cursor-pointer"
        />
      </Tooltip>
      <div>
        <div className="text-center font-bold text-4xl mt-2 capitalize truncate">
          {user?.firstName + " " + user?.sirName}
        </div>
      </div>
      <Modal show={show} onClose={toggle} className={`${scheme}`}>
        <Modal.Header>Changing your profile photo</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              The CRM App does not directly save your profile photo in the
              server for memory optimization.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              If you'd like to have your own profile photo, go to{" "}
              <a className="text-blue-500" href="https://en.gravatar.com">
                gravatar website
              </a>{" "}
              and sign up using the identical email address used for the CRM
              then upload your profile there.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Once done, refresh the CRM by pressing F5 key and you should see
              your avatar being reflected.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button gradientDuoTone="purpleToBlue" size="sm" onClick={toggle}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
