import { z } from "zod";

type HEX = `#${string}`;

export const tagsSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
});

export interface NewTagType {
  name: string;
}

export interface TagType extends NewTagType {
  id: number;
}

export interface TagArrayType {
  tags: TagType[];
}
