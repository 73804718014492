import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import OpeningOption from "./openingOption";
import { option } from "../../../types";
import { memo, useEffect } from "react";
import { sort } from "fast-sort";
import { useOrderStore } from "../../../../../../../store/orderStore";

interface props {
  locationIndex: number;
  openingIndex: number;
}

const OpeningOptions = ({ locationIndex, openingIndex }: props) => {
  const { control, getValues } = useFormContext();

  const optionsCoordForFields = `locations.${locationIndex}.openings.${openingIndex}.options`;

  const { fields, replace } = useFieldArray({
    control,
    name: optionsCoordForFields,
  });

  const productId = useWatch({
    control,
    name: `locations.${locationIndex}.openings.${openingIndex}.product`,
    exact: true,
  });

  const { productSetOptions } = useOrderStore();

  useEffect(() => {
    const options = productSetOptions.filter(
      po => po.productSetID == productId
    );
    const newOptions = options?.map(o => ({
      ...o,
      value: o.value || o.default || "",
    }));

    const currentOpeningOptions: option[] = getValues(
      `locations.${locationIndex}.openings.${openingIndex}.options`
    );

    const check = currentOpeningOptions.map(opt =>
      newOptions?.find(o => o.name == opt.name)
    );

    if (check.includes(undefined) || check.length == 0) {
      replace(sort(newOptions).asc("position"));
    }
  }, [productSetOptions]);

  return (
    <>
      {fields.map((item, index) => {
        return (
          <OpeningOption
            key={item.id}
            locationIndex={locationIndex}
            openingIndex={openingIndex}
            index={index}
          />
        );
      })}
    </>
  );
};

export default memo(OpeningOptions);
