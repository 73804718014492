import { Button, Spinner } from "flowbite-react";
import { QUOTE_PARAMS_FIELDS } from "../fragments";
import { ProductSetType } from "../../../workorder/types";
import { QuoteParams } from "../types";
import { ADD_QUOTE_PARAM, UPDATE_QUOTE_PARAM_BY_PK } from "../gqls";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { coerceNumber } from "../../../process/types";

const marginScheme = z.object({
  margin: z.object({
    wholesale: coerceNumber.optional(),
    retail: coerceNumber.optional(),
  }),
});

interface props {
  productSet: ProductSetType;
  compulsoryParams: QuoteParams[];
  defaultMargin: number;
}

export default function ProductSetMarginControl({
  productSet,
  compulsoryParams,
  defaultMargin,
}: props) {
  const existingParam = compulsoryParams.find(
    p => p.name == "margin" && p.productSetId == productSet.id
  );

  const margin = {
    wholesale: existingParam?.value?.wholesale || 0,
    retail: existingParam?.value?.retail || 0,
  };

  const defaultValues = {
    margin,
  };

  const [update, { loading: updating }] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);
  const [insert, { loading: inserting }] = useMutation(ADD_QUOTE_PARAM);

  const loading = updating || inserting;

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: zodResolver(marginScheme),
  });

  const onSubmit = handleSubmit(data => {
    if (existingParam) {
      update({
        variables: {
          id: existingParam.id,
          set: {
            value: data.margin,
          },
        },
        onCompleted() {
          reset({
            margin: data.margin,
          });
        },
      });
    } else {
      insert({
        variables: {
          object: {
            name: "margin",
            value: data.margin,
            productSetId: productSet.id,
          },
        },
        onCompleted() {
          reset({
            margin: data.margin,
          });
        },
        update(cache, { data: { insert_quoteParams_one: newParam } }) {
          cache.modify({
            fields: {
              quoteParams(existing = []) {
                const newParamRef = cache.writeFragment({
                  data: newParam,
                  fragment: QUOTE_PARAMS_FIELDS,
                  fragmentName: "QuoteParamsFields",
                });
                return [...existing, newParamRef];
              },
            },
          });
        },
      });
    }
  });

  const cancel = () => {
    reset({
      margin,
    });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="p-2 border-b-[1px] flex flex-row items-center justify-between"
    >
      <h3>{productSet.name}</h3>
      <div className="flex flex-row items-center gap-1">
        <input
          type="number"
          className="p-1 text-plum text-lg font-semibold bg-transparent border-none w-12 text-center outline-none"
          placeholder="0"
          {...register("margin.wholesale")}
        />
        /
        <input
          type="number"
          className="p-1 text-plum text-lg font-semibold bg-transparent border-none w-12 text-center outline-none"
          placeholder="0"
          {...register("margin.retail")}
        />
        %
        {isDirty && (
          <div className="flex flex-row items-center gap-1">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone={"purpleToBlue"}
              type="submit"
            >
              update
              {loading && <Spinner size="xs" className="ml-1" color="purple" />}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
