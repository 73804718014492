import { z } from "zod";
import { ContactTypeType } from "../settings/contact/contactTypes/types";
import { coerceNumber } from "../process/types";
import { optionalEmail } from "../process/types";

export const contactSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  typeID: coerceNumber,
  number: z.string().optional(),
  address: z.union([z.string().optional(), z.null()]),
  mail: optionalEmail,
  description: z.string().optional(),
  role: z.string().optional(),
  isOrganisation: z.boolean().optional(),
  organisationID: z.number().optional(),
  organisation: z.any(),
});

export interface ContactProductType {
  productId: number;
  qty: number;
}

export interface NewContactType {
  name: string;
  typeID: number;
  number?: string;
  address?: string;
  mail?: string;
  description?: string;
  role?: string;
  isOrganisation?: boolean;
  organisationID?: number;
  organisation?: ContactType;
}

export interface ContactType
  extends Omit<NewContactType, "typeID" | "organisationID"> {
  user: {
    id: number;
    firstName: string;
    sirName: string;
  };
  id: number;
  contactType: ContactTypeType;
  organisation?: ContactType;
  deleted: boolean;
  updated_at: string;
}

export interface ContactArrayType {
  contacts: ContactType[];
}

export interface ContactStreamType {
  contacts_stream: ContactType[];
}

export interface ContactFilter {
  name: string;
  where: string;
  type: "in" | "range" | "contains";
  rangeType?: "date" | "number";
  defaultValues: any;
  values: any;
  options?: {
    name: string;
    value: any;
  }[];
}

export interface contactQueryParams {
  where: {
    _and: any[];
  };
  offset: number;
}
