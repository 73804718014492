import { gql } from "@apollo/client";

export const PRODUCT_CORE_FIELDS = gql`
  fragment ProductCoreFields on products {
    id
    name
    description
    image
  }
`;
