import {
  FlagIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import {
  Button,
  Label,
  ListGroup,
  Select,
  Spinner,
  TextInput,
  ToggleSwitch,
} from "flowbite-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { gql, useLazyQuery, TypedDocumentNode } from "@apollo/client";
import { useDebounce } from "usehooks-ts";
import { ContactTypeType } from "../../settings/contact/contactTypes/types";
import { CONTACT_CORE_FIELDS } from "../../contact/fragments";
import { ContactType } from "../../contact/types";
import { geocode, revGeocode } from "../../../../utils/geocode";
import { MarkerProp } from "../types";

interface SearchContactType {
  search_contacts: ContactType[];
}

export const SEARCH_CONTACT: TypedDocumentNode<SearchContactType> = gql`
  ${CONTACT_CORE_FIELDS}
  query SEARCH_CONTACT($Str: String!) {
    search_contacts(args: { search: $Str }) {
      ...ContactCoreFields
    }
  }
`;

interface props {
  contactTypes: ContactTypeType[];
  setMarkers: Dispatch<SetStateAction<MarkerProp[]>>;
}

export default function Contact({ contactTypes, setMarkers }: props) {
  const {
    register,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  const [makingNewContact, setMakingNewContact] = useState(false);
  const toggleNewContact = () => {
    if (makingNewContact) {
      setValue("newContact", undefined);
    }
    setMakingNewContact(!makingNewContact);
  };

  const [searchStr, setSearchStr] = useState("");
  const debouncedStr = useDebounce<string>(searchStr, 500);

  const [search_contact, { loading: search_loading, data: search_data }] =
    useLazyQuery(SEARCH_CONTACT, { fetchPolicy: "no-cache" });

  interface contact {
    id: number;
    name: string;
    number?: string;
    email?: string;
    address?: string;
  }

  const contacts: contact[] = watch("contacts", []);
  const customerName = watch("name");
  const customerAddress = watch("address");

  const search_results = search_data
    ? search_data.search_contacts.filter(
        c => !contacts.find(ct => ct.id == c.id)
      )
    : false;
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (debouncedStr.trim() !== "") {
      setSearching(true);
      search_contact({
        variables: { Str: debouncedStr },
      });
    } else {
      setSearching(false);
    }
  }, [debouncedStr]);

  const addContact = (contact: contact) => {
    const newContacts = contacts.concat(contact);
    setValue("contacts", newContacts);
  };

  const deleteContact = (id: number) => {
    const newContacts = contacts.filter(c => c.id !== id);
    setValue("contacts", newContacts);
  };

  const copyCustomerInfo = () => {
    const [name, address] = getValues(["name", "address"]);
    setValue("newContact.name", name);
    setValue("newContact.address", address);
  };

  const contactClick = async (contact: contact) => {
    addContact(contact);
    trigger("contacts");
    setValue("returning", true);
    if (!customerName) {
      setValue("name", contact.name);
    }
    if (!customerAddress && contact.address) {
      setValue("address", contact.address);
      const loc = await geocode(contact.address);
      if (!loc) {
        return;
      }
      setMarkers(state =>
        state
          .filter(s => s.label?.toLowerCase() !== "customer")
          .concat({
            label: "Customer",
            position: loc,
            icon: <FlagIcon className="w-6" />,
          })
      );
      const convertedAddress = await revGeocode(loc);
      if (convertedAddress && convertedAddress[0]) {
        const postCode = convertedAddress[0].address?.postalCode;
        if (postCode) {
          setValue("postCode", postCode);
        }
      }
    }
  };

  return (
    <section className="col-span-1 row-span-2 space-y-2 flex flex-col z-20">
      <h2>Contacts</h2>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-2 flex-1">
        {/* Search existing contacts */}
        <div className="space-y-2">
          <Label value="Search" />
          <div className="relative">
            <TextInput
              type="text"
              icon={MagnifyingGlassIcon}
              placeholder="Search existing contacts"
              sizing="sm"
              value={searchStr}
              onChange={e => {
                setSearchStr(e.target.value);
              }}
            />
            {search_loading && (
              <Spinner
                className="absolute m-auto top-0 bottom-0 right-2"
                size="sm"
              />
            )}
          </div>
          {searching && search_results && (
            <div className="space-y-2">
              <Label value="Search Results" />
              {search_results.length < 1 && (
                <div className="text-sm">no more matching results</div>
              )}
              <ListGroup>
                {search_results.map(result => (
                  <ListGroup.Item
                    key={result.id}
                    onClick={() => {
                      contactClick(result);
                    }}
                  >
                    <div className="flex flex-row items-center justify-between w-full">
                      <div className="truncate flex-1 w-0 text-left">
                        {result.name}
                        {result.number && `- ${result.number}`}
                      </div>

                      <PlusCircleIcon className="w-5" />
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <div className="flex justify-end">
                <Button
                  size="sm"
                  gradientDuoTone="purpleToBlue"
                  outline
                  onClick={() => {
                    setSearching(false);
                    setSearchStr("");
                  }}
                >
                  clear
                </Button>
              </div>
            </div>
          )}
        </div>

        {contacts && contacts.length > 0 && (
          <div className="space-y-2">
            <Label value="Added" />
            <ListGroup>
              {contacts.map(contact => (
                <ListGroup.Item
                  key={contact.id}
                  onClick={() => {
                    deleteContact(contact.id);
                  }}
                >
                  <div className="flex flex-row items-center justify-between w-full">
                    <div className="truncate flex-1 w-0 text-left">
                      {contact.name} {contact.number && `- ${contact.number}`}
                    </div>
                    <MinusCircleIcon className="w-5" />
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        )}

        <div className="flex flex-row justify-end pt-2">
          <ToggleSwitch
            checked={makingNewContact}
            label="Add new contact"
            onChange={toggleNewContact}
            //@ts-expect-error
            color="purple"
          />
        </div>

        {makingNewContact && (
          <div className="space-y-2">
            <hr className="border-gray-500" />
            <div className="space-y-2">
              <Label value="Type" />
              <Select {...register("newContact.typeID")} sizing="sm">
                {contactTypes.map(ct => (
                  <option key={ct.id} value={ct.id}>
                    {ct.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="space-y-2">
              <Label value="Name" />
              <TextInput
                type="text"
                placeholder="Enter Name"
                sizing="sm"
                {...register("newContact.name")}
                onBlur={() => {
                  trigger("contacts");
                }}
                color={errors.newContact?.message ? "failure" : undefined}
              />
            </div>
            <div className="space-y-2">
              <Label value="Number" />
              <TextInput
                type="text"
                placeholder="Enter Number"
                sizing="sm"
                {...register("newContact.number")}
                color={errors.newContact?.message ? "failure" : undefined}
              />
            </div>
            <div className="space-y-2">
              <Label value="Email" />
              <TextInput
                type="text"
                placeholder="Enter Email"
                sizing="sm"
                {...register("newContact.mail")}
                color={errors.newContact?.message ? "failure" : undefined}
              />
            </div>
            <div className="space-y-2">
              <Label value="Address" />
              <TextInput
                type="text"
                placeholder="Enter Address"
                sizing="sm"
                {...register("newContact.address")}
                color={errors.newContact?.message ? "failure" : undefined}
              />
            </div>

            <div className="flex justify-end pt-2">
              <Button
                outline
                gradientDuoTone="purpleToBlue"
                size="sm"
                onClick={copyCustomerInfo}
              >
                Copy Customer Info
              </Button>
            </div>
          </div>
        )}

        <div className="text-sm text-red-500">
          {errors.contacts?.message?.toString() || ""}
        </div>
      </div>
    </section>
  );
}
