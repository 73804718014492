import { HomeIcon } from "@heroicons/react/24/solid";
import { Breadcrumb, Spinner } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useQuery, TypedDocumentNode, useMutation } from "@apollo/client";
import { ProcessHistory, ProcessStreamType, ProcessType } from "../types";
import { PROCESS_CORE_FIELDS } from "../fragments";
import { useAuthStore } from "../../../../store/authStore";
import ProcessIds from "./processIds";
import { fullNumber } from "../../../../utils/fullNumber";
import ProcessName from "./processName";
import ProcessStatus from "./processStatus";
import ProcessActions from "./processActions";
import ProcessTags from "./processTags";
import ProcessMap from "./processMap";
import ProcessAddress from "./processAddress";
import { MarkerProp } from "../types";
import { useState } from "react";
import ProcessSales from "./processSales";
import ProcessDescription from "./processDesc";
import ProcessProducts from "./processProducts";
import ProcessContacts from "./processContacts";
import NavButtons from "./navButtons";
import ProcessLogs from "./processLogs";
import ProcessPayment from "./processPayment";
import ProcessProduction from "./processProduction";
import ProcessFiles from "./processFiles";
import ProcessSchedule from "./processSchedule";
import ProcessTasks from "./processTasks";
import ProcessHistories from "./processHistory";
import { UPDATE_PROCESS_BY_PK, REMOVE_PROCESS } from "./gqls";
import { createPathArr } from "../../../../utils/fileAPI";
import ProcessOrders from "./processOrders";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { OrderType } from "../../workorder/types";
import { ProductionType } from "../../shared/productions/types";
import { UPDATE_ORDER_BY_PK } from "../../workorder/gqls";
import { addAlert } from "../../../../store/alertStore";
import ProcessAssignment from "./processAssignment";

interface process_by_pk_type {
  process_by_pk: ProcessType;
}

export const GET_PROCESS_BY_PK: TypedDocumentNode<process_by_pk_type> = gql`
  ${PROCESS_CORE_FIELDS}
  query GET_PROCESS_BY_PK($id: Int!) {
    process_by_pk(id: $id) {
      ...ProcessCoreFields
    }
  }
`;

export const STREAM_PROCESS_BY_PK: TypedDocumentNode<ProcessStreamType> = gql`
  ${PROCESS_CORE_FIELDS}
  subscription STREAM_PROCESS_BY_PK(
    $where: process_bool_exp
    $cursor: [process_stream_cursor_input]!
  ) {
    process_stream(where: $where, cursor: $cursor, batch_size: 1) {
      ...ProcessCoreFields
    }
  }
`;

export default function ProcessDetail() {
  const navigate = useNavigate();
  const { id, screen } = useParams();

  const [markers, setMarkers] = useState<MarkerProp[]>([]);
  const [page, setPage] = useState(screen || "info");

  const { data, loading, error, subscribeToMore } = useQuery(
    GET_PROCESS_BY_PK,
    {
      variables: { id: Number(id) },
    }
  );

  const [update_process] = useMutation(UPDATE_PROCESS_BY_PK);
  const [remove_process] = useMutation(REMOVE_PROCESS);

  subscribeToMore({
    document: STREAM_PROCESS_BY_PK,
    variables: {
      where: { id: { _eq: id } },
      cursor: {
        initial_value: { updated_at: new Date().toISOString() },
        ordering: "ASC",
      },
    },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.process_stream[0];
      if (!newFeedItem) return prev;

      return Object.assign({}, prev, {
        process_by_pk: newFeedItem,
      });
    },
  });

  const process = data?.process_by_pk;
  const { user } = useAuthStore();

  const fullNum =
    process &&
    `${fullNumber(process.processType.prefix, process.year, process.number)}`;

  const jobPath: false | string[] = process ? createPathArr(process) : false;

  const baseHistory: Omit<ProcessHistory, "message" | "type"> = {
    by: user?.id || -1,
    date: new Date().toISOString(),
    status: process?.processStatus.id || -1,
  };

  const _delete = () => {
    if (!process) {
      return;
    }
    const proceed = confirm("delete process?");
    if (!proceed) {
      return;
    }
    update_process({
      variables: {
        id: process.id,
        set: {
          deleted: true,
        },
      },
      onError(error) {
        console.log(error);
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: process.id,
          __typename: "process",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
        remove_process({
          variables: { id: process.id },
        });
        navigate("/process");
      },
    });
  };

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 20,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const [update_order] = useMutation(UPDATE_ORDER_BY_PK);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over) {
      const order = active.data?.current?.order as OrderType;
      if (order.status.name == "draft") {
        addAlert({
          message: "you cannot assign workorder in draft status",
          type: "warning",
        });
        return;
      }
      const production = over.data?.current as ProductionType;
      const exIds = order.productionIds || [];
      const newIds = exIds
        .filter(id => id !== production.id)
        .concat(production.id);

      update_order({
        variables: {
          id: order.id,
          set: {
            productionIds: newIds,
            statusId: 3,
          },
        },
      });
    }
  };

  if (!id) {
    return null;
  }

  if (!process) {
    return null;
  }

  return (
    <main className="px-6 pb-10 select-none flex flex-col h-full">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("../");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            Process
          </Breadcrumb.Item>
          <Breadcrumb.Item className="truncate">{fullNum}</Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      {loading && !process && (
        <div className="flex-1 flex justify-center items-center">
          <Spinner size="xl" light color="purple" />
        </div>
      )}
      {process && (
        <article className="mt-3">
          {/* Name and actions */}
          <div className="flex flex-row items-center gap-2">
            <ProcessName
              process={process}
              baseHistory={baseHistory}
              jobPath={jobPath}
            />
            <ProcessActions process={process} baseHistory={baseHistory} />
          </div>
          {/* Nav and tags */}
          <div className="flex flex-row-reverse flex-wrap gap-2">
            <NavButtons page={page} setPage={setPage} />
            <ProcessTags
              page={page}
              process={process}
              baseHistory={baseHistory}
            />
          </div>
          {/* Basic Details + Sales Info */}
          <div
            className={`${page == "info" ? "grid" : "hidden"} grid-cols-1 
            @md:grid-cols-2 supports-[not(container-type:inline-size)]:md:grid-cols-2 
            @lg:grid-cols-3 supports-[not(container-type:inline-size)]:lg:grid-cols-3 
            @xl:grid-cols-6 supports-[not(container-type:inline-size)]:xl:grid-cols-6 
            @2xl:grid-cols-8 supports-[not(container-type:inline-size)]:2xl:grid-cols-8
            gap-4 mt-2`}
          >
            {/* Basic, Status */}
            <ProcessMap
              process={process}
              baseHistory={baseHistory}
              markers={markers}
            />
            <ProcessStatus process={process} baseHistory={baseHistory} />
            <ProcessIds
              process={process}
              baseHistory={baseHistory}
              jobPath={jobPath}
            />
            <ProcessAddress
              process={process}
              baseHistory={baseHistory}
              setMarkers={setMarkers}
            />
            <ProcessSales
              process={process}
              baseHistory={baseHistory}
              jobPath={jobPath}
            />
            <ProcessContacts process={process} baseHistory={baseHistory} />
            <ProcessProducts process={process} baseHistory={baseHistory} />
            <ProcessDescription process={process} baseHistory={baseHistory} />
            <ProcessLogs process={process} baseHistory={baseHistory} />
          </div>
          {/* Progress */}
          <div
            className={`${page == "prog" ? "grid" : "hidden"} grid-cols-1 
            @md:grid-cols-2 supports-[not(container-type:inline-size)]:md:grid-cols-2 
            @xl:grid-cols-4 supports-[not(container-type:inline-size)]:xl:grid-cols-4 
            gap-4 mt-2`}
          >
            <DndContext
              sensors={sensors}
              // onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              // onDragCancel={handleDragCancel}
              modifiers={[restrictToWindowEdges]}
            >
              <ProcessOrders
                process={process}
                baseHistory={baseHistory}
                defaultHidden={false}
                promeasureTypes={["quote"]}
                title="Quote"
              />
              <ProcessOrders
                process={process}
                baseHistory={baseHistory}
                defaultHidden={true}
                promeasureTypes={["order"]}
                title="Dealer Orders"
              />
              <ProcessOrders
                process={process}
                baseHistory={baseHistory}
                defaultHidden={false}
                promeasureTypes={["workorder", "measure"]}
                title="ProMeasure"
              />
              <ProcessProduction
                process={process}
                baseHistory={baseHistory}
                jobPath={jobPath}
              />
            </DndContext>
            <ProcessSchedule process={process} baseHistory={baseHistory} />
            <ProcessTasks process={process} baseHistory={baseHistory} />
            <ProcessAssignment process={process} baseHistory={baseHistory} />
            <ProcessPayment process={process} baseHistory={baseHistory} />
            <ProcessHistories process={process} baseHistory={baseHistory} />
          </div>

          <div className={`${page == "file" ? "grid" : "hidden"} mt-2`}>
            <ProcessFiles
              process={process}
              baseHistory={baseHistory}
              jobPath={jobPath}
            />
          </div>
        </article>
      )}
    </main>
  );
}
