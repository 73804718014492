import { Table } from "flowbite-react";
import { ContactType } from "./types";
import Contact from "./contact";
import { useState } from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";

interface props {
  contacts?: ContactType[];
}

export default function ContactsTable({ contacts }: props) {
  if (!contacts) {
    return null;
  }

  const [page, setPage] = useState(1);
  const pageLength = 100;
  const totalPages = Math.ceil(contacts.length / pageLength);

  const movePage = (direction: 1 | -1) => {
    const newPage = page + direction;
    if (newPage < 1 || newPage > totalPages) {
      return;
    }
    setPage(newPage);
  };

  const paginatedContacts = contacts.slice(
    (page - 1) * pageLength,
    page * pageLength
  );

  return (
    <>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell className="hidden @xl:table-cell supports-[not(container-type:inline-size)]:xl:table-cell">
            isOrg
          </Table.HeadCell>
          <Table.HeadCell className="hidden @lg:table-cell supports-[not(container-type:inline-size)]:lg:table-cell">
            Role
          </Table.HeadCell>
          <Table.HeadCell className="hidden @xs:table-cell supports-[not(container-type:inline-size)]:xs:table-cell">
            Mobile
          </Table.HeadCell>
          <Table.HeadCell className="hidden @sm:table-cell supports-[not(container-type:inline-size)]:sm:table-cell">
            Email
          </Table.HeadCell>
          <Table.HeadCell className="hidden @md:table-cell supports-[not(container-type:inline-size)]:md:table-cell">
            Address
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {paginatedContacts?.map(contact => (
            <Contact key={contact.id} contact={contact} />
          ))}
        </Table.Body>
      </Table>
      <div className="flex flex-row items-center justify-end gap-2">
        <ChevronDoubleLeftIcon
          className="w-5 cursor-pointer"
          onClick={() => {
            movePage(-1);
          }}
        />
        <div className="leading-[0]">
          <span className="text-grass">{page}</span> of {totalPages}
        </div>
        <ChevronDoubleRightIcon
          className="w-5 cursor-pointer"
          onClick={() => {
            movePage(1);
          }}
        />
      </div>
    </>
  );
}
