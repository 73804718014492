import React from "react";
import { gql, TypedDocumentNode } from "@apollo/client";
import { TeamTypeArayType } from "./types";

export const GET_TEAM_TYPE: TypedDocumentNode<TeamTypeArayType> = gql`
  query GET_TEAM_TYPE {
    teamType {
      id
      name
    }
  }
`;
export default function TeamType() {
  return <div></div>;
}
