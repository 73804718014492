import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Badge,
  Button,
  Checkbox,
  Label,
  ListGroup,
  Select,
  Spinner,
  TextInput,
  ToggleSwitch,
  Tooltip,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { processDetailSubProps } from "../types";
import {
  processContactSchema,
  ProcessHistory,
  processNewContactSchema,
} from "../types";
import {
  ArrowTopRightOnSquareIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { addAlert } from "../../../../store/alertStore";
import { SEARCH_CONTACT } from "../addProcess/contact";
import { GET_CONTACTS_BY_IDS } from "./processActions";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { ContactType, NewContactType } from "../../contact/types";
import { GET_CONTACT_TYPES } from "../../settings/contact/contactTypes/contactTypes";
import useAddContact from "../../contact/addContact/useAddContact";
import { sort } from "fast-sort";

const resolver = z.object({
  contacts: z.array(processContactSchema).optional(),
  newContact: processNewContactSchema.optional(),
});

interface contactsTypes {
  contacts?: ContactType[];
  newContact?: NewContactType;
}

export default function ProcessContacts({
  process,
  baseHistory,
}: processDetailSubProps) {
  const { contacts } = process;
  const [active, setActive] = useState(0);
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const { data: data_contacts } = useQuery(GET_CONTACTS_BY_IDS, {
    variables: { ids: contacts },
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      reset({
        contacts: data?.contacts.filter(c => contacts?.includes(c.id)) || [],
        newContact: undefined,
      });
    },
  });

  const curContacts =
    data_contacts?.contacts &&
    sort(data_contacts.contacts.filter(c => contacts?.includes(c.id))).desc(
      c => c.id == process.primaryContact
    );

  const defaultValues: contactsTypes = {
    contacts: [],
    newContact: undefined,
  };

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    trigger,
    register,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues,
  });

  const [makingNewContact, setMakingNewContact] = useState(false);

  const [searchStr, setSearchStr] = useState("");
  const debouncedStr = useDebounce<string>(searchStr, 500);

  const [search_contact, { loading: search_loading, data: search_data }] =
    useLazyQuery(SEARCH_CONTACT, { fetchPolicy: "no-cache" });

  const [searching, setSearching] = useState(false);

  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);
  const formContacts: ContactType[] = watch("contacts") || [];

  const { data: data_contact_types } = useQuery(GET_CONTACT_TYPES);

  const contactTypes = data_contact_types?.contactType;

  const { addNewContact, adding_contact } = useAddContact();

  useEffect(() => {
    if (debouncedStr.trim() !== "") {
      setSearching(true);
      search_contact({
        variables: { Str: debouncedStr },
      });
    } else {
      setSearching(false);
    }
  }, [debouncedStr]);

  const toggleNewContact = () => {
    if (makingNewContact) {
      setValue("newContact", undefined);
    }
    setMakingNewContact(!makingNewContact);
  };

  const search_results = search_data
    ? search_data.search_contacts.filter(
        c => !formContacts.find(ct => ct.id == c.id)
      )
    : false;

  const onSubmit = handleSubmit(async data => {
    const { contacts, newContact } = data;

    if (!isDirty) {
      addAlert({ message: "nothing changed", type: "warning" });
      return;
    }

    const addedContact: ContactType = await addNewContact(
      newContact as NewContactType
    );

    const newContacts = contacts || [];

    if (addedContact) {
      newContacts.push(addedContact);
    }

    const history: ProcessHistory = {
      ...baseHistory,
      message: `contacts updated`,
      type: "detail",
    };

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(history)
      : [history];

    update_process({
      variables: {
        id: process.id,
        set: {
          contacts: newContacts ? newContacts.map(c => Number(c.id)) : [],
          histories,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update contacts",
          type: "failure",
        });
      },
      onCompleted() {
        reset({ contacts: newContacts, newContact: undefined });
        setMakingNewContact(false);
        toggleEditing();
        setSearchStr("");
        setActive(0);
      },
    });
  });

  const cancel = () => {
    reset();
    toggleEditing();
    setSearchStr("");
    setActive(0);
  };

  const addContact = (contact: ContactType) => {
    setValue("contacts", formContacts.concat(contact), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const deleteContact = (id: number) => {
    setValue(
      "contacts",
      formContacts.filter(c => c.id !== id),
      { shouldDirty: true, shouldTouch: true, shouldValidate: true }
    );
  };

  const navigate = useNavigate();

  const goToContact = (id: number) => {
    navigate("/contacts/detail/" + id);
  };

  const makePrimary = (id: number) => {
    update_process({
      variables: {
        id: process.id,
        set: {
          primaryContact: id,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update contacts",
          type: "failure",
        });
      },
      onCompleted() {
        setActive(0);
      },
    });
  };

  const call = (num: string) => {
    window.location.href = `tel:${num}`;
  };

  const mail = (address?: string) => {
    if (!address) {
      return;
    }
    window.location.href = `mailto:${address}`;
  };

  return (
    <form
      onSubmit={onSubmit}
      className={`col-span-1 
      @xl:col-span-2 supports-[not(container-type:inline-size)]:xl:col-span-2
      space-y-2 flex flex-col`}
    >
      <div className="flex flex-row items-center gap-2 justify-between @md:justify-start">
        <h2>Contacts</h2>
        {!editing && (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 cursor-pointer hover:text-grass"
          />
        )}
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
        {!editing && (
          <div className="flex flex-col gap-2">
            {curContacts?.map((contact, i) => (
              <div
                key={contact.id}
                className={`flex-1 flex flex-col p-1 gap-1 rounded-md ${
                  active == i ? "" : "hidden"
                }`}
              >
                <div className="flex flex-row justify-between gap-2">
                  <div className="flex flex-row items-center gap-2">
                    <Tooltip content={`${i == 0 ? "primary" : "make primary"}`}>
                      <Checkbox
                        readOnly
                        onClick={() => {
                          makePrimary(contact.id);
                        }}
                        checked={contact.id == process.primaryContact}
                        className="checked:bg-plum dark:checked:bg-plum cursor-pointer"
                      />
                    </Tooltip>
                    <h3 className="select-none flex-1 truncate">
                      {contact.name}
                    </h3>
                    {contact.role && <Badge>{contact.role}</Badge>}
                  </div>
                  <Link to={`/contacts/detail/${contact.id}`}>
                    <ArrowTopRightOnSquareIcon className="w-4 hover:text-grass cursor-pointer" />
                  </Link>
                </div>
                <div className="flex flex-row justify-between gap-2">
                  <Label>Number</Label>
                  <div
                    className="select-text cursor-pointer"
                    onClick={() => {
                      call(contact.number || "");
                    }}
                  >
                    {contact.number}
                  </div>
                </div>
                <div className="flex flex-row justify-between gap-2">
                  <Label>Mail</Label>
                  <div
                    className="select-text cursor-pointer"
                    onClick={() => {
                      mail(contact.mail || "");
                    }}
                  >
                    {contact.mail}
                  </div>
                </div>
              </div>
            ))}
            <hr />
            <div className="flex flex-row gap-2 justify-end">
              {curContacts?.map((contact, i) => (
                <Badge
                  onClick={() => {
                    setActive(i);
                  }}
                  key={contact.id}
                  color={active == i ? "purple" : "gray"}
                  className="cursor-pointer"
                >
                  {i + 1}
                </Badge>
              ))}
            </div>
          </div>
        )}

        {editing && (
          <>
            {/* Search existing contacts */}
            <div className="space-y-2">
              <Label value="Search" />
              <div className="relative">
                <TextInput
                  type="text"
                  icon={MagnifyingGlassIcon}
                  placeholder="Search existing contacts"
                  sizing="sm"
                  value={searchStr}
                  onChange={e => {
                    setSearchStr(e.target.value);
                  }}
                />
                {search_loading && (
                  <Spinner
                    className="absolute m-auto top-0 bottom-0 right-2"
                    size="sm"
                  />
                )}
              </div>
              {searching && search_results && (
                <div className="space-y-2">
                  <Label value="Search Results" />
                  {search_results.length < 1 && (
                    <div className="text-sm">no more matching results</div>
                  )}
                  <ListGroup>
                    {search_results.map(result => (
                      <ListGroup.Item
                        key={result.id}
                        onClick={() => {
                          addContact(result);
                          trigger("contacts");
                        }}
                      >
                        <div className="flex items-center justify-between w-full">
                          <div className="flex items-center">
                            <UserIcon className="w-4 mr-2" /> {result.name}
                            {result.number && `- ${result.number}`}
                          </div>
                          <PlusCircleIcon className="w-5" />
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <div className="flex justify-end">
                    <Button
                      size="sm"
                      gradientDuoTone="purpleToBlue"
                      outline
                      onClick={() => {
                        setSearching(false);
                        setSearchStr("");
                      }}
                    >
                      clear
                    </Button>
                  </div>
                </div>
              )}
            </div>

            {formContacts && formContacts.length > 0 && (
              <div className="space-y-2">
                <Label value="Added" />
                <ListGroup>
                  {formContacts.map(contact => (
                    <ListGroup.Item key={contact.id}>
                      <div className="flex items-center justify-between w-full">
                        <div
                          className="flex items-center"
                          onClick={() => {
                            goToContact(contact.id);
                          }}
                        >
                          <UserIcon className="w-4 mr-2" /> {contact.name}
                          {contact.number && `- ${contact.number}`}
                        </div>
                        <MinusCircleIcon
                          onClick={() => {
                            deleteContact(contact.id);
                          }}
                          className="w-5"
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            )}

            <div className="flex flex-row justify-end pt-2">
              <ToggleSwitch
                checked={makingNewContact}
                label="Add new contact"
                onChange={toggleNewContact}
                //@ts-expect-error
                color="purple"
              />
            </div>

            {makingNewContact && (
              <div className="space-y-2">
                <hr className="border-gray-500" />
                <div className="space-y-2">
                  <Label value="Type" />
                  <Select {...register("newContact.typeID")} sizing="sm">
                    {contactTypes?.map(ct => (
                      <option key={ct.id} value={ct.id}>
                        {ct.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label value="Name" />
                  <TextInput
                    type="text"
                    placeholder="Enter Name"
                    sizing="sm"
                    {...register("newContact.name")}
                    onBlur={() => {
                      trigger("contacts");
                    }}
                    color={errors.newContact?.message ? "failure" : undefined}
                  />
                </div>
                <div className="space-y-2">
                  <Label value="Number" />
                  <TextInput
                    type="text"
                    placeholder="Enter Number"
                    sizing="sm"
                    {...register("newContact.number")}
                    color={errors.newContact?.message ? "failure" : undefined}
                  />
                </div>
                <div className="space-y-2">
                  <Label value="Email" />
                  <TextInput
                    type="text"
                    placeholder="Enter Email"
                    sizing="sm"
                    {...register("newContact.mail")}
                    color={errors.newContact?.message ? "failure" : undefined}
                  />
                </div>
                <div className="space-y-2">
                  <Label value="Address" />
                  <TextInput
                    type="text"
                    placeholder="Enter Address"
                    sizing="sm"
                    {...register("newContact.address")}
                    color={errors.newContact?.message ? "failure" : undefined}
                  />
                </div>
              </div>
            )}

            <div className="text-sm text-red-500">
              {errors.contacts?.message?.toString() || ""}
            </div>
            <div className="flex justify-end flex-row items-center gap-2 mt-2">
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                type="submit"
                outline
              >
                {(loading || adding_contact) && (
                  <Spinner size="xs" light className="mr-1" />
                )}
                update
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}
