import { create } from "zustand";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_CONTACT_TYPES } from "../routes/protected/settings/contact/contactTypes/contactTypes";
import {
  ContactFilter,
  contactQueryParams,
} from "../routes/protected/contact/types";
import { ContactTypeType } from "../routes/protected/settings/contact/contactTypes/types";

type Store = {
  filters: ContactFilter[];
  setFilters: (filters: ContactFilter[]) => void;
  cursor: string;
  setCursor: (str: string) => void;
};

export const useContactStore = create<Store>(set => ({
  filters: [],
  setFilters: filters => {
    if (filters.length == 0) {
      localStorage.removeItem("contactFilters");
    }
    localStorage.setItem("contactFilters", JSON.stringify(filters));
    return set(state => ({
      ...state,
      filters,
    }));
  },
  cursor: new Date().toISOString(),
  setCursor(cursor: string) {
    return set(state => ({
      ...state,
      cursor,
    }));
  },
}));

export const useDefaultFilters = () => {
  const [types, setTypes] = useState<undefined | ContactTypeType[]>();

  useQuery(GET_CONTACT_TYPES, {
    onCompleted(data) {
      setTypes(data.contactType);
    },
  });

  const { setFilters } = useContactStore();

  const savedFilters = localStorage.getItem("contactFilters");

  useEffect(() => {
    if (savedFilters && savedFilters.length > 0) {
      setFilters(JSON.parse(savedFilters));
      return;
    }

    if (types) {
      const defaultFilters: ContactFilter[] = [];

      // Types
      const options_type = types?.map(t => ({
        name: t.name,
        value: t.id,
      }));

      const defaultValues_type = types?.map(t => t.id);

      const typeFilter: ContactFilter = {
        name: "Type",
        where: "typeID",
        type: "in",
        values: defaultValues_type,
        defaultValues: defaultValues_type,
        options: options_type,
      };
      defaultFilters.push(typeFilter);

      setFilters(defaultFilters);
    }
  }, [types]);
};

export const refineFilters: (filters: ContactFilter[]) => contactQueryParams = (
  filters: ContactFilter[]
) => {
  if (!filters) {
    return {
      where: { _and: [] },
      offset: 0,
    };
  }

  const where: any = { _and: [] };
  const order_by: object[] = [];

  for (const filter of filters) {
    // Filter
    switch (filter.type) {
      case "in":
        where._and.push({ [filter.where]: { _in: filter.values } });
        break;
      case "contains":
        where._and.push({
          _or: [
            { [filter.where]: { _contains: filter.values } },
            { [filter.where]: { _contained_in: filter.values } },
          ],
        });
        break;
      case "range":
        const { from, to } = filter.values;
        if (from && to) {
          where._and.push({
            [filter.where]: {
              _gte: filter.values.from,
              _lte: filter.values.to,
            },
          });
        }
        break;
    }
  }

  // @ts-expect-error
  if (!where._and?.find(w => w.deleted)) {
    where._and.push({ deleted: { _eq: false } });
  }

  return {
    where: where,
    order_by: order_by,
    offset: 0,
  };
};
