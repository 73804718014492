import { processDetailSubProps } from "../types";
import AddLog from "../../shared/logs/addLog";
import Log from "../../shared/logs/log";
import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { PROCESS_LOGS_CORE_FIELDS } from "../../shared/logs/fragments";
import { LogArrayType, LogStreamType } from "../../shared/logs/types";
import { uniqueById } from "../../../../utils/arrayObjMethods";
import { sort } from "fast-sort";

export const GET_PROCESS_LOGS: TypedDocumentNode<LogArrayType> = gql`
  ${PROCESS_LOGS_CORE_FIELDS}
  query GET_PROCESS_LOGS($processID: Int!) {
    processLogs(
      where: { processID: { _eq: $processID }, deleted: { _eq: false } }
    ) {
      ...ProcessLogsCoreFields
    }
  }
`;

export const SUB_PROCESS_LOGS: TypedDocumentNode<LogStreamType> = gql`
  ${PROCESS_LOGS_CORE_FIELDS}
  subscription SUB_PROCESS_LOGS(
    $processID: Int!
    $cursor: [processLogs_stream_cursor_input]!
  ) {
    processLogs_stream(
      where: { processID: { _eq: $processID } }
      cursor: $cursor
      batch_size: 10
    ) {
      ...ProcessLogsCoreFields
    }
  }
`;

export default function ProcessLogs({ process }: processDetailSubProps) {
  const { data, loading, error, subscribeToMore } = useQuery(GET_PROCESS_LOGS, {
    variables: {
      processID: process.id,
    },
  });

  const logs = data?.processLogs;

  subscribeToMore({
    document: SUB_PROCESS_LOGS,
    variables: {
      processID: process.id,
      cursor: {
        initial_value: { updated_at: new Date().toISOString() },
        ordering: "ASC",
      },
    },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.processLogs_stream;

      const existing = prev.processLogs.filter(
        p => !newFeedItem.find(i => i.id == p.id)
      );

      const filterDeleted = [...newFeedItem, ...existing].filter(
        log => !log.deleted
      );

      return Object.assign({}, prev, {
        processLogs: uniqueById(filterDeleted),
      });
    },
  });

  return (
    <div
      className="row-span-3 min-h-[400px] col-span-1 
    @md:col-span-2 supports-[not(container-type:inline-size)]:md:col-span-2
    @lg:col-span-3 supports-[not(container-type:inline-size)]:lg:col-span-3
    @2xl:col-span-4 supports-[not(container-type:inline-size)]:2xl:col-span-4
    gap-2 flex flex-col"
    >
      <h2>Comments</h2>
      <AddLog processID={process.id} />
      <div className="flex flex-col flex-1 pt-2 gap-2 max-h-screen overflow-x-hidden overflow-y-auto @md:scrollbar-thin @md:scrollbar-thumb-gray-300 @md:dark:scrollbar-thumb-slate-700">
        {logs &&
          sort(logs)
            .desc(log => new Date(log.timeStamp))
            .map(log => <Log key={log.id} log={log} />)}
      </div>
    </div>
  );
}
