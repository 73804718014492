import { Button, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useScheme } from "../../../../../../../store/schemeStore";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useOrderStore } from "../../../../../../../store/orderStore";
import { push } from "../../../../../../../utils/pushAPI";
import { GET_USERS_CONDITIONAL } from "../../../../../settings/users/gql";
import { DeductionType, deductionSchema } from "../../../../types";
import { ADD_DEDUCTION } from "../../../../gqls";

interface props {
  adding: boolean;
  setAdding: React.Dispatch<React.SetStateAction<boolean>>;
  optionCode: string;
  deductions: DeductionType[];
  search: string;
  optionName: string;
  cb?: (newmat: DeductionType) => void;
}

export default function AddCustomDeduction({
  adding,
  setAdding,
  optionCode,
  deductions,
  search,
  optionName,
  cb,
}: props) {
  const onClose = () => {
    setAdding(false);
  };

  const { addDeductions } = useOrderStore();

  const possibleConditions =
    deductions.reduce<DeductionType["optionCondition"]>((prev, cur) => {
      let newConditions = prev || [];
      if (cur.optionCondition) {
        const uniqueConditions = cur.optionCondition.filter(
          oc =>
            !newConditions.find(
              nc => nc.option == oc.option && nc.value == oc.value
            )
        );
        newConditions.push(...uniqueConditions);
      }

      return newConditions;
    }, []) || [];

  const defaultValues = {
    productSetId: deductions[0]?.productSet?.id,
    name: search || "",
    width: null,
    height: null,
    optionCode,
    pending: true,
    optionCondition: possibleConditions || [],
  };

  const { scheme } = useScheme();

  const {
    reset,
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(deductionSchema),
  });

  // console.log(errors);

  useEffect(() => {
    const name = search;

    reset({
      ...defaultValues,
      name: name || "",
    });
  }, [search]);

  const [insert_deduction, { loading }] = useMutation(ADD_DEDUCTION);

  const { data: data_users } = useQuery(GET_USERS_CONDITIONAL, {
    variables: {
      where: {
        team: {
          inHouse: { _eq: true },
          type: { name: { _eq: "admin" } },
        },
        auth: { name: { _in: ["office_admin", "office_leader"] } },
        active: { _eq: true },
      },
    },
  });

  const adminUsersToNotify = data_users?.users.map(u => u.id);

  const onSubmit = handleSubmit(data => {
    insert_deduction({
      variables: {
        object: data,
      },
      onCompleted(data) {
        const insertedDeduction = data.insert_deductions_one;
        addDeductions([
          {
            ...data.insert_deductions_one,
            pending: false,
          },
        ]);
        if (adminUsersToNotify) {
          push({
            title: "New material added",
            body: `Please confirm pending material [${insertedDeduction.name}] for ${insertedDeduction.productSet?.name}`,
            ids: adminUsersToNotify,
            // path:""
          });
        }
        if (cb) {
          cb(data.insert_deductions_one);
        }
      },
    });
  });

  return (
    <Modal
      show={adding}
      onClose={onClose}
      className={`${scheme} !h-full !items-start`}
    >
      <form onSubmit={onSubmit}>
        <Modal.Header>Add Custom Material for {optionName}</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-2">
            <Label value="Name" />
            <TextInput
              {...register("name")}
              placeholder="enter name"
              required
            />
            <Label value="Width Deduction" />
            <TextInput
              {...register("width")}
              placeholder="width deduction in mm, append - in front"
            />
            <Label value="Height Deduction" />
            <TextInput
              {...register("height")}
              placeholder="height deduction in mm, append - in front"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end gap-2">
          <Button
            size="sm"
            color="purple"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            outline
            size="sm"
            gradientDuoTone="purpleToBlue"
            type="submit"
          >
            <div className="flex flex-row gap-2 items-center">
              {loading && <Spinner size="sm" color="purple" />}
              Add
            </div>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
