import { useMutation } from "@apollo/client";
import { QuoteParams } from "../types";
import { UPDATE_QUOTE_PARAM_BY_PK } from "../gqls";
import { useForm } from "react-hook-form";
import { Button, Card, Spinner } from "flowbite-react";

interface props {
  params?: QuoteParams[];
}
export default function MinInstallCharge({ params }: props) {
  const [update, { loading: updating }] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);

  const defaultminInstall = params?.find(p => p.name == "minInstallCharge");

  const defaultValues = {
    minInstall: defaultminInstall?.value || 0,
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const submit = handleSubmit(data => {
    if (defaultminInstall) {
      update({
        variables: {
          id: defaultminInstall.id,
          set: {
            value: Number(data.minInstall),
          },
        },
        onCompleted() {
          reset({
            minInstall: data.minInstall,
          });
        },
      });
    }
  });

  const cancel = () => {
    reset();
  };

  return (
    <Card className={`col-span-1 relative`}>
      <div className="flex flex-col min-h-[120px] ">
        <div className="flex flex-row items-center font-bold text-4xl">
          <input
            type="number"
            className="text-plum font-bold text-4xl bg-transparent w-24 border-none outline-none px-0 text-center"
            {...register("minInstall")}
          />
          AUD
        </div>
        <h2>Min Install Charge</h2>
      </div>
      {isDirty && (
        <div className="absolute bottom-2 right-2 flex flex-row gap-2 items-center justify-end">
          <Button size="xs" color="purple" onClick={cancel}>
            cancel
          </Button>
          <Button
            size="xs"
            outline
            gradientDuoTone={"purpleToBlue"}
            onClick={submit}
          >
            update
            {updating && <Spinner size="sm" className="ml-1" color="purple" />}
          </Button>
        </div>
      )}
    </Card>
  );
}
