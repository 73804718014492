import { useQuery } from "@apollo/client";
import {
  GET_DEDUCTIONS_CONDITIONAL,
  GET_DEDUCTION_PRESETS_CONDITIONAL,
  GET_PRODUCT_OPTIONS_BY_PID,
  GET_PRODUCT_OPTIONS_CONDITIONAL,
  GET_PRODUCT_SET_BY_ID,
} from "../../../workorder/gqls";
import {
  FieldValues,
  FormProvider,
  UseFieldArrayAppend,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  NewProductSetOptionType,
  condition,
  modifier,
  optionValues,
  productSetOptionScheme,
} from "../../../workorder/types";
import {
  Badge,
  Button,
  Checkbox,
  Label,
  Modal,
  Select,
  TextInput,
} from "flowbite-react";
import { Fragment, useEffect, useState } from "react";
import { GET_MATERIALS_CONDITIONAL } from "../../../materials/gqls";
import isNullish from "../../../../../utils/isNullish";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import ValuePopulate from "../../../workorder/ValuePopulate";
import SearchSelect from "../../../../../comps/searchSelect";
import { useScheme } from "../../../../../store/schemeStore";
import { sort } from "fast-sort";
import { MaterialsType } from "../../../materials/types";
import { zodResolver } from "@hookform/resolvers/zod";
import CheckboxWithLabel from "../../../../../comps/CheckboxWithLabel";
import { useOrderStore } from "../../../../../store/orderStore";

interface props {
  submitFunction: (data: NewProductSetOptionType) => void;
  cancelFunction: () => void;
  exValues?: NewProductSetOptionType;
  productSetId: number;
}

export default function EditProductSetOption({
  submitFunction,
  cancelFunction,
  productSetId,
  exValues,
}: props) {
  const defaultValues: NewProductSetOptionType = exValues || {
    name: "",
    displayName: "",
    type: "select",
    default: "",
    optional: false,
    conditions: [],
    group: undefined,
    modifiers: [],
    source: undefined,
    optionCode: "",
    parent: [],
    position: 0,
    value: undefined,
    values: undefined,
    valuesQty: undefined,
    rowNum: 0,
    presetID: undefined,
    allowCustomInput: false,
    nameScope: undefined,
    disableAutoSelect: false,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(productSetOptionScheme),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  // console.log(errors);

  const onSubmit = handleSubmit(data => {
    submitFunction(data);
  });
  const cancel = () => {
    reset();
    cancelFunction();
  };

  const typeOptions = [
    "select",
    "text",
    "number",
    "multi-select",
    "multiple",
    "multi-name-number",
    "param",
  ];

  const type = useWatch({
    name: "type",
    control,
  });

  const group = useWatch({
    name: "group",
    control,
  });

  const { data } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        _and: [
          { productSetID: { _eq: productSetId } },
          { source: { _eq: "deductionPreset" } },
        ],
      },
    },
  });

  const presetOptions = data?.productSetOptions;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center justify-between">
          <div className="flex flex-row gap-2 items-center">
            <TextInput
              className="w-fit"
              addon="Name"
              {...register("name")}
              sizing="sm"
              placeholder="Option name"
            />
            <TextInput
              className="w-fit"
              addon="DPName"
              {...register("displayName")}
              sizing="sm"
              placeholder="Option name to display"
            />
            <Select
              className="w-fit capitalize"
              addon="Type"
              {...register("type")}
              sizing="sm"
            >
              {typeOptions.map(t => (
                <option className="capitalize" key={t}>
                  {t}
                </option>
              ))}
            </Select>
            <Select
              className="w-fit"
              addon="Row"
              {...register("rowNum")}
              sizing="sm"
            >
              <option className="capitalize" value={0}>
                0
              </option>
              <option className="capitalize" value={1}>
                1
              </option>
              <option className="capitalize" value={2}>
                2
              </option>
              <option className="capitalize" value={3}>
                3
              </option>
            </Select>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <Label value="Optional" />
            <Checkbox
              className="checked:bg-plum dark:checked:bg-plum"
              {...register("optional")}
            />
          </div>
        </div>
        {type == "select" && <SelectOption productSetId={productSetId} />}
        {type == "param" && <ParamOption productSetId={productSetId} />}
        {type == "multi-select" && <MultiSelectOption />}
        {(type == "text" || type == "number") && <DefaultValue type={type} />}
        {type == "multiple" && <MultipleOption />}
        {type == "multi-name-number" && <MultiNameNumberOption />}
        {/* Common fields */}
        <hr />
        <Label value="Option Settings" />
        <div className="flex flex-row gap-2 items-center">
          <TextInput
            className="w-fit"
            addon="Group"
            {...register("group")}
            sizing="sm"
            placeholder="group (optional)"
          />
          {!isNullish(group) && (
            <Select
              className="w-fit"
              addon="Preset"
              {...register("presetID")}
              sizing="sm"
              placeholder="bind to preset"
            >
              <option value={undefined}>bind to preset</option>
              {presetOptions?.map(po => (
                <option key={po.id} value={po.id}>
                  {po.displayName || po.name}
                </option>
              ))}
            </Select>
          )}
        </div>
        <Label value="Parent Options" />
        <Parent productSetId={productSetId} />
        {type == "select" && (
          <>
            <Label value="Material Modifiers" />
            <Modifiers productSetId={productSetId} />
          </>
        )}
        <hr />
        <div className="flex flex-row gap-2 justify-end items-center">
          <Button onClick={cancel} size="xs" color="purple">
            cancel
          </Button>
          <Button
            onClick={onSubmit}
            outline
            size="xs"
            gradientDuoTone="purpleToBlue"
            disabled={!typeOptions.includes(type)}
          >
            save
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}

const MultiNameNumberOption = () => {
  const { control } = useFormContext();
  const { append, remove } = useFieldArray({
    name: "values",
    control,
  });

  const values: string[] =
    useWatch({
      name: "values",
      control,
    }) || [];

  const [newValue, setNewValue] = useState("");
  const add = () => {
    if (isNullish(newValue)) {
      return;
    }
    append(newValue);
    setNewValue("");
  };

  return (
    <div className="flex flex-col gap-2">
      <Label value="Add Selectable Value" />
      <div className="flex flex-row gap-2 items-center">
        <TextInput
          className="w-fit"
          value={newValue}
          onChange={e => setNewValue(e.target.value)}
          sizing="sm"
          placeholder="Value name"
        />
        <PlusCircleIcon
          className="w-5 text-grass cursor-pointer"
          onClick={add}
        />
      </div>
      <Label value="Selectable Values" />
      <div className="select-text flex flex-col w-fit">
        <div className="flex flex-row rounded-t-md dark:bg-gray-600 bg-gray-100 py-2 font-semibold uppercase">
          <div className="text-xs px-2 w-48">name</div>
          <div className="p-0 w-20" />
        </div>
        <div className="text-black dark:text-white">
          {values?.map((val, index) => (
            <div
              key={index}
              className={`flex flex-row ${
                index && index % 2 !== 0 && "dark:bg-gray-600 bg-gray-100"
              }`}
            >
              <div className="w-48 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                {val}
              </div>
              <div className="py-[5px] w-20">
                <div className="flex flex-row gap-3 w-full justify-center">
                  <TrashIcon
                    className="w-4 text-red-500 cursor-pointer"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MultipleOption = () => {
  const { register } = useFormContext();
  return (
    <TextInput
      addon="Qty"
      type="number"
      className="w-fit"
      {...register("valuesQty")}
      sizing="sm"
      placeholder="Enter qty of values required"
    />
  );
};

const DefaultValue = ({ type }: { type: string }) => {
  const { register } = useFormContext();

  return (
    <TextInput
      className="w-fit"
      {...register("default")}
      addon="default"
      sizing="sm"
      placeholder="Default Value"
      type={type}
    />
  );
};

const MultiSelectOption = () => {
  const { setValue, control } = useFormContext();
  const { append, remove } = useFieldArray({
    name: "values",
    control,
  });

  const values: string[] =
    useWatch({
      name: "values",
      control,
    }) || [];

  const [newValue, setNewValue] = useState("");
  const add = () => {
    if (isNullish(newValue)) {
      return;
    }
    append(newValue);
    setNewValue("");
  };

  const defaultValues: string[] =
    useWatch({
      name: "default",
      control,
    }) || [];

  useEffect(() => {
    return () => {
      setValue("default", undefined);
      setValue("values", undefined);
    };
  }, []);

  const toggleDefault = (val: string) => {
    if (defaultValues.includes(val)) {
      setValue(
        "default",
        defaultValues.filter(d => d !== val)
      );
    } else {
      setValue("default", defaultValues.concat(val));
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Label value="Add Selectable Value" />
      <div className="flex flex-row gap-2 items-center">
        <TextInput
          className="w-fit"
          value={newValue}
          onChange={e => setNewValue(e.target.value)}
          sizing="sm"
          placeholder="Value name"
        />
        <PlusCircleIcon
          className="w-5 text-grass cursor-pointer"
          onClick={add}
        />
      </div>
      <Label value="Selectable Values" />
      <div className="select-text flex flex-col w-fit">
        <div className="flex flex-row rounded-t-md dark:bg-gray-600 bg-gray-100 py-2 font-semibold uppercase">
          <div className="text-xs px-2 w-48">name</div>
          <div className="text-xs px-2 w-20">default</div>
          <div className="p-0 w-20" />
        </div>
        <div className="text-black dark:text-white">
          {values?.map((val, index) => (
            <div
              key={index}
              className={`flex flex-row ${
                index && index % 2 !== 0 && "dark:bg-gray-600 bg-gray-100"
              }`}
            >
              <div className="w-48 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                {val}
              </div>

              <div
                className="group py-[5px] w-20 px-2 border-r-[1px] dark:border-gray-500 flex flex-row justify-center cursor-pointer"
                onClick={() => {
                  toggleDefault(val);
                }}
              >
                <Checkbox
                  className="checked:bg-plum dark:checked:bg-plum"
                  readOnly
                  checked={defaultValues.includes(val)}
                />
              </div>
              <div className="py-[5px] w-20">
                <div className="flex flex-row gap-3 w-full justify-center">
                  <TrashIcon
                    className="w-4 text-red-500 cursor-pointer"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ParamOption = ({ productSetId }: { productSetId: number }) => {
  const { register, setValue, control, getValues } = useFormContext();
  useEffect(() => {
    return () => {
      setValue("source", undefined);
    };
  }, []);

  const sources = ["deductions", "inventory", "deductionPreset"];

  const { data: data_productSet } = useQuery(GET_PRODUCT_SET_BY_ID, {
    variables: {
      id: productSetId,
    },
  });

  const productId = data_productSet?.productSets_by_pk.product.id;

  const { data: data_mats } = useQuery(GET_MATERIALS_CONDITIONAL, {
    variables: {
      where: {
        productId: { _eq: productId },
      },
    },
  });
  const inventory = data_mats?.materials;

  const { data: data_deductions } = useQuery(GET_DEDUCTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: productSetId },
      },
    },
  });
  const deductions = data_deductions?.deductions;

  const { data: data_deductionPreset } = useQuery(
    GET_DEDUCTION_PRESETS_CONDITIONAL,
    {
      variables: {
        where: {
          for: { _eq: productSetId },
        },
      },
    }
  );
  const deductionPreset = data_deductionPreset?.deductionPreset;

  const optionSources = {
    inventory,
    deductions,
    deductionPreset,
  };

  const optionSource = useWatch({
    name: "source",
    control,
  });

  const value: string = useWatch({
    name: "value",
    control,
  });

  useEffect(() => {
    setValue("value", undefined);
  }, [optionSource]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-2 items-center">
        <Select
          className="w-fit capitalize"
          addon="source"
          {...register("source")}
          sizing="sm"
        >
          <option value="">no option source</option>
          {sources.map(s => (
            <option key={s}>{s}</option>
          ))}
        </Select>
        <SearchSelect
          // @ts-ignore
          options={optionSources[optionSource]
            // @ts-ignore
            ?.map(o => ({
              value: o.id,
              name: `${o.name}${o.color ? " " + o.color : ""}`,
            }))}
          placeholder="Select Param Value"
          value={value}
          onChange={val => {
            setValue("value", val);
          }}
        />
      </div>
    </div>
  );
};

interface availableParam {
  name: string;
  values: string[];
}

const SelectOption = ({ productSetId }: { productSetId: number }) => {
  const { register, setValue, control, getValues } = useFormContext();

  useEffect(() => {
    return () => {
      setValue("source", undefined);
      setValue("optionCode", undefined);
      setValue("conditions", undefined);
    };
  }, []);

  const sources = ["deductions", "inventory", "deductionPreset"];

  // const { data: data_productSet } = useQuery(GET_PRODUCT_SET_BY_ID, {
  //   variables: {
  //     id: productSetId,
  //   },
  // });

  // const productId = data_productSet?.productSets_by_pk.product.id;

  const {
    materials: cachedMaterials,
    deductions: cachedDeductions,
    presets: cachedPresets,
  } = useOrderStore();

  const inventory = cachedMaterials;
  const deductions = cachedDeductions.filter(
    d => d.productSet?.id == Number(productSetId)
  );
  const deductionPreset = cachedPresets.filter(
    p => p.productSet.id == Number(productSetId)
  );

  const optionSources = {
    inventory,
    deductions,
    deductionPreset,
  };

  const optionSource = useWatch({
    name: "source",
    control,
  });

  const optionCode = useWatch({
    name: "optionCode",
    control,
  });

  const values: string[] = useWatch({
    name: "values",
    control,
  });

  useEffect(() => {
    if (!values) {
      return;
    }
    const conditions: condition[] = getValues("conditions");
    if (conditions) {
      setValue(
        "conditions",
        conditions.filter(c =>
          values.map(v => v.toString()).includes(c.value.toString())
        )
      );
    }
  }, [values]);

  const defaultValue: string = useWatch({
    name: "default",
    control,
  });

  const { append, remove, replace } = useFieldArray({
    name: "values",
    control,
  });

  const [availavbleParams, setAvailableParams] = useState<availableParam[]>([]);

  useEffect(() => {
    if (!isNullish(optionCode)) {
      const foundOptions =
        // @ts-ignore
        optionSources[optionSource]
          ?.filter(
            // @ts-ignore
            o => o.optionCode == optionCode
          )
          // @ts-ignore
          ?.map(o => o.id) || [];

      if (optionSource == "inventory") {
        const mats = cachedMaterials.filter(mat =>
          foundOptions.includes(mat.id)
        );
        const existingParams = mats.reduce<availableParam[]>((prev, cur) => {
          let newParams = prev;
          if (cur.params) {
            cur.params.map(param => {
              if (newParams.find(p => p.name == param.name)) {
                newParams = newParams.map(np => {
                  if (np.name == param.name) {
                    return {
                      ...np,
                      values: [...new Set(np.values.concat(param.value))],
                    };
                  } else {
                    return np;
                  }
                });
              } else {
                newParams = newParams.concat({
                  name: param.name,
                  values: [param.value],
                });
              }
            });
          }

          return newParams;
        }, []);

        setAvailableParams(existingParams);
      }

      replace(foundOptions);
    }
  }, [optionCode]);

  useEffect(() => {
    if (!values) {
      return;
    }
    if (!values.includes(defaultValue)) {
      setValue("default", undefined);
    }
  }, [values]);

  const [newValue, setNewValue] = useState("");

  const addValue = () => {
    append(newValue);
    setNewValue("");
  };

  const allowCustomInput = useWatch({
    name: "allowCustomInput",
    control,
  });

  const sourcesAllowingCustomInput = ["deductions", "inventory"];

  const possibleNameScopes = [
    "brand",
    "name",
    "color",
    "size",
    "width",
    "height",
  ];

  const nameScope: string[] | undefined = useWatch({
    name: "nameScope",
    control,
  });

  const nameScopeEnabled = nameScope ? true : false;
  const toggleNameScopeEdit = () => {
    nameScopeEnabled
      ? setValue("nameScope", undefined)
      : setValue("nameScope", []);
  };

  const toggleNameScope = (name: string) => {
    if (!nameScope) {
      return;
    }

    if (nameScope.includes(name)) {
      setValue(
        "nameScope",
        nameScope.filter(s => s !== name)
      );
    } else {
      setValue("nameScope", nameScope.concat(name));
    }
  };

  const [page, setPage] = useState(1);
  const pageLength = 20;
  const totalPages = Math.ceil(values && values.length / pageLength);

  const movePage = (direction: 1 | -1) => {
    const newPage = page + direction;
    if (newPage < 1 || newPage > totalPages) {
      return;
    }
    setPage(newPage);
  };

  const paramCondition = useWatch({ name: "optionCondition", control })
    ?.material?.param;

  const paginatedValues = values
    ? values
        .filter(val => {
          if (optionSource == "inventory") {
            if (!paramCondition) {
              return true;
            }
            const mat = cachedMaterials.find(m => m.id == Number(val));
            return mat?.params?.find(
              param =>
                param.name == paramCondition?.name &&
                param.value == paramCondition?.value
            );
          }

          return true;
        })
        .slice((page - 1) * pageLength, page * pageLength)
    : [];

  const { scheme } = useScheme();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-2 items-center">
        <Select
          className="w-fit capitalize"
          addon="source"
          {...register("source")}
          sizing="sm"
        >
          <option value="">no option source</option>
          {sources.map(s => (
            <option key={s}>{s}</option>
          ))}
        </Select>
        {!isNullish(optionSource) && (
          <TextInput
            className="w-fit"
            addon="code"
            {...register("optionCode")}
            placeholder="Option code"
            sizing="sm"
          />
        )}
        {availavbleParams.length > 0 && (
          <ParamCondition availableParams={availavbleParams} />
        )}
        {sourcesAllowingCustomInput.includes(optionSource) &&
          !isNullish(optionCode) && (
            <CheckboxWithLabel
              name="Allow Custom Input"
              checked={allowCustomInput}
              onChange={() => {
                setValue("allowCustomInput", !allowCustomInput);
              }}
            />
          )}
      </div>
      {optionSource == "inventory" && (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <Label value="Customize Material Props To Display" />{" "}
            <Checkbox
              className="checked:bg-plum dark:checked:bg-plum"
              checked={nameScopeEnabled}
              readOnly
              onClick={toggleNameScopeEdit}
            />
          </div>
          <div className="flex flex-row gap-2">
            {possibleNameScopes.map(ps => {
              const active = nameScope && nameScope.includes(ps);
              return (
                <Button
                  color={active ? "purple" : "gray"}
                  size="xs"
                  outline={active}
                  key={ps}
                  onClick={() => {
                    toggleNameScope(ps);
                  }}
                  className={`${!active && "opacity-50"}`}
                  disabled={!nameScopeEnabled}
                >
                  {ps}
                </Button>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex flex-row justify-between items-center">
        <Label value="Available Values" />
        <div className="flex flex-row gap-2 items-center">
          <Label value="Disable Auto Select" />
          <Checkbox
            className="checked:bg-plum dark:checked:bg-plum"
            {...register("disableAutoSelect")}
          />
        </div>
      </div>
      {isNullish(optionSource) ? (
        <>
          <div className="flex flex-row gap-2 items-center">
            <TextInput
              className="w-fit"
              addon="New"
              value={newValue}
              onChange={e => {
                setNewValue(e.target.value);
              }}
              sizing="sm"
            />
            {!isNullish(newValue.trim()) && (
              <PlusCircleIcon
                className="w-5 text-grass cursor-pointer"
                onClick={addValue}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {isNullish(optionCode) && (
            <div className="flex flex-row gap-2 items-center">
              <SearchSelect
                // @ts-ignore
                options={optionSources[optionSource]
                  // @ts-ignore
                  ?.filter(o => values && !values.includes(o.id.toString()))
                  // @ts-ignore
                  .map(o => ({
                    value: o.id,
                    name: `${o.name}${o.color ? " " + o.color : ""}`,
                  }))}
                placeholder="Select New Value"
                value={newValue}
                onChange={val => {
                  setNewValue(val);
                }}
              />
              {!isNullish(newValue.trim()) && (
                <PlusCircleIcon
                  className="w-5 text-grass cursor-pointer"
                  onClick={addValue}
                />
              )}
            </div>
          )}
        </>
      )}
      {values && (
        <div className="select-text flex flex-col">
          <div className="flex flex-row rounded-t-md dark:bg-gray-600 bg-gray-100 py-2 font-semibold uppercase">
            <div className="text-xs px-2 w-48">name</div>
            <div className="text-xs px-2 flex-1">conditions</div>
            <div className="text-xs px-2 w-20">default</div>
            <div className="p-0 w-20" />
          </div>
          <div className="text-black dark:text-white">
            {paginatedValues.map((val, index) => (
              <div
                key={JSON.stringify(val)}
                className={`flex flex-row ${
                  index && index % 2 !== 0 && "dark:bg-gray-600 bg-gray-100"
                }`}
              >
                <div className="w-48 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                  <ValuePopulate source={optionSource} value={val} />
                </div>
                <div className="flex-1 py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                  <ValueCondition value={val} productSetId={productSetId} />
                </div>

                <div
                  className="group py-[5px] w-20 px-2 border-r-[1px] dark:border-gray-500 flex flex-row justify-center cursor-pointer"
                  onClick={() => {
                    setValue("default", val);
                  }}
                >
                  <div
                    className={`
                  ${
                    defaultValue && defaultValue.toString() == val.toString()
                      ? "block"
                      : "hidden group-hover:block"
                  }
                  `}
                  >
                    *
                  </div>
                </div>
                <div className="py-[5px] w-20">
                  <div className="flex flex-row gap-3 w-full justify-center">
                    {isNullish(optionCode) && (
                      <TrashIcon
                        className="w-4 text-red-500 cursor-pointer"
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`${
              scheme == "dark" ? "text-white" : "text-black"
            } flex flex-row items-center justify-end gap-2 mt-1`}
          >
            <ChevronDoubleLeftIcon
              className="w-5 cursor-pointer"
              onClick={() => {
                movePage(-1);
              }}
            />
            <div className="leading-[0]">
              <span className="text-grass">{page}</span> of {totalPages}
            </div>
            <ChevronDoubleRightIcon
              className="w-5 cursor-pointer"
              onClick={() => {
                movePage(1);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const ParamCondition = ({
  availableParams,
}: {
  availableParams: availableParam[];
}) => {
  const { setValue } = useFormContext();

  const [paramCondition, setParamCondition] = useState({
    param: "",
    value: "",
  });

  const availablevalues = availableParams?.find(
    p => p.name == paramCondition.param
  )?.values;

  useEffect(() => {
    if (isNullish(paramCondition.param) || isNullish(paramCondition.value)) {
      return setValue("optionCondition", {
        material: undefined,
      });
    }
    const { param, value } = paramCondition;
    setValue("optionCondition", {
      material: {
        param: {
          name: param,
          value,
        },
      },
    });
  }, [paramCondition]);

  return (
    <div className="flex flex-row flex-wrap gap-2 items-center">
      <Select
        value={paramCondition.param}
        onChange={e =>
          setParamCondition({ ...paramCondition, param: e.target.value })
        }
        sizing="sm"
      >
        <option value="">Param Condition</option>
        <option value="">None</option>
        {availableParams.map(p => (
          <option key={p.name} value={p.name}>
            {p.name}
          </option>
        ))}
      </Select>
      {availablevalues && (
        <Select
          value={paramCondition.value}
          onChange={e =>
            setParamCondition({ ...paramCondition, value: e.target.value })
          }
          sizing="sm"
        >
          <option value="">Param Value</option>
          {availablevalues.map(p => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </Select>
      )}
    </div>
  );
};

interface valueCondition {
  productSetId: number;
  value: number | string;
}

const ValueCondition = ({ value, productSetId }: valueCondition) => {
  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_BY_PID, {
    variables: {
      id: productSetId,
    },
  });
  const optionTypes = ["select"];
  const options = data_options
    ? sort(data_options.productSetOptions)
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const [editing, setEditing] = useState(false);

  const { scheme } = useScheme();

  const { control, setValue } = useFormContext();

  const formConditions: condition[] =
    useWatch({
      name: "conditions",
      control,
    }) || [];

  const myCondition = formConditions?.find(
    c => c.value.toString() == value.toString()
  );

  const [conditions, setConditions] = useState<optionValues[]>(
    myCondition?.conditions || []
  );

  const defaultValues: optionValues = {
    option: 0,
    values: [],
  };

  const [newCondition, setNewCondition] = useState(defaultValues);

  const nullishValues = ["", null, 0, undefined];

  const selectedOption = options?.find(o => o.id == newCondition.option);
  const [selectables, setSelectables] = useState<any[]>([]);
  const [paramSelectables, setParamSelectables] = useState<any[]>([]);

  const { materials, deductions, presets } = useOrderStore();

  useEffect(() => {
    if (selectedOption?.source == "deductionPreset") {
      const _presets = presets.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_presets);
    }
    if (selectedOption?.source == "deductions") {
      const _deductions = deductions.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_deductions);
    }
    if (selectedOption?.source == "inventory") {
      const _materials = materials.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_materials);
    }
    if (!selectedOption?.source && selectedOption?.type == "select") {
      // @ts-ignore
      setSelectables(selectedOption.values);
    }
  }, [selectedOption]);

  const add = () => {
    if (conditions.find(c => c.option == newCondition.option)) {
      return;
    }
    if (isNullish(newCondition.option)) {
      return;
    }
    if (newCondition.values.length == 0) {
      return;
    }
    setConditions(conditions.concat(newCondition));
    setNewCondition(defaultValues);
    setSelectables([]);
    setParamSelectables([]);
  };

  const removeValue = (val: number | string) => {
    setNewCondition({
      ...newCondition,
      values: newCondition.values.filter(c => c !== val),
    });
  };

  const [addingParam, setAddingParam] = useState(false);

  const [newValue, setNewValue] = useState("");
  const addValue = () => {
    setNewCondition({
      ...newCondition,
      values: newCondition.values.concat(newValue),
    });
    setNewValue("");
  };

  const defaultNewParam = {
    where: "",
    value: "",
  };

  const [newParam, setNewParam] = useState(defaultNewParam);

  const addParam = () => {
    const newParamCondition: optionValues = {
      option: newCondition.option,
      param: `${newParam.where}::${newParam.value}`,
      values: [],
    };

    setConditions(conditions.concat(newParamCondition));
    setNewCondition(defaultValues);
    setSelectables([]);
    setParamSelectables([]);
    setNewParam(defaultNewParam);
    setAddingParam(false);
  };

  const save = () => {
    const existingConditions: condition[] = formConditions;
    const newConditions = existingConditions
      .filter(c => c.value.toString() !== value.toString())
      .concat({
        value,
        conditions,
      });
    setValue("conditions", newConditions);
    setEditing(false);
  };

  const cancel = () => {
    setEditing(false);
  };

  return (
    <div className="w-full h-full group flex flex-row gap-2 justify-between items-center">
      <div>
        {myCondition?.conditions?.map(po => (
          <Badge key={po.option} color="purple">
            <div className="flex flex-row gap-1 items-center">
              {options.find(o => o.id == po.option)?.name}:
              {po.values && (
                <>
                  {po.values?.map(val => (
                    <Fragment key={val}>
                      <ValuePopulate
                        source={options.find(o => o.id == po.option)?.source}
                        value={val}
                      />
                      ,
                    </Fragment>
                  ))}
                </>
              )}
            </div>
          </Badge>
        ))}
      </div>
      <PencilIcon
        className="hidden group-hover:block w-4 text-grass cursor-pointer"
        onClick={() => {
          setEditing(true);
        }}
      />
      <Modal
        show={editing}
        onClose={() => {
          setEditing(false);
        }}
        className={`${scheme}`}
      >
        <Modal.Header>Edit Value Conditions</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-2">
            <Select
              addon="Option"
              sizing="sm"
              value={newCondition.option?.toString()}
              disabled={newCondition.values.length > 0 ? true : false}
              onChange={e => {
                setNewCondition({
                  ...newCondition,
                  option: Number(e.target.value),
                });
              }}
            >
              <option value="">select option</option>
              {options
                ?.filter(
                  o =>
                    !conditions.find(
                      c => c.option.toString() == o.id.toString()
                    )
                )
                .map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </Select>
            {!addingParam && (
              <div className="flex flex-row items-center gap-2">
                <Select
                  addon="Value"
                  sizing="sm"
                  className="flex-1"
                  value={newValue}
                  onChange={e => {
                    if (nullishValues.includes(e.target.value)) {
                      return;
                    }
                    setNewValue(e.target.value);
                  }}
                >
                  <option value="">select option</option>
                  {selectables
                    ?.filter(
                      option =>
                        !newCondition.values.includes(
                          option?.id?.toString() || option.toString()
                        )
                    )
                    .map((option, i) => (
                      <option key={option.id || i} value={option.id || option}>
                        {option.name || option}
                      </option>
                    ))}
                </Select>
                <PlusCircleIcon
                  className="w-5 text-grass cursor-pointer"
                  onClick={addValue}
                />
              </div>
            )}
            {addingParam && (
              <div className="flex flex-col gap-2">
                <Select
                  addon="param"
                  sizing="sm"
                  className="flex-1"
                  value={newParam.where}
                  onChange={e => {
                    setNewParam({
                      ...newParam,
                      where: e.target.value,
                    });
                  }}
                >
                  <option value="">select param</option>
                  {paramSelectables.map((param, i) => (
                    <option key={i} value={param.name}>
                      {param.name}
                    </option>
                  ))}
                </Select>

                <Select
                  addon="value"
                  sizing="sm"
                  className="flex-1"
                  disabled={newParam.where == "" ? true : false}
                  value={newParam.value}
                  onChange={e => {
                    setNewParam({
                      ...newParam,
                      value: e.target.value,
                    });
                  }}
                >
                  <option value="">select param</option>
                  {paramSelectables
                    .find(param => newParam.where == param.name)
                    // @ts-expect-error
                    ?.values?.map((val, i) => (
                      <option key={i} value={val}>
                        {val}
                      </option>
                    ))}
                </Select>
              </div>
            )}
            {paramSelectables.length > 0 && !addingParam && (
              <div className="flex flex-row justify-end">
                params found, add param condition instead of values?
                <a
                  className="text-blue-500 underline cursor-pointer ml-1"
                  onClick={() => {
                    setAddingParam(true);
                  }}
                >
                  switch
                </a>
              </div>
            )}
            {addingParam && (
              <div className="flex flex-row justify-end">
                {" "}
                <a
                  className="text-blue-500 underline cursor-pointer ml-1"
                  onClick={() => {
                    setAddingParam(false);
                  }}
                >
                  add value condition
                </a>
              </div>
            )}
            <div className="flex flex-row flex-wrap items-center gap-2 justify-between">
              <div className="flex flex-row flex-wrap gap-2 items-center">
                {newCondition.values.map((c, i) => {
                  const option = options.find(o => o.id == newCondition.option);
                  const source = option?.source;
                  const value = c;
                  return (
                    <Badge
                      key={c}
                      color="purple"
                      className="cursor-pointer"
                      onClick={() => {
                        removeValue(c);
                      }}
                    >
                      <div className="flex flex-row gap-1 items-center">
                        <ValuePopulate source={source} value={value} />
                        <XMarkIcon className="w-4" />
                      </div>
                    </Badge>
                  );
                })}
              </div>

              <Button
                onClick={() => {
                  if (addingParam) {
                    addParam();
                  } else {
                    add();
                  }
                }}
                outline
                size="xs"
                gradientDuoTone="greenToBlue"
              >
                add condition
              </Button>
            </div>

            {conditions.length > 0 && (
              <div className="flex flex-col dark:text-white">
                <div className="bg-gray-200 dark:bg-gray-500 rounded-t-md flex flex-row gap-2 items-center">
                  <div className="px-2 py-[5px] w-20">Config</div>
                  <div className="px-2 py-[5px] flex-1">Values</div>
                  <div className="px-2 py-[5px] w-10" />
                </div>
                {conditions.map((item, index) => {
                  const option = options.find(o => o.id == item.option);
                  const source = option?.source;
                  return (
                    <div
                      key={item.option}
                      className="rounded-md flex flex-row gap-2 items-center"
                    >
                      <div className="px-2 py-[5px] w-20 overflow-clip">
                        {options.find(o => o.id == item.option)?.name}
                      </div>
                      <div className="px-2 py-[5px] flex-1 flex flex-row gap-2 justify-start">
                        {item.param && (
                          <Badge
                            key={item.param}
                            color="purple"
                            className="cursor-pointer"
                          >
                            {item.param}
                          </Badge>
                        )}
                        {item.values?.map(value => (
                          <Badge
                            key={value}
                            color="purple"
                            className="cursor-pointer"
                          >
                            <ValuePopulate source={source} value={value} />
                          </Badge>
                        ))}
                      </div>

                      <div className="px-2 py-[5px] w-10 flex flex-row gap-2">
                        <MinusCircleIcon
                          className="text-red-500 w-5 cursor-pointer"
                          onClick={() => {
                            setConditions(
                              conditions.filter(c => c.option !== item.option)
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-row justify-end">
          <Button color="purple" size="xs" onClick={cancel}>
            cancel
          </Button>
          <Button
            gradientDuoTone="purpleToBlue"
            size="xs"
            outline
            onClick={save}
          >
            save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const Parent = ({ productSetId }: { productSetId: number }) => {
  const { scheme } = useScheme();
  const { control } = useFormContext();
  const { append, remove } = useFieldArray({
    name: "parent",
    control,
  });
  const addedOptions: optionValues[] =
    useWatch({
      name: "parent",
      control,
    }) || [];

  const { materials, deductions, presets } = useOrderStore();

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: Number(productSetId) },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const defaultValues: optionValues = {
    option: 0,
    type: "",
    values: [],
  };

  const [newParentOption, setNewParentOption] = useState(defaultValues);

  const optionTypes = ["select"];
  const options = data_options
    ? sort(data_options.productSetOptions)
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const nullishValues = ["", null, 0, undefined];

  const selectedOption = options?.find(o => o.id == newParentOption.option);
  const [selectables, setSelectables] = useState<any[]>([]);

  useEffect(() => {
    if (selectedOption?.source == "deductionPreset") {
      const _presets = presets.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_presets);
    }
    if (selectedOption?.source == "deductions") {
      const _deductions = deductions.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_deductions);
    }
    if (selectedOption?.source == "inventory") {
      const _materials = materials.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_materials);
    }
    if (!selectedOption?.source && selectedOption?.type == "select") {
      // @ts-ignore
      setSelectables(selectedOption.values);
    }
  }, [selectedOption]);

  const add = () => {
    if (addedOptions.find(c => c.option == newParentOption.option)) {
      return;
    }
    if (newParentOption.values.length < 1) {
      return;
    }
    append(newParentOption);
    setNewParentOption(defaultValues);
  };

  const removeValue = (val: number | string) => {
    setNewParentOption({
      ...newParentOption,
      values: newParentOption.values.filter(c => c !== val),
    });
  };

  const [newValue, setNewValue] = useState("");
  const addValue = () => {
    if (nullishValues.includes(newValue)) {
      return;
    }
    setNewParentOption({
      ...newParentOption,
      values: newParentOption.values.concat(newValue),
    });
  };

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Config */}
      <div className="flex flex-row gap-2 items-center">
        <Select
          addon="Option"
          sizing="sm"
          value={newParentOption.option?.toString()}
          disabled={newParentOption.values.length > 0 ? true : false}
          onChange={e => {
            setNewParentOption({
              ...newParentOption,
              option: Number(e.target.value),
            });
          }}
        >
          <option value="">select option</option>
          {options
            ?.filter(
              o =>
                !addedOptions.find(c => c.option.toString() == o.id.toString())
            )
            .map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </Select>
        <Select
          addon="Value"
          sizing="sm"
          value={newValue}
          onChange={e => {
            setNewValue(e.target.value);
          }}
        >
          <option value="">select option</option>
          {selectables
            ?.filter(
              option =>
                !newParentOption.values.includes(
                  option?.id?.toString() || option.toString()
                )
            )
            .map((option, i) => (
              <option key={option.id || i} value={option.id || option}>
                {option.name || option}
              </option>
            ))}
        </Select>
        {!isNullish(newValue) && (
          <PlusCircleIcon
            className="w-5 text-grass cursor-pointer"
            onClick={addValue}
          />
        )}
      </div>
      <div className="flex flex-row flex-wrap items-center gap-2 justify-between">
        <div className="flex flex-row flex-wrap gap-2 items-center">
          {newParentOption.values.map((c, i) => {
            const option = options.find(o => o.id == newParentOption.option);
            const source = option?.source;
            const value = c;
            return (
              <Badge
                key={c}
                color="purple"
                className="cursor-pointer"
                onClick={() => {
                  removeValue(c);
                }}
              >
                <div className="flex flex-row gap-1 items-center">
                  <ValuePopulate source={source} value={value} />
                  <XMarkIcon className="w-4" />
                </div>
              </Badge>
            );
          })}
        </div>

        <Button onClick={add} outline size="xs" gradientDuoTone="greenToBlue">
          add condition
        </Button>
      </div>
      {/* Added Parent Options */}
      {addedOptions.length > 0 && (
        <div className="flex flex-col dark:text-white">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-t-md flex flex-row gap-2 items-center">
            <div className="px-2 py-[5px] w-20">Config</div>
            <div className="px-2 py-[5px] w-20">Type</div>
            <div className="px-2 py-[5px] flex-1">Values</div>
            <div className="px-2 py-[5px] w-10" />
          </div>
          {addedOptions.map((item, index) => {
            const option = options.find(o => o.id == item.option);
            const source = option?.source;
            return (
              <div
                key={item.option}
                className="rounded-md flex flex-row gap-2 items-center"
              >
                <div className="px-2 py-[5px] w-20 overflow-clip">
                  {options.find(o => o.id == item.option)?.name}
                </div>
                <div className="px-2 py-[5px] w-20 overflow-clip">
                  {item.type}
                </div>
                <div className="px-2 py-[5px] flex-1 flex flex-row gap-2 justify-start">
                  {item.values?.map(value => (
                    <Badge
                      key={value}
                      color="purple"
                      className="cursor-pointer"
                    >
                      <ValuePopulate source={source} value={value} />
                    </Badge>
                  ))}
                </div>

                <div className="px-2 py-[5px] w-10 flex flex-row gap-2">
                  <MinusCircleIcon
                    className="text-red-500 w-5 cursor-pointer"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Modifiers = ({ productSetId }: { productSetId: number }) => {
  const { control, register } = useFormContext();
  const { append, replace } = useFieldArray({
    name: "modifiers",
    control,
  });
  const modifiers: modifier[] =
    useWatch({
      name: "modifiers",
      control,
    }) || [];

  const remove = (index: number) => {
    replace(modifiers.filter((m, i) => i !== index));
  };

  const { data: data_productSet } = useQuery(GET_PRODUCT_SET_BY_ID, {
    variables: {
      id: productSetId,
    },
  });

  const productId = data_productSet?.productSets_by_pk.product.id;

  const { data: data_mats } = useQuery(GET_MATERIALS_CONDITIONAL, {
    variables: {
      where: {
        productId: { _eq: productId },
      },
    },
  });
  const materials = data_mats?.materials;

  const source = useWatch({
    name: "source",
    control,
  });

  const { scheme } = useScheme();
  const { units } = useOrderStore();

  return (
    <div className="flex flex-col gap-2">
      <NewModifier materials={materials} append={append} />
      <div className="select-text flex flex-col gap-1">
        <div className="flex flex-row rounded-t-md dark:bg-gray-600 bg-gray-100 py-2 font-semibold uppercase">
          <div className="text-xs px-2 flex-1">type</div>
          <div className="text-xs px-2 flex-1">value</div>
          <div className="text-xs px-2 flex-1">prev</div>
          <div className="text-xs px-2 flex-1">new</div>
          <div className="text-xs px-2 flex-1">id</div>
          <div className="text-xs px-2 flex-1">qty</div>
          <div className="text-xs px-2 flex-1">unit</div>
          <div className="p-0 w-20" />
        </div>
        <div className="text-black dark:text-white">
          {modifiers.map((modifier, index) => (
            <div
              key={index}
              className={`flex flex-row ${
                index && index % 2 !== 0 && "dark:bg-gray-600 bg-gray-100"
              }`}
            >
              <div className="flex-1 py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                {modifier.type}
              </div>
              <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                <ValuePopulate source={source} value={modifier.value} />
              </div>
              <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                <ValuePopulate source="inventory" value={modifier.prev} />
              </div>
              <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                <ValuePopulate source="inventory" value={modifier.new} />
              </div>
              <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                <ValuePopulate source="inventory" value={modifier.id} />
              </div>
              <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                <input
                  {...register(`modifiers.${index}.qty`)}
                  className="bg-transparent w-full"
                />
              </div>
              <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
                <select
                  className={`${
                    scheme == "dark" ? "text-white" : "text-black"
                  } bg-transparent w-full
                  border-none outline-none
                  text-sm
                  py-0 px-0
                  `}
                  {...register(`modifiers.${index}.unit`)}
                >
                  {units.map(u => (
                    <option
                      key={u.id}
                      value={u.id}
                      className={`${
                        scheme == "dark" ? "text-black" : "text-white"
                      } text-sm`}
                    >
                      {u.name}
                    </option>
                  ))}
                </select>
                {/* {units.find(u => u.id == modifier.unit)?.name || modifier.unit} */}
              </div>
              <div className="py-[5px] w-20">
                <div className="flex flex-row gap-3 w-full justify-center">
                  <TrashIcon
                    className="w-4 text-red-500 cursor-pointer"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const NewModifier = ({
  materials,
  append,
}: {
  materials?: MaterialsType[];
  append: UseFieldArrayAppend<FieldValues, "modifiers">;
}) => {
  const defaultValue: modifier = {
    type: "add",
    value: "",
    id: "",
    new: "",
    prev: "",
    qty: "",
    unit: 1,
  };
  const [newModifier, setNewModifier] = useState(defaultValue);

  const add = () => {
    const { type, id, new: newId, prev, qty } = newModifier;
    if (type == "add" || type == "multiply") {
      if (isNullish(id) || isNullish(qty)) {
        return;
      }
    }
    if (type == "replace") {
      if (isNullish(newId) || isNullish(prev)) {
        return;
      }
    }
    append(newModifier);
  };

  const { control } = useFormContext();

  const values: string[] =
    useWatch({
      name: "values",
      control,
    }) || [];
  const source: string = useWatch({
    name: "source",
    control,
  });

  useEffect(() => {
    setNewModifier({
      ...defaultValue,
      type: newModifier.type,
    });
  }, [newModifier.type]);

  return (
    <div className="flex flex-row gap-2 items-center flex-wrap">
      <Select
        value={newModifier.type}
        onChange={e => {
          setNewModifier({
            ...newModifier,
            type: e.target.value,
          });
        }}
        sizing="sm"
        addon="type"
        className="w-44"
      >
        <option>add</option>
        <option>replace</option>
        <option>multiply</option>
      </Select>
      <Select
        sizing="sm"
        addon="Value"
        className="w-fit"
        placeholder="trigger value"
        value={newModifier.value}
        onChange={e => {
          setNewModifier({ ...newModifier, value: e.target.value });
        }}
      >
        <option value="">always</option>
        {values?.map((v, i) => (
          <option key={i} value={v}>
            <ValuePopulate source={source} value={v} />
          </option>
        ))}
      </Select>
      {newModifier.type == "add" && (
        <AddParam
          materials={materials}
          newModifier={newModifier}
          setNewModifier={setNewModifier}
        />
      )}
      {newModifier.type == "replace" && (
        <ReplaceParam
          materials={materials}
          newModifier={newModifier}
          setNewModifier={setNewModifier}
        />
      )}
      {newModifier.type == "multiply" && (
        <MultiplyParam
          materials={materials}
          newModifier={newModifier}
          setNewModifier={setNewModifier}
        />
      )}
      <PlusCircleIcon className="w-5 cursor-pointer text-grass" onClick={add} />
    </div>
  );
};

interface modifierParam {
  materials?: MaterialsType[];
  newModifier: modifier;
  setNewModifier: React.Dispatch<React.SetStateAction<modifier>>;
}

const AddParam = ({
  materials,
  newModifier,
  setNewModifier,
}: modifierParam) => {
  const idOptions: { name: string; value: string | number }[] = [
    { name: "Selected Item", value: "selected" },
  ];
  const materialOptions = materials
    ? materials.map(m => ({
        name: `${m.name} ${m.color}`,
        value: m.id,
      }))
    : [];

  const { units } = useOrderStore();

  return (
    <>
      <SearchSelect
        onChange={val => {
          setNewModifier({
            ...newModifier,
            id: val,
          });
        }}
        value={newModifier.id as string}
        placeholder="Material To Add"
        options={idOptions.concat(...materialOptions)}
      />
      <TextInput
        sizing="sm"
        value={newModifier.qty}
        onChange={e => {
          setNewModifier({
            ...newModifier,
            qty: e.target.value,
          });
        }}
        className="flex-1"
        placeholder="Qty Formula"
        addon="Qty"
      />

      <Select
        sizing="sm"
        value={newModifier.unit}
        onChange={e => {
          setNewModifier({
            ...newModifier,
            unit: Number(e.target.value),
          });
        }}
        className="flex-1"
        placeholder="Unit"
        addon="unit"
      >
        {units.map(u => (
          <option key={u.id} value={u.id}>
            {u.name}
          </option>
        ))}
      </Select>
    </>
  );
};

const ReplaceParam = ({
  materials,
  newModifier,
  setNewModifier,
}: modifierParam) => {
  const idOptions: { name: string; value: string | number }[] = [
    { name: "Selected Item", value: "selected" },
  ];
  const materialOptions = materials
    ? materials.map(m => ({
        name: `${m.name} ${m.color}`,
        value: m.id,
      }))
    : [];

  return (
    <>
      <SearchSelect
        onChange={val => {
          setNewModifier({
            ...newModifier,
            prev: val,
          });
        }}
        value={newModifier.prev as string}
        placeholder="Change From"
        options={idOptions.concat(...materialOptions)}
      />
      <SearchSelect
        onChange={val => {
          setNewModifier({
            ...newModifier,
            new: val,
          });
        }}
        value={newModifier.new as string}
        placeholder="Change To"
        options={idOptions.concat(...materialOptions)}
      />
    </>
  );
};

const MultiplyParam = ({
  materials,
  newModifier,
  setNewModifier,
}: modifierParam) => {
  const idOptions: { name: string; value: string | number }[] = [
    { name: "Selected Item", value: "selected" },
  ];
  const materialOptions = materials
    ? materials.map(m => ({
        name: `${m.name} ${m.color}`,
        value: m.id,
      }))
    : [];

  return (
    <>
      <SearchSelect
        onChange={val => {
          setNewModifier({
            ...newModifier,
            id: val,
          });
        }}
        value={newModifier.id as string}
        placeholder="Material to multiply"
        options={idOptions.concat(...materialOptions)}
      />
      <TextInput
        sizing="sm"
        value={newModifier.qty}
        type="number"
        onChange={e => {
          setNewModifier({
            ...newModifier,
            qty: e.target.value,
          });
        }}
        className="w-40"
        placeholder="Qty"
        addon="Qty"
      />
    </>
  );
};
