import { Button, Modal, Select, Spinner } from "flowbite-react";
import { useBoolean } from "usehooks-ts";
import { useScheme } from "../../../store/schemeStore";
import { useState } from "react";
import { GET_ORDERS_CONDITIONAL } from "../workorder/gqls";
import { useMutation, useQuery } from "@apollo/client";
import { AssignmentType } from "./types";
import useGetOrderProducts from "../workorder/useGetOrderProducts";
import { ADD_ASSIGNMENT_ORDER_BY_PK } from "./gqls";

interface props {
  assignment: AssignmentType;
}

export default function IncludeDoc({ assignment }: props) {
  const { data } = useQuery(GET_ORDERS_CONDITIONAL, {
    variables: {
      where: {
        processId: { _eq: assignment.processId },
        id: { _nin: assignment.orders },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const orders =
    data?.workorder.filter(order => !assignment.orders.includes(order.id)) ||
    [];

  const getOrderProducts = useGetOrderProducts();

  const populatedOrders =
    orders.map(order => {
      return {
        ...order,
        products: getOrderProducts(order),
      };
    }) || [];

  const {
    value: including,
    setFalse: setIncludingFalse,
    setTrue: setIncludingTrue,
  } = useBoolean();

  const { scheme } = useScheme();
  const [docToAdd, setDocToAdd] = useState<number | undefined>();

  const [addDoc, { loading }] = useMutation(ADD_ASSIGNMENT_ORDER_BY_PK);

  const includeOrder = () => {
    if (!docToAdd) {
      return;
    }
    addDoc({
      variables: {
        id: assignment.id,
        orderId: docToAdd,
      },
      onCompleted() {
        setIncludingFalse();
      },
    });
  };

  return (
    <div className="flex flex-row justify-end">
      <Button
        size="xs"
        gradientDuoTone="purpleToBlue"
        outline
        onClick={setIncludingTrue}
      >
        Include existing doc
      </Button>
      <Modal
        show={including}
        onClose={setIncludingFalse}
        className={`${scheme} !h-full !items-start`}
      >
        <Modal.Header>Select Doc to include</Modal.Header>
        <Modal.Body>
          <Select
            value={docToAdd}
            onChange={e => setDocToAdd(Number(e.target.value))}
          >
            <option>select doc to add</option>
            {populatedOrders?.map(order => (
              <option key={order.id} value={order.id}>
                {order.type.name}
                {" -"}
                {order.orderRef}{" "}
                {order.products.map(p => `${p.name} (${p.qty})`).join(" ")}
              </option>
            ))}
          </Select>
        </Modal.Body>
        <Modal.Footer className="justify-end gap-2">
          <Button size="sm" color="purple" onClick={setIncludingFalse}>
            Cancel
          </Button>
          <Button
            outline
            size="sm"
            gradientDuoTone="purpleToBlue"
            onClick={includeOrder}
          >
            <div className="flex flex-row gap-2 items-center">
              {loading && <Spinner size="sm" color="purple" />}
              Include
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
