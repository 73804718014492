import { ProcessType } from "../../process/types";
import { PaymentType } from "./types";
import DynamicHeroIcon from "../../../../comps/hIcon";
import { currencyFormat } from "../../../../utils/numberMethods";
import dayjs from "dayjs";
import { useState } from "react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { gql, useMutation } from "@apollo/client";
import { Spinner } from "flowbite-react";

export const DELETE_PAYMENT_LOG = gql`
  mutation DELETE_PAYMENT_LOG($id: Int!) {
    delete_paymentLogs_by_pk(id: $id) {
      id
    }
  }
`;

interface props {
  payment: PaymentType;
  process: ProcessType;
}

export default function PaymentLog({ payment, process }: props) {
  const isMinus = payment.amount < 0;
  const [extended, setExtended] = useState(false);

  const toggleExtended = () => {
    setExtended(!extended);
  };

  const [delete_paymentLog, { loading }] = useMutation(DELETE_PAYMENT_LOG, {
    variables: { id: payment.id },
    update(cache, { data: { delete_paymentLogs_by_pk: deletedPayment } }) {
      const normalizedId = cache.identify({
        id: deletedPayment.id,
        __typename: "paymentLogs",
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const deletePayment = () => {
    const proceed = confirm(
      "do you really want to delete this payment record?"
    );
    if (proceed) {
      delete_paymentLog();
    }
  };

  return (
    <div
      className={`shadow-md bg-white dark:bg-gray-800 rounded-md p-3 flex flex-col gap-2 ${
        !extended && "dark:hover:bg-gray-700 hover:bg-gray-50"
      }`}
    >
      <div
        className="flex flex-row gap-2 justify-between cursor-pointer"
        onClick={toggleExtended}
      >
        <div className="flex flex-row gap-2 items-center">
          {dayjs(payment.updated_at).format("DD MMM YY")}
          <DynamicHeroIcon class="w-4" icon={payment.paymentMethod.iconName} />
        </div>
        <div className={`${isMinus ? "text-red-500" : ""}`}>
          {currencyFormat(payment.amount)}
        </div>
      </div>
      <div className={`w-full ${extended ? "flex" : "hidden"} flex-col gap-2`}>
        <textarea
          rows={1}
          value={payment.comment || undefined}
          disabled
          className="resize-none text-sm dark:bg-gray-700 rounded-md border-none "
        />
        <div className="flex flex-row justify-end gap-2 items-center">
          <span className="capitalize">
            By {payment.user.firstName} {payment.user.sirName}
          </span>
          {loading ? (
            <Spinner className="w-4" light />
          ) : (
            <TrashIcon
              onClick={deletePayment}
              className="text-red-500 w-4 cursor-pointer"
            />
          )}
        </div>
      </div>
    </div>
  );
}
