import { MaterialsType } from "../../../../../materials/types";

export default function matDisplayName(
  mat?: Partial<MaterialsType>,
  nameScope?: string[]
) {
  if (!mat) {
    return "N/A";
  }

  let dpValue = mat?.name ? `${mat.name} ${mat.color}` : "";

  if (nameScope) {
    const matName = nameScope.reduce<string[]>((prev, cur) => {
      let newName = prev;

      if (cur == "brand" && mat?.brand) {
        return newName.concat(mat.brand);
      }

      if (cur == "name" && mat?.name) {
        return newName.concat(mat.name);
      }

      if (cur == "color" && mat?.color) {
        return newName.concat(mat.color);
      }

      if (cur == "size" && mat?.size) {
        return newName.concat(mat.size.toString());
      }

      if (cur == "width" && mat?.widthDeduction) {
        return newName.concat(mat.widthDeduction.toString());
      }

      if (cur == "height" && mat?.heightDeduction) {
        return newName.concat(mat.heightDeduction.toString());
      }

      return newName;
    }, []);

    dpValue = matName.join(" ");
  }

  return dpValue;
}
