import { filteredLocation, curtainLabel } from "./types";

export default function getCurtainLabels(locations: filteredLocation[]) {
  const openings = locations
    .map(location => ({
      ...location,
      name:
        location.name || `# ${location.index !== undefined && location.index}`,
    }))
    .map(l =>
      l.openings.map(opening => ({
        ...opening,
        locationName: l.name,
        name: opening.name || (opening.index !== undefined && opening.index),
      }))
    )
    .flat();

  const labels: curtainLabel[] = [];

  let itemNum = 0;

  for (const opening of openings) {
    for (const item of opening.items) {
      itemNum++;

      const loc = `${opening.locationName}.${opening.name}`;

      const { calcs, options, width, height } = item;

      const presets = options.filter(
        o => o.source == "deductionPreset" && !o.noCalc
      );

      const ctrl = options.find(option => option.name == "Ctrl")?.value;

      const masterQty = ctrl == "CO" ? 2 : 1;

      for (const preset of presets) {
        const myOptions = options.filter(o => o.name.includes(preset.name));

        const myCalcs = calcs.filter(calc =>
          myOptions.some(o => o.id == calc.optionId)
        );

        const fabric = options.find(
          o => o.name == `Fabric ${preset.name}`
        )?.displayValue;

        const drop = myCalcs.find(calc => calc.name == "DropCal")?.size;
        const hem = myCalcs.find(calc => calc.name == "Hem")?.size;

        const heading = myOptions.find(o => o.group == "Heading")?.displayValue;
        const lining = options.find(o => o.name == "Lining")?.displayValue;
        const careCode = myCalcs.find(calc => calc.name == "CareCode")?.size;
        const hoistCut = myCalcs.find(calc => calc.name == "Hoist_Cut")?.size;
        const fabricW = Number(
          String(myCalcs.find(calc => calc.name == "Fabric W")?.size).split(
            " "
          )[0]
        );
        const fabricH = myCalcs.find(calc => calc.name == "Fabric H")?.size;
        const curtainW = Number(
          String(myCalcs.find(calc => calc.name == "Curtain W")?.size).split(
            " "
          )[0]
        );
        const curtainH = myCalcs.find(calc => calc.name == "Curtain H")?.size;
        const hookType = myOptions.find(
          o => !o.noCalc && o.group == "Hook"
        )?.displayValue;
        const hooks =
          Number(myCalcs.find(calc => calc.name == "BaseCarriers")?.size) /
          masterQty;

        let qty = masterQty;

        if (
          !isNaN(Number(drop)) &&
          Number(drop) !== 0 &&
          Number(drop) !== Infinity
        ) {
          qty = Number(drop) * masterQty;
        }

        const label: curtainLabel = {
          location: loc,
          width,
          height,
          drop: Number(drop),
          hem: String(hem),
          fabricW,
          fabricH: Number(fabricH),
          curtainW,
          curtainH: Number(curtainH),
          side: undefined,
          hoistCut: Number(hoistCut),
          hooks,
          hookType: hookType ? String(hookType) : "",
          lining:
            !lining || String(lining) == "NoLining" ? "Nill" : String(lining),
          careCode: careCode ? String(careCode) : "",
          heading: String(heading) || "",
          fabric: String(fabric) || "",
          ctrl: String(ctrl),
          itemNum,
        };

        const _labels = Array(qty == Infinity ? 1 : qty)
          .fill(label)
          .map((label, i) => ({
            ...label,
            side: masterQty < 2 ? undefined : i == 0 ? "L" : "R",
          }));

        labels.push(..._labels);
      }
    }
  }

  return labels;
}
