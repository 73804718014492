import {
  Badge,
  Button,
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { useForm } from "react-hook-form";
import { GET_RECORD_TYPE, INSERT_RECORD_MULTIPLE } from "./gqls";
import { useMutation, useQuery } from "@apollo/client";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import "./calendar.css";
import { TrashIcon } from "@heroicons/react/24/solid";
import { GET_RECORD } from "./gqls";
import { useAuthStore } from "../../../../store/authStore";
import { useEffect, useState } from "react";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { coerceNumber } from "../../process/types";
import { weekdays } from "../../../../types/calendarTypes";
import customParseFormat from "dayjs/plugin/customParseFormat";
import getBase64 from "../../../../utils/getBase64";
import { upload } from "../../../../utils/fileAPI";
dayjs.extend(customParseFormat);
const ISDEV = import.meta.env.DEV;

const resolver = z
  .object({
    type: coerceNumber,
    dates: z.array(z.string()).min(1, { message: "select at least one date" }),
    desc: z.string().min(2, { message: "description is required" }),
    file: z.any(),
  })
  .refine(
    data => {
      return data.type !== 0;
    },
    {
      message: "select a type",
      path: ["type"],
    }
  );

interface props {
  cb?: () => void;
}

export default function RecordMore({ cb }: props) {
  const { user } = useAuthStore();

  const [curDate, setCurDate] = useState(new Date());

  const firstDay = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
  const lastDay = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);

  const defaultValues: {
    type: number;
    dates: string[];
    desc: string;
    file: any;
  } = {
    type: 0,
    dates: [],
    desc: "",
    file: undefined,
  };

  const [insert_records, { loading: inserting }] = useMutation(
    INSERT_RECORD_MULTIPLE
  );

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(resolver),
  });

  const dates = watch("dates");

  const { data: data_types } = useQuery(GET_RECORD_TYPE);
  const types = data_types?.recordType.filter(t => {
    if (t.name == "standard") {
      return false;
    }

    if (
      t.name == "missed" &&
      dates &&
      dates.find(d =>
        dayjs(d, "DD/MM/YYYY").isAfter(dayjs(new Date().setHours(23, 59, 59)))
      )
    ) {
      return false;
    }
    return true;
  });

  const { data, loading, refetch } = useQuery(GET_RECORD, {
    variables: {
      where: {
        _and: [
          {
            who: {
              _eq: user?.id,
            },
          },
          {
            start: {
              _gte: dayjs(firstDay.setHours(0, 0, 0, 0)).format(),
              _lte: dayjs(lastDay.setHours(23, 59, 59, 59)).format(),
            },
          },
        ],
      },
    },
    fetchPolicy: "cache-and-network",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.records) {
        const filtered = dates.filter(
          date =>
            !data.records.find(d => dayjs(d.start).isSame(dayjs(date), "date"))
        );

        setValue("dates", filtered);
      }
    },
  });

  const exists = (date: string | Date) =>
    data?.records.find(d => dayjs(d.start).isSame(dayjs(date), "date"))
      ? true
      : false;

  const toggleDate = (date: string) => {
    if (exists(dayjs(date, "DD/MM/YYYY").format("YYYY-MM-DD"))) {
      return;
    }
    if (dates.includes(date)) {
      setValue(
        "dates",
        dates.filter(d => d !== date)
      );
    } else {
      setValue("dates", dates.concat(date));
    }
  };

  const onSubmit = handleSubmit(async data => {
    const records = data.dates.map(d => {
      const date = dayjs(d, "DD/MM/YYYY").format("YYYY-MM-DD");
      const day = dayjs(date).format("ddd").toLowerCase();
      const dayTerm = user?.hours && user?.hours[day as weekdays];

      const start = new Date(date).setHours(dayTerm?.start || 7, 0, 0, 0);
      const end = new Date(date).setHours(dayTerm?.end || 16, 0, 0, 0);
      const brk = dayTerm?.break || 30;

      return {
        who: user?.id,
        typeID: data.type,
        statusID: 5,
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
        desc: data.desc,
        break: brk,
      };
    });

    insert_records({
      variables: {
        objects: records,
      },
      onError(error) {
        console.log(error);
      },
      onCompleted: async () => {
        const file = data.file && data.file[0];
        refetch();

        if (file && user?.id) {
          for await (const date of data.dates) {
            const D = dayjs(date, "DD/MM/YYYY")
              .format("YYYY-MM-DD")
              .toLowerCase();
            const path = [
              "EZILUX",
              "26 HR",
              "Users DO NOT DELETE",
              String(user?.id),
              "records",
              D,
            ];
            const res = (await getBase64(file)) as string;
            const finalPath = path.concat(file.name);
            await upload(res, finalPath);
          }
        }
        reset();
        if (cb) {
          cb();
        }
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Modal.Body className="dark:bg-gray-800 flex flex-col gap-2 dark:text-white">
        <div className="mb-4">
          Request <span className="text-grass">leave</span> or{" "}
          <span className="text-grass">missing attendance</span> for approval
        </div>
        <Label value="Dates" />
        <div className="flex flex-row flex-wrap gap-2 mb-2">
          <div className="flex-[2] min-w-[320px]">
            <Calendar
              calendarType="US"
              className="bg-transparent dark:text-white"
              onClickDay={date => {
                toggleDate(dayjs(date).format("DD/MM/YYYY"));
              }}
              onActiveStartDateChange={d => {
                setCurDate(new Date(d.activeStartDate || ""));
              }}
              tileClassName={({ date }) => {
                const cn: string[] = [];
                if (dates.includes(dayjs(date).format("DD/MM/YYYY"))) {
                  cn.push("react-calendar__tile--selected");
                }
                if (loading) {
                  cn.push("react-calendar__tile--loading");
                }
                if (exists(date)) {
                  cn.push("react-calendar__tile--disabled");
                }

                return cn.join(" ");
              }}
            />
          </div>
          <div className="flex-1 flex min-w-[150px] flex-col gap-2 items-center">
            <Label value="Selected" />
            <hr className="dark:border-gray-500 w-full mb-1" />
            {dates.map(date => (
              <Badge
                onClick={() => {
                  toggleDate(date);
                }}
                color="purple"
                key={date}
                size="md"
                className="cursor-pointer w-full flex flex-row justify-center"
              >
                <div className="flex flex-row gap-2">
                  {date}
                  <TrashIcon className="w-4 pb-[1px]" />
                </div>
              </Badge>
            ))}
            {dates.length == 0 && (
              <Badge
                color="purple"
                size="md"
                className="cursor-pointer w-full flex flex-row justify-center"
              >
                <div
                  className={`flex flex-row gap-2 ${
                    errors.dates?.message && "text-red-500"
                  }`}
                >
                  no dates selected
                </div>
              </Badge>
            )}
          </div>
        </div>
        <hr className="dark:border-gray-500 mb-2" />
        <Label value="Type" />
        <Select
          {...register("type")}
          sizing="sm"
          helperText={errors.type?.message?.toString() || ""}
          color={errors.type?.message ? "failure" : undefined}
        >
          <option value={0}>Select Request Type</option>
          {types?.map(type => (
            <option className="capitalize" key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </Select>
        <Label value="Desc" />
        <Textarea
          {...register("desc")}
          className="resize-none scrollbar-none text-sm"
          helperText={errors.desc?.message?.toString() || ""}
          color={errors.desc?.message ? "failure" : undefined}
          placeholder="please describe the grounds of the request"
        />
        <Label value="Supporting Docs" />
        <TextInput {...register("file")} sizing="xs" type="file" />
      </Modal.Body>
      <Modal.Footer className="dark:bg-gray-800 justify-end gap-2">
        <Button
          onClick={() => {
            reset();
            if (cb) {
              cb();
            }
          }}
          size="sm"
          gradientDuoTone="purpleToBlue"
        >
          Cancel
        </Button>
        <Button type="submit" outline size="sm" gradientDuoTone="purpleToBlue">
          Request
          {inserting && (
            <Spinner size="sm" className="ml-2 mb-[1px]" color="purple" />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
}
