import { useNotificationStore } from "../store/notificationStore";
import MyToast from "./MyToast";

export default function Toasts() {
  const { toasts } = useNotificationStore();

  return (
    <div className="absolute right-5 top-[100%] z-40 flex-col gap-2">
      {toasts.map(toast => (
        <MyToast toast={toast} key={toast.id} />
      ))}
    </div>
  );
}
