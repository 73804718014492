import { useQuery } from "@apollo/client";
import { location } from "./createOrder/types";
import { GET_PRODUCT_SETS } from "./gqls";
import { useCallback } from "react";
import usePopulateOption from "../settings/order/usePopulateOption";

export default function useRefineOrder() {
  const { data } = useQuery(GET_PRODUCT_SETS);
  const ProductSets = data?.productSets;

  const populateOption = usePopulateOption();

  const refineOrder = async (locations: location[]) => {
    const _locations = await Promise.all(
      locations.map(async (l, i) => ({
        ...l,
        index: i + 1,
        openings: await Promise.all(
          l.openings.map(async (o, ix) => {
            const populatedOptions = o.options
              ? await Promise.all(
                  o.options
                    .filter(opt => opt && !opt.noCalc)
                    .map(
                      async opt =>
                        await populateOption({
                          option: opt.id,
                          value: opt.value,
                        })
                    )
                )
              : [];
            return {
              ...o,
              populatedOptions,
              items: await Promise.all(
                o.items.map(async (item, iz) => {
                  const populatedOptions = await Promise.all(
                    item.options
                      .filter(opt => opt && !opt.noCalc)
                      .map(
                        async opt =>
                          await populateOption({
                            option: opt.id,
                            value: opt.value,
                          })
                      )
                  );
                  return { ...item, populatedOptions, index: iz + 1 };
                })
              ),
              index: ix + 1,
            };
          })
        ),
      }))
    );

    return _locations;
  };

  return useCallback(refineOrder, [ProductSets, populateOption]);
}
