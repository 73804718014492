import { TypedDocumentNode, gql } from "@apollo/client";
import { unitArrayType } from "./types";
import { UNIT_CORE_FIELDS } from "./fragments";

export const GET_UNITS: TypedDocumentNode<unitArrayType> = gql`
  ${UNIT_CORE_FIELDS}
  query GET_UNITS {
    units {
      ...UnitCoreFields
    }
  }
`;
