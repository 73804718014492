import { useMutation, gql } from "@apollo/client";
import { Button, Spinner, TextInput, Textarea, Label } from "flowbite-react";
import { NewProcessTypeType, processTypeSchema } from "./types";
import { PROCESS_TYPE_CORE_FIELDS } from "./fragments";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";

export const INSERT_PROCESS_TYPE = gql`
  ${PROCESS_TYPE_CORE_FIELDS}
  mutation INSERT_PROCESS_TYPE(
    $name: String!
    $description: String!
    $color: String!
    $prefix: String
  ) {
    insert_processType_one(
      object: {
        name: $name
        description: $description
        color: $color
        prefix: $prefix
      }
    ) {
      ...ProcessTypeCoreFields
    }
  }
`;

export default function AddProcessType({
  toggleAdding,
}: {
  toggleAdding: () => void;
}) {
  const [insert_processType, { loading, error }] =
    useMutation(INSERT_PROCESS_TYPE);
  if (error) {
    console.log(error);

    addAlert({
      message: "something went wrong cannot add type",
      type: "failure",
    });
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewProcessTypeType>({
    resolver: zodResolver(processTypeSchema),
    defaultValues: {
      name: "",
      description: "",
      prefix: "",
      color: undefined,
    },
  });

  const onSubmit = handleSubmit(async data => {
    insert_processType({
      variables: { ...data, name: data.name.toLowerCase().replaceAll(" ", "") },
      update(cache, { data: { insert_processType_one: newProcessType } }) {
        cache.modify({
          fields: {
            processType(existingProcessTypes = []) {
              const newProcessTypeRef = cache.writeFragment({
                data: newProcessType,
                fragment: PROCESS_TYPE_CORE_FIELDS,
              });
              return [...existingProcessTypes, newProcessTypeRef];
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          message: "New Type successfully added",
          type: "success",
        });
        toggleAdding();
      },
    });
  });

  const cancel = () => {
    reset();
    toggleAdding();
  };

  return (
    <form
      onSubmit={onSubmit}
      className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 mb-5 space-y-2"
    >
      <div>
        <Label htmlFor="name" value="Name" />
        <TextInput
          type="text"
          placeholder="Enter the name for new type no space allowed"
          {...register("name")}
          color={errors.name?.message ? "failure" : undefined}
          helperText={errors.name?.message || ""}
        />
      </div>
      <div>
        <Label htmlFor="prefix" value="Prefix" />
        <TextInput
          type="text"
          placeholder="Optional prefix"
          {...register("prefix")}
          color={errors.prefix?.message ? "failure" : undefined}
          helperText={errors.prefix?.message || ""}
        />
      </div>

      <div>
        <Label htmlFor="description" value="Description" />

        <Textarea
          {...register("description")}
          placeholder="Describe the process type"
          color={errors.description?.message ? "failure" : undefined}
          helperText={errors.description?.message || ""}
          className="mt-2 text-sm"
        />
      </div>
      <div>
        <Label htmlFor="color" value="Color" />
        <input
          type="color"
          {...register("color")}
          className="w-full h-10 border-2 p-0 bg-transparent border-none"
        />
        <div> {errors.color?.message || ""}</div>
      </div>

      <div className="flex flex-row justify-end gap-2">
        <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
          Cancel
        </Button>
        <Button gradientDuoTone="purpleToBlue" outline size="sm" type="submit">
          {loading && (
            <div className="mr-3">
              <Spinner size="sm" light={true} />
            </div>
          )}
          Add
        </Button>
      </div>
    </form>
  );
}
