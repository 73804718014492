import { create } from "zustand";
import uuid from "react-uuid";
import { unstable_batchedUpdates } from "react-dom";
import React from "react";

type alertTypes = "success" | "warning" | "info" | "failure";

export type AlertType = {
  type: alertTypes;
  id: string;
  action?: () => void;
  message: string;
  children?: React.ReactNode | JSX.Element;
};

type Store = {
  alerts: AlertType[];
  setAlert: (alert: AlertType) => void;
  removeAlert: (id: string) => void;
};

export const useAlertStore = create<Store>(set => ({
  alerts: [],
  setAlert: alert =>
    set(state => ({
      ...state,
      alerts: [...state.alerts, alert],
    })),
  removeAlert: id =>
    set(state => ({
      ...state,
      alerts: [...state.alerts.filter(a => a.id !== id)],
    })),
}));

export interface NewAlert {
  type: alertTypes;
  message: string;
  timeout?: number;
  persist?: boolean;
  children?: React.ReactNode | JSX.Element;
}

export const addAlert = (newAlert: NewAlert) => {
  const { type, message, timeout, persist, children } = newAlert;

  const alert = {
    id: uuid(),
    type,
    message,
    children,
  };

  unstable_batchedUpdates(() => {
    useAlertStore.getState().setAlert(alert);
  });

  if (!persist) {
    setTimeout(() => {
      unstable_batchedUpdates(() => {
        useAlertStore.getState().removeAlert(alert.id);
      });
    }, timeout || 2000);
  }
};
