import dayjs from "dayjs";
import {
  Badge,
  Button,
  Checkbox,
  Label,
  ListGroup,
  Spinner,
} from "flowbite-react";
import { useEffect, useState } from "react";
import getContrast from "../../../../utils/getContrast";
import { ProductionType } from "../../shared/productions/types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { coerceNumber, coerceDate } from "../types";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { PRODUCTION_CORE_FIELDS } from "../../shared/productions/fragments";
import {
  GET_PRODUCTION_STATUSES_NO_PID,
  GET_PRODUCTION_STATUSES_BY_PID,
} from "../../settings/production/productionStatuses/productionStatuses";
import { exists, mkdir } from "../../../../utils/fileAPI";
import Folder from "../../shared/files/folder";
import { useDroppable } from "@dnd-kit/core";
import { STREAM_ORDER } from "../../workorder/gqls";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { GET_RELEASED_ORDERS_CONDITIONAL } from "../../workorder/orderRelease/gqls";

export const UPDATE_PRODUCTION_BY_PID = gql`
  ${PRODUCTION_CORE_FIELDS}
  mutation UPDATE_PRODUCTION_BY_PID($id: Int!, $set: productions_set_input!) {
    update_productions_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ProductionCoreFields
    }
  }
`;

export const DELETE_PRODUCTION = gql`
  mutation DELETE_PRODUCTION($id: Int!) {
    delete_productions_by_pk(id: $id) {
      id
    }
  }
`;

export const productionSchema = z.object({
  badge: z.string(),
  statusID: coerceNumber,
  qty: coerceNumber,
  due: coerceDate,
  description: z.string(),
});

interface props {
  production: ProductionType;
  jobPath: false | string[];
}
export default function Production({ production, jobPath }: props) {
  const path =
    jobPath && jobPath.concat("production", production.id.toString());

  const [fileChecking, setFileChecking] = useState(false);

  const initFiles = async () => {
    setFileChecking(true);
    if (path) {
      const checkPath = await exists(path);
      if (!checkPath) {
        await mkdir(path);
        setFileChecking(false);
      } else {
        setFileChecking(false);
      }
    } else {
      setFileChecking(false);
    }
  };

  useEffect(() => {
    initFiles();
  }, []);

  const [extended, setExtended] = useState(false);
  const toggleExtended = () => {
    setExtended(!extended);
  };

  const { data: data_product_statuses } = useQuery(
    GET_PRODUCTION_STATUSES_BY_PID,
    { variables: { id: production.product.id } }
  );
  const productStatuses = data_product_statuses?.productionStatus;

  const { data: data_production_statuses } = useQuery(
    GET_PRODUCTION_STATUSES_NO_PID
  );
  const statuses = data_production_statuses?.productionStatus.filter(
    s => s.id !== production.productionStatus.id
  );

  const [update_production, { loading: updating }] = useMutation(
    UPDATE_PRODUCTION_BY_PID
  );

  const [delete_production, { loading: deleting }] =
    useMutation(DELETE_PRODUCTION);

  const defaultValues = {
    badge: production.badge,
    statusID: production.productionStatus.id,
    qty: production.qty,
    due: dayjs(production.due || new Date()).format("YYYY-MM-DD"),
    description: production.description,
  };

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues,
    resolver: zodResolver(productionSchema),
  });

  const [editing, setEditing] = useState<null | string>(null);
  const toggleEditing = (str: string) => {
    if (editing == str) {
      setEditing(null);
    } else {
      setEditing(str);
    }
  };

  const onSubmit = handleSubmit(async data => {
    update_production({
      variables: {
        id: production.id,
        set: data,
      },
      onCompleted() {
        reset({ ...data });
        setEditing(null);
      },
    });
  });

  const changeStatus = (id: number) => {
    setValue("statusID", id, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
    onSubmit();
  };

  const cancel = () => {
    setEditing(null);
    reset();
  };

  const deleteProduction = () => {
    const proceed = confirm("Do you really want to delete the production?");
    if (proceed) {
      delete_production({
        variables: { id: production.id },
        update(cache, { data: { delete_productions_by_pk: deleted } }) {
          const normalizedId = cache.identify({
            id: deleted.id,
            __typename: "productions",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    }
  };

  const { setNodeRef, isOver } = useDroppable({
    id: "droppable",
    data: production,
  });

  const style = {
    opacity: isOver ? 0.5 : 1,
  };

  // const { data: orders_data } = useSubscription(STREAM_ORDER, {
  //   variables: {
  //     where: {
  //       processId: { _eq: production.process?.id },
  //     },
  //     cursor: {
  //       initial_value: { updated_at: dayjs().format("YYYY-MM-DD") },
  //       ordering: "ASC",
  //     },
  //   },
  // });

  const navigate = useNavigate();

  const { data: data_myOrder } = useQuery(GET_RELEASED_ORDERS_CONDITIONAL, {
    variables: {
      where: {
        productionId: { _eq: production?.id },
      },
    },
  });

  const myOrderId = data_myOrder?.releasedOrders[0]?.id;

  const print = () => {
    navigate(`/production/print/${production?.id}/${myOrderId}`);
  };

  return (
    <form
      onSubmit={onSubmit}
      className={`shadow-md bg-white dark:bg-gray-800 rounded-md p-3 flex flex-col gap-2 ${
        !extended && "dark:hover:bg-gray-700 hover:bg-gray-50"
      }`}
      style={style}
      ref={setNodeRef}
    >
      <div className="flex flex-row gap-2 items-center justify-between cursor-pointer">
        <div
          onClick={toggleExtended}
          className="flex flex-1 flex-row gap-2 items-center"
        >
          <div className="ring-1 ring-dark rounded-full p-1 bg-white">
            <img src={production.product.image} className="w-5" />
          </div>
          <h3>{production.product.name}</h3>
        </div>

        <div className="flex flex-row gap-2 items-center relative">
          {production.badge && <Badge>{production.badge}</Badge>}
          <h3
            onClick={() => {
              toggleEditing("qty");
            }}
            className="hover:text-plum"
          >
            {production.qty}
          </h3>

          {editing == "qty" && (
            <div className="absolute right-0 top-[100%] mt-2 items-center flex flex-row gap-2 bg-white dark:bg-dark p-2 rounded-md border-[1px] dark:border-gray-700 z-10">
              <input
                className="bg-transparent text-center border-none outline-none p-0 w-20"
                placeholder="new qty"
                type="number"
                {...register("qty")}
                autoFocus
              />
              <Button
                type="submit"
                size="xs"
                outline
                gradientDuoTone="purpleToBlue"
              >
                update
                {updating && (
                  <Spinner light size="xs" color="purple" className="ml-1" />
                )}
              </Button>
              <Button onClick={cancel} size="xs" gradientDuoTone="purpleToBlue">
                Cancel
              </Button>
            </div>
          )}
          <Badge
            onClick={() => {
              toggleEditing("status");
            }}
            style={{
              backgroundColor: production.productionStatus.color,
              color: getContrast(production.productionStatus.color),
            }}
          >
            {production.productionStatus.name}
          </Badge>
          {updating && <Spinner light size="sm" color="purple" />}

          {editing == "status" && (
            <ListGroup className="absolute top-[100%] right-0 z-10">
              {statuses?.map(status => (
                <ListGroup.Item
                  onClick={() => {
                    changeStatus(status.id);
                  }}
                  key={status.id}
                >
                  <Badge
                    style={{
                      backgroundColor: status.color,
                      color: getContrast(status.color),
                    }}
                  >
                    {status.name}
                  </Badge>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </div>
      </div>
      {extended && (
        <>
          {/* ID */}
          <div className="gap-2 flex flex-row justify-between">
            <Label value="Id" />
            {production.id}
          </div>
          {/* Assigned to */}
          <div className="gap-2 flex flex-row justify-between">
            <Label value="Assigned To" />
            {production.team.name}
          </div>
          {/* Badge */}
          <div className="gap-2 flex flex-row relative justify-between">
            <Label value="Badge" />
            <Badge
              onClick={() => {
                toggleEditing("badge");
              }}
              className="cursor-pointer"
            >
              {production.badge || "No Badge"}
            </Badge>

            {editing == "badge" && (
              <div className="absolute right-0 top-[100%] mt-2 items-center flex flex-row gap-2 bg-white dark:bg-dark p-2 rounded-md border-[1px] dark:border-gray-700 z-10">
                <input
                  className="bg-transparent text-center border-none outline-none p-0 w-20"
                  placeholder="new badge"
                  {...register("badge")}
                  autoFocus
                />
                <Button
                  type="submit"
                  size="xs"
                  outline
                  gradientDuoTone="purpleToBlue"
                >
                  update
                  {updating && (
                    <Spinner light size="xs" color="purple" className="ml-1" />
                  )}
                </Button>
                <Button
                  onClick={cancel}
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
          {/* Due */}
          <div className="gap-2 flex flex-row justify-between relative">
            <Label value="Due" />
            <div
              onClick={() => {
                toggleEditing("due");
              }}
              className="hover:text-plum cursor-pointer"
            >
              {production.due
                ? dayjs(production.due).format("DD/MM/YYYY")
                : "Nill"}
            </div>

            {editing == "due" && (
              <div className="absolute right-0 top-[100%] mt-2 items-center flex flex-row gap-2 bg-white dark:bg-dark p-2 rounded-md border-[1px] dark:border-gray-700 z-10">
                <input
                  className="dark:bg-gray-500 rounded-md outline-none p-[5px] dark:border-none font-normal text-sm w-[50%]"
                  type="date"
                  {...register("due")}
                  autoFocus
                />
                <Button
                  type="submit"
                  size="xs"
                  outline
                  gradientDuoTone="purpleToBlue"
                >
                  update
                  {updating && (
                    <Spinner light size="xs" color="purple" className="ml-1" />
                  )}
                </Button>
                <Button
                  onClick={cancel}
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
          {/* Done */}
          <div className="gap-2 flex flex-row justify-between">
            <Label value="Done" />
            <div>{production.done || 0}</div>
          </div>
          {/* Released */}
          <div className="gap-2 flex flex-row justify-between">
            <Label value="Released" />
            <div>
              {dayjs(production.created_at).format("DD/MM/YYYY")} by{" "}
              <span className="capitalize">{production.user.firstName}</span>
            </div>
          </div>
          <div className="gap-2 flex flex-col">
            <Label value="Progress" />
            <div className="flex flex-row gap-4 flex-wrap">
              {productStatuses?.map(ps => (
                <div key={ps.id} className="flex items-center gap-2">
                  <Checkbox
                    className="checked:bg-plum dark:checked:bg-plum cursor-pointer"
                    readOnly
                    disabled
                    checked={
                      production.progress?.find(prog => prog == ps.id)
                        ? true
                        : false
                    }
                  />
                  <Label className="cursor-pointer">{ps.name}</Label>
                </div>
              ))}
            </div>
          </div>
          {/* Desc */}
          <Label value="Desc" />
          <div
            className="w-full"
            onClick={() => {
              if (!editing) {
                toggleEditing("desc");
              }
            }}
          >
            <textarea
              rows={1}
              {...register("description")}
              disabled={editing !== "desc"}
              className={`resize-none text-sm bg-gray-100 dark:bg-gray-700 rounded-md border-none w-full scrollbar-none ${
                editing == "desc" ? "" : "hover:brightness-125 cursor-pointer"
              }`}
            />
          </div>
          {editing == "desc" && (
            <div className="items-center flex flex-row gap-2 justify-end">
              <Button
                type="submit"
                size="xs"
                outline
                gradientDuoTone="purpleToBlue"
              >
                update
                {updating && (
                  <Spinner light size="xs" color="purple" className="ml-1" />
                )}
              </Button>
              <Button onClick={cancel} size="xs" gradientDuoTone="purpleToBlue">
                Cancel
              </Button>
            </div>
          )}

          {myOrderId && (
            <div className="flex flex-row justify-center items-center relative w-full my-1">
              <Button
                outline
                size="sm"
                gradientDuoTone="purpleToBlue"
                className="z-10"
                onClick={print}
              >
                View Order
              </Button>
              <hr className="border-plum w-full absolute" />
            </div>
          )}

          {/* Files */}
          {path && !fileChecking && (
            <>
              <Label value="Files" />
              <div className="dark:bg-gray-700 bg-gray-100 rounded-md px-2 py-3">
                <Folder path={path} simple={true} />
              </div>
            </>
          )}

          {/* Action */}
          <div className="flex flex-row gap-2 justify-end items-center">
            <Button
              onClick={deleteProduction}
              size="xs"
              outline
              gradientDuoTone="purpleToBlue"
            >
              Delete
              {deleting && (
                <Spinner light size="xs" color="purple" className="ml-1" />
              )}
            </Button>
          </div>
        </>
      )}
    </form>
  );
}
