import { create } from "zustand";
import { MessagePayload } from "firebase/messaging";
import uuid from "react-uuid";

type toastTypes = "success" | "warning" | "info" | "failure";

export type ToastType = {
  type: toastTypes;
  id: string;
  action?: () => void;
  message: string;
  children?: React.ReactNode | JSX.Element;
};

export interface StampedMessagePayload extends MessagePayload {
  timestamp: Date;
}

type Store = {
  token?: string;
  setToken: (token: string | undefined) => void;
  notifications: StampedMessagePayload[];
  addNotification: (notification: StampedMessagePayload) => void;
  removeNotification: (id: string) => void;
  resetNotification: () => void;
  toasts: ToastType[];
  addToast: (alert: ToastType) => void;
  removeToast: (id: string) => void;
  sound: boolean;
  setSound: (bool: boolean) => void;
};

const initNotifications = () => {
  const localItem = localStorage.getItem("notifications");
  if (localItem) {
    const existing = JSON.parse(localItem) as StampedMessagePayload[];

    return existing;
  }
  return [];
};

const initSound = () => {
  const localItem = localStorage.getItem("notificationSound");
  if (localItem) {
    const existing = JSON.parse(localItem);
    return existing;
  }

  return true;
};

export const useNotificationStore = create<Store>(set => ({
  token: "",
  setToken(token) {
    return set(state => ({
      ...state,
      token,
    }));
  },
  sound: initSound(),
  setSound(sound) {
    localStorage.setItem("notificationSound", JSON.stringify(sound));
    return set(state => {
      return {
        ...state,
        sound,
      };
    });
  },
  notifications: initNotifications(),
  addNotification(notification) {
    return set(state => {
      const notifications = state.notifications.concat(notification);
      localStorage.setItem("notifications", JSON.stringify(notifications));
      return {
        ...state,
        notifications,
      };
    });
  },
  removeNotification(id) {
    return set(state => {
      const notifications = state.notifications.filter(n => n.messageId !== id);
      localStorage.setItem("notifications", JSON.stringify(notifications));
      return {
        ...state,
        notifications,
      };
    });
  },
  resetNotification() {
    localStorage.removeItem("notifications");
    return set(state => ({
      ...state,
      notifications: [],
    }));
  },
  toasts: [],
  addToast(alert) {
    return set(state => ({
      ...state,
      toasts: state.toasts.concat(alert),
    }));
  },
  removeToast(id) {
    return set(state => ({
      ...state,
      toasts: state.toasts.filter(n => n.id !== id),
    }));
  },
}));

export const useAddNotification = () => {
  const { addNotification, addToast, removeToast } = useNotificationStore();

  return (notification: StampedMessagePayload) => {
    addNotification(notification);

    const newToast: ToastType = {
      id: uuid(),
      message: notification.data?.title as string,
      type: "info",
    };

    addToast(newToast);
  };
};
