import { gql } from "@apollo/client";
import { TASK_STATUS_CORE_FIELDS } from "../../settings/tasks/taskStatuses/fragments";
import { PROCESS_CORE_FIELDS } from "../../process/fragments";

export const TASK_CORE_FIELDS = gql`
  ${TASK_STATUS_CORE_FIELDS}
  ${PROCESS_CORE_FIELDS}
  fragment TaskCoreFields on tasks {
    id
    assignedToUser {
      id
      email
      firstName
      sirName
    }
    assignedByUser {
      id
      email
      firstName
      sirName
    }
    schedules
    description
    due
    process {
      ...ProcessCoreFields
    }
    taskStatus {
      ...TaskStatusCoreFields
    }
    deleted
    updated_at
    created_at
  }
`;
