import { useState } from "react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { TypedDocumentNode, gql, useQuery } from "@apollo/client";
import { TeamArrayType } from "./types";
import { TEAM_CORE_FIELDS } from "./fragments";
import { Spinner } from "flowbite-react";
import Team from "./team";
import AddTeam from "./addTeam";
import { sort } from "fast-sort";

export const GET_TEAMS: TypedDocumentNode<TeamArrayType> = gql`
  ${TEAM_CORE_FIELDS}
  query GET_TEAMS {
    teams {
      ...TeamCoreFields
    }
  }
`;

export default function TeamList() {
  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const { data, loading, error } = useQuery(GET_TEAMS, {
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    console.log(error);
  }

  const teams = data?.teams ? sort(data.teams).asc(u => u.name) : false;

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <h1>Teams</h1>
        <div onClick={toggleAdding} className="w-6 cursor-pointer">
          {adding ? (
            <MinusCircleIcon className="text-red-500" />
          ) : (
            <PlusCircleIcon className="text-grass hover:animate-pulse" />
          )}
        </div>
      </div>
      {loading && !data && (
        <div className="flex flex-row justify-center mt-4 mb-6">
          <Spinner color="purple" size="xl" />
        </div>
      )}
      <section className="mt-2 space-y-2">
        {adding && <AddTeam toggleAdding={toggleAdding} />}
        {teams &&
          teams?.map(team => <Team key={team.id} teams={teams} team={team} />)}
      </section>
    </div>
  );
}
