import { useState } from "react";
import Heading from "../../../comps/heading";
import Record from "../shared/record/record";
import RecordList from "./recordList";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

export default function RecordsLoader() {
  const [show, setShow] = useState(true);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <main className="select-none flex flex-col flex-1">
      <header className="px-6">
        <Heading />
        <div className="flex flex-row text-grass mb-10">
          check in and out records
        </div>
      </header>
      <section className="flex flex-col px-6 pb-6 gap-4 w-full m-auto">
        <div className="flex flex-row items-center justify-between">
          <h2 className={`${!show && "opacity-0"}`}>Attendance</h2>

          <div
            onClick={toggleShow}
            className="flex flex-row gap-1 hover:text-grass cursor-pointer"
          >
            {show ? (
              <>
                <EyeIcon className="w-4" />
                Hide
              </>
            ) : (
              <>
                <EyeSlashIcon className="w-4" />
                Show
              </>
            )}
          </div>
        </div>
        {show && <Record />}

        <h2>Record List</h2>
        <RecordList />
      </section>
    </main>
  );
}
