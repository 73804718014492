import { FC } from "react";
import * as HIcons from "@heroicons/react/24/solid";

const DynamicHeroIcon: FC<{
  icon: string;
  class?: string;
  onClick?: () => void;
}> = props => {
  const { ...icons } = HIcons;
  // @ts-ignore
  const TheIcon: JSX.Element = icons[props.icon];

  if (!TheIcon) {
    return null;
  }

  return (
    <>
      {/* @ts-ignore */}
      <TheIcon
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        className={`${props.class ? props.class : "h-6 w-6 text-white"}`}
        aria-hidden="true"
      />
    </>
  );
};

export default DynamicHeroIcon;
