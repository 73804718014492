import { useAuthStore } from "../store/authStore";

type conditionalls = {
  permission: string;
  checkGroup: number[] | "userTeam";
  conditionGroup: number[];
};

type permission = string | conditionalls;

function isConditionall(obj: any): obj is conditionalls {
  return obj.permission !== undefined;
}

export function haveMatches(arr1: number[], arr2: number[]) {
  return arr1.some(item => arr2.includes(item));
}

export default function checkAuth(
  permissionParams: permission[] | string
): boolean {
  const user = useAuthStore.getState().user;
  if (!user?.permissions) {
    return false;
  }

  const permissions = user.permissions;

  if (permissions.length < 1) {
    return false;
  }

  const permissionNames = permissions.map(p => p.name);

  if (typeof permissionParams == "string") {
    return permissionNames.includes(permissionParams);
  }

  let check = false;

  for (const permission of permissionParams) {
    if (isConditionall(permission)) {
      const allowed = permissionNames.includes(permission.permission);

      if (!allowed) {
        continue;
      }

      let checkGroup;

      let userTeams = [user?.team?.id || 0];
      if (user?.team?.subTeams) {
        userTeams = userTeams.concat(user.team.subTeams);
      }

      if (permission.checkGroup == "userTeam") {
        checkGroup = userTeams;
      } else {
        checkGroup = permission.checkGroup;
      }

      const matching = haveMatches(checkGroup, permission.conditionGroup);
      if (matching) {
        check = true;
      }
    } else {
      const allowed = permissionNames.includes(permission);
      if (allowed) {
        check = true;
      }
    }
  }

  return check;
}
