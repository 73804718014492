import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import getBase64 from "../../../../../utils/getBase64";
import getDimensions from "../../../../../utils/getDimensions";
import { FileInput, Label, TextInput } from "flowbite-react";
import {
  ArrowTopRightOnSquareIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

export default function DeductionImage() {
  const {
    register,
    control,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: "imageTexts",
    control,
  });

  const [imgSize, setImgSize] = useState({
    width: 0,
    height: 0,
  });

  const imageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors("image");

    const files = event.target.files;

    if (files) {
      const file = files[0];
      if (!file) {
        return;
      }
      const { size } = file;

      if (size / 1000 > 50) {
        setError("image", { type: "custom", message: "file size too big" });
        event.target.value = "";
        return;
      }

      const base64 = (await getBase64(file)) as string;

      setValue("image", base64);
    }
  };

  const image = useWatch({
    name: "image",
    control,
  });

  useEffect(() => {
    if (!image) {
      return;
    }
    getDimensions(image).then(d => {
      setImgSize(d);
    });
  }, [image]);

  const defaultValues = {
    coord: {
      x: 0,
      y: 0,
    },
    scope: "",
    rotation: 0,
  };

  const nullishValues = ["", null, 0];

  const [newText, setNewText] = useState(defaultValues);

  const add = () => {
    if (nullishValues.includes(newText.scope)) {
      return;
    }
    append(newText);
    setNewText(defaultValues);
  };

  const imageTexts: {
    scope: string;
    rotation: number;
    coord: {
      x: number;
      y: number;
    };
  }[] = useWatch({
    name: "imageTexts",
    control,
  });

  const clear = () => {
    const proceed = confirm("clear image and texts?");
    if (!proceed) {
      return;
    }

    setValue("image", null);
    setValue("imageTexts", []);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center">
        <Label htmlFor="image" value="Image" />
        {image && (
          <TrashIcon
            className="w-5 text-red-500 cursor-pointer"
            onClick={clear}
          />
        )}
      </div>
      {image && (
        <div className="p-10 flex flex-row justify-center">
          <div className="relative p-0">
            <img src={image} className="w-100 max-w-full dark:invert" />

            {imageTexts &&
              imageTexts.map((text, i) => (
                <div
                  key={i}
                  className="absolute dark:text-white text-xs"
                  style={{
                    top:
                      ((text.coord.y / imgSize.height) * 100).toFixed(2) + "%",
                    left:
                      ((text.coord.x / imgSize.width) * 100).toFixed(2) + "%",
                    rotate: `${text.rotation}deg`,
                  }}
                >
                  {text.scope}
                </div>
              ))}
          </div>
        </div>
      )}
      <div>
        <FileInput
          id="image"
          accept="image/*"
          color={errors.image?.message ? "failure" : undefined}
          helperText={
            errors.image?.message?.toString() ||
            "upload product image less than 50kb size"
          }
          className="mt-2 text-sm"
          onChange={imageHandler}
        />
      </div>
      {/* New Text */}
      <Label htmlFor="image" value="Image Texts" />
      <div className="flex flex-row gap-2 items-center">
        <div className="flex-1 relative items-center">
          <TextInput
            className="w-full"
            sizing="sm"
            addon="Txt"
            placeholder="Text Formula"
            value={newText.scope}
            onChange={e => {
              setNewText({ ...newText, scope: e.target.value });
            }}
          />
          <ArrowTopRightOnSquareIcon
            onClick={() => {
              window.open("https://mathnotepad.com/");
            }}
            className="absolute cursor-pointer right-2 top-1/2 -translate-y-1/2 w-5 dark:text-white text-gray-600"
          />
        </div>
        <TextInput
          sizing="sm"
          addon="x"
          placeholder="x Coord"
          type="number"
          className="w-24"
          value={newText.coord.x}
          onChange={e => {
            setNewText({
              ...newText,
              coord: { ...newText.coord, x: Number(e.target.value) },
            });
          }}
        />
        <TextInput
          sizing="sm"
          addon="y"
          placeholder="y Coord"
          type="number"
          className="w-24"
          value={newText.coord.y}
          onChange={e => {
            setNewText({
              ...newText,
              coord: { ...newText.coord, y: Number(e.target.value) },
            });
          }}
        />
        <TextInput
          sizing="sm"
          addon="Angle"
          placeholder="Rotation Angle"
          type="number"
          className="w-32"
          value={newText.rotation}
          onChange={e => {
            setNewText({ ...newText, rotation: Number(e.target.value) });
          }}
        />
        <PlusCircleIcon
          className="w-6 text-grass cursor-pointer"
          onClick={add}
        />
      </div>

      {fields.length > 0 && (
        <div className="flex flex-col dark:text-white">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-t-md flex flex-row gap-2 items-center">
            <div className="px-2 py-[5px] flex-1">Text</div>
            <div className="px-2 py-[5px] w-16">X</div>
            <div className="px-2 py-[5px] w-16">Y</div>
            <div className="px-2 py-[5px] w-16">Angle</div>
            <div className="px-2 py-[5px] w-10" />
          </div>
          {fields.map((item, index) => (
            <div
              key={item.id}
              className="rounded-md flex flex-row gap-2 items-center"
            >
              <div className="px-2 py-[5px] flex-1 overflow-clip">
                <input
                  className="bg-transparent w-full"
                  {...register(`imageTexts.${index}.scope`)}
                />
              </div>
              <div className="px-2 py-[5px] w-16">
                <input
                  type="number"
                  className="bg-transparent w-full text-sm p-0 border-0"
                  {...register(`imageTexts.${index}.coord.x`)}
                />
              </div>
              <div className="px-2 py-[5px] w-16">
                <input
                  type="number"
                  className="bg-transparent w-full text-sm p-0 border-0"
                  {...register(`imageTexts.${index}.coord.y`)}
                />
              </div>
              <div className="px-2 py-[5px] w-16">
                <input
                  type="number"
                  className="bg-transparent w-full text-sm p-0 border-0"
                  {...register(`imageTexts.${index}.rotation`)}
                />
              </div>
              <div className="px-2 py-[5px] w-10 flex flex-row gap-2">
                <MinusCircleIcon
                  className="text-red-500 w-5 cursor-pointer"
                  onClick={() => {
                    remove(index);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
