import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { InventoryArrayType, MaterialsType } from "./types";
import { MATERIALS_CORE_FIELDS, MATERIALS_FIELDS_MIN } from "./fragments";
import { gql } from "@apollo/client";

export const GET_MATERIALS: TypedDocumentNode<InventoryArrayType> = gql`
  ${MATERIALS_CORE_FIELDS}
  query GET_MATERIALS {
    materials {
      ...MaterialsCoreFields
    }
  }
`;

export const GET_MATERIALS_MIN: TypedDocumentNode<InventoryArrayType> = gql`
  query GET_MATERIALS_MIN {
    materials {
      id
      brand
      name
      color
      code
      optionCode
      optionCondition
      product {
        id
        name
      }
      widthDeduction
      heightDeduction
      size
      params
      pending
      selectable
      prices
    }
  }
`;

export const GET_MATERIALS_CONDITIONAL: TypedDocumentNode<InventoryArrayType> = gql`
  ${MATERIALS_FIELDS_MIN}
  query GET_MATERIALS_CONDITIONAL($where: materials_bool_exp!) {
    materials(where: $where) {
      ...MaterialsFieldsMin
    }
  }
`;

export interface insert_materials_one {
  insert_materials_one: MaterialsType;
}

export const ADD_MATERIALS: TypedDocumentNode<insert_materials_one> = gql`
  ${MATERIALS_CORE_FIELDS}
  mutation ADD_MATERIALS($object: materials_insert_input!) {
    insert_materials_one(object: $object) {
      ...MaterialsCoreFields
    }
  }
`;

interface update_materials_by_pk_type {
  update_materials_by_pk: MaterialsType;
}

export const UPDATE_MATERIALS_BY_PK: TypedDocumentNode<update_materials_by_pk_type> = gql`
  ${MATERIALS_CORE_FIELDS}
  mutation UPDATE_MATERIALS_BY_PK($id: Int!, $set: materials_set_input!) {
    update_materials_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...MaterialsCoreFields
    }
  }
`;

export const UPDATE_MATERIAL_MANY = gql`
  ${MATERIALS_CORE_FIELDS}
  mutation UPDATE_MATERIAL_MANY($updates: [materials_updates!]!) {
    update_materials_many(updates: $updates) {
      returning {
        ...MaterialsCoreFields
      }
    }
  }
`;

export const DELETE_MATERIALS = gql`
  mutation DELETE_MATERIALS($id: Int!) {
    delete_materials_by_pk(id: $id) {
      id
    }
  }
`;
