import { useEffect } from "react";
import { socket } from "../socket";
import { useScheduleStore } from "../store/scheduleStore";
import { Calendar } from "../types/calendarTypes";
import { usePopulateCalendar } from "../utils/calendarMethods";

const onConnect = () => {
  console.log("connected");
};
const onDisconnect = () => {
  console.log("disconnected");
};

export default function SocketActions() {
  const { concatCalendars, evictCalendar, mergeCalendars } = useScheduleStore();

  const { loading, populateCalendar } = usePopulateCalendar();

  useEffect(() => {
    if (loading) {
      return;
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    socket.on("calendarEvict", (calId: string) => {
      evictCalendar(calId);
    });
    socket.on("calendarAdd", (calendar: Calendar) => {
      const populatedCalendar = populateCalendar(calendar);
      if (populatedCalendar) {
        concatCalendars([populatedCalendar]);
      }
    });
    socket.on("calendarMerge", (calendar: Calendar) => {
      const populatedCalendar = populateCalendar(calendar);
      // console.log(populatedCalendar);
      if (populatedCalendar) {
        mergeCalendars([populatedCalendar]);
      }
    });

    // return () => {
    //   socket.disconnect();
    // };
  }, [loading, populateCalendar]);

  return null;
}
