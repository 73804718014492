import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  DeductionArrayType,
  DeductionPresetArrayType,
  DeductionPresetType,
  DeductionType,
  LayoutArrayType,
  LayoutType,
  OrderArrayType,
  OrderType,
  ProductImageArrayType,
  ProductImageType,
  ProductSetArrayType,
  ProductSetByIdType,
  ProductSetType,
  optionProps,
  orderStatusType,
  orderSummaryColumn,
} from "./types";
import {
  DEDUCTION_CORE_FIELDS,
  DEDUCTION_FIELDS_MIN,
  DEDUCTION_PRESET_CORE_FIELDS,
  LAYOUT_CORE_FIELDS,
  ORDER_SUMMARY_COLUMN_FIELDS,
  PRODUCT_IMAGES_CORE_FIELDS,
  PRODUCT_SET_CORE_FIELDS,
  PRODUCT_SET_OPTIONS_FIELDS,
  WORKORDER_CORE_FIELDS,
} from "./fragments";
import { gql } from "@apollo/client";

export const GET_ORDERS: TypedDocumentNode<OrderArrayType> = gql`
  ${WORKORDER_CORE_FIELDS}
  query GET_ORDERS {
    workorder {
      ...WorkorderCoreFields
    }
  }
`;

interface WorkorderStreamType {
  workorder_stream: OrderType[];
}

export const STREAM_ORDER: TypedDocumentNode<WorkorderStreamType> = gql`
  ${WORKORDER_CORE_FIELDS}
  subscription STREAM_ORDER(
    $where: workorder_bool_exp
    $cursor: [workorder_stream_cursor_input]!
  ) {
    workorder_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...WorkorderCoreFields
    }
  }
`;

export const GET_ORDERS_CONDITIONAL: TypedDocumentNode<OrderArrayType> = gql`
  ${WORKORDER_CORE_FIELDS}
  query GET_ORDERS($where: workorder_bool_exp!) {
    workorder(where: $where) {
      ...WorkorderCoreFields
    }
  }
`;

interface workorderAggQueryType {
  workorder: OrderType[];
  workorder_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

export const GET_ORDERS_AGG: TypedDocumentNode<workorderAggQueryType> = gql`
  ${WORKORDER_CORE_FIELDS}
  query GET_ORDERS_AGG($where: workorder_bool_exp!, $limit: Int, $offset: Int) {
    workorder(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { due: asc, created_at: asc }
    ) {
      ...WorkorderCoreFields
    }
    workorder_aggregate(
      where: $where
      order_by: { due: asc, created_at: asc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

interface workorder_by_pk {
  workorder_by_pk: OrderType;
}

export const GET_ORDER_BY_PK: TypedDocumentNode<workorder_by_pk> = gql`
  ${WORKORDER_CORE_FIELDS}
  query GET_PRODUCTION_BY_PK($id: Int!) {
    workorder_by_pk(id: $id) {
      ...WorkorderCoreFields
    }
  }
`;

export interface insert_workorder_one {
  insert_workorder_one: OrderType;
}

/**
 * @param {object} object - new order object
 */
export const ADD_ORDER: TypedDocumentNode<insert_workorder_one> = gql`
  ${WORKORDER_CORE_FIELDS}
  mutation ADD_ORDER($object: workorder_insert_input!) {
    insert_workorder_one(object: $object) {
      ...WorkorderCoreFields
    }
  }
`;

interface update_workorder_by_pk_type {
  update_workorder_by_pk: OrderType;
}

export const UPDATE_ORDER_BY_PK: TypedDocumentNode<update_workorder_by_pk_type> = gql`
  ${WORKORDER_CORE_FIELDS}
  mutation UPDATE_ORDER_BY_PK($id: Int!, $set: workorder_set_input!) {
    update_workorder_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...WorkorderCoreFields
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation DELETE_ORDER($id: Int!) {
    delete_workorder_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_DEDUCTIONS: TypedDocumentNode<DeductionArrayType> = gql`
  ${DEDUCTION_CORE_FIELDS}
  query GET_DEDUCTIONS {
    deductions {
      ...DeductionCoreFields
    }
  }
`;

export const GET_DEDUCTIONS_NO_IMG: TypedDocumentNode<DeductionArrayType> = gql`
  ${DEDUCTION_FIELDS_MIN}
  query GET_DEDUCTIONS_NO_IMG {
    deductions {
      ...DeductionFieldsMin
    }
  }
`;

export interface insert_deductions_one {
  insert_deductions_one: DeductionType;
}

export const ADD_DEDUCTION: TypedDocumentNode<insert_deductions_one> = gql`
  ${DEDUCTION_CORE_FIELDS}
  mutation ADD_DEDUCTION($object: deductions_insert_input!) {
    insert_deductions_one(object: $object) {
      ...DeductionCoreFields
    }
  }
`;

export const DELETE_DEDUCTION = gql`
  mutation DELETE_DEDUCTION($id: Int!) {
    delete_deductions_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_DEDUCTION_PRESETS: TypedDocumentNode<DeductionPresetArrayType> = gql`
  ${DEDUCTION_PRESET_CORE_FIELDS}
  query GET_DEDUCTION_PRESETS {
    deductionPreset {
      ...DeductionPresetCoreFields
    }
  }
`;

export const GET_DEDUCTION_PRESETS_BY_PRODUCT: TypedDocumentNode<DeductionPresetArrayType> = gql`
  ${DEDUCTION_PRESET_CORE_FIELDS}
  query GET_DEDUCTION_PRESETS_BY_PRODUCT($id: Int!) {
    deductionPreset(where: { for: { _eq: $id } }) {
      ...DeductionPresetCoreFields
    }
  }
`;

export const GET_DEDUCTION_PRESETS_BY_IDS: TypedDocumentNode<DeductionPresetArrayType> = gql`
  ${DEDUCTION_PRESET_CORE_FIELDS}
  query GET_DEDUCTION_PRESETS_BY_IDS($ids: [Int!]!) {
    deductionPreset(where: { id: { _in: $ids } }) {
      ...DeductionPresetCoreFields
    }
  }
`;

export const GET_DEDUCTION_PRESETS_CONDITIONAL: TypedDocumentNode<DeductionPresetArrayType> = gql`
  ${DEDUCTION_PRESET_CORE_FIELDS}
  query GET_DEDUCTION_PRESETS_CONDITIONAL($where: deductionPreset_bool_exp!) {
    deductionPreset(where: $where) {
      ...DeductionPresetCoreFields
    }
  }
`;

export interface insert_deductionPreset_one {
  insert_deductionPreset_one: DeductionPresetType;
}

export const ADD_DEDUCTION_PRESET: TypedDocumentNode<insert_deductionPreset_one> = gql`
  ${DEDUCTION_PRESET_CORE_FIELDS}
  mutation ADD_DEDUCTION_PRESET($object: deductionPreset_insert_input!) {
    insert_deductionPreset_one(object: $object) {
      ...DeductionPresetCoreFields
    }
  }
`;

interface update_deductionPreset_by_pk_type {
  update_deductionPreset_by_pk: DeductionPresetType;
}

export const UPDATE_DEDUCTION_PRESET_BY_PK: TypedDocumentNode<update_deductionPreset_by_pk_type> = gql`
  ${DEDUCTION_PRESET_CORE_FIELDS}
  mutation UPDATE_DEDUCTION_PRESET_BY_PK(
    $id: Int!
    $set: deductionPreset_set_input!
  ) {
    update_deductionPreset_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...DeductionPresetCoreFields
    }
  }
`;

export const DELETE_DEDUCTION_PRESET = gql`
  mutation DELETE_DELETE_DEDUCTION_PRESET($id: Int!) {
    delete_deductionPreset_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_DEDUCTIONS_BY_IDS: TypedDocumentNode<DeductionArrayType> = gql`
  ${DEDUCTION_CORE_FIELDS}
  query GET_DEDUCTIONS_BY_IDS($ids: [Int!]!) {
    deductions(where: { id: { _in: $ids } }) {
      ...DeductionCoreFields
    }
  }
`;

export const GET_DEDUCTIONS_CONDITIONAL: TypedDocumentNode<DeductionArrayType> = gql`
  ${DEDUCTION_CORE_FIELDS}
  query GET_DEDUCTIONS_CONDITIONAL($where: deductions_bool_exp!) {
    deductions(where: $where) {
      ...DeductionCoreFields
    }
  }
`;

export const GET_DEDUCTIONS_CONDITIONAL_NO_IMG: TypedDocumentNode<DeductionArrayType> = gql`
  ${DEDUCTION_FIELDS_MIN}
  query GET_DEDUCTIONS_CONDITIONAL_NO_IMG($where: deductions_bool_exp!) {
    deductions(where: $where) {
      ...DeductionFieldsMin
    }
  }
`;

interface update_deductions_by_pk_type {
  update_deductions_by_pk: DeductionType;
}

export const UPDATE_DEDUCTIONS_BY_PK: TypedDocumentNode<update_deductions_by_pk_type> = gql`
  ${DEDUCTION_CORE_FIELDS}
  mutation UPDATE_DEDUCTIONS_BY_PK($id: Int!, $set: deductions_set_input!) {
    update_deductions_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...DeductionCoreFields
    }
  }
`;

export const GET_PRODUCT_SETS: TypedDocumentNode<ProductSetArrayType> = gql`
  ${PRODUCT_SET_CORE_FIELDS}
  query GET_PRODUCT_SETS {
    productSets {
      ...ProductSetCoreFields
    }
  }
`;

export const GET_PRODUCT_SETS_MIN: TypedDocumentNode<ProductSetArrayType> = gql`
  query GET_PRODUCT_SETS_MIN {
    productSets {
      id
      name
      calcs
      hideItems
      product {
        id
        name
      }
      orderPresets
      qtyCalc
      sizeLimit
      position
      warnings
    }
  }
`;

export interface insert_productSets_one {
  insert_productSets_one: ProductSetType;
}

export const ADD_PRODUCT_SETS = gql`
  ${PRODUCT_SET_CORE_FIELDS}
  mutation ADD_PRODUCT_SETS($object: productSets_insert_input!) {
    insert_productSets_one(object: $object) {
      ...ProductSetCoreFields
    }
  }
`;

export interface update_productSets_by_pk_type {
  update_productSets_by_pk: ProductSetType;
}

export const UPDATE_PRODUCT_SET_BY_PK: TypedDocumentNode<update_productSets_by_pk_type> = gql`
  ${PRODUCT_SET_CORE_FIELDS}
  mutation UPDATE_PRODUCT_SET_BY_PK($id: Int!, $set: productSets_set_input!) {
    update_productSets_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ProductSetCoreFields
    }
  }
`;

export const GET_PRODUCT_SET_BY_ID: TypedDocumentNode<ProductSetByIdType> = gql`
  ${PRODUCT_SET_CORE_FIELDS}
  query GET_PRODUCT_SET_BY_ID($id: Int!) {
    productSets_by_pk(id: $id) {
      ...ProductSetCoreFields
    }
  }
`;

export const GET_PRODUCT_SET_CONDITIONAL: TypedDocumentNode<ProductSetArrayType> = gql`
  ${PRODUCT_SET_CORE_FIELDS}
  query GET_PRODUCT_SET_CONDITIONAL($where: productSets_bool_exp!) {
    productSets(where: $where) {
      ...ProductSetCoreFields
    }
  }
`;

export const GET_LAYOUTS: TypedDocumentNode<LayoutArrayType> = gql`
  ${LAYOUT_CORE_FIELDS}
  query GET_LAYOUTS {
    layouts {
      ...LayoutCoreFields
    }
  }
`;

export interface insert_layouts_one {
  insert_layouts_one: LayoutType;
}

export const ADD_LAYOUT: TypedDocumentNode<insert_layouts_one> = gql`
  ${LAYOUT_CORE_FIELDS}
  mutation ADD_LAYOUT($object: layouts_insert_input!) {
    insert_layouts_one(object: $object) {
      ...LayoutCoreFields
    }
  }
`;

export interface update_layouts_by_pk_type {
  update_layouts_by_pk: LayoutType;
}

export const UPDATE_LAYOUT_BY_PK: TypedDocumentNode<update_layouts_by_pk_type> = gql`
  ${LAYOUT_CORE_FIELDS}
  mutation UPDATE_LAYOUT_BY_PK($id: Int!, $set: layouts_set_input!) {
    update_layouts_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...LayoutCoreFields
    }
  }
`;

export const DELETE_LAYOUT = gql`
  mutation DELETE_LAYOUT($id: Int!) {
    delete_layouts_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_PRODUCT_IMAGES_BY_PID: TypedDocumentNode<ProductImageArrayType> = gql`
  ${PRODUCT_IMAGES_CORE_FIELDS}
  query GET_PRODUCT_IMAGES_BY_PID($id: Int!) {
    productImages(where: { productSetId: { _eq: $id } }) {
      ...ProductImagesCoreFields
    }
  }
`;

export const GET_PRODUCT_IMAGES: TypedDocumentNode<ProductImageArrayType> = gql`
  ${PRODUCT_IMAGES_CORE_FIELDS}
  query GET_PRODUCT_IMAGES {
    productImages {
      ...ProductImagesCoreFields
    }
  }
`;

export interface insert_productImages_one {
  insert_productImages_one: ProductImageType;
}

export const ADD_PRODUCT_IMAGE: TypedDocumentNode<insert_productImages_one> = gql`
  ${PRODUCT_IMAGES_CORE_FIELDS}
  mutation ADD_PRODUCT_IMAGE($object: productImages_insert_input!) {
    insert_productImages_one(object: $object) {
      ...ProductImagesCoreFields
    }
  }
`;

export interface update_productImages_by_pk_type {
  update_productImages_by_pk: ProductImageType;
}

export const UPDATE_PRODUCT_IMAGE_BY_PK: TypedDocumentNode<update_productImages_by_pk_type> = gql`
  ${PRODUCT_IMAGES_CORE_FIELDS}
  mutation UPDATE_PRODUCT_IMAGES_BY_PK(
    $id: Int!
    $set: productImages_set_input!
  ) {
    update_productImages_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ProductImagesCoreFields
    }
  }
`;

export const DELETE_PRODUCT_IMAGE = gql`
  mutation PRODUCT_IMAGE($id: Int!) {
    delete_productImages_by_pk(id: $id) {
      id
    }
  }
`;

interface ProductOptionArray {
  productSetOptions: optionProps[];
}

export const GET_PRODUCT_OPTIONS_BY_PID: TypedDocumentNode<ProductOptionArray> = gql`
  ${PRODUCT_SET_OPTIONS_FIELDS}
  query GET_PRODUCT_OPTIONS_BY_PID($id: Int!) {
    productSetOptions(where: { productSetID: { _eq: $id } }) {
      ...ProductSetOptionsFields
    }
  }
`;

export interface productOptionByPk {
  productSetOptions_by_pk: optionProps;
}

export const GET_PRODUCT_OPTIONS_BY_ID: TypedDocumentNode<productOptionByPk> = gql`
  ${PRODUCT_SET_OPTIONS_FIELDS}
  query GET_PRODUCT_OPTIONS_BY_ID($id: Int!) {
    productSetOptions_by_pk(id: $id) {
      ...ProductSetOptionsFields
    }
  }
`;

export const GET_PRODUCT_OPTIONS_CONDITIONAL: TypedDocumentNode<ProductOptionArray> = gql`
  ${PRODUCT_SET_OPTIONS_FIELDS}
  query GET_PRODUCT_OPTIONS_CONDITIONAL($where: productSetOptions_bool_exp!) {
    productSetOptions(where: $where) {
      ...ProductSetOptionsFields
    }
  }
`;

export const GET_PRODUCT_OPTIONS: TypedDocumentNode<ProductOptionArray> = gql`
  ${PRODUCT_SET_OPTIONS_FIELDS}
  query GET_PRODUCT_OPTIONS {
    productSetOptions {
      ...ProductSetOptionsFields
    }
  }
`;

export interface update_productSetOptions_by_pk_type {
  update_productSetOptions_by_pk: ProductSetType;
}

export const UPDATE_PRODUCT_SET_OPTION_BY_PK: TypedDocumentNode<update_productSetOptions_by_pk_type> = gql`
  ${PRODUCT_SET_OPTIONS_FIELDS}
  mutation UPDATE_PRODUCT_SET_OPTION_BY_PK(
    $id: Int!
    $set: productSetOptions_set_input!
  ) {
    update_productSetOptions_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ProductSetOptionsFields
    }
  }
`;

export interface insert_productSetOptions_one {
  insert_productSetOptions_one: DeductionPresetType;
}

export const ADD_PRODUCT_SET_OPTION: TypedDocumentNode<insert_productSetOptions_one> = gql`
  ${PRODUCT_SET_OPTIONS_FIELDS}
  mutation ADD_PRODUCT_SET_OPTION($object: productSetOptions_insert_input!) {
    insert_productSetOptions_one(object: $object) {
      ...ProductSetOptionsFields
    }
  }
`;

export const DELETE_PRODUCT_SET_OPTION = gql`
  mutation DELETE_PRODUCT_SET_OPTION($id: Int!) {
    delete_productSetOptions_by_pk(id: $id) {
      id
    }
  }
`;

interface orderStatusArrayType {
  workorderStatus: orderStatusType[];
}

interface orderSummaryColumnArrayType {
  orderSummaryColumn: orderSummaryColumn[];
}

export const GET_ORDER_SUMMARY_COLUMN: TypedDocumentNode<orderSummaryColumnArrayType> = gql`
  ${ORDER_SUMMARY_COLUMN_FIELDS}
  query GET_ORDER_SUMMARY_COLUMN {
    orderSummaryColumn {
      ...OrderSummaryColumnFields
    }
  }
`;

interface update_orderSummaryColumn_by_pk_type {
  orderSummaryColumn: orderSummaryColumn[];
}

export const UPDATE_ORDER_SUMMARY_COLUMN_BY_PK: TypedDocumentNode<update_orderSummaryColumn_by_pk_type> = gql`
  ${ORDER_SUMMARY_COLUMN_FIELDS}
  mutation UPDATE_ORDER_SUMMARY_COLUMN_BY_PK(
    $id: Int!
    $set: orderSummaryColumn_set_input!
  ) {
    update_orderSummaryColumn_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...OrderSummaryColumnFields
    }
  }
`;

export const ADD_ORDER_SUMMARY_COLUMN = gql`
  ${ORDER_SUMMARY_COLUMN_FIELDS}
  mutation ADD_ORDER_SUMMARY_COLUMN($object: orderSummaryColumn_insert_input!) {
    insert_orderSummaryColumn_one(object: $object) {
      ...OrderSummaryColumnFields
    }
  }
`;

export const DELETE_ORDER_SUMMARY_COLUMN = gql`
  mutation DELETE_ORDER_SUMMARY_COLUMN($id: Int!) {
    delete_orderSummaryColumn_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_ORDER_STATUS: TypedDocumentNode<orderStatusArrayType> = gql`
  query GET_ORDER_STATUS {
    workorderStatus {
      id
      name
      color
      priority
    }
  }
`;

interface orderTypeArrayType {
  workorderType: orderStatusType[];
}

export const GET_ORDER_TYPES: TypedDocumentNode<orderTypeArrayType> = gql`
  query GET_ORDER_TYPES {
    workorderType {
      id
      name
      color
      priority
      permission
    }
  }
`;
