import { z } from "zod";
import { coerceNumber } from "../../process/types";

export const teamSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  inHouse: z.boolean(),
  teamType: z.string(),
  subTeams: z.array(z.number()).optional(),
  processTypes: z.array(z.number()).optional(),
  products: z.array(coerceNumber).optional(),
});

export const teamTypeSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
});

export interface TeamTypeType {
  id: number;
  name: string;
}

export interface TeamTypeArayType {
  teamType: TeamTypeType[];
}

export interface TeamType {
  id: number;
  name: string;
  inHouse: boolean;
  type?: TeamTypeType;
  subTeams?: number[];
  processTypes?: number[];
  products?: number[];
}

export interface TeamArrayType {
  teams: TeamType[];
}

export interface NewTeamType {
  name: string;
  inHouse: boolean;
  type: number;
}
