import { useQuery } from "@apollo/client";
import { GET_PRICING_TABLES } from "../gqls";
import AddPricingTable from "./addPricingTable";
import PricingTable from "./pricingTable";
import { sort } from "fast-sort";

export default function PricingTables() {
  const { data } = useQuery(GET_PRICING_TABLES);
  const pricingTables = data?.productSetPricingTable || [];

  return (
    <div className="flex flex-col gap-2">
      <AddPricingTable />
      {sort(pricingTables)
        .by([
          { asc: table => table.productSetId },
          { asc: table => table.name },
        ])
        .map(table => (
          <PricingTable key={table.id} table={table} />
        ))}
    </div>
  );
}
