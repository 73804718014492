import { z } from "zod";
import { coerceNumber } from "../../../process/types";

type HEX = `#${string}`;

export const processTypeSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  description: z
    .string()
    .min(2, { message: "description cannot be empty" })
    .trim(),
  color: z.string(),
  path: z.array(z.string()).optional(),
  prefix: z
    .string()
    .max(2, { message: "prefix cannot have more than 2 characters" })
    .optional(),
  statuses: z.union([z.array(coerceNumber).optional(), z.null()]),
});

export interface NewProcessTypeType {
  name: string;
  description?: string;
  color: HEX;
  path: string[];
  prefix: string;
}

export interface ProcessTypeType extends NewProcessTypeType {
  id: number;
  statuses?: number[];
}

export interface ProcessTypeArrayType {
  processType: ProcessTypeType[];
}
