import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "flowbite-react";
import step1 from "../../../assets/calendar-sync-1.png";
import step2 from "../../../assets/calendar-sync-2.png";
import step3 from "../../../assets/calendar-sync-3.png";
import step4 from "../../../assets/calendar-sync-4.png";
import step5 from "../../../assets/calendar-sync-5.png";
import step6 from "../../../assets/calendar-sync-6.png";

export default function Howtosync() {
  return (
    <div className="space-y-6">
      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        By enabling this feature, your calendar will be usable through the CRM
        app.
      </p>
      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Please bear in mind that you are most likely not to sync your personal
        google calendar, but the calendar provided by the company. The below
        process can be completed by the admins, or by yourself provided that the
        calendar has been shared to you already.
      </p>

      <h1 className="text-gray-800 dark:text-white text-lg font-medium">
        Steps to syncing your calendar
      </h1>

      <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400 ml-6">
        <ol className="list-decimal space-y-2">
          <li>
            <div className="flex flex-row items-center">
              Go to your Settings in google calendar website by clicking the cog
              icon on the top right side and clicking "Setting"
              <Tooltip
                className="w-80"
                content={
                  <div>
                    <img src={step1} />
                  </div>
                }
              >
                <QuestionMarkCircleIcon className="w-6 ml-2 cursor-pointer" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="flex flex-row items-center">
              On the left sidebar, select the calendar you want to sync and go
              to "Share with specific people or groups"
              <Tooltip
                className="w-80"
                content={
                  <div>
                    <img src={step2} />
                  </div>
                }
              >
                <QuestionMarkCircleIcon className="w-6 ml-2 cursor-pointer" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="flex flex-row items-center">
              Click on the "Add people and groups" button
              <Tooltip
                className="w-80"
                content={
                  <div>
                    <img src={step3} />
                  </div>
                }
              >
                <QuestionMarkCircleIcon className="w-6 ml-2 cursor-pointer" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="flex flex-row items-center">
              <p>
                On dialouge that pops up, enter{" "}
                <span className="font-medium dark:text-white text-gray-900">
                  ezicrm@airy-ceremony-285205.iam.gserviceaccount.com
                </span>{" "}
                in the upper input and set permissions to "Make changes and
                manage sharing" and click "Send"
              </p>
              <Tooltip
                className="w-80"
                content={
                  <div>
                    <img src={step4} />
                  </div>
                }
              >
                <QuestionMarkCircleIcon className="w-6 ml-2 cursor-pointer" />
              </Tooltip>
            </div>
          </li>
          <li>
            (optional) if you are adding a calendar of your own, also share the
            calendar with the email address supplied by admin so they can check
            your schedule on their google calendar
          </li>
          <li>
            <div className="flex flex-row items-center">
              Now, go to Integrate calendar section
              <Tooltip
                className="w-80"
                content={
                  <div>
                    <img src={step5} />
                  </div>
                }
              >
                <QuestionMarkCircleIcon className="w-6 ml-2 cursor-pointer" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="flex flex-row items-center">
              Copy the Calendar ID under the integrate calendar title
              <Tooltip
                className="w-80"
                content={
                  <div>
                    <img src={step6} />
                  </div>
                }
              >
                <QuestionMarkCircleIcon className="w-6 ml-2 cursor-pointer" />
              </Tooltip>
            </div>
          </li>
          <li>
            <div className="flex flex-row items-center">
              Finally, paste in the calendar ID you copied in step 7 to your
              profile's Calendar Id section and Save
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
}
