import { useMutation, useQuery } from "@apollo/client";
import {
  GET_DEDUCTION_PRESETS_BY_PRODUCT,
  GET_PRODUCT_OPTIONS_BY_PID,
  GET_PRODUCT_SET_BY_ID,
  UPDATE_PRODUCT_SET_BY_PK,
} from "../../../workorder/gqls";
import {
  FormProvider,
  UseFieldArrayRemove,
  UseFieldArraySwap,
  UseFieldArrayUpdate,
  UseFormSetValue,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { ProductSetOrderPreset, optionProps } from "../../../workorder/types";
import { GET_PRODUCTS } from "../../products/products";
import {
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { Badge, Button, Modal } from "flowbite-react";
import ReleaseSettingEdit from "./releaseSettingEdit";
import { ProductType } from "../../products/types";
import { useScheme } from "../../../../../store/schemeStore";

interface props {
  productSetId: number;
}

export default function ProductSetReleaseSetting({ productSetId }: props) {
  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_BY_PID, {
    variables: {
      id: productSetId,
    },
  });

  const options = data_options?.productSetOptions;

  const { data: data_presets } = useQuery(GET_DEDUCTION_PRESETS_BY_PRODUCT, {
    variables: {
      id: productSetId,
    },
  });

  const { data: data_products } = useQuery(GET_PRODUCTS);

  const products = data_products?.products;

  const calcs = data_presets?.deductionPreset?.reduce<string[]>((prev, cur) => {
    if (cur.formulas) {
      return [...new Set(prev.concat(cur.formulas.map(f => f.name)))];
    } else {
      return prev;
    }
  }, []);

  const [defaultValues, setDefaultValues] = useState<{
    presets: ProductSetOrderPreset[];
    touched: boolean;
  }>({
    presets: [],
    touched: false,
  });

  useQuery(GET_PRODUCT_SET_BY_ID, {
    variables: {
      id: productSetId,
    },
    onCompleted(data) {
      const productSet = data.productSets_by_pk;
      if (productSet.orderPresets) {
        setDefaultValues({
          ...defaultValues,
          presets: productSet.orderPresets,
        });
        setValue("presets", productSet.orderPresets);
      }
    },
  });

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, control, reset, setValue } = methods;

  const { append, remove, update } = useFieldArray({
    name: "presets",
    control,
  });

  const addedPresets: ProductSetOrderPreset[] = useWatch({
    name: "presets",
    control,
  });

  const [adding, setAdding] = useState(false);

  const toggleAdding = () => {
    setAdding(!adding);
  };

  const add = (newPreset: ProductSetOrderPreset) => {
    append({ ...newPreset, productId: Number(newPreset.productId) });
    setValue("touched", true);
    setAdding(false);
  };

  const touched = useWatch({
    control,
    name: "touched",
  });

  const [savePreset] = useMutation(UPDATE_PRODUCT_SET_BY_PK);

  const onSubmit = handleSubmit(data => {
    savePreset({
      variables: {
        id: productSetId,
        set: {
          orderPresets: data.presets,
        },
      },
      onCompleted() {
        reset(data);
        setValue("touched", false);
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="flex flex-col gap-2">
        <div className="flex flex-row justify-between items-center">
          <h3>Order Release Presets</h3>
          {adding ? (
            <MinusCircleIcon
              onClick={toggleAdding}
              className="w-5 text-red-500 cursor-pointer"
            />
          ) : (
            <PlusCircleIcon
              onClick={toggleAdding}
              className="w-5 text-plum cursor-pointer"
            />
          )}
        </div>
        {adding && (
          <div className="flex flex-col gap-2 p-3 rounded-md border-[1px] ml-2">
            <ReleaseSettingEdit
              options={options}
              calcs={calcs}
              products={products}
              submitCB={add}
              cancelCB={() => {
                setAdding(false);
              }}
            />
          </div>
        )}
        {(!addedPresets || addedPresets.length == 0) && (
          <div className="flex flex-row">
            <Badge color="purple">no presets exist</Badge>
          </div>
        )}
        <div className="flex flex-col gap-2">
          {addedPresets.map((preset, i) => {
            const product = products?.find(
              p => p.id == Number(preset.productId)
            );
            return (
              <Preset
                key={i}
                remove={remove}
                setValue={setValue}
                products={products}
                product={product}
                preset={preset}
                options={options}
                calcs={calcs}
                index={i}
                update={update}
              />
            );
          })}
        </div>

        {touched && (
          <div className="flex flex-row justify-end gap-2 items-center">
            <Button
              onClick={() => {
                reset(defaultValues);
              }}
              size="xs"
              color="purple"
            >
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              outline
              type="submit"
            >
              save
            </Button>
          </div>
        )}
        <hr />
      </form>
    </FormProvider>
  );
}

interface presetProps {
  remove: UseFieldArrayRemove;
  setValue: UseFormSetValue<{
    presets: ProductSetOrderPreset[];
    touched: boolean;
  }>;
  products?: ProductType[];
  product?: ProductType;
  preset: ProductSetOrderPreset;
  options?: optionProps[];
  index: number;
  calcs?: string[];
  update: UseFieldArrayUpdate<
    {
      presets: ProductSetOrderPreset[];
      touched: boolean;
    },
    "presets"
  >;
}

const Preset = ({
  remove,
  setValue,
  product,
  preset,
  options,
  calcs,
  index,
  update,
  products,
}: presetProps) => {
  const [editing, setEditing] = useState(false);
  const onClose = () => {
    setEditing(false);
  };

  const { scheme } = useScheme();
  const updatePreset = (updatedPreset: ProductSetOrderPreset) => {
    update(index, updatedPreset);
    setValue("touched", true);
    setEditing(false);
  };

  return (
    <>
      <div className="group border-[1px] shadow-sm bg-white dark:bg-gray-800 dark:border-gray-700 rounded-md flex flex-col gap-2 p-2 relative">
        <PencilIcon
          className="hidden group-hover:block w-5 absolute text-grass top-2 right-8 cursor-pointer"
          onClick={() => {
            setEditing(true);
          }}
        />
        <TrashIcon
          className="hidden group-hover:block w-5 absolute text-red-500 top-2 right-1 cursor-pointer"
          onClick={() => {
            remove(index);
            setValue("touched", true);
          }}
        />

        <div className="flex flex-row gap-2 items-end">
          {product?.image && (
            <div className="ring-1 ring-dark rounded-full p-1 bg-white">
              <img src={product.image} className="w-5" />
            </div>
          )}
          <div className="font-semibold text-lg">{preset.name}</div>
          <div className="opacity-70">order for {product?.name}</div>
        </div>
        <div className="flex flex-row flex-wrap gap-2">
          {preset.options.map(opt => (
            <Badge color="purple" key={opt}>
              {options?.find(o => o.id == opt)?.name}
            </Badge>
          ))}
          {preset.calcs.map(c => (
            <Badge color="purple" key={c}>
              {c}
            </Badge>
          ))}
          {preset.includeComps && <Badge color="purple">Comp Sheet</Badge>}
        </div>
      </div>
      <Modal
        show={editing}
        onClose={onClose}
        className={`${scheme} !h-full !items-start`}
      >
        <Modal.Header>Edit {preset.name}</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-2">
            <ReleaseSettingEdit
              submitCB={updatePreset}
              cancelCB={() => {
                setEditing(false);
              }}
              calcs={calcs}
              defaultVals={preset}
              options={options}
              products={products}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
