import { Button, Spinner } from "flowbite-react";
import { QUOTE_PARAMS_FIELDS } from "../fragments";
import { ProductSetType } from "../../../workorder/types";
import { QuoteParams } from "../types";
import { ADD_QUOTE_PARAM, UPDATE_QUOTE_PARAM_BY_PK } from "../gqls";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

interface props {
  productSet: ProductSetType;
  compulsoryParams: QuoteParams[];
}

export default function PricingFormula({
  productSet,
  compulsoryParams,
}: props) {
  const existingParam = compulsoryParams.find(
    p => p.name == "formula" && p.productSetId == productSet.id
  );

  const formula = existingParam?.value || "";

  const defaultValues = {
    formula,
  };

  const [update, { loading: updating }] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);
  const [insert, { loading: inserting }] = useMutation(ADD_QUOTE_PARAM);

  const loading = updating || inserting;

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const onSubmit = handleSubmit(data => {
    if (existingParam) {
      update({
        variables: {
          id: existingParam.id,
          set: {
            value: data.formula,
          },
        },
        onCompleted() {
          reset({
            formula: data.formula,
          });
        },
      });
    } else {
      insert({
        variables: {
          object: {
            name: "formula",
            value: data.formula,
            productSetId: productSet.id,
          },
        },
        onCompleted() {
          reset({
            formula: data.formula,
          });
        },
        update(cache, { data: { insert_quoteParams_one: newParam } }) {
          cache.modify({
            fields: {
              quoteParams(existing = []) {
                const newParamRef = cache.writeFragment({
                  data: newParam,
                  fragment: QUOTE_PARAMS_FIELDS,
                  fragmentName: "QuoteParamsFields",
                });
                return [...existing, newParamRef];
              },
            },
          });
        },
      });
    }
  });

  const cancel = () => {
    reset({
      formula: formula,
    });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="p-2 border-b-[1px] flex flex-row items-center gap-2"
    >
      <h3>{productSet.name}</h3>

      <div className="flex flex-row items-center gap-1 flex-1">
        <input
          placeholder="enter pricing formula"
          className="p-1 text-grass text-lg font-semibold text-right bg-transparent border-none flex-1 outline-none"
          {...register("formula")}
        />
        {isDirty && (
          <div className="flex flex-row items-center gap-1">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone={"purpleToBlue"}
              type="submit"
            >
              update
              {loading && <Spinner size="xs" className="ml-1" color="purple" />}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
