import { useEffect, useState } from "react";
import { Coord, revGeocode } from "../../../utils/geocode";

export default function RevGeocoded({ coord }: { coord: Coord }) {
  const [address, setAddress] = useState("");
  useEffect(() => {
    revGeocode(coord).then(d => {
      if (Array.isArray(d)) {
        const item = d[0];
        const _address = `${item?.address?.street} ${item?.address?.district}`;
        setAddress(_address);
      }
    });
  }, [coord]);
  return <>{address}</>;
}
