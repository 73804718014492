import { useOrderStore } from "../../../../../../store/orderStore";
import { useQuoteStore } from "../../../../../../store/quoteStore";
import isNullish from "../../../../../../utils/isNullish";
import { MaterialsType } from "../../../../materials/types";
import { calc, item, opening, option, order } from "../../types";
import { create, all } from "mathjs";
import findPriceFromTable from "./findPriceFromTable";
import useScopeConcat from "../ordering/options/scopeConcat";

interface props {
  isOpening: boolean;
  item?: item;
  opening?: opening;
  productSetId: number;
  sqm: number;
  isRetail: boolean;
  userId?: number;
  itemSalesParams?: item["salesParams"] | opening["salesParams"];
  salesParams?: order["salesParams"];
}

export default function useGetPrice() {
  const { materials, presets, productSets } = useOrderStore();
  const { quoteParams, pricingTables } = useQuoteStore();

  const defaultMargin =
    quoteParams.find(param => param.name == "margin" && !param.productSetId)
      ?.value || 30;

  const math = create(all);

  math.SymbolNode.onUndefinedSymbol = e => {
    // console.log(e);
    return 0;
  };

  const scopeConcat = useScopeConcat();

  return ({
    isOpening,
    item,
    opening,
    sqm,
    productSetId,
    isRetail,
    userId,
    itemSalesParams,
    salesParams,
  }: props) => {
    try {
      const productSet = productSets.find(ps => ps.id == productSetId);
      const productMargin = quoteParams.find(
        param => param.name == "margin" && param.productSetId == productSetId
      )?.value;
      const productBaseInstallCost = quoteParams.find(
        param => param.name == "install" && param.productSetId == productSetId
      )?.value;

      const myProductSalesParam = salesParams?.productParams?.find(
        p => p.productSetId == productSetId
      );

      const wholesaleMargin =
        isNullish(myProductSalesParam?.margin) && !isRetail
          ? isNullish(productMargin?.wholesale)
            ? defaultMargin
            : productMargin.wholesale
          : myProductSalesParam?.margin || 0;

      let sqmPrice = quoteParams.find(
        param =>
          param.name == "sqmPrice" && param.productSetId == opening?.product
      )?.value;

      sqmPrice = Number(
        isRetail ? sqmPrice?.retail || 0 : sqmPrice?.wholesale || 0
      );

      if (!isRetail) {
        const dealerSqmPrice = quoteParams.find(
          p =>
            p.name == "sqmPrice" &&
            p.productSetId == opening?.product &&
            p.userId == userId
        );

        if (dealerSqmPrice) {
          sqmPrice = Number(dealerSqmPrice.value);
        }
      }

      const wholesaleDiscountRate = Number(
        !isRetail
          ? quoteParams.find(
              p =>
                p.name == "discount" &&
                p.productSetId == opening?.product &&
                p.userId == userId
            )?.value || 0
          : 0
      );

      let retailProductDiscount = {
        value: 0,
        type: "rate",
      };

      const retailItemDiscount = Number(itemSalesParams?.discount || 0);

      const retailMargin = isNullish(myProductSalesParam?.margin)
        ? Number(productMargin?.retail)
        : Number(myProductSalesParam?.margin);

      if (isRetail) {
        if (myProductSalesParam?.sqm) {
          sqmPrice = Number(myProductSalesParam?.sqm);
        }
        if (myProductSalesParam?.discount) {
          retailProductDiscount = myProductSalesParam?.discount;
        }
      }

      const materialCost =
        item?.materials?.reduce((acc, cur) => {
          const material = materials.find(mat => mat.id == cur.id);
          if (!material) {
            return acc;
          }

          const price =
            material.prices.find(p => p.unit == cur.unit)?.price || 0;
          const materialPrice = price * cur.qty;

          return acc + materialPrice;
        }, 0) || 1;

      const formula = quoteParams.find(
        param => param.name == "formula" && param.productSetId == productSetId
      )?.value;

      const options: option[] = item?.options || opening?.options || [];
      const calcs: calc[] = item?.calcs || [];

      const optionNamePopulatedCalcs = calcs.map(c => ({
        ...c,
        optionName: options.find(o => o.id == c.optionId)?.name || "",
      }));

      const calcScopes = optionNamePopulatedCalcs.reduce<any>(
        (prv, cur) => {
          return {
            ...prv,
            [cur.name.replaceAll(" ", "")]: cur.size,
            [`${cur.optionName}${cur.name}`.replaceAll(" ", "")]: cur.size,
          };
        },
        {
          W: item?.width,
          H: item?.height,
        }
      );

      let scopes = options.reduce<any>(
        (prv, cur) => {
          const scopeName = cur.name.replaceAll(" ", "");
          let appended = {
            ...prv,
          };

          appended = scopeConcat({
            prvScopes: appended,
            scopeName,
            option: cur,
          });

          return appended;
        },
        {
          ...calcScopes,
          sqm,
          material: materialCost,
          margin: wholesaleMargin,
          sqmPrice,
        }
      );

      const myTable = pricingTables
        .filter(
          table =>
            table.productSetId == productSetId &&
            (!table.condition || math.evaluate(table.condition, scopes))
        )
        .sort((a, b) => Number(!!b.condition) - Number(!!a.condition))[0];

      const width = isOpening
        ? opening?.options?.find(o => o.name == "W")?.value
        : item?.width;
      const height = isOpening
        ? opening?.options?.find(o => o.name == "H")?.value
        : item?.height;

      const tablePrice = myTable
        ? findPriceFromTable({
            width,
            height,
            table: myTable,
          })
        : 0;

      scopes = {
        ...scopes,
        tablePrice,
      };

      const presetOptions =
        (options &&
          options.filter(o => o.source == "deductionPreset" && !o.noCalc)) ||
        [];

      const production = presetOptions.reduce<number>((prev, cur) => {
        const preset = presets.find(p => p.id == cur.value);
        const name = cur.name;
        if (!preset || !preset.labour) {
          return prev;
        }
        let presetScopes = { ...scopes, name };

        for (const prop in scopes) {
          if (prop.includes(name)) {
            presetScopes = {
              ...presetScopes,
              [prop.replace(name, "")]: scopes[prop],
            };
          }
        }

        // console.log(presetScopes);

        return prev + math.evaluate(preset.labour, presetScopes);
      }, 0);

      scopes = {
        ...scopes,
        production,
      };

      const productQty = math.evaluate(productSet?.qtyCalc || "1", scopes);

      const installationCost = () => {
        const supOnly = Number(itemSalesParams?.supplyOnly);
        if (supOnly) {
          return 0;
        }

        const baseCost = math.evaluate(
          myProductSalesParam?.baseInstall || productBaseInstallCost || "1",
          scopes
        );

        let installCost = baseCost * productQty;

        if (itemSalesParams?.installParams) {
          const installParamsCost =
            itemSalesParams.installParams.reduce<number>((prev, cur) => {
              if (Number(cur.value)) {
                return (
                  prev +
                  math.evaluate(cur.price || "1", { ...scopes, baseCost })
                );
              }
              return prev;
            }, 0);

          installCost += installParamsCost;
        }

        return installCost;
      };

      // console.log(materialCost, production);

      if (formula) {
        let cost = math.evaluate(formula, scopes);

        if (isRetail) {
          if (retailMargin) {
            cost = cost / ((100 - retailMargin) / 100);
          }
        }

        let discount = wholesaleDiscountRate
          ? cost - cost * (wholesaleDiscountRate / 100)
          : 0;

        if (isRetail) {
          discount =
            retailItemDiscount +
            (retailProductDiscount.type == "rate"
              ? (cost * Number(retailProductDiscount.value)) / 100
              : Number(retailProductDiscount.value));
        }

        // console.log(cost, installationCost());

        return {
          price: Math.ceil(cost - discount),
          install: isRetail ? installationCost() || 0 : undefined,
        };
      }
      if (isOpening && opening) {
        let cost = sqm * sqmPrice;

        let discount = wholesaleDiscountRate
          ? cost - cost * (wholesaleDiscountRate / 100)
          : 0;

        if (isRetail) {
          discount =
            retailItemDiscount +
            (retailProductDiscount.type == "rate"
              ? (cost * Number(retailProductDiscount.value)) / 100
              : Number(retailProductDiscount.value));
        }

        return {
          price: Math.ceil(cost - discount),
          install: isRetail ? installationCost() || 0 : undefined,
        };
      }
      if (!isOpening && item) {
        let cost =
          (materialCost + production) / ((100 - wholesaleMargin) / 100);

        if (isRetail) {
          cost = cost / ((100 - retailMargin) / 100);
        }
        let discount = wholesaleDiscountRate
          ? cost - cost * (wholesaleDiscountRate / 100)
          : 0;

        if (isRetail) {
          discount =
            retailItemDiscount +
            (retailProductDiscount.type == "rate"
              ? (cost * Number(retailProductDiscount.value)) / 100
              : Number(retailProductDiscount.value));
        }

        return {
          price: Math.ceil(cost - discount),
          install: isRetail ? installationCost() || 0 : undefined,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        price: 0,
        install: 0,
      };
    }
  };
}
