import { useIsMounted } from "usehooks-ts";
import { useNavStore } from "../../../../store/navStore";
import { useScheme } from "../../../../store/schemeStore";
import { ProcessType } from "../types";
import ListItem from "./listItem";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";

interface props {
  process: ProcessType[];
}

export default function ProcessList({ process }: props) {
  const length = process.length;
  const { scheme } = useScheme();

  const renderRow: ListRowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style} className="row pb-[10px]">
        <ListItem process={process[index]} />
      </div>
    );
  };

  const { containerRef } = useNavStore();
  const mounted = useIsMounted();

  return (
    <section className="px-6 h-[calc(100vh-62px)] mt-1">
      <AutoSizer className={scheme}>
        {({ width, height }) => (
          <List
            onScroll={() => {
              if (containerRef && mounted()) {
                containerRef.scrollTo({
                  behavior: "smooth",
                  top: containerRef.scrollHeight,
                });
              }
            }}
            height={height}
            width={width}
            rowCount={length}
            rowHeight={99.96 + 10}
            rowRenderer={renderRow}
            overscanRowCount={3}
          />
        )}
      </AutoSizer>
    </section>
  );
}
