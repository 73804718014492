import { MapsProps } from "../map/map";
import { useEffect, useRef, useState } from "react";
import { UserType } from "../../../settings/users/types";
import {
  getSchedules,
  getVacancies,
  vacancyList,
  vacancyType,
} from "../../../../../utils/calendarMethods";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { calcDistance, numberCoord } from "../../../../../utils/geocode";
import { useDebounce } from "usehooks-ts";
import { addAlert } from "../../../../../store/alertStore";
import { MarkerProp } from "../../types";
import { default as config } from "../../../../../config";
import { myLineProps } from "../map/line";
import { ArrowPathIcon, HomeIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import Booking from "./booking";
import Vacancies from "./vacancies";
import Calendar from "./calendar";
import { BreifEvent, BreifEventList } from "../../../../../types/calendarTypes";

interface ScheduleProps extends Omit<MapsProps, "lines" | "setShowSchedule"> {
  sales: UserType[];
  homeMarker: MarkerProp;
}

export default function Schedule({
  showSchedule,
  homeMarker,
  markers,
  setMarkers,
  sales,
  setLines,
}: ScheduleProps) {
  const [schedules, setSchedules] = useState<BreifEventList[]>([]);
  const [vacancies, setVacancies] = useState<vacancyList[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedSales, setSelectedSales] = useState<UserType[]>(sales);
  const debouncedSales = useDebounce(selectedSales, 1000);

  const toggleSales = (id: number) => {
    if (selectedSales.find(user => user.id == id)) {
      setSelectedSales(state => state.filter(user => user.id !== id));
    } else {
      const rep = sales.find(s => s.id == id);
      if (rep) {
        setSelectedSales(state => state.concat(rep));
      }
    }
  };

  const customerMarker = markers.find(marker => marker?.label == "Customer");
  const previousMarker = useRef<MarkerProp>();

  const [configs, setConfigs] = useState({
    preferEarliest: false,
    week: 2,
    minH: 2,
  });
  const [seeCalendar, setSeeCalendar] = useState(false);

  const previousConfigs = useRef(configs);

  const { setValue } = useFormContext();

  const loadSchedules = (scheduleList: UserType[]) => {
    setLoading(true);
    getSchedules(scheduleList)
      .then(eventLists => {
        setSchedules(eventLists);

        const vacancyLists = getVacancies({
          eventLists,
          customerLocation: customerMarker?.position,
          minH: configs.minH,
          weekSpan: configs.week,
        });

        setVacancies(vacancyLists);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        addAlert({ message: "could not load schedules", type: "failure" });
        setLoading(false);
      });
  };

  const reload = () => {
    const FilteredSalesList = sales.filter(
      s => s.calId && debouncedSales.find(ss => ss.id == s.id)
    );
    loadSchedules(FilteredSalesList);
  };

  useEffect(() => {
    const FilteredSalesList = sales.filter(
      s => s.calId && debouncedSales.find(ss => ss.id == s.id)
    );

    if (FilteredSalesList.length < 1 && loading) {
      return;
    }

    const filteredSalesIds = FilteredSalesList.map(f => f.id);
    const existingSalesIds = vacancies.map(v => v.user.id);

    if (previousMarker.current !== customerMarker) {
      loadSchedules(FilteredSalesList);
      previousMarker.current = customerMarker;
      return;
    }

    if (
      previousConfigs.current.minH !== configs.minH ||
      previousConfigs.current.week !== configs.week
    ) {
      previousConfigs.current = configs;
      loadSchedules(FilteredSalesList);
    }

    if (!filteredSalesIds.every(v => existingSalesIds.includes(v))) {
      loadSchedules(FilteredSalesList);
      return;
    }
  }, [sales, debouncedSales, customerMarker, configs.week, configs.minH]);

  const [vacancy, setVacancy] = useState<vacancyType>();

  useEffect(() => {
    setSchedule(undefined);

    let markers = customerMarker ? [customerMarker, homeMarker] : [homeMarker];
    let lines: myLineProps[] = [];

    setMarkers(markers);
    setLines(lines);

    if (!vacancy) {
      return;
    }
    setValue("salesRep", vacancy.user.id);
    const prevGeocode =
      vacancy.prevEvent?.geocode ||
      vacancy.user.baseLocation ||
      config.companyLoc;
    const nextGeocode =
      vacancy.nextEvent?.geocode ||
      vacancy.user.baseLocation ||
      config.companyLoc;

    const prevInfo = vacancy.prevEvent ? (
      <div className="p-2 ring-1 ring-gray-200 dark:ring-gray-600 bg-gray-100 dark:bg-gray-700 flex-1 rounded-md flex flex-col text-xs">
        <div>{vacancy.prevEvent.summary}</div>
        <div>
          {dayjs(vacancy.prevEvent.start).format("H:mm")}~
          {dayjs(vacancy.prevEvent.end).format("H:mm")}
        </div>
      </div>
    ) : undefined;

    const prevMarker: MarkerProp = {
      position: {
        lat: Number(prevGeocode.lat),
        lng: Number(prevGeocode.lng),
      },
      label: "prevLocation",
      info: prevInfo,
    };

    if (calcDistance(numberCoord(prevGeocode), config.companyLoc) < 500) {
      prevMarker.icon = <HomeIcon className="w-6" />;
      markers = markers.filter(m => m.label !== "Company");
    }

    if (
      vacancy.user.baseLocation &&
      calcDistance(
        numberCoord(prevGeocode),
        numberCoord(vacancy.user.baseLocation)
      ) < 500
    ) {
      prevMarker.icon = <HomeIcon className="w-6" />;
    }

    const nextInfo = vacancy.nextEvent ? (
      <div className="p-2 ring-1 ring-gray-200 dark:ring-gray-600 bg-gray-100 dark:bg-gray-700 flex-1 rounded-md flex flex-col text-xs">
        <div>{vacancy.nextEvent.summary}</div>
        <div>
          {dayjs(vacancy.nextEvent.start).format("H:mm")}~
          {dayjs(vacancy.nextEvent.end).format("H:mm")}
        </div>
      </div>
    ) : undefined;

    const nextMarker: MarkerProp = {
      position: {
        lat: Number(nextGeocode.lat),
        lng: Number(nextGeocode.lng),
      },
      label: "nextLocation",
      info: nextInfo,
    };

    if (calcDistance(numberCoord(nextGeocode), config.companyLoc) < 500) {
      nextMarker.icon = <HomeIcon className="w-6" />;
      markers = markers.filter(m => m.label !== "Company");
    }

    if (
      vacancy.user.baseLocation &&
      calcDistance(
        numberCoord(nextGeocode),
        numberCoord(vacancy.user.baseLocation)
      ) < 500
    ) {
      nextMarker.icon = <HomeIcon className="w-6" />;
    }

    markers = [...markers, prevMarker, nextMarker];
    setMarkers(markers);

    if (!customerMarker) {
      return;
    }

    if (
      calcDistance(numberCoord(prevGeocode), numberCoord(nextGeocode)) < 500
    ) {
      nextMarker.label = "";
      prevMarker.label = "returnLocation";
    }

    const prevLine: myLineProps = {
      path: [customerMarker.position, numberCoord(prevGeocode)],
    };
    const nextLine: myLineProps = {
      path: [customerMarker.position, numberCoord(nextGeocode)],
    };
    lines = [...lines, prevLine, nextLine];
    setLines(lines);
  }, [vacancy]);

  const [schedule, setSchedule] = useState<BreifEvent>();

  useEffect(() => {
    setVacancy(undefined);

    let markers = customerMarker ? [customerMarker, homeMarker] : [homeMarker];
    let lines: myLineProps[] = [];

    setMarkers(markers);
    setLines(lines);

    if (!schedule?.geocode) {
      return;
    }

    const Info = (
      <div className="p-2 ring-1 ring-gray-200 dark:ring-gray-600 bg-gray-100 dark:bg-gray-700 flex-1 rounded-md flex flex-col text-xs">
        <div>{schedule.summary}</div>
        <div>
          {dayjs(schedule.start).format("H:mm")}~
          {dayjs(schedule.end).format("H:mm")}
        </div>
      </div>
    );

    const marker: MarkerProp = {
      position: {
        lat: Number(schedule.geocode.lat),
        lng: Number(schedule.geocode.lng),
      },
      label: "Schedule",
      info: Info,
    };

    markers = [...markers, marker];
    setMarkers(markers);
  }, [schedule]);

  return (
    <section
      className={`${
        showSchedule ? "flex" : "hidden"
      } col-span-full flex-row flex-wrap z-20 gap-4`}
    >
      <div className="space-y-2 flex-1 flex flex-col min-w-[75%]">
        <h2>Schedule</h2>
        <div className="shadow-md flex-1 bg-white dark:bg-gray-800 rounded-md p-4 space-y-2 ">
          {/* Sales select and configs */}
          <div className="flex flex-row flex-wrap mt-1 gap-4">
            <div className="space-y-2 flex-grow">
              <Label value="Sales" />
              <div className="flex flex-row flex-wrap p-1 mb-2 gap-2">
                {sales.map(user => (
                  <div
                    key={user.id}
                    className="flex items-center gap-2"
                    onClick={() => {
                      toggleSales(user.id);
                    }}
                  >
                    <Checkbox
                      className="cursor-pointer"
                      readOnly
                      checked={
                        selectedSales.find(ss => ss.id == user.id)
                          ? true
                          : false
                      }
                    />
                    <Label className="cursor-pointer">
                      {user.firstName} {user.sirName[0].toUpperCase()}
                    </Label>
                  </div>
                ))}
              </div>
            </div>
            {/* Preference */}
            <div className="space-y-2 flex flex-col">
              <Label value="Preference" />
              <Button.Group>
                <Button
                  color={`${!configs.preferEarliest ? "purple" : "gray"}`}
                  size="xs"
                  className="py-1"
                  onClick={() => {
                    setConfigs(state => ({
                      ...state,
                      preferEarliest: false,
                    }));
                  }}
                >
                  Efficiency
                </Button>
                <Button
                  color={`${configs.preferEarliest ? "purple" : "gray"}`}
                  size="xs"
                  className="py-1"
                  onClick={() => {
                    setConfigs(state => ({
                      ...state,
                      preferEarliest: true,
                    }));
                  }}
                >
                  Earliest
                </Button>
              </Button.Group>
            </div>
            <div className="flex flex-row items-center gap-2">
              {/* Min H */}
              <div className="space-y-2 flex flex-col">
                <Label value="Min H" />
                <TextInput
                  className="w-12"
                  type="number"
                  sizing="sm"
                  value={configs.minH}
                  onChange={e => {
                    const str = e.target.value;
                    if (str == "") {
                      return;
                    }
                    if (Number(str) > 5) {
                      return;
                    }
                    if (Number(str) < 0) {
                      return;
                    }

                    setConfigs(state => ({
                      ...state,
                      minH: Math.round(Number(str)),
                    }));
                  }}
                />
              </div>
              {/* Span */}
              <div className="space-y-2 flex flex-col">
                <Label value="Span" />
                <TextInput
                  className="w-12"
                  type="number"
                  sizing="sm"
                  value={configs.week}
                  onChange={e => {
                    const str = e.target.value;
                    if (str == "") {
                      return;
                    }
                    if (Number(str) > 4) {
                      return;
                    }
                    if (Number(str) < 0) {
                      return;
                    }

                    setConfigs(state => ({
                      ...state,
                      week: Math.round(Number(str)),
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center pt-2">
            <Button.Group>
              <Button
                color={`${!seeCalendar ? "purple" : "gray"}`}
                size="xs"
                className="py-1"
                onClick={() => {
                  setSeeCalendar(false);
                }}
              >
                Vacancies
              </Button>
              <Button
                color={`${seeCalendar ? "purple" : "gray"}`}
                size="xs"
                className="py-1"
                onClick={() => {
                  setSeeCalendar(true);
                }}
              >
                Calendar
              </Button>
            </Button.Group>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              outline
              onClick={reload}
            >
              <ArrowPathIcon
                className={`w-4 ${loading ? "animate-spin" : ""}`}
              />
            </Button>
          </div>
          {/* Vacancies */}
          {!seeCalendar && (
            <Vacancies
              vacancy={vacancy}
              configs={configs}
              loading={loading}
              selectedSales={selectedSales}
              setVacancy={setVacancy}
              vacancies={vacancies}
            />
          )}
          {seeCalendar && (
            <Calendar
              schedules={schedules}
              setSchedule={setSchedule}
              selectedSales={selectedSales}
              setVacancy={setVacancy}
              vacancies={vacancies}
              selectedSchedule={schedule}
              selectedVacancy={vacancy}
            />
          )}
        </div>
      </div>
      <Booking minH={configs.minH} vacancy={vacancy} />
    </section>
  );
}
