import { Breadcrumb, Tabs } from "flowbite-react";
import {
  BuildingStorefrontIcon,
  HashtagIcon,
  HomeIcon,
  TableCellsIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useOrderStorePopulate } from "../../../../store/orderStore";
import BasePriceFactors from "./priceFactors/priceFactors";
import DealerPriceFactors from "./dealerPriceFactors/dealerPriceFactors";
import InstallationPriceFactors from "./InstallationPriceFactors/installationPriceFactors";
import PricingTables from "./pricingTable/pricingTables";

export default function QuoteRelated() {
  const navigate = useNavigate();

  const isLoading = useOrderStorePopulate();

  if (isLoading) {
    return null;
  }

  return (
    <div className="mb-1 flex-grow mx-auto">
      <section className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-4">
        <nav className="w-full">
          <Breadcrumb className="w-full">
            <Breadcrumb.Item
              className="cursor-pointer"
              onClick={() => {
                navigate("../");
              }}
            >
              <HomeIcon className="w-5 mr-2" />
              Settings
            </Breadcrumb.Item>

            <Breadcrumb.Item>Quote</Breadcrumb.Item>
          </Breadcrumb>
        </nav>
        <main className="space-y-4">
          <Tabs.Group style="underline">
            <Tabs.Item icon={HashtagIcon} title="Base Pricing">
              <BasePriceFactors />
            </Tabs.Item>
            <Tabs.Item icon={TableCellsIcon} title="Pricing Table">
              <PricingTables />
            </Tabs.Item>
            <Tabs.Item icon={WrenchScrewdriverIcon} title="Install Pricing">
              <InstallationPriceFactors />
            </Tabs.Item>
            <Tabs.Item icon={BuildingStorefrontIcon} title="Dealer Settings">
              <DealerPriceFactors />
            </Tabs.Item>
          </Tabs.Group>
        </main>
      </section>
    </div>
  );
}
