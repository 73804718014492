import { useQuery } from "@apollo/client";
import {
  AdjustmentsHorizontalIcon,
  ArrowDownTrayIcon,
  CalendarIcon,
  QueueListIcon,
  TableCellsIcon,
} from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { refineFilters, useRecordStore } from "../../../store/recordStore";
import { GET_RECORD, STREAM_RECORDS } from "../shared/record/gqls";
import Filters from "./filters";
import RecordListView from "./recordListView";
import RecordTableView from "./recordTableView";
import { range } from "./types";
import { addDays } from "../../../utils/dateMethods";
import { exportData } from "../../../utils/arrayObjMethods";
import { cloneDeep } from "apollo-utilities";

export default function RecordList() {
  const { filters, cursor, setCursor } = useRecordStore();

  const { data, loading, subscribeToMore } = useQuery(GET_RECORD, {
    variables: {
      where: refineFilters(filters).where,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    onError(error) {
      console.log(error);
    },
    onCompleted() {
      setCursor(new Date().toISOString());
    },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: STREAM_RECORDS,
      variables: {
        where: refineFilters(filters).where,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.records);
        const newFeedItem = subscriptionData.data.records_stream;
        const existing = previousData.filter(
          p => !newFeedItem.find(i => i.id == p.id)
        );

        return Object.assign({}, previous, {
          records: [...newFeedItem, ...existing],
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [filters]);

  const records = data?.records;

  const [view, setView] = useState(true);
  const toggleView = () => {
    setView(!view);
  };

  const [filtering, setFiltering] = useState(false);
  const toggleFiltering = () => {
    setFiltering(!filtering);
  };

  const today = dayjs(new Date()).format("YYYY-MM-DD");

  const rangeFilter = filters.find(f => f.name == "Range");
  const range = rangeFilter?.values || ({ to: today, from: today } as range);

  const dates: Date[] = [];

  let span = dayjs(range.to).diff(dayjs(range.from), "days");

  for (let i = 0; i <= span; i++) {
    const date = addDays(new Date(range.from), i);
    dates.push(date);
  }

  const exportToExcel = () => {
    const _data = records?.map(record => ({
      name: `${record.user.firstName} ${record.user.sirName}`,
      date: dayjs(record.start).format("YYYY-MM-DD"),
      in: dayjs(record.start).format("HH:mm"),
      out: dayjs(record.start).format("HH:mm"),
      break: record.break,
      inLocation: `${record.startLoc?.lat} / ${record.startLoc?.lng}`,
      outLocation: `${record.endLoc?.lat} / ${record.endLoc?.lng}`,
      fixes: record.logs?.length,
      desc: record.desc || "",
      type: record.type.name,
      status: record.status.name,
    }));
    exportData(_data);
  };

  return (
    <section className="flex flex-col gap-2">
      <div className="flex flex-row gap-2 items-center justify-between flex-wrap">
        <div className="flex flex-row gap-2 items-center">
          <div
            className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
            onClick={toggleView}
          >
            {view && <QueueListIcon className="w-4" />}
            {!view && <TableCellsIcon className="w-4" />}
            View
          </div>
          <div
            onClick={toggleFiltering}
            className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
          >
            <AdjustmentsHorizontalIcon className="w-4" />
            Filters
          </div>
          <div
            onClick={exportToExcel}
            className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
          >
            <ArrowDownTrayIcon className="w-4" />
            Export
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="flex flex-row gap-1 items-center">
            <CalendarIcon className="w-4 pb-[2px]" />
            {dayjs(range?.from || "").format("DD/MM/YY")} -{" "}
            {dayjs(range?.to || "").format("DD/MM/YY")}
          </div>
          {loading && <Spinner color="purple" size="sm" />}
        </div>
      </div>
      <Filters filtering={filtering} />
      {view && records && (
        <RecordListView records={records} range={range} dates={dates} />
      )}
      {view == false && records && (
        <RecordTableView records={records} range={range} dates={dates} />
      )}
    </section>
  );
}
