import { Outlet, useRoutes } from "react-router-dom";
import { useEffect, useState } from "react";
import ProcessList from "./processLoader";
import AddProcess from "./addProcess/addProcess";
import {
  useProcessStore,
  useDefaultFilters,
  refineFilters,
} from "../../../store/processStore";
import ProcessDetail from "./processDetail/processDetail";
import { processQueryParams } from "./filter/types";
import Converter from "./addProcess/converter";
import DirConverter from "./dirConverter";
import { useSearchStore } from "../../../store/searchStore";
import { useProcessSearch } from "./processSearch";
import { useOrderStorePopulate } from "../../../store/orderStore";

const ProcessWrapper = () => {
  const { setSearchFunction, clearSearchFunction, setSearchResults } =
    useSearchStore();

  const searchProcess = useProcessSearch();

  useEffect(() => {
    setSearchFunction(str => {
      if (!str) {
        return;
      }
      searchProcess(str);
    });
    return () => {
      clearSearchFunction();
      setSearchResults([]);
    };
  }, []);

  const loading = useOrderStorePopulate();

  if (loading) {
    return null;
  }

  return <Outlet />;
};

export default function Process() {
  const { filters } = useProcessStore();
  useDefaultFilters();
  const [refinedfilters, setRefinedFilters] = useState<processQueryParams>({
    where: {
      _and: [],
    },
    order_by: {},
    offset: 0,
  });

  useEffect(() => {
    const _refinedFilters = refineFilters(filters);
    setRefinedFilters(_refinedFilters);
  }, [filters]);

  const processRoutes = useRoutes([
    {
      path: "/",
      element: <ProcessWrapper />,
      children: [
        {
          path: "/",
          element: <ProcessList filters={refinedfilters} />,
        },
        { path: "add", element: <AddProcess /> },
        { path: "detail/:id/:screen?", element: <ProcessDetail /> },
        { path: "converter", element: <Converter /> },
        { path: "dirConverter", element: <DirConverter /> },
      ],
    },
  ]);
  return processRoutes;
}
