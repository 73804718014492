import { useRoutes } from "react-router-dom";
import Contacts from "./contact/contacts";
import Container from "./container";
import Dashboard from "./dashboard/dashboard";
import Process from "./process/process";
import Profile from "./profile/profile";
import Settings from "./settings/settings";
import Todo from "./todos/todo";
import Records from "./records/records";
import { getHolidays } from "../../utils/calendarMethods";
import { useEffect } from "react";
import { useHolidayStore } from "../../store/holidayStore";
import Production from "./production/production";
import FullWindow from "../../comps/pdfViewer/FullWindow";
import Order from "./workorder/order";
import Assignment from "./assignment/assignment";
import Schedule from "./schedule/schedule";

export default function ProtectedRoot() {
  const { setHolidays } = useHolidayStore();

  useEffect(() => {
    getHolidays().then(data => {
      setHolidays(data);
    });
  }, []);

  let routes = useRoutes([
    {
      path: "/",
      element: <Container />,
      children: [
        { path: "/", element: <Dashboard /> },
        { path: "*", element: <Dashboard /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "schedule/*", element: <Schedule /> },
        { path: "process/*", element: <Process /> },
        { path: "contacts/*", element: <Contacts /> },
        { path: "profile", element: <Profile /> },
        { path: "settings/*", element: <Settings /> },
        { path: "todo/*", element: <Todo /> },
        { path: "records/*", element: <Records /> },
        { path: "production/*", element: <Production /> },
        { path: "order/*", element: <Order /> },
        { path: "assignment/*", element: <Assignment /> },
        { path: "pdf/:name/:file", element: <FullWindow /> },
      ],
    },
  ]);

  return routes;
}
