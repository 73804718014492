import { PencilIcon } from "@heroicons/react/24/solid";
import { ProcessHistory } from "../types";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { Button, Spinner } from "flowbite-react";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { useMutation } from "@apollo/client";
import { processDetailSubProps } from "../types";
import { addAlert } from "../../../../store/alertStore";
import { createPathArr, rename } from "../../../../utils/fileAPI";

const resolver = z.object({
  name: z.string().min(1, { message: "name cannot be empty" }),
});

interface props extends processDetailSubProps {
  jobPath: false | string[];
}

export default function ProcessName({ process, baseHistory, jobPath }: props) {
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const { name } = process;

  const defaultValues = useCallback(() => ({ name }), [process]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: defaultValues(),
  });

  useEffect(() => {
    reset(defaultValues());
  }, [process]);

  const [update_process_name, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);

  const onSubmit = handleSubmit(async data => {
    if (!isDirty) {
      return addAlert({ message: "nothing changed", type: "warning" });
    }
    const history: ProcessHistory = {
      ...baseHistory,
      message: `name updated from ${process.name} to ${data.name}`,
      type: "detail",
    };

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(history)
      : [history];

    update_process_name({
      variables: {
        id: process.id,
        set: {
          name: data.name,
          histories,
        },
      },
      onCompleted(res) {
        reset({ name: data.name });
        toggleEditing();
        if (jobPath) {
          const newProcess = res.update_process_by_pk;
          const newPath = createPathArr(newProcess);
          if (newPath) {
            rename(jobPath, newPath);
          }
        }
      },
    });
  });

  const cancel = () => {
    reset();
    toggleEditing();
  };

  return (
    <form
      onSubmit={onSubmit}
      className="group flex-1 flex flex-row items-center gap-2 justify-between @md:justify-start"
    >
      {editing ? (
        <input
          className="bg-transparent outline-none opacity-70 text-2xl w-full"
          autoFocus
          {...register("name")}
        />
      ) : (
        <h1>{process.name}</h1>
      )}
      {/* TODO AuthCheck */}
      <div>
        {editing ? (
          <div className="flex flex-row items-center gap-2">
            <Button size="xs" color="gray" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              update
            </Button>
          </div>
        ) : (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 hover:text-grass hidden group-hover:block cursor-pointer mr-1"
          />
        )}
      </div>
    </form>
  );
}
