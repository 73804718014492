import { z } from "zod";
import { User } from "../../../store/authStore";
import { PermissionType } from "../settings/auths/permissions/types";
import {
  contactTypeSchema,
  ContactTypeType,
} from "../settings/contact/contactTypes/types";
import { PaymentStatusType } from "../settings/payment/paymentStatuses/types";
import { ProcessStatusType } from "../settings/process/processStatuses/types";
import { ProcessTypeType } from "../settings/process/processTypes/types";
import { SourceType } from "../settings/process/sources/types";
import { TagType } from "../settings/process/tags/types";
import { ProductType } from "../settings/products/types";
import { TeamType } from "../settings/teams/types";
import { UserType } from "../settings/users/types";
import { OrderType } from "../workorder/types";

export const coerceNumber = z.coerce.number().transform(val => Number(val));
export const optionalEmail = z.union([
  z.null(),
  z.string().length(0),
  z.string().email({ message: "please enter a valid email" }),
]);

export const coerceDate = z
  .union([z.null(), z.string(), z.coerce.date()])
  .transform(arg => (arg ? new Date(arg).toISOString() : null));

export const processProductSchema = z.object({
  productId: coerceNumber,
  qty: coerceNumber,
});

export const processNewContactSchema = z.object({
  name: z.string(),
  number: z.string().optional(),
  mail: optionalEmail,
  address: z.string().optional(),
  typeID: coerceNumber,
});

export const processContactSchema = z.object({
  id: coerceNumber,
  name: z.string(),
  number: z.union([z.string().optional(), z.null()]),
  address: z.union([z.string().optional(), z.null()]),
  mail: z.any(),
  contactType: contactTypeSchema,
});

export const special = /[`!@#$%^*_+\=\[\]{};':"\\|,.<>\/?~]/g;

export const processSchema = z
  .object({
    name: z.string().min(2, { message: "name cannot be empty" }).trim(),
    year: coerceNumber,
    number: coerceNumber,
    typeId: coerceNumber,
    address: z.string().optional(),
    postCode: z.coerce
      .number()
      .transform(val => Number(val))
      .optional(),
    description: z.string().optional(),
    returning: z.boolean().optional(),
    salesRep: z.coerce
      .number()
      .transform(val => Number(val))
      .optional(),
    status: z.coerce
      .number()
      .positive({ message: "status shoud be selected" })
      .transform(val => Number(val)),
    from: z
      .string()
      .transform(arg => (arg ? new Date(arg).toISOString() : undefined))
      .optional(),
    due: z
      .string()
      .transform(arg => (arg ? new Date(arg).toISOString() : undefined))
      .optional(),
    products: z.array(processProductSchema).optional(),
    tags: z.array(z.number()).optional(),
    sources: z.array(z.number()).optional(),
    newContact: processNewContactSchema.optional(),
    contacts: z.array(processContactSchema).optional(),
    schedules: z.array(z.string()).optional(),
    value: z.coerce
      .number()
      .transform(val => Number(val))
      .optional(),
  })
  .refine(
    process => {
      return !special.test(process.name);
    },
    { message: "special characrter not allowed", path: ["name"] }
  )
  .refine(
    process => {
      return (
        (process.contacts && process.contacts.length > 0) || process.newContact
      );
    },
    { message: "add at least one contact", path: ["contacts"] }
  );

export interface ProcessProductType {
  productId: number;
  qty: number;
}

export interface NewProcessType {
  name: string;
  year: number;
  number: number;
  typeId: number;
  address?: string;
  postCode?: number;
  description?: string;
  returning?: boolean;
  salesRep?: number;
  status: number;
  from: Date | null;
  due: Date | null;
  products: ProcessProductType[];
  tags?: number[];
  sources?: number[];
  schedules?: string[];
  newContact?: {
    name: string;
    number?: string;
    mail?: string;
    address?: string;
    typeID?: number;
  };
  contacts: {
    id: number;
    name: string;
    number?: string;
    mail?: string;
    address?: string;
    typeID?: number;
  }[];
  workorders?: Pick<OrderType, "id" | "createdBy" | "orderRef" | "type">[];
}

export interface salesRepUser {
  email: string;
  id: number;
  firstName: string;
  sirName: string;
}

export interface ProcessHistory {
  type: "status" | "detail";
  message: string;
  status: number;
  date: string;
  by: number;
  link?: string;
}

export interface ProcessType
  extends Omit<NewProcessType, "typeId" | "salesRep" | "status" | "contacts"> {
  id: number;
  processType: ProcessTypeType;
  processStatus: ProcessStatusType;
  salesRepUser?: salesRepUser;
  created_at: string;
  updated_at: string;
  deleted: boolean;
  histories?: ProcessHistory[];
  value?: number;
  tags?: number[];
  sources?: number[];
  contacts?: number[];
  paymentStatus?: PaymentStatusType;
  primaryContact?: number;
  createdUser: {
    email: string;
    id: number;
    firstName: string;
    sirName: string;
    team: {
      id: number;
      name: string;
    };
  };
}

export interface ProcessArrayType {
  process: ProcessType[];
}

export interface ProcessStreamType {
  process_stream: ProcessType[];
}

export interface RELATED_CONFIGS {
  processStatus: ProcessStatusType[];
  processType: ProcessTypeType[];
  products: ProductType[];
  sources: SourceType[];
  tags: TagType[];
  contactType: ContactTypeType[];
  teams: TeamType[];
  users: UserType[];
  permissions: PermissionType[];
  paymentStatus: PaymentStatusType[];
}

export interface MarkerProp {
  position: {
    lat: number;
    lng: number;
  };
  label?: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  onClick?: () => void;
}

export interface processDetailSubProps {
  process: ProcessType;
  user?: User;
  baseHistory: Omit<ProcessHistory, "message" | "type">;
}
