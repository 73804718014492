import { TypedDocumentNode, gql } from "@apollo/client";
import { RELEASED_ORDERS_CORE_FIELDS } from "./fragments";
import { PopulatedReleasedOrderType, ReleasedOrdersArrayType } from "./types";
import { WORKORDER_CORE_FIELDS } from "../fragments";

export const GET_RELEASED_ORDERS_BY_IDS: TypedDocumentNode<ReleasedOrdersArrayType> = gql`
  ${RELEASED_ORDERS_CORE_FIELDS}
  query GET_RELEASED_ORDERS_BY_IDS($ids: [Int!]!) {
    releasedOrders(where: { id: { _in: $ids } }) {
      ...RelasedOrdersCoreFields
    }
  }
`;

export const GET_RELEASED_ORDERS_CONDITIONAL: TypedDocumentNode<ReleasedOrdersArrayType> = gql`
  ${RELEASED_ORDERS_CORE_FIELDS}
  query GET_RELEASED_ORDERS_CONDITIONAL($where: releasedOrders_bool_exp!) {
    releasedOrders(where: $where) {
      ...RelasedOrdersCoreFields
    }
  }
`;

interface releasedOrders_by_pk {
  releasedOrders_by_pk: PopulatedReleasedOrderType;
}

export const GET_RELEASED_ORDER_BY_PK: TypedDocumentNode<releasedOrders_by_pk> = gql`
  ${RELEASED_ORDERS_CORE_FIELDS}
  ${WORKORDER_CORE_FIELDS}
  query GET_RELEASED_ORDER_BY_PK($id: Int!) {
    releasedOrders_by_pk(id: $id) {
      ...RelasedOrdersCoreFields
      workorder {
        ...WorkorderCoreFields
      }
    }
  }
`;

export const ADD_RELEASED_ORDERS = gql`
  ${RELEASED_ORDERS_CORE_FIELDS}
  mutation ADD_RELEASED_ORDERS($object: releasedOrders_insert_input!) {
    insert_releasedOrders_one(object: $object) {
      ...RelasedOrdersCoreFields
    }
  }
`;
