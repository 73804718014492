import { gql } from "@apollo/client";

export const RELEASED_ORDERS_CORE_FIELDS = gql`
  fragment RelasedOrdersCoreFields on releasedOrders {
    id
    orderId
    productSetId
    presetName
    productionId
  }
`;
