import dayjs from "dayjs";
import { useEffect, useState } from "react";

export default function RecordClock() {
  const [date, setDate] = useState(new Date());
  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  return <>{dayjs(date).format("HH:mm:ss")}</>;
}
