import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Badge,
  Button,
  Label,
  ListGroup,
  Select,
  Spinner,
  TextInput,
  ToggleSwitch,
} from "flowbite-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { UPDATE_CONTACT_BY_PK } from "./gqls";
import {
  ArrowTopRightOnSquareIcon,
  BuildingOffice2Icon,
  ChatBubbleLeftEllipsisIcon,
  CheckBadgeIcon,
  CheckIcon,
  EnvelopeIcon,
  IdentificationIcon,
  MagnifyingGlassIcon,
  MapIcon,
  PencilIcon,
  PhoneIcon,
  TagIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { addAlert } from "../../../store/alertStore";
import {
  Autocomplete,
  AutocompleteProps,
  LoadScriptProps,
  useLoadScript,
} from "@react-google-maps/api";
import { MarkerProp, coerceNumber } from "../process/types";
import { ContactType } from "./types";
import checkAuth from "../../../utils/checkAuth";
import { GET_CONTACT_TYPES } from "../settings/contact/contactTypes/contactTypes";
import { default as config } from "../../../config";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];
import { SEARCH_CONTACT } from "../process/addProcess/contact";
import { useDebounce } from "usehooks-ts";
import { useNavigate } from "react-router-dom";

const companyLoc = config.companyLoc;

const resolver = z.object({
  address: z.string().optional(),
  number: z.string().optional(),
  mail: z.string().optional(),
  isOrganisation: z.boolean(),
  typeID: coerceNumber,
  organisation: z.any(),
  description: z.string().optional(),
  role: z.string().optional(),
});

interface props {
  contact: ContactType;
  setMarkers: Dispatch<SetStateAction<MarkerProp[]>>;
}

export const REMOVE_CONTACT = gql`
  mutation REMOVE_CONTACT($id: Int!) {
    delete_contacts_by_pk(id: $id) {
      id
    }
  }
`;

// TODO: CheckAuth, can edit status
export default function ContactDetails({ contact, setMarkers }: props) {
  const { data, error } = useQuery(GET_CONTACT_TYPES);
  const types = data?.contactType;
  if (error) {
    console.log(error);
  }

  const {
    address,
    number,
    mail,
    isOrganisation,
    contactType,
    organisation,
    description,
    role,
  } = contact;
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const [search, setSearch] = useState("");
  const [results, setResults] = useState<ContactType[]>([]);
  const debouncedSearch = useDebounce(search, 500);
  const [search_contact] = useLazyQuery(SEARCH_CONTACT);

  useEffect(() => {
    if (search.trim() == "") {
      setResults([]);
    }
    search_contact({
      variables: { Str: debouncedSearch },
      fetchPolicy: "network-only",
      onCompleted(data) {
        const res = data.search_contacts?.filter(
          c => c.isOrganisation && c.id !== contact.id
        );

        if (res) {
          setResults(res);
        }
      },
    });
  }, [debouncedSearch]);

  const defaultValues = {
    address: address ? address : "",
    number: number ? number : "",
    mail: mail ? mail : "",
    isOrganisation: isOrganisation,
    typeID: contactType.id,
    organisation: organisation ? organisation : null,
    description: description ? description : "",
    role: role ? role : "",
  };

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [contact]);

  const formIsOrganisation = watch("isOrganisation");
  const toggleOrganisation = () => {
    setValue("isOrganisation", !formIsOrganisation, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const formOrganisation = watch("organisation");
  const setOrganisation = (org: ContactType) => {
    setValue("organisation", org, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };
  const clearOrganisation = () => {
    setValue("organisation", null, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const [update_contact, { loading }] = useMutation(UPDATE_CONTACT_BY_PK);
  const [remove_contact] = useMutation(REMOVE_CONTACT);

  const onSubmit = handleSubmit(async data => {
    if (!isDirty) {
      addAlert({ message: "nothing changed", type: "warning" });
      return;
    }

    update_contact({
      variables: {
        id: contact.id,
        set: {
          ...data,
          organisation: undefined,
          organisationID: data.organisation?.id || null,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update contact",
          type: "failure",
        });
      },
      onCompleted() {
        reset({ ...data });
        removeMarker();
        toggleEditing();
      },
    });
  });

  const removeMarker = () => {
    setMarkers(markers =>
      markers.filter(marker => marker.label !== "new address")
    );
  };

  const cancel = () => {
    reset();
    toggleEditing();
    removeMarker();
    setResults([]);
    setSearch("");
  };

  const _delete = () => {
    const proceed = confirm("delete contact?");
    if (!proceed) {
      return;
    }
    update_contact({
      variables: {
        id: contact.id,
        set: {
          deleted: true,
        },
      },
      onError(error) {
        console.log(error);
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: contact.id,
          __typename: "contacts",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
        remove_contact({
          variables: { id: contact.id },
        });
        navigate("/contacts");
      },
    });
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });

  const autoCompleteLoaded = isLoaded
    ? (autocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(autocomplete);
      }
    : () => {};

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const {
        formatted_address: address,
        address_components,
        geometry,
      } = place;

      const { lat, lng } = {
        lat: geometry?.location?.lat(),
        lng: geometry?.location?.lng(),
      };
      if (lat && lng) {
        const marker: MarkerProp = {
          position: { lat, lng },
          label: "new address",
        };

        setMarkers(markers => {
          return [...markers, marker];
        });
      }

      const postCode = address_components
        ? address_components[address_components.length - 1].long_name
        : null;

      if (address) {
        setValue(
          "address",
          address
            .replace(", Australia", "")
            .replace(postCode || "", "")
            .trim()
        );
      }
    }
  };

  const autocompleteOptions: AutocompleteProps["options"] = {
    componentRestrictions: { country: "au" },
    bounds: {
      north: companyLoc.lat + 3,
      south: companyLoc.lat - 3,
      east: companyLoc.lng + 3,
      west: companyLoc.lng - 3,
    },
  };

  const canEdit = checkAuth("contacts_edit");

  const navigate = useNavigate();

  const goToOrganisation = () => {
    if (organisation) {
      navigate("../detail/" + organisation.id);
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      className="col-span-1 
      space-y-2 flex flex-col"
    >
      <div className="flex flex-row items-center gap-2 justify-between @md:justify-start"></div>
      <h2>Details</h2>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
        <div className="flex flex-col gap-2">
          <Label className="flex-1" value="Address" />
          {isLoaded && (
            <Autocomplete
              onLoad={autoCompleteLoaded}
              onPlaceChanged={onPlaceChanged}
              options={autocompleteOptions}
              fields={[
                "formatted_address",
                "geometry.location",
                "address_component",
              ]}
            >
              <TextInput
                icon={MapIcon}
                sizing="sm"
                {...register("address")}
                disabled={loading || !editing}
                color={errors.address?.message ? "failure" : undefined}
                style={{ opacity: 1 }}
                placeholder={editing ? "Enter address" : "No Address"}
              />
            </Autocomplete>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Label className="flex-1" value="Type" />
          <Select
            icon={TagIcon}
            sizing="sm"
            {...register("typeID")}
            disabled={loading || !editing}
            color={errors.number?.message ? "failure" : undefined}
            style={{ opacity: 1 }}
          >
            {types?.map(type => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col gap-2">
          <Label className="flex-1" value="Number" />
          <TextInput
            icon={PhoneIcon}
            sizing="sm"
            {...register("number")}
            disabled={loading || !editing}
            color={errors.number?.message ? "failure" : undefined}
            style={{ opacity: 1 }}
            placeholder={editing ? "Enter number" : "No Number"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label className="flex-1" value="Mail" />
          <TextInput
            icon={EnvelopeIcon}
            sizing="sm"
            {...register("mail")}
            disabled={loading || !editing}
            color={errors.mail?.message ? "failure" : undefined}
            style={{ opacity: 1 }}
            placeholder={editing ? "Enter email" : "No Email"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label className="flex-1" value="Role" />
          <TextInput
            icon={IdentificationIcon}
            sizing="sm"
            {...register("role")}
            disabled={loading || !editing}
            color={errors.role?.message ? "failure" : undefined}
            style={{ opacity: 1 }}
            placeholder={editing ? "Enter role" : "No Role"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label className="flex-1" value="Description" />
          <TextInput
            icon={ChatBubbleLeftEllipsisIcon}
            sizing="sm"
            {...register("description")}
            disabled={loading || !editing}
            color={errors.description?.message ? "failure" : undefined}
            style={{ opacity: 1 }}
            placeholder={editing ? "Enter description" : "No Desc"}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label className="flex-1" value="Organisation" />
          {!editing && (
            <div className="flex flex-row gap-2">
              <TextInput
                icon={BuildingOffice2Icon}
                sizing="sm"
                style={{ opacity: 1 }}
                disabled
                value={organisation?.name || "Nill"}
                className="flex-1"
              />
              {organisation && (
                <ArrowTopRightOnSquareIcon
                  className="w-5 cursor-pointer"
                  onClick={goToOrganisation}
                />
              )}
            </div>
          )}
          {editing && (
            <>
              <TextInput
                icon={MagnifyingGlassIcon}
                sizing="sm"
                style={{ opacity: 1 }}
                onChange={e => {
                  setSearch(e.target.value);
                }}
                value={search}
                placeholder="Search contacts"
              />
              <ListGroup>
                {results?.map(res => (
                  <ListGroup.Item
                    key={res.id}
                    onClick={() => {
                      setOrganisation(res);
                    }}
                  >
                    <div className="flex flex-row gap-1">
                      {res.id == formOrganisation?.id && (
                        <CheckIcon className="w-4" />
                      )}
                      {res.name} - {res.mail}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              <Badge
                color="purple"
                className="w-min cursor-pointer"
                onClick={clearOrganisation}
              >
                <div className="flex flex-row gap-2">
                  {formOrganisation?.name || "Nill"}
                  {formOrganisation && <TrashIcon className="w-4" />}
                </div>
              </Badge>
            </>
          )}
        </div>
        <div className="flex flex-row justify-end mt-2 gap-2 ">
          {editing ? (
            <ToggleSwitch
              checked={formIsOrganisation ? true : false}
              label="Is Organisation"
              onChange={toggleOrganisation}
              //@ts-expect-error
              color="purple"
              disabled={loading}
              style={{ opacity: 1 }}
            />
          ) : (
            <div className="flex flex-row gap-2">
              {formIsOrganisation ? (
                <>
                  <CheckBadgeIcon className="w-4" />
                  Organisation Contact
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="flex justify-end flex-row items-center gap-2 mt-2">
          {editing && (
            <>
              <Button size="xs" color="failure" onClick={_delete}>
                delete
              </Button>
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                type="submit"
                outline
              >
                {loading && <Spinner size="xs" light className="mr-1" />}
                update
              </Button>
            </>
          )}
          {!editing && canEdit && (
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              onClick={toggleEditing}
              outline
            >
              <PencilIcon className="w-3 mr-2" /> Edit
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
