import { useNavigate } from "react-router-dom";
import Heading from "../../../comps/heading";
import { useAuthStore } from "../../../store/authStore";
import Record from "../shared/record/record";
import Agenda from "./agenda";

export default function Dashboard() {
  const { user } = useAuthStore();

  const navigate = useNavigate();

  if (user?.auth.name == "dealer") {
    navigate("order");
    return null;
  }

  const welcome = `G'day ${user?.firstName}`;
  return (
    <main className="select-none flex flex-col flex-1">
      <header className="px-6">
        <Heading name={welcome} />
        <div className="flex flex-row text-grass mb-10">
          dashboard, stats, check-in
        </div>
      </header>
      <section className="px-6">
        <div
          className={` grid grid-cols-1 
            @md:grid-cols-2 supports-[not(container-type:inline-size)]:md:grid-cols-2 
            @lg:grid-cols-3 supports-[not(container-type:inline-size)]:lg:grid-cols-3 
            @xl:grid-cols-4 supports-[not(container-type:inline-size)]:xl:grid-cols-4 
            @2xl:grid-cols-5 supports-[not(container-type:inline-size)]:2xl:grid-cols-5
            gap-4 mt-2`}
        >
          <div className="col-span-1">
            <Record />
          </div>
          <Agenda />
        </div>
      </section>
    </main>
  );
}
