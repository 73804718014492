import { HomeIcon } from "@heroicons/react/24/solid";
import { Badge, Breadcrumb, Spinner } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useQuery, TypedDocumentNode, useLazyQuery } from "@apollo/client";
import { ContactArrayType, ContactStreamType, ContactType } from "./types";
import { CONTACT_CORE_FIELDS } from "./fragments";
import ContactName from "./contactName";
import ContactActions from "./contactActions";
import { useState } from "react";
import { MarkerProp, ProcessArrayType, ProcessType } from "../process/types";
import ContactMaps from "./contactMaps";
import ContactDetails from "./contactDetails";
import { PROCESS_CORE_FIELDS } from "../process/fragments";
import { fullNumber } from "../../../utils/fullNumber";
import ContactsTable from "./contactsTable";

interface contacts_by_pk_type {
  contacts_by_pk: ContactType;
}

export const GET_CONTACT_BY_ORGANISATION: TypedDocumentNode<ContactArrayType> = gql`
  ${CONTACT_CORE_FIELDS}
  query GET_CONTACT_BY_ORGANISATION($id: Int!) {
    contacts(where: { organisationID: { _eq: $id } }) {
      ...ContactCoreFields
    }
  }
`;

export const GET_PROCESS_BY_CONTACT: TypedDocumentNode<ProcessArrayType> = gql`
  ${PROCESS_CORE_FIELDS}
  query GET_PROCESS_BY_CONTACT($contacts: jsonb!) {
    process(
      where: {
        _and: [
          {
            _or: [
              { contacts: { _contains: $contacts } }
              { contacts: { _contained_in: $contacts } }
            ]
          }
          { contacts: { _gt: 0 } }
        ]
      }
    ) {
      ...ProcessCoreFields
    }
  }
`;

export const GET_CONTACT_BY_PK: TypedDocumentNode<contacts_by_pk_type> = gql`
  ${CONTACT_CORE_FIELDS}
  query GET_CONTACT_BY_PK($id: Int!) {
    contacts_by_pk(id: $id) {
      ...ContactCoreFields
    }
  }
`;

export const STREAM_CONTACT_BY_PK: TypedDocumentNode<ContactStreamType> = gql`
  ${CONTACT_CORE_FIELDS}
  subscription STREAM_CONTACT_BY_PK(
    $where: contacts_bool_exp
    $cursor: [contacts_stream_cursor_input]!
  ) {
    contacts_stream(where: $where, cursor: $cursor, batch_size: 1) {
      ...ContactCoreFields
    }
  }
`;

export default function ContactDetail() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, loading, error, subscribeToMore } = useQuery(
    GET_CONTACT_BY_PK,
    {
      variables: { id: Number(id) },
    }
  );

  if (error) {
    console.log(error);
  }

  const [get_process_by_contact, { data: data_process }] = useLazyQuery(
    GET_PROCESS_BY_CONTACT,
    { fetchPolicy: "network-only" }
  );
  const { data: data_sub_contacts } = useQuery(GET_CONTACT_BY_ORGANISATION, {
    variables: { id: id },
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      const contacts = data.contacts.map(c => c.id).concat(Number(id));
      get_process_by_contact({
        variables: {
          contacts,
        },
        onError(error) {
          console.log(error);
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });

  const process = data_process?.process;

  subscribeToMore({
    document: STREAM_CONTACT_BY_PK,
    variables: {
      where: { id: { _eq: id } },
      cursor: {
        initial_value: { updated_at: new Date().toISOString() },
        ordering: "ASC",
      },
    },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.contacts_stream[0];
      if (!newFeedItem) return prev;

      return Object.assign({}, prev, {
        contacts_by_pk: newFeedItem,
      });
    },
  });

  const contact = data?.contacts_by_pk;
  const [markers, setMarkers] = useState<MarkerProp[]>([]);

  return (
    <main className="px-6 pb-10 select-none flex flex-col h-full">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("../");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            Contact
          </Breadcrumb.Item>
          <Breadcrumb.Item className="truncate">
            {contact?.name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      {loading && !contact && (
        <div className="flex-1 flex justify-center items-center">
          <Spinner size="xl" light color="purple" />
        </div>
      )}
      {contact && (
        <article className="mt-3">
          {/* Name and actions */}
          <div className="flex flex-row items-center gap-2">
            <ContactName contact={contact} />
            <ContactActions contact={contact} />
          </div>
          <div
            className="grid grid-cols-1 
            @lg:grid-cols-3 supports-[not(container-type:inline-size)]:lg:grid-cols-3
            gap-4 mt-2"
          >
            <ContactMaps contact={contact} markers={markers} />
            <ContactDetails contact={contact} setMarkers={setMarkers} />
          </div>
          {contact.isOrganisation && (
            <div className="mt-2 space-y-2">
              <h2>Organisation Contacts</h2>

              <ContactsTable contacts={data_sub_contacts?.contacts} />
            </div>
          )}
          <div className="mt-2 space-y-2">
            <h2>Associated Process</h2>
            <div className="flex flex-row gap-2 flex-wrap">
              {process?.map(p => (
                <Process key={p.id} process={p} />
              ))}
              {process?.length == 0 && <Badge color="purple">Nill</Badge>}
            </div>
          </div>
        </article>
      )}
    </main>
  );
}

const Process = ({ process }: { process: ProcessType }) => {
  const fullNum = fullNumber(
    process.processType.prefix,
    process.year,
    process.number,
    process.salesRepUser
  );

  const navigate = useNavigate();

  const go = () => {
    navigate("/process/detail/" + process.id);
  };

  return (
    <Badge className="cursor-pointer" color="purple" onClick={go}>
      [{fullNum}] {process.name}
    </Badge>
  );
};
