import { gql } from "@apollo/client";

export const PROCESS_TYPE_CORE_FIELDS = gql`
  fragment ProcessTypeCoreFields on processType {
    id
    name
    description
    color
    path
    prefix
    statuses
  }
`;
