import { useRef, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { PROCESS_LOGS_CORE_FIELDS } from "./fragments";
import { LogType } from "./types";
// @ts-expect-error
import md5 from "md5";
import { Avatar, Button, ListGroup, Spinner } from "flowbite-react";
import checkAuth from "../../../../utils/checkAuth";
import { useAuthStore } from "../../../../store/authStore";
import { useOnClickOutside } from "usehooks-ts";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
dayjs.extend(relativeTime);

export const UPDATE_LOG = gql`
  ${PROCESS_LOGS_CORE_FIELDS}
  mutation UPDATE($id: Int!, $content: String!) {
    update_processLogs_by_pk(
      pk_columns: { id: $id }
      _set: { content: $content }
    ) {
      ...ProcessLogsCoreFields
    }
  }
`;

export const DELETE_LOG = gql`
  ${PROCESS_LOGS_CORE_FIELDS}
  mutation DELETE_LOG($id: Int!) {
    update_processLogs_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      ...ProcessLogsCoreFields
    }
  }
`;

export const REMOVE_LOG = gql`
  ${PROCESS_LOGS_CORE_FIELDS}
  mutation REMOVE_LOG($id: Int!) {
    delete_processLogs_by_pk(id: $id) {
      id
    }
  }
`;

export default function Log({ log }: { log: LogType }) {
  const [deleteLogById, { loading: deleting }] = useMutation(DELETE_LOG);
  const [removeLogById] = useMutation(REMOVE_LOG);
  const [updateLogById, { loading: updating }] = useMutation(UPDATE_LOG);
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setShowMenu(false);
    setEditing(!editing);
  };
  const { user } = useAuthStore();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      content: log.content,
    },
  });

  const onSubmit = handleSubmit(data => {
    updateLogById({
      variables: {
        id: log.id,
        content: data.content,
      },
      onCompleted() {
        setEditing(false);
        reset({
          content: data.content,
        });
      },
    });
  });

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setShowMenu(false);
  });

  const deleteLog = () => {
    setShowMenu(false);
    deleteLogById({
      variables: { id: log.id },
      update(cache, { data: { update_processLogs_by_pk: deleted } }) {
        const normalizedId = cache.identify({
          id: deleted.id,
          __typename: "processLogs",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onCompleted() {
        removeLogById({ variables: { id: log.id } });
      },
    });
  };

  const avatarUrl = `https://www.gravatar.com/avatar/${md5(
    log.user.email
  )}?d=mp`;

  const dayDiff = dayjs(log.timeStamp).diff(dayjs(new Date()), "day");
  const date =
    dayDiff > 2
      ? dayjs(log.timeStamp).format("DD/MM/YY")
      : dayjs(log.timeStamp).fromNow();

  const canEdit = checkAuth([
    "log_edit_others",
    {
      permission: "log_edit_mine",
      checkGroup: [user?.id || -1],
      conditionGroup: [log.user.id],
    },
  ]);

  const cancel = () => {
    setEditing(false);
    reset();
  };

  return (
    <div
      ref={ref}
      className={`relative flex flex-row border-[1px] shadow-sm items-start ${
        !editing ? "group" : ""
      } bg-white dark:bg-gray-800 dark:border-gray-700 p-2 rounded-md gap-4 ${
        deleting ? "opacity-50" : ""
      }`}
    >
      <Avatar img={avatarUrl} rounded bordered color="purple" />
      {canEdit && (
        <>
          <div
            onClick={toggleMenu}
            className="absolute group-hover:block hidden right-2 p-1 rounded-sm dark:bg-gray-500 bg-gray-100 cursor-pointer"
          >
            <EllipsisHorizontalIcon className="w-4" />
          </div>
          {showMenu && (
            <ListGroup className="absolute z-10 right-2 top-10">
              <ListGroup.Item onClick={toggleEditing}>
                <div className="flex flex-row gap-2">
                  <PencilIcon className="w-4" /> Edit
                </div>
              </ListGroup.Item>
              <ListGroup.Item onClick={deleteLog}>
                <div className="flex flex-row gap-2 text-red-500">
                  <TrashIcon className="w-4" />
                  Delete
                </div>
              </ListGroup.Item>
            </ListGroup>
          )}
        </>
      )}

      <form className="flex flex-col gap-1 flex-1" onSubmit={onSubmit}>
        <div className="flex flex-row items-center gap-2">
          <span className="font-semibold capitalize">
            {log.user.firstName} {log.user.sirName}
          </span>
          <span className="text-xs italic font-extralight ">{date}</span>
        </div>
        {editing ? (
          <>
            <textarea
              rows={3}
              autoFocus
              className="bg-transparent border-none scrollbar-none resize-none text-sm p-1 mb-2 rounded-md"
              {...register("content")}
            />
            <div className="flex flex-row items-center justify-end gap-2">
              <Button size="xs" gradientDuoTone="purpleToBlue" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                outline
                type="submit"
              >
                {updating && (
                  <div className="mr-1">
                    <Spinner size="sm" light={true} />
                  </div>
                )}
                update
              </Button>
            </div>
          </>
        ) : (
          <div> {log.content}</div>
        )}
      </form>
    </div>
  );
}
