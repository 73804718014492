import { Button } from "flowbite-react";
import { useNotificationStore } from "../../../store/notificationStore";
import NotificationItem from "./notification";
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/solid";

export default function Notifications() {
  const { notifications, sound, setSound, resetNotification } =
    useNotificationStore();

  const toggleSound = () => {
    setSound(!sound);
  };

  return (
    <div className="flex flex-col border-[1px] shadow-sm items-start bg-white dark:bg-gray-800 dark:border-gray-700 py-2 rounded-md gap-2 overflow-hidden">
      <div className="w-full flex flex-row justify-between items-center px-3">
        <h3>Notifications</h3>
        <div
          onClick={toggleSound}
          className="w-5 hover:text-plum cursor-pointer"
        >
          {sound ? <SpeakerWaveIcon /> : <SpeakerXMarkIcon />}
        </div>
      </div>

      <div className="px-3 w-full flex flex-col gap-2">
        {notifications.map(notification => (
          <NotificationItem
            key={notification.messageId}
            notification={notification}
          />
        ))}
      </div>
      {notifications.length == 0 && (
        <div className="px-3">No notifications received</div>
      )}
      {notifications.length > 0 && (
        <>
          <hr className="dark:border-gray-500 border-gray-300 w-full" />
          <div className="w-full flex flex-row justify-end px-3">
            <Button size="xs" outline color="gray" onClick={resetNotification}>
              clear all
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
