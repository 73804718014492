import { sort } from "fast-sort";
import { useOrderStore } from "../../../../../../store/orderStore";
import { ProductSetType } from "../../../types";
import { item, opening, option } from "../../types";
import Column from "./column";
import isNullish from "../../../../../../utils/isNullish";

interface props {
  product: ProductSetType;
  opening: opening;
  item?: item;
  locationIndex: number | string;
  singleLocation: boolean;
  noPrice?: boolean;
  hasItemDiscounts?: boolean;
  separateInstall?: boolean;
  gstInclusive?: boolean;
}

export default function SummaryItem({
  locationIndex,
  singleLocation,
  product,
  opening,
  item,
  noPrice,
  hasItemDiscounts,
  separateInstall,
  gstInclusive,
}: props) {
  const isOpening = product.hideItems;

  const index = `${singleLocation ? "" : `${locationIndex}.`}${opening.index}${
    !isOpening ? `.${item?.index}` : ""
  }`;

  const width = isOpening
    ? opening.options?.find(o => o.name == "W")?.value
    : item?.width;
  const height = isOpening
    ? opening.options?.find(o => o.name == "H")?.value
    : item?.height;

  let sqm = Number(((width * height) / 1000000).toFixed(2));
  const trueSqm = sqm;
  if (sqm < 0.5) {
    sqm = 0.5;
  }
  const price = isOpening ? opening.price || 0 : item?.price || 0;

  const install = isOpening
    ? isNullish(opening.customInstall)
      ? opening.install || 0
      : opening.customInstall || 0
    : isNullish(item?.customInstall)
    ? item?.install || 0
    : item?.customInstall || 0;

  const discount = isOpening
    ? opening.salesParams?.discount || 0
    : item?.salesParams?.discount || 0;

  const tdStyle = "px-2 font-semibold text-[9pt] text-center";

  const { orderSummaryColumn } = useOrderStore();

  const summaryColumns = sort(orderSummaryColumn).asc("position");

  const presetOptions = item?.options.filter(
    o => o.source == "deductionPreset" && !o.noCalc
  ) || [opening];

  const multipleConfigs = isOpening
    ? false
    : item &&
      item?.options.filter(o => o.source == "deductionPreset").length > 1;

  const rowSpan = isOpening ? 1 : presetOptions?.length;

  const options = isOpening ? opening.options || [] : item?.options || [];

  return (
    <>
      {presetOptions?.map((preset, i) => (
        <tr key={i} className="h-[22px]">
          <td className="w-[10px] bg-[#c1aa80]" />
          {i == 0 && (
            <>
              <td rowSpan={rowSpan} className={`${tdStyle} text-center px-1`}>
                {index}
              </td>
              <td rowSpan={rowSpan} className={tdStyle}>
                {opening.name || ""}
              </td>
              <td
                rowSpan={rowSpan}
                colSpan={multipleConfigs ? 1 : 2}
                className={tdStyle}
              >
                {product.name}
              </td>
            </>
          )}
          {multipleConfigs && <td className={tdStyle}>{preset.name}</td>}
          {i == 0 && (
            <>
              <td rowSpan={rowSpan} className={tdStyle}>
                {width}
              </td>
              <td rowSpan={rowSpan} className={tdStyle}>
                {height}
              </td>
            </>
          )}

          {summaryColumns.map(column => (
            <Column
              key={column.id}
              tdStyle={tdStyle}
              column={column}
              presetOptionsLength={presetOptions.length}
              preset={!isOpening ? (preset as option) : undefined}
              isOpening={isOpening}
              options={options}
            />
          ))}
          <td className={tdStyle}>{trueSqm}</td>
          {!noPrice && (
            <>
              {separateInstall && (
                <td className={tdStyle}>
                  $
                  {gstInclusive
                    ? Number(install * 1.1)
                        .toFixed(1)
                        .replace(".0", "")
                    : install}
                </td>
              )}
              <td className={tdStyle}>
                $
                {Number(gstInclusive ? price * 1.1 : price)
                  .toFixed(1)
                  .replace(".0", "")}
              </td>
              {hasItemDiscounts && (
                <td className={`${tdStyle} text-red-500`}>
                  {discount
                    ? `$${
                        gstInclusive
                          ? Number(discount * 1.1)
                              .toFixed(1)
                              .replace(".0", "")
                          : discount
                      }`
                    : ""}
                </td>
              )}
            </>
          )}
          <td className="w-[10px] bg-[#c1aa80]" />
        </tr>
      ))}
      {(!presetOptions || presetOptions.length < 1) && (
        <tr className="border-b-[1px] border-black">
          <td className="w-[10px] bg-[#c1aa80]" />
          <td rowSpan={rowSpan} className={`${tdStyle} text-center px-1`}>
            {index}
          </td>
          <td rowSpan={rowSpan} className={tdStyle}>
            {opening.name || ""}
          </td>
          <td
            rowSpan={rowSpan}
            colSpan={multipleConfigs ? 1 : 2}
            className={tdStyle}
          >
            {product.name}
          </td>

          <td rowSpan={rowSpan} className={tdStyle}>
            {width}
          </td>
          <td rowSpan={rowSpan} className={tdStyle}>
            {height}
          </td>

          {summaryColumns.map(column => (
            <Column
              key={column.id}
              tdStyle={tdStyle}
              column={column}
              presetOptionsLength={0}
              preset={undefined}
              isOpening={isOpening}
              options={options}
            />
          ))}
          <td className={tdStyle}>{sqm}</td>
          {!noPrice && (
            <>
              {separateInstall && (
                <td className={tdStyle}>
                  $
                  {gstInclusive
                    ? Number(install * 1.1)
                        .toFixed(1)
                        .replace(".0", "")
                    : install}
                </td>
              )}
              <td className={tdStyle}>
                $
                {Number(gstInclusive ? price * 1.1 : price)
                  .toFixed(1)
                  .replace(".0", "")}
              </td>
              {hasItemDiscounts && (
                <td className={`${tdStyle} text-red-500`}>
                  {discount
                    ? `$${
                        gstInclusive
                          ? Number(discount * 1.1)
                              .toFixed(1)
                              .replace(".0", "")
                          : discount
                      }`
                    : ""}
                </td>
              )}
            </>
          )}
          <td className="w-[10px] bg-[#c1aa80]" />
        </tr>
      )}
    </>
  );
}
