import { useCallback } from "react";
import { useOrderStore } from "../../../store/orderStore";
import { option, order } from "./createOrder/types";
import { ProductSetType } from "./types";
import { all, create } from "mathjs";
import { MaterialsType } from "../materials/types";
import isNullish from "../../../utils/isNullish";

interface productQty {
  productSet: ProductSetType;
  qty: number;
}

export default function useGetProductQty() {
  const { productSets } = useOrderStore();

  const math = create(all);

  math.SymbolNode.onUndefinedSymbol = e => {
    // console.log(e);
    return 0;
  };

  return useCallback((order: order) => {
    const locations = order.locations;
    const openings = locations.map(l => l.openings).flat();

    const qty = openings.reduce<productQty[]>((prev, cur) => {
      const ProductSet = productSets.find(ps => ps.id == Number(cur.product));

      if (!ProductSet) {
        return prev;
      }

      const isOpeningProduct = ProductSet?.hideItems;
      const openingOptions: option[] = cur?.options || [];
      const openingScopes = openingOptions?.reduce<any>((prv, cur) => {
        const scopeName = cur.name.replaceAll(" ", "");
        let appended = {
          ...prv,
          [scopeName]: cur.value,
        };
        if (cur.source == "inventory") {
          const material = cur.populatedValue as MaterialsType;
          if (material) {
            appended = {
              ...appended,
              [`${scopeName}_W`]: isNullish(material.widthDeduction)
                ? 0
                : Number(material.widthDeduction),
              [`${scopeName}_H`]: isNullish(material.heightDeduction)
                ? 0
                : Number(material.heightDeduction),
              [`${scopeName}_Size`]: isNullish(material.size)
                ? 0
                : Number(material.size),
            };
          }
        }
        return appended;
      }, {});

      const myQty = Number(
        isOpeningProduct
          ? math.evaluate(ProductSet.qtyCalc || "1", openingScopes)
          : cur.items.reduce<number>((prev, cur) => {
              const itemOptions: option[] = cur?.options || [];
              const itemScopes = itemOptions?.reduce<any>((prv, cur) => {
                const scopeName = cur.name.replaceAll(" ", "");
                let appended = {
                  ...prv,
                  [scopeName]: cur.value,
                };
                if (cur.source == "inventory") {
                  const material = cur.populatedValue as MaterialsType;
                  if (material) {
                    appended = {
                      ...appended,
                      [`${scopeName}_W`]: isNullish(material.widthDeduction)
                        ? 0
                        : Number(material.widthDeduction),
                      [`${scopeName}_H`]: isNullish(material.heightDeduction)
                        ? 0
                        : Number(material.heightDeduction),
                      [`${scopeName}_Size`]: isNullish(material.size)
                        ? 0
                        : Number(material.size),
                    };
                  }
                }
                return appended;
              }, {});
              return (
                prev + math.evaluate(ProductSet.qtyCalc || "1", itemScopes)
              );
            }, 0)
      );

      if (prev.find(p => p.productSet.id == ProductSet.id)) {
        return prev.map(p => {
          if (p.productSet.id == ProductSet.id) {
            return {
              ...p,
              qty: p.qty + myQty,
            };
          }
          return p;
        });
      }

      return prev.concat({
        productSet: ProductSet,
        qty: myQty,
      });
    }, []);

    return qty;
  }, productSets);
}
