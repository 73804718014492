import { gql, useQuery, TypedDocumentNode } from "@apollo/client";
import { PROCESS_TYPE_CORE_FIELDS } from "./fragments";
import { ProcessTypeArrayType } from "./types";
import { addAlert } from "../../../../../store/alertStore";
import { Spinner } from "flowbite-react";
import ProcessType from "./processType";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import checkAuth from "../../../../../utils/checkAuth";
import { useState } from "react";
import AddProcessType from "./addType";

export const GET_PROCESS_TYPES: TypedDocumentNode<ProcessTypeArrayType> = gql`
  ${PROCESS_TYPE_CORE_FIELDS}
  query GET_PROCESS_TYPES {
    processType {
      ...ProcessTypeCoreFields
    }
  }
`;

export default function ProcessTypes() {
  const { data, error, loading } = useQuery(GET_PROCESS_TYPES);
  if (error) {
    console.log(error);
    addAlert({ message: "could not load types", type: "failure" });
  }

  const [adding, setAdding] = useState(false);

  const toggleAdding = () => {
    setAdding(!adding);
  };

  const editAuth = checkAuth("setting_process_type_edit");
  const viewAuth = checkAuth("setting_process_type_access");

  return (
    <div className="mb-1 flex-grow  mx-auto">
      <div className="flex flex-row justify-between items-center">
        <h1>Process Types</h1>
        {editAuth && (
          <div onClick={toggleAdding} className="w-6 cursor-pointer">
            {adding ? (
              <MinusCircleIcon className="text-red-500" />
            ) : (
              <PlusCircleIcon className="text-grass hover:animate-pulse" />
            )}
          </div>
        )}
      </div>
      {loading && !data && (
        <div className="flex flex-row justify-center mt-4 mb-6">
          <Spinner color="purple" size="xl" />
        </div>
      )}
      <section className="my-4 space-y-2">
        {adding && <AddProcessType toggleAdding={toggleAdding} />}
        {!viewAuth && "no access"}
        {viewAuth &&
          data?.processType?.map(pt => <ProcessType key={pt.id} pt={pt} />)}
      </section>
    </div>
  );
}
