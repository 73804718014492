import { useNavigate } from "react-router-dom";
import { ProcessHistory, ProcessType } from "../types";
import { fullNumber } from "../../../../utils/fullNumber";
import dayjs from "dayjs";
import { Badge, ListGroup } from "flowbite-react";
import getContrast from "../../../../utils/getContrast";
import {
  BanknotesIcon,
  BuildingStorefrontIcon,
  CakeIcon,
  CurrencyDollarIcon,
  MapIcon,
  TagIcon,
} from "@heroicons/react/24/solid";
import { GET_PRODUCTS } from "../../settings/products/products";
import { useMutation, useQuery } from "@apollo/client";
import { currencyFormat } from "../../../../utils/numberMethods";
import { GET_TAGS } from "../../settings/process/tags/tags";
import { GET_SOURCES } from "../../settings/process/sources/sources";
import { UPDATE_PROCESS_STATUS } from "../processBoard/processBoard";
import { GET_PROCESS_STATUSES } from "../../settings/process/processStatuses/processStatuses";
import { useOnClickOutside } from "usehooks-ts";
import { useRef, useState } from "react";
import { useAuthStore } from "../../../../store/authStore";
import checkAuth from "../../../../utils/checkAuth";

interface props {
  process: ProcessType;
}

export default function ListItem({ process }: props) {
  const { user } = useAuthStore();

  const {
    id,
    processStatus,
    name,
    processType,
    year,
    number,
    salesRepUser,
    address,
    products,
    value,
    from,
    due,
    created_at,
    tags,
    sources,
    paymentStatus,
  } = process;

  const teamProcess = user?.team?.processTypes || [];

  const canEdit = checkAuth([
    "process_edit_all",
    {
      permission: "process_edit_team",
      checkGroup: teamProcess,
      conditionGroup: [processType.id],
    },
    {
      permission: "process_edit_self",
      checkGroup: [user?.id || 0],
      conditionGroup: [salesRepUser?.id || -1],
    },
  ]);

  const { data: data_products } = useQuery(GET_PRODUCTS);
  const productList = data_products?.products;

  const { data: data_tags } = useQuery(GET_TAGS);
  const tagList = data_tags?.tags;

  const { data: data_sources } = useQuery(GET_SOURCES);
  const sourceList = data_sources?.sources;

  const { data: data_statuses } = useQuery(GET_PROCESS_STATUSES);
  const statusList = data_statuses?.processStatus;
  const preferredStatuses = statusList?.filter(stat =>
    process.processType.statuses?.includes(stat.id)
  );
  const otherStatuses = statusList?.filter(
    stat => !preferredStatuses?.find(pStat => pStat.id == stat.id)
  );

  const [more, setMore] = useState(false);
  const toggleMore = () => {
    setMore(!more);
  };

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    canEdit && setEditing(!editing);
  };

  const [update_process_status] = useMutation(UPDATE_PROCESS_STATUS);

  const changeStatus = (status: number) => {
    const histories: ProcessHistory[] = process.histories
      ? [...process.histories]
      : [];
    const newStatus = statusList?.find(p => p.id == status);

    const history: ProcessHistory = {
      by: user?.id || -1,
      date: new Date().toISOString(),
      status: process?.processStatus.id || -1,
      message: `status updated from ${process.processStatus.name} to ${newStatus?.name}`,
      type: "status",
    };

    histories.push(history);

    update_process_status({
      variables: { id, status, histories },
      optimisticResponse: {
        update_process_by_pk: {
          __typename: "process",
          ...process,
          processStatus:
            statusList?.find(sl => sl.id == status) || processStatus,
        },
      },
    });
    setEditing(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setEditing(false);
  });

  const navigate = useNavigate();
  const goToDetail = () => {
    navigate("/process/detail/" + process.id);
  };
  const fullNum = fullNumber(processType.prefix, year, number, salesRepUser);

  const isPastDue = processStatus.dueMatters && dayjs(due).isBefore(new Date());

  return (
    <div
      ref={ref}
      className="border-[1px] shadow-sm bg-white dark:bg-gray-800 dark:border-gray-700 rounded-md flex flex-row gap-2 items-center justify-between"
    >
      <div className="flex flex-row flex-[1] items-center gap-2 p-3">
        {/* Name, Number */}
        <div className="flex flex-col flex-[1] gap-1">
          <div
            onClick={goToDetail}
            className="flex flex-col gap-1 cursor-pointer group hover:text-grass"
          >
            <div>{fullNum}</div>
            <div className="flex flex-row">
              <h3 className="truncate flex-1 w-0 group-hover:text-grass">
                {name}
              </h3>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            {/* value */}
            <div className="flex flex-row gap-1 items-center">
              <CurrencyDollarIcon className="w-4" />
              {currencyFormat(value || 0).replace("$", "")}
            </div>
            {/* address */}
            <div className="hidden @sm:flex supports-[not(container-type:inline-size)]:sm:flex flex-row gap-1 items-center">
              <MapIcon className="w-4" />
              <div className="max-w-[330px] truncate">{address}</div>
            </div>
            {/* from */}
            <div className="hidden @md:flex supports-[not(container-type:inline-size)]:md:flex flex-row gap-1 items-center">
              <CakeIcon className="w-4" />
              <div>{dayjs(from || created_at).format("DD/MM/YYYY")}</div>
            </div>
            {/* payment */}
            {paymentStatus && (
              <div className="hidden @lg:flex supports-[not(container-type:inline-size)]:lg:flex flex-row gap-2 items-center flex-wrap">
                <BanknotesIcon className="w-4" />
                <Badge
                  style={{
                    color: paymentStatus
                      ? getContrast(paymentStatus?.color || "")
                      : "unset",
                    backgroundColor: paymentStatus?.color || "unset",
                  }}
                  // className="w-min"
                >
                  {paymentStatus?.name || "Nill"}
                </Badge>
              </div>
            )}
            {/* products */}
            {products && (
              <div className="hidden @md:flex supports-[not(container-type:inline-size)]:md:flex flex-row gap-2 items-center flex-wrap">
                {products?.map((p, i) => {
                  const product = productList?.find(
                    pro => pro.id == p.productId
                  );
                  return (
                    <div
                      key={`${p.productId}${i}`}
                      className="flex flex-row items-center gap-2"
                    >
                      {product?.image && (
                        <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                          <img src={product?.image} className="w-3" />
                        </div>
                      )}
                      {p.qty}
                    </div>
                  );
                })}
              </div>
            )}
            {/* tags */}
            {tags && tags.length > 0 && (
              <div className="hidden @lg:flex supports-[not(container-type:inline-size)]:lg:flex flex-row gap-2 items-center flex-wrap">
                <TagIcon className="w-4" />
                {tags?.map(t => {
                  const tag = tagList?.find(tl => tl.id == t);
                  return (
                    <Badge key={t} color="purple">
                      #{tag?.name}
                    </Badge>
                  );
                })}
              </div>
            )}

            {/* sources */}
            {sources && sources.length > 0 && (
              <div className="hidden @xl:flex supports-[not(container-type:inline-size)]:xl:flex flex-row gap-2 items-center flex-wrap">
                <BuildingStorefrontIcon className="w-4" />
                {sources?.map(s => {
                  const source = sourceList?.find(sl => sl.id == s);
                  return (
                    <Badge key={s} color="purple">
                      {source?.name}
                    </Badge>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-2 p-2">
        {/* Status */}
        <div className="relative">
          <div onClick={toggleEditing} className="cursor-pointer">
            <Badge
              style={{
                backgroundColor: processStatus.color,
                color: getContrast(processStatus.color),
              }}
              size="md"
              className="pointer-events-none"
            >
              {processStatus.name}
            </Badge>
          </div>
          {editing && (
            <ListGroup className="absolute top-[100%] mt-2 z-10 right-0">
              {preferredStatuses?.map(stat => (
                <ListGroup.Item
                  key={stat.id}
                  onClick={() => {
                    if (stat.id == processStatus.id) {
                      return;
                    }
                    changeStatus(stat.id);
                  }}
                >
                  <Badge
                    size="sm"
                    className="w-full pointer-events-none truncate"
                    style={{
                      backgroundColor: stat.color,
                      color: getContrast(stat.color),
                      opacity: stat.id == processStatus.id ? 0.5 : 1,
                    }}
                  >
                    {stat.name}
                  </Badge>
                </ListGroup.Item>
              ))}
              <ListGroup.Item onClick={toggleMore}>
                <div className="inline-flex items-center justify-center w-full">
                  <hr className="w-full my-3 bg-gray-200 border-[1px] dark:bg-gray-900" />
                  <span className="absolute px-3 font-medium  -translate-x-1/2 left-1/2 bg-white dark:bg-gray-700 rounded-md">
                    more
                  </span>
                </div>
              </ListGroup.Item>
              {more &&
                otherStatuses?.map(stat => (
                  <ListGroup.Item
                    key={stat.id}
                    onClick={() => {
                      if (stat.id == processStatus.id) {
                        return;
                      }
                      changeStatus(stat.id);
                    }}
                  >
                    <Badge
                      size="sm"
                      className="w-full pointer-events-none truncate"
                      style={{
                        backgroundColor: stat.color,
                        color: getContrast(stat.color),
                        opacity: stat.id == processStatus.id ? 0.5 : 1,
                      }}
                    >
                      {stat.name}
                    </Badge>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          )}
        </div>
        {/* Due */}
        <div
          className={`flex flex-col items-center justify-center p-2 ${
            isPastDue && "text-red-500"
          }`}
        >
          <div>{due ? dayjs(due).format("DD") : "No"}</div>
          <div>{due ? dayjs(due).format("MMM") : "Due"}</div>
        </div>
      </div>
    </div>
  );
}
