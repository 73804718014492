import { TypedDocumentNode, gql } from "@apollo/client";
import { USER_CORE_FIELDS } from "./fragments";
import { UserArrayType } from "./types";

export const GET_USERS: TypedDocumentNode<UserArrayType> = gql`
  ${USER_CORE_FIELDS}
  query GET_USERS {
    users {
      ...UserCoreFields
    }
  }
`;

export const GET_ACTIVE_USERS: TypedDocumentNode<UserArrayType> = gql`
  ${USER_CORE_FIELDS}
  query GET_ACTIVE_USERS {
    users(where: { active: { _eq: true } }) {
      ...UserCoreFields
    }
  }
`;

export const GET_USERS_CONDITIONAL: TypedDocumentNode<UserArrayType> = gql`
  ${USER_CORE_FIELDS}
  query GET_USERS_CONDITIONAL($where: users_bool_exp) {
    users(where: $where) {
      ...UserCoreFields
    }
  }
`;
