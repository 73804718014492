import { addAlert } from "../store/alertStore";
import { Coord } from "./geocode";

export default function getLocation() {
  return new Promise<Coord | undefined>(resolve => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(permissionStatus => {
          if (permissionStatus.state === "denied") {
            addAlert({
              message: "please enable location service",
              type: "warning",
            });

            resolve(undefined);
          } else {
            navigator.geolocation.getCurrentPosition(position => {
              if (position.coords?.latitude && position.coords?.longitude) {
                resolve({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                });
              } else {
                resolve(undefined);
              }
            });
          }
        })
        .catch(err => {
          console.log(err);
          resolve(undefined);
        });
    } else {
      resolve(undefined);
    }
  });
}
