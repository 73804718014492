import { useMutation, useQuery, gql } from "@apollo/client";
import {
  Button,
  Spinner,
  TextInput,
  Label,
  Select,
  ToggleSwitch,
} from "flowbite-react";
import { teamSchema } from "./types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TEAM_CORE_FIELDS } from "./fragments";
import { GET_TEAM_TYPE } from "./teamType";
import { addAlert } from "../../../../store/alertStore";

export const INSERT_TEAM = gql`
  ${TEAM_CORE_FIELDS}
  mutation INSERT_TEAM($name: String!, $inHouse: Boolean!, $teamType: Int!) {
    insert_teams_one(
      object: { name: $name, inHouse: $inHouse, teamType: $teamType }
    ) {
      ...TeamCoreFields
    }
  }
`;

export default function AddTeam({
  toggleAdding,
}: {
  toggleAdding: () => void;
}) {
  const [insert_team, { loading }] = useMutation(INSERT_TEAM);

  const {
    data,
    loading: team_type_loading,
    error: team_type_error,
  } = useQuery(GET_TEAM_TYPE);

  if (team_type_error) {
    console.log(team_type_error);
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      name: "",
      teamType: "0",
      inHouse: false,
    },
  });

  const onSubmit = handleSubmit(async data => {
    insert_team({
      variables: {
        ...data,
        name: data.name.trim(),
        teamType: Number(data.teamType),
      },
      update(cache, { data: { insert_teams_one: newTeam } }) {
        cache.modify({
          fields: {
            teams(existingTeams = []) {
              const newTeamRef = cache.writeFragment({
                data: newTeam,
                fragment: TEAM_CORE_FIELDS,
              });
              return [...existingTeams, newTeamRef];
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          message: "New Team successfully added",
          type: "success",
        });
        toggleAdding();
      },
      onError(error) {
        console.log(error);

        addAlert({
          message: "something went wrong cannot add team",
          type: "failure",
        });
      },
    });
  });

  const cancel = () => {
    reset();
    toggleAdding();
  };

  const inHouse = watch("inHouse");

  const toggleInhouse = () => {
    setValue("inHouse", !inHouse);
  };

  return (
    <section className="rounded-md p-3 dark:bg-gray-700 bg-gray-100">
      <form onSubmit={onSubmit}>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="teamName" value="Team Name" />
          </div>
          <TextInput
            type="text"
            placeholder="change team Name"
            color={errors.name?.message ? "failure" : undefined}
            helperText={errors.name?.message || ""}
            {...register("name")}
          />
        </div>
        <div id="select" className="mt-2">
          <div className="mb-2 block">
            <Label htmlFor="countries" value="Select Team Type" />
          </div>
          <Select
            required={true}
            {...register("teamType")}
            color={errors.teamType?.message ? "failure" : undefined}
            helperText={errors.teamType?.message || ""}
          >
            <option value={0}>
              {team_type_loading ? "...loading" : "select from the list"}
            </option>
            {data?.teamType?.map(teamType => (
              <option key={teamType.id} value={teamType.id}>
                {teamType.name}
              </option>
            ))}
          </Select>
        </div>
        <div id="select" className="mt-4">
          <div className="mb-2 block">
            <ToggleSwitch
              //@ts-expect-error
              color="purple"
              label="In House"
              checked={inHouse}
              onChange={toggleInhouse}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end gap-2 mt-4">
          <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
            Cancel
          </Button>
          <Button
            gradientDuoTone="purpleToBlue"
            outline
            size="sm"
            type="submit"
          >
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Save
          </Button>
        </div>
      </form>
    </section>
  );
}
