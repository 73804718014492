import { ADD_MATERIALS, GET_MATERIALS } from "../../../materials/gqls";
import { useMutation, useQuery } from "@apollo/client";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import {
  Button,
  Checkbox,
  Label,
  Select,
  Table,
  TextInput,
} from "flowbite-react";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { GET_PRODUCTS } from "../../products/products";
import { zodResolver } from "@hookform/resolvers/zod";
import { newMaterialSchema } from "../../../materials/types";
import { MATERIALS_CORE_FIELDS } from "../../../materials/fragments";
import { sort } from "fast-sort";
import { GET_PRODUCT_SET_CONDITIONAL } from "../../../workorder/gqls";
import Material from "./material";
import OptionCondition from "../optionCondition";
import Params from "./params";
import Prices from "./prices";

export default function Materials() {
  const [selectedProduct, setSelectedProduct] = useState(3);
  const { data: data_mats, loading } = useQuery(GET_MATERIALS);
  const [filter, setFilter] = useState("");
  const materials = data_mats
    ? data_mats.materials.filter(
        mat =>
          mat.product.id == selectedProduct &&
          (filter.trim() == "" ||
            `${mat.brand} ${mat.name} ${mat.product.name} ${mat.color} ${mat.optionCode}`
              .toLowerCase()
              .includes(filter.trim().toLocaleLowerCase()))
      )
    : [];

  const { data: data_products } = useQuery(GET_PRODUCTS);

  const [insert, { loading: inserting }] = useMutation(ADD_MATERIALS);

  const defaultValues = {
    productId: null as null | number,
    name: "",
    code: null,
    brand: "",
    widthDeduction: null,
    heightDeduction: null,
    image: null,
    color: "",
    size: null,
    optionCode: "",
    params: [],
    prices: [],
    pending: false,
    selectable: true,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(newMaterialSchema),
  });

  const { register, setValue, handleSubmit, reset, control } = methods;

  const productId: number | null = useWatch({
    name: "productId",
    control,
  });

  const { data: productSet_data } = useQuery(GET_PRODUCT_SET_CONDITIONAL, {
    variables: {
      where: {
        typeId: { _eq: productId },
      },
    },
  });

  const productSetOptions =
    productSet_data?.productSets &&
    productSet_data?.productSets[0]?.productSetOptions;

  const optionCodes = productSetOptions
    ?.filter(option => option.optionCode && option.source == "inventory")
    .map(option => option.optionCode)
    .reduce<string[]>((prev, cur) => {
      if (!cur || prev.includes(cur)) {
        return prev;
      }
      return prev.concat(cur);
    }, []);

  const productSetID =
    productSet_data?.productSets && productSet_data?.productSets[0]?.id;

  const onSubmit = handleSubmit(async data => {
    insert({
      variables: {
        object: {
          ...data,
          productId: data.productId == 0 ? null : data.productId,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        reset();
        setAdding(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            materials(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_materials_one,
                fragment: MATERIALS_CORE_FIELDS,
                fragmentName: "MaterialsCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const cancel = () => {
    reset();
    setAdding(!adding);
  };

  const [page, setPage] = useState(1);
  const pageLength = 50;
  const totalPages = Math.ceil(materials.length / pageLength);

  const movePage = (direction: 1 | -1) => {
    const newPage = page + direction;
    if (newPage < 1 || newPage > totalPages) {
      return;
    }
    setPage(newPage);
  };

  const sortedMaterials = materials
    ? sort(materials).by([
        { desc: d => d.pending },
        { asc: d => d.product.name },
        { asc: d => d.optionCode },
        { asc: d => d.brand },
        { asc: d => d.name },
        { asc: d => d.color },
      ])
    : [];

  const paginatedMaterials = sortedMaterials.slice(
    (page - 1) * pageLength,
    page * pageLength
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-center gap-2">
        <Select
          value={selectedProduct}
          onChange={e => {
            setSelectedProduct(Number(e.target.value));
            setValue("productId", Number(e.target.value));
          }}
          sizing="sm"
        >
          {data_products?.products.map(p => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </Select>
        <div className="flex flex-row justify-between items-center gap-2">
          <TextInput
            onChange={e => setFilter(e.target.value)}
            value={filter}
            placeholder="search"
            type="search"
            name="searchMaterial"
            autoComplete="off"
            icon={MagnifyingGlassIcon}
            sizing="sm"
          />
          {!adding && (
            <Button
              gradientDuoTone="purpleToBlue"
              outline
              size="xs"
              onClick={toggleAdding}
            >
              new
            </Button>
          )}
        </div>
      </div>
      {adding && (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className="flex flex-col mb-2 gap-2">
            <div className="flex flex-row flex-wrap gap-2 items-center">
              <Select {...register("productId")} sizing="sm">
                <option value={""}>Select product</option>
                <option value={""}>None</option>
                {data_products?.products.map(p => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </Select>
              <TextInput
                {...register("brand")}
                sizing="sm"
                placeholder="Brand"
                className="flex-1 min-w-[100px]"
              />
              <TextInput
                {...register("name")}
                sizing="sm"
                placeholder="Name"
                className="flex-1 min-w-[100px]"
              />
              <TextInput
                {...register("code")}
                sizing="sm"
                placeholder="Item Code"
                className="flex-1 min-w-[100px]"
              />
              <TextInput
                {...register("color")}
                sizing="sm"
                placeholder="color"
                className="flex-1 min-w-[100px]"
              />
              <TextInput
                {...register("size")}
                sizing="sm"
                placeholder="size"
                className="flex-1 min-w-[100px]"
                addon="Size"
              />
              <Select
                {...register("optionCode")}
                sizing="sm"
                addon="OC"
                placeholder="option code"
                className="w-48"
              >
                <option value="">None</option>
                {optionCodes?.map(optionCode => (
                  <option key={optionCode}>{optionCode}</option>
                ))}
              </Select>
              <TextInput
                {...register("widthDeduction")}
                sizing="sm"
                type="number"
                addon="W"
                placeholder="Width"
                className="flex-1 min-w-[100px]"
              />
              <TextInput
                {...register("heightDeduction")}
                sizing="sm"
                type="number"
                addon="H"
                placeholder="Height"
                className="flex-1 min-w-[100px]"
              />
              <Prices />
            </div>
            <hr />
            <div className="flex flex-row justify-end flex-wrap gap-2 items-center">
              <Label value="Registration Pending" />
              <Checkbox
                className="checked:bg-plum dark:checked:bg-plum"
                {...register("pending")}
              />
              <Label value="Selectable" />
              <Checkbox
                className="checked:bg-plum dark:checked:bg-plum"
                {...register("selectable")}
              />
            </div>
            {productSetID && (
              <>
                <h4 className="font-semibold dark:text-white">
                  Option Conditions
                </h4>
                <OptionCondition productSetID={productSetID} />
                <hr />
                <h4 className="font-semibold dark:text-white">Params</h4>
                <Params />
                <hr />
              </>
            )}

            <div className="flex flex-row justify-end items-center gap-2">
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                outline
                type="submit"
              >
                Add
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      <Table striped className="select-text">
        <Table.Head>
          <Table.HeadCell className="px-1">id</Table.HeadCell>
          <Table.HeadCell className="px-1">product</Table.HeadCell>
          <Table.HeadCell className="px-1">brand</Table.HeadCell>
          <Table.HeadCell className="px-1">name</Table.HeadCell>
          <Table.HeadCell className="px-1">colour</Table.HeadCell>
          <Table.HeadCell className="px-1">size</Table.HeadCell>
          <Table.HeadCell className="px-1">W</Table.HeadCell>
          <Table.HeadCell className="px-1">H</Table.HeadCell>
          <Table.HeadCell className="px-1">code</Table.HeadCell>
          <Table.HeadCell className="px-1">prices</Table.HeadCell>
          <Table.HeadCell className="px-1">optionCode</Table.HeadCell>
          <Table.HeadCell className="px-1">conditions / params</Table.HeadCell>
          <Table.HeadCell className="p-0" />
        </Table.Head>
        <Table.Body className="text-black dark:text-white">
          {paginatedMaterials.map(item => (
            <Material key={item.id} item={item} />
          ))}
        </Table.Body>
      </Table>
      <div className="flex flex-row items-center justify-end gap-2">
        <ChevronDoubleLeftIcon
          className="w-5 cursor-pointer"
          onClick={() => {
            movePage(-1);
          }}
        />
        <div className="leading-[0]">
          <span className="text-grass">{page}</span> of {totalPages}
        </div>
        <ChevronDoubleRightIcon
          className="w-5 cursor-pointer"
          onClick={() => {
            movePage(1);
          }}
        />
      </div>
    </div>
  );
}
