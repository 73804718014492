import { Badge, Button, ListGroup, Spinner } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { TaskType } from "./types";
import getContrast from "../../../../utils/getContrast";
import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  ClockIcon,
  TrashIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { useOnClickOutside } from "usehooks-ts";
import { useForm } from "react-hook-form";
import { TypedDocumentNode, useQuery } from "@apollo/client";
import { GET_TASK_STATUSES } from "../../settings/tasks/taskStatuses/taskStatuses";
import { sort } from "fast-sort";
import { gql, useMutation } from "@apollo/client";
import { TASK_CORE_FIELDS } from "./fragments";
import { addAlert } from "../../../../store/alertStore";
import { useAuthStore } from "../../../../store/authStore";
import { fullNumber } from "../../../../utils/fullNumber";
import { useNavigate } from "react-router-dom";
import checkAuth from "../../../../utils/checkAuth";

interface update_tasks_by_pk_type {
  update_tasks_by_pk: TaskType;
}

export const UPDATE_TASK_BY_PK: TypedDocumentNode<update_tasks_by_pk_type> = gql`
  ${TASK_CORE_FIELDS}
  mutation UPDATE_TASK_BY_PK($id: Int!, $set: tasks_set_input!) {
    update_tasks_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...TaskCoreFields
    }
  }
`;

export const REMOVE_TASK = gql`
  mutation REMOVE_TASK($id: Int!) {
    delete_tasks_by_pk(id: $id) {
      id
    }
  }
`;

interface props {
  task: TaskType;
  showJobName?: boolean;
}
export default function Task({ task, showJobName }: props) {
  const ref = useRef<HTMLFormElement>(null);

  const [update_task, { loading: updating }] = useMutation(UPDATE_TASK_BY_PK);
  const [remove_task] = useMutation(REMOVE_TASK);

  const { user } = useAuthStore();

  useOnClickOutside(ref, () => {
    cancel();
  });

  const defaultValues = {
    description: task.description,
    due: task.due,
    statusID: task.taskStatus.id,
    deleted: task.deleted,
  };

  const { register, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset({
      description: task.description,
      due: task.due,
      statusID: task.taskStatus.id,
      deleted: task.deleted,
    });
  }, [task]);

  const { data, loading: loading_status } = useQuery(GET_TASK_STATUSES);
  const statuses = data?.taskStatus
    ? sort(data?.taskStatus).asc(s => s.priority)
    : [];

  const status = statuses.find(s => s.id == watch("statusID"));
  const description = watch("description");
  const due = watch("due");

  const [editing, setEditing] = useState<null | string>(null);
  const toggleEditing = (str: string) => {
    if (editing == str) {
      setEditing(null);
    } else {
      setEditing(str);
    }
  };

  const onSubmit = handleSubmit(async data => {
    update_task({
      variables: {
        id: task.id,
        set: {
          statusID: data.statusID,
          due: data.due || null,
          description,
          deleted: data.deleted,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update task",
          type: "failure",
        });
      },
      onCompleted() {
        reset({
          statusID: data.statusID,
          due,
          description,
          deleted: data.deleted,
        });
        setEditing(null);
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: task.id,
          __typename: "tasks",
        });
        if (data.deleted) {
          cache.evict({ id: normalizedId });
          cache.gc();
          remove_task({
            variables: { id: task.id },
          });
        }
      },
    });
  });

  const changeStatus = (id: number) => {
    setValue("statusID", id, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
    onSubmit();
  };

  const deleteTask = () => {
    setValue("deleted", true, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
    onSubmit();
    setEditing(null);
  };

  const cancel = () => {
    setEditing(null);
    reset();
  };

  const canEdit =
    user &&
    (user.id == task.assignedByUser.id || user.id == task.assignedToUser.id);

  const canViewJob =
    editing !== "desc" &&
    showJobName &&
    checkAuth(["process_access_self", "process_access_all"]);

  const pastDue =
    status?.name !== "completed" &&
    task.due &&
    dayjs(task.due).isBefore(new Date());

  const navigate = useNavigate();
  const goToJob = () => {
    canViewJob && navigate("/process/detail/" + task.process?.id + "/prog");
  };

  return (
    <form
      ref={ref}
      className={`relative flex flex-col border-[1px] shadow-sm items-start bg-white dark:bg-gray-800 dark:border-gray-700 py-2 px-3 rounded-md gap-2 ${
        updating && "opacity-50"
      }`}
    >
      <div className="w-full flex flex-row gap-2 items-center justify-between">
        {editing !== "desc" && (
          <h3
            onClick={() => {
              canEdit && toggleEditing("desc");
            }}
            className={`${canEdit && "cursor-pointer hover:text-plum"}`}
          >
            {description}
          </h3>
        )}
        {editing == "desc" && (
          <div className="flex-1 justify-between flex flex-row items-center gap-2">
            <input
              autoFocus
              {...register("description")}
              className="bg-transparent dark:text-white text-lg font-semibold flex-1 w-0"
            />
            {updating && <Spinner size="sm" color="purple" />}
            {!updating && (
              <div className="flex flex-row items-center gap-1">
                <XCircleIcon
                  className="cursor-pointer w-6 text-red-500"
                  onClick={cancel}
                />
                <CheckCircleIcon
                  className="cursor-pointer w-6 text-grass"
                  onClick={onSubmit}
                />
              </div>
            )}
          </div>
        )}
        {task.process && (
          <div
            onClick={goToJob}
            className="flex flex-row items-center gap-1 hover:text-plum cursor-pointer"
          >
            <span className="mt-[2px]">
              {fullNumber(
                task.process.processType.prefix,
                task.process.year,
                task.process.number
              )}
            </span>
            <ArrowTopRightOnSquareIcon className="w-4" />
          </div>
        )}
      </div>
      <div className="w-full flex flex-row gap-2 items-center justify-between relative">
        <Badge
          style={{
            backgroundColor: status?.color,
            color: getContrast(status?.color || ""),
          }}
          onClick={() => {
            canEdit && toggleEditing("status");
          }}
          className={`${canEdit && "cursor-pointer"}`}
        >
          {editing == "status" && updating ? (
            <Spinner size="sm" color="purple" />
          ) : (
            status?.name
          )}
        </Badge>

        {editing == "status" && (
          <ListGroup className="absolute top-[100%] mt-2 left-0 z-10 shadow-md">
            {statuses?.map(status => (
              <ListGroup.Item
                onClick={() => {
                  changeStatus(status.id);
                }}
                key={status.id}
              >
                <Badge
                  style={{
                    backgroundColor: status.color,
                    color: getContrast(status.color),
                  }}
                >
                  {status.name}
                </Badge>
              </ListGroup.Item>
            ))}
            <hr className="dark:border-gray-500 border-gray-300" />
            <ListGroup.Item onClick={deleteTask}>
              <Badge color="failure">
                <div className="flex flex-row items-center gap-2 text-xs">
                  <TrashIcon className="w-4 text-red-500" />
                  delete
                </div>
              </Badge>
            </ListGroup.Item>
          </ListGroup>
        )}
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row items-center gap-1">
            <ClockIcon
              onClick={() => {
                canEdit && toggleEditing("due");
              }}
              className={`w-5 ${canEdit && "cursor-pointer hover:text-plum"}`}
            />
            <span className={`${pastDue && "text-red-500"}`}>
              {(task.due && dayjs(task.due).format("DD/MM/YY")) || "no due"}
            </span>

            {editing == "due" && (
              <div className="absolute right-0 top-[100%] mt-2 items-center flex flex-row gap-2 bg-white dark:bg-gray-800 p-2 rounded-md border-[1px] dark:border-gray-700 z-10">
                <input
                  className="dark:bg-gray-500 rounded-md outline-none p-[5px] dark:border-none font-normal text-sm"
                  type="date"
                  {...register("due")}
                  autoFocus
                />

                <Button
                  onClick={onSubmit}
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                  outline
                >
                  {updating && <Spinner size="sm" color="purple" />}
                  {!updating && "update"}
                </Button>
              </div>
            )}
          </div>

          <div
            onClick={() => {
              toggleEditing("user");
            }}
            className="flex flex-row items-center gap-1 relative cursor-pointer hover:text-plum"
          >
            <UserCircleIcon className="w-5" />
            <span className="font-semibold capitalize">
              {task.assignedToUser.firstName}
            </span>
          </div>
          {editing == "user" && (
            <div className="absolute right-0 top-[100%] mt-2 items-center flex flex-row gap-2 bg-white dark:bg-gray-800 p-2 rounded-md border-[1px] dark:border-gray-700 z-10">
              Assinged by {task.assignedByUser.firstName}{" "}
              {task.assignedByUser.sirName}
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
