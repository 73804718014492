import { z } from "zod";
import { coerceDate, coerceNumber } from "../../process/types";
import { PaymentStatusType } from "../../settings/payment/paymentStatuses/types";

export const paymentSchema = z.object({
  processID: coerceNumber,
  amount: coerceNumber,
  comment: z.string().optional(),
  methodID: coerceNumber,
  received_at: coerceDate,
  received_by: coerceNumber,
});

export interface NewPaymentType {
  processID: number;
  amount: number;
  comment: string;
  methodID: number;
  received_at: string;
  received_by?: number;
}

export interface PaymentType
  extends Omit<NewPaymentType, "received_by" | "methodID"> {
  id: number;
  user: {
    id: number;
    firstName: string;
    sirName: string;
    email: string;
  };
  created_at: string;
  updated_at: string;
  paymentMethod: {
    id: number;
    name: string;
    iconName: string;
  };
}

export interface PaymentArrayType {
  paymentLogs: PaymentType[];
}

export interface PaymentStreamType {
  paymentLogs_stream: PaymentType[];
}
