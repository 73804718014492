import { gql } from "@apollo/client";
import { PAYMENT_STATUS_CORE_FIELDS } from "../settings/payment/paymentStatuses/fragments";
import { PROCESS_STATUS_CORE_FIELDS } from "../settings/process/processStatuses/fragments";
import { PROCESS_TYPE_CORE_FIELDS } from "../settings/process/processTypes/fragments";

export const PROCESS_CORE_FIELDS = gql`
  ${PAYMENT_STATUS_CORE_FIELDS}
  ${PROCESS_STATUS_CORE_FIELDS}
  ${PROCESS_TYPE_CORE_FIELDS}
  fragment ProcessCoreFields on process {
    id
    name
    year
    number
    address
    postCode
    description
    returning
    created_at
    updated_at
    deleted
    from
    due
    histories
    products
    value
    tags
    sources
    primaryContact
    contacts
    schedules
    paymentStatus {
      ...PaymentStatusCoreFields
    }
    salesRepUser {
      id
      sirName
      firstName
      email
    }
    processType {
      ...ProcessTypeCoreFields
    }
    processStatus {
      ...ProcessStatusCoreFields
    }
    workorders {
      id
      orderRef
      createdBy
      type {
        name
      }
    }
    createdUser {
      id
      sirName
      firstName
      email
      team {
        id
        name
      }
    }
  }
`;
