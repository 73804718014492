import dayjs from "dayjs";

interface props {
  startTime: number | string | Date;
  endTime?: number | string | Date;
  date?: number | string | Date;
  brk?: number;
}
export default function totalH({ startTime, endTime, date, brk }: props) {
  const diff = dayjs(endTime || date).diff(dayjs(startTime));
  const mins = Math.round(diff / 60000 - (brk || 0));
  let hour = Math.floor(mins / 60);
  const min = mins % 60;

  if (min < 25) {
    hour = hour;
  } else if (min < 50) {
    hour = hour + 0.5;
  } else {
    hour++;
  }

  if (hour < 0) {
    hour = 0;
  }

  return hour;
}
