import { useEffect, useState } from "react";
import { useOrderStore } from "../../../store/orderStore";

interface props {
  source?: string;
  value?: number | string;
  showDesc?: boolean;
}

const ValuePopulate = ({ source, value, showDesc }: props) => {
  const { materials, deductions, presets } = useOrderStore();

  const [dpValue, setDPValue] = useState(value);

  const populate = () => {
    if (!source || !value) {
      return;
    }
    if (source == "deductionPreset") {
      const preset = presets.find(p => p.id == Number(value));
      if (preset) {
        setDPValue(`${showDesc ? `${preset.desc} ` : ""}${preset.name}`);
      }
    }
    if (source == "deductions") {
      const deduction = deductions.find(d => d.id == Number(value));
      if (deduction) {
        setDPValue(deduction.name);
      }
    }
    if (source == "inventory") {
      const material = materials.find(m => m.id == Number(value));
      if (material) {
        setDPValue(
          `${material.name} ${material.color || ""}${
            material.size ? ` ${material.size}` : ""
          }`
        );
      }
    }
  };

  useEffect(() => {
    populate();
  }, [source, value, materials, deductions, presets]);

  return <>{dpValue}</>;
};

export default ValuePopulate;
