import dayjs from "dayjs";
import {
  DateLocalizer,
  Navigate,
  NavigateAction,
  ViewProps,
} from "react-big-calendar";
import { useNavStore } from "../../../../store/navStore";
import { dateGroup } from "./types";
import DateGroup from "./dateGroup";
import { sort } from "fast-sort";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToAnchor from "../../../../utils/scrollToAnchor";
import { useScheduleStore } from "../../../../store/scheduleStore";
import { SparklesIcon } from "@heroicons/react/24/solid";

export default function MyAgenda({ ...props }: ViewProps) {
  const { events, onSelectEvent, onDrillDown, views } = props;

  // @ts-expect-error
  const today = views?.month == false ? new Date() : false;

  const { date, setDate } = useScheduleStore();

  // const midNight = new Date (new Date().setHours(0,0,0,0))
  const { containerRef } = useNavStore();

  const eventList =
    events?.filter(e =>
      e.start && today
        ? dayjs(e.start).isAfter(today) &&
          Math.abs(dayjs(e.start).diff(dayjs(today), "days")) < 14
        : Math.abs(dayjs(e.start).diff(dayjs(date), "days")) < 7
    ) || [];

  const dateGroups = sort(
    eventList.reduce<dateGroup[]>((prev, cur) => {
      const curDate = dayjs(cur.start).format("YYYY-MM-DD");
      const check = prev.find(p => p.date == curDate);
      if (check) {
        return prev.map(p => {
          if (p.date == curDate) {
            return {
              ...p,
              events: p.events.concat(cur),
            };
          } else {
            return p;
          }
        });
      } else {
        return prev.concat({
          date: curDate,
          events: [cur],
        });
      }
    }, [])
  ).asc("date");

  const [closestDateId, setClosestDateId] = useState("");

  useEffect(() => {
    const closestDate = sort(dateGroups).asc(e => {
      return Math.abs(new Date(e.date).getTime() - new Date().getTime());
    })[0];

    if (closestDate) {
      setClosestDateId(closestDate.date);
    }
  }, [dateGroups]);

  const [scrolledToCurrent, setScrolledToCurrent] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (today) {
      return;
    }
    if (closestDateId !== "" && !scrolledToCurrent) {
      navigate(`#${closestDateId}`, { replace: true });
      setScrolledToCurrent(true);
    }
  }, [closestDateId]);

  useEffect(() => {
    return () => {
      setScrolledToCurrent(false);
    };
  }, []);

  useEffect(() => {
    const day = new Date(date).getDay();
    if (day !== 0) {
      const firstDateOfWeek = dayjs(date).subtract(day, "days").toDate();
      setDate(firstDateOfWeek);
    }
  }, [date]);

  return (
    <div
      // ref={ref}
      onScroll={() => {
        if (containerRef && !today) {
          containerRef.scrollTo({
            behavior: "smooth",
            top: containerRef.scrollHeight,
          });
        }
      }}
      className="flex flex-col self-stretch border-y-[1px] dark:border-gray-600 overflow-scroll md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700 md:dark:scrollbar-track-gray-800"
    >
      {dateGroups.length == 0 && (
        <div className="flex flex-row justify-center items-center p-10 gap-4 opacity-75">
          <SparklesIcon className="w-6" />
          No Schedules in this range
        </div>
      )}
      {dateGroups.map((group, i) => (
        <DateGroup
          key={group.date}
          group={group}
          onDrillDown={onDrillDown}
          onSelectEvent={onSelectEvent}
          index={i}
        />
      ))}
      <ScrollToAnchor />
    </div>
  );
}

MyAgenda.range = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
  const start = dayjs(date).subtract(7, "days").toDate();
  const end = dayjs(date).add(7, "days").toDate();

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

MyAgenda.navigate = (
  date: Date,
  action: NavigateAction,
  { localizer }: { localizer: DateLocalizer }
) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -7, "day");

    case Navigate.NEXT:
      return localizer.add(date, 7, "day");

    default:
      return date;
  }
};

MyAgenda.title = (date: Date) => {
  return `${dayjs(date).subtract(7, "days").format("D MMM")} - ${dayjs(date)
    .add(7, "days")
    .format("D MMM")}`;
};
