import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Modal,
  Button,
  Label,
  Spinner,
  TextInput,
  FileInput,
} from "flowbite-react";
import { useScheme } from "../../../../store/schemeStore";
import checkAuth from "../../../../utils/checkAuth";
import { useMutation, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../store/alertStore";
import { ProductType, productSchema } from "./types";
import { PRODUCT_CORE_FIELDS } from "./fragments";
import { useState } from "react";
import getBase64 from "../../../../utils/getBase64";

export const UPDATE_PRODUCT = gql`
  ${PRODUCT_CORE_FIELDS}
  mutation UPDATE_PRODUCT(
    $id: Int!
    $description: String!
    $name: String!
    $image: String
  ) {
    update_products_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, image: $image }
    ) {
      ...ProductCoreFields
    }
  }
`;

export const DELETE_PRODUCT = gql`
  ${PRODUCT_CORE_FIELDS}
  mutation DELETE_PRODUCT($id: Int!) {
    delete_products_by_pk(id: $id) {
      id
    }
  }
`;

export default function Product({ product }: { product: ProductType }) {
  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow(!show);
  };

  const { scheme } = useScheme();

  const [update_product, { loading, error }] = useMutation(UPDATE_PRODUCT);
  if (error) {
    console.log(error);
    addAlert({ message: "could not update type", type: "failure" });
  }

  const [delete_product, { error: delete_error, loading: delete_loading }] =
    useMutation(DELETE_PRODUCT);
  if (delete_error) {
    console.log(delete_error);
    addAlert({ message: "could not delete type", type: "failure" });
  }

  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(productSchema),
    defaultValues: {
      name: product.name,
      description: product.description,
      image: product.image,
    },
  });

  const onSubmit = handleSubmit(async data => {
    update_product({
      variables: { id: product.id, ...data },
      onCompleted(data) {
        if (data.update_products_by_pk) {
          const { name, description, image } = data.update_products_by_pk;
          reset({
            name,
            description,
            image,
          });
          toggleModal();
          addAlert({
            message: "product updated successfully",
            type: "success",
          });
        }
      },
    });
  });

  const deleteProduct = () => {
    const proceed = confirm("Delete Product?");
    if (proceed) {
      delete_product({
        variables: { id: product.id },
        update(cache, { data: { delete_products_by_pk: deleted } }) {
          const normalizedId = cache.identify({
            id: deleted.id,
            __typename: "products",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
        onCompleted(data) {
          if (data.delete_products_by_pk) {
            toggleModal();
          }
        },
      });
    }
  };

  const cancel = () => {
    reset();
    toggleModal();
  };

  const imageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors("image");

    const files = event.target.files;

    if (files) {
      const file = files[0];
      if (!file) {
        return;
      }
      const { size } = file;

      if (size / 1000 > 50) {
        setError("image", { type: "custom", message: "file size too big" });
        event.target.value = "";
        return;
      }

      const base64 = (await getBase64(file)) as string;

      setValue("image", base64);
    }
  };

  const image = watch("image");

  return (
    <>
      <div className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          {product.image && (
            <div className="ring-1 ring-dark rounded-full p-1 bg-white">
              <img src={product.image} className="w-5" />
            </div>
          )}

          <h3>{product.name}</h3>
        </div>
        {checkAuth("setting_products_edit") && (
          <PencilIcon
            className="w-4 mt-1 cursor-pointer"
            onClick={toggleModal}
          />
        )}
      </div>
      <Modal show={show} onClose={toggleModal} className={`${scheme}`}>
        <Modal.Header>Edit Process Type</Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} className="space-y-3">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Name" />
              </div>
              <TextInput
                type="text"
                placeholder="type name"
                color={errors.name?.message ? "failure" : undefined}
                helperText={errors.name?.message || ""}
                {...register("name")}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="description" value="Description" />
              </div>
              <TextInput
                type="text"
                placeholder="type description"
                color={errors.description?.message ? "failure" : undefined}
                helperText={errors.description?.message || ""}
                {...register("description")}
              />
            </div>

            <div>
              <Label htmlFor="image" value="Image" />
              {image && <img src={image} className="w-20 my-2" />}
              <FileInput
                id="image"
                accept="image/*"
                color={errors.image?.message ? "failure" : undefined}
                helperText={
                  errors.image?.message || "product image less than 50kb size"
                }
                className="mt-2 text-sm"
                onChange={imageHandler}
              />
            </div>

            {/* Action Buttons */}
            <div className="flex flex-row justify-end gap-2 items-center">
              {delete_loading && <Spinner size="sm" light={true} />}
              <TrashIcon
                className="w-5 text-red-500 cursor-pointer"
                onClick={deleteProduct}
              />
              <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
                Cancel
              </Button>
              <Button
                outline
                gradientDuoTone="purpleToBlue"
                size="sm"
                type="submit"
              >
                {loading && (
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                )}
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
