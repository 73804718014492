import { gql } from "@apollo/client";

export const PROCESS_LOGS_CORE_FIELDS = gql`
  fragment ProcessLogsCoreFields on processLogs {
    id
    user {
      id
      firstName
      sirName
      email
    }
    content
    timeStamp
    processID
    productionID
    deleted
    updated_at
  }
`;
