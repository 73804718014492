// @ts-expect-error
import { Document, Page } from "react-pdf/dist/esm/entry.vite";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useState } from "react";
import { useElementSize } from "usehooks-ts";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

interface props {
  document: string;
  name: string;
}

export default function PdfViewer({ document, name }: props) {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [ref, { width }] = useElementSize();

  const pdfWidth = width > 1024 ? 1024 : width;

  const movePage = (num: number) => {
    let p = page + num;
    if (p < 1) {
      p = 1;
    }
    if (p > lastPage) {
      p = lastPage;
    }
    setPage(p);
  };

  // @ts-expect-error
  function onDocumentLoadSuccess({ numPages }) {
    setLastPage(numPages);
  }

  console.log(document);

  return (
    <div
      ref={ref}
      className="select-text flex flex-col gap-2 flex-1 w-0 relative"
    >
      <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={page} width={pdfWidth} />
      </Document>
      <div
        className="flex flex-row gap-2 justify-end"
        style={{ width: pdfWidth }}
      >
        <div
          className="w-4 cursor-pointer"
          onClick={() => {
            movePage(-1);
          }}
        >
          {"<"}
        </div>
        <div>
          {page} of {lastPage}
        </div>
        <div
          className="w-4 cursor-pointer"
          onClick={() => {
            movePage(1);
          }}
        >
          {">"}
        </div>
      </div>
      <Link
        to={`/pdf/${encodeURIComponent(name)}/${encodeURIComponent(document)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="absolute opacity-30 p-2 right-2 top-2 shadow-lg cursor-pointer rounded-md bg-dark">
          <ArrowUpRightIcon className="w-5" />
        </div>
      </Link>
    </div>
  );
}
