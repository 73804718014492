import { ProcessType } from "../types";
import { Badge, Table } from "flowbite-react";
import { useAuthStore } from "../../../../store/authStore";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../../settings/products/products";
import getContrast from "../../../../utils/getContrast";
import { fullNumber } from "../../../../utils/fullNumber";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../../../../utils/numberMethods";

interface props {
  process: ProcessType;
}
export default function TableItem({ process }: props) {
  const {
    processStatus,
    name,
    processType,
    year,
    number,
    salesRepUser,
    address,
    products,
    value,
    from,
    due,
    created_at,
    paymentStatus,
  } = process;

  const { data: data_products } = useQuery(GET_PRODUCTS);
  const productList = data_products?.products;

  const fullNum = fullNumber(processType.prefix, year, number, salesRepUser);

  const isPastDue =
    processStatus.dueMatters && dayjs(process.due).isBefore(new Date());

  const navigate = useNavigate();
  const goToDetail = () => {
    navigate("/process/detail/" + process.id);
  };

  return (
    <Table.Row
      onClick={goToDetail}
      className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
    >
      <Table.Cell className="hidden @xs:table-cell supports-[not(container-type:inline-size)]:xs:table-cell">
        <Badge
          style={{
            color: getContrast(processType.color),
            backgroundColor: processType.color,
          }}
          className="w-min"
        >
          {processType.name}
        </Badge>
      </Table.Cell>
      <Table.Cell className="hidden @sm:table-cell supports-[not(container-type:inline-size)]:sm:table-cell">
        {fullNum}
      </Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell className="hidden @lg:table-cell supports-[not(container-type:inline-size)]:lg:table-cell">
        {currencyFormat(value || 0)}
      </Table.Cell>
      <Table.Cell className="hidden @2xl:table-cell supports-[not(container-type:inline-size)]:2xl:table-cell">
        <div className="flex flex-row gap-2 items-center">
          {products?.map((p, i) => {
            const product = productList?.find(pro => pro.id == p.productId);
            return (
              <div
                key={`${p.productId}${i}`}
                className="flex flex-row items-center gap-2"
              >
                {product?.image && (
                  <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                    <img src={product?.image} className="w-3" />
                  </div>
                )}
                {p.qty}
              </div>
            );
          })}
        </div>
      </Table.Cell>
      <Table.Cell className="hidden @md:table-cell supports-[not(container-type:inline-size)]:md:table-cell">
        {address}
      </Table.Cell>
      <Table.Cell className="hidden @lg:table-cell supports-[not(container-type:inline-size)]:lg:table-cell">
        {from ? dayjs(from).format("DD/MM/YY") : "Nill"}
      </Table.Cell>
      <Table.Cell className="hidden @md:table-cell supports-[not(container-type:inline-size)]:md:table-cell">
        <span className={`${isPastDue && "text-red-500"}`}>
          {due ? dayjs(due).format("DD/MM/YY") : "Nill"}
        </span>
      </Table.Cell>
      <Table.Cell className="hidden @xl:table-cell supports-[not(container-type:inline-size)]:xl:table-cell">
        <Badge
          style={{
            color: paymentStatus
              ? getContrast(paymentStatus?.color || "")
              : "unset",
            backgroundColor: paymentStatus?.color || "unset",
          }}
          className="w-min truncate"
        >
          {paymentStatus?.name || "Nill"}
        </Badge>
      </Table.Cell>
      <Table.Cell className="hidden @sm:table-cell supports-[not(container-type:inline-size)]:sm:table-cell">
        <Badge
          style={{
            color: getContrast(processStatus.color),
            backgroundColor: processStatus.color,
          }}
          className="w-min truncate"
        >
          {processStatus.name}
        </Badge>
      </Table.Cell>
    </Table.Row>
  );
}
