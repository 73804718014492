import { z } from "zod";
import { coerceDate, coerceNumber, ProcessType } from "../../process/types";
import { ProductionStatusType } from "../../settings/production/productionStatuses/types";
import { ProductType } from "../../settings/products/types";
import { TeamType } from "../../settings/teams/types";
import { order } from "../../workorder/createOrder/types";

export const productionSchema = z.object({
  processID: coerceNumber,
  badge: z.string(),
  teamID: coerceNumber,
  productID: coerceNumber,
  statusID: coerceNumber,
  qty: coerceNumber,
  due: coerceDate,
  description: z.string(),
  createdBy: coerceNumber,
});

export interface NewProductionType {
  processID: number;
  badge: string;
  teamID: number;
  productID: number;
  statusID: number;
  qty: number;
  due: string | null;
  description: string;
  createdBy: number;
  created_at?: string;
}

export interface ProductionType
  extends Omit<
    NewProductionType,
    "processID" | "productID" | "teamID" | "statusID"
  > {
  id: number;
  user: {
    id: number;
    firstName: string;
    sirName: string;
    email: string;
  };
  process?: ProcessType;
  product: ProductType;
  productionStatus: ProductionStatusType;
  team: TeamType;
  done: number;
  created_at: string;
  progress?: number[];
  deleted: boolean;
  updated_at: string;
  workorders?: { id: number; order: order }[];
}

export interface ProductionArrayType {
  productions: ProductionType[];
}

export interface ProductionStreamType {
  productions_stream: ProductionType[];
}
