import { useMutation, useQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Badge, Button, Select, Spinner, TextInput } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { processDetailSubProps } from "../types";
import {
  ProcessHistory,
  processProductSchema,
  ProcessProductType,
} from "../types";
import {
  PencilIcon,
  PlusCircleIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { addAlert } from "../../../../store/alertStore";
import { GET_PRODUCTS } from "../../settings/products/products";

const resolver = z.object({
  products: z.array(processProductSchema).optional(),
});

// TODO: CheckAuth, can edit status
export default function ProcessProducts({
  process,
  baseHistory,
}: processDetailSubProps) {
  const { products } = process;
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const defaultValues = useCallback(
    () => ({
      products,
    }),
    [process]
  );

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: defaultValues(),
  });

  useEffect(() => {
    reset(defaultValues());
  }, [process]);

  const formProducts: ProcessProductType[] = watch("products") || [];

  const [productToAdd, setProductToAdd] = useState({
    productId: 0,
    qty: 0,
  });

  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);
  const { data: data_products } = useQuery(GET_PRODUCTS);
  const allProducts = data_products?.products;

  const onSubmit = handleSubmit(async data => {
    const { products } = data;

    if (!isDirty) {
      addAlert({ message: "nothing changed", type: "warning" });
      return;
    }

    const history: ProcessHistory = {
      ...baseHistory,
      message: `products updated`,
      type: "detail",
    };

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(history)
      : [history];

    update_process({
      variables: {
        id: process.id,
        set: {
          products,
          histories,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update products",
          type: "failure",
        });
      },
      onCompleted() {
        reset({ products });
        toggleEditing();
      },
    });
  });

  const cancel = () => {
    reset();
    toggleEditing();
  };

  const addProduct = () => {
    if (productToAdd.productId == 0 || productToAdd.qty < 1) {
      return;
    }
    setValue("products", formProducts.concat(productToAdd), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setProductToAdd({
      productId: 0,
      qty: 0,
    });
  };

  const deleteProduct = (id: number) => {
    setValue(
      "products",
      formProducts.filter(p => p.productId !== id),
      { shouldDirty: true, shouldTouch: true, shouldValidate: true }
    );
  };

  return (
    <form
      onSubmit={onSubmit}
      className="col-span-1 
      @xl:col-span-3 supports-[not(container-type:inline-size)]:xl:col-span-3
      @2xl:col-span-2 supports-[not(container-type:inline-size)]:2xl:col-span-2
      space-y-2 flex flex-col"
    >
      <div className="flex flex-row items-center gap-2 justify-between @md:justify-start">
        <h2>Products</h2>
        {!editing && (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 cursor-pointer hover:text-grass"
          />
        )}
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
        {!editing && (
          <div className="flex flex-row flex-wrap h-full gap-2">
            {products?.map((p, i) => {
              const product = allProducts?.find(ap => ap.id == p.productId);
              if (!product) {
                return null;
              } else {
                return (
                  <Badge key={i}>
                    <div className="flex flex-row gap-1 items-center py-1">
                      {product?.image && (
                        <img src={product.image} className="w-5" />
                      )}
                      {product?.name}: {p.qty}
                    </div>
                  </Badge>
                );
              }
            })}
            {(!products || products.length < 1) && (
              <div className="flex-1 h-full flex justify-center items-center">
                <Badge
                  color="purple"
                  className="cursor-pointer w-fit"
                  onClick={() => {
                    toggleEditing();
                  }}
                >
                  <div className="flex flex-row items-center gap-1 text-sm">
                    <PlusIcon className="w-3" />
                    add products
                  </div>
                </Badge>
              </div>
            )}
          </div>
        )}

        {editing && (
          <>
            <div className="flex flex-row gap-2 items-center justify-between">
              <div className="flex flex-row items-center gap-2">
                <Select
                  sizing="sm"
                  value={productToAdd.productId}
                  onChange={e => {
                    setProductToAdd({
                      ...productToAdd,
                      productId: Number(e.target.value),
                    });
                  }}
                  className="w-fit"
                >
                  <option value={0}>Select</option>

                  {allProducts
                    ?.filter(
                      pr => !formProducts.find(p => pr.id == p.productId)
                    )
                    .map(product => (
                      <option key={product.id} value={product.id}>
                        {product.name}
                      </option>
                    ))}
                </Select>
                <TextInput
                  type="number"
                  sizing="sm"
                  className="w-36"
                  addon="qty"
                  value={productToAdd.qty}
                  onChange={e => {
                    setProductToAdd({
                      ...productToAdd,
                      qty: Number(e.target.value),
                    });
                  }}
                />
              </div>
              {productToAdd.productId !== 0 && productToAdd.qty > 0 && (
                <PlusCircleIcon
                  className="w-7 text-grass cursor-pointer hover:animate-pulse"
                  onClick={addProduct}
                />
              )}
            </div>
            <div className="py-1 flex flex-wrap gap-2">
              {formProducts.map((pr, i) => {
                const product = allProducts?.find(p => pr.productId == p.id);
                return (
                  <Badge
                    key={i}
                    className="group cursor-pointer hover:animate-pulse relative"
                    onClick={() => {
                      deleteProduct(pr.productId);
                    }}
                  >
                    <div className="flex flex-row gap-1 items-center py-1">
                      {product?.image && (
                        <img src={product.image} className="w-5" />
                      )}
                      {product?.name}: {pr.qty}
                    </div>
                    <div className="text-red-500 rounded-md absolute left-0 top-0 group-hover:flex backdrop-blur-md justify-center items-center hidden w-full h-full">
                      Delete
                    </div>
                  </Badge>
                );
              })}
            </div>
            <div className="flex justify-end flex-row items-center gap-2 mt-2">
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                type="submit"
                outline
              >
                {loading && <Spinner size="xs" light className="mr-1" />}
                update
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}
