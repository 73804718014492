import { create } from "zustand";
import {
  QuoteParams,
  pricingTable,
} from "../routes/protected/settings/quote/types";
import { useQuery } from "@apollo/client";
import {
  GET_PRICING_TABLES,
  GET_QUOTE_PARAMS,
} from "../routes/protected/settings/quote/gqls";

type Store = {
  quoteParams: QuoteParams[];
  setQuoteParams: (quoteParams: QuoteParams[]) => void;
  pricingTables: pricingTable[];
  setPricingTables: (pricingTables: pricingTable[]) => void;
};

export const useQuoteStore = create<Store>((set, get) => ({
  quoteParams: [],
  setQuoteParams(quoteParams) {
    return set(state => ({
      ...state,
      quoteParams,
    }));
  },
  pricingTables: [],
  setPricingTables(pricingTables) {
    return set(state => ({
      ...state,
      pricingTables,
    }));
  },
}));

export const usePopulateQuoteStore = () => {
  const { setQuoteParams, setPricingTables } = useQuoteStore();

  const { loading: loadingQuoteParam } = useQuery(GET_QUOTE_PARAMS, {
    onCompleted(data) {
      setQuoteParams(data.quoteParams || []);
    },
  });

  const { loading: loadingPricingTable } = useQuery(GET_PRICING_TABLES, {
    onCompleted(data) {
      setPricingTables(data.productSetPricingTable || []);
    },
  });

  const loading = loadingQuoteParam || loadingPricingTable;

  return loading;
};
