import { processDetailSubProps } from "../types";
import AddProduction from "../../shared/productions/addProduction";
import { Button } from "flowbite-react";
import { useScheme } from "../../../../store/schemeStore";
import { useState } from "react";
import DynamicHeroIcon from "../../../../comps/hIcon";
import { gql, useQuery, TypedDocumentNode } from "@apollo/client";
import { PRODUCTION_CORE_FIELDS } from "../../shared/productions/fragments";
import { ProductionArrayType } from "../../shared/productions/types";
import Production from "./production";
import { sort } from "fast-sort";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

export const GET_PRODUCTIONS_BY_PID: TypedDocumentNode<ProductionArrayType> = gql`
  ${PRODUCTION_CORE_FIELDS}
  query GET_PRODUCTIONS_BY_PID($pid: Int) {
    productions(where: { processID: { _eq: $pid } }) {
      ...ProductionCoreFields
    }
  }
`;

interface props extends processDetailSubProps {
  jobPath: false | string[];
}

export default function ProcessProduction({ process, jobPath }: props) {
  const { scheme } = useScheme();
  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const [seeAll, setSeeAll] = useState(false);
  const toggleSeeAll = () => {
    setSeeAll(!seeAll);
  };

  const { data } = useQuery(GET_PRODUCTIONS_BY_PID, {
    variables: { pid: process.id },
    fetchPolicy: "cache-and-network",
  });

  const productions = data?.productions
    ? sort(data.productions)
        .desc("created_at")
        .filter(p => p.process?.id == process.id)
    : undefined;

  const closedItems = productions?.filter(
    p => p.productionStatus.name == "closed"
  );
  const otherItems = productions?.filter(
    p => p.productionStatus.name !== "closed"
  );

  return (
    <div
      className="col-span-1 
     flex flex-col gap-2"
    >
      <div className="flex flex-row items-center justify-between">
        <h2 className="mb-2">Production</h2>
        <div
          onClick={toggleSeeAll}
          className="flex flex-row gap-2 items-center hover:text-plum cursor-pointer"
        >
          {seeAll ? (
            <EyeIcon className="w-4" />
          ) : (
            <EyeSlashIcon className="w-4" />
          )}
          Closed
        </div>
      </div>
      <Button
        onClick={toggleAdding}
        size="sm"
        color={scheme == "dark" ? "dark" : "purple"}
      >
        <DynamicHeroIcon
          class="w-5"
          icon={adding ? "MinusCircleIcon" : "PlusCircleIcon"}
        />
      </Button>
      {adding && (
        <AddProduction
          processID={process.id}
          setAdding={setAdding}
          jobPath={jobPath}
        />
      )}
      {otherItems?.map(production => (
        <Production
          key={production.id}
          production={production}
          jobPath={jobPath}
        />
      ))}

      {seeAll &&
        closedItems?.map(production => (
          <Production
            key={production.id}
            production={production}
            jobPath={jobPath}
          />
        ))}
    </div>
  );
}
