import {
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  Badge,
  Label,
  Select,
  Spinner,
  Textarea,
  TextInput,
} from "flowbite-react";
import { useFormContext } from "react-hook-form";
import { TagType } from "../../settings/process/tags/types";
import { ProductType } from "../../settings/products/types";
import { useMutation } from "@apollo/client";
import { INSERT_TAG } from "../../settings/process/tags/addTag";
import { useState } from "react";
import { TAGS_CORE_FIELDS } from "../../settings/process/tags/fragments";
import { ProcessProductType } from "../types";

export default function Etc({
  products: productList,
  tags: tagList,
}: {
  products: ProductType[];
  tags: TagType[];
}) {
  const [insert_tag, { loading: inserting_tag }] = useMutation(INSERT_TAG);

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [searchStr, setSearchStr] = useState("");
  const [searching, setSearching] = useState(false);

  const tags: number[] = watch("tags") || [];
  const toggleTag = (id: number) => {
    if (tags.find(s => id == s)) {
      setValue(
        "tags",
        tags.filter(s => s !== id)
      );
    } else {
      setValue("tags", tags.concat(id));
    }
  };

  const unAddedtags =
    searchStr !== ""
      ? tagList.filter(
          t => t.name.includes(searchStr) && !tags.find(tag => tag == t.id)
        )
      : [];

  const addTag = () => {
    insert_tag({
      variables: {
        name: searchStr,
      },
      update(cache, { data: { insert_tags_one: newTag } }) {
        cache.modify({
          fields: {
            tags(existingTags = []) {
              const newTagRef = cache.writeFragment({
                data: newTag,
                fragment: TAGS_CORE_FIELDS,
              });
              return [...existingTags, newTagRef];
            },
          },
        });
      },
      onCompleted(data) {
        const tag = data.insert_tags_one;
        setSearchStr("");
        setSearching(false);
        toggleTag(tag.id);
      },
    });
  };

  const products: ProcessProductType[] = watch("products") || [];

  const [productToAdd, setProductToAdd] = useState({
    productId: 0,
    qty: 0,
  });

  const addProduct = () => {
    if (productToAdd.productId == 0 || productToAdd.qty < 1) {
      return;
    }
    setValue("products", products.concat(productToAdd));
    setProductToAdd({
      productId: 0,
      qty: 0,
    });
  };

  const deleteProduct = (id: number) => {
    setValue(
      "products",
      products.filter(p => p.productId !== id)
    );
  };

  return (
    <section className="col-span-1 space-y-2 flex flex-col">
      <h2>Etc</h2>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-2 flex-1">
        <div className="space-y-2">
          <Label value="Tags" />
          <div className="rounded-md border-[1px] border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-[#374151] py-1 px-3">
            <div className="relative">
              <MagnifyingGlassIcon className="w-5 absolute m-auto top-0 bottom-0 left-0 dark:text-gray-400" />
              <input
                value={searchStr}
                onChange={e => {
                  const str = e.target.value.toLowerCase().replace(" ", "");
                  setSearchStr(str);
                  setSearching(str !== "");
                }}
                placeholder="Search Tags"
                className="bg-transparent outline-none pl-7 w-full placeholder:text-gray-400 text-xs"
              />
              {searching && (
                <div className="absolute m-auto top-0 bottom-0 right-0 flex gap-1">
                  <XMarkIcon
                    onClick={() => {
                      setSearchStr("");
                      setSearching(false);
                    }}
                    className="w-5 cursor-pointer"
                  />
                  {!tagList.find(t => t.name == searchStr) &&
                    !inserting_tag && (
                      <PlusCircleIcon
                        onClick={addTag}
                        className="w-5 text-grass  cursor-pointer"
                      />
                    )}
                </div>
              )}

              {inserting_tag && (
                <Spinner
                  className="absolute m-auto top-0 bottom-0 right-0"
                  size="sm"
                />
              )}
            </div>
            {unAddedtags && unAddedtags.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2 py-2">
                {unAddedtags.map(tag => (
                  <Badge
                    key={tag.id}
                    className="cursor-pointer relative"
                    onClick={() => {
                      toggleTag(tag.id);
                    }}
                  >
                    {tag.name}
                    <PlusCircleIcon className="absolute w-4 text-green-500 top-[-6px] right-[-6px]" />
                  </Badge>
                ))}
              </div>
            )}
            {tags && tags.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2 py-2">
                {tags.map(tag => (
                  <Badge
                    key={tag}
                    color="purple"
                    className="cursor-pointer relative"
                    onClick={() => {
                      toggleTag(tag);
                    }}
                  >
                    {tagList.find(t => t.id == tag)?.name}
                    <MinusCircleIcon className="absolute w-4 text-red-500 top-[-6px] right-[-6px]" />
                  </Badge>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="space-y-2">
          <Label value="Products" />
          <div className="flex flex-row gap-2 items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <Select
                sizing="sm"
                value={productToAdd.productId}
                onChange={e => {
                  setProductToAdd({
                    ...productToAdd,
                    productId: Number(e.target.value),
                  });
                }}
                className="w-fit"
              >
                <option value={0}>Select</option>

                {productList
                  .filter(pr => !products.find(p => pr.id == p.productId))
                  .map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
              </Select>
              <TextInput
                type="number"
                sizing="sm"
                className="w-36"
                addon="qty"
                value={productToAdd.qty}
                onChange={e => {
                  setProductToAdd({
                    ...productToAdd,
                    qty: Number(e.target.value),
                  });
                }}
              />
            </div>
            {productToAdd.productId !== 0 && productToAdd.qty > 0 && (
              <PlusCircleIcon
                className="w-7 text-grass cursor-pointer hover:animate-pulse"
                onClick={addProduct}
              />
            )}
          </div>
          <div className="py-1 flex flex-wrap gap-2">
            {products.map((pr, i) => {
              const product = productList.find(p => pr.productId == p.id);
              return (
                <Badge
                  key={i}
                  className="group cursor-pointer hover:animate-pulse relative"
                  onClick={() => {
                    deleteProduct(pr.productId);
                  }}
                >
                  <div className="flex flex-row gap-1 items-center py-1">
                    {product?.image && (
                      <img src={product.image} className="w-5" />
                    )}
                    {product?.name}: {pr.qty}
                  </div>
                  <div className="text-red-500 rounded-md absolute left-0 top-0 group-hover:flex backdrop-blur-md justify-center items-center hidden w-full h-full">
                    Delete
                  </div>
                </Badge>
              );
            })}
          </div>
        </div>
        <div className="space-y-2">
          <Label value="Description" />
          <Textarea
            placeholder="Enter Description"
            rows={3}
            {...register("description")}
            helperText={errors.description?.message?.toString() || ""}
            color={errors.description?.message ? "failure" : undefined}
            className="text-sm resize-none"
          />
        </div>
      </div>
    </section>
  );
}
