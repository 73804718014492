import { Coord, geocode } from "../../../utils/geocode";
import { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScriptProps,
  useLoadScript,
} from "@react-google-maps/api";
import {
  darkStyles,
  googleMapOptions,
  lightStyles,
} from "../process/mapOptions";
import { useScheme } from "../../../store/schemeStore";
import MapMarker from "../process/addProcess/map/marker";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
import "../process/processDetail/map.css";
import { default as config } from "../../../config";
import { MarkerProp } from "../process/types";
import { ContactType } from "./types";

const companyLoc = config.companyLoc;

const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];

interface props {
  contact: ContactType;
  markers: MarkerProp[];
}

export default function ProcessMap({ contact, markers }: props) {
  const [geo, setGeo] = useState<Coord | undefined>(undefined);
  const { scheme } = useScheme();
  const [newLoc, setNewLoc] = useState<Coord | undefined>(undefined);

  useEffect(() => {
    if (markers[0]) {
      setNewLoc(markers[0].position);
    } else {
      setNewLoc(undefined);
    }
  }, [markers]);

  useEffect(() => {
    if (contact.address) {
      geocode(contact.address).then(data => {
        setGeo(data);
      });
    }
  }, [contact.address]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });

  if (!isLoaded) return <div>Loading...</div>;

  const mapOptions = {
    ...googleMapOptions,
    styles: scheme == "dark" ? darkStyles : lightStyles,
  };

  return (
    <div
      className="col-span-1
      @lg:col-span-2 supports-[not(container-type:inline-size)]:lg:col-span-2
    space-y-2 min-h-[280px]"
    >
      <div className="h-full overflow-hidden relative">
        <div
          className={`z-10 w-full h-full absolute pointer-events-none vignette${
            scheme == "dark" ? "-dark" : ""
          } `}
        />
        <GoogleMap
          zoom={14}
          tilt={0}
          center={newLoc || geo || companyLoc}
          options={mapOptions}
          mapContainerClassName="processDetailMap"
        >
          {geo && <MapMarker marker={{ position: geo }} />}
          {markers.map((marker, i) => (
            <MapMarker key={i} marker={marker} />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
}
