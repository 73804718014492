import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Badge,
  Button,
  Label,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import getContrast from "../../../../utils/getContrast";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { coerceNumber } from "../types";
import { processDetailSubProps } from "../types";
import { ProcessHistory } from "../types";
import { fullNumber } from "../../../../utils/fullNumber";
import { GET_PROCESS_TYPES } from "../../settings/process/processTypes/processTypes";
import { PencilIcon } from "@heroicons/react/24/solid";
import { addAlert } from "../../../../store/alertStore";
import { GET_PROCESS_LAST_NUM } from "../addProcess/ids";
import { rename, mkdir, createPathArr } from "../../../../utils/fileAPI";
const ISDEV = import.meta.env.DEV;

const resolver = z.object({
  typeId: coerceNumber,
  year: coerceNumber,
  number: coerceNumber,
});

interface props extends processDetailSubProps {
  jobPath: false | string[];
}

// TODO: CheckAuth, can edit status
export default function ProcessIds({ process, baseHistory, jobPath }: props) {
  const { processType, year, number } = process;
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const defaultValues = useCallback(
    () => ({
      typeId: processType.id.toString(),
      year: year.toString(),
      number: number.toString(),
    }),
    [process]
  );

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: defaultValues(),
  });

  useEffect(() => {
    reset(defaultValues());
  }, [process]);

  const formTypeId = watch("typeId");
  const formYear = watch("year");

  const [getLastNum, { loading: loading_lastNum }] = useLazyQuery(
    GET_PROCESS_LAST_NUM,
    {
      fetchPolicy: "network-only",
    }
  );

  const { data: data_process_type } = useQuery(GET_PROCESS_TYPES);

  useEffect(() => {
    if (
      !formTypeId ||
      (formTypeId == processType.id.toString() && formYear == year.toString())
    ) {
      return;
    }
    getLastNum({
      variables: {
        typeId: formTypeId,
        year: formYear,
      },
      fetchPolicy: "no-cache",
      onCompleted(data) {
        let newNum;
        if (data.process && data.process.length > 0) {
          newNum = data.process[0].number + 1;
        } else {
          newNum = 1;
        }
        setValue("number", newNum.toString());
      },
      onError(err) {
        console.log(err);
        addAlert({ message: "could not load last number", type: "failure" });
      },
    });
  }, [formTypeId, formYear]);

  const processTypes = data_process_type?.processType;

  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);

  const originalId = fullNumber(processType.prefix, year, number);

  const onSubmit = handleSubmit(async data => {
    const { typeId, year, number } = data;

    if (!isDirty) {
      addAlert({ message: "nothing changed", type: "warning" });
      return;
    }

    const prefix = processTypes
      ? processTypes.find(p => p.id == Number(typeId))?.prefix || ""
      : "";
    const newId = fullNumber(prefix, Number(year), Number(number));

    const history: ProcessHistory = {
      ...baseHistory,
      message: `id updated from ${originalId} to ${newId}`,
      type: "detail",
    };

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(history)
      : [history];

    update_process({
      variables: {
        id: process.id,
        set: {
          typeId: Number(typeId),
          year: Number(year),
          number: Number(number),
          histories,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update ids, possible duplicate",
          type: "failure",
        });
      },
      onCompleted(data) {
        reset({ typeId, year, number });
        toggleEditing();

        if (data.update_process_by_pk) {
          const newProcess = data.update_process_by_pk;
          const newPath = createPathArr(newProcess);

          if (newPath) {
            if (jobPath) {
              rename(jobPath, newPath);
            } else {
              mkdir(newPath);
            }
          }

          if (jobPath && !newPath) {
            addAlert({
              message:
                "new job type does not have set directory, old folder will be left as it is please delete or move manually",
              type: "warning",
            });
          }
        }
      },
    });
  });

  const cancel = () => {
    reset();
    toggleEditing();
  };
  let years = [];

  for (let i = 2014; i <= new Date().getFullYear() + 1; i++) {
    years.push(i);
  }
  return (
    <form onSubmit={onSubmit} className="col-span-1 space-y-2" dir="ltr">
      <div className="flex flex-row items-center gap-2 justify-between @md:justify-start">
        <h2>IDs</h2>
        {!editing && (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 cursor-pointer hover:text-grass"
          />
        )}
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 ">
        <div className="flex flex-row items-center justify-between gap-2">
          <Label value="Type" className="flex-1" />

          {editing ? (
            <Select
              sizing="sm"
              {...register("typeId")}
              color={errors.typeId?.message ? "failure" : undefined}
              disabled={loading}
              className="flex-[2]"
            >
              {processTypes &&
                processTypes.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
            </Select>
          ) : (
            <Badge
              style={{
                backgroundColor: processType.color,
                color: getContrast(processType.color),
              }}
              size="sm"
            >
              {processType.name}
            </Badge>
          )}
        </div>
        <div className="flex flex-row items-center justify-between gap-2">
          <Label value="Year" className="flex-1" />
          {editing ? (
            <Select
              className="flex-[2]"
              sizing="sm"
              {...register("year")}
              color={errors.year?.message ? "failure" : undefined}
            >
              {years.map(y => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </Select>
          ) : (
            <div>{year}</div>
          )}
        </div>
        <div className="flex flex-row items-center justify-between gap-2">
          <Label value="Number" className="flex-1" />
          {editing ? (
            <TextInput
              type="number"
              sizing="sm"
              {...register("number")}
              className="flex-[2]"
              disabled={loading || loading_lastNum}
              color={errors.number?.message ? "failure" : undefined}
            />
          ) : (
            <div>{number}</div>
          )}
        </div>

        {editing && (
          <div className="flex justify-end flex-row items-center gap-2 mt-2">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              update
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
