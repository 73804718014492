import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { ContactType } from "./types";

interface props {
  contact: ContactType;
}

export default function ContactActions({ contact }: props) {
  const call = () => {
    if (!contact.number) {
      return;
    }
    window.location.href = `tel:${contact.number}`;
  };

  const mail = () => {
    if (!contact.mail) {
      return;
    }
    window.location.href = `mailto:${contact.mail}`;
  };

  return (
    <div className="flex flex-row items-center gap-3 z-30">
      <div className="relative">
        <EnvelopeIcon
          className="w-5 cursor-pointer hover:text-grass"
          onClick={mail}
        />
      </div>
      <div className="relative">
        <PhoneIcon
          className="w-5 cursor-pointer hover:text-grass"
          onClick={call}
        />
      </div>
    </div>
  );
}
