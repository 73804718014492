import { Select, Label, Button, Spinner } from "flowbite-react";
import { UserType } from "../types";
import { gql, useMutation, useQuery } from "@apollo/client";
import { addAlert } from "../../../../../store/alertStore";
import { useState, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import { USER_CORE_FIELDS } from "../fragments";
import { GET_TEAMS } from "../../teams/teamList";
import { GET_AUTHS } from "../../auths/roles/roles";
import { PencilIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export const UPDATE_USER_STATUS = gql`
  ${USER_CORE_FIELDS}
  mutation UPDATE_USER_STATUS(
    $teamID: Int
    $authID: Int!
    $id: Int!
    $active: Boolean!
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { teamID: $teamID, authID: $authID, active: $active }
    ) {
      ...UserCoreFields
    }
  }
`;

export default function Basic({ user }: { user: UserType }) {
  const [editing, setEditing] = useState(false);

  const {
    data: team_data,
    loading: team_loading,
    error: team_error,
  } = useQuery(GET_TEAMS);

  if (team_error) {
    console.log(team_error);
    addAlert({ message: "cannot load teams", type: "failure" });
  }

  const teams = team_data ? team_data.teams : false;

  const {
    data: auth_data,
    loading: auth_loading,
    error: auth_error,
  } = useQuery(GET_AUTHS);

  if (auth_error) {
    console.log(auth_error);
    addAlert({ message: "cannot load auths", type: "failure" });
  }

  const roles = auth_data ? auth_data.auths : false;

  const [update_user_role_auth, { loading, error }] =
    useMutation(UPDATE_USER_STATUS);

  if (error) {
    console.log(error);
    addAlert({ message: "action failed", type: "failure" });
  }

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      teamID: user?.team?.id || "",
      authID: user?.auth?.id || "",
      active: user.active ? "true" : "false",
    },
  });

  const onSubmit = handleSubmit(async data => {
    const { teamID, authID, active } = data;

    if (!authID) {
      return addAlert({ message: "Please select a role", type: "warning" });
    }

    const variables = {
      id: user.id,
      teamID: teamID ? Number(teamID) : undefined,
      authID: authID ? Number(authID) : undefined,
      active: active == "true",
    };

    update_user_role_auth({ variables });

    reset({
      teamID,
      authID,
      active,
    });

    setEditing(false);
  });

  const clickEdit = (e: MouseEvent) => {
    if (!editing) {
      e.preventDefault();
      setEditing(state => !state);
    }
  };

  const clickCancle = () => {
    setEditing(false);
    reset();
  };

  const navigate = useNavigate();

  return (
    <form className="flex-1 min-w-[250px] mx-auto" onSubmit={onSubmit}>
      <div className="text-2xl font-bold mb-2 ">Role & Status</div>
      <div className="shadow-md bg-white mb-5 dark:bg-gray-800 rounded-md p-4 ">
        <div className="mb-2 block">
          <Label htmlFor="Auth" value="Auth" />
        </div>
        <div className={`${user?.auth?.name && "text-plum capitalize"}`}>
          {editing ? (
            <>
              {!auth_loading && roles && (
                <Select sizing="sm" className="w-max" {...register("authID")}>
                  <option value="">Select Role</option>
                  {roles &&
                    roles
                      ?.filter(
                        role =>
                          role.name !== "admin" && role.name !== "accountant"
                      )
                      .map(role => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                </Select>
              )}
            </>
          ) : (
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/settings/auths");
              }}
            >
              {user?.auth?.name || "not assigned"}
            </div>
          )}
        </div>
        <div className="my-2 block">
          <Label htmlFor="Team" value="Team" />
        </div>
        <div>
          {editing ? (
            <>
              {!team_loading && teams && (
                <Select sizing="sm" className="w-max" {...register("teamID")}>
                  <option value="">No Team</option>
                  {teams &&
                    teams?.map(team => (
                      <option key={team.id} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                </Select>
              )}
            </>
          ) : (
            <>{user?.team?.name || "not assigned"}</>
          )}
        </div>

        <div className="mb-2 block mt-2">
          <Label htmlFor="status" value="Status" />
        </div>
        <div>
          {editing ? (
            <>
              <Select sizing="sm" className="w-max" {...register("active")}>
                <option value="false">Inactive</option>
                <option value="true">Active</option>
              </Select>
            </>
          ) : (
            <>{user?.active ? "Active" : "Inactive"}</>
          )}
        </div>

        <div className="my-2 block">
          <Label htmlFor="singedDate" value="Registered On" />
        </div>
        <div>
          {user?.created_at && new Date(user.created_at).toLocaleDateString()}
        </div>

        <hr className="my-[22px] border-gray-300 dark:border-gray-500" />
        <div className="flex space-x-2 justify-end ">
          {(editing || loading) && (
            <Button
              gradientDuoTone="purpleToBlue"
              size="sm"
              onClick={clickCancle}
            >
              Cancel
            </Button>
          )}

          <Button
            onClick={clickEdit}
            outline
            type="submit"
            gradientDuoTone="purpleToBlue"
            size="sm"
          >
            {!editing && !loading && <PencilIcon className="w-4 mr-2" />}
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            {editing || loading ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
    </form>
  );
}
