import dayjs from "dayjs";
import ListItem from "./listItem";
import { range, recordGroup } from "./types";
import { GET_RECORD_STATUS } from "../shared/record/gqls";
import { useQuery } from "@apollo/client";
import { sort } from "fast-sort";
import { RecordType } from "../shared/record/types";
import { weekdays } from "../../../types/calendarTypes";

interface props {
  group: recordGroup;
  range: range;
  dates: Date[];
}

interface dateGroup {
  date: Date;
  record?: RecordType;
}

export default function ListGroup({ group, range, dates }: props) {
  const { data } = useQuery(GET_RECORD_STATUS);
  const statuses = data?.recordStatus;
  const rejectedStatus = statuses?.find(stat => stat.name == "rejected");
  const pendingStatus = statuses?.find(stat => stat.name == "pending");

  const totalH = group.records.reduce<number>((prv, cur) => {
    if (!cur.end) {
      return prv;
    }
    if (
      cur.status.id == rejectedStatus?.id ||
      cur.status.id == pendingStatus?.id
    ) {
      return prv;
    }

    const diff = dayjs(cur.end).diff(dayjs(cur.start));
    const mins = Math.round(diff / 60000 - (cur.break || 0));
    let hour = Math.floor(mins / 60);
    const min = mins % 60;

    if (min < 25) {
      hour = hour;
    } else if (min < 50) {
      hour = hour + 0.5;
    } else {
      hour++;
    }

    if (hour < 0) {
      hour = 0;
    }
    return prv + hour;
  }, 0);

  const weekends = [0, 6];

  const dateGroup: dateGroup[] = dates.map(date => {
    const record = group.records.find(gr =>
      dayjs(gr.start).isSame(dayjs(date), "date")
    );
    return {
      date,
      record,
    };
  });

  const filteredGroup = dateGroup.filter(dg => {
    const day = dayjs(dg.date).format("ddd").toLowerCase();
    const dayTerm = group.user?.hours && group.user?.hours[day as weekdays];

    if (dg.record) {
      return true;
    }

    if (dayTerm?.off) {
      return false;
    } else if (weekends.includes(dg.date.getDay())) {
      return false;
    }

    if (dayjs(dg.date).isBefore(dayjs(group.user.created_at))) {
      return false;
    }

    if (dayjs(dg.date).isAfter(dayjs(new Date())) && !dg.record) {
      return false;
    }

    return true;
  });

  const sorted = sort(filteredGroup).asc(d => d.date);

  return (
    <div className="flex flex-col gap-2">
      <h3 className="capitalize">
        {group.user.firstName} {group.user.sirName}
      </h3>
      {sorted.map((dg, i) => (
        <ListItem
          key={`${dg.date.getDate()}_${i}`}
          date={dg.date}
          record={dg.record}
          user={group.user}
        />
      ))}
      <div className="flex flex-row justify-end">Total H: {totalH}</div>
    </div>
  );
}
