import { Outlet, useRoutes } from "react-router-dom";
import ProductionList from "./productionList";
import ProductionDetail from "./productionDetail";
import { useSearchStore } from "../../../store/searchStore";
import { useProductionSearch } from "./productionSearch";
import { useEffect } from "react";
import PrintProductionOrder from "./printProductionOrder";
import { useOrderStore } from "../../../store/orderStore";
import { useQuery } from "@apollo/client";
import {
  GET_DEDUCTIONS_NO_IMG,
  GET_DEDUCTION_PRESETS,
  GET_ORDER_SUMMARY_COLUMN,
  GET_PRODUCT_OPTIONS,
  GET_PRODUCT_SETS_MIN,
} from "../workorder/gqls";
import { GET_MATERIALS_MIN } from "../materials/gqls";

const ProductionWrapper = () => {
  const { setSearchFunction, clearSearchFunction, setSearchResults } =
    useSearchStore();

  const searchProduction = useProductionSearch();

  useEffect(() => {
    setSearchFunction(str => {
      if (!str) {
        return;
      }
      searchProduction(str);
    });
    return () => {
      clearSearchFunction();
      setSearchResults([]);
    };
  }, []);

  const {
    setDeductions,
    setProductSetOptions,
    setMaterials,
    setPresets,
    setProductSets,
    setOrderSummaryColumn,
  } = useOrderStore();

  const { loading } = useQuery(GET_DEDUCTIONS_NO_IMG, {
    onCompleted(data) {
      if (data.deductions) {
        setDeductions(data.deductions);
      }
    },
  });

  const { loading: optionsLoading } = useQuery(GET_PRODUCT_OPTIONS, {
    onCompleted(data) {
      if (data.productSetOptions) {
        setProductSetOptions(data.productSetOptions);
      }
    },
  });

  const { loading: matsLoading } = useQuery(GET_MATERIALS_MIN, {
    onCompleted(data) {
      if (data.materials) {
        setMaterials(data.materials);
      }
    },
  });

  const { loading: presetsLoading } = useQuery(GET_DEDUCTION_PRESETS, {
    onCompleted(data) {
      if (data.deductionPreset) {
        setPresets(data.deductionPreset);
      }
    },
  });

  const { loading: productSetsLoading } = useQuery(GET_PRODUCT_SETS_MIN, {
    onCompleted(data) {
      if (data.productSets) {
        setProductSets(data.productSets);
      }
    },
  });

  const { loading: orderSummaryColumnLoading } = useQuery(
    GET_ORDER_SUMMARY_COLUMN,
    {
      onCompleted(data) {
        if (data.orderSummaryColumn) {
          setOrderSummaryColumn(data.orderSummaryColumn);
        }
      },
    }
  );

  if (
    loading ||
    optionsLoading ||
    matsLoading ||
    presetsLoading ||
    productSetsLoading ||
    orderSummaryColumnLoading
  ) {
    return null;
  }
  return <Outlet />;
};

export default function Production() {
  const productionRoutes = useRoutes([
    {
      path: "/",
      element: <ProductionWrapper />,
      children: [
        {
          path: "/",
          element: <ProductionList />,
        },
        {
          path: "/detail/:id",
          element: <ProductionDetail />,
        },
        {
          path: "/print/:productionId/:id",
          element: <PrintProductionOrder />,
        },
      ],
    },
  ]);
  return productionRoutes;
}
