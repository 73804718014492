import { TypedDocumentNode, gql } from "@apollo/client";
import { PRODUCTION_CORE_FIELDS } from "../shared/productions/fragments";
import { ProductionType } from "../shared/productions/types";
import { TEAM_CORE_FIELDS } from "../settings/teams/fragments";
import { PRODUCT_CORE_FIELDS } from "../settings/products/fragments";
import { PRODUCTION_STATUS_CORE_FIELDS } from "../settings/production/productionStatuses/fragments";

interface productionQueryType {
  productions: ProductionType[];
  productions_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

export const ADD_PRODUCTION = gql`
  ${PRODUCTION_CORE_FIELDS}
  mutation ADD_PRODUCTION(
    $processID: Int!
    $badge: String
    $teamID: Int!
    $productID: Int!
    $statusID: Int!
    $qty: Int!
    $due: date
    $description: String
    $createdBy: Int!
  ) {
    insert_productions_one(
      object: {
        processID: $processID
        badge: $badge
        teamID: $teamID
        productID: $productID
        statusID: $statusID
        qty: $qty
        due: $due
        description: $description
        createdBy: $createdBy
      }
    ) {
      ...ProductionCoreFields
    }
  }
`;

interface productionArrayType {
  productions: ProductionType[];
}

export const GET_PRODUCTIONS_CONDITIONAL: TypedDocumentNode<productionArrayType> = gql`
  ${PRODUCTION_CORE_FIELDS}
  query GET_PRODUCTIONS_CONDITIONAL($where: productions_bool_exp!) {
    productions(where: $where) {
      ...ProductionCoreFields
    }
  }
`;

export const GET_PRODUCTIONS_CONDITIONAL_MIN: TypedDocumentNode<productionArrayType> = gql`
  
  query GET_PRODUCTIONS_CONDITIONAL_MIN($where: productions_bool_exp!) {
    ${TEAM_CORE_FIELDS}
    ${PRODUCT_CORE_FIELDS}
    ${PRODUCTION_STATUS_CORE_FIELDS}
    productions(where: $where) {
      id
    user {
      id
      firstName
      sirName
      email
    }
    team {
      ...TeamCoreFields
    }
    processId
    product {
      ...ProductCoreFields
    }
    productionStatus {
      ...ProductionStatusCoreFields
    }
    qty
    done
    badge
    description
    due
    created_at
    progress
    deleted
    updated_at
    }
  }
`;

export const GET_PRODUCTIONS: TypedDocumentNode<productionQueryType> = gql`
  ${PRODUCTION_CORE_FIELDS}
  query GET_PRODUCTIONS(
    $where: productions_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    productions(
      where: $where
      limit: $limit
      offset: $offset
      order_by: {
        team: { name: asc }
        productionStatus: { priority: asc }
        due: asc
        created_at: asc
      }
    ) {
      ...ProductionCoreFields
    }
    productions_aggregate(
      where: $where
      order_by: {
        team: { name: asc }
        productionStatus: { priority: asc }
        due: asc
        created_at: asc
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

interface ProductionOne {
  productions_by_pk: ProductionType;
}

export const GET_PRODUCTION_BY_PK: TypedDocumentNode<ProductionOne> = gql`
  ${PRODUCTION_CORE_FIELDS}
  query GET_PRODUCTION_BY_PK($id: Int!) {
    productions_by_pk(id: $id) {
      ...ProductionCoreFields
    }
  }
`;

interface ProductionStreamType {
  productions_stream: ProductionType[];
}

export const STREAM_PRODUCTIONS: TypedDocumentNode<ProductionStreamType> = gql`
  ${PRODUCTION_CORE_FIELDS}
  subscription STREAM_PRODUCTIONS(
    $where: productions_bool_exp
    $cursor: [productions_stream_cursor_input]!
  ) {
    productions_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...ProductionCoreFields
    }
  }
`;
