import { processDetailSubProps } from "../types";
import { GET_SALES_USERS } from "../addProcess/sales";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { addAlert } from "../../../../store/alertStore";
import { useCallback, useEffect, useRef, useState } from "react";
import { PermissionType } from "../../settings/auths/permissions/types";
import { GET_PERMISSIONS } from "../../settings/auths/permissions/permissions";
import { GET_TEAMS } from "../../settings/teams/teamList";
import { UserType } from "../../settings/users/types";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { coerceNumber } from "../types";
import { ProcessHistory } from "../types";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { GET_SOURCES } from "../../settings/process/sources/sources";
import { SourceType } from "../../settings/process/sources/types";
import { currencyFormat } from "../../../../utils/numberMethods";
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Label,
  ListGroup,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
// @ts-expect-error
import md5 from "md5";
import { useOnClickOutside } from "usehooks-ts";
import getContrast from "../../../../utils/getContrast";
import { GET_USERS } from "../../settings/users/gql";
import { createPathArr, rename } from "../../../../utils/fileAPI";

const resolver = z.object({
  salesRep: coerceNumber.optional(),
  value: coerceNumber.optional(),
  sources: z.array(coerceNumber).optional(),
});

interface props extends processDetailSubProps {
  jobPath: false | string[];
}

export default function ProcessSales({ process, baseHistory, jobPath }: props) {
  const { salesRepUser } = process;
  const [sales, setSales] = useState<UserType[]>([]);
  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);
  const { data: data_users } = useQuery(GET_USERS);
  const users = data_users?.users;
  const [get_sales] = useLazyQuery(GET_SALES_USERS, {
    onError(error) {
      console.log(error);
      addAlert({ message: "cannot load sales users", type: "failure" });
    },
  });
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const ref = useRef<HTMLFormElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });
  const [permission, setPermission] = useState<PermissionType>();

  const { data: data_sources } = useQuery(GET_SOURCES);
  const allSources = data_sources?.sources;

  const { data: data_teams } = useQuery(GET_TEAMS);
  const teams = data_teams?.teams;

  useQuery(GET_PERMISSIONS, {
    onCompleted(data) {
      if (data.permissions) {
        const permission = data.permissions.find(p => p.name == "can_sales");
        setPermission(permission);
      }
    },
  });

  useEffect(() => {
    if (!teams) {
      return;
    }

    const typeId = process.processType.id;
    const validTeams = teams
      .filter(team => team.processTypes?.includes(Number(typeId)))
      .map(team => team.id);

    if (permission && typeId) {
      get_sales({
        variables: {
          pid: permission.id,
          validTeams1: validTeams,
          validTeams2: validTeams,
        },
        onCompleted(data) {
          setSales(data.users);
        },
      });
    }
  }, [process.processType.id, permission]);

  const defaultValues = useCallback(
    () => ({
      salesRep: salesRepUser ? salesRepUser.id.toString() : "",
      value: process.value ? process.value.toString() : "",
      sources: process.sources || [],
    }),
    [process]
  );

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: defaultValues(),
  });

  useEffect(() => {
    reset(defaultValues());
  }, [process]);

  const formSalesUser = watch("salesRep");
  const curSalesUser = users?.find(s => s.id == Number(formSalesUser));

  const onSubmit = handleSubmit(async data => {
    const { salesRep, sources, value } = data;

    if (!isDirty && Number(salesRep) == salesRepUser?.id) {
      addAlert({ message: "nothing changed", type: "warning" });
      return;
    }

    const newHistories: ProcessHistory[] = [];

    if (dirtyFields.salesRep) {
      let message = "";

      if (salesRep == "" || !salesRep) {
        message = "sales rep unassigned";
      } else {
        const exSales = salesRepUser?.firstName;
        const newSales = sales.find(s => s.id == Number(salesRep))?.firstName;
        message = `sales rep changed from ${exSales} to ${newSales}`;
      }

      const history: ProcessHistory = {
        ...baseHistory,
        message,
        type: "detail",
      };
      newHistories.push(history);
    }

    if (dirtyFields.sources) {
      const newSources = sources.reduce<string>((prv, cur) => {
        const source: SourceType | undefined = allSources?.find(
          s => s.id == Number(cur)
        );

        if (source) {
          return prv.concat(` ${source.name}`);
        } else {
          return prv;
        }
      }, "");

      const history: ProcessHistory = {
        ...baseHistory,
        message: `sources altered to [${newSources}]`,
        type: "detail",
      };
      newHistories.push(history);
    }

    if (dirtyFields.value) {
      const history: ProcessHistory = {
        ...baseHistory,
        message: `value updated to ${currencyFormat(Number(value))}`,
        type: "detail",
      };
      newHistories.push(history);
    }

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(...newHistories)
      : newHistories;

    update_process({
      variables: {
        id: process.id,
        set: {
          salesRep: salesRep ? Number(salesRep) : null,
          value: value ? Number(value) : null,
          sources: sources ? sources : null,
          histories,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update sales info",
          type: "failure",
        });
      },
      onCompleted(res) {
        if (jobPath) {
          const newProcess = res.update_process_by_pk;
          const newPath = createPathArr(newProcess);
          if (newPath) {
            rename(jobPath, newPath);
          }
        }
        reset({ salesRep, value, sources });
        setEditing(false);
      },
    });
  });

  const cancel = () => {
    reset();
    toggleEditing();
  };

  const changeSales = (id: number) => {
    setValue("salesRep", id.toString(), { shouldDirty: true });
    onSubmit();
    toggleOpen();
  };

  const sources = watch("sources");

  const toggleSource = (id: number) => {
    if (sources.find(s => id == s)) {
      setValue(
        "sources",
        sources.filter(s => s !== id),
        {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        }
      );
    } else {
      setValue("sources", sources.concat(id), {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <form
      ref={ref}
      onSubmit={onSubmit}
      className="col-span-1 
      @xl:col-span-2 supports-[not(container-type:inline-size)]:xl:col-span-2
      space-y-2 flex flex-col"
    >
      <div className="flex flex-row items-center gap-2 justify-between @md:justify-start">
        <h2>Sales</h2>
        {!editing && (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 cursor-pointer hover:text-grass"
          />
        )}
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col flex-1 justify-center gap-2">
        <div className="flex flex-row gap-4">
          {/* Avatar */}
          <div className="flex flex-col justify-center items-center gap-2 relative">
            <Avatar
              img={
                curSalesUser
                  ? `https://www.gravatar.com/avatar/${md5(
                      curSalesUser.email
                    )}?d=mp`
                  : ""
              }
              size="md"
            />
            {editing ? (
              <Select
                sizing="sm"
                {...register("salesRep")}
                color={errors.salesRep?.message ? "failure" : undefined}
                disabled={loading}
                className="mt-2"
              >
                {sales?.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.sirName[0]}
                  </option>
                ))}
              </Select>
            ) : (
              <>
                {curSalesUser ? (
                  <div className="text-center">
                    {curSalesUser.firstName} {curSalesUser.sirName}
                  </div>
                ) : (
                  <Badge
                    color="purple"
                    className="cursor-pointer"
                    onClick={toggleOpen}
                  >
                    <div className="flex flex-row items-center gap-1 w-max text-xs">
                      <PlusIcon className="w-3" />
                      add sales
                    </div>
                  </Badge>
                )}
              </>
            )}

            {open && (
              <ListGroup className="absolute top-[100%] w-max mt-2 left-0">
                {sales?.map(user => (
                  <ListGroup.Item
                    key={user.id}
                    onClick={() => {
                      if (user.id == salesRepUser?.id) {
                        return;
                      }
                      changeSales(user.id);
                    }}
                  >
                    <Badge
                      size="sm"
                      className="w-full"
                      style={{
                        backgroundColor: user.color,
                        color: getContrast(user.color || ""),
                        opacity: user.id == salesRepUser?.id ? 0.5 : 1,
                      }}
                    >
                      {user.firstName} {user.sirName}
                    </Badge>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </div>
          <div className="flex-1 flex flex-col gap-2">
            {editing && (
              <div className="flex flex-col items-end gap-2">
                <div className="flex flex-row flex-wrap p-1 mb-2 gap-2">
                  {allSources?.map(source => (
                    <div
                      key={source.id}
                      className="flex items-center gap-2"
                      onClick={() => {
                        toggleSource(source.id);
                      }}
                    >
                      <Checkbox
                        className="checked:bg-plum dark:checked:bg-plum cursor-pointer"
                        readOnly
                        checked={
                          sources.find(s => s == source.id) ? true : false
                        }
                      />
                      <Label className="cursor-pointer">{source.name}</Label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!editing && (
              <div className="flex flex-wrap flex-row-reverse p-1 mb-2 gap-2">
                {process.sources?.map(s => {
                  const source = allSources?.find(as => as.id == Number(s));
                  if (!source) {
                    return null;
                  } else {
                    return (
                      <Badge key={source.id} color="purple">
                        {source.name}
                      </Badge>
                    );
                  }
                })}
              </div>
            )}
            <div className="flex flex-row justify-end">
              {!editing && <h1>{currencyFormat(Number(process.value))}</h1>}
              {editing && (
                <div className="flex-1 flex flex-row items-center justify-end">
                  <TextInput
                    addon="$"
                    type="number"
                    sizing="sm"
                    {...register("value")}
                    disabled={loading}
                    color={errors.value?.message ? "failure" : undefined}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {editing && (
          <div className="flex justify-end flex-row items-center gap-2 mt-2">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              update
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
