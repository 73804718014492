import { TextInput } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import CustomQuoteOptions from "./customQuoteOptions";

interface props {
  openingCoord: string;
}

export default function CustomItem({ openingCoord }: props) {
  const { register, control } = useFormContext();

  const showPrice = useWatch({
    name: `showPrice`,
    control,
    exact: true,
  });

  return (
    <>
      <TextInput
        sizing="sm"
        type="number"
        addon="W"
        className="w-24"
        {...register(`${openingCoord}.width`)}
      />
      <TextInput
        sizing="sm"
        type="number"
        addon="H"
        className="w-24"
        {...register(`${openingCoord}.height`)}
      />
      <TextInput
        sizing="sm"
        className="flex-1 min-w-[200px]"
        {...register(`${openingCoord}.customDesc`)}
        placeholder="Describe custom item"
      />
      {showPrice && (
        <>
          <CustomQuoteOptions coord={openingCoord} showPrice={showPrice} />
          <div className={`relative group/option w-20 my-[5px]`}>
            <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
              Install
            </div>
            <TextInput
              addon="$"
              className="w-full"
              {...register(`${openingCoord}.install`)}
              sizing="sm"
            />
          </div>
          <div className={`relative group/option w-20 my-[5px]`}>
            <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
              Price
            </div>
            <TextInput
              addon="$"
              className="w-full"
              {...register(`${openingCoord}.price`)}
              sizing="sm"
            />
          </div>
        </>
      )}
    </>
  );
}
