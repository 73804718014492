export default function (value: any, includeZero?: boolean) {
  const nullishValues: any[] = [undefined, null, ""];
  if (includeZero) {
    nullishValues.push(0);
  }

  if (Array.isArray(value)) {
    return value.filter(v => v !== 0 && v !== "").length == 0;
  }

  return nullishValues.includes(value);
}
