import { useMutation, useQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Badge,
  Button,
  Label,
  ListGroup,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import getContrast from "../../../../utils/getContrast";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { coerceDate, coerceNumber } from "../types";
import { processDetailSubProps } from "../types";
import { ProcessHistory } from "../types";
import { GET_PROCESS_STATUSES } from "../../settings/process/processStatuses/processStatuses";
import { PencilIcon } from "@heroicons/react/24/solid";
import { addAlert } from "../../../../store/alertStore";
import dayjs from "dayjs";
import { useOnClickOutside } from "usehooks-ts";

const resolver = z.object({
  status: coerceNumber,
  from: coerceDate,
  due: coerceDate,
});

// TODO: CheckAuth, can edit status
export default function ProcessStatus({
  process,
  baseHistory,
}: processDetailSubProps) {
  const { processStatus, from, due } = process;
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const ref = useRef<HTMLFormElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const defaultValues = useCallback(
    () => ({
      status: processStatus.id.toString(),
      from: from ? dayjs(from).format("YYYY-MM-DD") : null,
      due: due ? dayjs(due).format("YYYY-MM-DD") : null,
    }),
    [process]
  );

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: defaultValues(),
  });

  useEffect(() => {
    reset(defaultValues());
  }, [process]);

  const { data: data_process_status } = useQuery(GET_PROCESS_STATUSES);
  const processStatuses = data_process_status?.processStatus;

  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);

  const onSubmit = handleSubmit(async data => {
    const { status, from, due } = data;

    if (!isDirty && Number(status) == processStatus.id) {
      addAlert({ message: "nothing changed", type: "warning" });
      return;
    }

    const newHistories: ProcessHistory[] = [];

    if (Number(status) !== Number(process.processStatus.id)) {
      const newStatus = processStatuses?.find(p => p.id == Number(status));

      const history: ProcessHistory = {
        ...baseHistory,
        message: `status updated from ${processStatus.name} to ${newStatus?.name}`,
        type: "status",
      };
      newHistories.push(history);
    }

    if (dirtyFields.due) {
      const history: ProcessHistory = {
        ...baseHistory,
        message: `due date updated from ${process.due || "NILL"} to ${
          (due && dayjs(due).format("YYYY-MM-DD")) || "NILL"
        }`,
        type: "detail",
      };
      newHistories.push(history);
    }

    if (dirtyFields.from) {
      const history: ProcessHistory = {
        ...baseHistory,
        message: `from date updated from ${process.from || "NILL"} to ${
          (from && dayjs(from).format("YYYY-MM-DD")) || "NILL"
        }`,
        type: "detail",
      };
      newHistories.push(history);
    }

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(...newHistories)
      : newHistories;

    update_process({
      variables: {
        id: process.id,
        set: {
          status: Number(status),
          from,
          due,
          histories,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update status",
          type: "failure",
        });
      },
      onCompleted() {
        reset({
          status,
          from: from ? dayjs(from).format("YYYY-MM-DD") : null,
          due: due ? dayjs(due).format("YYYY-MM-DD") : null,
        });
        setEditing(false);
      },
    });
  });

  const cancel = () => {
    reset();
    toggleEditing();
  };

  const changeStatus = (id: number) => {
    setValue("status", id.toString(), {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
    onSubmit();
    toggleOpen();
  };

  const isPastDue =
    process.processStatus.dueMatters &&
    process.due &&
    dayjs(process.due).isBefore(new Date());

  return (
    <form
      ref={ref}
      onSubmit={onSubmit}
      className="col-span-1 space-y-2"
      dir="ltr"
    >
      <div className="flex flex-row items-center gap-2 justify-between @md:justify-start">
        <h2>Status</h2>
        {!editing && (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 cursor-pointer hover:text-grass"
          />
        )}
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 ">
        <div className="flex flex-row items-center justify-between relative">
          <Label value="Status" />

          {editing ? (
            <Select
              sizing="sm"
              {...register("status")}
              color={errors.status?.message ? "failure" : undefined}
              disabled={loading}
            >
              {processStatuses &&
                processStatuses.map(stat => (
                  <option key={stat.id} value={stat.id}>
                    {stat.name}
                  </option>
                ))}
            </Select>
          ) : (
            <div className="flex flex-row items-center gap-1">
              <Badge
                style={{
                  backgroundColor: processStatus.color,
                  color: getContrast(processStatus.color),
                }}
                className="cursor-pointer"
                size="sm"
                onClick={toggleOpen}
              >
                {processStatus.name}
              </Badge>
              {loading && <Spinner size="xs" light />}
            </div>
          )}

          {open && (
            <ListGroup className="absolute top-[100%] mt-2 right-0 z-10">
              {processStatuses?.map(stat => (
                <ListGroup.Item
                  key={stat.id}
                  onClick={() => {
                    if (stat.id == processStatus.id) {
                      return;
                    }
                    changeStatus(stat.id);
                  }}
                >
                  <Badge
                    size="sm"
                    className="w-full"
                    style={{
                      backgroundColor: stat.color,
                      color: getContrast(stat.color),
                      opacity: stat.id == processStatus.id ? 0.5 : 1,
                    }}
                  >
                    {stat.name}
                  </Badge>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </div>
        <div className="flex flex-row items-center justify-between">
          <Label value="From" />
          {editing ? (
            <TextInput
              sizing="sm"
              {...register("from")}
              color={errors.from?.message ? "failure" : undefined}
              type="date"
            />
          ) : (
            <div>{from ? dayjs(from).format("DD/MM/YYYY") : "Nill"}</div>
          )}
        </div>
        <div className="flex flex-row items-center justify-between">
          <Label value="Due" />
          {editing ? (
            <TextInput
              sizing="sm"
              {...register("due")}
              color={errors.due?.message ? "failure" : undefined}
              type="date"
            />
          ) : (
            <div className={`${isPastDue ? "text-red-500" : ""}`}>
              {due ? dayjs(due).format("DD/MM/YYYY") : "Nill"}
            </div>
          )}
        </div>

        {editing && (
          <div className="flex justify-end flex-row items-center gap-2 mt-2">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              update
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
