import { gql } from "@apollo/client";
import { TEAM_CORE_FIELDS } from "../../settings/teams/fragments";
import { PROCESS_CORE_FIELDS } from "../../process/fragments";
import { PRODUCT_CORE_FIELDS } from "../../settings/products/fragments";
import { PRODUCTION_STATUS_CORE_FIELDS } from "../../settings/production/productionStatuses/fragments";

export const PRODUCTION_CORE_FIELDS = gql`
  ${TEAM_CORE_FIELDS}
  ${PROCESS_CORE_FIELDS}
  ${PRODUCT_CORE_FIELDS}
  ${PRODUCTION_STATUS_CORE_FIELDS}
  fragment ProductionCoreFields on productions {
    id
    user {
      id
      firstName
      sirName
      email
    }
    team {
      ...TeamCoreFields
    }
    process {
      ...ProcessCoreFields
    }
    product {
      ...ProductCoreFields
    }
    productionStatus {
      ...ProductionStatusCoreFields
    }
    qty
    done
    badge
    description
    due
    created_at
    progress
    deleted
    updated_at
  }
`;
