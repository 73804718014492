import { Label, TextInput } from "flowbite-react";
import { useFormContext } from "react-hook-form";

export default function Customer() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <section className="col-span-1 space-y-2 z-20">
      <h2>Customer</h2>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-2 ">
        <div className="space-y-2">
          <Label value="Name" />
          <TextInput
            type="text"
            placeholder="Enter Name"
            sizing="sm"
            {...register("name")}
            helperText={errors.name?.message?.toString() || ""}
            color={errors.name?.message ? "failure" : undefined}
          />
        </div>
        <div className="space-y-2">
          <Label value="Address" />
          <TextInput
            type="text"
            placeholder="Enter Address"
            sizing="sm"
            {...register("address")}
            helperText={errors.address?.message?.toString() || ""}
            color={errors.address?.message ? "failure" : undefined}
          />
        </div>
        <div className="space-y-2">
          <Label value="Post Code" />
          <TextInput
            type="text"
            placeholder="Enter Post Code"
            sizing="sm"
            {...register("postCode")}
            helperText={errors.postCode?.message?.toString() || ""}
            color={errors.postCode?.message ? "failure" : undefined}
          />
        </div>
      </div>
    </section>
  );
}
