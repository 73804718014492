import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADD_ORDER,
  GET_ORDER_BY_PK,
  GET_ORDER_STATUS,
  GET_ORDER_TYPES,
  GET_PRODUCT_SETS,
  UPDATE_ORDER_BY_PK,
} from "../gqls";
import {
  Badge,
  Breadcrumb,
  Button,
  Label,
  ListGroup,
  Modal,
  Select,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { HomeIcon } from "@heroicons/react/24/solid";
import { fullNumber } from "../../../../utils/fullNumber";
import { useAuthStore } from "../../../../store/authStore";
import checkAuth from "../../../../utils/checkAuth";
import getContrast from "../../../../utils/getContrast";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { GET_RELEASED_ORDERS_CONDITIONAL } from "../orderRelease/gqls";
import { OrderType, ProductSetOrderPreset } from "../types";
import { ReleasedOrderType } from "../orderRelease/types";
import { GET_PRODUCTION_BY_PK } from "../../production/gql";
import { useScheme } from "../../../../store/schemeStore";
import { useForm } from "react-hook-form";
import { addAlert } from "../../../../store/alertStore";
import { ProcessType } from "../../process/types";
import { createPathArr, exists, mkdir } from "../../../../utils/fileAPI";
import Folder from "../../shared/files/folder";

export default function OrderDetail() {
  const { id } = useParams();

  const { data, loading } = useQuery(GET_ORDER_BY_PK, {
    variables: {
      id,
    },
  });

  const order = data?.workorder_by_pk;

  const { data: product_data } = useQuery(GET_PRODUCT_SETS);
  const ProductSets = product_data?.productSets;

  const locations = order?.order.locations.map((l, i) => ({
    ...l,
    index: i + 1,
    openings: l.openings.map((o, ix) => ({
      ...o,
      items: o.items.map((item, iz) => ({ ...item, index: iz + 1 })),
      index: ix + 1,
    })),
  }));

  const openings = locations?.map(l => l.openings).flat();

  const products = openings?.reduce<
    { name: string; qty: number; openingProduct: boolean; img: string }[]
  >((prev, cur) => {
    const Product = ProductSets?.find(p => p.id == cur.product);
    if (!Product) {
      return prev;
    }

    const name = Product?.name;
    const isOpeningProduct = Product.hideItems;
    const qty = isOpeningProduct ? 1 : cur.items.length;

    if (prev.find(p => p.name == name)) {
      return prev.map(pro => {
        if (pro.name == name) {
          return { ...pro, qty: pro.qty + qty };
        } else return pro;
      });
    } else {
      return prev.concat({
        name,
        qty,
        openingProduct: Product.hideItems,
        img: Product.product.image,
      });
    }
  }, []);

  const navigate = useNavigate();

  const process = order?.process;

  const fullNum = process
    ? fullNumber(
        process.processType.prefix,
        process.year,
        process.number,
        process.salesRepUser
      )
    : "";

  const { user } = useAuthStore();

  const goToJob = () => {
    if (!process) {
      return;
    }
    const checkGroup = user?.team?.processTypes || [];
    checkAuth([
      "process_access_all",
      {
        permission: "process_access_self",
        checkGroup,
        conditionGroup: [process.processType.id],
      },
    ]) && navigate("/process/detail/" + process.id + "/prog");
  };

  const goToEdit = () => {
    navigate(`/order/edit/${process?.id}/${order?.id}`);
  };

  const { data: data_statuses } = useQuery(GET_ORDER_STATUS);
  const statuses = data_statuses?.workorderStatus;

  const [update_order, { loading: updating }] = useMutation(UPDATE_ORDER_BY_PK);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const changeStat = (id: number) => {
    if (!order) {
      return;
    }

    update_order({
      variables: {
        id: order.id,
        set: {
          statusId: id,
        },
      },
    });
    toggleOpen();
  };

  const { data: data_releasedOrders } = useQuery(
    GET_RELEASED_ORDERS_CONDITIONAL,
    {
      variables: {
        where: {
          orderId: { _eq: order?.id },
        },
      },
    }
  );

  const releasedOrders = data_releasedOrders?.releasedOrders;

  const productsWithPresets = openings?.reduce<
    {
      productId: number;
      id: number;
      name: string;
      qty: number;
      openingProduct: boolean;
      presets: ProductSetOrderPreset[];
    }[]
  >((prev, cur) => {
    const ProductSet = ProductSets?.find(p => p.id == cur.product);
    if (!ProductSet) {
      return prev;
    }

    const name = ProductSet?.name;
    const isOpeningProduct = ProductSet.hideItems;
    const qty = isOpeningProduct ? 1 : cur.items.length;

    if (prev.find(p => p.name == name)) {
      return prev.map(pro => {
        if (pro.name == name) {
          return { ...pro, qty: pro.qty + qty };
        } else return pro;
      });
    } else {
      return prev.concat({
        id: ProductSet.id,
        name,
        qty,
        openingProduct: ProductSet.hideItems,
        presets: ProductSet.orderPresets || [],
        productId: ProductSet.product.id,
      });
    }
  }, []);

  return (
    <main className="px-6 pb-10 select-none flex flex-col h-full">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("/order");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            ProMeasure
          </Breadcrumb.Item>
          <Breadcrumb.Item className="capitalize">{order?.id}</Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      {loading && !order && (
        <div className="flex-1 flex justify-center items-center">
          <Spinner size="xl" light color="purple" />
        </div>
      )}

      {order && (
        <article className="mt-3 mx-auto w-full max-w-[800px]">
          <div className="flex flex-row items-center gap-2">
            <h2 className="capitalize">{process?.name}</h2>
          </div>
          <div className="mt-2">
            <form
              // ref={ref}
              // onSubmit={onSubmit}
              className="space-y-2 flex flex-col"
            >
              <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
                <div className="flex flex-row justify-between items-center">
                  <Label value="Doc Id" />
                  {id}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Job Id" />
                  <div
                    className="cursor-pointer hover:text-plum"
                    onClick={goToJob}
                  >
                    {fullNum}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Ref" />
                  {order.orderRef || ""}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Type" />
                  <OrderTypeControl order={order} />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Status" />
                  <div className="relative" ref={ref}>
                    <Badge
                      style={{
                        color: getContrast(order.status.color),
                        backgroundColor: order.status.color,
                      }}
                      size="sm"
                      onClick={toggleOpen}
                      className={`w-fit hover:brightness-125 cursor-pointer ${
                        updating && "opacity-50"
                      }`}
                    >
                      {order.status.name}
                    </Badge>
                    {open && (
                      <ListGroup className="absolute right-0 z-10 mt-2">
                        {statuses
                          ?.filter(stat => stat.id !== order.status?.id)
                          .map(stat => (
                            <ListGroup.Item
                              onClick={() => {
                                changeStat(stat.id);
                              }}
                              key={stat.id}
                            >
                              <Badge
                                style={{
                                  backgroundColor: stat.color,
                                  color: getContrast(stat.color),
                                }}
                              >
                                {stat.name}
                              </Badge>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Due" />
                  {order.due ? dayjs(order.due).format("DD/MM/YYYY") : "Nill"}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Created" />
                  {order.created_at
                    ? dayjs(order.created_at).format("DD/MM/YYYY")
                    : "Nill"}
                </div>
                {/* included products */}
                <div className="flex flex-row justify-between items-center">
                  <Label value="Included Products" />
                  <div className="flex flex-row gap-2 items-center">
                    {products &&
                      products.length > 0 &&
                      products.map((product, i) => {
                        return (
                          <div
                            key={i}
                            className="flex flex-row items-center gap-1"
                          >
                            {product.img && (
                              <img
                                src={product.img}
                                className="w-5 dark:invert"
                              />
                            )}

                            <h4>{product.qty}</h4>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center capitalize">
                  <Label value="Created By" />
                  {order.user.firstName} {order.user.sirName}
                </div>
                <div className="flex flex-col gap-2">
                  <Label className="flex-1" value="Remarks" />
                  <Textarea
                    disabled={true}
                    style={{ opacity: 1 }}
                    className="resize-none text-sm"
                    value={order.description || ""}
                  />
                </div>

                {order.status.id > 1 && (
                  <div className="flex flex-col gap-2 my-1">
                    <div className="flex flex-row justify-center">
                      <h3>Order Production Stats</h3>
                    </div>

                    <div className="flex flex-col gap-2">
                      {productsWithPresets?.map(product => {
                        const openingReleased = releasedOrders?.find(
                          ro => ro.productSetId == product.id
                        );

                        return (
                          <div className="flex flex-col gap-2" key={product.id}>
                            <div className="font-semibold">{product.name}</div>
                            <div className="flex ml-2 flex-col gap-2">
                              {product.presets.map(preset => {
                                const released = releasedOrders?.find(
                                  ro =>
                                    ro.productSetId == product.id &&
                                    ro.presetName == preset.name
                                );

                                return (
                                  <div
                                    key={`${preset.productId}${preset.name}`}
                                    className="flex flex-row gap-2 items-center justify-between"
                                  >
                                    <Label value={preset.name} />
                                    <ProductionStatus released={released} />
                                  </div>
                                );
                              })}
                              {product.openingProduct && (
                                <div className="flex flex-row gap-2 items-center justify-between">
                                  <Label value={product.name} />
                                  <ProductionStatus
                                    released={openingReleased}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <Files id={Number(id)} process={order.process} />
                <div className="flex flex-row justify-end items-center gap-2 mt-1">
                  <CopyControl order={order} />
                  <Button
                    size="sm"
                    outline
                    gradientDuoTone="purpleToBlue"
                    onClick={goToEdit}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </article>
      )}
    </main>
  );
}

interface copyControl {
  order: OrderType;
}

const CopyControl = ({ order }: copyControl) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const { scheme } = useScheme();

  const defaultValues = {
    typeId: order.type.id,
    orderRef: order.orderRef,
  };

  const { data } = useQuery(GET_ORDER_TYPES);
  const types = data?.workorderType;

  const { register, reset, handleSubmit } = useForm({
    defaultValues,
  });

  const cancel = () => {
    setOpen(false);
    reset();
  };

  const { user } = useAuthStore();

  const [copyOrder, { loading }] = useMutation(ADD_ORDER);

  const navigate = useNavigate();

  const submit = handleSubmit(data => {
    const typeId = Number(data.typeId);

    const newOrder = {
      createdBy: user?.id,
      order: order.order,
      processId: order.process.id,
      typeId,
      orderRef: data.orderRef,
      statusId: 1,
      lastUpdateBy: user?.id,
    };
    copyOrder({
      variables: {
        object: newOrder,
      },
      onCompleted(data) {
        addAlert({
          message: "Order copied successfully",
          type: "success",
        });
        navigate(`/order/detail/${data.insert_workorder_one?.id}`);
      },
    });
  });

  return (
    <>
      <Button size="sm" color="purple" onClick={toggleOpen}>
        Copy
      </Button>
      <Modal
        show={open}
        onClose={cancel}
        className={`${scheme} ${scheme == "dark" && "bg-gray-800"}`}
      >
        <Modal.Header className={`${scheme == "dark" && "bg-gray-800"}`}>
          Copy Order
        </Modal.Header>
        <Modal.Body className={`${scheme == "dark" && "bg-gray-800"}`}>
          <div
            className={`${
              scheme == "dark" ? "text-white" : "text-black"
            } flex flex-col gap-2`}
          >
            Please select the type of order you want to copy.
            <Select sizing="sm" {...register("typeId", { required: true })}>
              {types?.map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Select>
            Ref (optional)
            <TextInput sizing="sm" {...register("orderRef")} />
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`${scheme == "dark" && "bg-gray-800"} justify-end gap-1`}
        >
          <Button size="sm" color="purple" onClick={cancel}>
            Cancel
          </Button>
          <Button
            outline
            size="sm"
            gradientDuoTone="purpleToBlue"
            onClick={submit}
          >
            <div className="flex flex-row gap-2 items-center">
              {loading && <Spinner size="sm" color="purple" />}
              Copy
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface productionStatus {
  released?: ReleasedOrderType;
}
const ProductionStatus = ({ released }: productionStatus) => {
  const { data } = useQuery(GET_PRODUCTION_BY_PK, {
    variables: {
      id: released?.productionId,
    },
  });

  const production = data?.productions_by_pk;

  const color = production ? production.productionStatus.color : "#CCCCCC";

  const navigate = useNavigate();
  const goToProduction = () => {
    navigate("/production/detail/" + production?.id);
  };
  return (
    <div
      className={`flex flex-1 flex-row gap-2 ${production && "cursor-pointer"}`}
      onClick={() => {
        production && goToProduction();
      }}
    >
      <div className="relative flex flex-row flex-1 justify-center items-center">
        {production && (
          <Badge className="capitalize z-10" color="purple">
            {production.team.name}
          </Badge>
        )}
        <hr className="absolute w-full border-dotted dark:border-gray-600" />
      </div>
      <Badge
        style={{
          color: getContrast(color),
          backgroundColor: color,
        }}
        className="capitalize"
      >
        {production ? production.productionStatus.name : "Not Assigned"}
      </Badge>
    </div>
  );
};

interface orderTypeControl {
  order: OrderType;
}

const OrderTypeControl = ({ order }: orderTypeControl) => {
  const { data: data_types } = useQuery(GET_ORDER_TYPES);
  const types = data_types?.workorderType;

  const [update_order, { loading: updating }] = useMutation(UPDATE_ORDER_BY_PK);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const changeType = (id: number) => {
    if (!order) {
      return;
    }

    update_order({
      variables: {
        id: order.id,
        set: {
          typeId: id,
        },
      },
    });
    toggleOpen();
  };

  return (
    <div className="relative">
      <Badge
        style={{
          color: getContrast(order.type.color),
          backgroundColor: order.type.color,
        }}
        size="sm"
        onClick={toggleOpen}
        className={`w-fit hover:brightness-125 cursor-pointer ${
          updating && "opacity-50"
        }`}
      >
        {order.type.name}
      </Badge>
      {open && (
        <ListGroup className="absolute right-0 z-10 mt-2">
          {types
            ?.filter(type => type.id !== order.type?.id)
            .map(stat => (
              <ListGroup.Item
                onClick={() => {
                  changeType(stat.id);
                }}
                key={stat.id}
              >
                <Badge
                  style={{
                    backgroundColor: stat.color,
                    color: getContrast(stat.color),
                  }}
                >
                  {stat.name}
                </Badge>
              </ListGroup.Item>
            ))}
        </ListGroup>
      )}
    </div>
  );
};

const Files = ({ process, id }: { process: ProcessType; id: number }) => {
  const [fileChecking, setFileChecking] = useState(false);
  const [path, setPath] = useState<undefined | string[]>(undefined);

  const initFiles = async () => {
    if (!process) {
      return;
    }

    const jobPath = createPathArr(process);

    if (!jobPath) {
      return;
    }

    const myPath = jobPath.concat("proMeasure", id.toString());

    if (myPath == path) {
      return;
    }

    setFileChecking(true);

    const checkPath = await exists(myPath);

    if (!checkPath) {
      await mkdir(myPath);
      setFileChecking(false);
    } else {
      setFileChecking(false);
    }
    setPath(myPath);
  };

  useEffect(() => {
    initFiles();
  }, [id]);

  return (
    <>
      {/* Files */}
      <div className="flex flex-col gap-2">
        <Label value="Files (diagram, pictures, etc..)" />
        <div className="dark:bg-gray-700 bg-gray-100 rounded-md px-2 py-3 border-[1px] dark:border-gray-600 border-gray-300">
          {fileChecking && <Spinner color="purple" />}
          {path && !fileChecking && <Folder path={path} simple={true} />}
        </div>
      </div>
    </>
  );
};
