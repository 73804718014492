import { ListGroup } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";

interface props {
  optionCoord: string;
  optionValue: any;
}

export default function Multiple({ optionValue, optionCoord }: props) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const { register, control, setValue, getValues } = useFormContext();

  const optionValuesQty = useWatch({
    control,
    name: `${optionCoord}.valuesQty`,
  });

  const { fields, replace } = useFieldArray({
    name: `${optionCoord}.value`,
    control,
  });

  useEffect(() => {
    const newOptions = Array(optionValuesQty).fill(0);

    if (
      !Array.isArray(optionValue) ||
      newOptions?.length !== optionValue.length
    ) {
      replace(newOptions);
    } else {
      replace(optionValue);
    }
  }, []);

  const nullishValues = [undefined, null, "", "0", 0];

  useEffect(() => {
    const currentDisplayValue = getValues(`${optionCoord}.displayValue`);
    if (
      !optionValue ||
      !Array.isArray(optionValue) ||
      optionValue.filter(v => !nullishValues.includes(v)).length < 1
    ) {
      if (currentDisplayValue !== "None") {
        setValue(`${optionCoord}.displayValue`, "None");
      }
      return;
    }
    if (currentDisplayValue !== optionValue.join(", ")) {
      setValue(`${optionCoord}.displayValue`, optionValue.join(", "));
    }
  }, [JSON.stringify(optionValue)]);

  return (
    <div className="relative" ref={ref}>
      <ListGroup onClick={toggleOpen}>
        <ListGroup.Item>
          {optionValue &&
          Array.isArray(optionValue) &&
          optionValue.filter(v => !nullishValues.includes(v)).length > 0 ? (
            <div className="text-xs">
              {optionValue.filter(v => !nullishValues.includes(v)).length}
            </div>
          ) : (
            <div className="text-xs">None</div>
          )}
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 flex flex-col gap-1 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
          {fields?.map((val, i) => {
            return (
              <div
                key={val.id}
                className="group/preset relative flex flex-row gap-2 items-center px-2 py-1"
              >
                <input
                  className="w-20 bg-transparent text-xs py-1"
                  type="number"
                  {...register(`${optionCoord}.value[${i}]`)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
