import { useState } from "react";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid";
// import { useMutation } from "@apollo/client";
// import { ADD_QUOTE_PARAM, UPDATE_QUOTE_PARAM_BY_PK } from "../gqls";
import { useForm } from "react-hook-form";
// import { QUOTE_PARAMS_FIELDS } from "../fragments";
import { QuoteParams } from "../types";
import CustomProductSalesParams from "./customProductSalesParams";

interface props {
  params: QuoteParams[];
}
export default function CustomProductInstallParams({ params }: props) {
  const [extended, setExtended] = useState(false);

  const existingSalesParams = params.find(
    p => p.name == "customProductSalesParams"
  );
  // const exInstallParam = params.find(p => p.name == "customProductInstall");
  // const exInstallPrice = exInstallParam?.value || "";

  // const defaultValues = {
  //   install: exInstallPrice,
  // };

  // const { getValues, reset } = useForm({
  //   defaultValues,
  // });

  // const [update, { loading: updating }] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);
  // const [insert, { loading: inserting }] = useMutation(ADD_QUOTE_PARAM);

  // const loading = updating || inserting;

  // const updateBase = () => {
  //   const installValue = getValues("install");
  //   if (exInstallParam) {
  //     update({
  //       variables: {
  //         id: exInstallParam.id,
  //         set: {
  //           value: installValue,
  //         },
  //       },
  //       onCompleted() {
  //         reset({
  //           install: installValue,
  //         });
  //       },
  //     });
  //   } else {
  //     insert({
  //       variables: {
  //         object: {
  //           name: "customProductInstall",
  //           value: installValue,
  //         },
  //       },
  //       onCompleted() {
  //         reset({
  //           install: installValue,
  //         });
  //       },
  //       update(cache, { data: { insert_quoteParams_one: newParam } }) {
  //         cache.modify({
  //           fields: {
  //             quoteParams(existing = []) {
  //               const newParamRef = cache.writeFragment({
  //                 data: newParam,
  //                 fragment: QUOTE_PARAMS_FIELDS,
  //                 fragmentName: "QuoteParamsFields",
  //               });
  //               return [...existing, newParamRef];
  //             },
  //           },
  //         });
  //       },
  //     });
  //   }
  // };

  // const cancel = () => {
  //   reset({
  //     install: defaultValues.install,
  //   });
  // };

  return (
    <div className="bg-gray-100 dark:bg-gray-600 flex flex-col rounded-md ">
      <div
        onClick={() => {
          setExtended(!extended);
        }}
        className="p-3 bg-gray-100 dark:bg-gray-600 flex flex-row items-center justify-between rounded-md cursor-pointer hover:brightness-125"
      >
        <div className="font-semibold text-xl capitalize">Custom</div>

        {extended ? (
          <ChevronDoubleUpIcon className="w-5" />
        ) : (
          <ChevronDoubleDownIcon className="w-5" />
        )}
      </div>
      {extended && (
        <div className="p-3 m-3 flex flex-col gap-1 rounded-md bg-white dark:bg-gray-800 drop-shadow-md">
          <CustomProductSalesParams existingSalesParams={existingSalesParams} />
        </div>
      )}
    </div>
  );
}
