import { useEffect, useRef, useState } from "react";
import { getSchedule } from "../../../utils/calendarMethods";
import { Event } from "../../../types/calendarTypes";
import { Modal } from "flowbite-react";
import Item from "../shared/schedules/item";
import { useScheme } from "../../../store/schemeStore";
import { useOnClickOutside } from "usehooks-ts";
import { useQuery } from "@apollo/client";
import { GET_PROCESS_CONDITIONAL } from "../process/processDetail/gqls";

interface props {
  schedule?: string;
  cb?: () => void;
}

export default function Detail({ schedule, cb }: props) {
  const [event, setEvent] = useState<null | Event>(null);

  const load = async () => {
    if (!schedule) {
      return setEvent(null);
    }
    try {
      const _event = await getSchedule(schedule);
      setEvent(_event);
    } catch (error) {
      console.log(error);
      setEvent(null);
    }
  };

  useEffect(() => {
    load();
    return () => {
      setEvent(null);
    };
  }, [schedule]);

  const { scheme } = useScheme();

  const onClose = () => {
    setEvent(null);
    if (cb) {
      cb();
    }
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    onClose();
  });

  const { data } = useQuery(GET_PROCESS_CONDITIONAL, {
    variables: {
      where: {
        schedules: {
          _contains: schedule,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const process = data?.process && data?.process[0];

  return (
    <Modal
      show={event ? true : false}
      onClose={onClose}
      className={`${scheme} ${scheme == "dark" && "text-white"} `}
    >
      <div className="mt-16 md:mt-0" ref={ref}>
        {event && (
          <Item
            displayProcessLink={true}
            expanded={true}
            schedule={event}
            process={process}
          />
        )}
      </div>
    </Modal>
  );
}
