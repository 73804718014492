import NameAvatar from "./nameAvatar";
import IdInfo from "./ids";
import Basic from "./basic";
import CalId from "./calId";
import ChangePassword from "./changePassword";
import Color from "./color";
import Devices from "./devices";
import Optional from "./optional";

export default function Profile() {
  return (
    <main className="flex flex-col px-6 pb-10 select-none">
      <div className="flex flex-col items-center mt-10">
        {/* Avatar, Colour */}
        <section className="flex flex-col relative items-center">
          <Color />
          <NameAvatar />
        </section>

        {/* Row 1 */}
        <section className="flex flex-row flex-wrap justify-betwen mt-10 w-[768px] max-w-full gap-8">
          {/* Email, Name Edit */}
          <IdInfo />
          {/* Basic Info */}
          <Basic />
        </section>
        {/* Row 2 */}
        <section className="flex flex-row flex-wrap justify-between w-[768px] mb-5 max-w-full">
          <Optional />
        </section>
        {/* Row 3 */}
        <section className="flex flex-row flex-wrap justify-between w-[768px] mb-5 max-w-full">
          <CalId />
        </section>
        {/* Row 4 */}
        <section className="flex flex-row flex-wrap justify-between w-[768px] mb-5 max-w-full">
          <Devices />
        </section>
        {/* Row 5 */}
        <section className="flex flex-row flex-wrap justify-between w-[768px] mb-5 max-w-full">
          <ChangePassword />
        </section>
      </div>
    </main>
  );
}
