import { Checkbox, ListGroup } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";
import { option } from "../../../types";

interface props {
  optionCoord: string;
}

interface multiValue {
  name: string;
  checked: boolean;
  value: number;
}

export default function MultiNameNumber({ optionCoord }: props) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const { setValue, control } = useFormContext();

  const option: option = useWatch({
    name: optionCoord,
    control,
  });

  const value = option.value as multiValue[];

  const multiOptions = () => {
    const options = option.values?.map(v => ({
      name: v,
      checked: false,
      value: 0,
    }));
    return options;
  };

  useEffect(() => {
    const newOptions = multiOptions();
    if (
      !Array.isArray(option.value) ||
      newOptions?.length !== option.value.length
    ) {
      setValue(`${optionCoord}.value`, newOptions);
    }
  }, []);

  const updateArrVal = (
    name: string,
    where: string,
    newVal: boolean | number
  ) => {
    let newValues = value || [];

    if (!Array.isArray(newValues)) {
      return;
    }

    newValues = newValues.map(val => {
      if (val.name == name) {
        return { ...val, [where]: newVal };
      } else {
        return val;
      }
    });

    setValue(`${optionCoord}.value`, newValues);
  };

  useEffect(() => {
    if (!option.value || !Array.isArray(option.value)) {
      setValue(`${optionCoord}.displayValue`, "None");
      return;
    }
    if (
      option.value.filter(
        // @ts-ignore
        v => v.checked
      ).length == 0
    ) {
      setValue(`${optionCoord}.displayValue`, "None");
      return;
    }
    setValue(
      `${optionCoord}.displayValue`,
      option.value
        // @ts-ignore
        .filter(
          // @ts-ignore
          v => v.checked
        )
        // @ts-ignore
        .map(val => `${val.name}`)
        .join(", ")
    );
  }, [JSON.stringify(option.value)]);

  // const noValue =
  //   value && Array.isArray(value) && value?.filter(v => v.checked).length == 0;

  return (
    <div className="relative" ref={ref}>
      <ListGroup onClick={toggleOpen}>
        <ListGroup.Item>
          <div className={`text-xs`}>{option.displayValue}</div>
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 flex flex-col bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
          {value?.map(val => {
            return (
              <div
                key={val.name}
                className="group/preset relative flex flex-row gap-2 items-center px-2 py-2"
              >
                <div
                  className="flex flex-row gap-2 items-center"
                  onClick={() => {
                    updateArrVal(val.name, "checked", !val.checked);
                  }}
                >
                  <Checkbox
                    readOnly
                    className="checked:bg-plum dark:checked:bg-plum"
                    checked={val.checked}
                  />
                  <div className="rounded-md cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                    {val.name}
                  </div>
                </div>
                <input
                  value={val.value}
                  type="number"
                  onChange={e => {
                    const newVal = Number(e.target.value);
                    updateArrVal(val.name, "value", newVal);
                  }}
                  onKeyDown={e => {
                    if (e.key == "ArrowUp" || e.key == "ArrowDown")
                      e.preventDefault();
                  }}
                  className="border-[1px] p-1 text-xs w-16"
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
