import { curtainLabel } from "./types";

interface props {
  label: curtainLabel;
  orderNumber: string;
  customer?: string;
}

export default function CurtainLabel({ label, orderNumber, customer }: props) {
  const {
    location,
    drop,
    hem,
    fabricW,
    curtainW,
    fabricH,
    curtainH,
    side,
    hoistCut,
    hooks,
    hookType,
    lining,
    careCode,
    heading,
    fabric,
    width,
    height,
    ctrl,
    itemNum,
  } = label;

  return (
    <div className="w-1/3 border-r-[1px] border-black px-2 py-5">
      <div className="text-lg flex flex-row justify-between">
        <span>Order No: {orderNumber}</span>
        <span>#{itemNum}</span>
      </div>
      <div className="text-lg truncate">Client: {customer}</div>
      <div className="text-lg flex flex-row justify-between">
        <span>Loc: {location}</span>
        <span>
          {width}x{height}
        </span>
      </div>

      <div className="text-lg truncate">Fabric: {fabric}</div>
      <div className="text-lg flex flex-row justify-between">
        <span>Drop (RR) QTY: {!!Number(drop) ? Number(drop) : "N/A"}</span>
        <span>Care: {careCode == "" ? <span>_____</span> : careCode}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span className="text-xl font-semibold text-blue-500">
          Hem: {!!Number(hem) ? Number(hem) / 2 : "Lead"}
        </span>
        <span className="text-lg">Ctrl: {ctrl}</span>
      </div>
      <div className="text-lg font-semibold">Heading: {heading}</div>
      <div className="flex flex-row justify-between">
        <span className="text-red-500 text-xl font-semibold flex-1">
          FW: {String(fabricW)}
          {side ? ` (${side})` : ""}
        </span>
        <span className="text-lg flex-1">
          Cur W: {String(curtainW)}
          {side ? ` (${side})` : ""}
        </span>
      </div>
      <div className="text-lg flex flex-row justify-between">
        <span className="flex-1">Fabric H: {fabricH}</span>
        <span className="flex-1">Cur H: {String(curtainH)}</span>
      </div>
      <div className="text-xl font-semibold text-red-500">
        Hoist Cut: {hoistCut}
      </div>
      <div className="text-lg flex flex-row justify-between">
        <span>Hook Qty: {hooks}</span>
        {hookType !== "" && <span>Hook Type: {hookType}</span>}
      </div>
      <div className="text-lg">Lining: {lining}</div>
    </div>
  );
}
