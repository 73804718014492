import { GET_MATERIALS_CONDITIONAL } from "../../materials/gqls";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CheckIcon,
  DocumentDuplicateIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  Badge,
  Button,
  FileInput,
  Select,
  Table,
  TextInput,
} from "flowbite-react";
import { Fragment, useEffect, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { sort } from "fast-sort";
import { useScheme } from "../../../../store/schemeStore";
import {
  ADD_LAYOUT,
  DELETE_LAYOUT,
  GET_DEDUCTIONS_CONDITIONAL,
  GET_DEDUCTION_PRESETS_CONDITIONAL,
  GET_LAYOUTS,
  GET_PRODUCT_OPTIONS_CONDITIONAL,
  GET_PRODUCT_SETS,
  UPDATE_LAYOUT_BY_PK,
} from "../../workorder/gqls";
import { createPortal } from "react-dom";
import {
  LayoutType,
  layoutConfig,
  layoutScheme,
  newLayoutType,
  optionValues,
} from "../../workorder/types";
import { LAYOUT_CORE_FIELDS } from "../../workorder/fragments";
import ValuePopulate from "../../workorder/ValuePopulate";
import getBase64 from "../../../../utils/getBase64";
import { useOrderStore } from "../../../../store/orderStore";

interface option {
  option: number;
  value: number | string;
  displayValue?: string;
}

export default function Layouts() {
  const { data: data_layouts, loading } = useQuery(GET_LAYOUTS);
  const [filter, setFilter] = useState("");
  const layouts = data_layouts
    ? data_layouts.layouts.filter(
        layout =>
          filter.trim() == "" ||
          `${layout.name} ${layout.productSet.name}`
            .toLowerCase()
            .includes(filter.trim().toLocaleLowerCase())
      )
    : [];

  const { data: data_products } = useQuery(GET_PRODUCT_SETS);

  const [insert, { loading: inserting }] = useMutation(ADD_LAYOUT);

  const defaultValues: newLayoutType = {
    name: "",
    for: 0,
    code: "",
    config: [],
    image: "",
    parent: [],
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(layoutScheme),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const productSetID: number | null = useWatch({
    name: "for",
    control,
  });

  const onSubmit = handleSubmit(async data => {
    insert({
      variables: {
        object: {
          ...data,
        },
      },
      onCompleted() {
        reset();
        setAdding(false);
      },
      onError(error) {
        console.log(error);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            layouts(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_layouts_one,
                fragment: LAYOUT_CORE_FIELDS,
                fragmentName: "LayoutCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const cancel = () => {
    reset();
    setAdding(!adding);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-end items-center gap-2">
        <TextInput
          onChange={e => setFilter(e.target.value)}
          value={filter}
          placeholder="search"
          type="search"
          name="searchMaterial"
          autoComplete="off"
          icon={MagnifyingGlassIcon}
          sizing="sm"
        />
        {!adding && (
          <Button
            gradientDuoTone="purpleToBlue"
            outline
            size="xs"
            onClick={toggleAdding}
          >
            new
          </Button>
        )}
      </div>
      {adding && (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className="flex flex-col mb-2 gap-2">
            <div className="flex flex-row flex-wrap gap-2 items-center">
              <Select {...register("for")} sizing="sm">
                <option value={0}>Select product</option>
                {data_products?.productSets.map(p => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </Select>
              <TextInput
                {...register("name")}
                sizing="sm"
                placeholder="Name"
                className="flex-1 min-w-[100px]"
              />
              <TextInput
                {...register("code")}
                sizing="sm"
                placeholder="Code"
                className="flex-1 min-w-[100px]"
              />
            </div>
            {productSetID ? (
              <>
                <h4 className="font-semibold dark:text-white">Configs</h4>
                <Configs productSetID={productSetID} />
                <hr />
                <h4 className="font-semibold dark:text-white">Conditions</h4>
                <Parent productSetID={productSetID} />
                <h4 className="font-semibold dark:text-white">Image</h4>
                <GroupImage />
                <hr />
              </>
            ) : null}
            <div className="flex flex-row justify-end items-center gap-2">
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                outline
                type="submit"
              >
                Add
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      <Table striped className="select-text">
        <Table.Head>
          <Table.HeadCell className="px-2">id</Table.HeadCell>
          <Table.HeadCell className="px-2">product</Table.HeadCell>
          <Table.HeadCell className="px-2">name</Table.HeadCell>
          <Table.HeadCell className="px-2">code</Table.HeadCell>
          <Table.HeadCell className="px-2">config</Table.HeadCell>
          <Table.HeadCell className="px-2">conditions</Table.HeadCell>
          <Table.HeadCell className="px-2">image</Table.HeadCell>
          <Table.HeadCell className="p-0" />
        </Table.Head>
        <Table.Body className="text-black dark:text-white">
          {layouts &&
            sort(layouts)
              .by([
                { asc: d => d.productSet.name },
                { asc: d => d.code },
                { asc: d => d.name },
              ])
              .map(item => <Layout key={item.id} item={item} />)}
        </Table.Body>
      </Table>
    </div>
  );
}

interface config {
  productSetID: number;
}

interface layoutProp {
  item: LayoutType;
}

const Layout = ({ item }: layoutProp) => {
  const { scheme } = useScheme();
  const [update, { loading: updating }] = useMutation(UPDATE_LAYOUT_BY_PK);

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: item.productSet?.id },
      },
    },
  });

  const productSetOptions = data_options?.productSetOptions;

  const optionTypes = ["select", "text", "number"];
  const options = productSetOptions
    ? sort(productSetOptions)
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const defaultValues: newLayoutType = {
    name: item.name,
    for: item.productSet.id,
    code: item.code,
    config: item.config,
    image: item.image,
    parent: item.parent,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(layoutScheme),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = methods;

  const [remove, { loading: deleting }] = useMutation(DELETE_LAYOUT);
  const [insert, { loading: inserting }] = useMutation(ADD_LAYOUT);

  const _delete = (id: number) => {
    const proceed = confirm("delete group?");
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: "layouts",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const [editing, setEditing] = useState(false);

  const save = handleSubmit(data => {
    update({
      variables: {
        id: item.id,
        set: {
          ...data,
        },
      },
      onCompleted() {
        setEditing(false);
      },
    });
  });

  const copy = handleSubmit(data => {
    const proceed = confirm(
      `copy current group${isDirty ? " with modified values" : ""}?`
    );
    if (!proceed) {
      return;
    }

    insert({
      variables: {
        object: {
          ...data,
          id: undefined,
        },
      },
      onCompleted() {
        reset();
        setEditing(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            layouts(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_layouts_one,
                fragment: LAYOUT_CORE_FIELDS,
                fragmentName: "LayoutCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const cancelEdit = () => {
    reset();
    setEditing(false);
  };

  return (
    <FormProvider {...methods}>
      <Table.Row key={item.id}>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500 w-4">
          {item.id}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.productSet?.name}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.name}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.code}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          <div className="flex flex-col gap-2 w-full">
            {item.config.map(conf => (
              <div key={conf.num} className="flex flex-row gap-2 items-center">
                <Badge color="success">{conf.num}</Badge>
                {conf.config.map(c => {
                  const option = options.find(o => o.id == c.option);
                  // const isPresetOption = option?.source == "deductionPreset"
                  return (
                    <Badge key={c.option} color="purple">
                      <div className="flex flex-row gap-1 items-center">
                        {option?.name}:{" "}
                        <ValuePopulate
                          source={option?.source}
                          value={c.value}
                        />
                      </div>
                    </Badge>
                  );
                })}
              </div>
            ))}
          </div>
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          <div className="flex flex-row gap-2 w-full flex-wrap">
            {item.parent?.map(po => (
              <Badge key={po.option} color="purple">
                <div className="flex flex-row gap-1 items-center">
                  {po.type || "check"}:
                  {options.find(o => o.id == po.option)?.name}
                  {po.values && (
                    <>
                      :
                      {po.values?.map(val => (
                        <Fragment key={val}>
                          <ValuePopulate
                            source={
                              options.find(o => o.id == po.option)?.source
                            }
                            value={val}
                          />
                          ,
                        </Fragment>
                      ))}
                    </>
                  )}
                </div>
              </Badge>
            ))}
          </div>
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.image ? <CheckIcon className="w-4 text-grass" /> : ""}
        </Table.Cell>
        <Table.Cell className="py-[5px] w-10">
          <div className="flex flex-row gap-3 w-full">
            <PencilIcon
              onClick={() => {
                setEditing(true);
              }}
              className="w-5 text-grass cursor-pointer"
            />
            <DocumentDuplicateIcon
              onClick={copy}
              className="w-5 text-plum cursor-pointer"
            />
          </div>
        </Table.Cell>
      </Table.Row>
      {/* Edit Deduction */}
      {editing && (
        <>
          {createPortal(
            <div
              className={`${scheme} absolute w-screen h-screen top-0 left-0 p-10 backdrop-blur-xl flex flex-col gap-2 overflow-x-hidden`}
            >
              <h3>Edit Group</h3>
              <div className="flex flex-row gap-2 flex-wrap">
                <Select addon="Product" sizing="sm" disabled>
                  <option value="">{item.productSet.name}</option>
                </Select>
              </div>
              <div className="flex flex-row gap-2 flex-wrap">
                <TextInput
                  {...register("name")}
                  sizing="sm"
                  placeholder="Enter Name"
                  addon="Name"
                />
                <TextInput
                  {...register("code")}
                  sizing="sm"
                  addon="code"
                  placeholder="Code"
                  className="w-32"
                />
              </div>
              {item.productSet.id ? (
                <>
                  <h4 className="font-semibold dark:text-white">Configs</h4>
                  <Configs productSetID={item.productSet.id} />
                  <hr />
                  <h4 className="font-semibold dark:text-white">Conditions</h4>
                  <Parent productSetID={item.productSet.id} />
                  <h4 className="font-semibold dark:text-white">Image</h4>
                  <GroupImage />
                </>
              ) : null}
              <hr />
              <div className="flex flex-row justify-between items-center gap-2">
                <Button
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                  outline
                  onClick={copy}
                >
                  Copy
                </Button>
                <div className="flex flex-row items-center gap-2">
                  <TrashIcon
                    onClick={() => {
                      _delete(item.id);
                    }}
                    className="w-5 text-red-500 cursor-pointer"
                  />
                  <Button size="xs" color="purple" onClick={cancelEdit}>
                    cancel
                  </Button>
                  <Button
                    size="xs"
                    gradientDuoTone="purpleToBlue"
                    outline
                    onClick={save}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>,
            document.body
          )}
        </>
      )}
    </FormProvider>
  );
};

const Configs = ({ productSetID }: config) => {
  const { scheme } = useScheme();
  const { control } = useFormContext();
  const { append, remove } = useFieldArray({
    name: "config",
    control,
  });
  const addedConfigs: layoutConfig[] = useWatch({
    name: "config",
    control,
  });

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: Number(productSetID) },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const defaultValues: layoutConfig = {
    num: 0,
    config: [],
  };

  const [newConfig, setNewConfig] = useState(defaultValues);

  const optionTypes = ["select", "text", "number"];
  const options = data_options
    ? sort(data_options.productSetOptions)
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const defaultOption = {
    option: 0,
    value: "",
    displayValue: undefined,
  };

  const nullishValues = ["", null, 0];

  const [newOption, setNewOption] = useState<option>(defaultOption);

  const selectedOption = options?.find(o => o.id == newOption.option);
  const [selectables, setSelectables] = useState<any[]>([]);

  const { presets, deductions, materials } = useOrderStore();

  useEffect(() => {
    if (selectedOption?.source == "deductionPreset") {
      const _presets = presets.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_presets);
    }
    if (selectedOption?.source == "deductions") {
      const _deductions = deductions.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_deductions);
    }
    if (selectedOption?.source == "inventory") {
      const _materials = materials.filter(cd => {
        if (selectedOption.optionCode) {
          return cd.optionCode == selectedOption.optionCode;
        } else {
          return selectedOption.values && selectedOption.values.includes(cd.id);
        }
      });
      setSelectables(_materials);
    }
    if (!selectedOption?.source && selectedOption?.type == "select") {
      // @ts-ignore
      setSelectables(selectedOption.values);
    }
  }, [selectedOption]);

  const addOption = () => {
    if (
      nullishValues.includes(newOption.value) ||
      nullishValues.includes(newOption.option)
    ) {
      return;
    }
    setNewConfig({
      ...newConfig,
      config: newConfig.config.concat(newOption),
    });
    setNewOption(defaultOption);
  };

  const add = () => {
    if (addedConfigs.find(c => c.num == newConfig.num)) {
      return;
    }
    append(newConfig);
    setNewConfig({ ...defaultValues, num: newConfig.num + 1 });
  };

  const removeOption = (option: number) => {
    setNewConfig({
      ...newConfig,
      config: newConfig.config.filter(c => c.option !== option),
    });
  };

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Config */}
      <div className="flex flex-row gap-2 items-center">
        <Select
          addon="Option"
          sizing="sm"
          value={newOption.option}
          onChange={e => {
            setNewOption({
              ...newOption,
              option: Number(e.target.value),
            });
          }}
        >
          <option value="">select option</option>
          {options
            ?.filter(o => !newConfig.config.find(c => c.option == o.id))
            .map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </Select>
        {selectedOption && selectedOption.type == "select" ? (
          <Select
            addon="Value"
            sizing="sm"
            value={newOption.value}
            onChange={e => {
              const selected = selectables.find(
                s => s.id?.toString() == e.target.value.toString()
              );

              const displayValue =
                selected?.name &&
                `${
                  selectedOption.source == "deductionPreset"
                    ? `${selected?.desc} `
                    : ""
                }${selected?.name}`;

              setNewOption({
                ...newOption,
                value: e.target.value,
                displayValue,
              });
            }}
          >
            <option value="">select option</option>
            {selectables?.map((option, i) => (
              <option key={option.id || i} value={option.id || option}>
                {selectedOption.source == "deductionPreset"
                  ? `${option.desc} `
                  : ""}
                {option.name || option}
              </option>
            ))}
          </Select>
        ) : (
          <TextInput
            sizing="sm"
            addon="Value"
            placeholder="Enter Value"
            value={newOption.value}
            type={`${selectedOption?.type == "number" ? "number" : "text"}`}
            onChange={e => {
              const value = e.target.value;

              setNewOption({
                ...newOption,
                value: selectedOption?.type == "number" ? Number(value) : value,
              });
            }}
          />
        )}
        <PlusCircleIcon
          className="w-6 text-grass cursor-pointer"
          onClick={addOption}
        />
      </div>
      <div className="flex flex-row flex-wrap items-center gap-2 justify-between">
        <div className="flex flex-row flex-wrap gap-2 items-center">
          <TextInput
            sizing="sm"
            addon="#"
            placeholder="Config Place"
            className="w-20"
            type="number"
            value={newConfig.num}
            onChange={e => {
              setNewConfig({ ...newConfig, num: Number(e.target.value) });
            }}
          />
          {newConfig.config.map(c => (
            <Badge
              key={c.option}
              color="purple"
              className="cursor-pointer"
              onClick={() => {
                removeOption(c.option);
              }}
            >
              <div className="flex flex-row gap-1 items-center">
                {options.find(o => o.id == c.option)?.name}:{" "}
                {c.displayValue || c.value}
                <XMarkIcon className="w-4" />
              </div>
            </Badge>
          ))}
        </div>

        <Button onClick={add} outline size="xs" gradientDuoTone="greenToBlue">
          add config
        </Button>
      </div>
      {/* Added Configs */}
      {addedConfigs.length > 0 && (
        <div className="flex flex-col dark:text-white">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-t-md flex flex-row gap-2 items-center">
            <div className="px-2 py-[5px] w-20">Num</div>
            <div className="px-2 py-[5px] flex-1">Configs</div>
            <div className="px-2 py-[5px] w-10" />
          </div>
          {addedConfigs.map((item, index) => (
            <div
              key={item.num}
              className="rounded-md flex flex-row gap-2 items-center"
            >
              <div className="px-2 py-[5px] w-20 overflow-clip">
                {Number(item.num)}
              </div>
              <div className="px-2 py-[5px] flex-1 flex flex-row gap-2 justify-start">
                {item.config.map(c => {
                  const option = options.find(o => o.id == c.option);

                  return (
                    <Badge
                      key={c.option}
                      color="purple"
                      className="cursor-pointer"
                    >
                      <div className="flex flex-row gap-1 items-center">
                        {option?.name}: {c.displayValue || c.value}
                      </div>
                    </Badge>
                  );
                })}
              </div>

              <div className="px-2 py-[5px] w-10 flex flex-row gap-2">
                <MinusCircleIcon
                  className="text-red-500 w-5 cursor-pointer"
                  onClick={() => {
                    remove(index);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Parent = ({ productSetID }: config) => {
  const { scheme } = useScheme();
  const { control } = useFormContext();
  const { append, remove } = useFieldArray({
    name: "parent",
    control,
  });
  const addedOptions: optionValues[] = useWatch({
    name: "parent",
    control,
  });

  const [getInv] = useLazyQuery(GET_MATERIALS_CONDITIONAL);
  const [getDeductions] = useLazyQuery(GET_DEDUCTIONS_CONDITIONAL);
  const [getPresets] = useLazyQuery(GET_DEDUCTION_PRESETS_CONDITIONAL);

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: Number(productSetID) },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const defaultValues: optionValues = {
    option: 0,
    type: "",
    values: [],
  };

  const [newParentOption, setNewParentOption] = useState(defaultValues);

  const optionTypes = ["select"];
  const options = data_options
    ? sort(data_options.productSetOptions)
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const nullishValues = ["", null, 0, undefined];

  const selectedOption = options?.find(o => o.id == newParentOption.option);
  const [selectables, setSelectables] = useState<any[]>([]);

  useEffect(() => {
    if (selectedOption?.source == "deductionPreset") {
      if (selectedOption.optionCode) {
        getPresets({
          variables: {
            where: { optionCode: { _eq: selectedOption.optionCode } },
          },
          onCompleted(data) {
            if (data.deductionPreset) {
              setSelectables(data.deductionPreset);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
      if (selectedOption.values && selectedOption.values?.length > 0) {
        getPresets({
          variables: {
            where: { id: { _in: selectedOption.values } },
          },
          onCompleted(data) {
            if (data.deductionPreset) {
              setSelectables(data.deductionPreset);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
    }
    if (selectedOption?.source == "deductions") {
      if (selectedOption.optionCode) {
        getDeductions({
          variables: {
            where: { optionCode: { _eq: selectedOption.optionCode } },
          },
          onCompleted(data) {
            if (data.deductions) {
              setSelectables(data.deductions);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
      if (selectedOption.values && selectedOption.values?.length > 0) {
        getDeductions({
          variables: {
            where: { id: { _in: selectedOption.values } },
          },
          onCompleted(data) {
            if (data.deductions) {
              setSelectables(data.deductions);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
    }
    if (selectedOption?.source == "inventory") {
      if (selectedOption.optionCode) {
        getInv({
          variables: {
            where: { optionCode: { _eq: selectedOption.optionCode } },
          },
          onCompleted(data) {
            if (data.materials) {
              setSelectables(data.materials);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
      if (selectedOption.values && selectedOption.values?.length > 0) {
        getInv({
          variables: {
            where: { id: { _in: selectedOption.values } },
          },
          onCompleted(data) {
            if (data.materials) {
              setSelectables(data.materials);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
    }
    if (!selectedOption?.source && selectedOption?.type == "select") {
      // @ts-ignore
      setSelectables(selectedOption.values);
    }
  }, [selectedOption]);

  const add = () => {
    if (nullishValues.includes(newParentOption.type)) {
      return;
    }
    if (addedOptions.find(c => c.option == newParentOption.option)) {
      return;
    }
    if (newParentOption.type == "check" && newParentOption.values.length < 1) {
      return;
    }
    append(newParentOption);
    setNewParentOption(defaultValues);
  };

  const removeValue = (val: number | string) => {
    setNewParentOption({
      ...newParentOption,
      values: newParentOption.values.filter(c => c !== val),
    });
  };

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Config */}
      <div className="flex flex-row gap-2 items-center">
        <Select
          addon="Option"
          sizing="sm"
          value={newParentOption.option?.toString()}
          disabled={newParentOption.values.length > 0 ? true : false}
          onChange={e => {
            setNewParentOption({
              ...newParentOption,
              option: Number(e.target.value),
            });
          }}
        >
          <option value="">select option</option>
          {options
            ?.filter(
              o =>
                !addedOptions.find(c => c.option.toString() == o.id.toString())
            )
            .map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </Select>
        <Select
          addon="Type"
          sizing="sm"
          value={newParentOption.type}
          onChange={e => {
            setNewParentOption({
              ...newParentOption,
              type: e.target.value,
            });
          }}
        >
          <option value="">select option</option>
          <option>check</option>
          <option>equal</option>
        </Select>
        {newParentOption.type == "check" &&
          selectedOption &&
          selectedOption.type == "select" && (
            <Select
              addon="Value"
              sizing="sm"
              onChange={e => {
                if (nullishValues.includes(e.target.value)) {
                  return;
                }
                setNewParentOption({
                  ...newParentOption,
                  values: newParentOption.values.concat(e.target.value),
                });
              }}
            >
              <option value="">select option</option>
              {selectables
                ?.filter(
                  option =>
                    !newParentOption.values.includes(
                      option?.id?.toString() || option.toString()
                    )
                )
                .map((option, i) => (
                  <option key={option.id || i} value={option.id || option}>
                    {option.name || option}
                  </option>
                ))}
            </Select>
          )}
      </div>
      <div className="flex flex-row flex-wrap items-center gap-2 justify-between">
        <div className="flex flex-row flex-wrap gap-2 items-center">
          {newParentOption.values.map((c, i) => {
            const option = options.find(o => o.id == newParentOption.option);
            const source = option?.source;
            const value = c;
            return (
              <Badge
                key={c}
                color="purple"
                className="cursor-pointer"
                onClick={() => {
                  removeValue(c);
                }}
              >
                <div className="flex flex-row gap-1 items-center">
                  <ValuePopulate source={source} value={value} />
                  <XMarkIcon className="w-4" />
                </div>
              </Badge>
            );
          })}
        </div>

        <Button onClick={add} outline size="xs" gradientDuoTone="greenToBlue">
          add condition
        </Button>
      </div>
      {/* Added Parent Options */}
      {addedOptions.length > 0 && (
        <div className="flex flex-col dark:text-white">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-t-md flex flex-row gap-2 items-center">
            <div className="px-2 py-[5px] w-20">Config</div>
            <div className="px-2 py-[5px] w-20">Type</div>
            <div className="px-2 py-[5px] flex-1">Values</div>
            <div className="px-2 py-[5px] w-10" />
          </div>
          {addedOptions.map((item, index) => {
            const option = options.find(o => o.id == item.option);
            const source = option?.source;
            return (
              <div
                key={item.option}
                className="rounded-md flex flex-row gap-2 items-center"
              >
                <div className="px-2 py-[5px] w-20 overflow-clip">
                  {options.find(o => o.id == item.option)?.name}
                </div>
                <div className="px-2 py-[5px] w-20 overflow-clip">
                  {item.type}
                </div>
                <div className="px-2 py-[5px] flex-1 flex flex-row gap-2 justify-start">
                  {item.values?.map(value => (
                    <Badge
                      key={value}
                      color="purple"
                      className="cursor-pointer"
                    >
                      <ValuePopulate source={source} value={value} />
                    </Badge>
                  ))}
                </div>

                <div className="px-2 py-[5px] w-10 flex flex-row gap-2">
                  <MinusCircleIcon
                    className="text-red-500 w-5 cursor-pointer"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const GroupImage = () => {
  const {
    control,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useFormContext();

  const imageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors("image");

    const files = event.target.files;

    if (files) {
      const file = files[0];
      if (!file) {
        return;
      }
      const { size } = file;

      if (size / 1000 > 50) {
        setError("image", { type: "custom", message: "file size too big" });
        event.target.value = "";
        return;
      }

      const base64 = (await getBase64(file)) as string;
      setValue("image", base64);
    }
  };

  const image = useWatch({
    name: "image",
    control,
  });

  const clear = () => {
    setValue("image", null);
  };

  return (
    <div className="flex flex-col gap-2">
      {image && (
        <div className="p-10 flex flex-row justify-center relative">
          <img src={image} className="w-100 max-w-full dark:invert" />
          <TrashIcon
            className="absolute w-5 text-red-500 cursor-pointer right-0 top-0"
            onClick={clear}
          />
        </div>
      )}
      <div>
        <FileInput
          id="image"
          accept="image/*"
          color={errors.image?.message ? "failure" : undefined}
          helperText={
            errors.image?.message?.toString() ||
            "upload product image less than 50kb size"
          }
          className="mt-2 text-sm"
          onChange={imageHandler}
        />
      </div>
    </div>
  );
};
