import { useEffect } from "react";
import { useLazyQuery, gql, TypedDocumentNode } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import { Label, Select, Spinner, TextInput } from "flowbite-react";
import { RELATED_CONFIGS } from "../types";
import checkAuth from "../../../../utils/checkAuth";
import { addAlert } from "../../../../store/alertStore";

interface LAST_NUM {
  process: {
    number: number;
  }[];
}

export const GET_PROCESS_LAST_NUM: TypedDocumentNode<LAST_NUM> = gql`
  query GET_PROCESS_LAST_NUM($typeId: Int!, $year: numeric!) {
    process(
      where: { _and: [{ typeId: { _eq: $typeId } }, { year: { _eq: $year } }] }
      order_by: { number: desc }
      limit: 1
    ) {
      id
      number
    }
  }
`;

export default function Ids({
  data,
  loading,
  teamProcess,
}: {
  data?: RELATED_CONFIGS;
  loading: boolean;
  teamProcess?: number[];
}) {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const typeId = watch("typeId");
  const year = watch("year");

  const [getLastNum, { loading: loading_lastNum }] = useLazyQuery(
    GET_PROCESS_LAST_NUM,
    {
      fetchPolicy: "network-only",
    }
  );

  const types = data
    ? data.processType.filter(pt =>
        checkAuth([
          "add_process_any_type",
          {
            permission: "add_process",
            checkGroup: teamProcess || [],
            conditionGroup: [pt.id],
          },
        ])
      )
    : false;

  useEffect(() => {
    if (!typeId) {
      return;
    }
    getLastNum({
      variables: {
        typeId,
        year: year || new Date().getFullYear(),
      },
      fetchPolicy: "no-cache",
      onCompleted(data) {
        let newNum;
        if (data.process && data.process.length > 0) {
          newNum = data.process[0].number + 1;
        } else {
          newNum = 1;
        }
        setValue("number", newNum);
      },
      onError(err) {
        console.log(err);
        addAlert({ message: "could not load last number", type: "failure" });
      },
    });
  }, [typeId, year]);

  const today = new Date();

  let years = [];

  for (let i = 2014; i <= today.getFullYear() + 1; i++) {
    years.push(i);
  }

  return (
    <section className="col-span-1 space-y-2 z-20">
      <h2>IDs</h2>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-2 ">
        <div className="space-y-2">
          <Label value="Type" />
          <Select
            sizing="sm"
            {...register("typeId")}
            color={errors.typeId?.message ? "failure" : undefined}
            disabled={loading}
          >
            {types &&
              types.map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
          </Select>
        </div>
        <div className="space-y-2">
          <Label value="Year" />
          <Select
            sizing="sm"
            {...register("year")}
            color={errors.year?.message ? "failure" : undefined}
          >
            {years.map(y => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </Select>
        </div>
        <div className="space-y-2">
          <Label value="Number" />
          <div className="relative">
            <TextInput
              type="number"
              sizing="sm"
              {...register("number")}
              disabled={loading || loading_lastNum}
              className="flex-1"
              color={errors.number?.message ? "failure" : undefined}
            />
            {(loading || loading_lastNum) && (
              <Spinner
                size="sm"
                className="absolute m-auto top-0 bottom-0 right-2"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
