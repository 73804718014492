import { useScheme } from "../../../../../store/schemeStore";
import { PolylineF, PolylineProps } from "@react-google-maps/api";

export interface myLineProps extends Omit<PolylineProps, "options"> {}

export default function Line(props: myLineProps) {
  const { scheme } = useScheme();

  const options = {
    strokeColor: scheme == "dark" ? "#FFFFFF" : "#000000",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: scheme == "dark" ? "#FFFFFF" : "#000000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
  };
  return <PolylineF {...props} options={options} />;
}
