import { useNavStore } from "../../../store/navStore";
import {
  Bars2Icon,
  SunIcon,
  BellIcon,
  BellSlashIcon,
  Cog6ToothIcon,
  MoonIcon,
  MagnifyingGlassIcon,
  ArrowsPointingOutIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { useScheme } from "../../../store/schemeStore";
import { useNavigate } from "react-router-dom";
import { ListGroup, Spinner, TextInput } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useSearchStore } from "../../../store/searchStore";
import { useDebounce, useOnClickOutside } from "usehooks-ts";
import checkAuth from "../../../utils/checkAuth";
import { useNotificationStore } from "../../../store/notificationStore";
import Notifications from "./notifications";
import Toasts from "../../Toasts";

export default function Header({ scrollPosition }: { scrollPosition: number }) {
  const { show, setShow, folded } = useNavStore();
  const { token, notifications } = useNotificationStore();

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
    setSearchResults([]);
  });

  const toggleNav = () => {
    setShow(!show);
  };

  const { scheme, setScheme } = useScheme();
  const toggleScheme = () => {
    const newScheme = scheme === "dark" ? "light" : "dark";
    setScheme(newScheme);
  };

  const navigate = useNavigate();

  const [focused, setFocused] = useState(false);

  // Connect Search Function

  const {
    searchString,
    setSearchString,
    searchFunction,
    searchResults,
    loading,
    setSearchResults,
  } = useSearchStore();
  const debouncedStr = useDebounce<string>(searchString, 500);

  useEffect(() => {
    const checkStr = debouncedStr.replaceAll(" ", "");
    if (checkStr !== "" && searchFunction !== undefined) {
      const str = debouncedStr.toLowerCase();

      searchFunction(str);
    }
  }, [debouncedStr]);

  const invert = scrollPosition > 5;

  const clearSearch = () => {
    setSearchResults([]);
    setSearchString("");
  };

  const goBack = () => {
    window.history.back();
  };
  const goForward = () => {
    window.history.forward();
  };

  return (
    <header
      ref={ref}
      className={`flex flex-row p-4 justify-between items-center h-[62px] sticky top-0 z-50 border-b-[1px] transition-all duration-100 ${
        invert
          ? "dark:bg-gray-800 dark:border-gray-600 bg-white"
          : "border-transparent"
      }`}
    >
      <Toasts />
      {/* Left */}
      <section className="flex flex-row items-center select-none">
        {/* nav toggle */}
        <div className="flex flex-row items-center">
          <div
            className="w-8 cursor-pointer rounded-sm p-1 hover:bg-gray-200 hover:dark:bg-gray-600"
            onClick={toggleNav}
          >
            <Bars2Icon />
          </div>
          <div
            className={`items-center ${focused ? "hidden" : "flex flex-row"}`}
          >
            <div
              className="w-7 cursor-pointer rounded-sm p-1 hover:bg-gray-200 hover:dark:bg-gray-600"
              onClick={goBack}
            >
              <ChevronLeftIcon />
            </div>
            <div
              className="w-7 cursor-pointer rounded-sm p-1 hover:bg-gray-200 hover:dark:bg-gray-600"
              onClick={goForward}
            >
              <ChevronRightIcon />
            </div>
          </div>
        </div>

        {/* <span className="ml-2 uppercase font-bold select-none text-xl">
          {routeName ? routeName : ""}
        </span> */}
      </section>

      {/* Right */}
      <section
        className={`relative flex flex-1 justify-end transition-all duration-300 flex-row items-center space-x-1 select-none ${
          !folded && show ? "hidden md:flex" : ""
        }`}
      >
        {searchFunction !== undefined && (
          <div className="relative max-w-max">
            {loading && (
              <Spinner
                size="sm"
                light
                color="purple"
                className="absolute my-auto top-0 bottom-0 z-10 right-2"
              />
            )}
            <TextInput
              className={` max-w-max transition-all duration-300 ${
                focused ? "w-72" : "w-10"
              }`}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setFocused(false);
                  clearSearch();
                }, 300);
              }}
              // type="search"
              name="headerSearch"
              autoComplete="off"
              sizing={focused ? "sm" : "xs"}
              icon={MagnifyingGlassIcon}
              value={searchString}
              onChange={e => {
                const str = e.target.value;
                setSearchString(str);
              }}
            />
            {searchResults.length > 0 && focused && (
              <ListGroup className="absolute mt-2 w-full max-h-[calc(100vh-100px)] overflow-y-scroll md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700">
                {searchResults.map((res, i) => (
                  <ListGroup.Item
                    key={i}
                    onClick={() => {
                      if (res.link) {
                        navigate(res.link);
                      }
                    }}
                  >
                    <div className="flex flex-col items-start">
                      <div>{res.header}</div>
                      <div className="text-left">{res.desc}</div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </div>
        )}

        <div
          className={`flex flex-row spzce-x-1 ${
            focused ? "hidden md:flex" : ""
          }`}
        >
          <div
            onClick={toggleOpen}
            className="relative w-8 cursor-pointer rounded-sm p-1 hover:bg-gray-200 hover:dark:bg-gray-600"
          >
            {token ? <BellIcon /> : <BellSlashIcon />}
            {notifications.length > 0 && (
              <div className="absolute p-1 bg-red-500 rounded-full top-1 right-[5px] animate-[pulse_1s_ease-in-out]" />
            )}
          </div>
          {open && (
            <div className="w-96 max-w-full absolute top-[100%] mt-2 z-50 right-0">
              <Notifications />
            </div>
          )}
          <div
            className="w-8 cursor-pointer rounded-sm p-1 hover:bg-gray-200 hover:dark:bg-gray-600"
            onClick={toggleScheme}
          >
            {scheme === "dark" ? <SunIcon /> : <MoonIcon />}
          </div>
          {checkAuth("setting_access") && (
            <div
              onClick={() => {
                navigate("settings");
              }}
              className="w-8 cursor-pointer rounded-sm p-1 hover:bg-gray-200 hover:dark:bg-gray-600"
            >
              <Cog6ToothIcon />
            </div>
          )}
          <div
            className="w-8 cursor-pointer rounded-sm p-1 hover:bg-gray-200 hover:dark:bg-gray-600"
            onClick={() => {
              if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
              } else if (document.exitFullscreen) {
                document.exitFullscreen();
              }
            }}
          >
            <ArrowsPointingOutIcon />
          </div>
        </div>
      </section>
    </header>
  );
}
