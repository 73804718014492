import { Button, Label, TextInput, Checkbox, Spinner } from "flowbite-react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Link } from "react-router-dom";
import { useAuthStore, useLogin } from "../../store/authStore";
import { useNavigate } from "react-router-dom";

const userSchema = z.object({
  email: z.string().email({ message: "Please input a valid email" }),
  password: z.string().min(1, { message: "Please input password" }),
  persist: z.boolean().optional(),
});

export default function Login() {
  const { loggingIn } = useAuthStore(state => state);

  // initialize login form

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(userSchema),
    defaultValues: {
      email: "",
      password: "",
      persist: true,
    },
  });

  const navigate = useNavigate();

  const login = useLogin();

  const onSubmit = handleSubmit(async data => {
    const res: Boolean = await login(data);
    if (res) {
      reset();
      navigate("/");
    }
  });

  return (
    <div className="h-full flex flex-col items-center">
      <div className="font-bold text-2xl mt-20 mb-10 select-none">Login</div>
      <form
        onSubmit={onSubmit}
        className="w-[576px] max-w-[80%] shadow-md bg-white dark:bg-gray-800 rounded-md p-8  flex flex-col gap-4"
      >
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Email" />
          </div>
          <TextInput
            type="email"
            placeholder="name@example.com"
            color={errors.email?.message ? "failure" : undefined}
            helperText={errors.email?.message || ""}
            {...register("email")}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="Password" />
          </div>
          <TextInput
            type="password"
            placeholder="password here"
            color={errors.password?.message ? "failure" : undefined}
            helperText={errors.password?.message || ""}
            {...register("password")}
          />
        </div>

        <div className="flex items-center gap-2 cursor-pointer">
          <Checkbox
            className="cursor-pointer"
            id="persist"
            {...register("persist")}
          />
          <Label htmlFor="persist" className="select-none cursor-pointer">
            Keep me logged in
          </Label>
        </div>

        <div className="flex gap-2 justify-end mt-5">
          <Link to="/register" relative="path">
            <Button gradientDuoTone="purpleToBlue" size="sm">
              Register
            </Button>
          </Link>
          <Button
            outline={true}
            gradientDuoTone="purpleToBlue"
            size="sm"
            type="submit"
          >
            {loggingIn && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Login
          </Button>
        </div>
      </form>

      <div className="w-[576px] max-w-[80%] text-sm italic mr-1 cursor-pointer text-right text-gray-700 dark:text-gray-200 mt-2 pr-1 mb-20">
        <Link to="/recover" relative="path" className="select-none">
          Forgot your password?
        </Link>
      </div>
    </div>
  );
}
