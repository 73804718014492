import { useForm } from "react-hook-form";
import ProductSetWarningControls from "./productSetWarningControls";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useScheme } from "../../../../../../store/schemeStore";
import { ProductSetType } from "../../../../workorder/types";

export default function NewProductSetWarning({
  add,
  productSetId,
}: {
  add: (warning: ProductSetType["warnings"][number]) => void;
  productSetId: ProductSetType["id"];
}) {
  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      where: "",
      condition: "",
      message: "",
    },
  });

  const [open, setOpen] = useState(false);

  const { scheme } = useScheme();

  const close = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit(warning => {
    add(warning);
    close();
  });

  return (
    <>
      <PlusCircleIcon
        className="w-5 cursor-pointer text-plum"
        onClick={() => {
          setOpen(true);
        }}
      />
      <Modal show={open} onClose={() => setOpen(false)} className={scheme}>
        <section className="p-4 flex flex-col gap-2">
          <h2>New Warning</h2>
          <div className="flex flex-col gap-2">
            <ProductSetWarningControls
              productSetId={productSetId}
              registerWhere={register("where")}
              registerCondition={register("condition")}
              registerMessage={register("message")}
            />
          </div>
          <div className="flex flex-row justify-end items-center gap-2">
            <Button onClick={close} type="button" size="xs" color="purple">
              Cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              outline
              onClick={onSubmit}
            >
              Add
            </Button>
          </div>
        </section>
      </Modal>
    </>
  );
}
