import { Button, Spinner } from "flowbite-react";
import Heading from "../../../comps/heading";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import { pullSchedule } from "../../../utils/calendarMethods";
import { useScheduleStore, view } from "../../../store/scheduleStore";
import Filters from "./filters";
import { CalendarType } from "../../../types/calendarTypes";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/sass/styles.scss";
import moment from "moment";
import "./schedule.css";
import CustomToolbar from "./toolbar";
import DynamicHeroIcon from "../../../comps/hIcon";
import dayjs from "dayjs";
import { useScheme } from "../../../store/schemeStore";
import { UserType } from "../settings/users/types";
import getContrast from "../../../utils/getContrast";
import MyAgenda from "./myAgendaView/myAgendaView";
import Detail from "./detail";
import NewSchedule from "./newSchedule";

const localizer = momentLocalizer(moment); // or globalizeLocalizer

export default function SchedulesLoader() {
  const [filtering, setFiltering] = useState(false);
  const { filters, calendars, setCalendars, view, setView, date, setDate } =
    useScheduleStore();

  const userFilter = filters.userFilter;

  const toggleFiltering = () => {
    setFiltering(!filtering);
  };

  const add = () => {
    setAdding(true);
  };

  const [loading, setLoading] = useState(false);

  const load = async () => {
    setLoading(true);

    const missingCals = await Promise.all(
      userFilter
        .filter(u => !calendars.find(cal => cal.user?.calId == u.calId))
        .map(async user => {
          const data = await pullSchedule({
            user,
          });

          return data;
        })
    );
    const filteredExistingCals = calendars.filter(cal =>
      userFilter.find(u => u.calId == cal.user?.calId)
    );

    const finalCals = missingCals
      .concat(filteredExistingCals)
      .filter(cal => cal !== undefined) as CalendarType[];

    setCalendars(finalCals);

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, [filters]);

  const myEventsList = calendars
    .map(cal => cal.events)
    .flat()
    .filter(e => e?.status && e.status !== "cancelled")
    .map(e => ({
      title: `${e.summary}${
        calendars.length > 1
          ? ` - ${e.user?.firstName[0]}${e.user?.sirName[0]}`.toUpperCase()
          : ""
      }`,
      start: new Date(e.start as string),
      end: new Date(e.end as string),
      allDay: e.type == "date" ? true : false,
      resource: e,
    }));

  const components = useMemo(
    () => ({
      toolbar: CustomToolbar,
    }),
    []
  );

  const formats = useMemo(
    () => ({
      agendaTimeRangeFormat: (range: { start: Date; end: Date }) => {
        return `${dayjs(range.start).format("hh:mm")}-${dayjs(range.end).format(
          "hh:mm"
        )}`;
      },
    }),
    []
  );

  const views: view[] = ["agenda", "month", "week"];
  const calendarViews = useMemo(
    () => ({
      month: true,
      week: true,
      day: true,
      agenda: MyAgenda,
    }),
    []
  );

  const [prevView, setPrevView] = useState<view | null>(null);

  const nextView = () => {
    if (prevView) {
      setPrevView(null);
      return setView(prevView);
    }
    const currentIndex = views.findIndex(v => v == view);
    setView(views[currentIndex + 1] || "agenda");
  };

  const { scheme } = useScheme();

  const [selectedSchedule, setSelctedSchedule] = useState<undefined | string>(
    undefined
  );

  const onSelectEvent = useCallback((event: any) => {
    try {
      const scheduleString = `${event.resource.calId}::${event.resource.id}`;
      setSelctedSchedule(scheduleString);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onDrillDown = useCallback(
    (date: Date) => {
      setDate(date);
      setPrevView(view);
      setView("day");
    },
    [view]
  );

  const eventPropGetter = useCallback(
    (event: any) => {
      const user = event.resource?.user as UserType;
      if (!user || view == "agenda") {
        return {};
      }
      return {
        style: {
          backgroundColor: user.color,
          color: getContrast(user.color as string),
        },
      };
    },
    [view]
  );

  const [adding, setAdding] = useState(false);

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6">
        <div className="flex flew-row justify-between h-8">
          <div className="flex flex-row items-center gap-2">
            <Heading />
          </div>

          <Button
            size="sm"
            outline
            gradientDuoTone="purpleToBlue"
            onClick={add}
          >
            ADD
          </Button>
        </div>
        <div className="flex flex-row text-grass mb-10">calendar, events</div>

        {/* Actions */}
        <div className="flex flex-row gap-2 flex-wrap justify-between items-center h-8">
          <div className="flex flex-row gap-2 items-center">
            <div
              onClick={toggleFiltering}
              className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center leading-[0]"
            >
              <AdjustmentsHorizontalIcon className="w-4" />
              Filters
            </div>
          </div>

          <div className="flex flex-row justify-end items-center gap-2 flex-1 h-8">
            {loading && <Spinner light color="purple" />}
            <div
              className="hover:text-grass cursor-pointer capitalize flex flex-row gap-1 items-center leading-[0]"
              onClick={nextView}
            >
              <DynamicHeroIcon
                icon={
                  view == "month"
                    ? "CalendarDaysIcon"
                    : view == "agenda"
                    ? "QueueListIcon"
                    : view == "week"
                    ? "ViewColumnsIcon"
                    : "SunIcon"
                }
                class="w-4"
              />
              {view}
            </div>
          </div>
        </div>
      </div>
      {/* Filter */}
      <Filters filtering={filtering} setFiltering={setFiltering} />
      {/* Content Part */}
      <section className="mt-2 pb-6  px-6">
        <div className="h-[calc(100vh-85px)] select-text">
          <Calendar
            className={`${scheme} curView-${view}`}
            localizer={localizer}
            events={myEventsList}
            startAccessor="start"
            drilldownView="day"
            endAccessor="end"
            components={components}
            formats={formats}
            views={calendarViews}
            view={view}
            onView={() => {}}
            date={date}
            onNavigate={d => setDate(d)}
            popup={true}
            // selectable={true}
            // onSelectSlot={onSelectSlot}
            onDrillDown={onDrillDown}
            onSelectEvent={onSelectEvent}
            eventPropGetter={eventPropGetter}
            longPressThreshold={20}
          />
        </div>
      </section>
      {selectedSchedule && (
        <Detail
          schedule={selectedSchedule}
          cb={() => {
            setSelctedSchedule(undefined);
          }}
        />
      )}
      {adding && (
        <NewSchedule
          adding={adding}
          cb={() => {
            setAdding(false);
          }}
        />
      )}
    </main>
  );
}
