import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { sort } from "fast-sort";
import { uniqueById } from "../../../../utils/arrayObjMethods";
import AddTask from "../../shared/task/addTask";
import { TASK_CORE_FIELDS } from "../../shared/task/fragments";
import Task from "../../shared/task/task";
import { TaskArrayType, TaskStreamType } from "../../shared/task/types";
import { processDetailSubProps } from "../types";

export const GET_PROCESS_TASKS: TypedDocumentNode<TaskArrayType> = gql`
  ${TASK_CORE_FIELDS}
  query GET_PROCESS_TASKS($processID: Int!) {
    tasks(where: { processID: { _eq: $processID }, deleted: { _eq: false } }) {
      ...TaskCoreFields
    }
  }
`;

export const SUB_PROCESS_TASKS: TypedDocumentNode<TaskStreamType> = gql`
  ${TASK_CORE_FIELDS}
  subscription SUB_PROCESS_TASKS(
    $processID: Int!
    $cursor: [tasks_stream_cursor_input]!
  ) {
    tasks_stream(
      where: { processID: { _eq: $processID } }
      cursor: $cursor
      batch_size: 10
    ) {
      ...TaskCoreFields
    }
  }
`;

export default function ProcessTasks({ process }: processDetailSubProps) {
  const { data, loading, error, subscribeToMore } = useQuery(
    GET_PROCESS_TASKS,
    {
      variables: {
        processID: process.id,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  if (error) {
    console.log(error);
  }

  const tasks = data?.tasks;

  subscribeToMore({
    document: SUB_PROCESS_TASKS,
    variables: {
      processID: process.id,
      cursor: {
        initial_value: { updated_at: new Date().toISOString() },
        ordering: "ASC",
      },
    },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.tasks_stream;

      const existing = prev.tasks.filter(
        p => !newFeedItem.find(i => i.id == p.id)
      );

      const filterDeleted = [...newFeedItem, ...existing].filter(
        log => !log.deleted
      );

      return Object.assign({}, prev, {
        tasks: uniqueById(filterDeleted),
      });
    },
  });

  return (
    <div
      className="col-span-1 
      @lg:row-span-6 supports-[not(container-type:inline-size)]:lg:row-span-6
     flex flex-col gap-2"
    >
      <h2 className="mb-2">Tasks</h2>
      <AddTask processID={process.id} />
      {tasks &&
        sort(tasks)
          .asc(t => t.taskStatus.priority)
          .map(task => <Task key={task.id} task={task} />)}
    </div>
  );
}
