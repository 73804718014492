import { Select, TextInput } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import { useQuoteStore } from "../../../../../../store/quoteStore";
import { useEffect } from "react";
import { installSalesParams } from "../../../../settings/quote/types";
import CheckboxWithLabel from "../../../../../../comps/CheckboxWithLabel";

interface props {
  coord: string;
  productSetId: number;
  showPrice: boolean;
}
export default function QuoteOptions({
  coord,
  productSetId,
  showPrice,
}: props) {
  const { register, setValue, control, getValues } = useFormContext();

  const paramsCoord = `${coord}.salesParams`;
  const { quoteParams } = useQuoteStore();

  const dbInstallParam = quoteParams.find(
    p => p.name == "salesParams" && p.productSetId == productSetId
  );

  const installParams: installSalesParams[] = useWatch({
    name: `${paramsCoord}.installParams`,
    control,
  });

  useEffect(() => {
    // console.log(installParams);
    // console.log(dbInstallParam);
    if (!installParams && dbInstallParam?.value) {
      const installParams = dbInstallParam?.value as installSalesParams[];
      setValue(
        `${paramsCoord}.installParams`,
        installParams.map(param => ({
          ...param,
          value: param.default,
        }))
      );
    }
  }, [installParams, dbInstallParam]);

  const supOnly = useWatch({
    name: `${paramsCoord}.supplyOnly`,
    control,
    defaultValue: false,
  });

  const orderType = getValues("type");

  const isRetail = orderType?.id !== 4;

  if (!showPrice || !isRetail) {
    return null;
  }

  return (
    <>
      <div className={`relative mr-1 group/option w-20  my-[5px] `}>
        <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
          Discount
        </div>
        <TextInput
          addon="$"
          className="w-full"
          {...register(`${paramsCoord}.discount`)}
          type="number"
          placeholder="0"
          sizing="sm"
        />
      </div>
      <div className={`relative mr-1 group/option w-20  my-[5px] `}>
        <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
          Sup-Only
        </div>
        <Select sizing="sm" {...register(`${paramsCoord}.supplyOnly`)}>
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </div>
      {!Number(supOnly) &&
        installParams?.map((param, i) => (
          <ItemInstallParam key={param.name} index={i} coord={paramsCoord} />
        ))}
    </>
  );
}
const ItemInstallParam = ({
  index,
  coord,
}: {
  index: number;
  coord: string;
}) => {
  const { control, setValue } = useFormContext();
  const param = useWatch({
    name: `${coord}.installParams.${index}`,
    control,
  });
  return (
    <CheckboxWithLabel
      className="mr-1"
      name={param.name}
      checked={param.value || false}
      onChange={() => {
        setValue(`${coord}.installParams.${index}.value`, !param.value);
      }}
    />
  );
};
