import { Label, TextInput } from "flowbite-react";
import { addDays } from "../../../utils/dateMethods";
import dayjs from "dayjs";
import { useEffect } from "react";
import { RecordFilter } from "./types";

interface props {
  setNew: (revisedFilter: RecordFilter) => void;
  filter: RecordFilter;
}

const FilterRangeType = ({ setNew, filter }: props) => {
  const rangeType = filter.rangeType || "number";

  if (filter.values?.from == undefined || filter.values?.to == undefined) {
    return null;
  }

  const from =
    rangeType == "date"
      ? dayjs(filter.values?.from).format("YYYY-MM-DD")
      : filter.values?.from;

  const to =
    rangeType == "date"
      ? dayjs(filter.values?.to).format("YYYY-MM-DD")
      : filter.values?.to;

  const handleChange = (where: string, value: string) => {
    if (rangeType == "date") {
      if (where == "from" && dayjs(value).isAfter(dayjs(to))) {
        return;
      }
      if (where == "to" && dayjs(value).isBefore(dayjs(from))) {
        return;
      }
    } else {
      if ((where == "from" && value > to) || (where == "to" && value < from)) {
        return;
      }
    }

    setNew({
      ...filter,
      values: {
        ...filter.values,
        [where]: value,
      },
    });
  };

  return (
    <div className="flex flex-row flex-wrap gap-2 ">
      {rangeType == "month" ? (
        <>
          <TextInput
            addon="Month"
            type={rangeType}
            value={dayjs(from).add(1, "month").format("YYYY-MM")}
            onChange={e => {
              setNew({
                ...filter,
                values: {
                  ...filter.values,
                  from: dayjs(e.target.value)
                    .subtract(1, "month")
                    .format("YYYY-MM"),
                  to: dayjs(e.target.value).add(1, "month").format("YYYY-MM"),
                },
              });
            }}
            sizing="sm"
          />
        </>
      ) : (
        <>
          <div className="space-y-2 flex-1 shadow-md bg-white dark:bg-gray-800 rounded-md col-span-1 z-20">
            <TextInput
              addon="From"
              type={rangeType}
              value={from}
              onChange={e => {
                handleChange("from", e.target.value);
              }}
              sizing="sm"
            />
          </div>
          <div className="space-y-2 flex-1 shadow-md bg-white dark:bg-gray-800 rounded-md col-span-1 z-20">
            <TextInput
              addon="To"
              type={rangeType}
              value={to}
              onChange={e => {
                handleChange("to", e.target.value);
              }}
              sizing="sm"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FilterRangeType;
