import { z } from "zod";

export const productSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  description: z
    .string()
    .min(2, { message: "description cannot be empty" })
    .trim(),
  image: z.string(),
});

export interface NewProductType {
  name: string;
  description: string;
  image: string;
}

export interface ProductType extends NewProductType {
  id: number;
}

export interface ProductArrayType {
  products: ProductType[];
}
