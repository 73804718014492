import { z } from "zod";

type HEX = `#${string}`;

export const paymentStatusSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  description: z
    .string()
    .min(2, { message: "description cannot be empty" })
    .trim(),
  color: z.string(),
  // priority: z.number(),
});

export interface NewPaymentStatusType {
  name: string;
  description?: string;
  color: HEX;
  priority: number;
  new?: boolean;
}

export interface PaymentStatusType extends NewPaymentStatusType {
  id: number;
}

export interface PaymentStatusArrayType {
  paymentStatus: PaymentStatusType[];
}
