import { Sidebar } from "flowbite-react";
import { SidebarItemProps } from "flowbite-react/lib/esm/components/Sidebar";
import { useNavStore } from "../../../store/navStore";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../../../store/authStore";
import { useScreen } from "usehooks-ts";

function haveMatches(arr1: string[], arr2: string[]) {
  return arr1.some(item => arr2.includes(item));
}

interface ISidebarProps extends SidebarItemProps {
  name: string;
  // onClick?: () => void;
  route?: string[];
  auths?: string[];
  // icon?: React.SVGProps<SVGSVGElement>;
}

export default function SidebarItem(props: ISidebarProps) {
  const screen = useScreen();

  const { user } = useAuthStore();

  const { auths } = props;

  const { folded, setShow } = useNavStore();

  const navigate = useNavigate();
  const location = useLocation();
  let route = "";

  if (props.route?.length) {
    route = props.route[0];
  }

  const selected =
    route &&
    location.pathname
      .split("/")[1]
      .toLocaleLowerCase()
      .includes(route.toLowerCase());

  const _onClick = (e: any) => {
    if (props.route?.length) {
      navigate(route);
    }
    if (screen && screen.width < 640) {
      setShow(false);
    }
    props.onClick && props.onClick(e);
  };

  const permissions = user?.permissions?.map(p => p.name);

  if (auths && (!permissions || !haveMatches(permissions, auths))) {
    return null;
  } else {
    return (
      <Sidebar.Item
        className={`cursor-pointer select-none ${
          selected ? "bg-slate-200 dark:bg-gray-700" : ""
        }`}
        {...props}
        onClick={_onClick}
      >
        <span className="font-medium capitalize">{!folded && props.name}</span>
      </Sidebar.Item>
    );
  }
}
