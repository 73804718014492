import { create } from "zustand";

type Store = {
  scheme?: string;
  setScheme: (scheme: string) => void;
};

export const useScheme = create<Store>(set => ({
  scheme: "",
  setScheme: (scheme: string) =>
    set(state => {
      localStorage.theme = scheme;

      return {
        ...state,
        scheme,
      };
    }),
}));
