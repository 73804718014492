import { gql } from "@apollo/client";

export const PROCESS_STATUS_CORE_FIELDS = gql`
  fragment ProcessStatusCoreFields on processStatus {
    id
    name
    description
    color
    priority
    dueMatters
  }
`;
