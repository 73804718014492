import { useMutation, useQuery } from "@apollo/client";
import {
  CalendarIcon,
  MapIcon,
  QueueListIcon,
  RocketLaunchIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { Avatar, Button, Spinner } from "flowbite-react";
import { useState } from "react";
import { addDays } from "../../../../utils/dateMethods";
import { ProcessType } from "../../process/types";
import { Event } from "../../../../types/calendarTypes";
// @ts-expect-error
import md5 from "md5";
import { UPDATE_PROCESS_BY_PK } from "../../process/processDetail/gqls";
import { deleteEvent } from "../../../../utils/calendarMethods";
import Booking from "./booking";
import { GET_USERS } from "../../settings/users/gql";
import checkAuth from "../../../../utils/checkAuth";
import isNullish from "../../../../utils/isNullish";
import { fullNumber } from "../../../../utils/fullNumber";
import { useNavigate } from "react-router-dom";
import { GET_PROCESS_ASSIGNMENT } from "../../process/processDetail/processAssignment";

interface prop {
  schedule: Event;
  process?: ProcessType;
  prvItem?: Event;
  cb?: () => void;
  expanded?: boolean;
  displayProcessLink?: boolean;
}

const Item = ({
  schedule,
  process,
  prvItem,
  cb,
  expanded,
  displayProcessLink,
}: prop) => {
  const { data: data_users } = useQuery(GET_USERS);
  const users = data_users?.users;

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const { data: data_assignment } = useQuery(GET_PROCESS_ASSIGNMENT, {
    variables: {
      processId: process?.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const assignments = data_assignment?.assignment.filter(assignment => {
    if (
      process?.createdUser.team.id &&
      checkAuth([
        "assignment_see_all",
        {
          permission: "assignment_edit_team",
          checkGroup: "userTeam",
          conditionGroup: [process?.createdUser.team.id],
        },
      ])
    ) {
      return true;
    }
    if (assignment.user.id == user?.id) {
      return true;
    }
    return false;
  });

  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);

  const [extended, setExtended] = useState(expanded || false);
  const toggleExtended = () => {
    setExtended(!extended);
  };

  let regex = /\[[^\]]*\]/i;

  const summary = process
    ? schedule.summary?.replace(regex, "").replace(`${process.name} / `, "")
    : schedule.summary;

  const isDate = schedule.start?.date;

  const start = schedule.start?.date || schedule.start?.dateTime;
  let end: any = schedule.end?.date || schedule.end?.dateTime;

  if (isDate) {
    end = addDays(new Date(end), -1);
  }

  const isSameDay =
    dayjs(start).format("DD/MM/YYYY") == dayjs(end).format("DD/MM/YYYY");

  const user = users?.find(u => u.calId == schedule.organizer?.email);
  const avatarUrl = `https://www.gravatar.com/avatar/${md5(
    user?.email || ""
  )}?d=mp`;

  const showDivider = () => {
    const curStartYear = Number(
      dayjs(schedule.start?.date || schedule.start?.dateTime).format("YYYY")
    );
    if (prvItem) {
      const prvEndYear = Number(
        dayjs(prvItem.end?.date || prvItem.end?.dateTime).format("YYYY")
      );

      return (
        prvEndYear !== curStartYear && curStartYear !== new Date().getFullYear()
      );
    } else {
      return curStartYear !== new Date().getFullYear();
    }
  };

  const _delete = async () => {
    const proceed = confirm("do you really want to delete the schedule?");
    if (!proceed) {
      return;
    }

    deleteEvent({
      calId: schedule.organizer?.email || "",
      id: schedule.id || "",
    });

    if (process) {
      const id = `${schedule.organizer?.email}::${schedule.id}`;
      const newSchedules = process.schedules?.filter(s => s !== id) || [];
      update_process({
        variables: {
          id: process.id,
          set: {
            schedules: newSchedules,
          },
        },
        onError(error) {
          console.log(error);
        },
      });
    }
  };

  const goToMap = () => {
    window.open("http://maps.google.com/?q=" + schedule.location);
  };

  const openCalendar = () => {
    window.open(schedule.htmlLink || "");
  };

  const canEdit =
    isNullish(schedule.recurringEventId) &&
    ((checkAuth("schedule_edit_mine") &&
      schedule.organizer?.id == user?.calId) ||
      checkAuth("schedule_edit_others"));

  const fullNum =
    process &&
    fullNumber(
      process.processType.prefix,
      process.year,
      process.number,
      process.salesRepUser
    );
  const navigate = useNavigate();
  const goToProcess = () => {
    navigate(`/process/detail/${process?.id}/prog`);
  };

  const goToAssignment = (id: number) => {
    navigate(`/assignment/detail/${id}`);
  };

  return (
    <>
      {showDivider() && (
        <div className="flex flex-row justify-end">
          <h2>{dayjs(start).format("YYYY")}</h2>
        </div>
      )}
      {!editing && (
        <div className="@container shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col">
          <div className="flex flex-row gap-1 items-center">
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex flex-row">
                <h3
                  onClick={toggleExtended}
                  className={`hover:text-plum cursor-pointer ${
                    extended ? "" : "flex-1 w-0 truncate"
                  }`}
                >
                  {summary}
                </h3>
              </div>
              <div className="flex @xs:hidden flex-row gap-2 items-center hover:text-plum cursor-pointer capitalize">
                <UserCircleIcon className="w-4" />
                {user?.firstName}
              </div>
              <div
                onClick={openCalendar}
                className="flex flex-row gap-2 items-center hover:text-plum cursor-pointer"
              >
                <CalendarIcon className="w-4 text-grass" />
                <div>
                  <span className="text-grass font-semibold">
                    {dayjs(start).format("DD/MM")}
                  </span>{" "}
                  {!isDate && dayjs(start).format("HH:mm")}
                </div>
                {!(isSameDay && isDate) && (
                  <div>
                    -{" "}
                    <span className="text-grass font-semibold">
                      {!isSameDay && dayjs(end).format("DD/MM")}
                    </span>{" "}
                    {!isDate && dayjs(end).format("HH:mm")}
                  </div>
                )}
              </div>
              {process && displayProcessLink && checkAuth("process_access") && (
                <div
                  className="flex flex-row gap-2 items-center hover:text-plum cursor-pointer"
                  onClick={goToProcess}
                >
                  <QueueListIcon className="w-4" /> {fullNum}
                </div>
              )}
              {displayProcessLink &&
                assignments &&
                assignments.length > 0 &&
                !checkAuth("process_access") && (
                  <div className="flex flex-col gap-2">
                    {assignments.map(assignment => (
                      <div
                        key={assignment.id}
                        className="flex flex-row gap-2 items-center cursor-pointer hover:text-plum"
                        onClick={() => {
                          goToAssignment(assignment.id);
                        }}
                      >
                        <RocketLaunchIcon className="w-4" />
                        {`${fullNum} [${assignment.id}]`}
                      </div>
                    ))}
                  </div>
                )}
              {!isNullish(schedule.location) && (
                <div
                  className="flex flex-row gap-2 items-center hover:text-plum cursor-pointer"
                  onClick={goToMap}
                >
                  <MapIcon className="w-4" /> {schedule.location}
                </div>
              )}
            </div>
            <div className="mt-1 hidden @xs:flex flex-col gap-1 items-center">
              <Avatar img={avatarUrl} rounded bordered color="purple" />
              <div className="capitalize w-12 truncate text-sm text-center">
                {user?.firstName}
              </div>
            </div>
          </div>
          {extended && (
            <div className="flex flex-col gap-2 mt-2">
              <textarea
                rows={4}
                disabled
                className={`resize-none text-sm bg-gray-100 dark:bg-gray-700 rounded-md border-none w-full md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700`}
                value={schedule.description || ""}
              />

              {canEdit && (
                <div className="flex flex-row gap-2 justify-end">
                  <Button
                    size="xs"
                    gradientDuoTone="purpleToBlue"
                    onClick={_delete}
                  >
                    delete
                    {loading && <Spinner className="ml-2" size="xs" light />}
                  </Button>
                  <Button
                    size="xs"
                    gradientDuoTone="purpleToBlue"
                    outline
                    onClick={toggleEditing}
                  >
                    edit
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {editing && (
        <Booking
          process={process}
          schedule={schedule}
          cancelFunc={() => {
            setEditing(false);
          }}
          cb={() => {
            if (cb) {
              cb();
            }
            setEditing(false);
          }}
        />
      )}
    </>
  );
};

export default Item;
