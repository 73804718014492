import { z } from "zod";
import { coerceDate, coerceNumber } from "../../process/types";

export const logSchema = z.object({
  by: coerceNumber,
  processID: coerceNumber.optional(),
  content: z.string(),
  productionID: coerceNumber.optional(),
});

export interface NewLogType {
  by: number;
  processID?: number;
  content: string;
  productionID?: number;
}

export interface LogType extends Omit<NewLogType, "by"> {
  id: number;
  user: {
    id: number;
    firstName: string;
    sirName: string;
    email: string;
  };
  timeStamp: string;
  updated_at: string;
  deleted: boolean;
}

export interface LogArrayType {
  processLogs: LogType[];
}

export interface LogStreamType {
  processLogs_stream: LogType[];
}
