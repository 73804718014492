import { Button, Spinner, Table, TextInput } from "flowbite-react";
import Heading from "../../../comps/heading";
import { useState } from "react";
import { TypedDocumentNode, gql, useMutation } from "@apollo/client";
import CsvDownloadButton from "react-json-to-csv";
import { addAlert } from "../../../store/alertStore";
import { Coord } from "../../../utils/geocode";
import dayjs from "dayjs";

const INSERT_RECORD: TypedDocumentNode = gql`
  mutation INSERT_RECORD($object: records_insert_input!) {
    insert_records_one(object: $object) {
      id
    }
  }
`;

interface ObjectId {
  $oid: string;
}
interface MongoDate {
  $date: string;
}

interface MongoLog {
  _id: ObjectId;
  start: MongoDate;
  end: MongoDate;
  desc: string;
  created: MongoDate;
}

interface MongoItem {
  _id: ObjectId;
  status: number;
  who: ObjectId;
  start: MongoDate;
  end: MongoDate;
  break: null | number;
  desc: null | string;
  type: null | number;
  created: MongoDate;
  log: null | MongoLog[];
  startLoc: null | Coord;
  endLoc: null | Coord;
}

interface ConnectorID {
  oldId: string;
  id: number;
}

interface InsertError {
  id: string;
  error: string;
  data: any;
}

interface Log {
  date: string;
  content: string;
}

interface Converted {
  oldId: string;
  created_at: string | undefined;
  updated_at: string | undefined;
  who: number;
  typeID: number;
  start: string;
  end: string | null;
  statusID: number;
  desc: null | string;
  break: number;
  startLoc: Coord | null;
  endLoc: Coord | null;
  logs: null | Log[];
  oldItem: MongoItem;
}

export default function Converter() {
  const [items, setItems] = useState<MongoItem[]>([]);
  const [convertedList, setConvertedList] = useState<Converted[]>([]);
  const [errors, setErrors] = useState<InsertError[]>([]);
  const [progress, setProgress] = useState(0);

  const [users, setUsers] = useState<ConnectorID[]>([]);
  const [statuses, setStatuses] = useState<ConnectorID[]>([]);
  const [types, setTypes] = useState<ConnectorID[]>([]);

  const [insert_record, { loading }] = useMutation(INSERT_RECORD);

  const readJsonFile = (file: Blob) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = event => {
        if (event.target) {
          resolve(JSON.parse(event.target.result as string));
        }
      };

      fileReader.onerror = error => reject(error);
      fileReader.readAsText(file);
    });

  const onChangeItems = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setItems(parsedData as MongoItem[]);
    }
  };

  const onChangeUsers = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setUsers(parsedData as ConnectorID[]);
    }
  };

  const onChangeStatuses = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setStatuses(parsedData as ConnectorID[]);
    }
  };

  const onChangeTypes = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const parsedData = await readJsonFile(event.target.files[0]);
      setTypes(parsedData as ConnectorID[]);
    }
  };

  const findId = (array: ConnectorID[], oid: string) => {
    return array.find(arr => arr.oldId == oid)?.id;
  };

  const convertDate = (date: Date | string) => {
    try {
      return new Date(date).toISOString();
    } catch (error) {
      return null;
    }
  };

  const convertLogs = (logs: MongoLog[] | undefined | null) => {
    if (!logs) {
      return null;
    }

    const newLogs: Log[] = logs.map(log => {
      const content = `Record changed from ${dayjs(log.start.$date).format(
        "HH:mm"
      )} - ${log.end ? dayjs(log.end.$date).format("HH:mm") : "pending"} `;
      return {
        date: convertDate(log.created.$date) as string,
        content,
      };
    });

    return newLogs;
  };

  const convertType = (oldId: number | null | undefined) => {
    if (oldId || oldId == undefined) {
      return 1;
    } else {
      const converted = Number(findId(types, oldId.toString()));
      if (converted == null) {
        return 1;
      } else {
        return converted;
      }
    }
  };

  const convert = (item: MongoItem) => {
    const converted: Converted = {
      oldId: item._id.$oid,
      break: item.break || 0,
      created_at: convertDate(item.created.$date) || undefined,
      desc: item.desc || null,
      end: item.end ? convertDate(item.end.$date) : null,
      start: convertDate(item.start.$date) as string,
      startLoc: item.startLoc || null,
      endLoc: item.endLoc || null,
      statusID: Number(findId(statuses, item.status.toString())),
      typeID: convertType(item.type),
      updated_at: item.log
        ? convertDate(item.log[item.log.length - 1]?.created.$date) || undefined
        : undefined,
      who: Number(findId(users, item.who.$oid)),
      logs: convertLogs(item.log),
      oldItem: item,
    };

    return converted;
  };

  const insertOne = async (oldItem: MongoItem) => {
    console.log(oldItem);
    const item = convert(oldItem);
    console.log(item);

    const proceed = confirm("insert this item?");

    if (!proceed) {
      return;
    }

    await insert_record({
      variables: { object: { ...item, oldItem: undefined, oldId: undefined } },
      onError(error) {
        const err: InsertError = {
          data: item,
          error: error.message,
          id: item.oldId,
        };
        setErrors(errs => errs.concat(err));
      },
    });
  };

  const insertAll = async () => {
    setErrors([]);
    const converted = items.map(item => convert(item));
    const proceed = confirm("insert all items?");

    if (!proceed) {
      return;
    }

    const totalLength = converted.length;
    let count = 0;

    for await (const item of converted) {
      await insert_record({
        variables: {
          object: { ...item, oldItem: undefined, oldId: undefined },
        },
        onError(error) {
          const err: InsertError = {
            data: item,
            error: error.message,
            id: item.oldId,
          };

          setErrors(errs => errs.concat(err));
        },
        onCompleted() {
          count++;
          setProgress(Math.round((count / totalLength) * 100));
        },
      });
    }

    addAlert({
      message: `${count}/${totalLength} inserted`,
      type: "info",
      persist: true,
    });
  };

  const convertAll = () => {
    const converted = items.map((item, i) => convert(item));
    setConvertedList(converted);
  };

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6">
        <div className="flex flew-row h-8">
          <Heading name="Process converter" />
        </div>
        <div className="flex flex-row text-red-500">
          WARNING, do not proceed if not 100% sure !!!
        </div>
      </div>
      <section className="p-6 gap-4 flex flex-col mt-1">
        <h2>Users</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          onChange={onChangeUsers}
        />
        {users.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>oldId</Table.HeadCell>
              <Table.HeadCell>id</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {users.slice(0, 10).map(user => (
                <Table.Row
                  key={user.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{user.oldId}</Table.Cell>
                  <Table.Cell>{user.id}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <h2>Statuses</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          disabled={users.length == 0}
          onChange={onChangeStatuses}
        />
        {statuses.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>oldId</Table.HeadCell>
              <Table.HeadCell>id</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {statuses.slice(0, 10).map(stat => (
                <Table.Row
                  key={stat.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{stat.oldId}</Table.Cell>
                  <Table.Cell>{stat.id}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        <h2>Types</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          disabled={statuses.length == 0}
          onChange={onChangeTypes}
        />
        {types.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>oldId</Table.HeadCell>
              <Table.HeadCell>id</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {types.slice(0, 10).map(type => (
                <Table.Row
                  key={type.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{type.oldId}</Table.Cell>
                  <Table.Cell>{type.id}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <h2>Items</h2>
        <TextInput
          sizing="xs"
          type="file"
          accept=".json, application/json"
          disabled={types.length == 0}
          onChange={onChangeItems}
        />
        {items.length > 0 && (
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell>who</Table.HeadCell>
              <Table.HeadCell>start</Table.HeadCell>
              <Table.HeadCell>end</Table.HeadCell>
              <Table.HeadCell>type</Table.HeadCell>
              <Table.HeadCell>status</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {items.slice(0, 10).map(item => (
                <Table.Row
                  onClick={() => {
                    insertOne(item);
                  }}
                  key={item._id.$oid}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                >
                  <Table.Cell>{item.who.$oid}</Table.Cell>
                  <Table.Cell>{item.start.$date}</Table.Cell>
                  <Table.Cell>{item.end.$date}</Table.Cell>
                  <Table.Cell>{item.type}</Table.Cell>
                  <Table.Cell>{item.status}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <div className="flex flex-row justify-end items-center gap-4">
          {loading && (
            <div className="flex flex-row items-center gap-2">
              <Spinner size="sm" />
              <span>{progress}%</span>
            </div>
          )}
          <Button
            size="sm"
            onClick={insertAll}
            disabled={items.length == 0 || loading}
            gradientDuoTone="purpleToBlue"
          >
            Convert and Insert All
          </Button>
          <Button
            outline
            size="sm"
            onClick={convertAll}
            disabled={items.length == 0}
            gradientDuoTone="purpleToBlue"
          >
            Convert All
          </Button>
          {convertedList.length > 0 && (
            <div className="p-[6px] bg-plum rounded-md border-[1px] dark:border-gray-500">
              <CsvDownloadButton delimiter="," data={convertedList} />
            </div>
          )}
        </div>
        {errors.length > 0 && (
          <>
            <h2>Errors</h2>
            {errors.map((err, i) => (
              <div
                onClick={() => {
                  console.log(err.data);
                }}
                key={i}
              >
                {err.id} - {err.error}
              </div>
            ))}
          </>
        )}
      </section>
    </main>
  );
}
