import { Badge, Button, Spinner } from "flowbite-react";
import Heading from "../../../comps/heading";
import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { TASK_CORE_FIELDS } from "../shared/task/fragments";
import { TaskArrayType, TaskStreamType } from "../shared/task/types";
import { useAuthStore } from "../../../store/authStore";
import { uniqueById } from "../../../utils/arrayObjMethods";
import AddTask from "../shared/task/addTask";
import Task from "../shared/task/task";
import { sort } from "fast-sort";
import { useEffect, useState } from "react";
import DynamicHeroIcon from "../../../comps/hIcon";
import { useTaskStore } from "../../../store/taskStore";
import { cloneDeep } from "apollo-utilities";

export const GET_MY_TASKS: TypedDocumentNode<TaskArrayType> = gql`
  ${TASK_CORE_FIELDS}
  query GET_MY_TASKS($where: tasks_bool_exp) {
    tasks(where: $where) {
      ...TaskCoreFields
    }
  }
`;

export const SUB_MY_TASKS: TypedDocumentNode<TaskStreamType> = gql`
  ${TASK_CORE_FIELDS}
  subscription SUB_MY_TASKS(
    $where: tasks_bool_exp
    $cursor: [tasks_stream_cursor_input]!
  ) {
    tasks_stream(where: $where, cursor: $cursor, batch_size: 10) {
      ...TaskCoreFields
    }
  }
`;

export default function Todo() {
  const [showCompleted, setShowCompleted] = useState(false);
  const toggleShowCompleted = () => {
    setShowCompleted(!showCompleted);
  };

  const [showOthers, setShowOthers] = useState(false);
  const toggleShowOthers = () => {
    setShowOthers(!showOthers);
  };

  const { user } = useAuthStore();
  const { cursor, setCursor } = useTaskStore();

  const where = {
    _or: [{ assignedTo: { _eq: user?.id } }, { assignedBy: { _eq: user?.id } }],
  };

  const { data, loading, error, subscribeToMore } = useQuery(GET_MY_TASKS, {
    variables: {
      where,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    onCompleted() {
      setCursor(new Date().toISOString());
    },
  });

  const ids = data?.tasks.map(t => t.id);

  const tasks =
    data?.tasks &&
    sort(
      data.tasks.filter(t => showOthers || t.assignedToUser.id == user?.id)
    ).by([
      { asc: t => t.taskStatus.priority },
      { asc: t => t.due },
      { asc: t => t.description },
    ]);
  if (error) {
    console.log(error);
  }

  const pending = tasks?.filter(t => t.taskStatus.name !== "completed");
  const completed = tasks?.filter(t => t.taskStatus.name == "completed");

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUB_MY_TASKS,
      variables: {
        where: { _or: [...where._or, { id: { _in: ids || [] } }] },
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.tasks);
        const newFeedItem = subscriptionData.data.tasks_stream;
        const existing = previousData.filter(
          p => !newFeedItem.find(i => i.id == p.id)
        );
        const filterDeleted = [...newFeedItem, ...existing].filter(
          p => !p.deleted
        );
        return Object.assign({}, previous, {
          tasks: filterDeleted,
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [where, ids]);

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6">
        <div className="flex flew-row justify-between h-8">
          <div className="flex flex-row items-center gap-2">
            <Heading name="TO DO" />
            <Badge color="purple">{pending?.length}</Badge>
          </div>
          {loading && <Spinner color="purple" />}
        </div>
        <div className="flex flex-row text-grass mb-10">to-do list</div>
      </div>
      {/* Content Part */}
      <section className="flex flex-col px-6 gap-4 w-full max-w-[768px] m-auto">
        {/* Actions */}
        <div className="flex flex-row gap-2 flex-wrap items-center">
          <div
            className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
            onClick={toggleShowCompleted}
          >
            <DynamicHeroIcon
              icon={showCompleted ? "EyeIcon" : "EyeSlashIcon"}
              class="w-4"
            />
            {showCompleted ? "Hide" : "Show"} completed
          </div>
          <div
            className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
            onClick={toggleShowOthers}
          >
            <DynamicHeroIcon
              icon={showOthers ? "EyeIcon" : "EyeSlashIcon"}
              class="w-4"
            />
            {showOthers ? "All" : "Mine only"}
          </div>
        </div>
        <AddTask />
        {pending?.map(task => (
          <Task key={task.id} task={task} showJobName={true} />
        ))}
        {showCompleted && (
          <div className="flex flex-col gap-4">
            <hr className="dark:border-gray-700" />
            {completed?.map(task => (
              <Task key={task.id} task={task} showJobName={true} />
            ))}
          </div>
        )}
      </section>
    </main>
  );
}
