import { useNavigate } from "react-router-dom";
import { fullNumber } from "../../../../utils/fullNumber";
import dayjs from "dayjs";
import { Badge, Checkbox, ListGroup } from "flowbite-react";
import getContrast from "../../../../utils/getContrast";
import {
  CakeIcon,
  ChatBubbleLeftEllipsisIcon,
  MapIcon,
} from "@heroicons/react/24/solid";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PRODUCTION_STATUSES_NO_PID,
  GET_PRODUCTION_STATUSES_BY_PID,
} from "../../settings/production/productionStatuses/productionStatuses";
import { useOnClickOutside } from "usehooks-ts";
import { useRef, useState } from "react";
import { useAuthStore } from "../../../../store/authStore";
import { ProductionType } from "../../shared/productions/types";
import { UPDATE_PRODUCTION_STATUS } from "../productionBoard/productionBoard";
import { UPDATE_PRODUCTION_BY_PID } from "../../process/processDetail/production";
import checkAuth from "../../../../utils/checkAuth";

interface props {
  production: ProductionType;
}

export default function ListItem({ production }: props) {
  const { user } = useAuthStore();

  const {
    id,
    productionStatus,
    process,
    due,
    description,
    created_at,
    badge,
    // done,
    product,
    qty,
    team,
    progress,
  } = production;

  const { data: data_statuses } = useQuery(GET_PRODUCTION_STATUSES_NO_PID);
  const statusList = data_statuses?.productionStatus;

  const { data: data_checkList } = useQuery(GET_PRODUCTION_STATUSES_BY_PID, {
    variables: { id: product.id },
  });
  const canEdit = checkAuth("production_edit");

  const checkList = data_checkList?.productionStatus;

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    canEdit && setEditing(!editing);
  };

  const [update_production_status] = useMutation(UPDATE_PRODUCTION_STATUS);

  const [update_production] = useMutation(UPDATE_PRODUCTION_BY_PID);

  const changeStatus = (status: number) => {
    update_production_status({
      variables: { id, status },
      optimisticResponse: {
        update_productions_by_pk: {
          __typename: "productions",
          ...production,
          productionStatus:
            statusList?.find(sl => sl.id == status) || productionStatus,
        },
      },
    });
    setEditing(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setEditing(false);
  });

  const navigate = useNavigate();
  const goToDetail = () => {
    navigate("/production/detail/" + id);
  };
  const fullNum =
    process &&
    fullNumber(
      process.processType.prefix,
      process.year,
      process.number,
      process.salesRepUser
    );

  const isPastDue = due && dayjs(due).isBefore(new Date());

  const check = (num: number) => {
    if (!canEdit) {
      return;
    }
    const progress: number[] = production.progress || [];

    const newProgress = progress.includes(num)
      ? progress.filter(p => p !== num)
      : progress.concat(num);
    update_production({
      variables: {
        id,
        set: {
          progress: newProgress,
        },
      },
      optimisticResponse: {
        update_productions_by_pk: {
          __typename: "productions",
          ...production,
          progress: newProgress,
        },
      },
    });
  };

  return (
    <div
      ref={ref}
      className="border-[1px] shadow-sm bg-white dark:bg-gray-800 dark:border-gray-700 rounded-md flex flex-col p-3"
    >
      <div className="flex flex-row gap-2 items-center justify-between">
        <div className="flex flex-row flex-[1] items-center gap-2">
          {/* Name, Number */}
          <div className="flex flex-col flex-[1] gap-1">
            <div
              onClick={goToDetail}
              className="flex flex-col gap-1 cursor-pointer group hover:text-grass"
            >
              <div>{fullNum}</div>
              <div className="flex flex-row gap-2 items-center">
                <h3 className="truncate flex-1 w-0 group-hover:text-grass">
                  {process?.name || description}
                </h3>
                {badge && <Badge color="purple">{badge}</Badge>}
              </div>
            </div>
            <div className="flex flex-row gap-2">
              {/* products */}

              <div className="flex flex-row gap-2 items-center">
                {product.image && (
                  <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                    <img src={product.image} className="w-3" />
                  </div>
                )}
                <div className="hidden @sm:block supports-[not(container-type:inline-size)]:sm:block">
                  {product.name}
                </div>
                <div>{qty}</div>
              </div>
              {/* team */}
              <div className="flex flex-row gap-2 items-center">
                {team.name}
              </div>
              {/* Desc */}
              {description && (
                <div className="hidden @md:flex supports-[not(container-type:inline-size)]:md:flex flex-row gap-1 items-center">
                  <ChatBubbleLeftEllipsisIcon className="w-4" />
                  <div className="max-w-[400px] truncate">{description}</div>
                </div>
              )}
              {/* address */}
              {process?.address && (
                <div className="hidden @lg:flex supports-[not(container-type:inline-size)]:lg:flex flex-row gap-1 items-center">
                  <MapIcon className="w-4" />
                  <div className="max-w-[400px] truncate">
                    {process.address}
                  </div>
                </div>
              )}
              {/* from */}
              <div className="hidden @sm:flex supports-[not(container-type:inline-size)]:sm:flex flex-row gap-1 items-center">
                <CakeIcon className="w-4" />
                <div>{dayjs(created_at).format("DD/MM/YYYY")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2 p-2">
          {/* Status */}
          <div className="relative">
            <div onClick={toggleEditing} className="cursor-pointer">
              <Badge
                style={{
                  backgroundColor: productionStatus.color,
                  color: getContrast(productionStatus.color),
                }}
                size="md"
                className="pointer-events-none"
              >
                {productionStatus.name}
              </Badge>
            </div>
            {editing && (
              <ListGroup className="absolute top-[100%] mt-2 z-10 right-0">
                {statusList?.map(stat => (
                  <ListGroup.Item
                    key={stat.id}
                    onClick={() => {
                      if (stat.id == productionStatus.id) {
                        return;
                      }
                      changeStatus(stat.id);
                    }}
                  >
                    <Badge
                      size="sm"
                      className="w-full pointer-events-none"
                      style={{
                        backgroundColor: stat.color,
                        color: getContrast(stat.color),
                        opacity: stat.id == productionStatus.id ? 0.5 : 1,
                      }}
                    >
                      {stat.name}
                    </Badge>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </div>
          {/* Due */}
          <div
            className={`flex flex-col items-center justify-center p-2 ${
              isPastDue && "text-red-500"
            }`}
          >
            <div>{due ? dayjs(due).format("DD") : "No"}</div>
            <div>{due ? dayjs(due).format("MMM") : "Due"}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-3 mt-3 flex-wrap">
        {checkList &&
          checkList.map(stat => (
            <div
              key={stat.id}
              onClick={() => {
                check(stat.id);
              }}
              className="hover:text-plum cursor-pointer"
            >
              <Checkbox
                className="checked:bg-plum dark:checked:bg-plum"
                checked={
                  progress && progress.find(pr => pr == stat.id) ? true : false
                }
                readOnly
              />{" "}
              {stat.name}
            </div>
          ))}
      </div>
    </div>
  );
}
