import { useAuthStore } from "../../../../store/authStore";
import { logSchema, NewLogType } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "@apollo/client";
import { PROCESS_LOGS_CORE_FIELDS } from "./fragments";
import { Avatar, Button, Spinner } from "flowbite-react";
import { useNavStore } from "../../../../store/navStore";
// @ts-expect-error
import md5 from "md5";

const INSERT_LOG = gql`
  ${PROCESS_LOGS_CORE_FIELDS}
  mutation INSERT_LOG(
    $by: Int!
    $content: String!
    $processID: Int
    $productionID: Int
  ) {
    insert_processLogs_one(
      object: {
        by: $by
        content: $content
        processID: $processID
        productionID: $productionID
      }
    ) {
      ...ProcessLogsCoreFields
    }
  }
`;

interface props {
  processID?: number;
  productionID?: number;
}

export default function AddLog({ processID, productionID }: props) {
  const { user } = useAuthStore();
  const userID = user?.id || 0;
  const { show } = useNavStore();

  const [insert_log, { loading }] = useMutation(INSERT_LOG);

  const defaultValues: NewLogType = {
    by: 0,
    content: "",
    processID,
    productionID,
  };

  const { register, reset, handleSubmit } = useForm({
    resolver: zodResolver(logSchema),
    defaultValues,
  });

  const onSubmit = handleSubmit(async data => {
    insert_log({
      variables: {
        ...data,
        by: userID,
      },
      onCompleted() {
        reset();
      },
    });
  });

  const avatarUrl = `https://www.gravatar.com/avatar/${md5(user?.email)}?d=mp`;

  return (
    <form
      className="w-full gap-2 flex flex-row items-center shadow-md relative border-[1px] bg-gray-100 dark:bg-gray-700 dark:border-gray-600 p-2 rounded-md"
      onSubmit={onSubmit}
    >
      <Avatar
        img={avatarUrl}
        size="sm"
        rounded
        bordered={true}
        color="purple"
        className={`${show ? "hidden" : "block"} md:block`}
      />
      <input
        {...register("content")}
        placeholder="Enter new comment"
        className="flex-1 bg-transparent outline-none border-none focus:outline-none"
      />
      <Button type="submit" size="xs" outline gradientDuoTone="purpleToBlue">
        {loading && <Spinner size="xs" light className="mr-1" />}
        Add
      </Button>
    </form>
  );
}
