import { sort } from "fast-sort";
import { MaterialsType } from "../../../materials/types";
import {
  DeductionType,
  ProductSetType,
  deductionSchema,
} from "../../../workorder/types";
import { useScheme } from "../../../../../store/schemeStore";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_DEDUCTION,
  DELETE_DEDUCTION,
  GET_PRODUCT_OPTIONS_CONDITIONAL,
  UPDATE_DEDUCTIONS_BY_PK,
} from "../../../workorder/gqls";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DEDUCTION_CORE_FIELDS } from "../../../workorder/fragments";
import { Badge, Button, Select, Table, TextInput } from "flowbite-react";
import {
  CheckIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { createPortal } from "react-dom";
import CheckboxWithLabel from "../../../../../comps/CheckboxWithLabel";
import DeductionImage from "./deductionImage";
import OptionCondition from "../optionCondition";
import UsedMats from "./usedMats";

interface OptionPopulatedDeductionType extends DeductionType {
  populatedOptionCondition?: {
    option: number | string;
    value: string | number;
  }[];
}

interface NewDeductionType {
  productSetID?: number | null;
  itemId?: number | null;
  name: string;
  width?: number | null;
  height?: number | null;
  parent?: number | null;
  widthAddition?: number | null;
  heightAddition?: number | null;
  optionCode?: string | null;
  usedItems?:
    | {
        id: number;
        qty: string;
        unit: number;
      }[]
    | null;
  image?: string | null;
  imageTexts?:
    | {
        scope: string;
        rotation: number;
        coord: {
          x: number;
          y: number;
        };
      }[]
    | null;
  pending: boolean;
  optionCondition?:
    | {
        option: number;
        value: number | string;
        displayValue?: number | string;
      }[]
    | null;
}

interface deduction {
  item: OptionPopulatedDeductionType;
  materials?: MaterialsType[];
  productSets?: ProductSetType[];
  deductions?: DeductionType[];
}

export default function Deduction({
  item,
  materials,
  productSets,
  deductions,
}: deduction) {
  const availableMats =
    materials &&
    sort(
      materials?.filter(mat => mat.product.id == item.productSet?.product?.id)
    ).asc([m => m.brand, m => m.name, m => m.color, m => m.size]);

  const { scheme } = useScheme();
  const [update, { loading: updating }] = useMutation(UPDATE_DEDUCTIONS_BY_PK);

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: item.productSet?.id },
      },
    },
  });

  const productSetOptions = data_options?.productSetOptions;

  const optionConditions =
    item.populatedOptionCondition &&
    sort(item.populatedOptionCondition).asc("option");

  const optionCodes = productSetOptions
    ?.filter(option => option.optionCode && option.source == "deductions")
    .map(option => option.optionCode)
    .reduce<string[]>((prev, cur) => {
      if (!cur || prev.includes(cur)) {
        return prev;
      }
      return prev.concat(cur);
    }, []);

  const defaultValues: NewDeductionType = {
    productSetID: item.productSet?.id || null,
    itemId: item.inventory?.id || null,
    name: item.name || "",
    width: item.width,
    height: item.height,
    parent: item.parentDeduction?.id || null,
    widthAddition: item.widthAddition,
    heightAddition: item.heightAddition,
    optionCode: item.optionCode,
    usedItems: item.usedItems,
    pending: item.pending,
    image: item.image,
    imageTexts: item.imageTexts,
    optionCondition: item.optionCondition,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(deductionSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isDirty },
  } = methods;

  const [remove, { loading: deleting }] = useMutation(DELETE_DEDUCTION);
  const [insert, { loading: inserting }] = useMutation(ADD_DEDUCTION);

  const _delete = (id: number) => {
    const proceed = confirm("delete deduction?");
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: "deductions",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      // TODO: delete from Zustand too
    });
  };

  const [editing, setEditing] = useState(false);

  const save = handleSubmit(data => {
    // console.log(data);
    // return;
    update({
      variables: {
        id: item.id,
        set: {
          ...data,
          itemId: data.itemId == 0 ? null : data.itemId,
          parent: data.parent == 0 ? null : data.parent,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        setEditing(false);
        reset({
          ...data,
        });
      },
    });
  });

  const copy = handleSubmit(data => {
    const proceed = confirm(
      `copy current deduction${isDirty ? " with modified values" : ""}?`
    );
    if (!proceed) {
      return;
    }

    insert({
      variables: {
        object: {
          ...data,
          id: undefined,
          itemId: data.itemId == 0 ? null : data.itemId,
          parent: data.parent == 0 ? null : data.parent,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        reset();
        setEditing(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            deductions(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_deductions_one,
                fragment: DEDUCTION_CORE_FIELDS,
                fragmentName: "DeductionCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const cancelEdit = () => {
    reset();
    setEditing(false);
  };

  const pending: boolean = useWatch({
    name: "pending",
    control,
  });

  return (
    <FormProvider {...methods}>
      <Table.Row key={item.id}>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500 w-4">
          {item.id}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.productSet?.name}
        </Table.Cell>

        <Table.Cell
          className={`py-[5px] px-2 border-r-[1px] dark:border-gray-500 ${
            item.pending && "text-red-500"
          }`}
        >
          {item.name}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.width}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.height}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.optionCode}
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          <div className="flex flex-row gap-1">
            {optionConditions?.map((option, i) => (
              <Badge color="purple" key={`${option.option}${i}`}>
                {option.option}:{option.value}
              </Badge>
            ))}
          </div>
        </Table.Cell>
        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500 truncate max-w-[100px]">
          {item.usedItems && item.usedItems.length > 0 && (
            <CheckIcon className="w-4 text-grass" />
          )}
        </Table.Cell>

        <Table.Cell className="py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {item.image ? <CheckIcon className="w-4 text-grass" /> : ""}
        </Table.Cell>
        <Table.Cell className="py-[5px] w-10">
          <div className="flex flex-row gap-3 w-full">
            <PencilIcon
              onClick={() => {
                setEditing(true);
              }}
              className="w-5 text-grass cursor-pointer"
            />
            <DocumentDuplicateIcon
              onClick={copy}
              className="w-5 text-plum cursor-pointer"
            />
          </div>
        </Table.Cell>
      </Table.Row>
      {/* Edit Deduction */}
      {editing && (
        <>
          {createPortal(
            <div
              className={`${scheme} absolute w-screen h-screen top-0 left-0 p-10 backdrop-blur-xl flex flex-col gap-2 overflow-x-hidden`}
            >
              <h3>Edit Deduction</h3>
              <div className="flex flex-row justify-between gap-2 flex-wrap">
                <div className="flex flex-row gap-2 flex-wrap">
                  <Select
                    addon="Product"
                    {...register("productSetID")}
                    sizing="sm"
                    disabled
                  >
                    <option value="">Select product</option>

                    {productSets?.map(p => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Select>
                  {/* <Select addon="Mat" {...register("itemId")} sizing="sm">
                    <option value="">Related Material (optional)</option>

                    {availableMats?.map(p => (
                      <option key={p.id} value={p.id}>
                        {p.brand} {p.name} {p.color}
                      </option>
                    ))}
                  </Select> */}
                </div>
                <CheckboxWithLabel
                  name="pending"
                  checked={pending}
                  onChange={() => {
                    setValue("pending", !pending);
                  }}
                />
              </div>
              <div className="flex flex-row gap-2 flex-wrap">
                <TextInput
                  {...register("name")}
                  sizing="sm"
                  placeholder="Enter Name"
                  addon="Name"
                />
                <TextInput
                  {...register("width")}
                  sizing="sm"
                  addon="W"
                  placeholder="Width"
                  className="w-32"
                  type="number"
                />
                <TextInput
                  {...register("height")}
                  sizing="sm"
                  addon="H"
                  placeholder="Height"
                  className="w-32"
                  type="number"
                />
                <Select
                  {...register("optionCode")}
                  sizing="sm"
                  addon="OC"
                  placeholder="option code"
                >
                  <option value="">None</option>
                  {optionCodes?.map(optionCode => (
                    <option key={optionCode}>{optionCode}</option>
                  ))}
                </Select>
              </div>
              {item.productSet?.id && (
                <>
                  <h4 className="font-semibold dark:text-white">
                    Option Conditions
                  </h4>
                  <OptionCondition productSetID={item.productSet.id} />
                  <hr />
                </>
              )}
              <h4 className="font-semibold dark:text-white">
                Deduction Image (Opening Product Only)
              </h4>
              <DeductionImage />
              <hr />
              <h4 className="font-semibold dark:text-white">Used Materials</h4>
              <UsedMats availableMats={availableMats} />
              <hr />
              <h4 className="font-semibold dark:text-white">
                Parent Deduction
              </h4>
              <div className="flex flex-row gap-2 flex-wrap">
                <Select addon="Parent" {...register("parent")} sizing="sm">
                  <option value="">Parent Deduction (optional)</option>

                  {deductions?.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Select>
                <TextInput
                  {...register("widthAddition")}
                  sizing="sm"
                  addon="addW"
                  placeholder="W Addition"
                  className="w-40"
                  type="number"
                />
                <TextInput
                  {...register("heightAddition")}
                  sizing="sm"
                  addon="addH"
                  placeholder="H Addition"
                  className="w-40"
                  type="number"
                />
              </div>
              <hr />
              <div className="flex flex-row justify-between items-center gap-2">
                <Button
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                  outline
                  onClick={copy}
                >
                  Copy
                </Button>
                <div className="flex flex-row items-center gap-2">
                  <TrashIcon
                    onClick={() => {
                      _delete(item.id);
                    }}
                    className="w-5 text-red-500 cursor-pointer"
                  />
                  <Button size="xs" color="purple" onClick={cancelEdit}>
                    cancel
                  </Button>
                  <Button
                    size="xs"
                    gradientDuoTone="purpleToBlue"
                    outline
                    onClick={save}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>,
            document.body
          )}
        </>
      )}
    </FormProvider>
  );
}
