import { gql, TypedDocumentNode } from "@apollo/client";
import {
  RECORD_STATUS_CORE_FIELDS,
  RECORD_TYPE_CORE_FIELDS,
  RECORD_CORE_FIELDS,
} from "./fragments";
import {
  RecordArrayType,
  RecordStatusArrayType,
  RecordStreamType,
  RecordType,
  RecordTypeArrayType,
} from "./types";

export const GET_RECORD_STATUS: TypedDocumentNode<RecordStatusArrayType> = gql`
  ${RECORD_STATUS_CORE_FIELDS}
  query GET_RECORD_STATUS {
    recordStatus {
      ...RecordStatusCoreFields
    }
  }
`;

export const GET_RECORD_TYPE: TypedDocumentNode<RecordTypeArrayType> = gql`
  ${RECORD_TYPE_CORE_FIELDS}
  query GET_RECORD_TYPE {
    recordType {
      ...RecordTypeCoreFields
    }
  }
`;

export const GET_RECORD: TypedDocumentNode<RecordArrayType> = gql`
  ${RECORD_CORE_FIELDS}
  query GET_RECORD($where: records_bool_exp!) {
    records(where: $where) {
      ...RecordCoreFields
    }
  }
`;

export const STREAM_RECORDS: TypedDocumentNode<RecordStreamType> = gql`
  ${RECORD_CORE_FIELDS}
  subscription STREAM_RECORDS(
    $where: records_bool_exp
    $cursor: [records_stream_cursor_input]!
  ) {
    records_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...RecordCoreFields
    }
  }
`;

interface record_by_pk_type {
  records_by_pk: RecordType;
}

export const GET_RECORD_BY_PK: TypedDocumentNode<record_by_pk_type> = gql`
  ${RECORD_CORE_FIELDS}
  query GET_RECORD_BY_PK($id: Int!) {
    records_by_pk(id: $id) {
      ...RecordCoreFields
    }
  }
`;

export const INSERT_RECORD = gql`
  ${RECORD_CORE_FIELDS}
  mutation INSERT_RECORD(
    $who: Int!
    $typeID: Int!
    $start: timestamptz
    $end: timestamptz
    $statusID: Int!
    $startLoc: jsonb
    $endLoc: jsonb
    $desc: String
    $break: Int
  ) {
    insert_records_one(
      object: {
        who: $who
        typeID: $typeID
        start: $start
        end: $end
        statusID: $statusID
        startLoc: $startLoc
        endLoc: $endLoc
        desc: $desc
        break: $break
      }
    ) {
      ...RecordCoreFields
    }
  }
`;

export const INSERT_RECORD_MULTIPLE = gql`
  ${RECORD_CORE_FIELDS}
  mutation INSERT_RECORD_MULTIPLE($objects: [records_insert_input!]!) {
    insert_records(objects: $objects) {
      ...RecordCoreFields
    }
  }
`;

export const UPDATE_RECORD = gql`
  ${RECORD_CORE_FIELDS}
  mutation UPDATE_RECORD($id: Int!, $set: records_set_input!) {
    update_records_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...RecordCoreFields
    }
  }
`;

export const DELETE_RECORD = gql`
  mutation DELETE_RECORD($id: Int!) {
    delete_records_by_pk(id: $id) {
      id
    }
  }
`;
