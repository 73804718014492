import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_ASSIGNMENT,
  GET_ASSIGNMENT_BY_PK,
  GET_ASSIGNMENT_STATUS,
  UPDATE_ASSIGNMENT_BY_PK,
} from "./gqls";
import {
  Badge,
  Breadcrumb,
  Button,
  Label,
  ListGroup,
  Modal,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import {
  HomeIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { fullNumber } from "../../../utils/fullNumber";
// import { useAuthStore } from "../../../store/authStore";
import checkAuth from "../../../utils/checkAuth";
import getContrast from "../../../utils/getContrast";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { GET_PRODUCTS } from "../settings/products/products";
import { useForm } from "react-hook-form";
import { currencyFormat } from "../../../utils/numberMethods";
import { ProcessType } from "../process/types";
import { createPathArr, exists, mkdir } from "../../../utils/fileAPI";
import Folder from "../shared/files/folder";
import { GET_ORDERS_CONDITIONAL } from "../workorder/gqls";
import { OrderType } from "../workorder/types";
import useGetOrderProducts from "../workorder/useGetOrderProducts";
import { useAuthStore } from "../../../store/authStore";
import IncludeDoc from "./includeDoc";

export default function AssignmentDetail() {
  const { id } = useParams();

  const { data, loading } = useQuery(GET_ASSIGNMENT_BY_PK, {
    variables: {
      id,
    },
  });

  const assignment = data?.assignment_by_pk;

  const navigate = useNavigate();

  const process = assignment?.process;

  const fullNum = process
    ? fullNumber(
        process.processType.prefix,
        process.year,
        process.number,
        process.salesRepUser
      )
    : "";

  // const { user } = useAuthStore();

  const goToJob = () => {
    if (!process) {
      return;
    }

    checkAuth("process_access") &&
      navigate("/process/detail/" + process.id + "/prog");
  };

  const { data: data_statuses } = useQuery(GET_ASSIGNMENT_STATUS);
  const statuses = data_statuses?.assignmentStatus;

  const [update_assignment, { loading: updating }] = useMutation(
    UPDATE_ASSIGNMENT_BY_PK
  );

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const changeStat = (id: number) => {
    if (!assignment) {
      return;
    }

    update_assignment({
      variables: {
        id: assignment.id,
        set: {
          statusId: id,
        },
      },
    });
    toggleOpen();
  };

  const { data: data_products } = useQuery(GET_PRODUCTS);
  const productList = data_products?.products;

  const products =
    assignment && assignment.products.length > 0
      ? assignment.products
      : process?.products;

  const team = process?.createdUser.team;

  const [editing, setEditing] = useState(false);

  const canEdit =
    team &&
    checkAuth([
      "assignment_edit_all",
      {
        permission: "assignment_edit_team",
        checkGroup: "userTeam",
        conditionGroup: [team?.id],
      },
    ]);

  const [remove] = useMutation(DELETE_ASSIGNMENT);

  const _delete = () => {
    const proceed = confirm("do you really want to delete this assignment?");
    if (!proceed) {
      return;
    }
    remove({
      variables: { id },
      update(cache) {
        const normalizedId = cache.identify({
          id: id,
          __typename: "assignment",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
        navigate("/assignment");
      },
    });
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      value: assignment?.value,
      desc: assignment?.desc,
      due: assignment?.due,
    },
  });

  useEffect(() => {
    if (!assignment) {
      return;
    }

    reset({
      value: assignment.value,
      desc: assignment.desc,
      due: assignment.due,
    });
  }, [assignment]);

  const cancel = () => {
    setEditing(false);
    reset();
  };

  const onSubmit = handleSubmit(data => {
    if (!assignment) {
      return;
    }

    update_assignment({
      variables: {
        id: assignment.id,
        set: {
          ...data,
        },
      },
      onCompleted() {
        reset(data);
        setEditing(false);
      },
    });
  });

  const { data: data_orders } = useQuery(GET_ORDERS_CONDITIONAL, {
    variables: {
      where: {
        id: { _in: assignment?.orders },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const orders = data_orders?.workorder.filter(order =>
    assignment?.orders.includes(order.id)
  );

  const goToAddOrder = () => {
    navigate(
      `/order/edit/${assignment?.processId}/assignment/${assignment?.id}`
    );
  };

  return (
    <main className="px-6 pb-10 select-none flex flex-col h-full">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("/assignment");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            Assignment
          </Breadcrumb.Item>
          <Breadcrumb.Item className="capitalize">
            {assignment?.id}
          </Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      {loading && !assignment && (
        <div className="flex-1 flex justify-center items-center">
          <Spinner size="xl" light color="purple" />
        </div>
      )}

      {assignment && (
        <article className="mt-3 mx-auto w-full max-w-[800px]">
          <div className="flex flex-row items-center gap-2">
            <h2 className="capitalize">{process?.name}</h2>
          </div>
          <div className="mt-2">
            <form
              // ref={ref}
              onSubmit={onSubmit}
              className="space-y-2 flex flex-col"
            >
              <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
                <div className="flex flex-row justify-between items-center">
                  <Label value="Assignment Id" />
                  {id}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Job Id" />
                  <div
                    className="cursor-pointer hover:text-plum"
                    onClick={goToJob}
                  >
                    {fullNum}
                  </div>
                </div>
                <div
                  ref={ref}
                  className="flex flex-row justify-between items-center"
                >
                  <Label value="Type" />

                  <Badge
                    style={{
                      color: getContrast(assignment.type.color),
                      backgroundColor: assignment.type.color,
                    }}
                    size="sm"
                    className="capitalize"
                  >
                    {assignment.type.name}
                  </Badge>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Address" />
                  {process?.address || ""}
                </div>
                <div
                  ref={ref}
                  className="flex flex-row justify-between items-center"
                >
                  <Label value="Status" />
                  <div className="relative">
                    <Badge
                      style={{
                        color: getContrast(assignment.status.color),
                        backgroundColor: assignment.status.color,
                      }}
                      size="sm"
                      onClick={toggleOpen}
                      className={`w-fit hover:brightness-125 cursor-pointer ${
                        updating && "opacity-50"
                      }`}
                    >
                      {assignment.status.name}
                    </Badge>
                    {open && (
                      <ListGroup className="absolute right-0 z-10 mt-2">
                        {statuses
                          ?.filter(stat => stat.id !== assignment.status?.id)
                          .map(stat => (
                            <ListGroup.Item
                              onClick={() => {
                                changeStat(stat.id);
                              }}
                              key={stat.id}
                            >
                              <Badge
                                style={{
                                  backgroundColor: stat.color,
                                  color: getContrast(stat.color),
                                }}
                              >
                                {stat.name}
                              </Badge>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Due" />
                  {editing ? (
                    <TextInput {...register("due")} type="date" sizing="sm" />
                  ) : assignment.due ? (
                    dayjs(assignment.due).format("DD/MM/YYYY")
                  ) : (
                    "Nill"
                  )}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Created" />
                  {assignment.created_at
                    ? dayjs(assignment.created_at).format("DD/MM/YYYY")
                    : "Nill"}
                </div>
                {/* included products */}
                <div className="flex flex-row justify-between items-center">
                  <Label value="Included Products" />
                  <div className="flex flex-row gap-2 items-center">
                    {products &&
                      products.length > 0 &&
                      products.map((product, i) => {
                        const Product = productList?.find(
                          p => p.id == product.productId
                        );
                        return (
                          <div
                            key={i}
                            className="flex flex-row items-center gap-1"
                          >
                            {Product?.image && (
                              <img
                                src={Product.image}
                                className="w-5 dark:invert"
                              />
                            )}

                            <h4>{product.qty}</h4>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center capitalize">
                  <Label value="Assigned To" />
                  {assignment.user.firstName} {assignment.user.sirName}
                </div>
                <div className="flex flex-row justify-between items-center capitalize">
                  <Label value="Assigned By" />
                  {assignment.createdBy.firstName}{" "}
                  {assignment.createdBy.sirName}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Value" />
                  {editing ? (
                    <TextInput
                      {...register("value")}
                      type="number"
                      sizing="sm"
                      className="text-end w-20"
                    />
                  ) : assignment.value ? (
                    currencyFormat(assignment.value)
                  ) : (
                    "Nill"
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row justify-between">
                    <Label className="flex-1" value="Remarks" />
                  </div>
                  <Textarea
                    disabled={editing ? false : true}
                    style={{ opacity: 1 }}
                    className="resize-none text-sm"
                    {...register("desc")}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row justify-between">
                    <Label className="flex-1" value="Docs" />
                    <PlusCircleIcon
                      className="w-5 text-grass cursor-pointer"
                      onClick={goToAddOrder}
                    />
                  </div>
                  <div className="flex flex-col gap-2 dark:bg-gray-700 bg-gray-100 rounded-md px-2 py-3 border-[1px] dark:border-gray-600 border-gray-300">
                    {orders?.map(order => (
                      <AssignmentOrder
                        key={order.id}
                        orders={orders}
                        order={order}
                        editing={editing}
                        assignmentId={Number(id)}
                      />
                    ))}
                    {editing && <IncludeDoc assignment={assignment} />}
                  </div>
                </div>
                {process && (
                  <>
                    <Files id={Number(id)} process={process} />
                    <Files id={Number(id)} process={process} shared={true} />
                  </>
                )}
                {canEdit && (
                  <div className="flex flex-row gap-2 justify-end items-center mt-1">
                    <Button size="xs" color="failure" onClick={_delete}>
                      Delete
                    </Button>
                    {!editing && (
                      <Button
                        size="xs"
                        outline
                        gradientDuoTone="purpleToBlue"
                        onClick={() => {
                          setEditing(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {editing && (
                      <>
                        <Button size="xs" color="purple" onClick={cancel}>
                          cancel
                        </Button>
                        <Button
                          size="xs"
                          outline
                          gradientDuoTone="purpleToBlue"
                          type="submit"
                        >
                          Submit
                          {updating && (
                            <Spinner
                              size="xs"
                              color="purple"
                              className="pb-[1px] ml-1"
                            />
                          )}
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </form>
          </div>
        </article>
      )}
    </main>
  );
}

const AssignmentOrder = ({
  orders,
  order,
  editing,
  assignmentId,
}: {
  orders: OrderType[];
  order: OrderType;
  editing: boolean;
  assignmentId: number;
}) => {
  const getOrderProducts = useGetOrderProducts();
  const products = getOrderProducts(order);

  const navigate = useNavigate();

  const goToEdit = () => {
    if (checkAuth("order_access")) {
      return navigate(`/order/detail/${order.id}`);
    }

    navigate(`/order/edit/${order.process.id}/${order.id}`);
  };

  const { user } = useAuthStore();

  const [updateOrder, { loading: updating }] = useMutation(
    UPDATE_ASSIGNMENT_BY_PK
  );

  const excludeOrder = () => {
    const proceed = confirm(
      "Are you sure you want to exclude this doc from current assignment?"
    );
    if (proceed) {
      updateOrder({
        variables: {
          id: assignmentId,
          set: {
            orders: orders.filter(o => o.id !== order.id),
          },
        },
      });
    }
  };

  return (
    <div
      className={`flex flex-row gap-2 items-center justify-between group ${
        !editing && "cursor-pointer"
      }`}
      onClick={() => {
        !editing && goToEdit();
      }}
    >
      <div className="flex flex-row gap-2 items-center">
        <Badge
          style={{
            backgroundColor: order.type.color,
            color: getContrast(order.type.color),
          }}
        >
          {order.type.name}
        </Badge>
        <div># {order.id}</div>
        {order.orderRef && <div>- {order.orderRef}</div>}
        {/* Products */}
        {products && products.length > 0 && (
          <div className="flex flex-row gap-2 items-center">
            {products.map((product, i) => {
              return (
                <div key={i} className="flex flex-row items-center gap-1">
                  {product.img && (
                    <img src={product.img} className="w-5 dark:invert" />
                  )}

                  <div>{product.qty}</div>
                </div>
              );
            })}
          </div>
        )}
        {/* Created By */}
        <div className="flex flex-row gap-1 items-center">
          <UserCircleIcon className="w-5" />
          {order.createdBy == user?.id
            ? "Created By Me"
            : `${order.user.firstName} ${order.user.sirName}`}
        </div>
      </div>
      <div className="flex flex-row items-center gap-1">
        <PencilIcon
          className="hidden group-hover:block text-plum w-5 cursor-pointer"
          onClick={goToEdit}
        />
        {editing && (
          <TrashIcon
            className="hidden group-hover:block text-red-500 w-5 cursor-pointer"
            onClick={excludeOrder}
          />
        )}
      </div>
    </div>
  );
};

interface assignmentFiles {
  process: ProcessType;
  id: number;
  shared?: boolean;
}

const Files = ({ process, id, shared }: assignmentFiles) => {
  const [fileChecking, setFileChecking] = useState(false);
  const [path, setPath] = useState<undefined | string[]>(undefined);

  const initFiles = async () => {
    if (!process) {
      return;
    }

    const jobPath = createPathArr(process);

    if (!jobPath) {
      return;
    }

    const myPath = shared
      ? jobPath.concat("installer")
      : jobPath.concat("assignment", id.toString());

    if (myPath == path) {
      return;
    }

    setFileChecking(true);

    const checkPath = await exists(myPath);

    if (!checkPath) {
      await mkdir(myPath);
      setFileChecking(false);
    } else {
      setFileChecking(false);
    }
    setPath(myPath);
  };

  useEffect(() => {
    initFiles();
  }, [id]);

  return (
    <>
      {/* Files */}
      {fileChecking && (
        <div className="flex flex-row justify-center mt-2">
          <Spinner size="lg" />
        </div>
      )}
      {path && !fileChecking && (
        <div className="flex flex-col gap-2">
          <Label value={`Files (${shared ? "Job" : "Assignment"})`} />
          <div className="dark:bg-gray-700 bg-gray-100 rounded-md px-2 py-3 border-[1px] dark:border-gray-600 border-gray-300">
            <Folder path={path} simple={true} />
          </div>
        </div>
      )}
    </>
  );
};
