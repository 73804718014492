import { Breadcrumb, Tabs } from "flowbite-react";
import {
  BookmarkSquareIcon,
  HomeIcon,
  MinusCircleIcon,
  PhotoIcon,
  RectangleGroupIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Materials from "./materials/materials";
import Deduction from "./deductions/deductions";
import DeductionPresets from "./deductionPresets";
import Layouts from "./layouts";
import ProductImages from "./productImages";
import ProductSets from "./productSet/productSets";
import OrderSummaryColumns from "./orderSummaryColumn/orderSummaryColumns";
import { useOrderStorePopulate } from "../../../../store/orderStore";

export default function OrderRelated() {
  const navigate = useNavigate();

  const isLoading = useOrderStorePopulate();

  if (isLoading) {
    return null;
  }

  return (
    <div className="mb-1 flex-grow mx-auto">
      <section className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-4">
        <nav className="w-full">
          <Breadcrumb className="w-full">
            <Breadcrumb.Item
              className="cursor-pointer"
              onClick={() => {
                navigate("../");
              }}
            >
              <HomeIcon className="w-5 mr-2" />
              Settings
            </Breadcrumb.Item>

            <Breadcrumb.Item>Order</Breadcrumb.Item>
          </Breadcrumb>
        </nav>
        <main className="space-y-4">
          <Tabs.Group style="underline">
            <Tabs.Item icon={Square3Stack3DIcon} title="Product Set">
              <ProductSets />
            </Tabs.Item>
            <Tabs.Item icon={Square2StackIcon} title="Materials">
              <Materials />
            </Tabs.Item>
            <Tabs.Item icon={MinusCircleIcon} title="Deductions">
              <Deduction />
            </Tabs.Item>
            <Tabs.Item icon={BookmarkSquareIcon} title="Deduction Presets">
              <DeductionPresets />
            </Tabs.Item>
            <Tabs.Item icon={RectangleGroupIcon} title="Item Groups">
              <Layouts />
            </Tabs.Item>
            <Tabs.Item icon={PhotoIcon} title="Item Images">
              <ProductImages />
            </Tabs.Item>
            <Tabs.Item icon={ViewColumnsIcon} title="Summary Columns">
              <OrderSummaryColumns />
            </Tabs.Item>
          </Tabs.Group>
        </main>
      </section>
    </div>
  );
}
