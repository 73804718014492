import { useQuery } from "@apollo/client";
import { HomeIcon } from "@heroicons/react/24/solid";
import { Breadcrumb } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { GET_PRODUCTION_BY_PK } from "./gql";
import { GET_RELEASED_ORDER_BY_PK } from "../workorder/orderRelease/gqls";
import { GET_PRODUCT_SET_BY_ID } from "../workorder/gqls";
import { useOrderStore } from "../../../store/orderStore";
import SummaryPage from "../workorder/createOrder/pages/summary/summaryPage";
import ProductTabOpening from "../workorder/createOrder/pages/orderPrint/productTabOpening";
import ProductTab from "../workorder/createOrder/pages/orderPrint/productTab";

export default function PrintProductionOrder() {
  const { productionId, id } = useParams();

  const { data: production_data } = useQuery(GET_PRODUCTION_BY_PK, {
    variables: {
      id: productionId,
    },
  });

  const production = production_data?.productions_by_pk;
  const navigate = useNavigate();

  const { data: order_data } = useQuery(GET_RELEASED_ORDER_BY_PK, {
    variables: {
      id,
    },
  });

  const order = order_data?.releasedOrders_by_pk;

  const { productSets } = useOrderStore();

  const myProductSet = productSets.find(ps => ps.id == order?.productSetId);

  const presets = myProductSet?.orderPresets;
  const myPreset = presets?.find(
    p => p.name == order?.presetName && p.productId == production?.product.id
  );

  const workorder = order?.workorder;

  const locations = workorder?.order?.locations.map((l, i) => ({
    ...l,
    index: i + 1,
    openings: l.openings.map((o, ix) => ({
      ...o,
      items: o.items.map((item, iz) => ({ ...item, index: iz + 1 })),
      index: ix + 1,
    })),
  }));

  const openings = locations?.map(l => l.openings).flat();

  const products = openings?.reduce<
    { id: number; name: string; qty: number; openingProduct: boolean }[]
  >((prev, cur) => {
    const Product = productSets?.find(p => p.id == cur.product);
    if (!Product) {
      return prev;
    }

    const name = Product?.name;
    const isOpeningProduct = Product.hideItems;
    const qty = isOpeningProduct ? 1 : cur.items.length;

    if (prev.find(p => p.name == name)) {
      return prev.map(pro => {
        if (pro.name == name) {
          return { ...pro, qty: pro.qty + qty };
        } else return pro;
      });
    } else {
      return prev.concat({
        id: Product.id,
        name,
        qty,
        openingProduct: Product.hideItems,
      });
    }
  }, []);

  const myProduct = products?.find(p => p.id == order?.productSetId);

  return (
    <main className="px-6 pb-10 select-none flex flex-col h-full">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("/production");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            Production
          </Breadcrumb.Item>
          <Breadcrumb.Item
            className="capitalize cursor-pointer"
            onClick={() => {
              navigate("/production/detail/" + productionId);
            }}
          >
            {production?.process?.name || production?.id}
          </Breadcrumb.Item>
          <Breadcrumb.Item className="capitalize">
            Order Preview
          </Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      {order?.workorder && myProduct && (
        <div className="flex flex-col gap-4 mt-10 max-w-[1130px] mx-auto">
          {/* Summary */}
          <SummaryPage dbOrder={workorder} noPrice={true} />
          {/* WorkOrder */}
          {myProduct.openingProduct ? (
            <ProductTabOpening
              productName={myProduct.name}
              locations={locations}
              customer={workorder?.process.name || ""}
              dbOrder={workorder}
            />
          ) : (
            <ProductTab
              productName={myProduct.name}
              locations={locations}
              customer={workorder?.process.name || ""}
              dbOrder={workorder}
              orderPreset={myPreset}
            />
          )}
        </div>
      )}
    </main>
  );
}
