import { TextInput } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";

interface props {
  coord: string;
}

export default function Note({ coord }: props) {
  const noteCoord = `${coord}.note`;

  const { register, control } = useFormContext();

  const showNotes = useWatch({
    name: "showNotes",
    control,
  });

  if (!showNotes) {
    return null;
  }

  return (
    <div className={`relative group/option min-w-[150px] flex-1 my-[5px] `}>
      <div className="left-[50%] -translate-x-[50%] -top-[8px] text-grass w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
        Note
      </div>
      <TextInput
        className="w-full"
        {...register(noteCoord)}
        placeholder="Enter notes / remarks"
        sizing="sm"
      />
    </div>
  );
}
