import React from "react";

export default function TnC() {
  return (
    <div className="space-y-6">
      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This site uses your information in order to enable the website's
        usability and functions only. By registering your acocunt, you agree to
        share your information (name, email, etc.) with the company and its
        members. Any content generated by the user while using the website will
        remain the website's property.
      </p>
      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        If you do not agree to the abover temrs or wish to have your information
        taken off the website completely upon cessation of the website, please
        do not proceed.
      </p>
    </div>
  );
}
