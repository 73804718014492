import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  addEvent,
  deleteEvent,
  newEventType,
  vacancyType,
} from "../../../../../utils/calendarMethods";
import {
  TextInput,
  Textarea,
  Label,
  ToggleSwitch,
  Select,
  Button,
  Spinner,
  Badge,
} from "flowbite-react";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { useAuthStore } from "../../../../../store/authStore";
import { UserType } from "../../../settings/users/types";
import checkAuth from "../../../../../utils/checkAuth";
import { addHours, hoursToTimeStamp } from "../../../../../utils/dateMethods";
import { z, ZodError } from "zod";
import { fullNumber } from "../../../../../utils/fullNumber";
import { GET_PROCESS_TYPES } from "../../../settings/process/processTypes/processTypes";
import { BreifEvent } from "../../../../../types/calendarTypes";
import { TrashIcon } from "@heroicons/react/24/solid";
import { GET_USERS } from "../../../settings/users/gql";

interface props {
  vacancy?: vacancyType;
  minH: number;
}

const bookingSchema = z
  .object({
    summary: z.string().min(1, { message: "title is required" }),
    description: z.string().optional(),
    allDay: z.boolean(),
    calId: z.string().min(1, { message: "user must be selected" }),
    date: z.string(),
    startTime: z.string(),
    endTime: z.string(),
  })
  .refine(
    booking => {
      return (
        hoursToTimeStamp(booking.startTime) < hoursToTimeStamp(booking.endTime)
      );
    },
    {
      message: "invalid timeframe",
      path: ["time"],
    }
  );

export default function Booking({ vacancy, minH }: props) {
  const { watch, setValue } = useFormContext();
  const name = watch("name");
  const typeId = watch("typeId");
  const year = watch("year");
  const number = watch("number");
  const newContact = watch("newContact");
  const contacts = watch("contact");
  const address = watch("address");

  const { user } = useAuthStore();

  const [bookings, setBookings] = useState<BreifEvent[]>([]);

  useEffect(() => {
    const scheduleIds = bookings.map(b => `${b.calId}::${b.id}`);
    setValue("schedules", scheduleIds);
  }, [bookings]);

  const initialBookingValue = {
    summary: `Quote`,
    description: "",
    date: dayjs(new Date()).format("YYYY-MM-DD"),
    startTime: dayjs(new Date().setHours(8, 0, 0, 0)).format("HH:mm"),
    endTime: dayjs(new Date().setHours(10, 0, 0, 0)).format("HH:mm"),
    allDay: false,
    calId: "",
  };

  const [booking, setBooking] = useState(initialBookingValue);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<ZodError | undefined>(undefined);

  const errorCheck = () => {
    try {
      bookingSchema.parse(booking);
      setErrors(undefined);
      return true;
    } catch (error) {
      setErrors(error as ZodError);
      return false;
    }
  };

  const submit = async () => {
    setLoading(true);
    const canProceed = errorCheck();
    if (!canProceed || !data_process_type) {
      return;
    }
    const type = data_process_type.processType.find(pt => pt.id == typeId);
    const prefix = type?.prefix || "";
    const sales = users.find(u => u.calId?.toString() == booking.calId);
    const fullNum = `[${fullNumber(prefix, year, number, sales)}]`;

    const summary = `${fullNum} ${name} / ${booking.summary}`;

    const contact = newContact
      ? newContact
      : contacts
      ? contacts[0]
      : undefined;

    let contactName = "";
    let contactNumber = "";

    if (contact) {
      contactName = contact.name;
      contactNumber = contact.number;
    }

    const description = `
    ${contactName} ${contactNumber}
    ${booking.description}

    Organised by ${user?.firstName} ${user?.sirName}
    `;

    const start = booking.startTime.split(":");
    const end = booking.endTime.split(":");

    const startDateTime = new Date(booking.date).setHours(
      Number(start[0]),
      Number(start[1])
    );
    const endDateTime = new Date(booking.date).setHours(
      Number(end[0]),
      Number(end[1])
    );

    const event: newEventType = {
      summary,
      calId: booking.calId,
      start: {
        dateTime: new Date(startDateTime),
      },
      end: {
        dateTime: new Date(endDateTime) || "",
      },
      description,
      location: address,
    };

    try {
      const newEvent = await addEvent(event);
      if (newEvent) {
        setBookings([...bookings, newEvent]);
        setBooking({ ...initialBookingValue, calId: booking.calId });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getError = (path: string) => {
    const err = errors?.errors?.find(e => e.path.includes(path))?.message;
    return err;
  };

  useEffect(() => {
    errorCheck();
  }, [booking]);

  useEffect(() => {
    if (!vacancy) {
      return;
    }

    setBooking({
      ...booking,
      date: dayjs(vacancy.date).format("YYYY-MM-DD"),
      startTime: dayjs(vacancy.start).format("HH:mm"),
      endTime: dayjs(addHours(vacancy.start, minH)).format("HH:mm"),
      allDay: false,
      calId: vacancy.user.calId?.toString() || "",
    });
  }, [vacancy]);

  const { data: data_user, error } = useQuery(GET_USERS);
  const { data: data_process_type, error: error_pt } =
    useQuery(GET_PROCESS_TYPES);

  if (error_pt) {
    console.log(error_pt);
  }

  const users: UserType[] = data_user
    ? data_user.users.filter(
        u =>
          u.active &&
          u.calId &&
          u.calId !== "" &&
          checkAuth([
            "sudo",
            {
              permission: "process_access_self",
              checkGroup: "userTeam",
              conditionGroup: [u.team?.id || -1],
            },
          ])
      )
    : [];

  if (error) {
    console.log(error);
  }

  const removeEvent = async (event: BreifEvent) => {
    const proceed = confirm("delete event?");
    if (!proceed) {
      return;
    }
    try {
      await deleteEvent({ calId: event.calId || "", id: event.id });
      setBookings(bookings.filter(e => e.id !== event.id));
    } catch (error) {
      console.log(error);
    }
  };

  const openEvent = (id: string) => {
    window.open(id, "_blank");
  };

  return (
    <div className="space-y-2 flex-grow flex flex-col min-w-[250px]">
      <h2>Booking</h2>
      <div className="shadow-md flex-1 bg-white dark:bg-gray-800 rounded-md p-4 space-y-2">
        {/* Title */}
        <div className="space-y-2">
          <Label value="Title" />
          <TextInput
            type="text"
            value={booking.summary}
            sizing="sm"
            onChange={e => {
              setBooking({
                ...booking,
                summary: e.target.value,
              });
            }}
            helperText={getError("summary")}
            color={getError("summary") ? "failure" : undefined}
          />
        </div>
        {/* Date */}
        <div className="space-y-2">
          <Label value="Date" />
          <TextInput
            type="date"
            sizing="sm"
            value={booking.date}
            onChange={e => {
              const date = e.target.value;
              setBooking({
                ...booking,
                date,
              });
            }}
          />
        </div>
        {/* All day? */}
        <div className="space-y-2 py-1 flex justify-end">
          <ToggleSwitch
            label="All day"
            //@ts-expect-error
            color="purple"
            checked={booking.allDay}
            onChange={() => {
              setBooking({
                ...booking,
                allDay: !booking.allDay,
              });
            }}
          />
        </div>
        {/* Time */}
        {!booking.allDay && (
          <>
            <div className="flex flex-row flex-wrap gap-2">
              {/* Start */}
              <div className="space-y-2 flex-1">
                <Label value="Start" />
                <TextInput
                  type="time"
                  sizing="sm"
                  step={60 * 15}
                  max={booking.endTime}
                  value={booking.startTime}
                  color={getError("time") ? "failure" : undefined}
                  onChange={e => {
                    const time = e.target.value;
                    setBooking({
                      ...booking,
                      startTime: time,
                    });
                  }}
                />
              </div>
              {/* End */}
              <div className="space-y-2 flex-1">
                <Label value="End" />
                <TextInput
                  type="time"
                  sizing="sm"
                  step={60 * 15}
                  color={getError("time") ? "failure" : undefined}
                  value={booking.endTime}
                  min={booking.startTime}
                  onChange={e => {
                    const time = e.target.value;
                    setBooking({
                      ...booking,
                      endTime: time,
                    });
                  }}
                />
              </div>
            </div>
            {getError("time") && (
              <div className="text-sm text-red-500">{getError("time")}</div>
            )}
          </>
        )}
        {/* Desc */}
        <div className="space-y-2">
          <Label value="Desc" />
          <Textarea
            value={booking.description}
            rows={3}
            onChange={e => {
              setBooking({
                ...booking,
                description: e.target.value,
              });
            }}
            className="text-sm"
          />
        </div>
        {/* By */}
        <div className="space-y-2">
          <Label value="By" />
          <Select
            sizing="sm"
            value={booking.calId}
            helperText={getError("calId")}
            color={getError("calId") ? "failure" : undefined}
            onChange={e => {
              const val = e.target.value;
              setBooking({
                ...booking,
                calId: val,
              });
            }}
          >
            <option value="">Select user</option>
            {users.map(u => (
              <option key={u.id} value={u.calId?.toString()}>
                {u.firstName} {u.sirName}
              </option>
            ))}
          </Select>
        </div>
        {/* Submit */}
        <div className="pt-2">
          <Button
            size="sm"
            gradientDuoTone="purpleToBlue"
            fullSized
            onClick={submit}
          >
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Send Booking
          </Button>
        </div>
        {/* Bookings */}
        {bookings.length > 0 && (
          <div className="pt-2 space-y-2 w-full">
            <Label value="Added" />
            {bookings.map(event => (
              <div
                key={event.id}
                className="flex flex-row justify-between gap-2  w-full"
              >
                <Badge
                  color="purple"
                  className="flex-1 cursor-pointer group overflow-clip"
                  onClick={() => {
                    event.htmlLink && openEvent(event.htmlLink);
                  }}
                >
                  <div className="truncate w-0 min-w-fit">{event.summary}</div>
                  <div className="group-hover:flex flex-col hidden">
                    <div>
                      🕓{dayjs(event.start).format("H:mm")} -{" "}
                      {dayjs(event.end).format("H:mm")}
                    </div>
                    <span className="truncate w-0 min-w-fit ">
                      🏴{event.location ? event.location : ""}
                    </span>
                  </div>
                </Badge>
                <TrashIcon
                  onClick={() => {
                    removeEvent(event);
                  }}
                  className="w-4 text-red-500 cursor-pointer"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
