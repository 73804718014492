import { PencilIcon } from "@heroicons/react/24/solid";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Button, Spinner } from "flowbite-react";
import { UPDATE_CONTACT_BY_PK } from "./gqls";
import { useMutation } from "@apollo/client";
import { addAlert } from "../../../store/alertStore";
import { ContactType } from "./types";

const resolver = z.object({
  name: z.string().min(1, { message: "name cannot be empty" }),
});

interface props {
  contact: ContactType;
}

export default function ProcessName({ contact }: props) {
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const { name } = contact;

  const {
    reset,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: {
      name,
    },
  });

  const [update_contact_name, { loading }] = useMutation(UPDATE_CONTACT_BY_PK);

  const onSubmit = handleSubmit(async data => {
    if (!isDirty) {
      return addAlert({ message: "nothing changed", type: "warning" });
    }

    update_contact_name({
      variables: {
        id: contact.id,
        set: {
          name: data.name,
        },
      },
      onCompleted() {
        reset({ name: data.name });
        toggleEditing();
      },
    });
  });

  const cancel = () => {
    reset();
    toggleEditing();
  };

  return (
    <form
      onSubmit={onSubmit}
      className="group flex-1 flex flex-row items-center gap-2 justify-between @md:justify-start"
    >
      {editing ? (
        <input
          className="bg-transparent outline-none opacity-70 text-2xl w-full"
          autoFocus
          {...register("name")}
        />
      ) : (
        <h1>{contact.name}</h1>
      )}
      {/* TODO AuthCheck */}
      <div>
        {editing ? (
          <div className="flex flex-row items-center gap-2">
            <Button size="xs" color="gray" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              update
            </Button>
          </div>
        ) : (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 hover:text-grass hidden group-hover:block cursor-pointer mr-1"
          />
        )}
      </div>
    </form>
  );
}
