import { useState, MouseEvent } from "react";
import { useAuthStore } from "../../../store/authStore";
import { TextInput, Label, Button, Spinner, Modal } from "flowbite-react";
import { useScheme } from "../../../store/schemeStore";
import { PencilIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql, useMutation } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import Howtosync from "./howtosync";

export const calIdSchema = z.object({
  calId: z.string().email({ message: "Please input a valid email" }),
});

export const CHANGE_CALID = gql`
  mutation ChangeCalId($calId: String!, $id: Int!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { calId: $calId }) {
      id
      calId
    }
  }
`;

export default function CalId() {
  const { user, setUser } = useAuthStore();

  const [change_name, { loading, error }] = useMutation(CHANGE_CALID);
  if (error) {
    console.log(error);
  }
  const [editing, setEditing] = useState(false);

  const { scheme } = useScheme();
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(calIdSchema),
    defaultValues: {
      calId: user?.calId || "",
    },
  });

  const onSubmit = handleSubmit(async calId => {
    change_name({
      variables: { id: user?.id, ...calId },
      onCompleted(data) {
        if (data?.update_users_by_pk) {
          const { calId } = data.update_users_by_pk;

          if (user) {
            setUser({
              ...user,
              calId,
            });
          }

          reset({
            calId,
          });
        }
      },
    });
    setEditing(state => !state);
  });

  const clickEdit = (e: MouseEvent) => {
    if (!editing) {
      e.preventDefault();
      setEditing(state => !state);
    }
  };

  const clickCancle = () => {
    setEditing(false);
    reset();
  };

  return (
    <form className="mb-1 flex-grow  mx-auto" onSubmit={onSubmit}>
      <div className=" flex flex-row justify-between">
        <div className="text-2xl font-bold mb-2">Calendar Sync</div>
        <InformationCircleIcon
          onClick={openModal}
          className="w-5 text-plum cursor-pointer animate-[spin_1s_ease-in-out]"
        />
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 ">
        <div className="mb-2 block mt-2">
          <Label htmlFor="calId" value="Calendar Id" />
        </div>
        <TextInput
          addon="@"
          type="text"
          disabled={!editing}
          {...register("calId")}
          color={errors.calId?.message ? "failure" : undefined}
          helperText={
            editing
              ? errors.calId?.message || (
                  <span className="italic">
                    Please read the{" "}
                    <a className="cursor-pointer text-plum" onClick={openModal}>
                      instruction
                    </a>{" "}
                    on how to activate the sync
                  </span>
                )
              : ""
          }
        />

        <hr className="my-4 border-gray-300 dark:border-gray-500" />
        <div className="flex space-x-2 justify-end">
          {(editing || loading) && (
            <Button
              gradientDuoTone="purpleToBlue"
              size="sm"
              onClick={clickCancle}
            >
              Cancel
            </Button>
          )}

          <Button
            onClick={clickEdit}
            outline
            type="submit"
            gradientDuoTone="purpleToBlue"
            size="sm"
          >
            {!editing && !loading && <PencilIcon className="w-4 mr-2" />}
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            {editing || loading ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        className={`${
          scheme === "dark" ? "dark" : ""
        } !h-screen md:h-[calc(100% - 2rem)]`}
      >
        <Modal.Header>Sync Your Google Calendar</Modal.Header>
        <Modal.Body>
          <Howtosync />
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button
            gradientDuoTone="purpleToBlue"
            size="sm"
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
}
