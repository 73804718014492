import { create } from "zustand";

type Store = {
  cursor: string;
  setCursor: (str: string) => void;
};

export const useTaskStore = create<Store>(set => ({
  cursor: new Date().toISOString(),
  setCursor(cursor: string) {
    return set(state => ({
      ...state,
      cursor,
    }));
  },
}));
