import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useScheme } from "../../../../../store/schemeStore";
import { MaterialsType } from "../../../materials/types";
import { optionValues } from "../../../workorder/types";
import { useState } from "react";
import isNullish from "../../../../../utils/isNullish";
import { Button, Label, Select, TextInput } from "flowbite-react";
import {
  ArrowTopRightOnSquareIcon,
  DocumentDuplicateIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/solid";
import OptionValueControl from "../optionValuesControl";
import OptionValuesArray from "../optionValuesArray";
import { useOrderStore } from "../../../../../store/orderStore";

interface usedMat {
  id: number;
  qty: string;
  unit: number;
  optionConditions?: optionValues[];
}

export default function UsedMats({
  availableMats,
}: {
  availableMats?: MaterialsType[];
}) {
  const { scheme } = useScheme();
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: "usedItems",
    control,
  });

  const { units } = useOrderStore();

  const addedMats: usedMat[] = useWatch({
    name: "usedItems",
    control,
  });

  const defaultValues: usedMat = {
    id: 0,
    qty: "",
    unit: 1,
    optionConditions: [],
  };

  const [newMat, setNewMat] = useState(defaultValues);

  const add = () => {
    if (
      isNullish(newMat.id, true) ||
      isNullish(newMat.qty, true) ||
      isNullish(newMat.unit, true)
    ) {
      return;
    }
    append(newMat);
    setNewMat(defaultValues);
  };

  const ProductSetID: number = useWatch({
    name: "productSetID",
    control,
  });

  const addOptionCondition = (optionValues: optionValues) => {
    if (!newMat.optionConditions) {
      return;
    }
    setNewMat({
      ...newMat,
      optionConditions: newMat.optionConditions.concat(optionValues),
    });
  };

  const removeOptionCondition = (id: number) => {
    if (!newMat.optionConditions) {
      return;
    }
    setNewMat({
      ...newMat,
      optionConditions: newMat.optionConditions.filter(oc => oc.option !== id),
    });
  };

  const addedOptionConditionIds =
    newMat.optionConditions?.map(o => o.option) || [];

  const copy = (index: number) => {
    const mat = addedMats[index];
    setNewMat({
      qty: mat.qty,
      id: 0,
      unit: mat.unit,
      optionConditions: mat.optionConditions,
    });
  };

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Mat */}
      <div className="flex flex-col gap-2">
        {/* default material data */}
        <Label value="Material Data" />
        <div className="flex flex-row gap-2 items-center">
          <Select
            addon="Mat"
            sizing="sm"
            value={newMat.id}
            onChange={e => {
              setNewMat({ ...newMat, id: Number(e.target.value) });
            }}
          >
            <option value="">select material</option>
            {availableMats
              ?.filter(mat => !addedMats.find(m => m.id == mat.id))
              .map(p => (
                <option key={p.id} value={p.id}>
                  {p.brand} {p.name} {p.color}{" "}
                  {isNullish(p.size, true) ? "" : p.size}
                </option>
              ))}
          </Select>
          <div className="flex-1 relative items-center">
            <TextInput
              className="w-full"
              sizing="sm"
              addon="Qty"
              placeholder="Qty Formula"
              value={newMat.qty}
              onChange={e => {
                setNewMat({ ...newMat, qty: e.target.value });
              }}
            />
            <ArrowTopRightOnSquareIcon
              onClick={() => {
                window.open("https://mathnotepad.com/");
              }}
              className="absolute cursor-pointer right-2 top-1/2 -translate-y-1/2 w-5 dark:text-white text-gray-600"
            />
          </div>
          <Select
            sizing="sm"
            addon="Unit"
            placeholder="Unit"
            className="w-40"
            value={newMat.unit}
            onChange={e => {
              setNewMat({ ...newMat, unit: Number(e.target.value) });
            }}
          >
            {units.map(u => (
              <option key={u.id} value={u.id}>
                {u.name}
              </option>
            ))}
          </Select>
        </div>
        {/* conditions */}
        <Label value="Add Material conditions" />
        <OptionValueControl
          cb={addOptionCondition}
          optionIdsToFilter={addedOptionConditionIds}
          productSetID={ProductSetID}
        />
        <Label value="Added Material conditions" />
        <div className="flex flex-col dark:text-white">
          <OptionValuesArray
            optionValuesArray={newMat.optionConditions}
            productSetId={ProductSetID}
            remove={removeOptionCondition}
          />
        </div>
        <div className="flex flex-row justify-end">
          <Button
            onClick={add}
            outline
            size="xs"
            gradientDuoTone="greenToBlue"
            className="w-fit"
          >
            Add Material
          </Button>
        </div>
      </div>
      {fields.length > 0 && (
        <div className="flex flex-col dark:text-white">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-t-md flex flex-row gap-2 items-center">
            <div className="px-2 py-[5px] w-96">Mat</div>
            <div className="px-2 py-[5px] flex-1">Qty Formula</div>
            <div className="px-2 py-[5px] flex-1">Options</div>
            <div className="px-2 py-[5px] w-28">Unit</div>
            <div className="px-2 py-[5px] w-16" />
          </div>
          {fields.map((item, index) => (
            <div
              key={item.id}
              className="rounded-md flex flex-row gap-2 items-center"
            >
              <div className="px-2 py-[5px] w-96 overflow-clip">
                <select
                  // disabled
                  {...register(`usedItems.${index}.id`)}
                  className="p-0 bg-transparent border-none text-sm"
                >
                  {availableMats?.map(p => (
                    <option key={p.id} value={p.id} className="dark:text-black">
                      {p.brand} {p.name} {p.color}{" "}
                      {isNullish(p.size, true) ? "" : p.size}
                    </option>
                  ))}
                </select>
              </div>
              <div className="px-2 py-[5px] flex-1">
                <input
                  className="bg-transparent w-full"
                  {...register(`usedItems.${index}.qty`)}
                />
              </div>
              <div className="px-2 py-[5px] flex-1">
                <OptionValuesArray
                  productSetId={ProductSetID}
                  optionValuesArray={addedMats[index]?.optionConditions}
                />
              </div>
              <div className="px-2 py-[5px] w-28">
                <select
                  className={`${
                    scheme == "dark" ? "text-white" : "text-black"
                  } bg-transparent w-full
                  border-none outline-none
                  text-sm
                  py-0 px-0
                  `}
                  {...register(`usedItems.${index}.unit`)}
                >
                  {units.map(u => (
                    <option
                      key={u.id}
                      value={u.id}
                      className={`${
                        scheme == "dark" ? "text-black" : "text-white"
                      } text-sm`}
                    >
                      {u.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="px-2 py-[5px] w-16 flex flex-row gap-2">
                <MinusCircleIcon
                  className="text-red-500 w-5 cursor-pointer"
                  onClick={() => {
                    remove(index);
                  }}
                />
                <DocumentDuplicateIcon
                  className="text-plum w-5 cursor-pointer"
                  onClick={() => {
                    copy(index);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
