import { Badge, Checkbox, Table } from "flowbite-react";
import getContrast from "../../../utils/getContrast";
import { ContactType } from "./types";
import { useNavigate } from "react-router-dom";
import { CheckBadgeIcon, StopIcon } from "@heroicons/react/24/solid";

interface props {
  contact: ContactType;
}

export default function contact({ contact }: props) {
  const navigate = useNavigate();
  const goToDetail = () => {
    navigate("/contacts/detail/" + contact.id);
  };
  return (
    <Table.Row
      onClick={goToDetail}
      className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
    >
      <Table.Cell>
        <Badge
          style={{
            backgroundColor: contact.contactType.color,
            color: getContrast(contact.contactType.color || ""),
          }}
          className="w-min select-none"
        >
          {contact.contactType.name}
        </Badge>
      </Table.Cell>
      <Table.Cell
        className="truncate max-w-[200px] 
      @md:max-w-[250px] supports-[not(container-type:inline-size)]:md:max-w-[250px]
      @lg:max-w-[300px] supports-[not(container-type:inline-size)]:lg:max-w-[300px]
      "
      >
        {contact.name}
      </Table.Cell>
      <Table.Cell className="hidden @xl:table-cell supports-[not(container-type:inline-size)]:xl:table-cell">
        {contact.isOrganisation ? (
          <Checkbox
            checked
            disabled
            className="checked:bg-plum dark:checked:bg-plum"
          />
        ) : (
          <Checkbox disabled />
        )}
      </Table.Cell>
      <Table.Cell className="hidden @lg:table-cell supports-[not(container-type:inline-size)]:lg:table-cell">
        {contact.role || "Nill"}
      </Table.Cell>
      <Table.Cell className="hidden @xs:table-cell supports-[not(container-type:inline-size)]:xs:table-cell">
        {contact.number}
      </Table.Cell>
      <Table.Cell
        className="hidden @sm:table-cell supports-[not(container-type:inline-size)]:sm:table-cell
      truncate max-w-[250px]
      "
      >
        {contact.mail}
      </Table.Cell>
      <Table.Cell
        className="hidden truncate @md:table-cell supports-[not(container-type:inline-size)]:md:table-cell
      @md:max-w-[250px] supports-[not(container-type:inline-size)]:md:max-w-[250px]
      @lg:max-w-[300px] supports-[not(container-type:inline-size)]:lg:max-w-[300px]
      "
      >
        {contact.address}
      </Table.Cell>
    </Table.Row>
  );
}
