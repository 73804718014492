import axios from "axios";
const API = import.meta.env.VITE_API;

interface props {
  title: string;
  body: string;
  ids: number[];
  path?: string;
}

export const push = async ({ title, body, ids, path }: props) => {
  try {
    const res = await axios.post(API + "/push", { title, body, ids, path });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
