import { SetStateAction, useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Autocomplete,
  AutocompleteProps,
  LoadScriptProps,
} from "@react-google-maps/api";
import "./map.css";
import { useScheme } from "../../../../../store/schemeStore";
import { Badge, Button, TextInput } from "flowbite-react";
import {
  ArrowsPointingInIcon,
  ArrowTopRightOnSquareIcon,
  FlagIcon,
  MapIcon,
} from "@heroicons/react/24/solid";
import { useFormContext } from "react-hook-form";
import { MarkerProp } from "../../types";
import { Dispatch } from "react";
import MapMarker from "./marker";
import Line, { myLineProps } from "./line";
import { default as config } from "../../../../../config";
import axios from "axios";
import { darkStyles, googleMapOptions, lightStyles } from "../../mapOptions";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
const LINXIO_ID = import.meta.env.VITE_LINXIO_ID;
const LINXIO_PW = import.meta.env.VITE_LINXIO_PW;

const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];
const companyLoc = config.companyLoc;

export interface MapsProps {
  showSchedule: boolean;
  setShowSchedule: Dispatch<SetStateAction<boolean>>;
  markers: MarkerProp[];
  setMarkers: Dispatch<SetStateAction<MarkerProp[]>>;
  lines: myLineProps[];
  setLines: Dispatch<SetStateAction<myLineProps[]>>;
  isOpen?: boolean;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
}

export interface VehicleType {
  name: string;
  type: string;
  id: number;
  regNo: string;
  status: string;
}

export default function Maps({
  showSchedule,
  setShowSchedule,
  markers,
  setMarkers,
  lines,
  setLines,
  isOpen,
  setIsOpen,
}: MapsProps) {
  const { scheme } = useScheme();

  const [token, setToken] = useState("");

  // const socket = io("https://track.linxio.com/coordinates", {
  //   transports: ["websocket"],
  //   reconnectionAttempts: 5,
  //   query: {
  //     token: token,
  //   },
  // });

  // const [isConnected, setIsConnected] = useState(socket.connected);
  const [vehicles, setVehicles] = useState<VehicleType[]>([]);
  const [vehicleMarkers, setVehicleMarkers] = useState<MarkerProp[]>([]);

  const getToken = async () => {
    const res = await axios.post(
      "https://api.linxio.com/api/login",
      {
        email: LINXIO_ID,
        password: LINXIO_PW,
      },
      {
        withCredentials: false,
      }
    );

    if (res?.data) {
      setToken(res.data.token);
      return true;
    } else {
      return false;
    }
  };

  // useEffect(() => {
  //   if (!token) {
  //     getToken();
  //   }
  //   if (token) {
  //     if (vehicles.length < 1) {
  //       axios
  //         .get("https://api.linxio.com/api/vehicles/fields/json", {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //           withCredentials: false,
  //         })
  //         .then(d => {
  //           if (d.data) {
  //             // console.log(d.data.data);
  //             const _vehicles: VehicleType[] = d.data.data?.map(
  //               (vehicle: any) => ({
  //                 name: vehicle.defaultLabel || "",
  //                 type: vehicle.type || "",
  //                 id: vehicle.id || 0,
  //                 regNo: vehicle.regNo || "",
  //                 status: vehicle.status || "",
  //               })
  //             );

  //             setVehicles(_vehicles);
  //           }
  //         });
  //     }

  //     //@ts-expect-error
  //     socket.on("error", error => {
  //       if (error === "AUTH_FAILED") {
  //         console.log("auth failed");
  //       }
  //       console.log(error);
  //       getToken();
  //     });
  //     //@ts-expect-error
  //     socket.on("connect_error", error => {
  //       console.log(error);
  //       getToken();
  //     });

  //     socket.on("connect", () => {
  //       setIsConnected(true);
  //       console.log("connected");

  //       if (vehicles.length > 0) {
  //         socket.emit("subscribe", { vehicleIds: vehicles.map(v => v.id) });

  //         //@ts-expect-error
  //         socket.on("coordinates", coordinates => {
  //           // console.log(coordinates);
  //         });
  //       }
  //     });

  //     socket.on("disconnect", () => {
  //       setIsConnected(false);
  //     });
  //   }

  //   return () => {
  //     socket.off("connect");
  //     socket.off("disconnect");
  //     socket.off("pong");
  //   };
  // }, [token, vehicles]);

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const [center, setCenter] = useState(companyLoc);
  const notCentre = center.lat !== -27.615648 || center.lng !== 153.115682;

  const { setValue } = useFormContext();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });

  if (!isLoaded) return <div>Loading...</div>;

  const mapOptions = {
    ...googleMapOptions,
    styles: scheme == "dark" ? darkStyles : lightStyles,
  };

  const autoCompleteLoaded = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setLines([]);

      const {
        formatted_address: address,
        address_components,
        geometry,
      } = place;
      const { lat, lng } = {
        lat: geometry?.location?.lat(),
        lng: geometry?.location?.lng(),
      };

      const loc = lat && lng ? { lat, lng } : null;
      if (loc) {
        setCenter(loc);
        setMarkers(state =>
          state
            .filter(s => s.label?.toLowerCase() !== "customer")
            .concat({
              label: "Customer",
              position: loc,
              icon: <FlagIcon className="w-6" />,
            })
        );
      }
      const postCode = address_components
        ? address_components[address_components.length - 1].long_name
        : null;

      if (postCode) {
        setValue("postCode", Number(postCode));
      }

      if (address) {
        setValue(
          "address",
          address
            .replace(", Australia", "")
            .replace(postCode || "", "")
            .trim()
        );
      }
    }
  };

  const autocompleteOptions: AutocompleteProps["options"] = {
    componentRestrictions: { country: "au" },
    bounds: {
      north: companyLoc.lat + 3,
      south: companyLoc.lat - 3,
      east: companyLoc.lng + 3,
      west: companyLoc.lng - 3,
    },
  };

  const resetCentre = () => {
    setCenter(companyLoc);
  };

  const customerMarker = markers.find(marker => marker?.label == "Customer");

  const toggleSchedule = () => {
    setShowSchedule(!showSchedule);
  };

  return (
    <div
      className={`relative overflow-hidden ${scheme == "dark" ? "dark" : ""} ${
        isOpen ? "h-screen" : " my-[-5vh] h-[70vh] max-h-[700px]"
      }`}
    >
      <div
        className={`z-10 w-full h-full absolute pointer-events-none vignette${
          scheme == "dark" ? "-dark" : ""
        } `}
      />
      <div
        className={`absolute z-20 flex items-center gap-2 ${
          isOpen ? "mb-10 bottom-0" : "bottom-[8%]"
        }  right-[2.4%] cursor-pointer`}
      >
        {notCentre && (
          <Badge color="gray" className="shadow-sm" onClick={resetCentre}>
            <ArrowsPointingInIcon className="w-5 py-[6px]" />
          </Badge>
        )}

        {customerMarker && (
          <Button
            size="sm"
            outline
            gradientDuoTone="purpleToBlue"
            onClick={toggleSchedule}
          >
            {showSchedule ? "Hide Schedule" : "Manage Schedule"}
          </Button>
        )}

        {!isOpen && (
          <Badge
            color="gray"
            className="shadow-sm"
            onClick={() => {
              if (setIsOpen) {
                setIsOpen(true);
              }
            }}
          >
            <ArrowTopRightOnSquareIcon className="w-5 py-[6px]" />
          </Badge>
        )}
      </div>

      <GoogleMap
        zoom={12}
        tilt={0}
        center={center}
        options={mapOptions}
        mapContainerClassName="map-container"
      >
        <Autocomplete
          onLoad={autoCompleteLoaded}
          onPlaceChanged={onPlaceChanged}
          options={autocompleteOptions}
          fields={[
            "formatted_address",
            "geometry.location",
            "address_component",
          ]}
        >
          <TextInput
            icon={MapIcon}
            type="text"
            sizing="sm"
            className={`w-[96%] md:w-fit absolute ${
              isOpen ? "mt-10" : "top-[12%]"
            }  right-[2.4%] z-50`}
          />
        </Autocomplete>

        {markers.map((marker, i) => (
          <MapMarker key={i} marker={marker} />
        ))}
        {lines.map((line, i) => (
          <Line key={i} {...line} />
        ))}
      </GoogleMap>
    </div>
  );
}
