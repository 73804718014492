import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { Timeline } from "flowbite-react";
import { useState } from "react";
import DynamicHeroIcon from "../../../../comps/hIcon";
import { processDetailSubProps, ProcessHistory } from "../types";
import { sort } from "fast-sort";
import { GET_USERS } from "../../settings/users/gql";

export default function ProcessHistories({ process }: processDetailSubProps) {
  const { histories } = process;
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  const sorted = sort(histories || []).desc("date");

  return (
    <div
      className="col-span-full
      flex flex-col gap-4 justify-start
  "
    >
      <div className="flex flex-row justify-between items-center">
        <h2>Histories</h2>
        <div
          onClick={toggleShow}
          className="flex flex-row items-center gap-2 hover:text-plum cursor-pointer"
        >
          <DynamicHeroIcon
            icon={show ? "EyeIcon" : "EyeSlashIcon"}
            class="w-5"
          />
          {show ? "Hide" : "Show"}
        </div>
      </div>
      {show && (
        <Timeline>
          {sorted?.map((history, i) => (
            <Item key={i} history={history} />
          ))}
          <Timeline.Item>
            <Timeline.Point />
            <Timeline.Content>
              <Timeline.Time>
                {dayjs(process.created_at).format("DD MMM YYYY")}
              </Timeline.Time>

              <Timeline.Body>item created</Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
        </Timeline>
      )}
    </div>
  );
}

const Item = ({ history }: { history: ProcessHistory }) => {
  const { data } = useQuery(GET_USERS);
  const users = data?.users;
  const by = users?.find(u => u.id == history.by);
  return (
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>
          {dayjs(history.date).format("DD MMM YYYY")}
        </Timeline.Time>

        <Timeline.Body>
          {history.message} {by && `by ${by.firstName} ${by.sirName}`}
        </Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
  );
};
