import { gql, useQuery, TypedDocumentNode, useMutation } from "@apollo/client";
import { PRODUCTION_STATUS_CORE_FIELDS } from "./fragments";
import { ProductionStatusArrayType, ProductionStatusType } from "./types";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
  TouchSensor,
  DragOverlay,
  Active,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useState, useMemo } from "react";
import { createPortal } from "react-dom";
import ProductionStatus from "./productionStatus";
import { addAlert } from "../../../../../store/alertStore";
import { sort } from "fast-sort";
import { Spinner, Button } from "flowbite-react";
import checkAuth from "../../../../../utils/checkAuth";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ProductType } from "../../products/types";

export const GET_PRODUCTION_STATUSES_BY_PID: TypedDocumentNode<ProductionStatusArrayType> = gql`
  ${PRODUCTION_STATUS_CORE_FIELDS}
  query GET_PRODUCTION_STATUSES_BY_PID($id: Int!) {
    productionStatus(where: { productID: { _eq: $id } }) {
      ...ProductionStatusCoreFields
    }
  }
`;

export const GET_PRODUCTION_STATUSES_NO_PID: TypedDocumentNode<ProductionStatusArrayType> = gql`
  ${PRODUCTION_STATUS_CORE_FIELDS}
  query GET_PRODUCTION_STATUSES_NO_PID {
    productionStatus(where: { productID: { _is_null: true } }) {
      ...ProductionStatusCoreFields
    }
  }
`;

export const SORT_PRODUCTION_STATUS = gql`
  ${PRODUCTION_STATUS_CORE_FIELDS}
  mutation SORT_PRODUCTION_STATUSES($id: Int!, $priority: Int!) {
    update_productionStatus_by_pk(
      pk_columns: { id: $id }
      _set: { priority: $priority }
    ) {
      ...ProductionStatusCoreFields
    }
  }
`;

export const INSERT_PRODUCTION_STATUS = gql`
  ${PRODUCTION_STATUS_CORE_FIELDS}
  mutation INSERT_PRODUCTION_STATUSES(
    $name: String!
    $description: String!
    $color: String!
    $priority: Int!
    $productID: Int
  ) {
    insert_productionStatus_one(
      object: {
        name: $name
        description: $description
        color: $color
        priority: $priority
        productID: $productID
      }
    ) {
      ...ProductionStatusCoreFields
    }
  }
`;

export default function ProductionStatuses({
  product,
}: {
  product?: ProductType;
}) {
  const [sort_production_status] = useMutation(SORT_PRODUCTION_STATUS);
  const [insert_production_status] = useMutation(INSERT_PRODUCTION_STATUS);

  const [sortedProductionStatus, setSortedProductionStatus] = useState<
    ProductionStatusType[]
  >([]);

  const [touched, setTouched] = useState(false);

  const { data, loading, error, refetch } = useQuery(
    product ? GET_PRODUCTION_STATUSES_BY_PID : GET_PRODUCTION_STATUSES_NO_PID,
    {
      variables: { id: product?.id },
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (data.productionStatus) {
          const sortedProductionStatus = sort(data?.productionStatus)
            .asc(ps => ps.priority)
            .map((ps, i) => ({ ...ps, priority: i + 1 }));
          setSortedProductionStatus(sortedProductionStatus);
        }
      },
    }
  );

  if (error) {
    console.log(error);
    addAlert({
      message: "could not load production statuses",
      type: "failure",
    });
  }

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedProductionStatus.find(item => item.priority === theActive?.id),
    [theActive, sortedProductionStatus]
  );

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedProductionStatus.findIndex(
        ({ priority }) => priority === active.id
      );
      const overIndex = sortedProductionStatus.findIndex(
        ({ priority }) => priority === over.id
      );

      const movedArray = arrayMove(
        sortedProductionStatus,
        activeIndex,
        overIndex
      );

      setSortedProductionStatus(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const reset = () => {
    if (data?.productionStatus) {
      const sortedProductionStatus = sort(data?.productionStatus)
        .asc(ps => ps.priority)
        ?.map((ps, i) => ({ ...ps, index: i + 1 }));

      setSortedProductionStatus(sortedProductionStatus);
    }

    setTouched(false);
  };

  const [updating, setUpdating] = useState(false);

  const save = async () => {
    setUpdating(true);

    const newSortedStatus = sortedProductionStatus.map((ps, i) => ({
      ...ps,
      priority: i + 1,
    }));

    setSortedProductionStatus(newSortedStatus);

    for (const ps of newSortedStatus) {
      if (ps.new) {
        await insert_production_status({
          variables: { ...ps, productID: product?.id, id: undefined },
          onError(error) {
            console.log(error);
            addAlert({ message: "could not add new status", type: "failure" });
          },
        });
        refetch();
        continue;
      }
      await sort_production_status({
        variables: {
          id: ps.id,
          priority: ps.priority,
        },
      });
    }

    setUpdating(false);
    setTouched(false);
  };

  const addStatus = () => {
    const newPS: ProductionStatusType = {
      color: "#000000",
      id: Math.random(),
      priority: sortedProductionStatus.length + 1,
      name: "new productionStatus",
      description: "please give precise description",
      productID: product?.id,
      new: true,
    };

    setSortedProductionStatus(prev => prev.concat(newPS));
    setTouched(true);
  };

  // auths
  const viewAuth = checkAuth("setting_production_access");
  const editAuth = checkAuth("setting_production_edit");

  return (
    <div className="mb-1 flex-grow  mx-auto">
      <div className="flex flex-row justify-between items-center">
        <h1>{product ? product.name : "General"} Statuses</h1>
        {editAuth && (
          <PlusCircleIcon
            className="text-grass hover:animate-pulse w-6 cursor-pointer"
            onClick={addStatus}
          />
        )}
      </div>
      {loading && (
        <div className="flex flex-row justify-center mt-4 mb-6">
          <Spinner color="purple" size="xl" />
        </div>
      )}

      {sortedProductionStatus && viewAuth && (
        <section className="my-4 space-y-2">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]}
          >
            <SortableContext
              disabled={!editAuth}
              items={sortedProductionStatus.map(ps => ps.priority)}
              strategy={verticalListSortingStrategy}
            >
              {sortedProductionStatus.map(ps => (
                <ProductionStatus
                  updating={updating}
                  setSortedProductionStatus={setSortedProductionStatus}
                  key={ps.id}
                  ps={ps}
                  product={product}
                />
              ))}
            </SortableContext>
            {activeItem && (
              <>
                {createPortal(
                  <DragOverlay>
                    <ProductionStatus ps={activeItem} />
                  </DragOverlay>,
                  document.body
                )}
              </>
            )}
          </DndContext>
        </section>
      )}

      {!viewAuth && "no access"}

      {touched && (
        <div className="flex space-x-2 justify-end">
          <Button gradientDuoTone="purpleToBlue" size="sm" onClick={reset}>
            Reset
          </Button>

          <Button
            onClick={save}
            outline
            gradientDuoTone="purpleToBlue"
            size="sm"
          >
            {updating && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
