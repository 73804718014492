import { Outlet, useRoutes } from "react-router-dom";
import SchedulesLoader from "./schedulesLoader";
const ScheduleWrapper = () => {
  return <Outlet />;
};

export default function Schedule() {
  const scheduleRoutes = useRoutes([
    {
      path: "/",
      element: <ScheduleWrapper />,
      children: [
        {
          path: "/",
          element: <SchedulesLoader />,
        },
        // {
        //   path: "/detail/:id",
        //   element: <ContactDetail />,
        // },
        // {
        //   path: "/add",
        //   element: <AddContact />,
        // },
      ],
    },
  ]);
  return scheduleRoutes;
}
