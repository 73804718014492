import { NewProductionType, ProductionType, productionSchema } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, gql } from "@apollo/client";
import { PRODUCTION_CORE_FIELDS } from "./fragments";
import { useAuthStore } from "../../../../store/authStore";
import { Button, Label, Select, Spinner, TextInput } from "flowbite-react";
import { GET_TEAMS } from "../../settings/teams/teamList";
import checkAuth from "../../../../utils/checkAuth";
import { useScheme } from "../../../../store/schemeStore";
import { GET_PRODUCTS } from "../../settings/products/products";
import { GET_PRODUCTION_STATUSES_NO_PID } from "../../settings/production/productionStatuses/productionStatuses";
import { Dispatch, SetStateAction, useState } from "react";
import { push } from "../../../../utils/pushAPI";
import { GET_USERS } from "../../settings/users/gql";
import { fullNumber } from "../../../../utils/fullNumber";
import { TrashIcon } from "@heroicons/react/24/solid";
import { exists, mkdir, upload } from "../../../../utils/fileAPI";
import getBase64 from "../../../../utils/getBase64";
import { ADD_PRODUCTION } from "../../production/gql";

interface props {
  processID?: number;
  setAdding: Dispatch<SetStateAction<boolean>>;
  jobPath: false | string[];
}

export default function AddProduction({
  processID,
  setAdding,
  jobPath,
}: props) {
  const { user } = useAuthStore();
  const { data: data_users } = useQuery(GET_USERS);
  const users = data_users?.users;
  const { scheme } = useScheme();

  const [addProduction, { loading: inserting }] = useMutation(ADD_PRODUCTION);

  const { data: data_teams } = useQuery(GET_TEAMS);
  const manufactureTeams = data_teams?.teams.filter(
    t =>
      t.type?.name == "manufacture" &&
      checkAuth([
        {
          permission: "production_add",
          checkGroup: "userTeam",
          conditionGroup: [t.id],
        },
      ])
  );

  const { data: data_products } = useQuery(GET_PRODUCTS);
  const { data: data_productionStatus } = useQuery(
    GET_PRODUCTION_STATUSES_NO_PID
  );

  const [uploaded, setUploaded] = useState<undefined | File>();
  const removeFile = () => {
    setUploaded(undefined);
  };

  const uploadOrder = async (id: number) => {
    if (!uploaded) {
      return setAdding(false);
    }
    const path = jobPath && jobPath.concat("production", id.toString());
    if (!path) {
      return setAdding(false);
    }
    if (path) {
      const checkPath = await exists(path);
      if (!checkPath) {
        await mkdir(path);
        const res = (await getBase64(uploaded)) as string;
        const finalPath = path.concat(uploaded.name);
        await upload(res, finalPath);
        setAdding(false);
      } else {
        const res = (await getBase64(uploaded)) as string;
        const finalPath = path.concat(uploaded.name);
        await upload(res, finalPath);
        setAdding(false);
      }
    }
  };

  const defaultValues = {
    processID,
    badge: "",
    teamID: 0,
    productID: 0,
    statusID: 0,
    qty: 0,
    due: "",
    description: "",
    createdBy: user?.id || 0,
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<NewProductionType>({
    defaultValues,
    resolver: zodResolver(productionSchema),
  });

  const selectedTeam = manufactureTeams?.find(t => t.id == watch("teamID"));

  const availableProducts = selectedTeam
    ? data_products?.products.filter(p =>
        selectedTeam.products?.includes(p.id)
      ) || []
    : [];

  const onSubmit = handleSubmit(async data => {
    addProduction({
      variables: data,
      update(cache, { data: { insert_productions_one: newProduction } }) {
        cache.modify({
          fields: {
            productions(existingProductions = []) {
              const newProductionRef = cache.writeFragment({
                data: newProduction,
                fragment: PRODUCTION_CORE_FIELDS,
                fragmentName: "ProductionCoreFields",
              });
              return [...existingProductions, newProductionRef];
            },
          },
        });
      },
      onCompleted(res) {
        reset({
          ...defaultValues,
        });

        const production = res.insert_productions_one as ProductionType;
        if (!production) {
          return;
        }
        const process = production.process;
        if (!process) {
          return;
        }
        const ids = users
          ?.filter(
            u =>
              u.team?.id == production.team.id && u.auth.name.includes("leader")
          )
          .map(u => u.id);
        if (!ids) {
          return;
        }

        if (jobPath) {
          uploadOrder(production.id);
        } else {
          setAdding(false);
        }

        const title = `Order Released - ${
          process
            ? fullNumber(
                process.processType.prefix,
                process.year,
                process.number
              )
            : ""
        }`;
        const body = `${production.product.name} ${production.qty}`;

        push({
          title,
          body,
          ids,
        });
      },
    });
  });

  return (
    <form
      className="w-full gap-2 flex flex-col items-start flex-wrap shadow-md border-[1px] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 p-2 rounded-md"
      onSubmit={onSubmit}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <Label value="Team" />
        <Select
          style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
          sizing="sm"
          className="w-[50%]"
          {...register("teamID")}
        >
          <option value={0}>Select team</option>
          {manufactureTeams?.map(team => (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <Label value="Product" />
        <Select
          style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
          sizing="sm"
          className="w-[50%]"
          {...register("productID")}
        >
          <option value={0}>Select product</option>
          {availableProducts.map(product => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <Label value="Qty" />
        <TextInput
          {...register("qty")}
          sizing="sm"
          style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
          type="number"
          className="w-[50%]"
        />
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <Label value="Status" />
        <Select
          style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
          sizing="sm"
          className="w-[50%]"
          {...register("statusID")}
        >
          <option value={0}>Select status</option>
          {data_productionStatus?.productionStatus.map(status => (
            <option
              style={{ color: status.color }}
              key={status.id}
              value={status.id}
            >
              {status.name}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <Label value="Due" />
        <input
          type="date"
          {...register("due")}
          className="dark:bg-gray-500 rounded-md outline-none p-[5px] dark:border-none font-normal text-sm w-[50%]"
        />
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <Label value="Badge" />
        <TextInput
          {...register("badge")}
          sizing="sm"
          style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
          placeholder="ex) S1"
          className="w-[50%]"
        />
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <Label value="Desc" />
        <TextInput
          {...register("description")}
          sizing="sm"
          style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
          className="w-[50%]"
        />
      </div>
      {jobPath && (
        <div className="flex flex-row justify-between items-center w-full mt-4 mb-2">
          <Label value="Order" />
          <div className="flex flex-row w-[70%] gap-2 items-center">
            <TextInput
              onChange={e => {
                const files = e.target.files;
                if (!files || !files[0]) {
                  return;
                }
                setUploaded(files[0]);
              }}
              type="file"
              sizing="xs"
              style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
              className="flex-1"
            />
            {uploaded && (
              <TrashIcon
                onClick={removeFile}
                className="w-5 text-red-500 cursor-pointer"
              />
            )}
          </div>
        </div>
      )}

      <Button
        type="submit"
        gradientDuoTone="purpleToBlue"
        size="xs"
        outline
        className="self-end"
      >
        Add
        {inserting && (
          <Spinner light size="xs" color="purple" className="ml-1" />
        )}
      </Button>
    </form>
  );
}
