import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Label, Spinner, TextInput } from "flowbite-react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { coerceNumber } from "../types";
import { processDetailSubProps } from "../types";
import { ProcessHistory } from "../types";
import { PencilIcon } from "@heroicons/react/24/solid";
import { addAlert } from "../../../../store/alertStore";
import { Autocomplete, AutocompleteProps } from "@react-google-maps/api";
import { default as configs } from "../../../../config";
import { MarkerProp } from "../types";

const companyLoc = configs.companyLoc;

const resolver = z.object({
  address: z.string().optional(),
  postCode: coerceNumber.optional(),
});

interface props extends processDetailSubProps {
  setMarkers: Dispatch<SetStateAction<MarkerProp[]>>;
}

// TODO: CheckAuth, can edit status
export default function ProcessAddress({
  process,
  baseHistory,
  setMarkers,
}: props) {
  const { address, postCode } = process;
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const defaultValues = useCallback(
    () => ({
      address: address ? address : "",
      postCode: postCode ? postCode.toString() : "",
    }),
    [process]
  );

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: defaultValues(),
  });

  useEffect(() => {
    reset(defaultValues());
  }, [process]);

  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);

  const onSubmit = handleSubmit(async data => {
    const { address, postCode } = data;

    if (!isDirty) {
      addAlert({ message: "nothing changed", type: "warning" });
      return;
    }

    const prevAddress = `${defaultValues().address} ${
      defaultValues().postCode
    }`;
    const newAddress = `${address} ${postCode}`;

    const history: ProcessHistory = {
      ...baseHistory,
      message: `address updated ${prevAddress} to ${newAddress}`,
      type: "detail",
    };

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(history)
      : [history];

    update_process({
      variables: {
        id: process.id,
        set: {
          address,
          postCode: Number(postCode),
          histories,
        },
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "cannot update address",
          type: "failure",
        });
      },
      onCompleted() {
        reset({ address, postCode });
        removeMarker();
        toggleEditing();
      },
    });
  });

  const removeMarker = () => {
    setMarkers(markers =>
      markers.filter(marker => marker.label !== "new address")
    );
  };

  const cancel = () => {
    reset();
    toggleEditing();
    removeMarker();
  };

  const autoCompleteLoaded = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const {
        formatted_address: address,
        address_components,
        geometry,
      } = place;

      const { lat, lng } = {
        lat: geometry?.location?.lat(),
        lng: geometry?.location?.lng(),
      };
      if (lat && lng) {
        const marker: MarkerProp = {
          position: { lat, lng },
          label: "new address",
        };

        setMarkers(markers => {
          return [...markers, marker];
        });
      }

      const postCode = address_components
        ? address_components[address_components.length - 1].long_name
        : null;

      if (postCode) {
        setValue("postCode", postCode);
      }

      if (address) {
        setValue(
          "address",
          address
            .replace(", Australia", "")
            .replace(postCode || "", "")
            .trim()
        );
      }
    }
  };

  const autocompleteOptions: AutocompleteProps["options"] = {
    componentRestrictions: { country: "au" },
    bounds: {
      north: companyLoc.lat + 3,
      south: companyLoc.lat - 3,
      east: companyLoc.lng + 3,
      west: companyLoc.lng - 3,
    },
  };

  return (
    <form
      onSubmit={onSubmit}
      className="col-span-1 
      @lg:col-span-2 supports-[not(container-type:inline-size)]:lg:col-span-2
      space-y-2 flex flex-col"
    >
      <div className="flex flex-row items-center gap-2 justify-between @md:justify-start">
        <h2>Address</h2>
        {!editing && (
          <PencilIcon
            onClick={toggleEditing}
            className="w-4 cursor-pointer hover:text-grass"
          />
        )}
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col justify-evenly gap-2 flex-1">
        <div className="flex flex-row items-center justify-between gap-2">
          <Label className="flex-1" value="Address" />

          {editing ? (
            <Autocomplete
              onLoad={autoCompleteLoaded}
              onPlaceChanged={onPlaceChanged}
              options={autocompleteOptions}
              fields={[
                "formatted_address",
                "geometry.location",
                "address_component",
              ]}
              className="flex-[2]"
            >
              <TextInput
                sizing="sm"
                {...register("address")}
                disabled={loading}
                color={errors.address?.message ? "failure" : undefined}
              />
            </Autocomplete>
          ) : (
            <div className="flex-[2] text-right flex-wrap select-text">
              {address}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-between gap-2">
          <Label className="flex-1" value="Post Code" />
          {editing ? (
            <TextInput
              type="number"
              sizing="sm"
              className="flex-[2]"
              {...register("postCode")}
              disabled={loading}
              color={errors.postCode?.message ? "failure" : undefined}
            />
          ) : (
            <div className="select-text">{postCode}</div>
          )}
        </div>

        {editing && (
          <div className="flex justify-end flex-row items-center gap-2 mt-2">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              update
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
