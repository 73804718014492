import { sort } from "fast-sort";
import { Button } from "flowbite-react";
import { useEffect, useState } from "react";
import { useProcessStore } from "../../../../store/processStore";
import { ProcessFilter } from "./types";
import Filter from "./filter";
import Sorters from "./sorters";

interface props {
  filtering: boolean;
}

export default function Filters({ filtering }: props) {
  const { filters, setFilters } = useProcessStore();

  const [newFilters, setNewFilters] = useState<ProcessFilter[]>([]);
  const [touched, setTouched] = useState(false);

  if (!filters) {
    return null;
  }

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  // console.log(filters);

  const apply = () => {
    setFilters(newFilters);
    setTouched(false);
  };

  const cancel = () => {
    setNewFilters(filters);
    setTouched(false);
  };

  const reset = () => {
    localStorage.removeItem("processFilters");
    location.reload();
  };

  const isDefault =
    JSON.stringify(
      filters.map(f => ({ values: f.values, on: f.on, sort: f.sort }))
    ) ==
    JSON.stringify(
      filters.map(f => ({
        values: f.defaultValues,
        on: f.defaultOn,
        sort: f.defaultSort,
      }))
    );

  const sorters = sort(newFilters.filter(f => f.sort)).asc(
    s => s.sort?.priority
  );

  return (
    <div
      className={`dark:bg-gray-800 bg-white flex flex-col flex-wrap transition-all duration-500 ease-in-out px-6 overflow-hidden ${
        !filtering
          ? "max-h-0 border-0"
          : "max-h-[300vh] h-auto border-y-[1px] border-gray-200 dark:border-gray-600 mb-2"
      }`}
    >
      {/* Filter and Sorters */}
      <div className="flex flex-row flex-wrap gap-4">
        {/* Filter */}
        <div className="flex-[3] flex-grow min-w-[70%] flex flex-col gap-4">
          <h3 className="mt-4">Filters</h3>
          {/* Selected Filters */}
          <div className="flex flex-row flex-wrap gap-4 ">
            {sort(newFilters)
              .by([
                { desc: f => f.required },
                { asc: f => f.sort?.priority },
                { asc: f => f.name },
              ])
              .filter(f => f.on)
              .map(filter => (
                <Filter
                  key={filter.where}
                  filter={filter}
                  newFilters={newFilters}
                  setNewFilters={setNewFilters}
                  setTouched={setTouched}
                />
              ))}
          </div>
          {/* Unselected Filtes */}
          <div className="flex flex-row flex-wrap gap-4 px-2 py-4 border-t-[1px] border-gray-500">
            {sort(newFilters)
              .by([{ asc: f => f.sort?.priority }, { asc: f => f.name }])
              .filter(f => !f.on)
              .map(filter => (
                <Filter
                  key={filter.where}
                  filter={filter}
                  newFilters={newFilters}
                  setNewFilters={setNewFilters}
                  setTouched={setTouched}
                />
              ))}
          </div>
        </div>
        {/* Sort */}
        <div className="flex-[2] flex-grow min-w-[200px] flex flex-col gap-4">
          <h3 className="mt-4">Sort</h3>
          {/* Sorters */}
          <Sorters
            sorters={sorters}
            newFilters={newFilters}
            setNewFilters={setNewFilters}
            setTouched={setTouched}
            touched={touched}
          />
        </div>
      </div>
      {/* Action Buttons */}
      <div className="flex flex-row justify-end gap-2 my-4">
        {!isDefault && (
          <Button onClick={reset} size="sm" color="purple">
            Reset
          </Button>
        )}

        {touched && (
          <>
            <Button onClick={cancel} size="sm" color="purple">
              Cancel
            </Button>
            <Button onClick={apply} size="sm" color="purple" outline>
              Apply
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
