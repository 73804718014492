import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";

export default function Loading() {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const loop = setTimeout(() => {
      if (dots === "...") {
        setDots("");
      } else {
        setDots(prev => prev + ".");
      }
    }, 1000);

    return () => {
      clearTimeout(loop);
    };
  }, []);

  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="w-20 text-center ">
        <Spinner size="xxl" className="mb-2" color="purple" />
        Loading{dots}
      </div>
    </div>
  );
}
