import { ADD_CONTACT } from "./addContact";
import { useMutation } from "@apollo/client";
import { useAuthStore } from "../../../../store/authStore";
import { NewContactType } from "../types";
import { CONTACT_CORE_FIELDS } from "../fragments";

const useAddContact = () => {
  const [insert_contact, { loading: adding_contact }] =
    useMutation(ADD_CONTACT);
  const { user } = useAuthStore();

  const addNewContact = async (newContact?: NewContactType) => {
    if (newContact) {
      try {
        const res = await insert_contact({
          variables: {
            ...newContact,
            organisation: undefined,
            createdBy: user?.id,
            isOrganisation: false,
          },
          update(cache, { data: { insert_contacts_one: newContact } }) {
            cache.modify({
              fields: {
                contacts(exContacts = []) {
                  const newContactRef = cache.writeFragment({
                    data: newContact,
                    fragment: CONTACT_CORE_FIELDS,
                    fragmentName: "ContactCoreFields",
                  });
                  return [...exContacts, newContactRef];
                },
              },
            });
          },
        });

        return res?.data?.insert_contacts_one;
      } catch (error) {
        console.log(error);
        return false;
      }
    } else {
      return false;
    }
  };

  return { addNewContact, adding_contact };
};

export default useAddContact;
