import { Badge } from "flowbite-react";
import { SourceType } from "./types";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

export default function Source({
  source,
  deleting,
}: {
  source: SourceType;
  deleting: number;
}) {
  const { attributes, listeners, setNodeRef, transform, isDragging, over } =
    useDraggable({
      id: source.id,
      data: {
        source,
      },
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging && !over ? 0.5 : 1,
    display: deleting == source.id ? "none" : "unset",
  };

  return (
    <div
      style={style}
      className="flex flex-row items-center"
      {...attributes}
      {...listeners}
      ref={setNodeRef}
    >
      <Badge
        className="cursor-pointer"
        color={`${over && isDragging ? "failure" : "purple"}`}
      >
        {source.name}
      </Badge>
    </div>
  );
}
