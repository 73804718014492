import React from "react";
import { gql, TypedDocumentNode } from "@apollo/client";
import { PaymentMethodArrayType } from "./types";

export const GET_PAYMENT_METHODS: TypedDocumentNode<PaymentMethodArrayType> = gql`
  query GET_PAYMENT_METHODS {
    paymentMethods {
      id
      name
      iconName
    }
  }
`;

export default function PaymentMethods() {
  return <div></div>;
}
