import { z } from "zod";

type HEX = `#${string}`;

export const taskStatusSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  description: z.string().trim(),
  color: z.string(),
  // priority: z.number(),
});

export interface NewTaskStatusType {
  name: string;
  description?: string;
  color: HEX;
  priority: number;
  new?: boolean;
}

export interface TaskStatusType extends NewTaskStatusType {
  id: number;
}

export interface TaskStatusArrayType {
  taskStatus: TaskStatusType[];
}
