import { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { OrderType } from "../../../types";
import { additionalMat, inventory, location } from "../../types";
import { column, filteredLocation, materialWithInventory } from "./types";
import { PrinterIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { Card, Checkbox } from "flowbite-react";
import { sort } from "fast-sort";
import { useOrderStore } from "../../../../../../store/orderStore";
import { useFormContext } from "react-hook-form";

interface props {
  fullNum: string;
  customer?: string;
  pageMarginStyle: string;
  dbOrder?: OrderType;
  columns: column[];
  filteredLocations?: filteredLocation[];
  locations?: location[];
  materialsWithInventory: materialWithInventory[];
  productId?: number;
}

export default function ComponentsOrder({
  customer,
  fullNum,
  pageMarginStyle,
  dbOrder,
  materialsWithInventory,
  filteredLocations,
  columns,
  locations,
  productId,
}: props) {
  const compOrderRef = useRef(null);
  const printCompOrder = useReactToPrint({
    content: () => compOrderRef.current,
    documentTitle: customer || fullNum || "no-name",
  });

  const { units, materials: cachedMats } = useOrderStore();

  const { getValues } = useFormContext();

  const additionalMats: inventory[] =
    (getValues("additionalMats") as additionalMat[])?.filter(
      mat => cachedMats.find(m => m.id == mat.id)?.product.id == productId
    ) || [];

  return (
    <div className="w-[277mm]">
      <div className=" flex flex-row justify-end items-center">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-plum"
          onClick={printCompOrder}
        >
          Print
          <PrinterIcon className="w-4" />
        </div>
      </div>
      <div ref={compOrderRef} className="flex flex-col gap-2 print:px-6">
        <style>{pageMarginStyle}</style>
        <h1 className="mb-4 text-center dark:text-black print:pt-4">
          Components Order
        </h1>
        <div className="flex flex-row justify-between">
          <h3 className="dark:text-black">
            Due: {dbOrder?.due ? dayjs(dbOrder.due).format("DD/MM/YY") : "None"}
          </h3>
          <table className="border-[1px] border-gray-400">
            <tbody>
              <tr>
                <th className="border-[1px] border-gray-400 px-1">Job ID</th>
                <th className="border-[1px] border-gray-400 px-1">{fullNum}</th>
                <th className="border-[1px] border-gray-400 px-1">
                  Customer Ref
                </th>
                <th className="border-[1px] border-gray-400 px-1">
                  {dbOrder?.orderRef}
                </th>
                <th className="border-[1px] border-gray-400 px-1">Dealer ID</th>
                <th className="border-[1px] border-gray-400 px-1">
                  {dbOrder?.createdBy}
                </th>
                <th className="border-[1px] border-gray-400 px-1">
                  Dealer Name
                </th>
                <th className="border-[1px] border-gray-400 px-1">
                  {dbOrder?.user.firstName} {dbOrder?.user.sirName}
                </th>
              </tr>
              <tr>
                <th className="border-[1px] border-gray-400 px-1">
                  Client Name
                </th>
                <th colSpan={3} className="border-[1px] border-gray-400 px-1">
                  {dbOrder?.process?.name}
                </th>
                <th className="border-[1px] border-gray-400 px-1">Address</th>
                <th colSpan={3} className="border-[1px] border-gray-400 px-1">
                  {dbOrder?.process?.address}
                </th>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="dark:text-black">Components CheckList</h3>
        <Card className="w-full dark:bg-white dark:text-black">
          <div className="flex flex-col gap-1">
            {materialsWithInventory &&
              sort(materialsWithInventory)
                .by([
                  { asc: mat => mat.inventory?.brand },
                  { asc: mat => mat.inventory?.name },
                  { asc: mat => mat.inventory?.color },
                  { asc: mat => mat.inventory?.size },
                ])
                ?.map(mat => {
                  return (
                    <Fragment key={mat.id}>
                      <div className="flex flex-row justify-between py-[2px]">
                        <h4>
                          {mat.inventory?.brand} {mat.inventory?.name}{" "}
                          {mat.inventory?.color}
                          {mat.inventory.size ? ` ${mat.inventory.size}` : ""}
                        </h4>

                        <div className="flex flex-row gap-2 items-center">
                          <h4>
                            {mat.qty.toFixed(2).replace(".00", "")}{" "}
                            {units.find(u => u.id == mat.unit)?.name ||
                              mat.unit}
                          </h4>
                          <Checkbox checked={false} disabled />
                        </div>
                      </div>
                      <hr />
                    </Fragment>
                  );
                })}
          </div>
        </Card>

        <h3 className="mt-2 dark:text-black">Comps per item</h3>
        <table className="bg-white border-y-[1px] border-l-[1px] border-black">
          <thead className="select-text border-b-[1px] border-black">
            <tr>
              <th className="px-3 border-r-[1px] border-black">#</th>
              {materialsWithInventory?.map(mat => {
                return (
                  <th className="px-3 border-r-[1px] border-black" key={mat.id}>
                    {mat.inventory.name} {mat.inventory.color}
                    {mat.inventory.size ? ` ${mat.inventory.size}` : ""}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className="divide-y">
            {filteredLocations?.map((location, i) => {
              const locationName =
                location.name ||
                `# ${location.index !== undefined && location.index}`;
              return (
                <Fragment key={i}>
                  {filteredLocations.length > 1 && (
                    <tr className="text-plum font-semibold border-b-[1px] border-black">
                      <td
                        className="py-[4px] px-1 border-r-[1px] border-black"
                        colSpan={columns.length}
                      >
                        {locationName}
                      </td>
                    </tr>
                  )}
                  {location.openings.map(opening => {
                    const openingName =
                      opening.name ||
                      (opening.index !== undefined && opening.index);
                    return opening.items.map(item => {
                      return (
                        <tr
                          key={item.id}
                          className="text-black border-b-[1px] border-black"
                        >
                          <td className="py-[4px] px-1 border-r-[1px] border-black">{`${
                            locations && locations.length > 1
                              ? locationName + "."
                              : ""
                          }${openingName}.${
                            item.index !== undefined && item.index
                          }`}</td>
                          {materialsWithInventory?.map(mat => {
                            const itemMat = item.materials?.find(
                              m => m.id == mat.id
                            );
                            return (
                              <td
                                key={mat.id}
                                className="py-[4px] px-1 border-r-[1px] border-black"
                              >
                                {itemMat?.qty
                                  ? Math.round(Number(itemMat?.qty) * 2) / 2
                                  : 0}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    });
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>

        {additionalMats.length > 0 && (
          <div className="flex flex-col gap-2">
            <h3 className="mt-2 dark:text-black">Additional Materials</h3>
            <table className="border-t-[1px] border-l-[1px] border-black">
              <thead className="border-b-[1px] border-black">
                <tr>
                  <th className="border-r-[1px] border-black">#</th>
                  <th colSpan={2} className="border-r-[1px] border-black">
                    Material
                  </th>
                  <th className="border-r-[1px] border-black">Qty</th>
                  <th className="border-r-[1px] border-black">Unit</th>
                </tr>
              </thead>
              <tbody>
                {additionalMats.map((mat, i) => {
                  const material = cachedMats.find(m => m.id == mat.id);
                  if (!material) return null;
                  const unit = units.find(u => u.id == mat.unit);

                  return (
                    <tr key={i} className="border-b-[1px] border-black">
                      <td className="border-r-[1px] border-black px-1 text-center">
                        {i + 1}
                      </td>
                      <td
                        colSpan={2}
                        className="border-r-[1px] border-black px-2"
                      >
                        {[
                          material?.brand,
                          material?.name,
                          material?.color,
                          material?.size,
                        ]
                          .filter(m => m !== null)
                          .join(" ")}
                      </td>
                      <td className="border-r-[1px] border-black px-2">
                        {mat.qty}
                      </td>
                      <td className="border-r-[1px] border-black px-2">
                        {unit?.name}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
