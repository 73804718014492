import { z } from "zod";

import {
  ProcessProductType,
  ProcessType,
  coerceNumber,
  processProductSchema,
} from "../process/types";

export interface AssignmentStatType {
  id: number;
  name: string;
  priority: number;
  color: string;
}

export const assignmentSchema = z.object({
  processId: coerceNumber,
  userId: coerceNumber,
  statusId: coerceNumber,
  typeId: coerceNumber,
  value: coerceNumber.optional(),
  desc: z.string().nullable().optional(),
  due: z
    .string()
    .transform(arg => (arg ? new Date(arg).toISOString() : undefined))
    .nullable()
    .optional(),
  orders: z.array(z.number()),
  products: z.array(processProductSchema),
});

export interface AssignmentMinType {
  id: number;
  processId: number;
  userId: number;
  createdById: number;
  statusId: number;
  typeId: number;
  created_at: string;
  updated_at: string;
  value?: number;
  desc?: string;
  due?: string;
  products: ProcessProductType[];
  orders: number[];
}

export interface NewAssignmentType {
  processId: number;
  userId: undefined | number;
  statusId: undefined | number;
  typeId: undefined | number;
  value?: number;
  desc?: string;
  due?: string | null;
  products: ProcessProductType[];
  orders: number[];
}

export interface AssignmentType extends AssignmentMinType {
  process: ProcessType;
  user: {
    id: number;
    firstName: string;
    sirName: string;
    email: string;
  };
  createdBy: {
    id: number;
    firstName: string;
    sirName: string;
    email: string;
  };
  status: AssignmentStatType;
  type: AssignmentStatType;
}

export interface AssignmentByPK {
  assignment_by_pk: AssignmentType;
}

export interface AssignmentArrayType {
  assignment: AssignmentType[];
}

export interface AssignmentStreamType {
  assignment_stream: AssignmentType[];
}

export interface AssignmentFilter {
  name: string;
  where: string;
  type: "in" | "range" | "contains";
  rangeType?: "date" | "number";
  defaultValues: any;
  values: any;
  options?: {
    name: string;
    value: any;
  }[];
}

export interface assignmentQueryParams {
  where: {
    _and: any[];
  };
  offset: number;
}
