import { Card } from "flowbite-react";
import { useFormContext } from "react-hook-form";
import { TbCurrencyDollar, TbMathFunction, TbPercentage } from "react-icons/tb";
import { useOrderStore } from "../../../../../../store/orderStore";

interface props {
  index: number;
}

export default function ProductQuoteParams({ index }: props) {
  const { register, getValues } = useFormContext();
  const myCoord = `salesParams.productParams.${index}`;
  const productSetId = getValues(`${myCoord}.productSetId`);
  const { productSets } = useOrderStore();
  const productSet = productSets.find(ps => ps.id == productSetId);

  return (
    <Card className="col-span-1">
      <h3>{productSet?.name}</h3>
      <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
        <h3>Base Install Cost</h3>
        <div className="flex flex-1 flex-row items-center gap-1">
          <input
            placeholder="Enter number or function"
            className="p-1 text-plum text-lg font-semibold text-right bg-transparent border-none flex-1 w-0 outline-none"
            {...register(`${myCoord}.baseInstall`)}
          />
          <TbMathFunction className="w-5 h-5 ml-2" />
        </div>
      </div>
      <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
        <h3>Margin</h3>
        <div className="flex flex-1 flex-row items-center gap-1">
          <input
            placeholder="Enter number"
            className="p-1 text-plum text-lg font-semibold text-right bg-transparent border-none flex-1 w-0 outline-none"
            {...register(`${myCoord}.margin`)}
          />
          <TbPercentage className="w-5 h-5 ml-2" />
        </div>
      </div>
      <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
        <h3>Sqm</h3>
        <div className="flex flex-1 flex-row items-center gap-1">
          <input
            placeholder="Enter number"
            className="p-1 text-plum text-lg font-semibold text-right bg-transparent border-none flex-1 w-0 outline-none"
            {...register(`${myCoord}.sqm`)}
          />
          <TbCurrencyDollar className="w-5 h-5 ml-2" />
        </div>
      </div>
    </Card>
  );
}
