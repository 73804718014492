import { useNavigate } from "react-router-dom";
import { fullNumber } from "../../../utils/fullNumber";
import dayjs from "dayjs";
import { Badge, ListGroup } from "flowbite-react";
import getContrast from "../../../utils/getContrast";
import {
  CakeIcon,
  ChatBubbleLeftEllipsisIcon,
  CurrencyDollarIcon,
  MapIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { useMutation, useQuery } from "@apollo/client";
import { useOnClickOutside } from "usehooks-ts";
import { useRef, useState } from "react";
import { AssignmentType } from "./types";
import { useAuthStore } from "../../../store/authStore";
import { GET_ASSIGNMENT_STATUS, UPDATE_ASSIGNMENT_BY_PK } from "./gqls";
import { GET_PRODUCTS } from "../settings/products/products";
import checkAuth from "../../../utils/checkAuth";
import isNullish from "../../../utils/isNullish";
import { currencyFormat } from "../../../utils/numberMethods";

interface props {
  assignment: AssignmentType;
}

export default function ListItem({ assignment }: props) {
  const { user } = useAuthStore();

  const {
    id,
    created_at,
    process,
    status,
    type,
    user: installer,
    desc,
    due,
    value,
  } = assignment;

  const { data: data_statuses } = useQuery(GET_ASSIGNMENT_STATUS);
  const statusList = data_statuses?.assignmentStatus;

  const [update] = useMutation(UPDATE_ASSIGNMENT_BY_PK);

  const ref = useRef<HTMLDivElement>(null);

  const [editing, setEditing] = useState(false);

  const toggleEditing = () => {
    setEditing(!editing);
  };

  useOnClickOutside(ref, () => {
    setEditing(false);
  });

  const changeStatus = (statusId: number) => {
    update({
      variables: { id, set: { statusId } },
      optimisticResponse: {
        update_assignment_by_pk: {
          __typename: "assignment",
          ...assignment,
          statusId,
          status: statusList?.find(sl => sl.id == statusId) || status,
        },
      },
    });
  };

  const navigate = useNavigate();
  const goToJob = () => {
    if (checkAuth("process_access")) {
      navigate("/process/detail/" + process.id + "/prog");
    }
  };
  const goToDetail = () => {
    navigate("/assignment/detail/" + id);
  };
  const fullNum =
    process &&
    fullNumber(
      process.processType.prefix,
      process.year,
      process.number,
      process.salesRepUser
    );

  const isPastDue = due && dayjs(due).isBefore(new Date());

  const { data } = useQuery(GET_PRODUCTS);
  const productList = data?.products;
  const products =
    assignment.products.length > 0 ? assignment.products : process.products;

  const team = process.createdUser.team;

  return (
    <div
      ref={ref}
      className="border-[1px] shadow-sm bg-white dark:bg-gray-800 dark:border-gray-700 rounded-md flex flex-col gap-1 p-3"
    >
      <div className="flex flex-row gap-2 items-center">
        <div className="flex-1 w-0 flex flex-col gap-1">
          <div className="cursor-pointer hover:text-grass" onClick={goToJob}>
            {fullNum}
          </div>
          <h3
            className="flex-1 truncate cursor-pointer hover:text-grass"
            onClick={goToDetail}
          >
            {process?.name || "No Customer"}
          </h3>
        </div>
        <div className="flex flex-row items-center gap-2">
          {/* Status */}
          <div className="relative">
            <div onClick={toggleEditing} className="cursor-pointer">
              <Badge
                style={{
                  backgroundColor: status.color,
                  color: getContrast(status.color),
                }}
                size="md"
                className="pointer-events-none"
              >
                {status?.name || "Status Unknown"}
              </Badge>
            </div>
            {editing && (
              <ListGroup className="absolute top-[100%] mt-2 z-10 right-0">
                {statusList?.map(stat => (
                  <ListGroup.Item
                    key={stat.id}
                    onClick={() => {
                      if (stat.id == status.id) {
                        return;
                      }
                      changeStatus(stat.id);
                    }}
                  >
                    <Badge
                      size="sm"
                      className="w-full pointer-events-none"
                      style={{
                        backgroundColor: stat.color,
                        color: getContrast(stat.color),
                        opacity: stat.id == status.id ? 0.5 : 1,
                      }}
                    >
                      {stat?.name || "Status Unknown"}
                    </Badge>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </div>
          {/* Due */}
          <div
            className={`flex flex-col items-center justify-center p-2 ${
              isPastDue && "text-red-500"
            }`}
          >
            <div>{due ? dayjs(due).format("DD") : "No"}</div>
            <div>{due ? dayjs(due).format("MMM") : "Due"}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center">
        {/* type */}
        <Badge
          style={{
            backgroundColor: type.color,
            color: getContrast(type.color),
          }}
          size="md"
          className="pointer-events-none"
        >
          {type?.name || "Type Unknown"}
        </Badge>
        {/* user */}
        {user?.id !== installer.id && (
          <div className="flex flex-row items-center gap-1 leading-[0]">
            <UserCircleIcon className="w-5" />
            {installer.firstName} {installer.sirName}
          </div>
        )}
        {/* team */}
        <div className="hidden @sm:flex supports-[not(container-type:inline-size)]:sm:flex flex-row items-center gap-1 leading-[0]">
          <UserGroupIcon className="w-5" />
          {team?.name || "No Team"}
        </div>
        {/* products */}
        {products && (
          <div className="hidden @sm:flex supports-[not(container-type:inline-size)]:sm:flex flex-row gap-2 items-center">
            {products.map((product, i) => {
              const Product = productList?.find(p => p.id == product.productId);

              if (!Product) {
                return null;
              }
              return (
                <div
                  key={product.productId + " " + i}
                  className="flex flex-row gap-2 items-center"
                >
                  {Product.image && (
                    <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                      <img src={Product.image} className="w-3" />
                    </div>
                  )}
                  <div className="hidden @sm:block supports-[not(container-type:inline-size)]:sm:block">
                    {Product?.name || "Deleted Product"}
                  </div>
                  <div>{product.qty}</div>
                </div>
              );
            })}
          </div>
        )}
        {!isNullish(value, true) && (
          <div className="hidden @sm:flex supports-[not(container-type:inline-size)]:sm:flex flex-row gap-1 items-center">
            <CurrencyDollarIcon className="w-4" />
            <div className="max-w-[400px] truncate">
              {currencyFormat(value as number)}
            </div>
          </div>
        )}
        {/* address */}
        {process?.address && (
          <div className="hidden @md:flex supports-[not(container-type:inline-size)]:md:flex flex-row gap-1 items-center">
            <MapIcon className="w-4" />
            <div className="max-w-[400px] truncate">{process.address}</div>
          </div>
        )}
        {/* Desc */}
        {desc && (
          <div className="hidden @lg:flex supports-[not(container-type:inline-size)]:lg:flex flex-row gap-1 items-center">
            <ChatBubbleLeftEllipsisIcon className="w-4" />
            <div className="max-w-[400px] truncate">{desc}</div>
          </div>
        )}
        {/* from */}
        <div className="hidden @sm:flex supports-[not(container-type:inline-size)]:sm:flex flex-row gap-1 items-center">
          <CakeIcon className="w-4" />
          <div className="leading-[0]">
            {dayjs(created_at).format("DD/MM/YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
}
