import { Dispatch, SetStateAction } from "react";

export default function NavButtons({
  page,
  setPage,
}: {
  page: string;
  setPage: Dispatch<SetStateAction<string>>;
}) {
  return (
    <div className="flex flex-1 justify-end gap-2">
      <span
        onClick={() => {
          setPage("info");
        }}
        className={`${
          page == "info" ? "text-plum border-plum" : "border-transparent"
        } font-semibold p-1 border-b-2 cursor-pointer hover:text-plum`}
      >
        INFO
      </span>
      <span
        onClick={() => {
          setPage("prog");
        }}
        className={`${
          page == "prog" ? "text-plum border-plum" : "border-transparent"
        } font-semibold p-1 border-b-2 cursor-pointer hover:text-plum`}
      >
        PROG
      </span>
      <span
        onClick={() => {
          setPage("file");
        }}
        className={`${
          page == "file" ? "text-plum border-plum" : "border-transparent"
        } font-semibold p-1 border-b-2 cursor-pointer hover:text-plum`}
      >
        FILE
      </span>
    </div>
  );
}
