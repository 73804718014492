import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TbLocationFilled, TbLocationOff } from "react-icons/tb";
import getLocation from "../../../../utils/getLocation";
import { Coord } from "../../../../utils/geocode";
import {
  CursorArrowRippleIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";
import { Button, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useScheme } from "../../../../store/schemeStore";
import RecordMore from "./recordMore";
import { GET_RECORD, INSERT_RECORD, UPDATE_RECORD } from "./gqls";
import { useMutation, useQuery } from "@apollo/client";
import { useAuthStore } from "../../../../store/authStore";
import RecordClock from "./recordClock";
import { weekdays } from "../../../../types/calendarTypes";
import { addAlert } from "../../../../store/alertStore";
import { RECORD_CORE_FIELDS } from "./fragments";
import TotalH from "./totalH";
import { useNavigate } from "react-router-dom";
import { useCheckHoliday } from "../../../../utils/calendarMethods";

export default function Record() {
  const [location, setLocation] = useState<Coord | undefined>(undefined);
  const { user } = useAuthStore();

  const noCheckin = user?.team?.type?.name == "dealer";

  const { data, refetch } = useQuery(GET_RECORD, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        _and: [
          {
            who: {
              _eq: user?.id,
            },
          },
          {
            start: {
              _gte: dayjs(new Date().setHours(0, 0, 0, 0))
                .subtract(1, "day")
                .toISOString(),
              _lte: dayjs(new Date().setHours(23, 59, 59, 59)).toISOString(),
            },
          },
        ],
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const today = dayjs(new Date()).format("ddd").toLowerCase();
  const todayTerm = user?.hours && user?.hours[today as weekdays];

  const yesterday = dayjs().subtract(1, "day").toDate();

  const todayRecord = data?.records.find(record =>
    dayjs(record.start).isSame(new Date(), "date")
  );
  const yesterdayRecord = data?.records.find(record =>
    dayjs(record.start).isSame(yesterday, "date")
  );

  const checkHoliday = useCheckHoliday();

  const weekends = [0, 6];
  const canCheckIn = () => {
    if (dayjs(yesterday).isBefore(dayjs(user?.created_at))) {
      return true;
    }
    if (
      !yesterdayRecord &&
      !checkHoliday(yesterday) &&
      !weekends.includes(yesterday.getDay())
    ) {
      return false;
    }
    return true;
  };

  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  const [time, setTime] = useState(new Date());
  const [brk, setBrk] = useState(todayTerm?.break || 0);

  const [ready, setReady] = useState(false);
  const toggleReady = () => {
    if (noCheckin) {
      return;
    }
    setReady(!ready);
    setTime(new Date());
  };

  const [timer, setTimer] = useState(5);

  useEffect(() => {
    if (ready && !todayRecord) {
      const countDown = setInterval(() => {
        setTimer(state => state - 1);
      }, 1000);

      setTimeout(() => {
        setReady(false);
        setTimer(5);
        clearInterval(countDown);
      }, 5000);
    }
  }, [ready]);

  const { scheme } = useScheme();

  const loadLocation = async () => {
    const loc = await getLocation();
    setLocation(loc);
  };

  useEffect(() => {
    loadLocation();
  }, []);

  const isInRange = () => {
    if (!todayTerm) {
      return true;
    }
    if (todayTerm.off) {
      return false;
    }

    if (!todayRecord && todayTerm.start) {
      const startTime = new Date(
        new Date().setHours(todayTerm.start - 1, 0, 0, 0)
      ).getTime();
      return new Date().getTime() > startTime;
    }

    if (todayRecord && todayTerm.end) {
      const endTime = new Date(
        new Date().setHours(todayTerm.end, 0, 0, 0)
      ).getTime();
      return new Date().getTime() > endTime;
    }

    return false;
  };

  const [addRecord, { loading: inserting, error }] = useMutation(INSERT_RECORD);
  if (error) {
    console.log(error);
  }

  const [updateRecord, { loading: updating, error: error_updating }] =
    useMutation(UPDATE_RECORD);
  if (error_updating) {
    console.log(error_updating);
  }

  const clockIn = () => {
    toggleReady();
    if (!todayRecord) {
      addRecord({
        variables: {
          who: user?.id,
          typeID: 1,
          start: time.toISOString(),
          statusID: 1,
          startLoc: location,
          break: todayTerm?.break || 0,
        },
        update(cache, { data: { insert_records_one: newRecord } }) {
          cache.modify({
            fields: {
              records(existing = []) {
                const newRecordRef = cache.writeFragment({
                  data: newRecord,
                  fragment: RECORD_CORE_FIELDS,
                  fragmentName: "RecordCoreFields",
                });
                return [...existing, newRecordRef];
              },
            },
          });
        },
        onCompleted: () => {
          addAlert({
            message: "Checked In successfully",
            type: "success",
          });
        },
      });
    }
    if (todayRecord) {
      updateRecord({
        variables: {
          id: todayRecord.id,
          set: {
            end: time.toISOString(),
            statusID: 2,
            endLoc: location,
            break: brk || 0,
          },
        },
        onCompleted: () => {
          addAlert({
            message: "Checked Out successfully",
            type: "success",
          });
        },
      });
    }
  };

  const navigate = useNavigate();

  const goToDetail = () => {
    navigate("/records/detail/" + todayRecord?.id);
  };

  const recordMoreCb = () => {
    toggleShow();
    refetch();
  };

  return (
    <>
      <div className="flex flex-col gap-2 ">
        <section className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2 items-center">
            {location ? (
              <TbLocationFilled className="w-5 h-5" />
            ) : (
              <TbLocationOff className="w-5 h-5" />
            )}
            <h2>{dayjs(new Date()).format("ddd DD MMM")}</h2>
          </div>

          {!noCheckin && (
            <Button
              onClick={toggleShow}
              size="xs"
              outline
              gradientDuoTone="purpleToBlue"
            >
              More
            </Button>
          )}
        </section>
        {/* Clock and Trigger */}
        <section className="shadow-md border-[1px] bg-gray-100 dark:bg-gray-700 dark:border-gray-600 rounded-md inline-flex overflow-hidden">
          <div
            className={`min-w-[200%] flex flex-row transition-all ${
              ready && "-translate-x-1/2"
            }`}
          >
            {updating || inserting ? (
              <div className="p-4 flex-1 text-center flex flex-col justify-around">
                <Spinner color="purple" size="xl" />
              </div>
            ) : (
              <>
                {canCheckIn() || todayRecord ? (
                  <>
                    {/* Check In/Out */}
                    {!todayRecord?.end && (
                      <div
                        onClick={toggleReady}
                        className="p-4 flex-1 text-center flex flex-col gap-2 cursor-pointer group justify-around"
                      >
                        <div className="font-semibold text-4xl">
                          <RecordClock />
                        </div>
                        {!noCheckin && (
                          <div className="flex flex-row items-center gap-2 justify-center">
                            <CursorArrowRippleIcon
                              className={`w-5 group-hover:text-plum ${
                                !isInRange() && "opacity-40"
                              }`}
                            />
                            <h3
                              className={`group-hover:text-plum ${
                                !isInRange() && "opacity-40"
                              }`}
                            >
                              Check {todayRecord ? "Out" : "In"}
                            </h3>
                          </div>
                        )}
                      </div>
                    )}
                    {/* Shift recorded */}
                    {todayRecord?.end && (
                      <div className="p-4 flex-1 text-center flex flex-col justify-around">
                        <div className="font-semibold text-3xl">
                          Shift Recorded
                        </div>
                        <div className="flex flex-row items-center gap-2 justify-center group cursor-pointer">
                          <WrenchScrewdriverIcon className="w-5 group-hover:text-plum" />
                          <h3
                            onClick={goToDetail}
                            className="group-hover:text-plum"
                          >
                            Adjust Record
                          </h3>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="p-4 flex-1 flex flex-col items-center justify-center gap-1">
                      <h3>Record Missing</h3>
                      <div>Please add missing record for yesterday</div>
                      <Button
                        size="xs"
                        outline
                        color="purple"
                        className="mt-2"
                        onClick={toggleShow}
                      >
                        Rectify
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}

            {/* Confirm */}
            <div className="p-4 flex-1 text-center flex flex-col gap-2 items-center justify-around">
              {!todayRecord && (
                <div className="font-semibold text-4xl">
                  {dayjs(time).format("HH:mm:ss")}
                </div>
              )}
              {todayRecord && (
                <div className="flex flex-row items-center gap-2">
                  <h3>Break</h3>
                  <TextInput
                    type="number"
                    value={brk}
                    onChange={e => {
                      setBrk(Number(e.target.value));
                    }}
                    sizing="sm"
                    className="w-12"
                    style={{ fontSize: "1.5em" }}
                  />
                  min
                </div>
              )}
              <div className="flex flex-row items-center gap-2 justify-center">
                <Button
                  size="sm"
                  outline
                  gradientDuoTone="purpleToBlue"
                  onClick={clockIn}
                >
                  {!todayRecord && <>Confirm in {timer}</>}
                  {todayRecord && "Check Out"}
                </Button>
                {todayRecord && (
                  <Button
                    size="sm"
                    gradientDuoTone="purpleToBlue"
                    onClick={toggleReady}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          </div>
        </section>
        {!noCheckin && (
          <section className="flex flex-row justify-around shadow-md bg-white dark:bg-gray-800 rounded-md p-4">
            <div className="flex flex-col items-center">
              <Label value="Check In" />
              <h3>
                {todayRecord
                  ? dayjs(todayRecord.start).format("HH:mm")
                  : "pending"}
              </h3>
            </div>
            <div className="flex flex-col items-center">
              <Label value="Check Out" />
              <h3>
                {todayRecord?.end
                  ? dayjs(todayRecord.end).format("HH:mm")
                  : "pending"}
              </h3>
            </div>
            {todayRecord?.start && (
              <div className="flex flex-col items-center">
                <Label value="Total H" />
                <div className="flex flex-row items-center gap-1">
                  <h3>
                    <TotalH
                      startTime={todayRecord.start}
                      endTime={todayRecord.end}
                      brk={todayRecord.break}
                    />
                  </h3>
                  <span className="text-xs">
                    {todayRecord.end &&
                      `(${
                        Math.round(((todayRecord.break || 0) / 60) * 10) / 10
                      })`}
                  </span>
                </div>
              </div>
            )}
          </section>
        )}
      </div>
      <Modal
        show={show}
        onClose={toggleShow}
        className={`${scheme} !h-full !items-start`}
      >
        <Modal.Header className="dark:bg-gray-800">
          Attendance Action
        </Modal.Header>
        <RecordMore cb={recordMoreCb} />
      </Modal>
    </>
  );
}
