import { ProductionType } from "../../shared/productions/types";
import { useNavigate } from "react-router-dom";
import { fullNumber } from "../../../../utils/fullNumber";
import dayjs from "dayjs";
import { Active } from "@dnd-kit/core";

interface props {
  item: ProductionType;
  active?: Active;
  overlay: boolean;
  updating?: undefined | number;
}
export default function BoardItem({ item, active, overlay, updating }: props) {
  const navigate = useNavigate();

  const goToDetail = () => {
    navigate("/production/detail/" + item.id);
  };

  const { process } = item;

  const number =
    process &&
    `[${fullNumber(
      process.processType.prefix,
      process.year,
      process.number,
      process?.salesRepUser
    )}]`;

  const isActive = active && active.id == item.id;
  const isUpdating = updating && updating == item.id;

  const className = `flex flex-col gap-1 ring-1 ring-gray-200 dark:ring-gray-600 p-2 cursor-pointer bg-white dark:bg-gray-700 rounded-md dark:hover:text-grass hover:text-plum
    ${
      isActive
        ? overlay
          ? "dark:ring-grass ring-plum text-plum dark:text-grass"
          : "opacity-50"
        : ""
    }

    ${isUpdating ? "opacity-50" : ""}
    `;

  const isPastDue =
    item.productionStatus && item.due && dayjs(item.due).isBefore(new Date());

  const product = `${item.product.name} ${item.qty}`;

  return (
    <div onClick={goToDetail} className={className}>
      <div className="text-sm flex flex-row justify-between">
        <span>
          {number} {item.badge && <div> - {item.badge}</div>}
        </span>
        {item.due ? (
          <div className={`${isPastDue ? "text-red-500" : ""}`}>
            {dayjs(item.due).format("DD/MM")}{" "}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="truncate">{item.process?.name || item.description}</div>
      <div className="flex flex-row justify-between text-xs gap-2 flex-wrap items-center">
        <div className="flex-1 w-0 truncate">{product}</div>
        <div className="flex-1 w-0 truncate text-right"> {item.team.name}</div>
      </div>
      {/* TODO: Assigned task qty */}
    </div>
  );
}
