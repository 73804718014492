import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Spinner,
  TextInput,
  Textarea,
  Label,
  FileInput,
} from "flowbite-react";
import { NewProductType, productSchema } from "./types";
import { PRODUCT_CORE_FIELDS } from "./fragments";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../store/alertStore";
import getBase64 from "../../../../utils/getBase64";

export const INSERT_PRODUCT = gql`
  ${PRODUCT_CORE_FIELDS}
  mutation INSERT_PRODUCT(
    $name: String!
    $description: String!
    $image: String!
  ) {
    insert_products_one(
      object: { name: $name, description: $description, image: $image }
    ) {
      ...ProductCoreFields
    }
  }
`;

export default function AddProduct({
  toggleAdding,
}: {
  toggleAdding: () => void;
}) {
  const [insert_product, { loading }] = useMutation(INSERT_PRODUCT);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<NewProductType>({
    resolver: zodResolver(productSchema),
    defaultValues: {
      name: "",
      description: "",
      image: "",
    },
  });

  const onSubmit = handleSubmit(async data => {
    insert_product({
      variables: { ...data, id: undefined },
      update(cache, { data: { insert_products_one: newProduct } }) {
        cache.modify({
          fields: {
            products(existingProducts = []) {
              const newProductRef = cache.writeFragment({
                data: newProduct,
                fragment: PRODUCT_CORE_FIELDS,
              });
              return [...existingProducts, newProductRef];
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          message: "New Product successfully added",
          type: "success",
        });
        toggleAdding();
        reset();
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "something went wrong cannot add product",
          type: "failure",
        });
      },
    });
  });

  const cancel = () => {
    reset();
    toggleAdding();
  };

  const imageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors("image");

    const files = event.target.files;

    if (files) {
      const file = files[0];
      if (!file) {
        return;
      }
      const { size } = file;

      if (size / 1000 > 50) {
        setError("image", { type: "custom", message: "file size too big" });
        event.target.value = "";
        return;
      }

      const base64 = (await getBase64(file)) as string;

      setValue("image", base64);
    }
  };

  const image = watch("image");

  return (
    <form
      onSubmit={onSubmit}
      className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 mb-5 space-y-2"
    >
      <div>
        <Label htmlFor="name" value="Name" />
        <TextInput
          type="text"
          placeholder="Enter the name for new type no space allowed"
          {...register("name")}
          color={errors.name?.message ? "failure" : undefined}
          helperText={errors.name?.message || ""}
        />
      </div>
      <div>
        <Label htmlFor="description" value="Description" />

        <Textarea
          {...register("description")}
          placeholder="Describe the product"
          color={errors.description?.message ? "failure" : undefined}
          helperText={errors.description?.message || ""}
          className="mt-2 text-sm"
        />
      </div>

      <div>
        <Label htmlFor="image" value="Image" />
        {image && <img src={image} className="w-20 my-2" />}
        <FileInput
          id="image"
          accept="image/*"
          color={errors.image?.message ? "failure" : undefined}
          helperText={
            errors.image?.message || "product image less than 50kb size"
          }
          className="mt-2 text-sm"
          onChange={imageHandler}
        />
      </div>

      <div className="flex flex-row justify-end gap-2">
        <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
          Cancel
        </Button>
        <Button gradientDuoTone="purpleToBlue" outline size="sm" type="submit">
          {loading && (
            <div className="mr-3">
              <Spinner size="sm" light={true} />
            </div>
          )}
          Add
        </Button>
      </div>
    </form>
  );
}
