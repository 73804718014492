import { useFormContext, useWatch } from "react-hook-form";
import useGetPrice from "../../summary/getPrice";
import { TextInput } from "flowbite-react";
import { useIsMounted } from "usehooks-ts";
import { useEffect } from "react";
import { opening, option } from "../../../types";
import isNullish from "../../../../../../../utils/isNullish";

interface props {
  openingCoord: string;
  productSetId: number;
  showPrice: boolean;
}

export default function Price({
  openingCoord,
  productSetId,
  showPrice,
}: props) {
  const { control, getValues, setValue, register } = useFormContext();

  const orderType = getValues("type");
  const orderedUserId = getValues("user") as number;

  const isMounted = useIsMounted();

  const currentPrice = useWatch({
    name: `${openingCoord}.price`,
    control,
    defaultValue: 0,
  });

  const customInstall = useWatch({
    name: `${openingCoord}.customInstall`,
    control,
    defaultValue: undefined,
  });

  const allOptions: option[] = useWatch({
    name: `${openingCoord}.options`,
    control,
  });

  const widthOptionIndex = allOptions.findIndex(option => option.name == "W");
  const heightOptionIndex = allOptions.findIndex(option => option.name == "H");

  const width = useWatch({
    name: `${openingCoord}.options.${widthOptionIndex}.value`,
    control,
    defaultValue: 0,
  });

  const height = useWatch({
    name: `${openingCoord}.options.${heightOptionIndex}.value`,
    control,
    defaultValue: 0,
  });

  const getPrice = useGetPrice();

  let sqm = Number(((width * height) / 1000000).toFixed(2));
  if (sqm < 0.5) {
    sqm = 0.5;
  }

  const openingSalesParams = useWatch({
    name: `${openingCoord}.salesParams`,
    control,
  });

  const salesParams = useWatch({
    name: "salesParams",
    control,
  });

  const isRetail = orderType?.id !== 4;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const opening: opening = getValues(`${openingCoord}`);

    const sum = getPrice({
      isOpening: true,
      opening,
      sqm,
      productSetId: Number(productSetId),
      isRetail,
      userId: orderedUserId,
      itemSalesParams: openingSalesParams,
      salesParams,
    });

    const currentInstall = opening.install;

    const install = isNullish(customInstall)
      ? sum?.install || 0
      : Number(customInstall);

    const total = (sum?.price || 0) + install;

    if (currentInstall !== sum?.install) {
      setValue(`${openingCoord}.install`, sum?.install);
      setValue(`${openingCoord}.customInstall`, undefined);
    }

    if (currentPrice !== total) {
      setValue(`${openingCoord}.price`, total);
    }
  }, [
    sqm,
    openingSalesParams,
    salesParams,
    JSON.stringify(allOptions),
    customInstall,
  ]);

  return (
    <>
      {isRetail && (
        <div
          className={`relative group/option w-20 my-[5px] ${
            !showPrice && "hidden"
          }`}
        >
          <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
            Install
          </div>
          <Install coord={openingCoord} />
        </div>
      )}
      <div
        className={`relative group/option w-20 my-[5px] ${
          !showPrice && "hidden"
        }`}
      >
        <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
          Price
        </div>
        <TextInput
          addon="$"
          className="w-full"
          value={Math.ceil(currentPrice)}
          onChange={() => {}}
          sizing="sm"
        />
      </div>
    </>
  );
}

interface installProps {
  coord: string;
}
const Install = ({ coord }: installProps) => {
  const { setValue, control } = useFormContext();

  const updateInstall = (price: number) => {
    setValue(`${coord}.customInstall`, price);
  };

  const installValue = useWatch({
    name: `${coord}.install`,
    control,
    defaultValue: 0,
  });

  const customInstallValue = useWatch({
    name: `${coord}.customInstall`,
    control,
    defaultValue: undefined,
  });

  const value = isNullish(customInstallValue)
    ? installValue
    : customInstallValue;

  return (
    <TextInput
      addon="$"
      className="w-full"
      value={value}
      onChange={e => {
        updateInstall(Number(e.target.value));
      }}
      sizing="sm"
    />
  );
};
