import { ClipboardIcon, TagIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { order } from "../../types";
import { Tabs } from "flowbite-react";
import SummaryPage from "../summary/summaryPage";
import { OrderType } from "../../../types";
import { useOrderStore } from "../../../../../../store/orderStore";
import ProductTabOpening from "./productTabOpening";
import ProductTab from "./productTab";
import { useFormContext } from "react-hook-form";
import { useAuthStore } from "../../../../../../store/authStore";

interface props {
  dbOrder?: OrderType;
  cancelFunc: () => void;
}

export default function PrintPage({ cancelFunc, dbOrder }: props) {
  const { productSets } = useOrderStore();

  const { getValues } = useFormContext();

  const order = getValues() as order;

  const locations = order?.locations.map((l, i) => ({
    ...l,
    index: i + 1,
    openings: l.openings.map((o, ix) => ({
      ...o,
      items: o.items.map((item, iz) => ({ ...item, index: iz + 1 })),
      index: ix + 1,
    })),
  }));

  const openings = locations?.map(l => l.openings).flat();

  const { user } = useAuthStore();

  let products: {
    id: number;
    name: string;
    qty: number;
    openingProduct: boolean;
  }[] = [];

  if (user?.auth.name !== "dealer") {
    products = openings?.reduce<
      { id: number; name: string; qty: number; openingProduct: boolean }[]
    >((prev, cur) => {
      const Product = productSets?.find(p => p.id == cur.product);
      if (!Product) {
        return prev;
      }

      const name = Product?.name;
      const isOpeningProduct = Product.hideItems;
      const qty = isOpeningProduct ? 1 : cur.items.length;

      if (prev.find(p => p.name == name)) {
        return prev.map(pro => {
          if (pro.name == name) {
            return { ...pro, qty: pro.qty + qty };
          } else return pro;
        });
      } else {
        return prev.concat({
          id: Product.id,
          name,
          qty,
          openingProduct: Product.hideItems,
        });
      }
    }, []);
  }

  const noPriceTypes = ["measure", "workorder"];

  return (
    <div className="w-full py-6 relative flex flex-col overflow-y-visible overflow-x-auto">
      <div
        className="absolute group bg-white dark:bg-dark shadow-lg top-4 right-2 p-3 transition-all hover:scale-125 rounded-full cursor-pointer"
        onClick={cancelFunc}
      >
        <XMarkIcon className="w-6 transition-all group-hover:rotate-90 group-hover:text-red-500 dark:text-white text-black" />
      </div>

      <Tabs.Group style="underline">
        {/* Summary Tab */}
        <Tabs.Item icon={ClipboardIcon} title="Summary">
          <SummaryPage
            noPrice={noPriceTypes.includes(dbOrder?.type.name || "")}
            dbOrder={dbOrder}
          />
        </Tabs.Item>

        {products?.map(p => (
          <Tabs.Item key={p.name} icon={TagIcon} title={`${p.name} (${p.qty})`}>
            {p.openingProduct ? (
              <ProductTabOpening
                productName={p.name}
                locations={locations}
                customer={order.name}
                dbOrder={dbOrder}
              />
            ) : (
              <ProductTab
                productName={p.name}
                locations={locations}
                customer={order.name}
                dbOrder={dbOrder}
              />
            )}
          </Tabs.Item>
        ))}
      </Tabs.Group>
    </div>
  );
}
