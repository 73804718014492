import {
  Badge,
  Button,
  Modal,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import {
  orderSummaryColumn,
  orderSummaryColumnScheme,
} from "../../../workorder/types";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useScheme } from "../../../../../store/schemeStore";
import { useOrderStore } from "../../../../../store/orderStore";
import { sort } from "fast-sort";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  ADD_ORDER_SUMMARY_COLUMN,
  UPDATE_ORDER_SUMMARY_COLUMN_BY_PK,
} from "../../../workorder/gqls";
import { ORDER_SUMMARY_COLUMN_FIELDS } from "../../../workorder/fragments";

interface props {
  show: boolean;
  setShow: (bool: boolean) => void;
  column?: orderSummaryColumn;
  newPosition?: number;
}

export default function Addate({ show, setShow, column, newPosition }: props) {
  const updating = column ? true : false;

  const defaultValues: orderSummaryColumn = {
    id: column?.id || 0,
    name: column?.name || "",
    position: column?.position || newPosition || 100,
    default: column?.default,
    options: column?.options || [],
  };

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(orderSummaryColumnScheme),
  });

  // console.log(errors);

  useEffect(() => {
    if (!newPosition) {
      return;
    }
    reset({
      ...defaultValues,
      position: column?.position || newPosition,
    });
  }, [newPosition]);

  const { scheme } = useScheme();

  const { productSets, productSetOptions } = useOrderStore();

  const [insert] = useMutation(ADD_ORDER_SUMMARY_COLUMN);
  const [update] = useMutation(UPDATE_ORDER_SUMMARY_COLUMN_BY_PK);

  const onSubmit = handleSubmit(async formData => {
    if (loading) {
      return;
    }
    // console.log(data);
    setLoading(true);

    if (updating) {
      if (!column) {
        return;
      }
      update({
        variables: {
          id: column.id,
          set: {
            ...formData,
            id: undefined,
          },
        },
        onCompleted: () => {
          reset(formData);
          setShow(false);
          setLoading(false);
        },
      });
    } else {
      insert({
        variables: {
          object: formData,
        },
        onCompleted: () => {
          setShow(false);
          setLoading(false);
          reset();
        },
        update(cache, { data: { insert_orderSummaryColumn_one: newColumn } }) {
          cache.modify({
            fields: {
              orderSummaryColumn(exColumns = []) {
                const newColumnRef = cache.writeFragment({
                  data: newColumn,
                  fragment: ORDER_SUMMARY_COLUMN_FIELDS,
                  fragmentName: "OrderSummaryColumnFields",
                });
                return [...exColumns, newColumnRef];
              },
            },
          });
        },
      });
    }
  });

  const { append, remove: removeItem } = useFieldArray({
    // @ts-expect-error
    name: "options",
    control,
  });

  const addedOptions = useWatch({
    name: "options",
    control,
  });

  const [loading, setLoading] = useState(false);

  const cancel = () => {
    setShow(false);
    reset();
  };

  return (
    <Modal show={show} className={`${scheme} !h-full !items-start`}>
      <Modal.Header>Edit Summary Column</Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Body className="flex flex-col gap-2">
          <TextInput addon="Name" {...register("name")} />
          <TextInput addon="Default" {...register("default")} />
          <h3 className="mt-3">Included Options</h3>
          {productSets.map(ps => {
            const myOptions = productSetOptions.filter(
              option => option.productSetID == ps.id
            );
            return (
              <div className="flex flex-col gap-2" key={ps.id}>
                <h4 className="font-semibold">{ps.name}</h4>
                <div className="flex flex-row gap-2 items-center">
                  <Select
                    onChange={e => {
                      append(Number(e.target.value));
                    }}
                    sizing="sm"
                  >
                    <option>Select Option To Add</option>
                    {sort(myOptions)
                      .asc("position")
                      .filter(
                        option => !addedOptions.find(ao => ao == option.id)
                      )
                      .map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </Select>
                </div>
                <div className="flex flex-row gap-2 flex-wrap">
                  {addedOptions.map((opt, i) => {
                    const option = myOptions.find(o => o.id == opt);
                    if (!option) {
                      return null;
                    }

                    return (
                      <Badge
                        key={option.id}
                        color="purple"
                        className="cursor-pointer"
                        onClick={() => {
                          removeItem(i);
                        }}
                      >
                        {option.name}
                      </Badge>
                    );
                  })}
                </div>
                <hr />
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className="justify-end gap-2">
          <Button size="sm" color="purple" onClick={cancel}>
            Cancel
          </Button>
          <Button
            outline
            size="sm"
            gradientDuoTone="purpleToBlue"
            type="submit"
          >
            <div className="flex flex-row gap-2 items-center">
              {loading && <Spinner size="sm" color="purple" />}
              {updating ? "Update" : "Add"}
            </div>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
