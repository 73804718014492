import { ProcessType } from "../types";
import { useNavigate } from "react-router-dom";
import { fullNumber } from "../../../../utils/fullNumber";
import dayjs from "dayjs";
import { currencyFormat } from "../../../../utils/numberMethods";
import { Active } from "@dnd-kit/core";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../../settings/products/products";

interface props {
  item: ProcessType;
  active?: Active;
  overlay: boolean;
  updating?: undefined | number;
}
export default function BoardItem({ item, active, overlay, updating }: props) {
  const navigate = useNavigate();
  const { data } = useQuery(GET_PRODUCTS);
  const goToDetail = () => {
    navigate("./detail/" + item.id);
  };

  const number = `[${fullNumber(
    item.processType.prefix,
    item.year,
    item.number,
    item?.salesRepUser
  )}]`;

  const isActive = active && active.id == item.id;
  const isUpdating = updating && updating == item.id;

  const className = `mx-[1px] flex flex-col gap-1 ring-1 ring-gray-200 dark:ring-gray-600 p-2 cursor-pointer bg-white dark:bg-gray-700 rounded-md dark:hover:text-grass hover:text-plum
    ${
      isActive
        ? overlay
          ? "dark:ring-grass ring-plum text-plum dark:text-grass"
          : "opacity-50"
        : ""
    }

    ${isUpdating ? "opacity-50" : ""}
    `;

  const isPastDue =
    item.processStatus.dueMatters && dayjs(item.due).isBefore(new Date());

  const productsString =
    item.products?.reduce<string>((prev, cur) => {
      const product =
        data?.products && data.products.find(pr => pr.id == cur.productId);
      if (product) {
        return prev.concat(` ${product.name} ${cur.qty}`);
      } else {
        return prev;
      }
    }, "") || "";

  return (
    <div onClick={goToDetail} className={className}>
      <div className="text-sm flex flex-row justify-between">
        <span>{number}</span>
        {item.due ? (
          <div className={`${isPastDue ? "text-red-500" : ""}`}>
            {dayjs(item.due).format("DD/MM")}{" "}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="truncate">{item.name}</div>
      <div className="flex flex-row justify-between text-xs gap-2 flex-wrap items-center">
        <div className=" flex-1 w-0 truncate">{productsString}</div>
        {currencyFormat(item.value || 0)}
      </div>
      {/* TODO: Assigned task qty */}
    </div>
  );
}
