import { TextInput } from "flowbite-react";
import { calc } from "../../types";
import { useWatch, useFormContext } from "react-hook-form";
import isNullish from "../../../../../../utils/isNullish";
import { useEffect, useState } from "react";
import { useOrderStore } from "../../../../../../store/orderStore";

interface props {
  locationIndex: number;
  openingIndex: number;
  itemIndex: number;
  index: number;
}

export default function Calc({
  index,
  itemIndex,
  locationIndex,
  openingIndex,
}: props) {
  const { control } = useFormContext();
  const calcsIndex = `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}.calcs`;
  const calc: calc = useWatch({
    name: `${calcsIndex}.${index}`,
    control,
  });

  const { getProductSetOptionById } = useOrderStore();

  const optionName =
    getProductSetOptionById(calc.optionId)?.displayName ||
    getProductSetOptionById(calc.optionId)?.name;

  const [show, setShow] = useState(true);
  const [needPrefix, setNeedPrefix] = useState(false);
  const name = `${needPrefix ? `${optionName} ` : ""}${
    isNullish(calc.displayName) ? calc.name : calc.displayName || ""
  }`;

  // console.log(calc.show, calc.name);

  return (
    <div
      className={`relative mr-1 group/option min-w-[80px] ${
        (!show || !calc.show) && "hidden"
      } my-[5px]`}
      style={{
        width: `${name.length * 10}px`,
      }}
    >
      <div className="left-[50%] -translate-x-[50%] -top-[8px] text-grass w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
        {name}
      </div>
      <TextInput className="w-full" value={calc.size} disabled sizing="sm" />
      <ShowControl
        show={show}
        setShow={setShow}
        calc={calc}
        calcsIndex={calcsIndex}
        needPrefix={needPrefix}
        setNeedPrefix={setNeedPrefix}
        index={index}
      />
    </div>
  );
}

interface showControlProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  calcsIndex: string;
  calc: calc;
  index: number;
  needPrefix: boolean;
  setNeedPrefix: React.Dispatch<React.SetStateAction<boolean>>;
}
const ShowControl = ({
  show,
  setShow,
  calcsIndex,
  calc,
  index,
  needPrefix,
  setNeedPrefix,
}: showControlProps) => {
  const { control } = useFormContext();

  const calcs: calc[] = useWatch({
    name: calcsIndex,
    control,
  });
  const calcsNotMe = calcs.filter(
    c => !(c.optionId == calc.optionId && c.name == calc.name)
  );

  useEffect(() => {
    let showCheck = true;
    let prefixCheck = false;
    const sameCalcDiffOption = calcsNotMe.find(
      c => c.name == calc.name && c.optionId !== calc.optionId
    );
    if (sameCalcDiffOption) {
      if (sameCalcDiffOption.size == calc.size) {
        const sameCalcDiffOptionIndex = calcs.findIndex(
          c =>
            c.name == sameCalcDiffOption.name &&
            c.optionId == sameCalcDiffOption.optionId
        );
        showCheck = index > sameCalcDiffOptionIndex;
      } else {
        prefixCheck = true;
      }
    }

    if (showCheck !== show) {
      setShow(showCheck);
    }
    if (prefixCheck !== needPrefix) {
      setNeedPrefix(prefixCheck);
    }
  }, [calc.size, JSON.stringify(calcsNotMe)]);

  return null;
};
