import {
  GoogleMap,
  LoadScriptProps,
  useLoadScript,
} from "@react-google-maps/api";
import {
  darkStyles,
  googleMapOptions,
  lightStyles,
} from "../process/mapOptions";
import { useScheme } from "../../../store/schemeStore";
import MapMarker from "../process/addProcess/map/marker";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
import "../process/processDetail/map.css";
import { default as config } from "../../../config";
import { RecordType } from "../shared/record/types";
import { calcDistance, midPoint } from "../../../utils/geocode";

const companyLoc = config.companyLoc;

const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];

interface props {
  record: RecordType;
}

export default function RecordMap({ record }: props) {
  const { scheme } = useScheme();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });

  if (!isLoaded) return <div>Loading...</div>;

  const mapOptions = {
    ...googleMapOptions,
    styles: scheme == "dark" ? darkStyles : lightStyles,
  };

  const { endLoc, startLoc } = record;

  return (
    <div
      className="col-span-1
      @lg:col-span-2 supports-[not(container-type:inline-size)]:lg:col-span-2
    space-y-2 min-h-[280px]"
    >
      <div className="h-full overflow-hidden relative">
        <div
          className={`z-10 w-full h-full absolute pointer-events-none vignette${
            scheme == "dark" ? "-dark" : ""
          } `}
        />
        <GoogleMap
          zoom={14}
          tilt={0}
          center={endLoc || startLoc || companyLoc}
          options={mapOptions}
          mapContainerClassName="processDetailMap"
        >
          {startLoc && endLoc && calcDistance(startLoc, endLoc) < 100 ? (
            <MapMarker
              marker={{
                position: midPoint(startLoc, endLoc),
                label: "in/out",
              }}
            />
          ) : (
            <>
              {startLoc && (
                <MapMarker
                  marker={{
                    position: startLoc,
                    label: "in",
                  }}
                />
              )}
              {endLoc && (
                <MapMarker
                  marker={{
                    position: endLoc,
                    label: "out",
                  }}
                />
              )}
            </>
          )}
        </GoogleMap>
      </div>
    </div>
  );
}
