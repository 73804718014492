import { useOrderStore } from "../../../../../store/orderStore";
import { item, location } from "../types";
import { materialWithInventory } from "./orderPrint/types";
import useGetMaterialsWithInventory from "./useGetMaterialsWithInventory";

export default function useGetBlindFabricCutCharge(locations: location[]) {
  const { productSets } = useOrderStore();

  const itemsPerProductSets = productSets
    .filter(productSet => !!!productSet.hideItems)
    .map(productSet => {
      const items: item[] = [];
      for (const location of locations) {
        for (const opening of location.openings) {
          const openingProductSet = productSets.find(
            ps => ps.id == Number(opening.product)
          );
          if (!openingProductSet) continue;
          if (productSet.id == openingProductSet.id)
            items.push(...opening.items);
        }
      }
      return {
        productSet,
        items,
      };
    })
    .filter(group => group.items.length > 0);

  const getMaterialsWithInventory = useGetMaterialsWithInventory();

  const materialsPerProductSets = itemsPerProductSets.map(group => {
    const materialsWithInventory = getMaterialsWithInventory(
      group.productSet.id,
      false,
      group.items
    );
    return { productSet: group.productSet, materials: materialsWithInventory };
  });

  const allMats = materialsPerProductSets
    .map(group => group.materials)
    .flat()
    .reduce((acc, cur) => {
      if (!acc.find(m => m.id == cur.id)) {
        acc.push(cur);
      } else {
        const index = acc.findIndex(m => m.id == cur.id);
        acc[index] = {
          ...acc[index],
          qty: Number(acc[index].qty) + Number(cur.qty),
        };
      }

      return acc;
    }, [] as materialWithInventory[]);

  const blindFabricCutFee = allMats.reduce((fee, mat) => {
    if (mat.inventory?.optionCode == "BlindFabric") {
      const sqm =
        (Number(mat.inventory.widthDeduction) *
          Number(mat.inventory.heightDeduction)) /
        1000000;

      if (mat.qty < sqm) {
        fee += 100;
      }
    }
    return fee;
  }, 0);

  return blindFabricCutFee;
}
