import { optionValues } from "../../workorder/types";
import { Badge } from "flowbite-react";
import { sort } from "fast-sort";
import { GET_PRODUCT_OPTIONS_CONDITIONAL } from "../../workorder/gqls";
import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import ValuePopulate from "../../workorder/ValuePopulate";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface props {
  optionValuesArray?: optionValues[];
  productSetId: number;
  remove?: (id: number) => void;
}

export default function OptionValuesArray({
  optionValuesArray,
  productSetId,
  remove,
}: props) {
  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: Number(productSetId) },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const optionTypes = ["select"];
  const options = data_options
    ? sort(data_options.productSetOptions)
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  return (
    <div className="flex flex-row gap-2 flex-wrap">
      {optionValuesArray &&
        sort(optionValuesArray)
          .asc("option")
          ?.map(optionValue => {
            return (
              <Badge
                key={optionValue.option}
                color="purple"
                className="cursor-pointer"
                onClick={() => {
                  if (remove) {
                    remove(optionValue.option);
                  }
                }}
              >
                <div className="flex flex-row gap-1 items-center">
                  {options.find(o => o.id == optionValue.option)?.name}
                  {optionValue.values && (
                    <>
                      :
                      {optionValue.values?.map(val => (
                        <Fragment key={val}>
                          <ValuePopulate
                            source={
                              options.find(o => o.id == optionValue.option)
                                ?.source
                            }
                            value={val}
                          />
                          ,
                        </Fragment>
                      ))}
                    </>
                  )}
                  {remove && <XMarkIcon className="w-4" />}
                </div>
              </Badge>
            );
          })}
    </div>
  );
}
