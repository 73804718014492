import { gql } from "@apollo/client";

export const RECORD_STATUS_CORE_FIELDS = gql`
  fragment RecordStatusCoreFields on recordStatus {
    id
    name
    priority
    color
  }
`;

export const RECORD_TYPE_CORE_FIELDS = gql`
  fragment RecordTypeCoreFields on recordType {
    id
    name
    priority
    color
  }
`;

export const RECORD_CORE_FIELDS = gql`
  ${RECORD_STATUS_CORE_FIELDS}
  ${RECORD_TYPE_CORE_FIELDS}
  fragment RecordCoreFields on records {
    id
    created_at
    updated_at
    user {
      id
      firstName
      sirName
      email
      hours
      created_at
      teamID
    }
    type {
      ...RecordTypeCoreFields
    }
    status {
      ...RecordStatusCoreFields
    }
    start
    end
    break
    startLoc
    endLoc
    desc
    logs
  }
`;
