import { useState, MouseEvent } from "react";
import {
  TextInput,
  Label,
  Button,
  Spinner,
  Table,
  Checkbox,
} from "flowbite-react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql, useMutation } from "@apollo/client";

import { UserType } from "../types";
import checkAuth from "../../../../../utils/checkAuth";

export const hourTerm = z
  .object({
    start: z.coerce
      .number()
      .min(0, { message: "start hour must be bigger than 0" })
      .max(24, { message: "start hour must be smaller than 25" })
      .optional(),
    break: z.coerce.number().optional(),
    end: z.coerce
      .number()
      .min(0, { message: "end hour must be bigger than 0" })
      .max(24, { message: "end hour must be smaller than 25" })
      .optional(),
    off: z.boolean().optional(),
  })
  .refine(
    data => {
      if (!data.start && !data.end) {
        return true;
      }
      if (data.end && data.start && data.end > data.start) {
        return true;
      }
    },
    {
      message: "end time cannot be samller than start time",
      path: ["end"], // path of error
    }
  );
export const termsSchema = z.object({
  hours: z
    .object({
      sun: hourTerm,
      mon: hourTerm,
      tue: hourTerm,
      wed: hourTerm,
      thu: hourTerm,
      fri: hourTerm,
      sat: hourTerm,
    })
    .optional(),
});

export const UPDATE_TERMS_HOURS = gql`
  mutation UPDATE_TERMS_HOURS($id: Int!, $hours: jsonb) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { hours: $hours }) {
      id
      hours
    }
  }
`;

export default function Terms({ user }: { user: UserType }) {
  const [update_terms_hours, { loading, error }] =
    useMutation(UPDATE_TERMS_HOURS);
  if (error) {
    console.log(error);
  }
  const [editing, setEditing] = useState(false);

  const methods = useForm({
    resolver: zodResolver(termsSchema),
    defaultValues: {
      hours: user.hours || undefined,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  console.log(errors);

  const onSubmit = handleSubmit(async data => {
    update_terms_hours({
      variables: { id: user?.id, ...data },
      onCompleted(data) {
        if (data?.update_users_by_pk) {
          const { hours } = data.update_users_by_pk;
          reset({
            hours,
          });
        }
      },
      onError(error) {
        console.log(error);
      },
    });
    setEditing(state => !state);
  });

  const clickEdit = (e: MouseEvent) => {
    if (!editing) {
      e.preventDefault();
      setEditing(state => !state);
    }
  };

  const clickCancle = () => {
    setEditing(false);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <form className="mb-1 flex-grow max-w-full mx-auto" onSubmit={onSubmit}>
        <div className=" flex flex-row justify-between">
          <div className="text-2xl font-bold mb-2">Terms</div>
        </div>
        <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 ">
          <div className="mb-2 block mt-2">
            <Label htmlFor="Hours" value="Hours" />
          </div>
          <Table hoverable={true}>
            <Table.Head className="select-text">
              <Table.HeadCell>Day</Table.HeadCell>
              <Table.HeadCell>Start(hr)</Table.HeadCell>
              <Table.HeadCell>End(hr)</Table.HeadCell>
              <Table.HeadCell>Break(min)</Table.HeadCell>
              <Table.HeadCell>Off</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              <Day day="sun" editing={editing} />
              <Day day="mon" editing={editing} />
              <Day day="tue" editing={editing} />
              <Day day="wed" editing={editing} />
              <Day day="thu" editing={editing} />
              <Day day="fri" editing={editing} />
              <Day day="sat" editing={editing} />
            </Table.Body>
          </Table>

          <hr className="my-4 border-gray-300 dark:border-gray-500" />
          <div className="flex space-x-2 justify-end">
            {(editing || loading) && (
              <Button
                gradientDuoTone="purpleToBlue"
                size="sm"
                onClick={clickCancle}
              >
                Cancel
              </Button>
            )}

            {checkAuth("setting_users_edit_terms") && (
              <Button
                onClick={clickEdit}
                outline
                type="submit"
                gradientDuoTone="purpleToBlue"
                size="sm"
              >
                {!editing && !loading && <PencilIcon className="w-4 mr-2" />}
                {loading && (
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                )}
                {editing || loading ? "Save" : "Edit"}
              </Button>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

const Day = ({ day, editing }: { day: string; editing: boolean }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  //@ts-expect-error
  const myErrors = errors.hours && errors.hours[day];
  const errorMessage = `${myErrors?.start?.message || ""} ${
    myErrors?.end?.message || ""
  } ${myErrors?.break?.message || ""}`;

  return (
    <>
      <Table.Row>
        <Table.Cell className="capitalize">{day}</Table.Cell>
        <Table.Cell>
          <TextInput
            sizing="sm"
            {...register(`hours.${day}.start`)}
            type="number"
            disabled={!editing}
            step={0.01}
          />
        </Table.Cell>
        <Table.Cell>
          <TextInput
            sizing="sm"
            {...register(`hours.${day}.end`)}
            type="number"
            disabled={!editing}
            step={0.01}
          />
        </Table.Cell>
        <Table.Cell>
          <TextInput
            sizing="sm"
            {...register(`hours.${day}.break`)}
            type="number"
            disabled={!editing}
          />
        </Table.Cell>
        <Table.Cell>
          <Checkbox {...register(`hours.${day}.off`)} disabled={!editing} />
        </Table.Cell>
      </Table.Row>
      {myErrors && (
        <Table.Row className="text-red-500">
          <Table.Cell colSpan={4}>{errorMessage}</Table.Cell>
        </Table.Row>
      )}
    </>
  );
};
