import { Outlet, useNavigate, useRoutes } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useAssignmentStore,
  useDefaultFilters,
  refineFilters,
} from "../../../store/assignmentStore";
import { assignmentQueryParams } from "./types";
import checkAuth from "../../../utils/checkAuth";
import { useAuthStore } from "../../../store/authStore";
import AssignmentLoader from "./assignmentLoader";
import AssignmentDetail from "./assignmentDetail";

const AssignmentWrapper = () => {
  return <Outlet />;
};

export default function Assignment() {
  const { filters } = useAssignmentStore();
  useDefaultFilters();
  const [refinedfilters, setRefinedFilters] = useState<assignmentQueryParams>({
    where: {
      _and: [],
    },
    offset: 0,
  });

  const { user } = useAuthStore();

  useEffect(() => {
    const _refinedFilters = refineFilters(
      filters,
      checkAuth("assignment_see_all") ? undefined : user
    );
    setRefinedFilters(_refinedFilters);
  }, [filters]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!checkAuth("assignment_access")) {
      navigate("/");
    }
  }, []);

  const assignmentRoutes = useRoutes([
    {
      path: "/",
      element: <AssignmentWrapper />,
      children: [
        {
          path: "/",
          element: <AssignmentLoader filters={refinedfilters} />,
        },
        {
          path: "/detail/:id",
          element: <AssignmentDetail />,
        },
      ],
    },
  ]);
  return assignmentRoutes;
}
