import { TypedDocumentNode, gql } from "@apollo/client";
import { PRICING_TABLE_FIELDS, QUOTE_PARAMS_FIELDS } from "./fragments";
import {
  QuoteParams,
  QuoteParamsArrayType,
  pricingTable,
  pricingTableArrayType,
} from "./types";

export const GET_QUOTE_PARAMS: TypedDocumentNode<QuoteParamsArrayType> = gql`
  ${QUOTE_PARAMS_FIELDS}
  query GET_QUOTE_PARAMS {
    quoteParams {
      ...QuoteParamsFields
    }
  }
`;

interface update_quoteParams_by_pk_type {
  update_quoteParams_by_pk: QuoteParams;
}

export const UPDATE_QUOTE_PARAM_BY_PK: TypedDocumentNode<update_quoteParams_by_pk_type> = gql`
  ${QUOTE_PARAMS_FIELDS}
  mutation UPDATE_QUOTE_PARAM_BY_PK($id: Int!, $set: quoteParams_set_input!) {
    update_quoteParams_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...QuoteParamsFields
    }
  }
`;

export const ADD_QUOTE_PARAM = gql`
  ${QUOTE_PARAMS_FIELDS}
  mutation ADD_QUOTE_PARAM($object: quoteParams_insert_input!) {
    insert_quoteParams_one(object: $object) {
      ...QuoteParamsFields
    }
  }
`;

export const GET_PRICING_TABLES: TypedDocumentNode<pricingTableArrayType> = gql`
  ${PRICING_TABLE_FIELDS}
  query GET_PRICING_TABLES {
    productSetPricingTable {
      ...PricingTableFields
    }
  }
`;

interface update_productSetPricingTable_by_pk_type {
  update_productSetPricingTable_by_pk: pricingTable;
}

export const UPDATE_PRICING_TABLE_BY_PK = gql`
  ${PRICING_TABLE_FIELDS}
  mutation UPDATE_PRICING_TABLE_BY_PK(
    $id: Int!
    $set: productSetPricingTable_set_input!
  ) {
    update_productSetPricingTable_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...PricingTableFields
    }
  }
`;

export const ADD_PRICING_TABLE = gql`
  ${PRICING_TABLE_FIELDS}
  mutation ADD_PRICING_TABLE($object: productSetPricingTable_insert_input!) {
    insert_productSetPricingTable_one(object: $object) {
      ...PricingTableFields
    }
  }
`;
