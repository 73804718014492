import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { option } from "../../../types";
import { useOrderStore } from "../../../../../../../store/orderStore";

interface props {
  prvScopes: any;
  scopeName: string;
  option: option;
}
export default function useScopeConcat() {
  const { deductions, materials, units } = useOrderStore();

  return ({ prvScopes, scopeName, option }: props) => {
    let appended = {
      ...prvScopes,
      [scopeName]: option.value,
    };
    if (option.source == "inventory") {
      const material = materials.find(mat => mat.id == Number(option.value));

      if (material) {
        appended = {
          ...appended,
          [`${scopeName}_W`]: isNullish(material.widthDeduction)
            ? 0
            : Number(material.widthDeduction),
          [`${scopeName}_H`]: isNullish(material.heightDeduction)
            ? 0
            : Number(material.heightDeduction),
          [`${scopeName}_Code`]: isNullish(material.code) ? 0 : material.code,
          [`${scopeName}_Size`]: isNullish(material.size)
            ? 0
            : Number(material.size),
        };

        if (material.params) {
          for (const param of material.params) {
            appended = {
              ...appended,
              [`${scopeName}_${param.name}`]: param.value,
            };
          }
        }

        const materialPrices = material.prices || [];
        const materialPricesWithUnitName = materialPrices.map(price => ({
          unit: units.find(unit => unit.id == price.unit),
          price: price.price,
        }));

        for (const price of materialPricesWithUnitName) {
          if (!price.unit?.name) continue;
          appended = {
            ...appended,
            [`${scopeName}_${price.unit.name}_Price`]: isNullish(price.price)
              ? 0
              : Number(price.price),
          };
        }
      }
    }
    if (option.source == "deductions") {
      const deduction = deductions.find(mat => mat.id == Number(option.value));
      if (deduction) {
        appended = {
          ...appended,
          [`${scopeName}_W`]: isNullish(deduction.width)
            ? 0
            : Number(deduction.width),
          [`${scopeName}_H`]: isNullish(deduction.height)
            ? 0
            : Number(deduction.height),
        };
      }
    }
    return appended;
  };
}
