import { Badge } from "flowbite-react";
import { TagType } from "./types";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

export default function Tag({
  tag,
  deleting,
}: {
  tag: TagType;
  deleting: number;
}) {
  const { attributes, listeners, setNodeRef, transform, isDragging, over } =
    useDraggable({
      id: tag.id,
      data: {
        tag,
      },
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging && !over ? 0.5 : 1,
    display: deleting == tag.id ? "none" : "unset",
  };

  return (
    <div
      style={style}
      className="flex flex-row items-center"
      {...attributes}
      {...listeners}
      ref={setNodeRef}
    >
      <Badge
        className="cursor-pointer"
        color={`${over && isDragging ? "failure" : "purple"}`}
      >
        {tag.name}
      </Badge>
    </div>
  );
}
