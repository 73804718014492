import { useQuery } from "@apollo/client";
import { UserType } from "../types";
import { GET_RECORD } from "../../../shared/record/gqls";
import totalH from "../../../shared/record/totalHCalc";
import { sort } from "fast-sort";
import dayjs from "dayjs";
import { Label } from "flowbite-react";

interface props {
  user: UserType;
}

export default function Leaves({ user }: props) {
  const { data } = useQuery(GET_RECORD, {
    variables: {
      where: {
        who: { _eq: user.id },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const records = sort(data?.records || []).asc("start");

  const totalHours = records.reduce<number>((prev, cur) => {
    if (cur.status.name !== "confirmed") {
      return prev;
    }
    if (cur.type.name == "unpaid leave") {
      return prev;
    }
    const hours = totalH({
      startTime: cur.start,
      endTime: cur.end,
      brk: cur.break,
      date: new Date(),
    });
    return prev + hours;
  }, 0);

  const usedAnnualLeaveHours = records.reduce<number>((prev, cur) => {
    if (cur.status.name !== "confirmed") {
      return prev;
    }
    if (cur.type.name !== "annual leave") {
      return prev;
    }
    const hours = totalH({
      startTime: cur.start,
      endTime: cur.end,
      brk: cur.break,
      date: new Date(),
    });
    return prev + hours;
  }, 0);

  const usedSickLeaveHours = records.reduce<number>((prev, cur) => {
    if (cur.status.name !== "confirmed") {
      return prev;
    }
    if (cur.type.name !== "sick leave") {
      return prev;
    }
    const hours = totalH({
      startTime: cur.start,
      endTime: cur.end,
      brk: cur.break,
      date: new Date(),
    });
    return prev + hours;
  }, 0);

  const leaveAccPerHour = 0.0769210526315789;
  const sickLeaveRatePerHour = 1 / 26;

  const entitledLeaveHours = totalHours * leaveAccPerHour;
  const entitledSickLeaves = totalHours * sickLeaveRatePerHour;

  // TODO: user leaves calc start date needed
  // TODO: user leave hours compensation for pre-recorded hours

  return (
    <div className="mb-1 flex-grow max-w-full mx-auto">
      <div className=" flex flex-row justify-between">
        <div className="text-2xl font-bold mb-2">Leaves</div>
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2">
        <Label value="First Record" />
        {records[0] ? dayjs(records[0]?.start).format("DD MMM YYYY") : ""}
        <Label value="Paid Hours Worked" />
        {totalHours}
        <Label value="Annual Leave" />
        <div>
          {entitledLeaveHours.toFixed(2)} (total) -{" "}
          {usedAnnualLeaveHours.toFixed(2)} (used) ={" "}
          {(entitledLeaveHours - usedAnnualLeaveHours).toFixed(2)}
        </div>
        <Label value="Sick Leave" />
        <div>
          {entitledSickLeaves.toFixed(2)} (total) -{" "}
          {usedSickLeaveHours.toFixed(2)} (used) ={" "}
          {(entitledSickLeaves - usedSickLeaveHours).toFixed(2)}
        </div>
      </div>
    </div>
  );
}
