import { sort } from "fast-sort";
import { Button } from "flowbite-react";
import { useScheduleStore } from "../../../store/scheduleStore";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USERS } from "../settings/users/gql";
import checkAuth from "../../../utils/checkAuth";
import isNullish from "../../../utils/isNullish";
import { useAuthStore } from "../../../store/authStore";
import { UserType } from "../settings/users/types";

interface props {
  filtering: boolean;
  setFiltering: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Filters({ filtering, setFiltering }: props) {
  const { filters, setUserFilter, setFilters } = useScheduleStore();
  const userFilter = filters.userFilter;

  const { user } = useAuthStore();
  const { data: data_users } = useQuery(GET_ACTIVE_USERS);

  const options_user =
    data_users?.users &&
    sort(
      data_users?.users
        ?.filter(
          u =>
            (checkAuth([
              "schedule_view_all",
              {
                permission: "schedule_view_team",
                checkGroup: "userTeam",
                conditionGroup: [u.team?.id || -1],
              },
            ]) ||
              u.id == user?.id) &&
            u.active
        )
        .filter(u => !isNullish(u.calId))
    ).asc("firstName");

  const defaultValues = { userFilter };

  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const formUserFilter = watch("userFilter") || [];

  const isActive = (id: number) => formUserFilter.find(u => u.id == id);

  const toggle = (user: UserType) => {
    if (isActive(user.id)) {
      setValue(
        "userFilter",
        formUserFilter.filter(
          u => u.id !== user.id && options_user?.find(ou => ou.id == u.id)
        ),
        { shouldDirty: true }
      );
    } else {
      setValue(
        "userFilter",
        formUserFilter
          .filter(u => options_user?.find(ou => u.id == u.id))
          .concat(user),
        { shouldDirty: true }
      );
    }
  };

  useEffect(() => {
    reset({ userFilter });
  }, [userFilter]);

  useEffect(() => {
    const savedFilters = localStorage.getItem("scheduleFilters");

    if (userFilter.length == 0 && !savedFilters && user?.calId) {
      return setUserFilter([{ ...user, permissions: [] }]);
    }

    if (savedFilters) {
      let parsedFilters = JSON.parse(savedFilters);
      if (
        parsedFilters.userFilter &&
        parsedFilters.userFilter.length == 0 &&
        user?.calId
      ) {
        parsedFilters.userFilter.push({ ...user, permissions: [] });
      }
      setFilters(parsedFilters);
    }
  }, []);

  const update = handleSubmit(data => {
    setUserFilter(data.userFilter);
    reset(data);
    setFiltering(false);
  });

  return (
    <form
      onSubmit={update}
      className={`dark:bg-gray-800 bg-white flex flex-col gap-2 flex-wrap transition-all duration-500 ease-in-out px-6 overflow-hidden ${
        !filtering
          ? "max-h-0 border-0"
          : "max-h-[300vh] h-auto border-y-[1px] border-gray-200 dark:border-gray-600 mb-2 py-2"
      }`}
    >
      {/* UserFilter*/}
      <div className="flex flex-row flex-wrap gap-2">
        {options_user?.map(u => (
          <Button
            key={u.id}
            // outline={isActive(u.id) ? false : true}
            color={isActive(u.id) ? "purple" : "light"}
            onClick={() => {
              toggle(u);
            }}
            size="xs"
            className={`capitalize transition-all ${
              isActive(u.id) ? "" : "opacity-75"
            }`}
          >
            {u.firstName} {u.sirName}
          </Button>
        ))}
      </div>

      {isDirty && (
        <div className="flex flex-row justify-end gap-2 mt-2">
          <Button
            color="purple"
            size="xs"
            onClick={() => {
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            outline
            gradientDuoTone="purpleToBlue"
            size="xs"
            type="submit"
          >
            Apply
          </Button>
        </div>
      )}
    </form>
  );
}
