import { Outlet, useRoutes } from "react-router-dom";
import { useEffect, useState } from "react";
import ContactsLoader from "./contactsLoader";
import {
  useContactStore,
  useDefaultFilters,
  refineFilters,
} from "../../../store/contactStore";
import { contactQueryParams } from "./types";
import ContactDetail from "./contactDetail";
import AddContact from "./addContact/addContact";
import { searchResult, useSearchStore } from "../../../store/searchStore";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_CONTACT } from "../process/addProcess/contact";

const ContactWrapper = () => {
  const { setSearchFunction, clearSearchFunction, setSearchResults } =
    useSearchStore();

  const [search_contacts] = useLazyQuery(SEARCH_CONTACT);

  useEffect(() => {
    setSearchFunction(str => {
      if (!str) {
        return;
      }
      search_contacts({
        variables: { Str: str },
        fetchPolicy: "network-only",
        onCompleted(data) {
          if (data.search_contacts) {
            const res: searchResult[] = data.search_contacts.map(c => ({
              header: c.name,
              desc: c.number || c.mail || "",
              link: "contacts/detail/" + c.id,
            }));
            setSearchResults(res);
          }
        },
      });
    });

    return () => {
      clearSearchFunction();
      setSearchResults([]);
    };
  }, []);
  return <Outlet />;
};

export default function Contacts() {
  const { filters } = useContactStore();
  useDefaultFilters();
  const [refinedfilters, setRefinedFilters] = useState<contactQueryParams>({
    where: {
      _and: [],
    },
    offset: 0,
  });

  useEffect(() => {
    const _refinedFilters = refineFilters(filters);
    setRefinedFilters(_refinedFilters);
  }, [filters]);

  const contactRoutes = useRoutes([
    {
      path: "/",
      element: <ContactWrapper />,
      children: [
        {
          path: "/",
          element: <ContactsLoader filters={refinedfilters} />,
        },
        {
          path: "/detail/:id",
          element: <ContactDetail />,
        },
        {
          path: "/add",
          element: <AddContact />,
        },
      ],
    },
  ]);
  return contactRoutes;
}
