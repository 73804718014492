import { useState } from "react";
import { Modal, Tooltip, Button, Spinner } from "flowbite-react";
import { useScheme } from "../../../../../store/schemeStore";
import { useMutation } from "@apollo/client";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";
import { UserType } from "../types";
import { CHANGE_COLOR } from "../../../profile/color";

const colorSchema = z.object({
  color: z.string().min(4, { message: "enter valid color" }),
});

export default function Color({ user }: { user: UserType }) {
  const { scheme } = useScheme();

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [change_color, { loading, error }] = useMutation(CHANGE_COLOR);
  if (error) {
    console.log(error);
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(colorSchema),
    defaultValues: {
      color: user?.color || "",
    },
  });

  const onSubmit = handleSubmit(async color => {
    change_color({
      variables: { id: user?.id, ...color },
      onCompleted(data) {
        if (data?.update_users_by_pk) {
          const { color } = data.update_users_by_pk;

          reset({
            color,
          });

          toggleModal();
          addAlert({ message: "color updated successfully", type: "success" });
        }
      },
    });
  });

  return (
    <>
      <main className="absolute right-[-10px]">
        <Tooltip content="change the user color" className="w-max">
          <div
            onClick={toggleModal}
            className={`cursor-pointer w-5 h-5 rounded-full ring-black dark:ring-white ring-1 dark:ring-2 animate-[pulse_1s_ease-in-out]`}
            style={{
              backgroundColor: `${user?.color ? user.color : "#CCCCCC"}`,
            }}
          />
        </Tooltip>
      </main>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        className={scheme === "dark" ? "dark" : ""}
      >
        <Modal.Header>Change User Color</Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <input
              type="color"
              {...register("color")}
              className="w-full h-10 border-2 p-0 bg-transparent border-none"
            />
            <div> {errors.color?.message || ""}</div>

            <div className="flex flex-row justify-end mt-5 gap-2">
              <Button
                gradientDuoTone="purpleToBlue"
                size="sm"
                onClick={toggleModal}
              >
                Cancel
              </Button>
              <Button
                gradientDuoTone="purpleToBlue"
                outline
                size="sm"
                type="submit"
              >
                {loading && (
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                )}
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
