import { Select, TextInput } from "flowbite-react";
import { RefAttributes } from "react";
import { ProductSetType } from "../../../../workorder/types";
import { useOrderStore } from "../../../../../../store/orderStore";

export default function ProductSetWarningControls({
  productSetId,
  registerWhere,
  registerCondition,
  registerMessage,
}: {
  productSetId: ProductSetType["id"];
  registerWhere: RefAttributes<HTMLSelectElement>;
  registerCondition: RefAttributes<HTMLInputElement>;
  registerMessage: RefAttributes<HTMLInputElement>;
}) {
  const { productSetOptions } = useOrderStore();

  const options = productSetOptions.filter(
    o => o.productSetID === productSetId
  );

  const wheres = [
    {
      id: "Width",
      name: "Width",
    },
    {
      id: "Height",
      name: "Height",
    },
    ...options.map(({ id, name }) => ({
      id,
      name,
    })),
  ];

  return (
    <>
      <Select
        sizing="sm"
        {...registerWhere}
        defaultValue={wheres[0].id}
        style={{ opacity: 1 }}
      >
        {wheres.map(where => (
          <option key={where.id} value={where.id}>
            {where.name}
          </option>
        ))}
      </Select>
      <TextInput
        type="text"
        placeholder="Condition"
        {...registerCondition}
        addon="f(x)"
        sizing="sm"
        className="flex-1"
      />
      <TextInput
        type="text"
        placeholder="Warning"
        {...registerMessage}
        addon="msg"
        sizing="sm"
        className="flex-1"
      />
    </>
  );
}
