import { Toast } from "flowbite-react";
import { ToastType, useNotificationStore } from "../store/notificationStore";
import DynamicHeroIcon from "../comps/hIcon";
import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Sound from "./Sound";

interface props {
  toast: ToastType;
}
export default function MyToast({ toast }: props) {
  const { removeToast, sound } = useNotificationStore();
  let icon;

  switch (toast.type) {
    case "info":
      icon = "BellAlertIcon";
      break;
    case "success":
      icon = "CheckCircleIcon";
      break;
    case "warning":
      icon = "ExclamationCircleIcon";
      break;
    case "failure":
      icon = "XCircleIcon";
      break;
    default:
      icon = "BellAlertIcon";
      break;
  }

  const [visible, setVisible] = useState(false);

  const dismiss = () => {
    setVisible(false);
    setTimeout(() => {
      removeToast(toast.id);
    }, 200);
  };
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 200);

    setTimeout(() => {
      dismiss();
    }, 3000);
  }, []);
  return (
    <Toast
      className={`w-60 border-[1px] dark:border-gray-700 max-w-full ${
        !visible && "translate-x-[120%]"
      }`}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <DynamicHeroIcon icon={icon} class="w-5" />
          <div className="text-md font-semibold">{toast.message}</div>
        </div>
        <XMarkIcon onClick={dismiss} className="w-4 cursor-pointer" />
      </div>
      {sound && <Sound />}
    </Toast>
  );
}
