import { useEffect } from "react";
// @ts-expect-error
import ViewSDKClient from "./ViewSDKClient";
import { useParams } from "react-router";
import { useNavStore } from "../../store/navStore";
import { createPortal } from "react-dom";

const FullWindow = () => {
  const { name, file } = useParams();
  const { setShow } = useNavStore();

  console.log(file);

  useEffect(() => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      /* By default the embed mode will be Full Window */
      viewSDKClient.previewFile("pdf-div", {}, file, name);
    });

    setShow(false);
  }, []);

  return createPortal(
    <div
      id="pdf-div"
      className="h-screen w-screen z-50 absolute top-0 left-0"
    />,
    document.body
  );
};

export default FullWindow;
