import { Checkbox, Label } from "flowbite-react";
import { nestedFilterProps, ProcessFilter } from "./types";
import { useEffect, useState } from "react";
import { useOptionValidator } from "../../../../store/processStore";

interface props extends nestedFilterProps {
  filters: ProcessFilter[];
  seeAll?: boolean;
}

const FilterArrayType = ({ setNew, filter, filters, seeAll }: props) => {
  const toggleOption = (value: any) => {
    let newValues = [];
    // @ts-expect-error
    if (filter.values?.find(v => v == value)) {
      // @ts-expect-error
      newValues = filter.values?.filter(v => v !== value);
    } else {
      newValues = filter.values?.concat(value);
    }

    setNew({
      ...filter,
      values: newValues,
    });
  };

  const validator = useOptionValidator(filter.where, filters, filter.depends);

  useEffect(() => {
    // @ts-expect-error
    const newValues = filter.values?.filter(v => validator(v));
    // not working why?
    setNew({
      ...filter,
      values: newValues,
    });
  }, [validator]);

  return (
    <div className="flex flex-row flex-wrap gap-2 p-2">
      {filter.options
        ?.filter(o => validator(o.value) || seeAll)
        ?.map((o, i) => (
          <div
            key={`${o.name}${i}`}
            onClick={() => {
              toggleOption(o.value);
            }}
            className="flex flex-row gap-1 items-center"
          >
            <Checkbox
              className="checked:bg-plum dark:checked:bg-plum"
              checked={
                // @ts-expect-error
                (filter.values && filter.values?.find(f => f == o.value)) ||
                false
              }
              readOnly
            />
            <Label value={o.name} />
          </div>
        ))}
    </div>
  );
};

export default FilterArrayType;
