import { useSearchStore } from "../../../store/searchStore";
import { gql, useLazyQuery, TypedDocumentNode } from "@apollo/client";
import { PROCESS_CORE_FIELDS } from "./fragments";
import { ProcessType } from "./types";
import { GET_PROCESS_TYPES } from "../settings/process/processTypes/processTypes";
import { searchResult } from "../../../store/searchStore";
import { fullNumber } from "../../../utils/fullNumber";

interface searchResults {
  search_process: ProcessType[];
}

interface processQueryDocument {
  process: ProcessType[];
}

export const QUERY_PROCESS_BY_NUMBER: TypedDocumentNode<processQueryDocument> = gql`
  ${PROCESS_CORE_FIELDS}
  query QUERY_PROCESS($where: process_bool_exp) {
    process(where: $where, limit: 5) {
      ...ProcessCoreFields
    }
  }
`;

export const SEARCH_PROCESS: TypedDocumentNode<searchResults> = gql`
  ${PROCESS_CORE_FIELDS}
  query SEARCH_PROCESS($str: String!) {
    search_process(args: { search: $str }) {
      ...ProcessCoreFields
    }
  }
`;

export const useProcessSearch = () => {
  const { setSearchResults, setLoading } = useSearchStore();

  const [getProcessTypes] = useLazyQuery(GET_PROCESS_TYPES);

  const [searchProcessByNum] = useLazyQuery(QUERY_PROCESS_BY_NUMBER, {
    fetchPolicy: "cache-and-network",
  });

  const [searchProcess] = useLazyQuery(SEARCH_PROCESS, {
    fetchPolicy: "cache-and-network",
  });

  const searchFn = async (str: string) => {
    const year = Number(str.slice(0, 2));
    const number = Number(str.replace(year.toString(), ""));
    const notNum = isNaN(year) || isNaN(number);

    if (str && str.trim() !== "") {
      const isFullNumberRegex = /[A-Za-z]+[0-9]+/i;
      const isNumberRegex = /[0-9]+/i;

      if (isFullNumberRegex.test(str)) {
        const numberPart = str.replace(/^\D+/g, "");
        const prefix = str.replace(numberPart, "");
        const year = Number(numberPart.slice(0, 2));
        const number = Number(numberPart.replace(year.toString(), ""));

        const fullYear = Number(`20${year}`);

        let typeId = 0;

        const processTypeData = await getProcessTypes();
        const processTypes = processTypeData.data?.processType;

        if (processTypes) {
          typeId =
            processTypes.find(
              pt => pt.prefix?.toLowerCase() == prefix?.toLowerCase()
            )?.id || 0;
        }

        setLoading(true);

        searchProcessByNum({
          variables: {
            where: {
              _and: [
                { year: { _eq: fullYear } },
                { number: { _eq: number } },
                { typeId: { _eq: typeId } },
              ],
            },
          },
          onCompleted(data) {
            console.log(data);
            const results: searchResult[] = data.process.map(p => ({
              header: fullNumber(
                p.processType.prefix,
                p.year,
                p.number,
                p.salesRepUser
              ),
              desc: p.name,
              link: "./process/detail/" + p.id,
            }));
            setSearchResults(results);
            setLoading(false);
          },
          onError() {
            setSearchResults([]);
            setLoading(false);
          },
        });
        return;
      } else if (isNumberRegex.test(str) && !notNum) {
        const year = Number(str.slice(0, 2));
        const number = Number(str.replace(year.toString(), ""));

        const fullYear = Number(`20${year}`);

        setLoading(true);
        searchProcessByNum({
          variables: {
            where: {
              _and: [{ year: { _eq: fullYear } }, { number: { _eq: number } }],
            },
          },
          onCompleted(data) {
            const results: searchResult[] = data.process.map(p => ({
              header: fullNumber(
                p.processType.prefix,
                p.year,
                p.number,
                p.salesRepUser
              ),
              desc: p.name,
              link: "./process/detail/" + p.id,
            }));
            setSearchResults(results);
            setLoading(false);
          },
          onError() {
            setSearchResults([]);
            setLoading(false);
          },
        });
        return;
      } else {
        setLoading(true);
        searchProcess({
          variables: { str },
          onCompleted(data) {
            const results: searchResult[] = data.search_process.map(p => ({
              header: fullNumber(
                p.processType.prefix,
                p.year,
                p.number,
                p.salesRepUser
              ),
              desc: p.name,
              link: "./process/detail/" + p.id,
            }));
            setSearchResults(results);
            setLoading(false);
          },
          onError() {
            setSearchResults([]);
            setLoading(false);
          },
        });
      }
    } else {
      setSearchResults([]);
    }
  };

  return searchFn;
};
