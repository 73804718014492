import { useScheme } from "../../store/schemeStore";
import { MoonIcon } from "@heroicons/react/20/solid";
import { SunIcon } from "@heroicons/react/24/solid";
import logo from "../../logo.png";

export default function Header() {
  const { scheme, setScheme } = useScheme();

  return (
    <header className="bg-white dark:bg-gradient-to-r from-dark to-slate-800 drop-shadow-sm h-16 flex justify-between items-center px-4">
      <div className="flex flex-row items-center">
        <img src={logo} className="h-8 mr-2" />
        <span className="font-semibold text-lg">ezi-crm</span>
      </div>
      <div
        className="cursor-pointer"
        onClick={() => {
          const newScheme = scheme === "dark" ? "light" : "dark";
          setScheme(newScheme);
        }}
      >
        {scheme === "dark" ? (
          <SunIcon className="w-8 hover:stroke-lime-100" />
        ) : (
          <MoonIcon className="w-8 hover:stroke-dark" />
        )}
      </div>
    </header>
  );
}
