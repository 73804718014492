import { PrinterIcon } from "@heroicons/react/24/solid";
import { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { OrderType, ProductSetOrderPreset } from "../../../types";
import { location } from "../../types";
import dayjs from "dayjs";
import { workorderCalc, column, optionColumn, filteredLocation } from "./types";
import { sort } from "fast-sort";
import ItemRow from "./itemBasedItemRow";

interface props {
  fullNum: string;
  customer?: string;
  pageMarginStyle: string;
  selectedOrderPreset?: ProductSetOrderPreset;
  dbOrder?: OrderType;
  fixedOptions?: optionColumn[];
  columns: column[];
  presetColumnName: string;
  allOptionColumns: optionColumn[];
  calcs: workorderCalc[];
  filteredLocations?: filteredLocation[];
  locations?: location[];
}

export default function ItemBasedWorkOrder({
  fullNum,
  customer,
  pageMarginStyle,
  selectedOrderPreset,
  dbOrder,
  fixedOptions,
  columns,
  presetColumnName,
  allOptionColumns,
  calcs,
  filteredLocations,
  locations,
}: props) {
  const workOrderRef = useRef(null);
  const printWorkOrder = useReactToPrint({
    content: () => workOrderRef.current,
    documentTitle: customer || fullNum || "no-name",
  });

  let itemNum = 0;

  return (
    <div className="w-[277mm]">
      <div className=" flex flex-row justify-end items-center">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-plum"
          onClick={printWorkOrder}
        >
          Print
          <PrinterIcon className="w-4" />
        </div>
      </div>
      <div ref={workOrderRef} className="flex flex-col gap-2 print:px-6">
        <style>{pageMarginStyle}</style>
        <h1 className="mb-5 text-center dark:text-black print:pt-4">
          Work Order
          {selectedOrderPreset ? " - " + selectedOrderPreset.name : ""}
        </h1>
        <div className="flex flex-row justify-between">
          <h3 className="dark:text-black">
            Due: {dbOrder?.due ? dayjs(dbOrder.due).format("DD/MM/YY") : "N/A"}
          </h3>
          <table className="border-[1px] border-gray-400">
            <tbody>
              <tr className="border-b-[1px] border-gray-400">
                <th className="border-r-[1px] border-gray-400 px-1">Job ID</th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  {fullNum}
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  Customer Ref
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.orderRef || "N/A"}
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  Dealer ID
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.createdBy}
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  Dealer Name
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.user.firstName} {dbOrder?.user.sirName}
                </th>
              </tr>
              <tr>
                <th className="border-r-[1px] border-gray-400 px-1">
                  Client Name
                </th>
                <th colSpan={3} className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.process?.name}
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">Address</th>
                <th colSpan={3} className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.process?.address}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="dark:text-black">Fixed Options</h3>
        <div className="border-l-[1px] border-t-[1px] border-black flex flex-row flex-wrap">
          {fixedOptions
            ?.filter(
              o =>
                o.value !== "" &&
                columns.find(
                  c => c.name == (o.displayName || o.name)
                  // && c.optionId == o.id
                )
            )
            .map(option => {
              const minWLength = sort([
                (option.displayValue || option.value).length,
                (option.displayName || option.name).length,
              ]).desc()[0];

              return (
                <div
                  key={option.id}
                  className="flex flex-col border-b-[1px] border-r-[1px] border-black flex-1"
                  style={{
                    minWidth: `${minWLength * 10}px`,
                  }}
                >
                  <div className="text-center p-1 border-b-[1px] border-black">
                    {option.displayName || option.name}
                  </div>
                  <div className="text-center p-1">
                    {option.displayValue || option.value}
                  </div>
                </div>
              );
            })}
        </div>

        <h3 className="mt-2 dark:text-black">Items</h3>
        <table className="border-[1px] border-black">
          <thead className="border-b-[1px] border-black">
            <tr>
              <th className="px-1 border-r-[1px] border-black">#</th>
              <th className="px-1 border-r-[1px] border-black">Loc</th>
              {columns.find(c => c.name == "OW") && (
                <th className="py-[4px] px-1 border-r-[1px] border-black">
                  OW
                </th>
              )}
              {columns.find(c => c.name == "OH") && (
                <th className="py-[4px] px-1 border-r-[1px] border-black">
                  OH
                </th>
              )}
              <th className="px-1 border-r-[1px] border-black" colSpan={2}>
                {presetColumnName}
              </th>
              {allOptionColumns
                ?.filter(
                  oc =>
                    columns.find(
                      col =>
                        col.name == (oc.displayName || oc.name) ||
                        (col.isGroup && col.name == oc.group)
                    ) && oc.name.toLowerCase() !== "note"
                )
                .map(oc => (
                  <th key={oc.id} className="px-1 border-r-[1px] border-black">
                    {oc.group || oc.displayName || oc.name}
                  </th>
                ))}
              {calcs &&
                sort(calcs)
                  .asc("priority")
                  ?.filter(c =>
                    columns.find(col => col.name == (c.displayName || c.name))
                  )
                  .map(c => {
                    return (
                      <th
                        className="px-1 border-r-[1px] border-black capitalize"
                        key={c.name}
                      >
                        {c.name}
                      </th>
                    );
                  })}
              {columns.find(c => c.name == "note") && (
                <th className="px-1 border-r-[1px] border-black">Note</th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y">
            {filteredLocations?.map((location, i) => {
              const locationName =
                location.name ||
                `# ${location.index !== undefined && location.index}`;
              return (
                <Fragment key={i}>
                  {filteredLocations.length > 1 && (
                    <tr className="text-plum font-semibold">
                      <td
                        className={`${
                          i !== 0 && "border-t-[1px] border-black"
                        } py-[4px] px-3 border-r-[1px] border-black`}
                        colSpan={100}
                      >
                        {locationName}
                      </td>
                    </tr>
                  )}
                  {location.openings.map(opening => {
                    const openingName =
                      opening.name ||
                      (opening.index !== undefined && opening.index);
                    return opening.items.map(item => {
                      itemNum++;
                      return (
                        <ItemRow
                          selectedOrderPreset={selectedOrderPreset}
                          itemNum={itemNum}
                          presetColumnName={presetColumnName}
                          locationName={locationName}
                          locations={locations}
                          openingName={openingName}
                          columns={columns}
                          allOptionColumns={allOptionColumns}
                          calcs={calcs}
                          item={item}
                          key={item.id}
                        />
                      );
                    });
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
