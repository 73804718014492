import { z } from "zod";

type HEX = `#${string}`;

export const sourcesSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
});

export interface NewSourceType {
  name: string;
}

export interface SourceType extends NewSourceType {
  id: number;
}

export interface SourceArrayType {
  sources: SourceType[];
}
