import { useQuery } from "@apollo/client";
import { GET_DEDUCTIONS_BY_IDS } from "../../../../gqls";
import { openingCalc, option } from "../../../types";
import { Fragment, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { sort } from "fast-sort";
import { ChevronUpDownIcon, MinusIcon } from "@heroicons/react/24/solid";
import LayoutAndFrame from "./layoutAndFrame";
import isNullish from "../../../../../../../utils/isNullish";

interface props {
  coord: string;
}

interface multiValue {
  name: string;
  checked: boolean;
  value: number;
}

export default function ShutterPreview({ coord }: props) {
  const { control } = useFormContext();

  const calcs: openingCalc[] = useWatch({
    name: `${coord}.calcs`,
    control,
  });

  const options: option[] = useWatch({
    name: `${coord}.options`,
    control,
  })?.filter(
    // @ts-ignore
    o => !o.noCalc
  );

  const show: boolean = useWatch({
    name: `${coord}.preview`,
    control,
  });

  const layout = options?.find(o => o.name == "Layout")?.value;
  const type = options?.find(o => o.name == "Type")?.value;

  const { data } = useQuery(GET_DEDUCTIONS_BY_IDS, {
    variables: { ids: [layout] },
  });

  const layoutDeduction = data?.deductions && data?.deductions[0];

  const openingScopes = calcs?.reduce<any>((prv, cur) => {
    return {
      ...prv,
      [cur.name]: cur.value,
    };
  }, {});

  if (!openingScopes) {
    return null;
  }

  const OH = openingScopes.OH;
  const OW = openingScopes.OW;

  if (!OH || !OW) {
    return null;
  }

  const panels = OH.panels as any[];

  const tiers = options?.find(o => o.name == "Tiers")?.value as number[];

  const PW = OW.PW as number[];

  if (!PW) {
    return null;
  }

  const averagePW =
    PW?.reduce<number>((cur, prev) => {
      return prev + cur;
    }, 0) / PW.length;

  const Frames = OW.Frames;
  // @ts-expect-error
  const FrameType = options?.find(o => o.name == "Frame")?.scope?.value;

  const Uchannel = options?.find(o => o.name == "UChannel");
  // @ts-expect-error
  const bladeSize = options?.find(o => o.name == "Blade")?.scope?.value;
  const Sills = options?.find(o => o.name == "Sill")?.scope as multiValue[];

  const TPosts: undefined | number[] = OW.tPostLocs;

  const Headboard = options?.find(o => o.name == "Headboard")?.value == "Yes";
  const Pelmet = OW.Pelmet;
  const TrackSizes = OW.TrackSizes;

  if (!show) {
    return null;
  }

  return (
    <>
      {/* Pelmet */}
      {Headboard &&
        Pelmet &&
        (type == "Sliding" || type == "Track Bi-fold") && (
          <PelmetDrawing
            TrackSizes={TrackSizes}
            pelmet={Pelmet}
            options={options}
          />
        )}
      <div className="flex flex-row w-full flex-wrap gap-14 select-text">
        {/* Panel Preview */}
        <div
          className={`flex ${
            type == "Sliding" ? "flex-row gap-1" : "flex-col"
          } justify-center py-10`}
        >
          {panels
            ?.sort((a, b) => {
              if (b.Panel?.position === "Front") {
                return 1;
              }
              if (b.Panel?.position === "Middle") {
                return 0;
              }
              if (b.Panel?.top > a.Panel?.top) {
                return 1;
              }

              return -1;
            })
            .map((panel, i) => (
              <Fragment key={i}>
                <Panel
                  panel={panel}
                  averagePW={averagePW}
                  coord={coord}
                  bladeSize={bladeSize}
                />
                {i < panels.length - 1 && type !== "Sliding" && (
                  <div
                    className="self-end h-3 border-black border-l-2 border-r-2 bg-white flex flex-col justify-center dark:border-gray-500"
                    style={{ width: `${averagePW / 3.3}px` }}
                  >
                    <div className="relative h-2 border-t-[1px] border-b-[1px] border-black dark:border-gray-500">
                      <div className="absolute -left-14 text-xs italic -top-1">
                        {tiers[i]}
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
        </div>
        {/* Layout and Frame */}
        <div className="flex flex-1 flex-row py-10 px-10 min-w-[500px] min-h-[400px]">
          <LayoutAndFrame
            FrameType={FrameType}
            Uchannel={Uchannel}
            Frames={Frames}
            TPosts={TPosts}
            layoutDeduction={layoutDeduction}
            openingScopes={openingScopes}
            tiers={tiers}
            Sills={Sills}
            OW={OW}
          />
        </div>
      </div>
    </>
  );
}

interface PanelProps {
  panel: any;
  averagePW: number;
  coord: string;
  bladeSize: number | string;
}

const Panel = ({ panel, averagePW, coord, bladeSize }: PanelProps) => {
  const { TR, BR } = panel;
  const PH = panel?.Panel?.PH;
  const MidRailLocs = panel?.MidRailLocs as number[];
  const Blades = panel?.Blades as number[];

  const defaultSections = [
    {
      start: 0,
      end: Blades ? Blades?.length - 1 : 0,
    },
  ];

  const [sections, setSections] = useState(defaultSections);

  useEffect(() => {
    if (!panel) {
      return;
    }
    if (!PH || !TR || !BR || !Blades) {
      return;
    }
    if (MidRailLocs.length > 0) {
      let sortedBlades = sort(Blades).asc();

      const newSections = [];

      for (let i = 0; i < MidRailLocs.length + 1; i++) {
        if (i == 0) {
          newSections.push({
            start: 0,
            end: sortedBlades.findIndex(
              b =>
                b ==
                sort(sortedBlades.filter(b => b < MidRailLocs[0])).desc()[0]
            ),
          });
          continue;
        }
        if (i == MidRailLocs.length) {
          newSections.push({
            start: sortedBlades.findIndex(
              b =>
                b ==
                sort(
                  sortedBlades.filter(
                    b => b > MidRailLocs[MidRailLocs.length - 1]
                  )
                ).asc()[0]
            ),
            end: sortedBlades.findIndex(b => b == sort(sortedBlades).desc()[0]),
          });
          continue;
        }
        newSections.push({
          start: sortedBlades.findIndex(
            b =>
              b ==
              sort(sortedBlades.filter(b => b > MidRailLocs[i - 1])).asc()[0]
          ),
          end: sortedBlades.findIndex(
            b =>
              b == sort(sortedBlades.filter(b => b < MidRailLocs[i])).desc()[0]
          ),
        });
      }

      setSections(newSections);
    } else {
      setSections(defaultSections);
    }
  }, [MidRailLocs]);

  const scaledBladeSize = (Number(bladeSize) * 0.77) / 3.3;

  if (!panel) {
    return null;
  }
  if (!PH || !TR || !BR || !Blades) {
    return (
      <div className="h-5 w-full  pl-10">
        <div className="w-full h-full border-2 border-black dark:border-gray-400 bg-white" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-1">
      {panel.Panel?.position && (
        <div className="flex justify-center">
          <div className="pl-10">{panel.Panel.position}</div>
        </div>
      )}

      <div className="flex flex-row gap-2 self-end">
        {/* Panel Height */}
        <div className="py-[1px] self-stretch flex flex-row">
          <div className="flex flex-col justify-center -mr-[10px] z-10">
            <div className="bg-gray-100 dark:bg-dark">{PH}</div>
          </div>
          <div className="w-2 border-t-[1px] border-b-[1px] border-l-[1px] h-full border-black dark:border-gray-400" />
        </div>
        {/* Panel */}
        <div
          className="border-2 relative flex flex-row border-black dark:border-gray-400"
          style={{ width: `${averagePW / 3.3}px`, height: `${PH / 3.3}px` }}
        >
          {/* Rails */}
          <div className="w-[10px] bg-white" />
          <div className="relative flex-1 border-r-2 border-l-2 dark:bg-gray-400 border-black dark:border-gray-400">
            {/* TopRail */}
            <div
              className="z-10 bg-white dark:text-black absolute top-0 border-b-2 flex justify-center items-center w-full border-black dark:border-gray-400"
              style={{ height: `${TR / 3.3}px` }}
            >
              {Number(TR).toFixed(1).replace(".0", "")}
            </div>
            {/* BottomRail */}
            <div
              className="z-10 bg-white dark:text-black absolute bottom-0 border-t-2 flex justify-center items-center w-full border-black dark:border-gray-400"
              style={{ height: `${BR / 3.3}px` }}
            >
              {Number(BR).toFixed(1).replace(".0", "")}
            </div>

            {/* MidRails */}
            {MidRailLocs?.map(m => (
              <div
                key={m}
                className="z-10 bg-red-500 absolute h-[22px] text-white border-t-2 border-b-2 border-black dark:border-gray-400 flex justify-center items-center w-full translate-y-1/2"
                style={{
                  bottom: `${m / 3.3}px`,
                }}
              >
                {Number(m).toFixed(1).replace(".0", "")}
              </div>
            ))}

            {/* Blades */}
            {Blades.map(blade => (
              <div
                key={blade}
                className="bg-white text-gray-300 absolute translate-y-3 text-xs border-t-[1px] flex justify-center items-center w-full border-black dark:border-gray-400"
                style={{
                  bottom: `${blade / 3.3}px`,
                  height: `${scaledBladeSize}px`,
                }}
              >
                {Number(blade).toFixed(1).replace(".0", "")}
              </div>
            ))}
            {/* TiltRods */}
            {sections.map((s, i) => (
              <Section
                key={i}
                Blades={Blades}
                section={s}
                coord={coord}
                panel={panel}
              />
            ))}
          </div>
          <div className="w-[10px] bg-white" />
        </div>
      </div>
    </div>
  );
};

interface sectionProps {
  section: {
    start: number;
    end: number;
  };
  Blades: number[];
  coord: string;
  panel: any;
}

const Section = ({ section, Blades, coord, panel }: sectionProps) => {
  const sortedBlades = sort(Blades).asc();

  const { start, end } = section;

  const qty = Number(end) - Number(start) + 1;

  const defaultTiltrods = [
    {
      position: 0,
      qty,
    },
  ];

  const tiltrodsCoord = `${coord}.panel.${panel.id}.section.${section.start}.tiltrods`;
  const touchedCoord = `${coord}.panel.${panel.id}.section.${section.start}.touched`;

  useEffect(() => {
    setValue(
      `${coord}.panel.${panel.id}.section.${section.start}.position`,
      start
    );
    setValue(`${coord}.panel.${panel.id}.section.${section.start}.end`, end);
    setValue(`${coord}.panel.${panel.id}.section.${section.start}.qty`, qty);
  }, [qty, start]);

  const { setValue, control } = useFormContext();
  const { replace } = useFieldArray({
    name: tiltrodsCoord,
    control,
  });
  const tiltRods: any[] = useWatch({
    name: tiltrodsCoord,
    defaultValue: defaultTiltrods,
    control,
  });

  const touched: boolean = useWatch({
    name: touchedCoord,
    control,
  });

  const mountRef = useRef(false);

  const split = () => {
    replace([
      {
        position: 0,
        qty: Math.ceil(qty / 2),
      },
      {
        position: 1,
        qty: Math.floor(qty / 2),
      },
    ]);
  };

  const reset = () => {
    if (qty > 13) {
      split();
    } else {
      replace(defaultTiltrods);
    }
  };

  useEffect(() => {
    if (touched && !mountRef.current) {
      mountRef.current = true;
      return;
    } else {
      if (!touched) {
        reset();
      }
      mountRef.current = true;
    }
  }, [qty]);

  return (
    <>
      {tiltRods?.map((t, i) => {
        let startPosition = 0;
        let endPosition = 0;

        if (t.position == 0) {
          startPosition = sortedBlades[start];
          endPosition = sortedBlades[start + t.qty - 1];
        } else {
          endPosition = sortedBlades[end];
          startPosition = sortedBlades[end - t.qty + 1];
        }

        let height = `${(endPosition - startPosition) / 3.3}px`;
        let bottom = `${startPosition / 3.3}px`;
        return (
          <div
            key={t.position}
            className="absolute w-2 border-y-[1px] border-r-[1px] -right-5 translate-x-full border-black dark:border-white"
            style={{
              bottom,
              height,
            }}
          >
            {t.position == 1 ? (
              <div className="absolute top-1/2 -right-1 translate-x-full -translate-y-1/2">
                <input
                  value={tiltRods.find(t => t.position == 1)?.qty || 0}
                  className="text-sm border-[1px] p-1 w-6 dark:text-black"
                  type="number"
                  onChange={e => {
                    const val = Number(e.target.value);
                    if (val < 0 || val > qty || val > 13) {
                      return;
                    }

                    setValue(touchedCoord, true);

                    setValue(
                      tiltrodsCoord,
                      tiltRods.map(t => {
                        if (t.position !== 1) {
                          return {
                            ...t,
                            qty: qty - val,
                          };
                        } else {
                          return { ...t, qty: val };
                        }
                      })
                    );
                  }}
                />
              </div>
            ) : (
              <div
                className={`absolute flex flex-row items-center group top-1/2 -right-1 translate-x-full -translate-y-1/2 cursor-pointer`}
                onClick={() => {
                  if (tiltRods.length == 1) {
                    setValue(touchedCoord, true);
                    split();
                  } else {
                    reset();
                  }
                }}
              >
                {t.qty.toString()}
                {tiltRods.length == 1 && (
                  <ChevronUpDownIcon className="hidden group-hover:block w-4" />
                )}
                {tiltRods.length > 1 && (
                  <MinusIcon className="hidden group-hover:block w-4" />
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

interface PelmetProps {
  pelmet: any;
  options: option[];
  TrackSizes: number[];
}
const PelmetDrawing = ({ pelmet, options }: PelmetProps) => {
  const {
    defaultDepth,
    back,
    frontExt,
    depth,
    depthExt,
    leftCorner,
    leftIn,
    rightIn,
    rightCorner,
  } = pelmet;

  const TrackQty = options?.find(o => o.name == "TrackQty")?.value;
  const Returns = options?.find(o => o.name == "Returns")?.value;
  const Facia = options?.find(o => o.name == "Facia")?.value;

  const Pass = options?.find(o => o.name == "Pass")?.value;

  const PassOverride = Number(
    options?.find(o => o.name == "PassOverride")?.value
  );

  const TrackGap =
    !isNaN(PassOverride) && !isNullish(PassOverride, true)
      ? PassOverride
      : Pass == "Open"
      ? 96
      : 36;

  const Tracks = Array(Number(TrackQty) || 1).fill(0);

  const Mount = options?.find(o => o.name == "Mount")?.value;
  const isSemiReveal = Number(Mount) === 34;

  return (
    <>
      <div className="w-full flex flex-row pt-10 gap-4">
        <div className="flex flex-row items-center gap-2 self-stretch">
          <div>{depthExt}</div>
          <div className="w-2 h-full border-y-[1px] border-l-[1px] border-black dark:border-white" />
        </div>
        {/* Drawing */}
        <div className="relative w-full flex flex-col h-20">
          {/* SemiReveal */}
          {isSemiReveal && (
            <div
              className={`${Returns.includes("L") && "pl-2"} ${
                Returns.includes("R") && "pr-2"
              } flex flex-row justify-center flex-1 -mb-[2px]`}
            >
              <div className="w-10 h-full border-b-2 border-black dark:border-white relative flex flex-row justify-center">
                <div className="absolute bottom-1">{leftCorner}</div>
              </div>
              <div className="flex-1 h-full border-x-2 border-t-2 border-black flex flex-row justify-between pt-1 px-2">
                <div>{leftIn}</div>
                <div>{back}</div>
                <div>{rightIn}</div>
              </div>
              <div className="w-10 h-full border-b-2 border-black dark:border-white relative flex flex-row justify-center">
                <div className="absolute bottom-1">{rightCorner}</div>
              </div>
            </div>
          )}
          {/* Default Drawing  */}
          <div
            className={`
            flex-[2] flex flex-row
            `}
          >
            {/* Left Return */}
            <div
              className={`${
                Returns?.includes("L") ? "block" : "hidden"
              } w-2 h-full border-l-2 border-y-2 border-black dark:border-white bg-white`}
            ></div>
            {/* Board, Facia */}
            <div
              className={`flex flex-col flex-1 h-full border-b-2 border-black dark:border-white border-x-2 ${
                isSemiReveal ? "border-t-0" : "border-t-2"
              }`}
            >
              {Facia == "Both" && (
                <div className="h-2 border-b-2 border-black dark:border-white relative bg-white">
                  {Returns?.includes("L") && (
                    <div className="absolute h-full w-2 left-[3px] top-0 -translate-x-full bg-white border-l-2 border-black dark:border-white skew-x-[45deg] z-10" />
                  )}
                  {Returns?.includes("R") && (
                    <div className="absolute h-full w-2 right-[3px] top-0 translate-x-full bg-white border-r-2 border-black dark:border-white skew-x-[-45deg] z-10" />
                  )}
                </div>
              )}
              <div className="flex-1" />
              {(Facia == "Yes" || Facia == "Both") && (
                <div className="h-2 border-t-2 border-black dark:border-white relative bg-white">
                  {Returns?.includes("L") && (
                    <div className="absolute h-full w-2 left-[3px] top-0 -translate-x-full bg-white border-l-2 border-black dark:border-white skew-x-[-45deg] z-10" />
                  )}
                  {Returns?.includes("R") && (
                    <div className="absolute h-full w-2 right-[3px] top-0 translate-x-full bg-white border-r-2 border-black dark:border-white skew-x-[45deg] z-10" />
                  )}
                </div>
              )}
            </div>
            {/* Right Return */}
            <div
              className={`${
                Returns?.includes("R") ? "block" : "hidden"
              } w-2 h-full border-r-2 border-y-2 border-black dark:border-white bg-white`}
            ></div>
          </div>
          <div className="absolute flex flex-row justify-center -bottom-4 w-full h-2 border-x-[1px] border-b-[1px] border-black dark:border-white">
            <div className="absolute -bottom-2 translate-y-full">
              {frontExt}
            </div>
          </div>
        </div>

        {/* Side View */}
        <div
          className={`relative flex flex-row gap-2 self-center justify-center items-end ${
            Number(Mount) == 13 ? "pt-0" : "pt-5"
          } ml-2`}
        >
          <div
            className={`${
              Number(Mount) == 13 || isSemiReveal
                ? "h-10 left-0 -translate-x-full"
                : "h-14 -left-1"
            } absolute w-2 border-2 border-black dark:border-white bg-white dark:bg-dark`}
          />
          <div className="h-10 flex flex-col items-start z-10">
            <div
              className="h-3 border-2 border-black dark:border-white bg-white dark:bg-dark"
              style={{
                width: depth / 2,
              }}
            />
            <div className="flex flex-row">
              {Tracks.map((t, i) => {
                const gap = i === 0 ? 20 : TrackGap - 26;
                const trackWidth = 26;

                return (
                  <div key={i} className="flex flex-row">
                    <div className="relative" style={{ width: gap / 2 }}></div>
                    <div
                      className="border-x-[3px] border-t-[3px] border-double border-grass h-3"
                      style={{ width: trackWidth / 2 }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
          {Facia == "Both" && (
            <div
              className={`${
                Number(Mount) == 13 || isSemiReveal
                  ? "h-10 right-0 translate-x-full"
                  : "h-14 -right-1"
              } absolute w-2 border-2 border-black dark:border-white bg-white dark:bg-dark`}
            />
          )}
        </div>
      </div>
      {defaultDepth > depth && (
        <div className="mt-2 text-red-500">
          WARNING : inner depth is smaller than minimum : {defaultDepth}
        </div>
      )}
    </>
  );
};
