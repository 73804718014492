import { ListGroup } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import { option } from "../../../types";
import { useEffect, useRef, useState } from "react";
import { MaterialsType } from "../../../../../materials/types";
import { useOnClickOutside } from "usehooks-ts";
import { MagnifyingGlassIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { optionValues } from "../../../../types";
import { useOrderStore } from "../../../../../../../store/orderStore";
import isNullish from "../../../../../../../utils/isNullish";
import { sort } from "fast-sort";
import AddCustomMaterial from "./addCustomMaterial";
import matDisplayName from "./matDisplayName";

interface props {
  optionCoord: string;
  disabled?: boolean;
  availableOptions: (string | number)[] | undefined;
  formOptions: option[];
  optionValue: any;
  optionValues: option["values"];
  optionDefault: option["default"];
  optionName: string;
}

export default function MaterialSelect({
  optionCoord,
  disabled,
  availableOptions,
  formOptions,
  optionValue,
  optionValues,
  optionDefault,
  optionName,
}: props) {
  const { getValues, setValue, control } = useFormContext();

  const optionCode = useWatch({
    name: `${optionCoord}.optionCode`,
    exact: true,
    control,
  });

  const presetID = useWatch({
    name: `${optionCoord}.presetID`,
    exact: true,
    control,
  });

  const { materials: cachedMaterials } = useOrderStore();
  const materials = cachedMaterials.filter(mat => {
    if (optionCode) {
      return mat.optionCode == optionCode;
    } else {
      return optionValues?.includes(mat.id);
    }
  });

  const [loading, setLoading] = useState(true);

  const canAddCustom = useWatch({
    name: `${optionCoord}.allowCustomInput`,
    exact: true,
    control,
  });

  useEffect(() => {
    setLoading(true);

    const materialsIdOnly = materials.map(mat => mat.id);

    if (
      materials[0] &&
      JSON.stringify(materialsIdOnly) !== JSON.stringify(optionValues)
    ) {
      setValue(`${optionCoord}.values`, materialsIdOnly);
    }
    setLoading(false);
  }, [materials]);

  const nameScope: undefined | string[] = getValues(`${optionCoord}.nameScope`);

  useEffect(() => {
    if (!optionValue) {
      return;
    }

    const _mat = materials.find(
      d => d.id.toString() === optionValue.toString()
    );

    const mat = {
      id: _mat?.id,
      name: _mat?.name,
      widthDeduction: _mat?.widthDeduction,
      heightDeduction: _mat?.heightDeduction,
      size: _mat?.size,
      brand: _mat?.brand,
      color: _mat?.color,
      params: _mat?.params,
    };

    const currentPopulatedValue: MaterialsType | undefined = getValues(
      `${optionCoord}.populatedValue`
    );

    if (JSON.stringify(mat) !== JSON.stringify(currentPopulatedValue)) {
      setValue(`${optionCoord}.populatedValue`, mat);
    }

    const dpValue = matDisplayName(mat, nameScope);

    const currentDisplayValue = getValues(`${optionCoord}.displayValue`);

    if (currentDisplayValue !== dpValue) {
      setValue(`${optionCoord}.displayValue`, dpValue);
    }
  }, [JSON.stringify(optionValue), JSON.stringify(materials)]);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(!open);
  };
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const selectedMat = materials.find(
    d => d.id.toString() == optionValue.toString()
  );

  const finalOptions = availableOptions
    ? availableOptions.filter(o => {
        const material = materials.find(p => p.id.toString() == o.toString());
        if (!material) {
          return false;
        }

        const conditions = material?.optionCondition;

        const reducedConditions = conditions?.reduce<optionValues[]>(
          (acc, cur) => {
            let optionToCheck = cur;

            if (optionToCheck.group) {
              const matchingFormOption = formOptions.find(
                fo => fo.group == optionToCheck.group && fo.id == presetID
              );
              if (!matchingFormOption) return acc;
              optionToCheck = {
                ...optionToCheck,
                option: matchingFormOption?.id,
              };
            }

            if (acc.find(option => option.option == optionToCheck.option)) {
              return acc.map(ov => {
                if (ov.option == optionToCheck.option) {
                  return {
                    ...ov,
                    values: ov.values.concat(optionToCheck.value),
                  };
                } else {
                  return ov;
                }
              });
            } else {
              return acc.concat({
                option: optionToCheck.option,
                values: [optionToCheck.value],
              });
            }
          },
          []
        );

        if (reducedConditions) {
          return reducedConditions.every(c => {
            const myOption = formOptions.find(o => o.id == Number(c.option));
            const values = c.values.map(v => v.toString());
            return values.includes(myOption?.value.toString());
          });
        }

        return true;
      })
    : [];

  const isMounted = useRef(false);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!isMounted.current) {
      isMounted.current = true;
      if (!isNullish(optionValue, true)) {
        return;
      }
    }

    if (finalOptions.length == 0) {
      if (optionValue !== "") {
        setValue(`${optionCoord}.value`, "");
      }
      return;
    }
    if (!finalOptions.find(o => o.toString() == optionValue.toString())) {
      const newVal: any =
        finalOptions.find(o => o.toString() == optionDefault?.toString()) ||
        finalOptions[0];

      let newValId = newVal
        ? materials.find(d => d.id.toString() == newVal.toString())?.id
        : "";

      const disableAutoSelect = getValues(`${optionCoord}.disableAutoSelect`);

      if (disableAutoSelect) {
        newValId = "";
      }

      const populatedVal = materials.find(mat => mat.id == Number(newValId));
      if (optionValue !== newValId?.toString()) {
        setValue(`${optionCoord}.value`, newValId?.toString());

        const currentPopulatedValue = getValues(
          `${optionCoord}.populatedValue`
        );

        if (
          !currentPopulatedValue?.id ||
          currentPopulatedValue?.id !== populatedVal?.id
        ) {
          setValue(`${optionCoord}.populatedValue`, populatedVal);
        }
      }
    }
  }, [JSON.stringify(finalOptions), loading, isMounted.current]);

  const [search, setSearch] = useState("");

  const populatedOptions = finalOptions
    ? (finalOptions
        .map(o => materials.find(p => p.id.toString() == o.toString()))
        .filter(o => !isNullish(o)) as MaterialsType[])
    : ([] as MaterialsType[]);

  const sortedOptions = sort(populatedOptions)
    .asc(["brand", "name", "color", "size"])
    .filter(
      mat =>
        search.trim() == "" ||
        `${mat.name} ${mat.color}`.toLowerCase().includes(search.toLowerCase())
    );

  const [adding, setAdding] = useState(false);

  const onCustomMatAdd = (mat: MaterialsType) => {
    setAdding(false);
    setSearch("");
    setValue(`${optionCoord}.value`, mat.id.toString());
  };

  const filteredOption = sortedOptions.filter(mat => {
    if (mat.pending || !mat.selectable) {
      return false;
    }
    return true;
  });

  return (
    <div className="relative" ref={ref}>
      <ListGroup
        className={`${disabled ? "brightness-90 dark:brightness-75" : ""}`}
        onClick={toggleOpen}
      >
        <ListGroup.Item>
          <div
            className={`text-xs ${
              optionValue == "" && "text-red-500 animate-pulse"
            }`}
          >
            {optionValue == ""
              ? "Required"
              : matDisplayName(selectedMat, nameScope)}
          </div>
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 pb-10">
          <div className="flex flex-col bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
            <div className="relative flex flex-row gap-1 items-center rounded-t-md p-2 backdrop-brightness-90 dark:backdrop-brightness-75">
              {search.trim() == "" && (
                <MagnifyingGlassIcon className="w-4 absolute left-4 text-gray-700 dark:text-gray-500" />
              )}
              <input
                value={search}
                onChange={e => {
                  setSearch(e.target.value);
                }}
                autoFocus
                className="bg-white dark:bg-gray-100 w-full rounded-md px-2 py-1 dark:text-dark text-xs"
              />
            </div>

            <div className="max-h-64 overflow-x-hidden overflow-y-auto flex flex-col gap-1 p-1 md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700">
              {filteredOption.map(mat => {
                return (
                  <div
                    onClick={() => {
                      setValue(`${optionCoord}.value`, mat.id);
                      setOpen(false);
                    }}
                    key={mat.id}
                    className="group/preset relative"
                  >
                    <div className="rounded-md px-4 py-2 cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                      {matDisplayName(mat, nameScope)}
                    </div>
                  </div>
                );
              })}
              {filteredOption.length == 0 && optionCode && canAddCustom && (
                <div
                  onClick={() => {
                    setAdding(true);
                  }}
                  className="group/preset relative"
                >
                  <div className="flex flex-row gap-1 items-center rounded-md px-2 py-2 cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                    <PlusCircleIcon className="w-5 text-grass" /> Add New
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {optionCode && (
        <AddCustomMaterial
          adding={adding}
          setAdding={setAdding}
          optionCode={optionCode}
          materials={populatedOptions}
          search={search}
          optionName={optionName}
          cb={onCustomMatAdd}
        />
      )}
    </div>
  );
}
