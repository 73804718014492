import {
  AdjustmentsHorizontalIcon,
  ArrowDownTrayIcon,
  ClipboardIcon,
  QueueListIcon,
  TableCellsIcon,
} from "@heroicons/react/24/solid";
import { Button, Spinner, ListGroup } from "flowbite-react";
import Heading from "../../../comps/heading";
import { useNavigate } from "react-router-dom";
import { gql, useQuery, TypedDocumentNode, useLazyQuery } from "@apollo/client";
import { ProcessStreamType, ProcessType } from "./types";
import { PROCESS_CORE_FIELDS } from "./fragments";
import { processQueryParams } from "./filter/types";
import { useEffect, useState } from "react";
import { exportData } from "../../../utils/arrayObjMethods";
import { addAlert } from "../../../store/alertStore";
import ProcessBoard from "./processBoard/processBoard";
import ProcessList from "./processList/processList";
import ProcessTable from "./processTable/processTable";
import { processViewType, useProcessStore } from "../../../store/processStore";
import Filters from "./filter/filters";
import { cloneDeep } from "apollo-utilities";
import { GET_CONTACTS_BY_IDS } from "./processDetail/processActions";
import { useBoolean } from "usehooks-ts";

interface processQueryDocument {
  process: ProcessType[];
  process_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

export const QUERY_PROCESS: TypedDocumentNode<processQueryDocument> = gql`
  ${PROCESS_CORE_FIELDS}
  query QUERY_PROCESS(
    $where: process_bool_exp
    $limit: Int
    $order_by: [process_order_by!]
    $offset: Int
  ) {
    process(
      where: $where
      limit: $limit
      order_by: $order_by
      offset: $offset
    ) {
      ...ProcessCoreFields
    }
    process_aggregate(where: $where, order_by: $order_by) {
      aggregate {
        count
      }
    }
  }
`;

export const STREAM_PROCESS: TypedDocumentNode<ProcessStreamType> = gql`
  ${PROCESS_CORE_FIELDS}
  subscription STREAM_PROCESS(
    $where: process_bool_exp
    $cursor: [process_stream_cursor_input]!
  ) {
    process_stream(where: $where, cursor: $cursor, batch_size: 10) {
      ...ProcessCoreFields
    }
  }
`;

interface props {
  filters: processQueryParams;
}

export default function ProcessLoader({ filters }: props) {
  const [limit] = useState(1000);
  const [totalLength, setTotalLength] = useState(0);
  const [filtering, setFiltering] = useState(false);
  const toggleFiltering = () => {
    setFiltering(!filtering);
  };
  const { view, changeView, setView, cursor, setCursor } = useProcessStore();

  useEffect(() => {
    const savedView = localStorage.getItem("processView");
    if (savedView) {
      const views = ["board", "list", "table"];
      if (views.includes(savedView)) {
        setView(localStorage.getItem("processView") as processViewType);
      }
    }
  }, []);

  const { data, loading, error, fetchMore, subscribeToMore } = useQuery(
    QUERY_PROCESS,
    {
      variables: { ...filters, limit },
      onCompleted(data) {
        setTotalLength(data.process_aggregate?.aggregate?.count || 0);
      },
      onError(error) {
        addAlert({ message: "Cannot load process items", type: "failure" });
        console.log(error);
      },
    }
  );

  const ids = data?.process.map(p => p.id);

  if (error) {
    console.log(error);
  }

  const loadMore = () => {
    const currentLength = data?.process.length || 0;
    fetchMore({
      variables: {
        ...filters,
        offset: currentLength,
        limit,
      },
    });
  };

  useEffect(() => {
    const subFilter = {
      _or: [
        { _and: filters.where?._and.filter(p => !Object.hasOwn(p, "deleted")) },
        { id: { _in: ids || [] } },
      ],
    };

    const unsubscribe = subscribeToMore({
      document: STREAM_PROCESS,
      variables: {
        where: subFilter,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.process);
        const newFeedItem = subscriptionData.data.process_stream;
        const existing = previousData.filter(
          p => !newFeedItem.find(i => i.id == p.id)
        );
        const filterDeleted = [...newFeedItem, ...existing].filter(
          p => !p.deleted
        );
        return Object.assign({}, previous, {
          process: filterDeleted,
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [filters.where._and, ids]);

  const navigate = useNavigate();
  const goToAdd = () => {
    navigate("./add");
  };

  const exportToExcel = () => {
    const _data = data?.process.map(d => ({
      id: d.id,
      year: d.year,
      type: d.processType.name,
      status: d.processStatus.name,
      number: d.number,
      address: d.address,
      postCode: d.postCode,
      returning: d.returning,
      value: d.value,
      description: d.description,
      // TODO: Extend Data
    }));
    exportData(_data);
    setExporting(false);
  };

  const [getContactsByIds] = useLazyQuery(GET_CONTACTS_BY_IDS, {
    fetchPolicy: "no-cache",
  });

  const exportContactsToExcel = async () => {
    const contactIds = data?.process
      .map(p => p.contacts)
      .flat()
      .filter(c => c !== null && c !== undefined);
    const { data: contactsData } = await getContactsByIds({
      variables: {
        ids: contactIds,
      },
    });

    const _data = contactsData?.contacts.map(d => ({
      id: d.id,
      name: d.name,
      type: d.contactType.name,
      number: d.number,
      mail: d.mail,
      role: d.role,
      org: d.organisation,
      address: d.address,
      description: d.description,
      // TODO: Extend Data
    }));
    exportData(_data, "Contacts");
    setExporting(false);
  };

  const { value: exporting, setValue: setExporting } = useBoolean();

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6 mb-1">
        <div className="flex flew-row justify-between h-8">
          <Heading />
          <Button
            size="sm"
            outline
            gradientDuoTone="purpleToBlue"
            onClick={goToAdd}
          >
            ADD
          </Button>
        </div>
        <div className="flex flex-row text-grass mb-10">
          process, leads, and all
        </div>
        {/* Actions */}
        <div className="flex flex-row justify-between gap-2 flex-wrap items-center">
          <div className="flex flex-row gap-2">
            <div
              className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
              onClick={changeView}
            >
              {view == "board" && <ClipboardIcon className="w-4" />}
              {view == "list" && <QueueListIcon className="w-4" />}
              {view == "table" && <TableCellsIcon className="w-4" />}
              View
            </div>
            <div
              onClick={toggleFiltering}
              className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
            >
              <AdjustmentsHorizontalIcon className="w-4" />
              Filters
            </div>
            <div
              onClick={() => {
                setExporting(!exporting);
              }}
              className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center relative"
            >
              <ArrowDownTrayIcon className="w-4" />
              {exporting && (
                <ListGroup className="absolute z-10 top-full mt-1">
                  <ListGroup.Item onClick={exportToExcel}>
                    Process
                  </ListGroup.Item>
                  <ListGroup.Item onClick={exportContactsToExcel}>
                    Contacts
                  </ListGroup.Item>
                </ListGroup>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-2 flex-1 h-8">
            {loading && <Spinner light color="purple" />}
            <div className="text-sm">
              {data?.process.length}
              <span className="dark:text-grass text-plum"> of </span>
              {totalLength < (data?.process.length || 0)
                ? data?.process.length
                : totalLength}
            </div>
            {data && data?.process.length < totalLength && (
              <Button size="xs" onClick={loadMore} color="purple" outline>
                more
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* Filter */}
      <Filters filtering={filtering} />
      {/* Content Part */}
      {view == "board" && <ProcessBoard process={data?.process || []} />}
      {view == "list" && <ProcessList process={data?.process || []} />}
      {view == "table" && <ProcessTable process={data?.process || []} />}
    </main>
  );
}
