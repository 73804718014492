import { pricingTable } from "../../../../settings/quote/types";

export interface refinedPrice {
  width: number;
  height: number;
  price: number;
}
export default function refinePricingTable(table: pricingTable) {
  const { columns, rows } = table;

  const prices = rows.reduce<refinedPrice[]>((prev, cur) => {
    const height = cur.height;

    const prices = cur.prices.map(price => {
      return {
        price: price.value,
        width: columns.find(col => col.id == price.id)?.width || 0,
        height,
      };
    });

    return prev.concat(prices);
  }, []);

  return prices;
}
