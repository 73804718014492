import { useState } from "react";
import { useScheme } from "../../../../store/schemeStore";
import {
  Badge,
  Button,
  Label,
  Select,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import DynamicHeroIcon from "../../../../comps/hIcon";
import { useAuthStore } from "../../../../store/authStore";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS_CONDITIONAL } from "../../settings/users/gql";
import {
  ADD_ASSIGNMENT,
  GET_ASSIGNMENT_STATUS,
  GET_ASSIGNMENT_TYPE,
} from "../../assignment/gqls";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewAssignmentType, assignmentSchema } from "../../assignment/types";
import { ASSIGNMENT_ALL_FIELDS } from "../../assignment/fragments";
import { ProcessType } from "../types";
import { fullNumber } from "../../../../utils/fullNumber";
import { push } from "../../../../utils/pushAPI";
import { GET_ORDERS_CONDITIONAL } from "../../workorder/gqls";
import useGetOrderProducts from "../../workorder/useGetOrderProducts";
import { GET_PRODUCTS } from "../../settings/products/products";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { ProductType } from "../../settings/products/types";
import { OrderType } from "../../workorder/types";
import useGetProductQty from "../../workorder/useGetProductQty";

interface props {
  process: ProcessType;
}

export default function AddAssignment({ process }: props) {
  const { scheme } = useScheme();

  const { user } = useAuthStore();

  const subTeams = user?.team?.subTeams || [];

  const { data: data_users } = useQuery(GET_USERS_CONDITIONAL, {
    variables: {
      where: {
        _and: [
          { authID: { _eq: 18 } },
          { teamID: { _in: [...subTeams, user?.team?.id] } },
          { active: { _eq: true } },
        ],
      },
    },
  });

  const { data: data_type } = useQuery(GET_ASSIGNMENT_TYPE);
  const type = data_type?.assignmentType;

  const { data: data_status } = useQuery(GET_ASSIGNMENT_STATUS);
  const status = data_status?.assignmentStatus;

  const installers = data_users?.users;

  const [insert, { loading: inserting }] = useMutation(ADD_ASSIGNMENT);

  const defaultValues: NewAssignmentType = {
    processId: process.id,
    userId: undefined,
    statusId: undefined,
    typeId: undefined,
    desc: "",
    due: null,
    value: undefined,
    orders: [],
    products: [],
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues,
    resolver: zodResolver(assignmentSchema),
  });

  const onSubmit = handleSubmit(data => {
    insert({
      variables: {
        object: { ...data, createdById: user?.id },
      },
      update(cache, { data: { insert_assignment_one: newAssignment } }) {
        cache.modify({
          fields: {
            assignment(existingAssignment = []) {
              const newAssignmentRef = cache.writeFragment({
                data: newAssignment,
                fragment: ASSIGNMENT_ALL_FIELDS,
                fragmentName: "AssignmentAllFields",
              });
              return [...existingAssignment, newAssignmentRef];
            },
          },
        });
      },
      onCompleted() {
        reset();

        const title = `New Assignment - ${
          process
            ? fullNumber(
                process.processType.prefix,
                process.year,
                process.number,
                process.salesRepUser
              )
            : ""
        }`;

        const body = `Assignment has been added`;

        push({
          title,
          body,
          ids: [data.userId as number],
        });

        setAdding(false);
      },
    });
  });

  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
    reset();
  };

  const { data: data_orders } = useQuery(GET_ORDERS_CONDITIONAL, {
    variables: {
      where: {
        processId: { _eq: process.id },
      },
    },
  });

  const orders = data_orders?.workorder || [];

  const { append: append_orders, remove: remove_orders } = useFieldArray({
    // @ts-expect-error
    name: "orders",
    control,
  });

  const added_orders: number[] = useWatch({
    name: "orders",
    control,
  });

  const { data: data_products } = useQuery(GET_PRODUCTS);

  const products = data_products?.products;

  const { fields, append, remove } = useFieldArray({
    name: "products",
    control,
  });

  const getProductQty = useGetProductQty();

  return (
    <>
      <Button
        onClick={toggleAdding}
        size="sm"
        color={scheme == "dark" ? "dark" : "purple"}
      >
        <DynamicHeroIcon
          class="w-5"
          icon={adding ? "MinusCircleIcon" : "PlusCircleIcon"}
        />
      </Button>

      {adding && (
        <form
          className="w-full gap-2 flex flex-col items-start flex-wrap shadow-md border-[1px] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 p-2 rounded-md"
          onSubmit={onSubmit}
        >
          <div className="flex flex-row justify-between items-center w-full">
            <Label value="Type" />
            <Select
              style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
              sizing="sm"
              className="w-[50%]"
              {...register("typeId")}
            >
              <option value={undefined}>Select Type</option>
              {type?.map(t => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <Label value="Status" />
            <Select
              style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
              sizing="sm"
              className="w-[50%]"
              {...register("statusId")}
            >
              <option value={undefined}>Select Status</option>
              {status?.map(stat => (
                <option key={stat.id} value={stat.id}>
                  {stat.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <Label value="Installer" />
            <Select
              style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
              sizing="sm"
              className="w-[50%]"
              {...register("userId")}
            >
              <option value={undefined}>Select Installer</option>
              {installers?.map(installer => (
                <option key={installer.id} value={installer.id}>
                  {installer.firstName} {installer.sirName}
                </option>
              ))}
            </Select>
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <Label value="Value" />
            <TextInput
              className="w-[50%]"
              {...register("value")}
              style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
              type="number"
              sizing="sm"
              placeholder="assignment value $"
            />
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <Label value="Due" />
            <TextInput
              className="w-[50%]"
              {...register("due")}
              style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
              type="date"
              sizing="sm"
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between items-center w-full">
              <Label value="Include Product" />
              {products && (
                <AddProduct
                  products={products}
                  scheme={scheme}
                  append={append}
                  added={fields}
                />
              )}
            </div>
            {fields.length > 0 && (
              <div
                className="flex flex-row flex-wrap gap-2 p-2 rounded-md"
                style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
              >
                {fields.map((product, i) => {
                  const Product = products?.find(
                    p => p.id == product.productId
                  );
                  return (
                    <Badge
                      key={product.id}
                      color="purple"
                      className="cursor-pointer relative group"
                      onClick={() => {
                        remove(i);
                      }}
                    >
                      {Product?.name}:{product.qty}
                      <XCircleIcon className="w-4 text-red-500 hidden absolute group-hover:block -top-2 -right-2" />
                    </Badge>
                  );
                })}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between items-center w-full">
              <Label value="Include ProMeasure" />
              {orders && (
                <AddOrder
                  added={added_orders}
                  orders={orders}
                  scheme={scheme}
                  // @ts-expect-error
                  append={append_orders}
                />
              )}
            </div>
            {added_orders.length > 0 && (
              <div
                className="flex flex-row flex-wrap gap-2 p-2 rounded-md"
                style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
              >
                {added_orders.map((order, i) => {
                  const Order = orders.find(o => o.id == order);
                  if (!Order) {
                    return;
                  }

                  const products = getProductQty(Order.order)
                    .map(p => `${p.productSet.name} (${p.qty})`)
                    .join(" ");

                  return (
                    <Badge
                      key={order}
                      color="purple"
                      className="cursor-pointer relative group capitalize"
                      onClick={() => {
                        remove_orders(i);
                      }}
                    >
                      {Order.type.name} -{" "}
                      {Order?.orderRef ? Order.orderRef : `#${Order.id}`} -{" "}
                      {products}
                      <XCircleIcon className="w-4 text-red-500 hidden absolute group-hover:block -top-2 -right-2" />
                    </Badge>
                  );
                })}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <Label value="Desc" />
            <Textarea
              {...register("desc")}
              className="text-sm"
              style={{ backgroundColor: scheme == "dark" ? "#6b7280" : "" }}
            />
          </div>

          <Button
            type="submit"
            gradientDuoTone="purpleToBlue"
            size="xs"
            outline
            className="self-end"
          >
            Add
            {inserting && (
              <Spinner light size="xs" color="purple" className="ml-1" />
            )}
          </Button>
        </form>
      )}
    </>
  );
}

const AddProduct = ({
  products,
  scheme,
  append,
  added,
}: {
  scheme?: string;
  products: ProductType[];
  append: UseFieldArrayAppend<NewAssignmentType, "products">;
  added: FieldArrayWithId<NewAssignmentType, "products", "id">[];
}) => {
  const defaultValues: {
    productId?: number;
    qty?: number;
  } = {
    productId: undefined,
    qty: undefined,
  };

  const { register, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const add = handleSubmit(data => {
    const { productId, qty } = data;

    if (!productId || !qty) {
      return;
    }

    append({
      productId: Number(productId),
      qty: Number(qty),
    });

    setValue("productId", undefined);
    setValue("qty", undefined);
  });

  return (
    <div className="w-[50%] flex flex-row items-center gap-1">
      <Select
        style={{
          backgroundColor: scheme == "dark" ? "#6b7280" : "",
          paddingRight: 0,
        }}
        sizing="sm"
        className="flex-[2]"
        {...register("productId")}
      >
        <option>Select Product</option>
        {products
          ?.filter(p => !added.find(a => a.productId == p.id))
          .map(p => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
      </Select>
      <div className="flex flex-1 flex-row gap-1 items-center">
        <TextInput
          className="flex-[2]"
          style={{
            backgroundColor: scheme == "dark" ? "#6b7280" : "",
          }}
          type="number"
          sizing="sm"
          placeholder="qty"
          {...register("qty")}
        />
        <PlusCircleIcon
          onClick={add}
          className="w-5 text-grass cursor-pointer"
        />
      </div>
    </div>
  );
};

const AddOrder = ({
  added,
  orders,
  scheme,
  append,
}: {
  added: number[];
  scheme?: string;
  orders: OrderType[];
  // @ts-expect-error
  append: UseFieldArrayAppend<NewAssignmentType, "orders">;
}) => {
  const defaultValues: undefined | number = undefined;

  const { register, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const add = handleSubmit(data => {
    if (!data) {
      return;
    }
    append(Number(data.productId));

    setValue("", undefined);
  });

  // const getOrderProducts = useGetOrderProducts();

  const getProductQty = useGetProductQty();

  const populatedOrders =
    orders.map(order => {
      return {
        ...order,
        products: getProductQty(order.order),
      };
    }) || [];

  return (
    <div className="w-[50%] flex flex-row items-center gap-1">
      <Select
        style={{
          backgroundColor: scheme == "dark" ? "#6b7280" : "",
          paddingRight: 0,
        }}
        sizing="sm"
        className="flex-1"
        {...register("productId")}
      >
        <option>Select Order</option>
        {populatedOrders
          ?.filter(o => !added.includes(o.id))
          .map(order => (
            <option key={order.id} value={order.id}>
              {order.type.name}
              {" -"}
              {order.orderRef}{" "}
              {order.products
                .map(p => `${p.productSet.name} (${p.qty})`)
                .join(" ")}
            </option>
          ))}
      </Select>

      <PlusCircleIcon onClick={add} className="w-5 text-grass cursor-pointer" />
    </div>
  );
};
