import { useEffect, useState } from "react";
import { useAuthStore } from "../store/authStore";
import ProtectedRoot from "./protected/protectedRoot";
import PublicRoot from "./public/publicRoot";
import { useScheme } from "../store/schemeStore";
import Alert from "./Alert";
import {
  useNotificationStore,
  useAddNotification,
  StampedMessagePayload,
} from "../store/notificationStore";
import { getFCMToken, getMyMessaging } from "../firebase";
import { MessagePayload, onMessage } from "firebase/messaging";
import uuid from "react-uuid";
import Loading from "../comps/loading";
import ReloadPrompt from "./reloadPrompt";
import SocketActions from "./socketActions";

function Root() {
  const [loading, setLoading] = useState(true);
  const [tokenLoaded, setTokenLoaded] = useState(false);

  const { scheme, setScheme } = useScheme();

  const { setToken } = useNotificationStore();
  const addNotification = useAddNotification();

  const detectNotification = () => {
    try {
      if (!Notification) {
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleBgMsg = (res: MessageEvent) => {
    if (res.data?.messageType == "push-received") {
      return;
    }

    const converted: StampedMessagePayload = {
      collapseKey: "",
      from: "",
      messageId: uuid(),
      data: res.data,
      notification: res.data,
      timestamp: new Date(),
    };
    addNotification(converted);
  };

  const handleFgMsg = (res: MessagePayload) => {
    const converted = { ...res, timestamp: new Date() };
    addNotification(converted);
  };

  const initNotification = async () => {
    if (tokenLoaded) {
      return;
    }

    if (!detectNotification()) {
      setTokenLoaded(true);
      return;
    }

    if (Notification?.permission == "granted") {
      const token = await getFCMToken();
      setToken(token);
      setTokenLoaded(true);

      // init foregroudn listner
      const messaging = getMyMessaging();
      if (messaging) {
        onMessage(messaging, handleFgMsg);
      }

      // init background listner
      if (navigator?.serviceWorker) {
        navigator?.serviceWorker?.addEventListener("message", handleBgMsg);
      }
    } else {
      if ("Notification" in window) {
        try {
          Notification?.requestPermission();
        } catch (error) {
          console.log(error);
        }
        setTokenLoaded(true);
      }
    }
  };

  const user = useAuthStore(state => state.user);

  const cookieEnabled = navigator?.cookieEnabled;

  if (!cookieEnabled) {
    alert("Please allow the use of cookie to proceed");
  }

  const load = async () => {
    setLoading(true);

    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setScheme("dark");
    } else {
      setScheme("");
    }

    await initNotification();

    setLoading(false);
  };

  useEffect(() => {
    load();
    return () => {
      if (navigator?.serviceWorker) {
        navigator?.serviceWorker?.removeEventListener("message", handleBgMsg);
      }
    };
  }, [setScheme]);

  return (
    <div className={`Root flex h-screen flex-col ${scheme}`}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="bg-white flex-1 dark:bg-dark dark:text-white">
            {user ? <ProtectedRoot /> : <PublicRoot />}
          </div>
          <Alert />
          <ReloadPrompt />
          <SocketActions />
        </>
      )}
    </div>
  );
}

export default Root;
