import { Checkbox, Label } from "flowbite-react";
import { Dispatch, SetStateAction } from "react";
import FilterRangeType from "../records/rangeFilter";
import { RecordFilter } from "../records/types";
import { sort } from "fast-sort";

interface filterProps {
  filter: RecordFilter;
  newFilters: RecordFilter[];
  setNewFilters: Dispatch<SetStateAction<RecordFilter[]>>;
  setTouched: (bool: boolean) => void;
}

const Filter = ({
  filter,
  newFilters,
  setNewFilters,
  setTouched,
}: filterProps) => {
  const setNew = (revisedFilter: RecordFilter) => {
    setTouched(true);
    setNewFilters(
      newFilters.map(f => {
        if (f.name == filter.name) {
          return revisedFilter;
        } else {
          return f;
        }
      })
    );
  };

  const toggleOption = (value: any) => {
    let newValues = [];
    // @ts-expect-error
    if (filter.values?.find(v => v == value)) {
      // @ts-expect-error
      newValues = filter.values?.filter(v => v !== value);
    } else {
      newValues = filter.values?.concat(value);
    }

    setNew({
      ...filter,
      values: newValues,
    });
  };
  return (
    <div className="flex flex-col flex-wrap min-w-[250px] flex-1 p-2">
      <div className="flex flex-row justify-between items-center">
        <div className="flex-[2]">{filter.name}</div>
      </div>
      <div className="flex flex-row text-sm items-center gap-2 justify-between">
        <div className="flex flex-row flex-wrap gap-2 p-2">
          {filter.type !== "range" && (
            <>
              {filter.options &&
                sort(filter.options)
                  .asc("name")

                  .map(o => (
                    <div
                      key={o.name}
                      onClick={() => {
                        toggleOption(o.value);
                      }}
                      className="flex flex-row gap-1 items-center"
                    >
                      <Checkbox
                        className="checked:bg-plum dark:checked:bg-plum"
                        checked={
                          (filter.values &&
                            // @ts-expect-error
                            filter.values?.find(f => f == o.value)) ||
                          false
                        }
                        readOnly
                      />
                      <Label value={o.name} />
                    </div>
                  ))}
            </>
          )}
          {filter.type == "range" && (
            <FilterRangeType setNew={setNew} filter={filter} />
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Filter;
