import { z } from "zod";
import { coerceNumber } from "../../process/types";

export interface QuoteParams {
  id: number;
  name: string;
  value: any;
  productSetId?: number;
  userId?: number;
  updated_at: string;
  created_at: string;
}

export interface QuoteParamsArrayType {
  quoteParams: QuoteParams[];
}

export interface installSalesParams {
  name: string;
  type: "boolean";
  value: any;
  default: any;
  price: string;
}

export interface pricingTableRow {
  height: number;
  prices: {
    id: string;
    value: number;
  }[];
}

export interface pricingTableColumn {
  id: string;
  width: number;
}

export interface pricingTable {
  id: number;
  productSetId: number;
  columns: pricingTableColumn[];
  rows: pricingTableRow[];
  updated_at: string;
  created_at: string;
  name: string;
  condition?: string | null;
}

export interface pricingTableArrayType {
  productSetPricingTable: pricingTable[];
}

export type NewPricingTable = Omit<
  pricingTable,
  "id" | "created_at" | "updated_at"
>;

export const pricingTableRow = z.object({
  height: coerceNumber,
  prices: z.array(
    z.object({
      id: z.string(),
      value: coerceNumber,
    })
  ),
});

export const pricingTableColumn = z.object({
  id: z.string(),
  width: coerceNumber,
});

export const pricingTableScheme = z.object({
  productSetId: coerceNumber,
  columns: z.array(pricingTableColumn),
  rows: z.array(pricingTableRow),
  name: z.string().min(1, { message: "name cannot be empty" }),
  condition: z.string().optional().nullable(),
});
