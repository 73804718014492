import { useState } from "react";
import { UserType } from "../../users/types";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import ProductSetDiscountControl from "./productSetDiscountControl";
import ProductSetSqmControl from "./productSetSqmControl";
import { useOrderStore } from "../../../../../store/orderStore";
import { useQuery } from "@apollo/client";
import { GET_QUOTE_PARAMS } from "../gqls";

interface props {
  dealer: UserType;
}

export default function DealerFactors({ dealer }: props) {
  const [extended, setExtended] = useState(false);

  const navigate = useNavigate();
  const goToUserDetail = () => {
    navigate(`/settings/users/${dealer.id}`);
  };

  const { productSets } = useOrderStore();

  const { data } = useQuery(GET_QUOTE_PARAMS);
  const params = data?.quoteParams;

  return (
    <div className="bg-gray-100 dark:bg-gray-600 flex flex-col rounded-md ">
      <div
        onClick={() => {
          setExtended(!extended);
        }}
        className="p-3 bg-gray-100 dark:bg-gray-600 flex flex-row items-center justify-between rounded-md cursor-pointer hover:brightness-125"
      >
        <div className="flex flex-col gap-1">
          <div className="font-semibold text-xl capitalize">
            {dealer.firstName} {dealer.sirName}
          </div>
          <div className="italic">{dealer.email}</div>
        </div>
        {extended ? (
          <ChevronDoubleUpIcon className="w-5" />
        ) : (
          <ChevronDoubleDownIcon className="w-5" />
        )}
      </div>
      {extended && (
        <div className="flex flex-col gap-2 px-3 pb-3">
          <div
            className={`grid grid-cols-1 @md:grid-cols-2 supports-[not(container-type:inline-size)]:md:grid-cols-2 gap-4`}
          >
            <div className="col-span-1 flex flex-col gap-1">
              <h3>Discount Rate</h3>
              <div className="p-3 flex flex-col gap-1 rounded-md bg-white dark:bg-gray-800 drop-shadow-md">
                {params &&
                  productSets.map(ps => (
                    <ProductSetDiscountControl
                      key={ps.id}
                      dealer={dealer}
                      productSet={ps}
                      params={params}
                    />
                  ))}
              </div>
            </div>
            <div className="col-span-1 flex flex-col gap-1">
              <h3>Sqm Price</h3>
              <div className="p-3 flex flex-col gap-1 rounded-md bg-white dark:bg-gray-800 drop-shadow-md">
                {params &&
                  productSets.map(ps => (
                    <ProductSetSqmControl
                      key={ps.id}
                      dealer={dealer}
                      productSet={ps}
                      params={params}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div
            className="flex flex-row justify-end cursor-pointer italic hover:text-plum"
            onClick={goToUserDetail}
          >
            go to dealer's user detail
          </div>
        </div>
      )}
    </div>
  );
}
