import { gql } from "@apollo/client";

export const AUTH_CORE_FIELDS = gql`
  fragment AuthCoreFields on auths {
    id
    name
    description
    permissions
  }
`;
