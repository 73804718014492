import { PermissionType, permissionSchema } from "./types";
import { PERMISSION_CORE_FIELDS } from "./fragments";
import {
  Badge,
  Tooltip,
  Modal,
  Textarea,
  Button,
  Spinner,
} from "flowbite-react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useScheme } from "../../../../../store/schemeStore";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql, useMutation } from "@apollo/client";
import checkAuth from "../../../../../utils/checkAuth";

const UPDATE_PERMISSION = gql`
  ${PERMISSION_CORE_FIELDS}
  mutation UPDATE_PERMISSION($id: Int!, $description: String!) {
    update_permissions_by_pk(
      pk_columns: { id: $id }
      _set: { description: $description }
    ) {
      ...PermissionCoreFields
    }
  }
`;

const DELETE_PERMISSION = gql`
  mutation DELETE_PERMISSION($id: Int!) {
    delete_permissions_by_pk(id: $id) {
      id
    }
  }
`;

export default function Permission({
  permission,
}: {
  permission: PermissionType;
}) {
  const [update_permission, { loading, error }] =
    useMutation(UPDATE_PERMISSION);

  const [delete_permission, { loading: delete_loading, error: delete_error }] =
    useMutation(DELETE_PERMISSION);

  if (error) {
    console.log(error);
  }

  if (delete_error) {
    console.log(delete_error);
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(permissionSchema),
    defaultValues: {
      ...permission,
    },
  });

  const onSubmit = handleSubmit(async data => {
    if (!editing) {
      toggleEdit();
      return;
    }

    update_permission({
      variables: { ...data, id: permission.id },
      onCompleted: () => {
        toggleEdit();
      },
    });
  });

  const deletePermission = () => {
    const res = confirm(
      "you are trying to delete a permission which may cause significant errors, are you sure?"
    );
    if (res) {
      delete_permission({
        variables: { id: permission.id },
        update(
          cache,
          { data: { delete_permissions_by_pk: deletedPermission } }
        ) {
          const normalizedId = cache.identify({
            id: deletedPermission.id,
            __typename: "permissions",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    }
  };

  const clickCancle = () => {
    reset();
    toggleEdit();
  };

  const [show, setShow] = useState(false);
  const toggleModal = () => {
    setShow(!show);
  };

  const [editing, setEditing] = useState(false);
  const toggleEdit = () => {
    setEditing(!editing);
  };

  const { scheme } = useScheme();

  return (
    <form onSubmit={onSubmit}>
      <Badge
        onClick={toggleModal}
        color="purple"
        className="cursor-pointer hover:animate-pulse"
      >
        {permission.name}
      </Badge>

      <Modal
        className={`${scheme ? scheme : ""} !h-screen md:h-[calc(100% - 2rem)]`}
        show={show}
        onClose={toggleModal}
      >
        <Modal.Header>{permission.name}</Modal.Header>
        <Modal.Body>
          <Textarea
            className="disabled:opacity-75"
            disabled={!editing}
            {...register("description")}
            color={errors.description?.message ? "failure" : undefined}
            helperText={errors.description?.message || ""}
          />
        </Modal.Body>
        {checkAuth("setting_auth_edit") && (
          <Modal.Footer className="flex space-x-2 justify-end">
            {editing && !loading && (
              <>
                <TrashIcon
                  className="w-5 text-red-500 cursor-pointer"
                  onClick={deletePermission}
                />
                <Button
                  gradientDuoTone="purpleToBlue"
                  size="sm"
                  onClick={clickCancle}
                >
                  Cancel
                </Button>
              </>
            )}

            <Button
              outline
              gradientDuoTone="purpleToBlue"
              size="sm"
              onClick={onSubmit}
              type="submit"
            >
              {!editing && !loading && <PencilIcon className="w-4 mr-2" />}
              {loading && (
                <div className="mr-3">
                  <Spinner size="sm" light={true} />
                </div>
              )}
              {editing ? "Save" : "Edit"}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </form>
  );
}
