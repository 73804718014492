import { sort } from "fast-sort";
import { Button } from "flowbite-react";
import { useEffect, useState } from "react";
import { RecordFilter } from "../records/types";
import Filter from "./filter";
import {
  useDefaultFilters,
  useProductionStore,
} from "../../../store/productionStore";

interface props {
  filtering: boolean;
}

export default function Filters({ filtering }: props) {
  useDefaultFilters();

  const { filters, setFilters } = useProductionStore();

  const [newFilters, setNewFilters] = useState<RecordFilter[]>([]);
  const [touched, setTouched] = useState(false);

  if (!filters) {
    return null;
  }

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  const apply = () => {
    setFilters(newFilters);
    setTouched(false);
  };

  const cancel = () => {
    setNewFilters(filters);
    setTouched(false);
  };

  const reset = () => {
    localStorage.removeItem("productionFilters");
    location.reload();
  };

  const isDefault =
    JSON.stringify(filters.map(f => ({ values: f.values }))) ==
    JSON.stringify(
      filters.map(f => ({
        values: f.defaultValues,
      }))
    );

  return (
    <div
      className={`dark:bg-gray-800 bg-white flex flex-col flex-wrap transition-all duration-500 ease-in-out px-6 overflow-hidden ${
        !filtering
          ? "max-h-0 border-0"
          : "max-h-[300vh] h-auto border-y-[1px] border-gray-200 dark:border-gray-600 mb-2"
      }`}
    >
      {/* Filters*/}
      <div className="flex flex-row flex-wrap gap-4">
        {/* Filter */}

        {/* Selected Filters */}
        <div className="flex flex-row flex-wrap gap-4 ">
          {sort(newFilters)
            .by([{ asc: f => f.name }])
            .map(filter => (
              <Filter
                key={filter.where}
                filter={filter}
                newFilters={newFilters}
                setNewFilters={setNewFilters}
                setTouched={setTouched}
              />
            ))}
        </div>
      </div>
      {/* Action Buttons */}
      {(!isDefault || touched) && (
        <div className="flex flex-row justify-end gap-2 my-4">
          {!isDefault && (
            <Button onClick={reset} size="xs" color="purple">
              Reset
            </Button>
          )}

          {touched && (
            <>
              <Button onClick={cancel} size="xs" color="purple">
                Cancel
              </Button>
              <Button onClick={apply} size="xs" color="purple" outline>
                Apply
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
