import axios from "axios";
import { UserType } from "../routes/protected/settings/users/types";
import {
  BreifEvent,
  BreifEventList,
  Calendar,
  CalendarType,
  Event,
} from "../types/calendarTypes";
import { addDays, diffDateTime, onlyDate, isSameDay } from "./dateMethods";
import { sort } from "fast-sort";
const API = import.meta.env.VITE_API;
// import config from "../config.json";
import { default as config } from "../config";
import { calcDistance, Coord, geocode, numberCoord } from "./geocode";
import uuid from "react-uuid";
import { addAlert } from "../store/alertStore";
import { useHolidayStore } from "../store/holidayStore";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USERS } from "../routes/protected/settings/users/gql";
import { useCallback } from "react";

interface pullSchedulesProps {
  userList: UserType[];
  min?: number;
  max?: number;
  from?: string;
  to?: string;
}

interface pullScheduleProps {
  user: UserType;
}

export const usePopulateCalendar = () => {
  const { data, loading } = useQuery(GET_ACTIVE_USERS);
  const users = data?.users;

  const populateCalendar = (calendar: Calendar) => {
    const user = users?.find(u => u.calId == calendar.calId);

    if (!user) {
      return undefined;
    }
    const items = calendar.items;

    const userAttachedItems = items.map(item => ({
      ...item,
      user,
    }));

    const { calId, summary, updated, nextPageToken, nextSyncToken } = calendar;

    const populatedCalendar: CalendarType = {
      user,
      events: userAttachedItems,
      calId,
      summary,
      updated,
      nextPageToken,
      nextSyncToken,
    };

    return populatedCalendar;
  };

  return { loading, populateCalendar };
};

export const pullSchedule = async ({ user }: pullScheduleProps) => {
  const body = {
    calId: user.calId,
  };

  try {
    const res = await axios.post(API + "/calendar/single", body);
    if (!res.data) {
      console.log("no data");
      return undefined;
    }

    const calendar = res.data as Calendar;
    const items = calendar.items;

    const userAttachedItems = items.map(item => ({
      ...item,
      user,
    }));

    const { calId, summary, updated, nextPageToken, nextSyncToken } = calendar;

    const populatedCalendar: CalendarType = {
      user,
      events: userAttachedItems,
      calId,
      summary,
      updated,
      nextPageToken,
      nextSyncToken,
    };

    return populatedCalendar;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const pullSchedules = async ({
  userList,
  min,
  max,
  from,
  to,
}: pullSchedulesProps) => {
  const body = {
    list: userList.map(s => s.calId),
    min: min || 0,
    max: max || 14,
    from,
    to,
  };

  try {
    const res = await axios.post(API + "/calendar/all", body);
    if (!res.data) {
      console.log("no data");
      return [];
    }
    const schedules = res.data.map((calendar: Calendar) => {
      const { calId, items } = calendar;
      const user = userList.find(s => s.calId == calId);

      const userAttachedItems = items.map(item => ({
        ...item,
        user,
      }));

      return {
        user,
        events: userAttachedItems,
      };
    });

    return schedules;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export interface vacancyType {
  date: Date;
  start: Date;
  end: Date;
  position?: Coord;
  user: UserType;
  prevEvent?: BreifEvent;
  nextEvent?: BreifEvent;
  fullDay: boolean;
  efficiency?: number;
  existings: number;
  logic: number;
  id: string;
}

export interface vacancyList {
  user: UserType;
  vacancies: vacancyType[];
}

export const getSchedules = async (users: UserType[]) => {
  const eventList: BreifEventList[] = await pullSchedules({ userList: users });
  const geocodedList: BreifEventList[] = await Promise.all(
    eventList.map(async el => {
      const geocodedEvents: BreifEvent[] = await Promise.all(
        el.events.map(async event => {
          if (event.location) {
            try {
              const geocoded = await geocode(event.location);
              return {
                ...event,
                geocode: geocoded,
              };
            } catch (error) {
              console.log(error);
              return event;
            }
          } else {
            return event;
          }
        })
      );
      return { ...el, events: geocodedEvents };
    })
  );

  return geocodedList;

  // Time Matrix disabled due to performance reasons
  // const coords = geoCoded.map(gc => gc.geocode);
  // if (customerPosition) {
  //   const matrixes = await matrix(customerPosition, coords);
  //   return geoCoded;
  // } else {
  //   return geoCoded
  // }
};

const isWeekend = (date: Date) => {
  const day = date.getDay();

  return day == 6 || day == 0;
};

interface getVacancyProps {
  eventLists: BreifEventList[];
  minH?: number;
  weekSpan?: number;
  dayStart?: number;
  dayEnd?: number;
  customerLocation?: Coord;
}
export const getVacancies = (props: getVacancyProps) => {
  const { eventLists, minH, weekSpan, dayStart, dayEnd, customerLocation } =
    props;

  const minHourSpan = minH || 2;
  const today = onlyDate(new Date());
  const timeNow = new Date().getTime();
  const dates: Date[] = [];

  let span = 7 * (weekSpan || 2) - 2;
  const lastDayOfSpan = addDays(today, span).getDay();

  if (lastDayOfSpan < 5) {
    span += 5 - lastDayOfSpan;
  }

  for (let i = 0; i < span; i++) {
    const date = addDays(today, i);
    !isWeekend(date) && dates.push(date);
  }

  try {
    return eventLists.map(el => {
      const vacancies: vacancyType[] = dates.reduce((prev, cur) => {
        const user = el.user;

        const day = cur.getDay();

        let dayStartTime = dayStart || 7;
        let dayEndTime = dayEnd || 16;

        if (user.hours) {
          switch (day) {
            case 0: {
              if (user.hours.sun?.start && !user.hours.sun.off) {
                dayStartTime = user.hours.sun.start;
                dayEndTime = user.hours.sun.end || dayEndTime;
              }
              break;
            }
            case 1: {
              if (user.hours.mon?.start && !user.hours.mon.off) {
                dayStartTime = user.hours.mon.start;
                dayEndTime = user.hours.mon.end || dayEndTime;
              }
              break;
            }
            case 2: {
              if (user.hours.tue?.start && !user.hours.tue.off) {
                dayStartTime = user.hours.tue.start;
                dayEndTime = user.hours.tue.end || dayEndTime;
              }
              break;
            }
            case 3: {
              if (user.hours.wed?.start && !user.hours.wed.off) {
                dayStartTime = user.hours.wed.start;
                dayEndTime = user.hours.wed.end || dayEndTime;
              }
              break;
            }
            case 4: {
              if (user.hours.thu?.start && !user.hours.thu.off) {
                dayStartTime = user.hours.thu.start;
                dayEndTime = user.hours.thu.end || dayEndTime;
              }
              break;
            }
            case 5: {
              if (user.hours.fri?.start && !user.hours.fri.off) {
                dayStartTime = user.hours.fri.start;
                dayEndTime = user.hours.fri.end || dayEndTime;
              }
              break;
            }
            case 6: {
              if (user.hours.sat?.start && !user.hours.sat.off) {
                dayStartTime = user.hours.sat.start;
                dayEndTime = user.hours.sat.end || dayEndTime;
              }
              break;
            }
          }
        }

        const dayVacancies: vacancyType[] = [];

        const matchingEvents = el.events.filter(
          event => event.start && isSameDay(new Date(event.start), cur)
        );

        // Logic #1 - no events on the day
        if (matchingEvents.length < 1) {
          let start = new Date(cur);
          start.setHours(dayStartTime);

          let end = new Date(cur);
          end.setHours(dayEndTime);

          let travelTime = 0;

          if (customerLocation) {
            travelTime =
              calcDistance(
                el.user.baseLocation || config.companyLoc,
                customerLocation
              ) /
                1000 /
                60 +
              5 / 60;
          }

          dayVacancies.push({
            date: cur,
            start,
            end,
            user,
            fullDay: true,
            logic: 1,
            efficiency: travelTime,
            existings: matchingEvents.length,
            id: uuid(),
          });
        } else {
          // Events present on the day
          // TODO: Hour Terms for start and end time - when can you start travel and when do you want to get back home by?
          sort(matchingEvents)
            .asc(event => new Date(event.start || ""))
            .forEach((event, i) => {
              // No vacancy if fullDay event;
              if (event.type == "date") {
                return;
              }
              // start, end time check
              if (!event.start || !event.end) {
                return;
              }

              const eventDate = onlyDate(event.start);

              let dayStartTimeDate = new Date(eventDate);
              dayStartTimeDate.setHours(dayStartTime);

              let dayEndTimeDate = new Date(eventDate);
              dayEndTimeDate.setHours(dayEndTime);

              const eventStart = new Date(event.start);
              const eventEnd = new Date(event.end);

              // Logic #2 available before the first event
              if (i == 0) {
                // Start Time ~ First Event Start Time check
                const timeDiff =
                  diffDateTime(dayStartTimeDate, eventStart) / 60;
                let travelTime = 0;

                if (customerLocation) {
                  const eventTravelTime =
                    calcDistance(
                      el.user.baseLocation || config.companyLoc,
                      numberCoord(
                        event.geocode ||
                          el.user.baseLocation ||
                          config.companyLoc
                      )
                    ) /
                    1000 /
                    60;
                  const travelTimeToCustomer =
                    calcDistance(
                      el.user.baseLocation || config.companyLoc,
                      customerLocation
                    ) /
                    1000 /
                    60;
                  const travelTimeFromCustomer =
                    calcDistance(
                      customerLocation,
                      numberCoord(
                        event.geocode ||
                          el.user.baseLocation ||
                          config.companyLoc
                      )
                    ) /
                    1000 /
                    60;

                  travelTime =
                    travelTimeToCustomer +
                    travelTimeFromCustomer -
                    eventTravelTime +
                    5 / 60;
                  if (travelTime < 0) {
                    travelTime = 0;
                  }
                }

                if (
                  dayStartTimeDate.getTime() > timeNow &&
                  dayStartTimeDate.getTime() < eventStart.getTime() &&
                  timeDiff > minHourSpan + travelTime
                ) {
                  dayVacancies.push({
                    date: cur,
                    start: dayStartTimeDate,
                    end: eventStart,
                    fullDay: false,
                    user,
                    nextEvent: event,
                    logic: 2,
                    efficiency: travelTime,
                    existings: matchingEvents.length,
                    id: uuid(),
                  });
                }
              }

              // Logic #3 available between events
              const prevEvent = matchingEvents[i - 1];
              if (prevEvent && prevEvent.end) {
                const prevEnd = new Date(prevEvent.end);
                const timeDiff = diffDateTime(prevEnd, eventStart) / 60;
                let travelTime = 0;

                if (customerLocation) {
                  const eventTravelTime =
                    calcDistance(
                      numberCoord(
                        prevEvent.geocode ||
                          el.user.baseLocation ||
                          config.companyLoc
                      ),
                      numberCoord(
                        event.geocode ||
                          el.user.baseLocation ||
                          config.companyLoc
                      )
                    ) /
                    1000 /
                    60;
                  const travelTimeToCustomer =
                    calcDistance(
                      numberCoord(
                        prevEvent.geocode ||
                          el.user.baseLocation ||
                          config.companyLoc
                      ),
                      customerLocation
                    ) /
                    1000 /
                    60;
                  const travelTimeFromCustomer =
                    calcDistance(
                      customerLocation,
                      numberCoord(
                        event.geocode ||
                          el.user.baseLocation ||
                          config.companyLoc
                      )
                    ) /
                    1000 /
                    60;

                  travelTime =
                    travelTimeToCustomer +
                    travelTimeFromCustomer -
                    eventTravelTime +
                    5 / 60;
                  if (travelTime < 0) {
                    travelTime = 0;
                  }
                }

                if (
                  timeDiff > minHourSpan + travelTime &&
                  eventStart.getTime() > timeNow
                ) {
                  dayVacancies.push({
                    date: cur,
                    start: prevEnd,
                    end: eventStart,
                    fullDay: false,
                    user,
                    prevEvent: prevEvent,
                    nextEvent: event,
                    logic: 3,
                    efficiency: travelTime,
                    existings: matchingEvents.length,
                    id: uuid(),
                  });
                }
              }

              // Logic #4 available after the last event
              if (i == matchingEvents.length - 1) {
                const timeDiff = diffDateTime(dayEndTimeDate, eventEnd) / 60;
                let travelTime = 0;

                if (customerLocation) {
                  const eventTravelTime =
                    calcDistance(
                      el.user.baseLocation || config.companyLoc,
                      numberCoord(
                        event.geocode ||
                          el.user.baseLocation ||
                          config.companyLoc
                      )
                    ) /
                    1000 /
                    60;
                  const travelTimeFromCustomer =
                    calcDistance(
                      el.user.baseLocation || config.companyLoc,
                      customerLocation
                    ) /
                    1000 /
                    60;

                  const travelTimeToCustomer =
                    calcDistance(
                      customerLocation,
                      numberCoord(
                        event.geocode ||
                          el.user.baseLocation ||
                          config.companyLoc
                      )
                    ) /
                    1000 /
                    60;

                  travelTime =
                    travelTimeToCustomer +
                    travelTimeFromCustomer -
                    eventTravelTime +
                    5 / 60;

                  if (travelTime < 0) {
                    travelTime = 0;
                  }
                }

                if (
                  eventEnd.getTime() > timeNow &&
                  dayEndTimeDate.getTime() > eventEnd.getTime() &&
                  timeDiff > minHourSpan + travelTime
                ) {
                  dayVacancies.push({
                    date: cur,
                    start: eventEnd,
                    end: dayEndTimeDate,
                    fullDay: false,
                    user,
                    prevEvent: event,
                    logic: 4,
                    efficiency: travelTime,
                    existings: matchingEvents.length,
                    id: uuid(),
                  });
                }
              }
            });
        }

        return prev.concat(dayVacancies);
      }, <vacancyType[]>[]);

      return {
        user: el.user,
        vacancies,
      };
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};

export interface newEventType {
  calId: string;
  summary: string;
  start: {
    date?: string;
    dateTime?: string | number | Date;
  };
  end: { date?: string; dateTime?: string | number | Date };
  description?: string;
  location?: string;
}

export const addEvent = async (event: newEventType) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  const endpoint = API + "/calendar/add";

  try {
    const res = await axios.post(endpoint, event, config);
    if (res.data) {
      addAlert({ message: "Event successfully added", type: "success" });
      return res.data;
    }
    return false;
  } catch (err) {
    addAlert({ message: "Could not add event", type: "failure" });
    return false;
  }
};

export const deleteEvent = async ({
  calId,
  id,
}: {
  calId: string;
  id: string;
}) => {
  const endpoint = API + "/calendar/" + calId + "/" + id;

  try {
    const res = await axios.delete(endpoint);
    addAlert({ message: "Event successfully deleted", type: "success" });
    return res;
  } catch (err) {
    addAlert({ message: "Could not delete event", type: "failure" });
    return false;
  }
};

export const getSchedule = async (schedule: string) => {
  const ids = schedule.split("::");

  if (!ids[0] || !ids[1]) {
    console.log("invalid id");
    return false;
  }

  const endpoint = API + `/calendar/event/${ids[0]}/${ids[1]}`;

  try {
    const res = await axios.get(endpoint);

    if (res.data?.data) {
      return res.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

interface updateEventType extends newEventType {
  id: string;
}

interface updateEventProps {
  event: updateEventType;
  prevId: string;
}

export const updateEvent = async ({ event, prevId }: updateEventProps) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  const endpoint = API + "/calendar/update";

  try {
    const res = await axios.put(endpoint, { event, prevId }, config);
    if (res.data) {
      addAlert({ message: "Event successfully updated", type: "success" });
      return res.data;
    }
    return false;
  } catch (err) {
    addAlert({ message: "Could not update event", type: "failure" });
    return false;
  }
};

export const getHolidays = async () => {
  const ohterStates = [
    "Daylight Saving",
    "Cocos",
    "Victoria",
    "South Australia",
    "Tasmania",
    "Northern Territory",
    "Australian Capital Territory",
    "Western Australia",
    "New South Wales",
    "Christmas Island",
  ];

  try {
    const res = await axios.get(API + "/calendar/holidays");

    const year = new Date().getFullYear();
    if (res.data) {
      const holidays = res.data.filter((holiday: Event) => {
        const date = holiday.start?.date || holiday.start?.dateTime;
        if (new Date(date || "").getFullYear() !== year) {
          return false;
        }
        if (ohterStates.some(state => holiday.summary?.includes(state))) {
          return false;
        }

        return true;
      });

      return holidays;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useCheckHoliday = () => {
  const { holidays } = useHolidayStore();

  return (date: Date | string) => {
    const D = new Date(date);
    return holidays.find(h =>
      dayjs(h.start?.date || h.start?.dateTime).isSame(dayjs(D), "date")
    );
  };
};
