import { useForm } from "react-hook-form";
import { ProductSetType } from "../../../workorder/types";
import { useEffect } from "react";
import { useOrderStore } from "../../../../../store/orderStore";
import { Button, Spinner, TextInput } from "flowbite-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { coerceNumber } from "../../../process/types";
import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT_SET_BY_PK } from "../../../workorder/gqls";

interface props {
  productSetId: number;
}

const resolver = z.object({
  width: z
    .object({
      max: coerceNumber.nullable().optional(),
      min: coerceNumber.nullable().optional(),
    })
    .optional()
    .nullable(),
  height: z
    .object({
      max: coerceNumber.nullable().optional(),
      min: coerceNumber.nullable().optional(),
    })
    .optional()
    .nullable(),
});

export default function ProductSetSizeLimitSetting({ productSetId }: props) {
  const { productSets } = useOrderStore();

  const exLimits = productSets?.find(
    productSet => productSet.id === productSetId
  )?.sizeLimit;

  const defaultValues: ProductSetType["sizeLimit"] = {
    width: {
      max: undefined,
      min: undefined,
    },
    height: {
      max: undefined,
      min: undefined,
    },
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: zodResolver(resolver),
  });

  useEffect(() => {
    exLimits && reset(exLimits);
  }, [exLimits]);

  const [update, { loading }] = useMutation(UPDATE_PRODUCT_SET_BY_PK);

  const onSubmit = handleSubmit(data => {
    update({
      variables: {
        id: productSetId,
        set: {
          sizeLimit: data,
        },
      },
      onCompleted() {
        reset(data);
      },
    });
  });

  const cancel = () => {
    reset();
  };

  return (
    <form>
      <h3 className="font-semibold">Size Limits</h3>
      <div className="flex flex-row flex-wrap gap-2 items-center">
        <TextInput
          type="number"
          placeholder="Min Width"
          {...register("width.min")}
          addon="minW"
          sizing="sm"
        />

        <TextInput
          type="number"
          placeholder="Max Width"
          {...register("width.max")}
          addon="maxW"
          sizing="sm"
        />
        <TextInput
          type="number"
          placeholder="Min Height"
          {...register("height.min")}
          addon="minH"
          sizing="sm"
        />
        <TextInput
          type="number"
          placeholder="Min Height"
          {...register("height.max")}
          addon="maxH"
          sizing="sm"
        />
        {isDirty && (
          <div className="flex flex-row justify-end gap-2 items-center">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone="purpleToBlue"
              onClick={onSubmit}
            >
              {loading && <Spinner size="xs" color="purple" />}
              update
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
