import { Breadcrumb, Spinner, Table } from "flowbite-react";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@apollo/client";
import User from "./user";
import PendingUser from "./PendingUser";
import { sort } from "fast-sort";
import { addAlert } from "../../../../store/alertStore";
import { useEffect, useState } from "react";
import checkAuth from "../../../../utils/checkAuth";
import { GET_USERS } from "./gql";
import { useSearchStore } from "../../../../store/searchStore";

export default function Users() {
  const { data, loading, error } = useQuery(GET_USERS, {
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    console.log(error);
    addAlert({ message: "Cannot load users", type: "failure" });
  }

  const { setSearchFunction, clearSearchFunction, searchString } =
    useSearchStore();

  useEffect(() => {
    setSearchFunction(() => {});
    return () => {
      clearSearchFunction();
    };
  }, []);

  const [sortParams, setSortParams] = useState([
    {
      //@ts-expect-error
      desc: u => u.active,
    },
    {
      //@ts-expect-error
      asc: u => u.team?.name,
    },
    {
      //@ts-expect-error
      asc: u => u.auth?.name,
    },
  ]);

  const uncheckedUsers = data?.users
    ? data.users.filter(
        u =>
          !u.checked &&
          checkAuth([
            "setting_users_approve",
            {
              permission: "setting_users_approve_same_team",
              checkGroup: "userTeam",
              conditionGroup: [u?.team?.id || 0],
            },
          ])
      )
    : false;

  const sortedUsers = data?.users
    ? sort(data.users)
        .by(sortParams)
        .filter(u => u.checked)
        .filter(u => {
          if (searchString.trim() == "") {
            return true;
          }
          return `${u.firstName}${u.sirName}`
            .toLowerCase()
            .includes(searchString.toLowerCase());
        })
    : false;

  return (
    <div className="mb-1 flex-grow mx-auto">
      <section className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-4">
        <nav className="w-full">
          <Breadcrumb className="w-full ">
            <Link to="../">
              <Breadcrumb.Item>
                <HomeIcon className="w-5 mr-2" />
                Settings
              </Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Users</Breadcrumb.Item>
          </Breadcrumb>
        </nav>
        {/* NewUsers */}
        {uncheckedUsers && uncheckedUsers.length > 0 && (
          <div className="space-y-4 ">
            <h1 className="">Approval Pending</h1>
            {loading && !data && (
              <div className="flex flex-row justify-center mt-4 mb-6">
                <Spinner color="purple" size="xl" />
              </div>
            )}

            <Table hoverable={true}>
              <Table.Head className="select-text">
                <Table.HeadCell>Team</Table.HeadCell>
                <Table.HeadCell>Role</Table.HeadCell>
                <Table.HeadCell>Name</Table.HeadCell>
                <Table.HeadCell>Email</Table.HeadCell>
                <Table.HeadCell>Registered</Table.HeadCell>
                <Table.HeadCell>
                  <span className="sr-only">Action</span>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {uncheckedUsers.map(user => (
                  <PendingUser key={user.id} user={user} />
                ))}
              </Table.Body>
            </Table>
          </div>
        )}

        {/* Users */}
        <div className="space-y-4 ">
          <h1 className="">Users</h1>
          {loading && !data && (
            <div className="flex flex-row justify-center mt-4 mb-6">
              <Spinner color="purple" size="xl" />
            </div>
          )}
          {sortedUsers && (
            <Table hoverable={true}>
              <Table.Head className="select-text">
                <Table.HeadCell>Team</Table.HeadCell>
                <Table.HeadCell>Role</Table.HeadCell>
                <Table.HeadCell>Name</Table.HeadCell>
                <Table.HeadCell>Email</Table.HeadCell>
                <Table.HeadCell>Registered</Table.HeadCell>
                <Table.HeadCell>Active</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {sortedUsers.map(user => (
                  <User key={user.id} user={user} />
                ))}
              </Table.Body>
            </Table>
          )}
        </div>
      </section>
    </div>
  );
}
