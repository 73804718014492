import { gql } from "@apollo/client";
import { PRODUCT_CORE_FIELDS } from "../settings/products/fragments";

export const MATERIALS_CORE_FIELDS = gql`
  ${PRODUCT_CORE_FIELDS}
  fragment MaterialsCoreFields on materials {
    id
    brand
    name
    code
    color
    optionCode
    optionCondition
    image
    product {
      ...ProductCoreFields
    }
    widthDeduction
    heightDeduction
    size
    params
    pending
    selectable
    prices
  }
`;

export const MATERIALS_FIELDS_MIN = gql`
  fragment MaterialsFieldsMin on materials {
    id
    brand
    name
    color
    code
    optionCode
    optionCondition
    product {
      id
      name
      description
    }
    widthDeduction
    heightDeduction
    size
    params
    pending
    selectable
    prices
  }
`;
