import { UserType } from "../routes/protected/settings/users/types";
import { salesRepUser } from "../routes/protected/process/types";

export const fullNumber = (
  prefix: string,
  year: number,
  number: number,
  sales?: UserType | salesRepUser
) => {
  let fullNum = number.toString();
  for (let i = fullNum.length; i < 4; i++) {
    fullNum = "0" + fullNum;
  }
  let _year = year;
  if (!year) {
    _year = new Date().getFullYear() - 2000;
  }

  if (_year > 100) {
    _year -= 2000;
  }

  const appendix = sales ? "/" + sales?.firstName[0] + sales?.sirName[0] : "";

  return (prefix + _year.toString() + fullNum + appendix).toUpperCase();
};
