import { useLocation } from "react-router-dom";

export default function Heading({ name }: { name?: string }) {
  const location = useLocation();
  const route = location.pathname.toLocaleLowerCase().split("/")[1];

  return (
    <div className="font-bold select-none text-2xl uppercase">
      {name ? name : route}
    </div>
  );
}
