import { z } from "zod";
import { coerceNumber, ProcessType } from "../../process/types";
import { TaskStatusType } from "../../settings/tasks/taskStatuses/types";

export const taskSchema = z.object({
  description: z.string().min(2, { message: "description cannot be empty" }),
  assignedTo: coerceNumber,
  assignedBy: coerceNumber,
  statusID: coerceNumber,
  due: z.optional(z.string()),
  processID: z.optional(coerceNumber),
  schedules: z.optional(z.array(z.string())),
});

export interface NewTaskType {
  description: string;
  assignedTo: number;
  assignedBy: number;
  statusID: number;
  due?: string;
  processID?: number;
  schedules?: string[];
}

interface basicUserType {
  id: number;
  email: string;
  firstName: string;
  sirName: string;
}

export interface TaskType
  extends Omit<
    NewTaskType,
    "assignedTo" | "assignedBy" | "statusID" | "processID"
  > {
  id: number;
  assignedToUser: basicUserType;
  assignedByUser: basicUserType;
  process?: ProcessType;
  taskStatus: TaskStatusType;
  deleted: boolean;
  created_at: string;
  updated_at: string;
}

export interface TaskArrayType {
  tasks: TaskType[];
}

export interface TaskStreamType {
  tasks_stream: TaskType[];
}
