import { ListGroup } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { option } from "../../../types";
import {
  FieldValues,
  UseFormRegister,
  useFormContext,
  useWatch,
} from "react-hook-form";

interface props {
  optionCoordChained: string;
  register: UseFormRegister<FieldValues>;
  optionsCoord: string;
}

export default function Pelmetover({
  optionsCoord,
  optionCoordChained,
  register,
}: props) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const { control } = useFormContext();

  const formOptions: option[] = useWatch({
    name: optionsCoord,
    control,
  });

  const SBPosition = formOptions?.find(o => o.name == "SBLocation")?.value;

  const leftInset = useWatch({
    name: `${optionCoordChained}.value.left.inset`,
    control,
  });

  const leftExt = useWatch({
    name: `${optionCoordChained}.value.left.trackExt`,
    control,
  });

  const rightInset = useWatch({
    name: `${optionCoordChained}.value.right.inset`,
    control,
  });

  const rightExt = useWatch({
    name: `${optionCoordChained}.value.right.trackExt`,
    control,
  });

  const Returns = formOptions?.find(o => o.name === "Returns")
    ?.value as string[];
  const SideBoards = formOptions?.find(o => o.name === "SideBoard")
    ?.value as string[];
  const leftDeduction =
    (Returns?.includes("L") ? 5 : 0) + (SideBoards?.includes("L") ? 16 : 0);
  const rightDeduction =
    (Returns?.includes("R") ? 5 : 0) + (SideBoards?.includes("R") ? 16 : 0);

  return (
    <div className="relative min-w-[140px]" ref={ref}>
      <ListGroup onClick={toggleOpen}>
        <ListGroup.Item>
          <div className="text-xs">Dimensions</div>
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute p-2 z-20 right-0 mt-1 h-40 w-96 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600 flex flex-row gap-2">
          <div className="flex flex-row gap-2 h-full items-center">
            <input
              type="number"
              placeholder="0"
              className="dark:text-black w-10 border-b-[1px] text-xs py-1 px-1"
              {...register(`${optionCoordChained}.value.overAllDepth`)}
            />
            <div className="w-2 h-full border-y-[1px] border-l-[1px] rounded-l-xl border-black dark:border-white" />
          </div>
          <div className="relative flex flex-col w-full h-full">
            {/* Top */}
            <div className="flex flex-row flex-1">
              {/* Corner L */}
              <div className="w-20 border-b-2 border-black dark:border-white flex flex-col justify-end gap-2 items-center">
                {SBPosition === "Out" && (
                  <>
                    <input
                      type="number"
                      placeholder="0"
                      className="dark:text-black w-10 border-b-[1px] text-xs py-1 px-1"
                      {...register(`${optionCoordChained}.value.left.inset`)}
                    />
                    <div className="w-full h-2 border-x-[1px] border-t-[1px] rounded-t-xl border-black dark:border-white" />
                  </>
                )}
              </div>
              {/* Inner Depths */}
              <div className="flex-1 flex flex-row justify-between items-center border-t-2 border-x-2 border-black dark:border-white">
                {/* Left */}
                <div className="flex flex-row gap-2 h-full items-center">
                  <div className="w-2 h-full border-y-[1px] border-r-[1px] rounded-r-xl border-black dark:border-white" />
                  <input
                    type="number"
                    placeholder="0"
                    className="dark:text-black w-10 border-b-[1px] text-xs py-1 px-1"
                    {...register(`${optionCoordChained}.value.left.inner`)}
                  />
                </div>
                {/* Right */}
                <div className="flex flex-row gap-2 h-full items-center">
                  <input
                    type="number"
                    placeholder="0"
                    className="dark:text-black w-10 border-b-[1px] text-xs py-1 px-1"
                    {...register(`${optionCoordChained}.value.right.inner`)}
                  />
                  <div className="w-2 h-full border-y-[1px] border-l-[1px] rounded-l-xl border-black dark:border-white" />
                </div>
              </div>
              {/* Corner R */}
              <div className="w-20 border-b-2 border-black dark:border-white flex flex-col justify-end gap-2 items-center">
                {SBPosition === "Out" && (
                  <>
                    <input
                      type="number"
                      placeholder="0"
                      className="dark:text-black w-10 border-b-[1px] text-xs py-1 px-1"
                      {...register(`${optionCoordChained}.value.right.inset`)}
                    />
                    <div className="w-full h-2 border-x-[1px] border-t-[1px] rounded-t-xl border-black dark:border-white" />
                  </>
                )}
              </div>
            </div>
            {/* Bottom */}
            <div className="relative flex flex-row flex-1 justify-between border-x-2 border-b-2 border-black dark:border-white">
              {SBPosition === "Out" && (
                <>
                  {/* Left Track Extension */}
                  <div className="absolute flex flex-col gap-1 w-20 pt-1">
                    <div className="ml-10 w-10 h-2 border-l-[1px] border-black dark:border-white flex flex-col justify-center">
                      <div className="w-full h-1 border-t-[1px] border-black dark:border-white" />
                    </div>
                    <input
                      type="number"
                      placeholder="0"
                      className={`dark:text-black w-10 border-b-[1px] text-xs py-1 px-1 ml-5 ${
                        leftExt > leftInset - leftDeduction &&
                        "text-red-500 dark:text-red-500"
                      }
                      `}
                      {...register(`${optionCoordChained}.value.left.trackExt`)}
                    />
                  </div>

                  <div className="flex flex-col justify-end mb-2 items-center flex-1 gap-1">
                    <div>Tracks in Reveal</div>
                    <select
                      {...register(`${optionCoordChained}.value.tracks`)}
                      className="py-1 text-xs px-1 dark:text-black"
                    >
                      <option value={0}>None</option>
                      <option value={1}>Back</option>
                      {formOptions?.find(o => o.name == "TrackQty")?.value ==
                        3 && <option value={2}>Back, Middle</option>}
                    </select>
                  </div>
                  {/* Right Track Extension */}
                  <div className="absolute flex flex-col gap-1 w-20 pt-1 right-0 items-end">
                    <div className="mr-10 w-10 h-2 border-r-[1px] border-black dark:border-white flex flex-col justify-center">
                      <div className="w-full h-1 border-t-[1px] border-black dark:border-white" />
                    </div>
                    <input
                      type="number"
                      placeholder="0"
                      className={`dark:text-black w-10 border-b-[1px] text-xs py-1 px-1 mr-5 text-right
                      ${
                        rightExt > rightInset - rightDeduction &&
                        "text-red-500 dark:text-red-500"
                      }
                      `}
                      {...register(
                        `${optionCoordChained}.value.right.trackExt`
                      )}
                    />
                  </div>
                </>
              )}
            </div>
            {SBPosition === "In" && (
              <div className="absolute h-full pb-4 top-0 left-1/2 -translate-x-1/2 flex flex-col justify-end items-center flex-1 gap-1 pointer-events-none">
                <div className="flex-1 w-4 pl-1 flex flex-row justify-center border-y-2 border-black dark:border-white">
                  <div className="h-full border-x-[1px] border-black dark:border-white" />
                  <div className="w-2 h-full border-y-[1px] border-r-[1px] rounded-r-xl border-black dark:border-white" />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div>Sideboard Depth</div>
                  <input
                    type="number"
                    placeholder="0"
                    className="dark:text-black w-10 border-b-[1px] text-xs py-1 px-1 pointer-events-auto"
                    {...register(`${optionCoordChained}.value.depth`)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
