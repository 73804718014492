import { z } from "zod";

export const permissionSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  description: z.string().min(2, { message: "description cannot be empty" }),
});

export interface PermissionType {
  id: number;
  name: string;
  description: string;
}

export interface PermissionArrayType {
  permissions: PermissionType[];
}
