import { Badge, Label, ListGroup } from "flowbite-react";
import getContrast from "../../../utils/getContrast";
import { useRef, useState } from "react";
import { RecordStatusType } from "../shared/record/types";
import { useQuery } from "@apollo/client";
import { GET_RECORD_TYPE } from "../shared/record/gqls";
import { sort } from "fast-sort";
import { useOnClickOutside } from "usehooks-ts";

interface props {
  canApprove: boolean;
  type: RecordStatusType;
  changeType: (id: number) => void;
}

export default function RecordType({ canApprove, type, changeType }: props) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    canApprove && setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const [updating, setUpdating] = useState(false);

  const update = async (id: number) => {
    setUpdating(true);
    changeType(id);
    toggleOpen();
    setUpdating(false);
  };

  const { data } = useQuery(GET_RECORD_TYPE);
  const types = data?.recordType
    ? sort(data?.recordType).asc(stat => stat.priority)
    : [];

  return (
    <div ref={ref} className="flex flex-row justify-between items-center">
      <Label value="Type" />
      <div className="relative">
        <Badge
          style={{
            color: getContrast(type.color),
            backgroundColor: type.color,
          }}
          size="sm"
          onClick={toggleOpen}
          className={`w-fit ${updating && "opacity-50"} ${
            canApprove && "hover:brightness-125 cursor-pointer"
          }`}
        >
          {type.name}
        </Badge>
        {open && (
          <ListGroup className="absolute right-0 z-10 mt-2">
            {types.map(_type => (
              <ListGroup.Item
                onClick={() => {
                  update(_type.id);
                }}
                key={_type.id}
              >
                <Badge
                  style={{
                    backgroundColor: _type.color,
                    color: getContrast(_type.color),
                  }}
                  className={`${_type.id == type.id && "opacity-50"}`}
                >
                  {_type.name}
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    </div>
  );
}
