import { Button, Label, Modal, Select, TextInput } from "flowbite-react";
import {
  UseFieldArrayRemove,
  useController,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { useScheme } from "../../../../store/schemeStore";
import { useOrderStore } from "../../../../store/orderStore";
import { z } from "zod";
import { coerceNumber } from "../../process/types";
import { zodResolver } from "@hookform/resolvers/zod";
import isNullish from "../../../../utils/isNullish";
import { additionalMat } from "./types";
import { TrashIcon } from "@heroicons/react/24/solid";

export default function AdditionalMats() {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: "additionalMats",
    control,
  });

  return (
    <>
      <AddMaterial append={append} />
      {fields.length > 0 && (
        <>
          <div className="flex flex-col gap-1 mt-4 border-t-[1px] border-plum py-2 px-1">
            <div className="flex flex-row justify-center text-grass font-semibold">
              Additional Comps & Mats
            </div>
            {fields.map((field, index) => (
              <AdditionalMat key={field.id} index={index} remove={remove} />
            ))}
          </div>
        </>
      )}
    </>
  );
}

const AdditionalMat = ({
  index,
  remove,
}: {
  index: number;
  remove: UseFieldArrayRemove;
}) => {
  const { control } = useFormContext();
  const { field } = useController({
    name: `additionalMats.${index}`,
    control,
  });

  const mat: additionalMat = field.value;

  const { materials, units } = useOrderStore();

  const material = materials.find(m => m.id == mat.id);
  const unit = units.find(u => u.id == mat.unit);

  const _delete = () => {
    remove(index);
  };

  return (
    <div className="flex flex-row group relative items-center gap-2">
      <div className="p-[3px] rounded-full bg-white" />
      <div>
        {[material?.brand, material?.name, material?.color, material?.size]
          .filter(m => m !== null)
          .join(" ")}{" "}
        x{mat.qty} ({unit?.name})
      </div>
      <TrashIcon
        onClick={_delete}
        className="w-4 text-red-500 cursor-pointer hidden group-hover:block"
      />
    </div>
  );
};

const addMatScheme = z.object({
  mat: coerceNumber,
  qty: coerceNumber,
  unit: coerceNumber,
});

const AddMaterial = ({ append }: { append: (data: any) => void }) => {
  const { value: adding, setValue: setAdding } = useBoolean();

  const onClose = () => {
    setAdding(false);
  };

  const { scheme } = useScheme();

  const defaultValues = {
    productSetId: 0,
    mat: 0,
    qty: 0,
    unit: 0,
  };

  const { register, control, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: zodResolver(addMatScheme),
  });

  const { productSets, materials, units } = useOrderStore();

  const productSetId = useWatch({
    name: "productSetId",
    control,
  });

  const productSet = productSets.find(ps => ps.id == Number(productSetId));

  const selectableMats = materials.filter(
    mat =>
      productSet?.product.id == mat.product.id &&
      mat.prices.some(cost => !isNullish(cost.price, true))
  );

  const selectedMatId = useWatch({
    name: "mat",
    control,
  });

  const selectedMat = selectableMats.find(
    mat => mat.id == Number(selectedMatId)
  );

  const availableUnitIds = selectedMat?.prices
    .filter(cost => !isNullish(cost.price, true))
    .map(cost => cost.unit);

  const add = handleSubmit(data => {
    if ([data.mat, data.qty, data.unit].includes(0)) {
      return;
    }
    append({
      id: data.mat,
      qty: data.qty,
      unit: data.unit,
    });
    reset();
    setAdding(false);
  });

  const cancel = () => {
    reset();
    setAdding(false);
  };

  return (
    <>
      <div
        onClick={() => setAdding(true)}
        className="w-fit absolute -mt-[26px] font-semibold text-xs ml-32 flex flex-row items-center cursor-pointer dark:text-white z-10 hover:text-plum dark:hover:text-plum"
      >
        add material
      </div>
      <Modal show={adding} onClose={onClose} className={`${scheme}`}>
        <Modal.Header className="dark:bg-gray-800">
          Add Additional Components or Materials
        </Modal.Header>
        <Modal.Body className="dark:bg-gray-800">
          <div className="flex flex-col gap-2">
            <Label value="Product" />
            <Select
              sizing="sm"
              {...register("productSetId")}
              className="w-full"
            >
              <option value={0}>Select Product</option>
              {productSets.map(ps => (
                <option key={ps.id} value={ps.id}>
                  {ps.name}
                </option>
              ))}
            </Select>
            {productSetId !== 0 && (
              <>
                <Label value="Material" />
                <Select sizing="sm" {...register("mat")} className="w-full">
                  <option value={0}>Select Material</option>
                  {selectableMats.map(mat => (
                    <option key={mat.id} value={mat.id}>
                      {[mat.brand, mat.name, mat.color, mat.size]
                        .filter(m => m !== null)
                        .join(" ")}
                    </option>
                  ))}
                </Select>
                {selectedMatId !== 0 && (
                  <>
                    <div className="flex flex-row gap-2 items-center">
                      <TextInput
                        addon="Qty"
                        sizing="sm"
                        {...register("qty")}
                        className="flex-1"
                      />
                      <Select
                        addon="Unit"
                        sizing="sm"
                        {...register("unit")}
                        className="flex-1"
                      >
                        <option value={0}>select unit</option>
                        {units
                          .filter(u => availableUnitIds?.includes(u.id))
                          .map(u => (
                            <option key={u.id} value={u.id}>
                              {u.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end gap-1 dark:bg-gray-800">
          <Button size="sm" color="purple" onClick={cancel}>
            Cancel
          </Button>
          <Button
            size="sm"
            outline
            gradientDuoTone="purpleToBlue"
            onClick={add}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
