import { Button, Spinner } from "flowbite-react";
import Heading from "../../../comps/heading";
import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { sort } from "fast-sort";
import { useEffect, useState } from "react";
import { contactQueryParams, ContactStreamType, ContactType } from "./types";
import { CONTACT_CORE_FIELDS } from "./fragments";
import { useNavigate } from "react-router-dom";
import {
  AdjustmentsHorizontalIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/solid";
import Filters from "./filters";
import ContactsTable from "./contactsTable";
import { useContactStore } from "../../../store/contactStore";
import { cloneDeep } from "@apollo/client/utilities";
import { exportData } from "../../../utils/arrayObjMethods";

interface contactsQueryDocument {
  contacts: ContactType[];
  contacts_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

export const GET_CONTACTS: TypedDocumentNode<contactsQueryDocument> = gql`
  ${CONTACT_CORE_FIELDS}
  query GET_CONTACTS($where: contacts_bool_exp, $limit: Int, $offset: Int) {
    contacts(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { contactType: { name: asc }, name: asc }
    ) {
      ...ContactCoreFields
    }
    contacts_aggregate(
      where: $where
      order_by: { contactType: { name: asc }, name: asc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const SUB_CONTACTS: TypedDocumentNode<ContactStreamType> = gql`
  ${CONTACT_CORE_FIELDS}
  subscription SUB_CONTACTS(
    $cursor: [contacts_stream_cursor_input]!
    $where: contacts_bool_exp
  ) {
    contacts_stream(where: $where, cursor: $cursor, batch_size: 10) {
      ...ContactCoreFields
    }
  }
`;

interface props {
  filters: contactQueryParams;
}

export default function ContactsLoader({ filters }: props) {
  const [totalLength, setTotalLength] = useState(0);

  const [limit] = useState(1000);
  const { cursor, setCursor } = useContactStore();
  const [filtering, setFiltering] = useState(false);
  const toggleFiltering = () => {
    setFiltering(!filtering);
  };

  const { data, loading, error, subscribeToMore, fetchMore } = useQuery(
    GET_CONTACTS,
    {
      variables: { ...filters, limit },
      onCompleted(data) {
        setCursor(new Date().toISOString());
        setTotalLength(data.contacts_aggregate?.aggregate?.count || 0);
      },
    }
  );

  const ids = data?.contacts.map(c => c.id);

  useEffect(() => {
    const subFilter = {
      _or: [
        { _and: filters.where?._and.filter(p => !Object.hasOwn(p, "deleted")) },
        { id: { _in: ids || [] } },
      ],
    };

    const unsubscribe = subscribeToMore({
      document: SUB_CONTACTS,
      variables: {
        where: subFilter,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.contacts);
        const newFeedItem = subscriptionData.data.contacts_stream;
        const existing = previousData.filter(
          p => !newFeedItem.find(i => i.id == p.id)
        );
        const filterDeleted = [...newFeedItem, ...existing].filter(
          p => !p.deleted
        );
        return Object.assign({}, previous, {
          contacts: filterDeleted,
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [filters.where._and, ids]);

  const contacts =
    data?.contacts &&
    sort(data.contacts).by([
      { asc: t => t.contactType.name },
      { asc: t => t.name },
    ]);
  if (error) {
    console.log(error);
  }

  const loadMore = () => {
    const currentLength = data?.contacts.length || 0;
    fetchMore({
      variables: {
        ...filters,
        offset: currentLength,
        limit,
      },
    });
  };

  const navigate = useNavigate();
  const goToAdd = () => {
    navigate("./add");
  };

  const exportToExcel = () => {
    const _data = data?.contacts.map(d => ({
      id: d.id,
      name: d.name,
      type: d.contactType.name,
      number: d.number,
      mail: d.mail,
      role: d.role,
      org: d.organisation,
      address: d.address,
      description: d.description,
      // TODO: Extend Data
    }));
    exportData(_data, "Contacts");
  };

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6">
        <div className="flex flew-row justify-between h-8">
          <div className="flex flex-row items-center gap-2">
            <Heading />
          </div>

          <Button
            size="sm"
            outline
            gradientDuoTone="purpleToBlue"
            onClick={goToAdd}
          >
            ADD
          </Button>
        </div>
        <div className="flex flex-row text-grass mb-10">
          customers, suppliers, etc..
        </div>

        {/* Actions */}
        <div className="flex flex-row gap-2 flex-wrap justify-between items-center h-8">
          <div
            onClick={toggleFiltering}
            className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
          >
            <AdjustmentsHorizontalIcon className="w-4" />
            Filters
          </div>
          <div
            onClick={exportToExcel}
            className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
          >
            <ArrowDownTrayIcon className="w-4" />
          </div>
          <div className="flex flex-row justify-end items-center gap-2 flex-1 h-8">
            {loading && <Spinner light color="purple" />}
            <div className="text-sm">
              {data?.contacts.length}
              <span className="dark:text-grass text-plum"> of </span>
              {totalLength < (data?.contacts.length || 0)
                ? data?.contacts.length
                : totalLength}
            </div>
            {data && data?.contacts.length < totalLength && (
              <Button size="xs" onClick={loadMore} color="purple" outline>
                more
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* Filter */}
      <Filters filtering={filtering} />
      {/* Content Part */}
      <section className="px-6 mt-2 pb-6">
        <ContactsTable contacts={contacts} />
      </section>
    </main>
  );
}
