import { z } from "zod";
import { MaterialsType } from "../materials/types";
import { ProcessType, coerceNumber } from "../process/types";
import { ProductType } from "../settings/products/types";
import { order, value } from "./createOrder/types";

const optionalCoerceNumber = z.union([z.null(), z.undefined(), coerceNumber]);

export interface formula {
  name: string;
  displayName?: string;
  show?: boolean;
  formula: string;
  priority?: number;
}

export interface orderStatusType {
  id: number;
  name: string;
  color: string;
  priority: number;
  permission?: string;
}

export interface OrderType {
  id: number;
  createdBy: number;
  user: {
    id: number;
    firstName: string;
    sirName: string;
    mobile: string;
  };
  order: order;
  process: ProcessType;
  productionIds?: number[];
  description?: string;
  created_at: string;
  updated_at: string;
  due?: string;
  isDraft: boolean;
  orderRef?: string;
  type: orderStatusType;
  status: orderStatusType;
  lastUpdatedUser?: {
    id: number;
    firstName: string;
    sirName: string;
  };
}

export interface OrderArrayType {
  workorder: OrderType[];
}

export const usedItem = z.object({
  id: coerceNumber,
  qty: z.string(),
  unit: coerceNumber,
  optionConditions: z.array(z.any()).nullable().optional(),
});

export const deductionSchema = z.object({
  productSetID: optionalCoerceNumber,
  itemId: coerceNumber.nullable().optional(),
  name: z.string(),
  width: optionalCoerceNumber,
  height: optionalCoerceNumber,
  parent: optionalCoerceNumber.nullable().optional(),
  widthAddition: optionalCoerceNumber,
  heightAddition: optionalCoerceNumber,
  optionCode: z.string().nullable().optional(),
  usedItems: z.array(usedItem).nullable().optional(),
  image: z.string().nullable().optional(),
  pending: z.boolean(),
  imageTexts: z.array(z.any()).nullable().optional(),
  optionCondition: z.array(z.any()).nullable().optional(),
});

export interface DeductionType {
  id: number;
  name?: string;
  width?: number;
  height?: number;
  additionalValue?: string[];
  widthAddition?: number;
  heightAddition?: number;
  parentDeduction?: DeductionType;
  inventory?: MaterialsType;
  productSet?: ProductSetType;
  optionCode?: string;
  pending: boolean;
  usedItems?: {
    id: number;
    optionConditions?: optionValues[];
    qty: string;
    unit: number;
  }[];
  image?: string;
  imageTexts?: {
    scope: string;
    rotation: number;
    coord: {
      x: number;
      y: number;
    };
  }[];
  optionCondition?: {
    option: number;
    value: number | string;
    displayValue?: number | string;
  }[];
}

export interface DeductionArrayType {
  deductions: DeductionType[];
}

export const deductionPresetScheme = z.object({
  for: coerceNumber,
  name: z.string(),
  desc: z.string().nullable(),
  optionCode: z.string().optional(),
  deductions: z.array(z.number()),
  formulas: z.array(z.any()).nullable(),
  labour: z.string().nullable(),
  position: coerceNumber.nullable().optional(),
  optionCondition: z.array(z.any()).nullable(),
});

export interface DeductionPresetType {
  id: number;
  name: string;
  deductions: number[];
  formulas?: formula[];
  optionCode?: string;
  for: number;
  desc?: string;
  productSet: {
    id: number;
    name: string;
  };
  position?: number;
  labour?: string;
  optionCondition?: {
    option: number;
    value: number | string;
    displayValue?: number | string;
  }[];
}

export interface DeductionPresetArrayType {
  deductionPreset: DeductionPresetType[];
}

export interface modifier {
  value: string;
  type: string;
  prev?: number | string;
  new?: number | string;
  id?: number | string;
  qty?: number | string;
  unit?: number;
  optionValue?: value | value[];
}

export interface condition {
  value: number | string;
  conditions: optionValues[];
}

export interface optionValues {
  option: number;
  type?: string;
  values: any[];
  param?: string;
}

export interface optionProps {
  id: number;
  name: string;
  displayName?: string;
  type:
    | "select"
    | "multi-select"
    | "text"
    | "number"
    | "division"
    | "midrail"
    | "multiple"
    | "trackover"
    | "pelmetover"
    | "multi-name-number"
    | "param";
  source?: "deductions" | "inventory" | "deductionPreset";
  values?: (string | number)[]; //should it be value type?
  value?: any;
  optionCode?: string;
  optionCondition?: {
    material?: {
      param?: {
        name: string;
        value: string;
      };
    };
    deduction?: any;
    deductionPreset?: any;
  };
  optional: boolean;
  default: string | number;
  parent?: optionValues[];
  conditions?: condition[];
  modifiers?: modifier[];
  valuesQty?: number;
  position?: number;
  group?: string;
  productSetID: number;
  rowNum: number;
  presetID?: number;
  allowCustomInput: boolean;
  nameScope?: string[];
  disableAutoSelect: boolean;
}

export const modifierScheme = z.object({
  value: z.string().optional().nullable(),
  type: z.string(),
  prev: coerceNumber.optional().nullable(),
  new: coerceNumber.optional().nullable(),
  id: z.any().optional().nullable(),
  qty: z.any().optional().nullable(),
  unit: coerceNumber.optional().nullable(),
  optionValue: z.any().optional().nullable(),
});

export const productSetOptionScheme = z.object({
  name: z.string(),
  displayName: z.string().optional().nullable(),
  type: z.string(),
  default: z.any(),
  optional: z.boolean(),
  conditions: z.array(z.any()).optional().nullable(),
  group: z.string().optional().nullable(),
  modifiers: z.array(modifierScheme).optional().nullable(),
  parent: z.array(z.any()).optional().nullable(),
  source: z.string().optional().nullable(),
  optionCode: z.string().optional().nullable(),
  optionCondition: z.any().optional().nullable(),
  position: z.number(),
  value: z.any(),
  values: z.array(z.any()).optional().nullable(),
  valuesQty: coerceNumber.optional().nullable(),
  rowNum: coerceNumber,
  presetID: coerceNumber.optional().nullable(),
  allowCustomInput: z.boolean(),
  nameScope: z.array(z.any()).optional().nullable(),
  disableAutoSelect: z.boolean(),
});

export type NewProductSetOptionType = Omit<optionProps, "id" | "productSetID">;

export interface orderSummaryColumn {
  id: number;
  name: string;
  position: number;
  options: number[];
  default?: string;
}

export const orderSummaryColumnScheme = z.object({
  id: coerceNumber.optional().nullable(),
  name: z.string().min(2),
  position: coerceNumber,
  options: z.array(coerceNumber),
  default: z.string().optional().nullable(),
});

export interface ProductSetWarning {
  where: string | number;
  condition: string;
  message: string;
}

export interface ProductSetType {
  id: number;
  name: string;
  product: ProductType;
  hideItems: boolean;
  calcs?: formula[];
  productSetOptions?: optionProps[];
  desc?: string;
  orderPresets?: ProductSetOrderPreset[];
  qtyCalc?: string;
  position?: number;
  sizeLimit?: {
    width?: {
      min?: number;
      max?: number;
    };
    height?: {
      min?: number;
      max?: number;
    };
  };
  warnings: ProductSetWarning[];
}

export interface ProductSetByIdType {
  productSets_by_pk: ProductSetType;
}

export interface ProductSetArrayType {
  productSets: ProductSetType[];
}

export interface layoutConfig {
  num: number;
  config: {
    option: number;
    value: number | string;
    displayValue?: string;
  }[];
}

export interface LayoutType {
  id: number;
  productSet: ProductSetType;
  image?: string;
  code: string;
  name: string;
  config: layoutConfig[];
  parent?: optionValues[];
}

export interface newLayoutType extends Omit<LayoutType, "id" | "productSet"> {
  for: number;
}

export const layoutScheme = z.object({
  for: z.coerce.number(),
  code: z.string(),
  name: z.string(),
  config: z.array(z.any()),
  image: z.string().nullable(),
  parent: z.array(z.any()).nullable(),
});

export interface LayoutArrayType {
  layouts: LayoutType[];
}

export interface optionValue {
  option: number;
  value: number | string;
}

export interface ProductImageType {
  id: number;
  productSetId?: number;
  productSet: {
    id: number;
    name: string;
  };
  image: string;
  desc?: string;
  options: optionValues[];
  invert: boolean;
}

export type newProductImageType = Omit<ProductImageType, "id" | "productSet">;

export const productImageScheme = z.object({
  productSetId: z.coerce.number(),
  desc: z.string().nullable(),
  image: z.string(),
  options: z.array(z.any()).nullable(),
  invert: z.boolean(),
});

export interface ProductImageArrayType {
  productImages: ProductImageType[];
}

export interface ProductSetOrderPreset {
  name: string;
  options: number[];
  calcs: string[];
  includeComps: boolean;
  productId: number;
  condition?: string;
}
