import React, { useEffect, useState } from "react";
import { useScheme } from "../../../../store/schemeStore";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { optionValues } from "../../workorder/types";
import { GET_MATERIALS_CONDITIONAL } from "../../materials/gqls";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_DEDUCTIONS_CONDITIONAL,
  GET_DEDUCTION_PRESETS_CONDITIONAL,
  GET_PRODUCT_OPTIONS_CONDITIONAL,
} from "../../workorder/gqls";
import { sort } from "fast-sort";
import { Badge, Button, Select } from "flowbite-react";
import ValuePopulate from "../../workorder/ValuePopulate";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

interface props {
  productSetID: number;
  cb: (data: optionValues) => void;
  optionIdsToFilter: number[];
}

const OptionValueControl = ({ productSetID, cb, optionIdsToFilter }: props) => {
  const defaultValues: optionValues = {
    option: 0,
    type: "",
    values: [],
  };

  const { scheme } = useScheme();
  const { control, reset, register, handleSubmit } = useForm({
    defaultValues,
  });

  const { append, remove } = useFieldArray({
    name: "values",
    control,
  });

  const addedValues: (string | number)[] = useWatch({
    name: "values",
    control,
  });

  const optionId: number = useWatch({
    name: "option",
    control,
  });

  const [getInv] = useLazyQuery(GET_MATERIALS_CONDITIONAL);
  const [getDeductions] = useLazyQuery(GET_DEDUCTIONS_CONDITIONAL);
  const [getPresets] = useLazyQuery(GET_DEDUCTION_PRESETS_CONDITIONAL);

  const { data: data_options } = useQuery(GET_PRODUCT_OPTIONS_CONDITIONAL, {
    variables: {
      where: {
        productSetID: { _eq: Number(productSetID) },
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const optionTypes = ["select"];
  const options = data_options
    ? sort(data_options.productSetOptions)
        .asc("position")
        .filter(o => optionTypes.includes(o.type))
    : [];

  const nullishValues = ["", null, 0, undefined];

  const selectedOption = options?.find(o => o.id == optionId);
  const [selectables, setSelectables] = useState<any[]>([]);

  useEffect(() => {
    if (selectedOption?.source == "deductionPreset") {
      if (selectedOption.optionCode) {
        getPresets({
          variables: {
            where: { optionCode: { _eq: selectedOption.optionCode } },
          },
          onCompleted(data) {
            if (data.deductionPreset) {
              setSelectables(data.deductionPreset);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
      if (selectedOption.values && selectedOption.values?.length > 0) {
        getPresets({
          variables: {
            where: { id: { _in: selectedOption.values } },
          },
          onCompleted(data) {
            if (data.deductionPreset) {
              setSelectables(data.deductionPreset);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
    }
    if (selectedOption?.source == "deductions") {
      if (selectedOption.optionCode) {
        getDeductions({
          variables: {
            where: { optionCode: { _eq: selectedOption.optionCode } },
          },
          onCompleted(data) {
            if (data.deductions) {
              setSelectables(data.deductions);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
      if (selectedOption.values && selectedOption.values?.length > 0) {
        getDeductions({
          variables: {
            where: { id: { _in: selectedOption.values } },
          },
          onCompleted(data) {
            if (data.deductions) {
              setSelectables(data.deductions);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
    }
    if (selectedOption?.source == "inventory") {
      if (selectedOption.optionCode) {
        getInv({
          variables: {
            where: { optionCode: { _eq: selectedOption.optionCode } },
          },
          onCompleted(data) {
            if (data.materials) {
              setSelectables(data.materials);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
      if (selectedOption.values && selectedOption.values?.length > 0) {
        getInv({
          variables: {
            where: { id: { _in: selectedOption.values } },
          },
          onCompleted(data) {
            if (data.materials) {
              setSelectables(data.materials);
            }
          },
          onError(error) {
            console.log(error);
          },
        });
      }
    }
    if (!selectedOption?.source && selectedOption?.type == "select") {
      // @ts-ignore
      setSelectables(selectedOption.values);
    }
  }, [selectedOption]);

  const add = handleSubmit(data => {
    cb(data);
    reset();
  });

  const [valueToAdd, setValueToAdd] = useState("");
  const addValue = () => {
    if (nullishValues.includes(valueToAdd)) {
      return;
    }
    append(valueToAdd);
    setValueToAdd("");
  };

  return (
    <div className={`${scheme} w-full flex flex-col gap-2 flex-wrap`}>
      {/* New Config */}

      <div className="flex flex-row gap-2 items-center">
        <Select
          addon="Option"
          sizing="sm"
          disabled={addedValues.length > 0 ? true : false}
          {...register("option")}
        >
          <option value="">select option</option>
          {options
            ?.filter(
              o =>
                !optionIdsToFilter.find(id => id.toString() == o.id.toString())
            )
            .map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </Select>

        {selectedOption && selectedOption.type == "select" && (
          <Select
            addon="Value"
            sizing="sm"
            value={valueToAdd}
            onChange={e => {
              setValueToAdd(e.target.value);
            }}
          >
            <option value="">select option</option>
            {selectables
              ?.filter(
                option =>
                  !addedValues.includes(
                    option?.id?.toString() || option.toString()
                  )
              )
              .map((option, i) => (
                <option key={option.id || i} value={option.id || option}>
                  {option.name || option}
                </option>
              ))}
          </Select>
        )}
        <PlusCircleIcon
          className="text-grass w-5 cursor-pointer"
          onClick={addValue}
        />
      </div>
      <div className="flex flex-row flex-wrap items-center gap-2">
        {addedValues.map((c, i) => {
          const option = selectedOption;
          const source = option?.source;
          const value = c;
          return (
            <Badge
              key={c}
              color="purple"
              className="cursor-pointer"
              onClick={() => {
                remove(i);
              }}
            >
              <div className="flex flex-row gap-1 items-center">
                <ValuePopulate source={source} value={value} />
                <XMarkIcon className="w-4" />
              </div>
            </Badge>
          );
        })}
        {addedValues.length > 0 && (
          <Button
            onClick={add}
            size="xs"
            gradientDuoTone="purpleToBlue"
            outline
          >
            add conditions
          </Button>
        )}
      </div>
    </div>
  );
};

export default OptionValueControl;
