import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import DynamicHeroIcon from "../../../../comps/hIcon";
import PdfViewer from "../../../../comps/pdfViewer";
import { get } from "../../../../utils/fileAPI";
import Menu from "./menu";
import { FileType } from "./type";

interface props {
  path: string[];
  file: FileType;
  setCurrentPath: React.Dispatch<React.SetStateAction<string[]>>;
  clipboard: {
    path: string[];
    copy: boolean;
  };
  setClipboard: React.Dispatch<
    React.SetStateAction<{
      path: string[];
      copy: boolean;
    }>
  >;
}

export default function File({
  path,
  file,
  setCurrentPath,
  clipboard,
  setClipboard,
}: props) {
  const currentPath = path.concat(file.name);

  const [downloading, setDownloading] = useState(false);
  const [view, setView] = useState(false);
  const toggleView = () => {
    setView(!view);
  };

  const [downloaded, setDownloaded] = useState("");

  const ext = file.name.split(".").pop();

  const download = async () => {
    if (downloaded) {
      return toggleView();
    } else {
      setDownloading(true);
      const use = icon == "PhotoIcon" || ext == "pdf";
      const res = await get(currentPath, use);
      if (use && typeof res == "string") {
        setDownloaded(res);
        toggleView();
      }
      setDownloading(false);
    }
  };

  const [showMenu, setShowMenu] = useState(false);
  const toggleShow = () => {
    setShowMenu(!showMenu);
  };

  const [icon, setIcon] = useState("DocumentIcon");
  const images = ["jpg", "svg", "bmp", "jpeg", "png", "gif", "webp"];
  const videos = ["avi", "mkv", "mp4", "mov", "wmv", "webm", "flv", "f4v"];
  const sheets = ["sheet", "xls", "xlxs", "xlsx", "xlsm", "ppt", "pptx"];
  const docs = ["pdf", "doc", "docm", "docx"];

  useEffect(() => {
    if (!ext) {
      return;
    }
    if (images.includes(ext.toLowerCase())) {
      setIcon("PhotoIcon");
    }
    if (videos.includes(ext.toLowerCase())) {
      setIcon("FilmIcon");
    }
    if (sheets.includes(ext.toLowerCase())) {
      setIcon("DocumentChartBarIcon");
    }
    if (docs.includes(ext.toLowerCase())) {
      setIcon("DocumentTextIcon");
    }
  }, []);

  return (
    <>
      <div className="group relative flex flex-row gap-2 items-center justify-between">
        <div
          onClick={download}
          className="flex-1 hover:text-plum cursor-pointer flex flex-row gap-2 items-center"
        >
          <DynamicHeroIcon icon={icon} class="w-6" />

          <div className="flex-1 w-0 truncate">{file.name}</div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          {downloading && <Spinner color="purple" size="sm" />}
          <EllipsisVerticalIcon
            className="w-5 @md:hidden @md:group-hover:block hover:text-plum cursor-pointer"
            onClick={toggleShow}
          />
        </div>
        {showMenu && (
          <Menu
            path={currentPath}
            file={file}
            setShowMenu={setShowMenu}
            setCurrentPath={setCurrentPath}
            clipboard={clipboard}
            setClipboard={setClipboard}
          />
        )}
      </div>
      {view && downloaded && (
        <div className="flex flex-row justify-center">
          {ext !== "pdf" && <img className="w-full" src={downloaded} />}
          {ext == "pdf" && <PdfViewer document={downloaded} name={file.name} />}
        </div>
      )}
    </>
  );
}
