import { useMutation, gql } from "@apollo/client";
import { Button, Spinner, TextInput, Textarea } from "flowbite-react";
import { permissionSchema, PermissionType } from "./types";
import { PERMISSION_CORE_FIELDS } from "./fragments";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";

export const INSERT_PERMISSION = gql`
  ${PERMISSION_CORE_FIELDS}
  mutation INSERT_PERMISSION($name: String!, $description: String!) {
    insert_permissions_one(object: { name: $name, description: $description }) {
      ...PermissionCoreFields
    }
  }
`;

export default function AddPermission({
  toggleAdding,
}: {
  toggleAdding: () => void;
}) {
  const [insert_permission, { loading, error }] =
    useMutation(INSERT_PERMISSION);
  if (error) {
    console.log(error);

    addAlert({
      message: "something went wrong cannot add permission",
      type: "failure",
    });
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PermissionType>({
    resolver: zodResolver(permissionSchema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const onSubmit = handleSubmit(async data => {
    insert_permission({
      variables: { ...data, name: data.name.toLowerCase().replaceAll(" ", "") },
      update(cache, { data: { insert_permissions_one: newPermission } }) {
        cache.modify({
          fields: {
            permissions(existingPermissions = []) {
              const newPermissionRef = cache.writeFragment({
                data: newPermission,
                fragment: PERMISSION_CORE_FIELDS,
              });
              return [...existingPermissions, newPermissionRef];
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          message: "New Permission successfully added",
          type: "success",
        });
        toggleAdding();
      },
    });
  });

  const cancel = () => {
    reset();
    toggleAdding();
  };

  return (
    <form
      onSubmit={onSubmit}
      className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 mb-5"
    >
      <TextInput
        type="text"
        placeholder="Enter the name for new permission no space allowed"
        {...register("name")}
        color={errors.name?.message ? "failure" : undefined}
        helperText={errors.name?.message || ""}
      />

      <Textarea
        {...register("description")}
        placeholder="Describe the permission in detail"
        color={errors.description?.message ? "failure" : undefined}
        helperText={errors.description?.message || ""}
        className="mt-2 mb-4 text-sm"
      />

      <>
        <div className="flex flex-row justify-end gap-2">
          <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
            Cancel
          </Button>
          <Button
            gradientDuoTone="purpleToBlue"
            outline
            size="sm"
            type="submit"
          >
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Add
          </Button>
        </div>
      </>
    </form>
  );
}
