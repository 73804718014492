import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthStore } from "../../../store/authStore";
import { pullSchedule } from "../../../utils/calendarMethods";
import { useScheduleStore } from "../../../store/scheduleStore";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useScheme } from "../../../store/schemeStore";
import moment from "moment";
import dayjs from "dayjs";
import MyAgenda from "../schedule/myAgendaView/myAgendaView";
import Detail from "../schedule/detail";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";

export default function Agenda() {
  const { user } = useAuthStore();
  const { calendars, mergeCalendars } = useScheduleStore();

  const load = async () => {
    if (!user || !user.calId || calendars.find(c => c.calId == user.calId)) {
      return;
    }
    const userCalendar = await pullSchedule({
      user: { ...user, permissions: [] },
    });

    if (userCalendar) {
      mergeCalendars([userCalendar], true);
    }
  };

  useEffect(() => {
    load();
  }, [user]);

  const myCalendar = calendars.find(c => c.calId == user?.calId);
  const { scheme } = useScheme();
  const localizer = momentLocalizer(moment); // or globalizeLocalizer

  const formats = useMemo(
    () => ({
      agendaTimeRangeFormat: (range: { start: Date; end: Date }) => {
        return `${dayjs(range.start).format("hh:mm")}-${dayjs(range.end).format(
          "hh:mm"
        )}`;
      },
    }),
    []
  );

  const calendarViews = useMemo(
    () => ({
      month: false,
      week: false,
      day: false,
      agenda: MyAgenda,
    }),
    []
  );

  const [selectedSchedule, setSelctedSchedule] = useState<undefined | string>(
    undefined
  );

  const onSelectEvent = useCallback((event: any) => {
    try {
      const scheduleString = `${event.resource.calId}::${event.resource.id}`;
      setSelctedSchedule(scheduleString);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const navigate = useNavigate();

  const goToSchedule = () => {
    navigate("/schedule");
  };

  if (!myCalendar) {
    return null;
  }

  const events = myCalendar.events
    .filter(e => e?.status && e.status !== "cancelled")
    .map(e => ({
      title: `${e.summary}${
        calendars.length > 1
          ? ` - ${e.user?.firstName[0]}${e.user?.sirName[0]}`.toUpperCase()
          : ""
      }`,
      start: new Date(e.start as string),
      end: new Date(e.end as string),
      allDay: e.type == "date" ? true : false,
      resource: e,
    }));

  return (
    <div
      className="
      flex flex-col gap-2
      col-span-1 
    @lg:col-span-2 supports-[not(container-type:inline-size)]:lg:col-span-2 
    @xl:col-span-3 supports-[not(container-type:inline-size)]:xl:col-span-3 
    @2xl:col-span-4 supports-[not(container-type:inline-size)]:2xl:col-span-4"
    >
      <div className="flex flex-row justify-between items-center">
        <h2>Upcoming</h2>
        <Button
          onClick={goToSchedule}
          size="xs"
          outline
          gradientDuoTone="purpleToBlue"
        >
          More
        </Button>
      </div>
      <Calendar
        className={`${scheme} curView-agenda max-h-[500px]`}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        toolbar={false}
        formats={formats}
        views={calendarViews}
        popup={true}
        defaultView="agenda"
        onSelectEvent={onSelectEvent}
        longPressThreshold={20}
      />
      {selectedSchedule && (
        <Detail
          schedule={selectedSchedule}
          cb={() => {
            setSelctedSchedule(undefined);
          }}
        />
      )}
    </div>
  );
}
