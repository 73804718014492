import dayjs from "dayjs";
import { CalendarProps, Event } from "react-big-calendar";

interface props {
  event: Event;
  onSelectEvent: CalendarProps["onSelectEvent"];
}

export default function AgendaItem({ event, onSelectEvent }: props) {
  const isBefore = event.allDay
    ? dayjs(event.start).isBefore(dayjs(), "date")
    : dayjs(event.start).isBefore(dayjs());

  const onEventClick = (
    e: React.SyntheticEvent<HTMLElement, globalThis.Event>
  ) => {
    if (onSelectEvent) {
      onSelectEvent(event, e);
    }
  };

  return (
    <div
      onClick={onEventClick}
      className={`${
        isBefore && "opacity-50"
      } flex flex-col gap-1 md:flex-row md:gap-0 md:items-center md:h-[30px] hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer px-1 rounded-md`}
    >
      <div className="md:w-36 flex flex-row justify-between items-center gap-2 md:leading-[0]">
        <div className="flex flex-row gap-2 items-center">
          <div
            className="rounded-full p-[5px]"
            style={{
              backgroundColor: event.resource.user?.color,
            }}
          />
          {event.allDay
            ? "All day"
            : `${dayjs(event.start).format("hh:mm")} - ${dayjs(
                event.end
              ).format("hh:mm")}`}
        </div>
        <span className="md:hidden text-xs leading-[1]">
          {dayjs(event.start).format("ddd")}
        </span>
      </div>
      <div className="md:flex-1 md:truncate md:w-0">{event.title}</div>
    </div>
  );
}
