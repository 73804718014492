import { useState, MouseEvent } from "react";
import { useAuthStore } from "../../../store/authStore";
import { TextInput, Label, Button, Spinner } from "flowbite-react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql, useMutation } from "@apollo/client";
import {
  Autocomplete,
  AutocompleteProps,
  LoadScriptProps,
  useLoadScript,
} from "@react-google-maps/api";
import { default as config } from "../../../config";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];
const companyLoc = config.companyLoc;

export const optionalSchema = z.object({
  address: z.string().optional(),
  mobile: z.string().optional(),
  baseLocation: z
    .object({
      lat: z.number(),
      lng: z.number(),
    })
    .optional(),
});

export const UPDATE_OPTIONAL = gql`
  mutation UPDATE_OPTIONAL(
    $mobile: String
    $address: String
    $baseLocation: jsonb
    $id: Int!
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { mobile: $mobile, address: $address, baseLocation: $baseLocation }
    ) {
      id
      mobile
      address
      baseLocation
    }
  }
`;

export default function Optional() {
  const { user, setUser } = useAuthStore();

  const [update_optional, { loading, error }] = useMutation(UPDATE_OPTIONAL);
  if (error) {
    console.log(error);
  }
  const [editing, setEditing] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(optionalSchema),
    defaultValues: {
      mobile: user?.mobile || "",
      address: user?.address || "",
      baseLocation: user?.baseLocation || undefined,
    },
  });

  const onSubmit = handleSubmit(async data => {
    update_optional({
      variables: { id: user?.id, ...data },
      onCompleted(data) {
        if (data?.update_users_by_pk) {
          const { mobile, address, baseLocation } = data.update_users_by_pk;

          if (user) {
            setUser({
              ...user,
              mobile,
              address,
              baseLocation,
            });
          }

          reset({
            mobile,
            address,
            baseLocation,
          });
        }
      },
    });
    setEditing(state => !state);
  });

  const clickEdit = (e: MouseEvent) => {
    if (!editing) {
      e.preventDefault();
      setEditing(state => !state);
    }
  };

  const clickCancle = () => {
    setEditing(false);
    reset();
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });
  const [autocomplete, setAutocomplete] = useState<any | null>(null);

  const autoCompleteLoaded = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };
  const autocompleteOptions: AutocompleteProps["options"] = {
    componentRestrictions: { country: "au" },
    bounds: {
      north: companyLoc.lat + 3,
      south: companyLoc.lat - 3,
      east: companyLoc.lng + 3,
      west: companyLoc.lng - 3,
    },
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const { formatted_address: address, geometry } = place;

      if (address) {
        setValue("address", address.replace(", Australia", "").trim());
      }

      const { lat, lng } = {
        lat: geometry?.location?.lat(),
        lng: geometry?.location?.lng(),
      };

      const loc = lat && lng ? { lat, lng } : null;

      if (loc) {
        setValue("baseLocation", loc);
      }
    }
  };

  return (
    <form className="mb-1 flex-grow  mx-auto" onSubmit={onSubmit}>
      <div className=" flex flex-row justify-between">
        <div className="text-2xl font-bold mb-2">Optional Fields</div>
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 ">
        <div className="mb-2 block mt-2">
          <Label htmlFor="mobile" value="Mobile" />
        </div>
        <TextInput
          addon="☏"
          type="tel"
          placeholder="your mobile number"
          disabled={!editing}
          {...register("mobile")}
          color={errors.mobile?.message ? "failure" : undefined}
          helperText={errors.mobile?.message || ""}
        />

        <div className="mb-2 block mt-2">
          <Label htmlFor="address" value="Address" />
        </div>
        {isLoaded && (
          <Autocomplete
            onLoad={autoCompleteLoaded}
            onPlaceChanged={onPlaceChanged}
            options={autocompleteOptions}
            fields={[
              "formatted_address",
              "geometry.location",
              "address_component",
            ]}
          >
            <TextInput
              addon="🏁"
              type="text"
              placeholder="your address"
              disabled={!editing}
              color={errors.address?.message ? "failure" : undefined}
              helperText={errors.address?.message || ""}
              {...register("address")}
            />
          </Autocomplete>
        )}

        <hr className="my-4 border-gray-300 dark:border-gray-500" />
        <div className="flex space-x-2 justify-end">
          {(editing || loading) && (
            <Button
              gradientDuoTone="purpleToBlue"
              size="sm"
              onClick={clickCancle}
            >
              Cancel
            </Button>
          )}

          <Button
            onClick={clickEdit}
            outline
            type="submit"
            gradientDuoTone="purpleToBlue"
            size="sm"
          >
            {!editing && !loading && <PencilIcon className="w-4 mr-2" />}
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            {editing || loading ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
    </form>
  );
}
