export const currencyFormat = (number: number) => {
  return new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(number);
};

export const roundUp = (number: number) => {
  return Math.round(number - 0.2);
};
