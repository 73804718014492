import Heading from "../../../comps/heading";
import { Outlet, useRoutes, Link } from "react-router-dom";
import Users from "./users/users";
import { Button } from "flowbite-react";
import { useSearchStore } from "../../../store/searchStore";
import { useEffect } from "react";
import {
  UserGroupIcon,
  LockClosedIcon,
  BanknotesIcon,
  QueueListIcon,
  CubeIcon,
  FlagIcon,
  PhoneIcon,
  ListBulletIcon,
  SwatchIcon,
  // TagIcon,
  TruckIcon,
  ScaleIcon,
} from "@heroicons/react/24/solid";
import { useScheme } from "../../../store/schemeStore";
import Auths from "./auths/auths";
import Teams from "./teams/teams";
import UserDetail from "./users/userDetails/userDetail";
import ProcessRelated from "./process/processRelated";
import checkAuth from "../../../utils/checkAuth";
import Contact from "./contact/contact";
import PaymentRelated from "./payment/paymentRelated";
import Products from "./products/products";
import ProductionRelated from "./production/productionRelated";
import TaskRelated from "./tasks/taskRelated";
import OrderRelated from "./order/orderRelated";
import ProductSetEdit from "./order/productSet/productSetEdit";
import QuoteRelated from "./quote/quoteRelated";

const Setting = () => {
  return (
    <main className="px-6 pb-10 max-w-[1600px] mx-auto select-none">
      <Outlet />
    </main>
  );
};

const Routes = () => {
  const { scheme } = useScheme();
  const {
    searchString,
    setSearchFunction,
    clearSearchFunction: clearSearchFunctin,
  } = useSearchStore();

  useEffect(() => {
    setSearchFunction(() => {});
    return () => {
      clearSearchFunctin();
    };
  }, []);

  return (
    <>
      <Heading />
      <div className="flex flex-row text-grass mb-10">
        configuration and system settings
      </div>

      <section className="flex flex-row flex-wrap justify-center w-[768px] mt-5 max-w-full mx-auto">
        <nav className="w-[528px] max-w-full flex flex-col gap-4">
          {checkAuth("setting_users_access") && (
            <Link
              to="users"
              className={`${
                searchString !== "" &&
                !"users".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <UserGroupIcon className="w-5 mr-2" />
                USERS
              </Button>
            </Link>
          )}
          {checkAuth("setting_team_access") && (
            <Link
              to="teams"
              className={`${
                searchString !== "" &&
                !"teams".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <FlagIcon className="w-5 mr-2" />
                TEAMS
              </Button>
            </Link>
          )}
          {checkAuth("setting_auth_access") && (
            <Link
              to="auths"
              className={`${
                searchString !== "" &&
                !"role & permission".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <LockClosedIcon className="w-5 mr-2" />
                ROLE & PERMISSION
              </Button>
            </Link>
          )}
          {checkAuth("setting_process_access") && (
            <Link
              to="process"
              className={`${
                searchString !== "" &&
                !"process related".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <QueueListIcon className="w-5 mr-2" />
                PROCESS RELATED
              </Button>
            </Link>
          )}
          {checkAuth("setting_contact_access") && (
            <Link
              to="contact"
              className={`${
                searchString !== "" &&
                !"contact type".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <PhoneIcon className="w-5 mr-2" />
                CONTACT TYPE
              </Button>
            </Link>
          )}
          {checkAuth("setting_payment_access") && (
            <Link
              to="payment"
              className={`${
                searchString !== "" &&
                !"payment status".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <BanknotesIcon className="w-5 mr-2" />
                PAYMENT STATUS
              </Button>
            </Link>
          )}
          {checkAuth("setting_products_access") && (
            <Link
              to="products"
              className={`${
                searchString !== "" &&
                !"products".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <SwatchIcon className="w-5 mr-2" />
                PRODUCTS
              </Button>
            </Link>
          )}
          {checkAuth("setting_production_access") && (
            <Link
              to="production"
              className={`${
                searchString !== "" &&
                !"production status".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <TruckIcon className="w-5 mr-2" />
                PRODUCTION STATUS
              </Button>
            </Link>
          )}
          {checkAuth("setting_task_access") && (
            <Link
              to="task"
              className={`${
                searchString !== "" &&
                !"task status".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <ListBulletIcon className="w-5 mr-2" />
                TASK STATUS
              </Button>
            </Link>
          )}
          {checkAuth("setting_order_access") && (
            <Link
              to="order"
              className={`${
                searchString !== "" &&
                !"order".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <CubeIcon className="w-5 mr-2" />
                ORDER RELATED
              </Button>
            </Link>
          )}
          {checkAuth("setting_order_access") && (
            <Link
              to="quote"
              className={`${
                searchString !== "" &&
                !"quote".includes(searchString.toLowerCase()) &&
                "hidden"
              }`}
            >
              <Button className="w-full" color={`${scheme ? scheme : "light"}`}>
                <ScaleIcon className="w-5 mr-2" />
                QUOTE RELATED
              </Button>
            </Link>
          )}
        </nav>
      </section>
    </>
  );
};

export default function Settings() {
  const settingRoutes = useRoutes([
    {
      path: "/",
      element: <Setting />,
      children: [
        { path: "/", element: <Routes /> },
        { path: "users", element: <Users /> },
        { path: "users/:id", element: <UserDetail /> },
        { path: "auths", element: <Auths /> },
        { path: "teams", element: <Teams /> },
        { path: "process", element: <ProcessRelated /> },
        { path: "contact", element: <Contact /> },
        { path: "payment", element: <PaymentRelated /> },
        { path: "products", element: <Products /> },
        { path: "production", element: <ProductionRelated /> },
        { path: "task", element: <TaskRelated /> },
        { path: "order", element: <OrderRelated /> },
        { path: "quote", element: <QuoteRelated /> },
        { path: "order/productSet/:id", element: <ProductSetEdit /> },
      ],
    },
  ]);
  return settingRoutes;
}
