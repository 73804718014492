import { Select, Spinner, Table } from "flowbite-react";
import { UserType } from "./types";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { USER_CORE_FIELDS } from "./fragments";
import { useMutation, useQuery, gql } from "@apollo/client";
import { GET_TEAMS } from "../teams/teamList";
import { GET_AUTHS } from "../auths/roles/roles";
import { addAlert } from "../../../../store/alertStore";
import { HandRaisedIcon, UserPlusIcon } from "@heroicons/react/24/solid";

export const pendingkUserSchema = z.object({
  authID: z.string().min(1, { message: "Please select a role" }),
  teamID: z.string().optional(),
});

const APP_OR_DIS_USER = gql`
  ${USER_CORE_FIELDS}
  mutation APP_OR_DIS_USER(
    $id: Int!
    $authID: Int
    $teamID: Int
    $checked: Boolean!
    $active: Boolean!
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: {
        authID: $authID
        teamID: $teamID
        checked: $checked
        active: $active
      }
    ) {
      ...UserCoreFields
    }
  }
`;

export default function PendingUser({ user }: { user: UserType }) {
  const {
    data: team_data,
    loading: team_loading,
    error: team_error,
  } = useQuery(GET_TEAMS);

  if (team_error) {
    console.log(team_error);
    addAlert({ message: "cannot load teams", type: "failure" });
  }

  const teams = team_data ? team_data.teams : false;

  const {
    data: auth_data,
    loading: auth_loading,
    error: auth_error,
  } = useQuery(GET_AUTHS);

  if (auth_error) {
    console.log(auth_error);
    addAlert({ message: "cannot load auths", type: "failure" });
  }

  const roles = auth_data ? auth_data.auths : false;

  const { register, getValues } = useForm({
    resolver: zodResolver(pendingkUserSchema),
    defaultValues: {
      teamID: user.team?.id.toString() || "",
      authID: user.auth?.id.toString() || "",
    },
  });

  const [checkOutUser, { loading, error }] = useMutation(APP_OR_DIS_USER);

  if (error) {
    console.log(error);
    addAlert({ message: "action failed", type: "failure" });
  }

  const approve = () => {
    const { teamID, authID } = getValues();

    if (Number(authID) == 0) {
      return addAlert({ message: "Please select a role", type: "warning" });
    }

    const proceed = confirm("approve user?");
    if (!proceed) {
      return;
    }

    checkOutUser({
      variables: {
        checked: true,
        active: true,
        id: user.id,
        teamID: teamID ? Number(teamID) : undefined,
        authID: Number(authID),
      },
    });
  };

  const reject = () => {
    const proceed = confirm("reject user?");
    if (!proceed) {
      return;
    }

    checkOutUser({
      variables: {
        checked: true,
        active: false,
        id: user.id,
        authID: null,
      },
    });
  };

  return (
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100">
      <Table.Cell>
        {!team_loading && teams && (
          <Select sizing="sm" className="w-max" {...register("teamID")}>
            <option value="">No Team</option>
            {teams &&
              teams?.map(team => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
          </Select>
        )}
      </Table.Cell>
      <Table.Cell>
        {!auth_loading && roles && (
          <Select sizing="sm" className="w-max" {...register("authID")}>
            <option value="">Select Role</option>
            {roles &&
              roles
                ?.filter(
                  role => role.name !== "admin" && role.name !== "accountant"
                )
                .map(role => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
          </Select>
        )}
      </Table.Cell>
      <Table.Cell>
        {user.firstName} {user.sirName}
      </Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{new Date(user.created_at).toLocaleDateString()}</Table.Cell>
      <Table.Cell className="flex flex-row gap-3 items-center ">
        {loading ? (
          <Spinner className="mt-2" color="purple" />
        ) : (
          <>
            <UserPlusIcon
              onClick={approve}
              className="cursor-pointer w-5 mt-2 text-plum"
            />
            <HandRaisedIcon
              onClick={reject}
              className="cursor-pointer w-5 mt-2 text-red-500"
            />
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
