import { Label, Select, TextInput } from "flowbite-react";
import { useFormContext } from "react-hook-form";
import { ProcessStatusType } from "../../settings/process/processStatuses/types";
import { sort } from "fast-sort";
import getContrast from "../../../../utils/getContrast";

export default function Status({
  statuses,
}: {
  statuses: ProcessStatusType[];
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const sortedStatuses = sort(statuses).asc("priority");

  return (
    <section className="space-y-2 mt-2">
      {/* status, from, due */}
      <h2>Status & due</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4  ">
        <div className="space-y-2 shadow-md bg-white dark:bg-gray-800 rounded-md p-4 col-span-1 z-20">
          <Label value="Status" />
          <Select
            sizing="sm"
            {...register("status")}
            helperText={errors.status?.message?.toString() || ""}
            color={errors.status?.message ? "failure" : undefined}
          >
            <option value={0}>Choose Status</option>
            {sortedStatuses.map(status => (
              <option
                style={{
                  backgroundColor: status.color,
                  color: getContrast(status.color),
                }}
                className="capitalize"
                key={status.id}
                value={status.id}
              >
                {status.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="space-y-2 shadow-md bg-white dark:bg-gray-800 rounded-md p-4 col-span-1 z-20">
          <Label value="From" />
          <TextInput
            type="date"
            sizing="sm"
            {...register("from")}
            helperText={errors.from?.message?.toString() || ""}
            color={errors.from?.message ? "failure" : undefined}
          />
        </div>
        <div className="space-y-2 shadow-md bg-white dark:bg-gray-800 rounded-md p-4 col-span-1 z-20">
          <Label value="Due" />
          <TextInput
            type="date"
            sizing="sm"
            {...register("due")}
            helperText={errors.due?.message?.toString() || ""}
            color={errors.due?.message ? "failure" : undefined}
          />
        </div>
      </div>
    </section>
  );
}
