import { ToggleSwitch } from "flowbite-react";
import { Dispatch, SetStateAction, useState } from "react";
import { ProcessFilter } from "./types";
import FilterArrayType from "./arrayFilter";
import FilterRangeType from "./rangeFilter";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

interface filterProps {
  filter: ProcessFilter;
  newFilters: ProcessFilter[];
  setNewFilters: Dispatch<SetStateAction<ProcessFilter[]>>;
  setTouched: (bool: boolean) => void;
}

const Filter = ({
  filter,
  newFilters,
  setNewFilters,
  setTouched,
}: filterProps) => {
  const [seeAll, setSeeAll] = useState(false);

  const toggleSeeAll = () => {
    setSeeAll(!seeAll);
  };

  const setNew = (revisedFilter: ProcessFilter) => {
    setTouched(true);
    setNewFilters(
      newFilters.map(f => {
        if (f.name == filter.name) {
          return revisedFilter;
        } else {
          return f;
        }
      })
    );
  };

  const toggle = () => {
    if (filter.required) {
      return;
    }
    setNew({
      ...filter,
      on: !filter.on,
    });
  };

  return (
    <div
      className={`flex flex-col flex-wrap ${
        filter.on ? "min-w-[250px] max-w-[315px] flex-1" : ""
      }`}
    >
      <div
        className={`flex flex-row text-sm items-center gap-2 ${
          filter.on ? "justify-between" : ""
        }`}
      >
        {filter.name}
        {filter.on && filter.canShowAll && (
          <div onClick={toggleSeeAll} className="cursor-pointer">
            {seeAll ? (
              <EyeIcon className="w-4" />
            ) : (
              <EyeSlashIcon className="w-4" />
            )}
          </div>
        )}
        {!filter.required && (
          <ToggleSwitch
            checked={filter.on}
            //@ts-expect-error
            color="purple"
            label=""
            onChange={toggle}
            className="mr-[-10px]"
          />
        )}
      </div>
      <div className={`${filter.on ? "" : "hidden"}`}>
        {(filter.type == "contains" || filter.type == "in") && (
          <FilterArrayType
            filter={filter}
            setNew={setNew}
            filters={newFilters}
            seeAll={seeAll}
          />
        )}
        {filter.type == "range" && (
          <FilterRangeType filter={filter} setNew={setNew} />
        )}
      </div>
    </div>
  );
};

export default Filter;
