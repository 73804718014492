import {
  ArchiveBoxXMarkIcon,
  CloudArrowUpIcon,
  PaperAirplaneIcon,
  PrinterIcon,
  SquaresPlusIcon,
  TruckIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useMutation } from "@apollo/client";
import { DELETE_ORDER } from "../../gqls";
import { OrderType } from "../../types";
import checkAuth from "../../../../../utils/checkAuth";
import { useNavigate } from "react-router-dom";
import {
  REMOVE_PROCESS,
  UPDATE_PROCESS_BY_PK,
} from "../../../process/processDetail/gqls";
import OrderRelease from "../../orderRelease/orderRelease";
import { Tooltip } from "flowbite-react";

interface props {
  processId?: string;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  dbOrder?: OrderType;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  saveForm: () => Promise<void>;
}

const Actions = ({
  processId,
  setModal,
  dbOrder,
  setPage,
  saveForm,
}: props) => {
  // const { getValues } = useFormContext();
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const print = () => {
    setPage("printing");
    setOpen(false);
  };

  const [remove, { loading: deleting }] = useMutation(DELETE_ORDER);

  const navigate = useNavigate();

  const _delete = () => {
    remove({
      variables: { id: dbOrder?.id },
      update(cache, { data: { delete_workorder_by_pk: deleted } }) {
        const normalizedId = cache.identify({
          id: deleted.id,
          __typename: "workorder",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onCompleted() {
        navigate("/order");
      },
    });
  };

  // const [update_order] = useMutation(UPDATE_ORDER_BY_PK);

  const [remove_process] = useMutation(REMOVE_PROCESS);
  const [update_process] = useMutation(UPDATE_PROCESS_BY_PK);
  const _deleteProcess = () => {
    update_process({
      variables: {
        id: dbOrder?.process?.id,
        set: {
          deleted: true,
        },
      },
      onError(error) {
        console.log(error);
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: dbOrder?.process?.id,
          __typename: "process",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
        remove_process({
          variables: { id: dbOrder?.process?.id },
        });
        navigate("/order");
      },
    });
  };

  const withdraw = () => {
    const proceed = confirm(`Withdraw ${dbOrder?.type.name || "proMeasure"}?`);
    if (!proceed) {
      return;
    }

    const process = dbOrder?.process;
    if (process && process.processType.id == 4) {
      _deleteProcess();
    } else {
      _delete();
    }
  };

  const [releasing, setReleasing] = useState(false);

  const releasableTypes = ["order", "workorder"];

  const toggleRelease = () => {
    setReleasing(!releasing);
  };

  const save = () => {
    saveForm();
    setOpen(false);
  };

  return (
    <>
      {createPortal(
        <div className={`absolute right-4 p-4 bottom-8 transition-all`}>
          <div className="relative">
            <div
              onClick={toggleOpen}
              className={`p-2 bg-plum rounded-full shadow-md cursor-pointer hover:animate-pulse transition-all ${
                open && "-rotate-90"
              }`}
            >
              <SquaresPlusIcon className="w-6 text-white" />
            </div>

            <div
              className={`flex flex-row gap-2 items-center absolute right-2 top-[2px] transition-all  ${
                open
                  ? "-translate-x-10 opacity-100"
                  : "opacity-0 pointer-events-none"
              } `}
            >
              {/* WithDraw */}
              {dbOrder && dbOrder.status.id < 3 && (
                <div
                  onClick={withdraw}
                  className={`right-0 top-[2px] transition-all p-2 bg-red-500 rounded-full shadow-md cursor-pointer flex flex-row gap-2 items-center text-white`}
                >
                  <ArchiveBoxXMarkIcon className="w-5" />
                </div>
              )}
              {processId && (
                <div
                  onClick={save}
                  className={`right-0 top-[2px] transition-all p-2 bg-teal-500 rounded-full shadow-md cursor-pointer flex flex-row gap-2 items-center text-white`}
                >
                  <Tooltip content="Save">
                    <CloudArrowUpIcon className="w-5" />
                  </Tooltip>
                </div>
              )}
              {/* Print */}
              <div
                onClick={print}
                className={`right-0 top-[2px] transition-all py-2 pl-2 pr-3 bg-blue-500 rounded-full shadow-md cursor-pointer flex flex-row gap-2 items-center text-white`}
              >
                <PrinterIcon className="w-5" />
                Print
              </div>

              {/* Release */}
              {checkAuth("order_release") &&
                dbOrder &&
                releasableTypes.includes(dbOrder.type.name) && (
                  <div
                    onClick={toggleRelease}
                    className={`right-0 top-[2px] transition-all py-2 pl-2 pr-3 bg-plum rounded-full shadow-md cursor-pointer flex flex-row gap-2 items-center text-white`}
                  >
                    <TruckIcon className="w-5" />
                    Release
                  </div>
                )}
              {/* Submit */}
              {!dbOrder?.process && (
                <div
                  onClick={() => {
                    setModal(true);
                    setOpen(false);
                  }}
                  className={`right-0 top-[2px] transition-all py-2 pl-2 pr-3 bg-grass rounded-full shadow-md cursor-pointer flex flex-row gap-2 items-center text-white`}
                >
                  <PaperAirplaneIcon className="w-5" />
                  Submit
                </div>
              )}
              {/* Confirm Order */}
              {dbOrder?.status.id == 1 &&
                checkAuth("order_can_confirm") &&
                dbOrder.type.name == "order" && (
                  <div
                    onClick={() => {
                      setPage("orderConfirm");
                      setOpen(false);
                    }}
                    className={`right-0 top-[2px] transition-all py-2 pl-2 pr-3 bg-grass rounded-full shadow-md cursor-pointer flex flex-row gap-2 items-center text-white`}
                  >
                    <PaperAirplaneIcon className="w-5" />
                    Confirm
                  </div>
                )}
            </div>
          </div>
        </div>,
        document.body
      )}

      {dbOrder && releasing && (
        <OrderRelease
          onClose={() => {
            setReleasing(false);
          }}
          orderId={dbOrder?.id}
          show={releasing}
        />
      )}
    </>
  );
};

export default Actions;
