import { Button, Spinner } from "flowbite-react";
import Heading from "../../../comps/heading";
import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { sort } from "fast-sort";
import { useEffect, useState } from "react";
import {
  assignmentQueryParams,
  AssignmentStreamType,
  AssignmentType,
} from "./types";
import { ASSIGNMENT_ALL_FIELDS } from "./fragments";
import { useNavigate } from "react-router-dom";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import { cloneDeep } from "@apollo/client/utilities";
import { useAssignmentStore } from "../../../store/assignmentStore";
import Filters from "./filters";
import AssignmentList from "./assignmentList";

interface assignmentQueryDocument {
  assignment: AssignmentType[];
  assignment_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

export const GET_ASSIGNMENT: TypedDocumentNode<assignmentQueryDocument> = gql`
  ${ASSIGNMENT_ALL_FIELDS}
  query GET_ASSIGNMENT($where: assignment_bool_exp, $limit: Int, $offset: Int) {
    assignment(
      where: $where
      limit: $limit
      offset: $offset
      order_by: {
        type: { priority: asc }
        status: { priority: asc }
        process: { name: asc }
      }
    ) {
      ...AssignmentAllFields
    }
    assignment_aggregate(
      where: $where
      order_by: {
        type: { priority: asc }
        status: { priority: asc }
        process: { name: asc }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const SUB_ASSIGNMENT: TypedDocumentNode<AssignmentStreamType> = gql`
  ${ASSIGNMENT_ALL_FIELDS}
  subscription SUB_ASSIGNMENT(
    $cursor: [assignment_stream_cursor_input]!
    $where: assignment_bool_exp
  ) {
    assignment_stream(where: $where, cursor: $cursor, batch_size: 10) {
      ...AssignmentAllFields
    }
  }
`;

interface props {
  filters: assignmentQueryParams;
}

export default function AssignmentLoader({ filters }: props) {
  const [totalLength, setTotalLength] = useState(0);

  const [limit] = useState(100);
  const { cursor, setCursor } = useAssignmentStore();
  const [filtering, setFiltering] = useState(false);
  const toggleFiltering = () => {
    setFiltering(!filtering);
  };

  const { data, loading, error, subscribeToMore, fetchMore } = useQuery(
    GET_ASSIGNMENT,
    {
      variables: { ...filters, limit },
      onCompleted(data) {
        setCursor(new Date().toISOString());
        setTotalLength(data.assignment_aggregate?.aggregate?.count || 0);
      },
    }
  );

  const ids = data?.assignment.map(c => c.id);

  useEffect(() => {
    const subFilter = {
      _or: [{ id: { _in: ids || [] } }],
    };

    const unsubscribe = subscribeToMore({
      document: SUB_ASSIGNMENT,
      variables: {
        where: subFilter,
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.assignment);
        const newFeedItem = subscriptionData.data.assignment_stream;
        const existing = previousData.filter(
          p => !newFeedItem.find(i => i.id == p.id)
        );
        // const filterDeleted = [...newFeedItem, ...existing].filter(
        //   p => !p.deleted
        // );
        return Object.assign({}, previous, {
          assignment: [...newFeedItem, ...existing],
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [filters.where._and, ids]);

  const assignment =
    data?.assignment &&
    sort(data.assignment).by([
      { asc: t => t.type.priority },
      { asc: t => t.status.priority },
      { asc: t => t.process.name },
    ]);

  if (error) {
    console.log(error);
  }

  const loadMore = () => {
    const currentLength = data?.assignment.length || 0;
    fetchMore({
      variables: {
        ...filters,
        offset: currentLength,
        limit,
      },
    });
  };

  const navigate = useNavigate();
  const goToAdd = () => {
    navigate("./add");
  };

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6">
        <div className="flex flew-row justify-between h-8">
          <div className="flex flex-row items-center gap-2">
            <Heading />
          </div>

          {/* <Button
            size="sm"
            outline
            gradientDuoTone="purpleToBlue"
            onClick={goToAdd}
          >
            ADD
          </Button> */}
        </div>
        <div className="flex flex-row text-grass mb-10">
          process assignments
        </div>

        {/* Actions */}
        <div className="flex flex-row gap-2 flex-wrap justify-between items-center h-8">
          <div
            onClick={toggleFiltering}
            className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
          >
            <AdjustmentsHorizontalIcon className="w-4" />
            Filters
          </div>
          <div className="flex flex-row justify-end items-center gap-2 flex-1 h-8">
            {loading && <Spinner light color="purple" />}
            <div className="text-sm">
              {data?.assignment.length}
              <span className="dark:text-grass text-plum"> of </span>
              {totalLength < (data?.assignment.length || 0)
                ? data?.assignment.length
                : totalLength}
            </div>
            {data && data?.assignment.length < totalLength && (
              <Button size="xs" onClick={loadMore} color="purple" outline>
                more
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* Filter */}
      <Filters filtering={filtering} />
      {/* Content Part */}
      <section className="mt-2">
        {assignment && <AssignmentList assignment={assignment} />}

        {/* {data && data?.assignment.length < totalLength && (
          <div className="flex flex-row justify-end mt-2">
            <Button size="xs" onClick={loadMore} color="purple" outline>
              more
            </Button>
          </div>
        )} */}
      </section>
    </main>
  );
}
