import { useRoutes } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import Container from "./container";
import Recover from "./recover";
import ResetPassword from "./resetPassword";

export default function PublicRoot() {
  let routes = useRoutes([
    {
      path: "/",
      element: <Container />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "*",
          element: <Login />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/register/:type?",
          element: <Register />,
        },
        {
          path: "/recover",
          element: <Recover />,
        },
        {
          path: "/recover/:token/:email",
          element: <ResetPassword />,
        },
      ],
    },
  ]);

  return routes;
}
