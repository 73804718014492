import {
  Button,
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { useScheme } from "../../../../../store/schemeStore";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { coerceNumber } from "../../../process/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../../products/products";
import { useOrderStore } from "../../../../../store/orderStore";
import { ADD_PRODUCT_SETS } from "../../../workorder/gqls";
import { PRODUCT_SET_CORE_FIELDS } from "../../../workorder/fragments";

interface props {
  setFalse: () => void;
  newPosition: number;
}

interface newProductSetType {
  name: string;
  typeId: undefined | number; //productId
  hideItems: boolean | number;
}

const newProductSetScheme = z.object({
  name: z.string(),
  typeId: coerceNumber,
  hideItems: coerceNumber,
});

export default function NewProductSet({ setFalse, newPosition }: props) {
  const { scheme } = useScheme();

  const { productSets } = useOrderStore();
  const { data } = useQuery(GET_PRODUCTS);
  const products = data?.products;

  const availableProducts =
    products?.filter(
      p => productSets.find(ps => ps.product.id === p.id) === undefined
    ) || [];

  const defaultValues: newProductSetType = {
    name: "",
    typeId: undefined,
    hideItems: 0,
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: zodResolver(newProductSetScheme),
  });

  const [insert, { loading }] = useMutation(ADD_PRODUCT_SETS);

  const onClose = () => {
    reset();
    setFalse();
  };

  const onSubmit = handleSubmit(data => {
    insert({
      variables: {
        object: {
          name: data.name,
          typeId: data.typeId,
          hideItems: data.hideItems ? true : false,
          position: newPosition,
        },
      },
      onCompleted() {
        onClose();
      },
      update(cache, { data: { insert_productSets_one: newProductSet } }) {
        cache.modify({
          fields: {
            productSets(existing = []) {
              const newPSRef = cache.writeFragment({
                data: newProductSet,
                fragment: PRODUCT_SET_CORE_FIELDS,
                fragmentName: "ProductSetCoreFields",
              });
              return [...existing, newPSRef];
            },
          },
        });
      },
    });
  });

  return (
    <Modal
      show={true}
      onClose={onClose}
      className={`${scheme} !h-full !items-start`}
    >
      <form onSubmit={onSubmit}>
        <Modal.Header className={`${scheme == "dark" && "bg-gray-800"}`}>
          Add New Product Set
        </Modal.Header>
        <Modal.Body className={`${scheme == "dark" && "bg-gray-800"}`}>
          <div className="flex flex-col gap-2">
            <Label value="Name" />
            <TextInput {...register("name")} placeholder="enter brand name" />
            <Label value="Associated Product" />
            <Select {...register("typeId")}>
              {availableProducts.map(p => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
            </Select>
            <Label value="Entry Type" />
            <Select {...register("hideItems")}>
              <option value={0}>Item</option>
              <option value={1}>Opening</option>
            </Select>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`${scheme == "dark" && "bg-gray-800"} justify-end gap-2`}
        >
          <Button size="sm" color="purple" onClick={onClose}>
            Cancel
          </Button>
          <Button
            outline
            size="sm"
            gradientDuoTone="purpleToBlue"
            type="submit"
          >
            <div className="flex flex-row gap-2 items-center">
              {loading && <Spinner size="sm" color="purple" />}
              Add
            </div>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
