import { useMutation } from "@apollo/client";
import { Button, Card, Spinner } from "flowbite-react";
import { UPDATE_QUOTE_PARAM_BY_PK } from "../gqls";
import { QuoteParams } from "../types";
import { useForm } from "react-hook-form";

interface props {
  defaultMarginParam?: QuoteParams;
}
export default function DefaultMarginControl({ defaultMarginParam }: props) {
  const [update, { loading: updating }] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);

  const defaultValues = {
    margin: defaultMarginParam?.value || 0,
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const submit = handleSubmit(data => {
    if (defaultMarginParam) {
      update({
        variables: {
          id: defaultMarginParam.id,
          set: {
            value: Number(data.margin),
          },
        },
        onCompleted() {
          reset({
            margin: data.margin,
          });
        },
      });
    }
  });

  const cancel = () => {
    reset();
  };

  return (
    <Card className={`col-span-1 relative`}>
      <div className="flex flex-col min-h-[150px] ">
        <div className="flex flex-row items-center font-bold text-4xl">
          <input
            type="number"
            className="text-plum font-bold text-4xl bg-transparent w-16 text-left border-none outline-none px-0"
            {...register("margin")}
          />
          %
        </div>
        <h2>Default Margin</h2>
      </div>
      {isDirty && (
        <div className="absolute bottom-2 right-2 flex flex-row gap-2 items-center justify-end">
          <Button size="sm" color="purple" onClick={cancel}>
            cancel
          </Button>
          <Button
            size="sm"
            outline
            gradientDuoTone={"purpleToBlue"}
            onClick={submit}
          >
            update
            {updating && <Spinner size="sm" className="ml-1" color="purple" />}
          </Button>
        </div>
      )}
    </Card>
  );
}
