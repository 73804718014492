import { ListGroup } from "flowbite-react";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import isNullish from "../utils/isNullish";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { sort } from "fast-sort";
import { useScheme } from "../store/schemeStore";

interface props {
  disabled?: boolean;
  options?: {
    value: string | number;
    name: string;
  }[];
  value: string | number;
  onChange: (value: string) => void;
  defaultValue?: string | number;
  placeholder?: string;
}

export default function SearchSelect({
  disabled,
  options,
  value,
  onChange,
  defaultValue,
  placeholder,
}: props) {
  const [search, setSearch] = useState("");

  const [open, setOpen] = useState(false);

  const { scheme } = useScheme();

  const toggleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(!open);
  };
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  if (!options) {
    return null;
  }

  const finalOptions = options.filter(
    o =>
      isNullish(search.trim()) ||
      o.name.toLowerCase().includes(search.trim().toLowerCase())
  );

  const selected = options.find(
    o =>
      o.value.toString() == value?.toString() ||
      o.value.toString() == defaultValue?.toString()
  );

  const maxCharacterLength =
    sort(options).by([
      {
        desc: o => o.name.length,
      },
    ])[0]?.name.length || 10;

  return (
    <div className={`relative ${scheme}`} ref={ref}>
      <ListGroup
        className={`bg-gray-50 border-gray-300 ${
          disabled ? "brightness-90 dark:brightness-75" : ""
        }`}
        style={{
          minWidth: `${maxCharacterLength * 7.5}px`,
        }}
        onClick={toggleOpen}
      >
        <ListGroup.Item>
          <div className="text-xs">{selected?.name || placeholder || ""}</div>
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 flex flex-col gap-1 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
          {options.length > 4 && (
            <div className="relative flex flex-row gap-1 items-center rounded-t-md p-2 backdrop-brightness-90 dark:backdrop-brightness-75">
              {search.trim() == "" && (
                <MagnifyingGlassIcon className="w-4 absolute left-4 text-gray-700 dark:text-gray-500" />
              )}
              <input
                value={search}
                onChange={e => {
                  setSearch(e.target.value);
                }}
                autoFocus
                className="bg-white dark:bg-gray-100 w-full rounded-md px-2 py-1 dark:text-dark text-xs"
              />
            </div>
          )}
          {finalOptions.map(option => {
            return (
              <div
                onClick={() => {
                  onChange(option.value.toString());
                  setOpen(false);
                }}
                key={option.value}
                className="group/preset relative"
              >
                <div className="rounded-md px-4 py-2 cursor-pointer bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                  {option.name}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
