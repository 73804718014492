import { create } from "zustand";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  AssignmentFilter,
  AssignmentStatType,
  assignmentQueryParams,
} from "../routes/protected/assignment/types";
import {
  GET_ASSIGNMENT_STATUS,
  GET_ASSIGNMENT_TYPE,
} from "../routes/protected/assignment/gqls";
import { User } from "./authStore";

type Store = {
  filters: AssignmentFilter[];
  setFilters: (filters: AssignmentFilter[]) => void;
  cursor: string;
  setCursor: (str: string) => void;
};

export const useAssignmentStore = create<Store>(set => ({
  filters: [],
  setFilters: filters => {
    if (filters.length == 0) {
      localStorage.removeItem("assignmentFilters");
    }
    localStorage.setItem("assignmentFilters", JSON.stringify(filters));
    return set(state => ({
      ...state,
      filters,
    }));
  },
  cursor: new Date().toISOString(),
  setCursor(cursor: string) {
    return set(state => ({
      ...state,
      cursor,
    }));
  },
}));

export const useDefaultFilters = () => {
  const [types, setTypes] = useState<undefined | AssignmentStatType[]>();
  const [status, setStatus] = useState<undefined | AssignmentStatType[]>();

  useQuery(GET_ASSIGNMENT_TYPE, {
    onCompleted(data) {
      setTypes(data.assignmentType);
    },
  });

  useQuery(GET_ASSIGNMENT_STATUS, {
    onCompleted(data) {
      setStatus(data.assignmentStatus);
    },
  });

  const { setFilters } = useAssignmentStore();

  const savedFilters = localStorage.getItem("assignmentFilters");

  useEffect(() => {
    if (savedFilters && savedFilters.length > 0) {
      setFilters(JSON.parse(savedFilters));
      return;
    }

    if (types && status) {
      const defaultFilters: AssignmentFilter[] = [];

      // Types
      const options_type = types?.map(t => ({
        name: t.name,
        value: t.id,
      }));

      const defaultValues_type = types?.map(t => t.id);

      const typeFilter: AssignmentFilter = {
        name: "Type",
        where: "typeId",
        type: "in",
        values: defaultValues_type,
        defaultValues: defaultValues_type,
        options: options_type,
      };
      defaultFilters.push(typeFilter);

      // Status
      const options_status = status?.map(t => ({
        name: t.name,
        value: t.id,
      }));

      const defaultValues_status = status?.map(t => t.id);

      const statusFilter: AssignmentFilter = {
        name: "Status",
        where: "statusId",
        type: "in",
        values: defaultValues_status,
        defaultValues: defaultValues_status,
        options: options_status,
      };
      defaultFilters.push(statusFilter);

      setFilters(defaultFilters);
    }
  }, [types, status]);
};

export const refineFilters: (
  filters: AssignmentFilter[],
  user?: User
) => assignmentQueryParams = (filters: AssignmentFilter[], user) => {
  if (!filters) {
    return {
      where: { _and: [] },
      offset: 0,
    };
  }

  const where: any = { _and: [] };
  const order_by: object[] = [];

  for (const filter of filters) {
    // Filter
    switch (filter.type) {
      case "in":
        where._and.push({ [filter.where]: { _in: filter.values } });
        break;
      case "contains":
        where._and.push({
          _or: [
            { [filter.where]: { _contains: filter.values } },
            { [filter.where]: { _contained_in: filter.values } },
          ],
        });
        break;
      case "range":
        const { from, to } = filter.values;
        if (from && to) {
          where._and.push({
            [filter.where]: {
              _gte: filter.values.from,
              _lte: filter.values.to,
            },
          });
        }
        break;
    }
  }

  if (user) {
    const userSubTeams = user.team?.subTeams || [];
    where._and.push({
      _or: [
        {
          process: {
            createdUser: { teamID: { _in: [...userSubTeams, user.team?.id] } },
          },
        },
        {
          userId: { _eq: user.id },
        },
      ],
    });
  }

  return {
    where: where,
    order_by: order_by,
    offset: 0,
  };
};
