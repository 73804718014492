import { useQuery } from "@apollo/client";
import { GET_USERS_CONDITIONAL } from "../../users/gql";
import { useState } from "react";
import { UserType } from "../../users/types";
import DealerFactors from "./dealerFactors";

export default function DealerPriceFactors() {
  const { data, loading } = useQuery(GET_USERS_CONDITIONAL, {
    variables: {
      where: {
        team: {
          type: {
            id: { _eq: 4 },
          },
        },
        active: { _eq: true },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const dealers = data?.users || [];

  return (
    <div className="flex flex-col gap-2">
      {dealers.map(dealer => (
        <DealerFactors key={dealer.id} dealer={dealer} />
      ))}
    </div>
  );
}
