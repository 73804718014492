import { useEffect, useState } from "react";
import { DeductionType } from "../../../../types";
import getDimensions from "../../../../../../../utils/getDimensions";
import { evaluate } from "mathjs";
import { roundUp } from "../../../../../../../utils/numberMethods";

interface layoutImageProps {
  layoutDeduction: DeductionType;
  openingScopes: any;
}

export default function LayoutImage({
  layoutDeduction,
  openingScopes,
}: layoutImageProps) {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!layoutDeduction.image) {
      return;
    }
    getDimensions(layoutDeduction.image).then(d => {
      setSize(d);
    });
  }, [layoutDeduction.image]);

  return (
    <div className="relative mx-10 text-center">
      <img src={layoutDeduction.image} />
      {layoutDeduction.imageTexts?.map((it, i) => {
        let name: number | string = "";

        try {
          const res = evaluate(it.scope, openingScopes);
          name = res;
          if (!isNaN(Number(name))) {
            name = roundUp(Number(name));
          }
        } catch (error) {
          // console.log(error);
        }
        return (
          <div
            key={i}
            className={`absolute ${
              Number(name) > 900 && "text-red-500 animate-pulse"
            }`}
            style={{
              top: ((it.coord.y / size.height) * 100).toFixed(2) + "%",
              left: ((it.coord.x / size.width) * 100).toFixed(2) + "%",
              rotate: `${it.rotation}deg`,
            }}
          >
            {name.toString()}
          </div>
        );
      })}
    </div>
  );
}
