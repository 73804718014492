import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Modal, Button, Label, Spinner, TextInput } from "flowbite-react";
import { useScheme } from "../../../../../store/schemeStore";
import checkAuth from "../../../../../utils/checkAuth";
import { useMutation, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";
import { contactTypeSchema } from "./types";
import { CONTACT_TYPE_CORE_FIELDS } from "./fragments";
import { ContactTypeType } from "./types";
import { useState } from "react";
import PathEdit from "./pathEdit";

export const UPDATE_CONTACT_TYPE = gql`
  ${CONTACT_TYPE_CORE_FIELDS}
  mutation UPDATE_CONTACT_TYPE($id: Int!, $name: String!, $color: String!) {
    update_contactType_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, color: $color }
    ) {
      ...ContactTypeCoreFields
    }
  }
`;

export const DELETE_CONTACT_TYPE = gql`
  ${CONTACT_TYPE_CORE_FIELDS}
  mutation DELETE_CONTACT_TYPE($id: Int!) {
    delete_contactType_by_pk(id: $id) {
      id
    }
  }
`;

export default function ContactType({ ct }: { ct: ContactTypeType }) {
  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow(!show);
  };

  const { scheme } = useScheme();

  const [update_contact_type, { loading, error }] =
    useMutation(UPDATE_CONTACT_TYPE);
  if (error) {
    console.log(error);
    addAlert({ message: "could not update type", type: "failure" });
  }

  const [
    delete_contact_type,
    { error: delete_error, loading: delete_loading },
  ] = useMutation(DELETE_CONTACT_TYPE);
  if (delete_error) {
    console.log(delete_error);
    addAlert({ message: "could not delete type", type: "failure" });
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(contactTypeSchema),
    defaultValues: {
      name: ct.name,
      color: ct.color,
    },
  });

  const onSubmit = handleSubmit(async data => {
    update_contact_type({
      variables: { id: ct.id, ...data },
      onCompleted(data) {
        if (data.update_contactType_by_pk) {
          const { color, name } = data.update_contactType_by_pk;
          reset({
            color,
            name,
          });

          toggleModal();
          addAlert({ message: "type updated successfully", type: "success" });
        }
      },
    });
  });

  const deleteType = () => {
    const proceed = confirm("Delete Type?");
    if (proceed) {
      delete_contact_type({
        variables: { id: ct.id },
        update(cache, { data: { delete_contactType_by_pk: deletedPT } }) {
          const normalizedId = cache.identify({
            id: deletedPT.id,
            __typename: "contactType",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
        onCompleted(data) {
          if (data.delete_contactType_by_pk) {
            toggleModal();
          }
        },
      });
    }
  };

  const cancel = () => {
    reset();
    toggleModal();
  };

  const editAuth = checkAuth("setting_contact_edit");

  return (
    <>
      <div className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <div
            className="rounded-full ring-1 w-4 h-4 ring-black dark:ring-white"
            style={{
              backgroundColor: `${ct?.color ? ct.color : "#CCCCCC"}`,
            }}
          />
          <h3>{ct.name}</h3>
        </div>
        {editAuth && (
          <>
            <PencilIcon
              className="w-4 mt-1 cursor-pointer"
              onClick={toggleModal}
            />
          </>
        )}
      </div>
      <Modal show={show} onClose={toggleModal} className={`${scheme}`}>
        <Modal.Header>Edit Contact Type</Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} className="space-y-3">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Name" />
              </div>
              <TextInput
                type="text"
                placeholder="type name"
                color={errors.name?.message ? "failure" : undefined}
                helperText={errors.name?.message || ""}
                {...register("name")}
              />
            </div>

            <div>
              <Label htmlFor="color" value="Color" />
              <input
                type="color"
                {...register("color")}
                className="w-full h-10 border-2 p-0 bg-transparent border-none"
              />
              <div> {errors.color?.message || ""}</div>
            </div>
            {/* Action Buttons */}
            <div className="flex flex-row justify-end gap-2 items-center">
              {delete_loading && <Spinner size="sm" light={true} />}
              <TrashIcon
                className="w-5 text-red-500 cursor-pointer"
                onClick={deleteType}
              />
              <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
                Cancel
              </Button>
              <Button
                outline
                gradientDuoTone="purpleToBlue"
                size="sm"
                type="submit"
              >
                {loading && (
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                )}
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
