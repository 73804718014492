import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useOrderStore } from "../../../../../../store/orderStore";
import { OrderType } from "../../../types";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Card, Checkbox } from "flowbite-react";
import { location, salesParams } from "../../types";
import ProductQuoteParams from "./productQuoteParams";
import { useEffect } from "react";
import { useQuoteStore } from "../../../../../../store/quoteStore";

interface props {
  dbOrder?: OrderType;
  setPage: (page: string) => void;
}
export default function QuoteParams({ dbOrder, setPage }: props) {
  const { productSets } = useOrderStore();
  const { quoteParams } = useQuoteStore();

  const { control, register, setValue, getValues, reset } = useFormContext();
  const close = () => {
    setPage("ordering");
  };

  const locations: location[] = useWatch({
    name: "locations",
    control,
    defaultValue: [],
  });

  const openings = locations.map(l => l.openings).flat();

  const currentProducts = openings?.reduce<
    { id: number; name: string; qty: number; openingProduct: boolean }[]
  >((prev, cur) => {
    const Product = productSets?.find(p => p.id == cur.product);
    if (!Product) {
      return prev;
    }

    const name = Product?.name;
    const isOpeningProduct = Product.hideItems;
    const qty = isOpeningProduct ? 1 : cur.items.length;

    if (prev.find(p => p.name == name)) {
      return prev.map(pro => {
        if (pro.name == name) {
          return { ...pro, qty: pro.qty + qty };
        } else return pro;
      });
    } else {
      return prev.concat({
        id: Product.id,
        name,
        qty,
        openingProduct: Product.hideItems,
      });
    }
  }, []);

  const totalDiscountType = useWatch({
    name: `salesParams.totalDiscount.type`,
    control,
    defaultValue: "rate",
  });

  const { fields, append } = useFieldArray({
    name: "salesParams.productParams",
    control,
  });

  const defaultMinInstall = quoteParams.find(
    param => param.name == "minInstallCharge"
  )?.value;

  const defaultMeasureCharge = quoteParams.find(
    param => param.name == "measureCharge"
  )?.value;

  useEffect(() => {
    const productSalesParams: salesParams["productParams"] = getValues(
      "salesParams.productParams"
    );
    const absentProducts = currentProducts
      .map(productSet => {
        if (!productSalesParams?.find(p => p.productSetId == productSet.id)) {
          const exMarginParam = quoteParams.find(
            p => p.name == "margin" && p.productSetId == productSet.id
          );
          const exSqmParam = quoteParams.find(
            p => p.name == "sqmPrice" && p.productSetId == productSet.id
          );
          const exInstallParam = quoteParams.find(
            p => p.name == "install" && p.productSetId == productSet.id
          );
          const defaultMarginParam = quoteParams.find(
            p => p.name == "margin" && !p.productSetId
          );
          return {
            productSetId: productSet.id,
            baseInstall: exInstallParam?.value,
            margin: Number(exMarginParam?.value?.retail || 0),
            discount: {
              type: "rate",
              value: 0,
            },
            sqm: Number(exSqmParam?.value?.retail || 0),
          };
        } else {
          return null;
        }
      })
      .filter(cp => cp !== null);
    if (absentProducts.length > 0) {
      append(absentProducts);
    }
  }, [currentProducts]);

  return (
    <div className="flex flex-col gap-2">
      <div className="pl-1 flex flex-row justify-between items-center mt-2">
        <h3>Quote Params</h3>
        <XMarkIcon className="w-6 cursor-pointer" onClick={close} />
      </div>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 supports-[not(container-type:inline-size)]:md:grid-cols-2 gap-4 mt-2`}
      >
        <Card className="col-span-1">
          <h3>Total Discount</h3>
          <div className="flex flex-row justify-between items-center">
            <input
              className="p-1 text-plum text-2xl font-semibold bg-transparent border-none outline-none w-14 text-center"
              {...register("salesParams.totalDiscount.value")}
              placeholder="0"
              type="number"
            />
            <div className="flex flex-row gap-2 items-end">
              <div
                onClick={() => {
                  setValue("salesParams.totalDiscount.type", "rate");
                }}
                className={`${
                  totalDiscountType == "rate" &&
                  "text-plum text-xl font-semibold"
                } cursor-pointer`}
              >
                %
              </div>
              /
              <div
                onClick={() => {
                  setValue("salesParams.totalDiscount.type", "amount");
                }}
                className={`${
                  totalDiscountType == "amount" &&
                  "text-plum text-xl font-semibold"
                } cursor-pointer`}
              >
                AUD
              </div>
            </div>
          </div>
          <h3>Min Install Charge</h3>
          <div className="flex flex-row justify-between items-center">
            <input
              className="p-1 text-plum text-2xl font-semibold bg-transparent border-none outline-none w-14 text-center"
              {...register("salesParams.minInstallCharge")}
              placeholder={defaultMinInstall}
              type="number"
            />
            <div className="text-plum text-xl font-semibold">AUD</div>
          </div>
        </Card>
        <Card className="col-span-1">
          <div className="flex flex-col justify-start flex-1 gap-4">
            <h3>Various Quoting Options</h3>
            <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
              <h3>Include Measure</h3>
              <input
                className="px-1 py-[0] mb-[1px] text-plum text-lg font-semibold bg-transparent border-none outline-none w-14 text-center"
                {...register("salesParams.measureCharge")}
                placeholder={defaultMeasureCharge}
                type="number"
              />
              <Checkbox
                className="checked:bg-plum dark:checked:bg-plum"
                {...register("salesParams.measureInclude")}
                defaultChecked={true}
              />
            </div>
            <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
              <h3>Install Charge Separate Display</h3>
              <Checkbox
                className="checked:bg-plum dark:checked:bg-plum"
                {...register("salesParams.separateInstall")}
              />
            </div>
            <div className="px-2 border-b-[1px] flex flex-row items-center justify-between">
              <h3>GST Inclusive Display</h3>
              <Checkbox
                className="checked:bg-plum dark:checked:bg-plum"
                {...register("salesParams.gstInclusive")}
              />
            </div>
          </div>
        </Card>
        {fields?.map((p, i) => {
          return <ProductQuoteParams key={p.id} index={i} />;
        })}
      </div>
    </div>
  );
}
