import {
  Badge,
  Button,
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { useScheme } from "../../../../../../../store/schemeStore";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_MATERIALS } from "../../../../../materials/gqls";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  MaterialsType,
  newMaterialSchema,
} from "../../../../../materials/types";
import { useEffect, useState } from "react";
import { useOrderStore } from "../../../../../../../store/orderStore";
import isNullish from "../../../../../../../utils/isNullish";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { push } from "../../../../../../../utils/pushAPI";
import { GET_USERS_CONDITIONAL } from "../../../../../settings/users/gql";
import Prices from "../../../../../settings/order/materials/prices";

interface props {
  adding: boolean;
  setAdding: React.Dispatch<React.SetStateAction<boolean>>;
  optionCode: string;
  materials: MaterialsType[];
  search: string;
  optionName: string;
  cb?: (newmat: MaterialsType) => void;
}

export default function AddCustomMaterial({
  adding,
  setAdding,
  optionCode,
  materials,
  search,
  optionName,
  cb,
}: props) {
  const onClose = () => {
    setAdding(false);
  };

  const { addMaterials } = useOrderStore();

  const [priceLock, setPriceLock] = useState(false);

  const existingParams =
    materials.reduce<{ name: string; values: string[] }[]>((prev, cur) => {
      let newParams = prev;
      if (cur.params) {
        cur.params.map(param => {
          if (newParams.find(p => p.name == param.name)) {
            newParams = newParams.map(np => {
              if (np.name == param.name) {
                return {
                  ...np,
                  values: [...new Set(np.values.concat(param.value))],
                };
              } else {
                return np;
              }
            });
          } else {
            newParams = newParams.concat({
              name: param.name,
              values: [param.value],
            });
          }
        });
      }

      return newParams;
    }, []) || [];

  const possibleConditions =
    materials.reduce<MaterialsType["optionCondition"]>((prev, cur) => {
      let newConditions = prev || [];
      if (cur.optionCondition) {
        const uniqueConditions = cur.optionCondition.filter(
          oc =>
            !newConditions.find(
              nc => nc.option == oc.option && nc.value == oc.value
            )
        );
        newConditions.push(...uniqueConditions);
      }

      return newConditions;
    }, []) || [];

  const defaultValues = {
    productId: materials[0]?.product.id,
    brand: "",
    name: search.split(" ")[0] || "",
    color: search.split(" ")[1] || "",
    size: null,
    code: null,
    widthDeduction: null,
    heightDeduction: null,
    image: null,
    optionCode,
    pending: true,
    prices: [],
    selectable: true,
    params: existingParams.map(p => ({ name: p.name, value: p.values[0] })),
    optionCondition: possibleConditions || [],
  };

  const { scheme } = useScheme();

  const {
    reset,
    register,
    // setValue,
    handleSubmit,
    control,
    // formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(newMaterialSchema),
  });

  const { fields, remove, append } = useFieldArray({
    name: "params",
    control,
  });

  const params = useWatch({
    name: "params",
    control,
  });
  // console.log(errors);

  // const name = useWatch({
  //   name: "name",
  //   control,
  // });

  useEffect(() => {
    const name = search.split(" ")[0];
    const color = search.split(" ")[1];

    reset({
      ...defaultValues,
      name: name || "",
      color: color || "",
    });
  }, [search]);

  // useEffect(() => {
  //   let exMatWithSameName: undefined | MaterialsType = undefined;

  //   if (name.trim() !== "") {
  //     exMatWithSameName = materials.find(
  //       cm => cm.name.toLowerCase() == name && !isNullish(cm.prices, true)
  //     );
  //   }

  //   if (exMatWithSameName?.prices) {
  //     setPriceLock(true);
  //   }

  //   setValue("prices", exMatWithSameName?.prices || []);
  // }, [name]);

  const [insert_material, { loading }] = useMutation(ADD_MATERIALS);

  const { data: data_users } = useQuery(GET_USERS_CONDITIONAL, {
    variables: {
      where: {
        team: {
          inHouse: { _eq: true },
          type: { name: { _eq: "admin" } },
        },
        auth: { name: { _in: ["office_admin", "office_leader"] } },
        active: { _eq: true },
      },
    },
  });

  const adminUsersToNotify = data_users?.users.map(u => u.id);

  const onSubmit = handleSubmit(data => {
    insert_material({
      variables: {
        object: data,
      },
      onCompleted(data) {
        const insertedMat = data.insert_materials_one;
        addMaterials([
          {
            ...data.insert_materials_one,
            pending: false,
          },
        ]);
        if (adminUsersToNotify) {
          push({
            title: "New material added",
            body: `Please confirm pending material [${insertedMat.name}${
              insertedMat.color ? " " + insertedMat.color : ""
            }] for ${insertedMat.product.name}`,
            ids: adminUsersToNotify,
            // path:""
          });
        }
        if (cb) {
          cb(data.insert_materials_one);
        }
      },
    });
  });

  return (
    <Modal
      show={adding}
      onClose={onClose}
      className={`${scheme} !h-full !items-start`}
    >
      <form onSubmit={onSubmit}>
        <Modal.Header>Add Custom Material for {optionName}</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-2">
            <Label value="Brand *" />
            <TextInput {...register("brand")} placeholder="enter brand name" />
            <Label value="Name *" />
            <TextInput
              {...register("name")}
              placeholder="enter name"
              required
            />
            <Label value="Colour *" />
            <TextInput {...register("color")} placeholder="enter color" />
            <Label value="Size" />
            <TextInput
              {...register("size")}
              placeholder="enter size if applicable"
            />
            <Label value="Code" />
            <TextInput
              {...register("code")}
              placeholder="enter item code if available"
            />
            <Label value="Width (MUST if curtain fabric)" />
            <TextInput
              {...register("widthDeduction")}
              placeholder="width in mm if available"
            />
            <Label value="Height" />
            <TextInput
              {...register("heightDeduction")}
              placeholder="height in mm if available"
            />
            <Label value="Price" />
            {/* <TextInput
              {...register("price")}
              disabled={priceLock}
              placeholder="price in AUD if available"
            /> */}
            <Prices />
            <Label value="Params" />
            <div className="flex flex-row gap-2 flex-wrap">
              {existingParams
                .filter(ep => !params.find(p => p.name == ep.name))
                .map(ep => (
                  <div key={ep.name} className="relative group">
                    <Badge
                      size="sm"
                      color="purple"
                      className="cursor-pointer"
                      onClick={() => {
                        append({
                          name: ep.name,
                          value: ep.values[0],
                        });
                      }}
                    >
                      {ep.name}
                    </Badge>
                    <PlusCircleIcon className="w-4 text-grass absolute hidden group-hover:block -top-1 -right-1" />
                  </div>
                ))}
            </div>
            <div className="flex flex-row gap-2 flex-wrap">
              {fields.map((item, i) => (
                <div key={item.id} className="relative group">
                  <Select
                    sizing="sm"
                    addon={item.name}
                    {...register(`params.${i}.value`)}
                  >
                    {existingParams
                      .find(ep => ep.name == item.name)
                      ?.values.map(v => (
                        <option value={v} key={v}>
                          {v}
                        </option>
                      ))}
                  </Select>
                  <MinusCircleIcon
                    className="w-5 text-red-500 absolute hidden group-hover:block -top-1 -right-1 cursor-pointer"
                    onClick={() => {
                      remove(i);
                    }}
                  />
                </div>
              ))}
              {fields.length < 1 && (
                <div className="ml-2 italic">No Params Added</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end gap-2">
          <Button
            size="sm"
            color="purple"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            outline
            size="sm"
            gradientDuoTone="purpleToBlue"
            type="submit"
          >
            <div className="flex flex-row gap-2 items-center">
              {loading && <Spinner size="sm" color="purple" />}
              Add
            </div>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
