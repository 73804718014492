import { Badge, ListGroup, Tooltip } from "flowbite-react";
import { RecordType, basicUserType } from "../shared/record/types";
import getContrast from "../../../utils/getContrast";
import TotalH from "../shared/record/totalH";
import {
  CalculatorIcon,
  ChatBubbleLeftEllipsisIcon,
  ClockIcon,
  GiftIcon,
  MapPinIcon,
  MinusCircleIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import RevGeocoded from "./revGeocoded";
import { useOnClickOutside } from "usehooks-ts";
import { useRef, useState } from "react";
import { GET_RECORD_STATUS, UPDATE_RECORD } from "../shared/record/gqls";
import { useMutation, useQuery } from "@apollo/client";
import { sort } from "fast-sort";
import checkAuth from "../../../utils/checkAuth";
import { useNavigate } from "react-router-dom";
import { useCheckHoliday } from "../../../utils/calendarMethods";

interface prop {
  record?: RecordType;
  date: Date;
  user: basicUserType;
}
const ListItem = ({ record, date, user }: prop) => {
  const { data } = useQuery(GET_RECORD_STATUS);
  const [update, { loading }] = useMutation(UPDATE_RECORD);
  const statuses = data?.recordStatus
    ? sort(data?.recordStatus).asc(stat => stat.priority)
    : [];

  const inStatus = statuses.find(stat => stat.name == "in");

  const checkHoliday = useCheckHoliday();
  const possibleHoliday = checkHoliday(date)?.summary || null;

  const ref = useRef<HTMLDivElement>(null);

  const canEdit =
    record &&
    checkAuth([
      "records_action_all",
      {
        permission: "records_action_team",
        checkGroup: "userTeam",
        conditionGroup: [user.teamID || 0],
      },
    ]);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    canEdit && record?.status?.id !== inStatus?.id && setOpen(!open);
  };

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const changeStat = (id: number) => {
    if (!record) {
      return;
    }
    update({
      variables: {
        id: record.id,
        set: {
          statusID: id,
        },
      },
    });
    toggleOpen();
  };

  const navigate = useNavigate();

  const goToDetail = () => {
    if (!record) {
      return;
    }
    navigate("/records/detail/" + record.id);
  };

  return (
    <div
      ref={ref}
      className="flex flex-col border-[1px] shadow-sm items-start bg-white dark:bg-gray-800 dark:border-gray-700 py-2 px-3 rounded-md gap-2"
    >
      <div className="w-full flex flex-row justify-between items-center">
        {/* Info */}
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
            <div
              style={{
                backgroundColor:
                  record?.type?.color || (possibleHoliday ? "red" : "black"),
              }}
              className="p-[6px] mb-[1px] rounded-full"
            />
            <h3
              onClick={goToDetail}
              className={`${record && "hover:text-plum cursor-pointer"}`}
            >
              {dayjs(date).format("DD/MM ddd")}
            </h3>
            {possibleHoliday && (
              <Tooltip content={possibleHoliday}>
                <GiftIcon className="w-4" />
              </Tooltip>
            )}
          </div>
          {record && (
            <div className="flex flex-row gap-2">
              <div className="flex flex-row gap-1">
                <CalculatorIcon className="w-4" />
                <TotalH
                  startTime={record.start}
                  endTime={record.end}
                  brk={record.break}
                />
              </div>
              <div className="hidden @xs:flex flex-row gap-1">
                <ClockIcon className="w-4" />
                {dayjs(record.start).format("HH:mm")}
                {record.end
                  ? ` - ${dayjs(record.end).format("HH:mm")}`
                  : " - pending"}
              </div>
              <div className="hidden @sm:flex flex-row gap-1">
                <MinusCircleIcon className="w-4" />
                {Math.round(((record.break || 0) / 60) * 10) / 10}
              </div>
              <div className="hidden @sm:flex flex-row gap-1">
                <MapPinIcon className="w-4" />
                {record.endLoc ? (
                  <RevGeocoded coord={record.endLoc} />
                ) : (
                  "unknown"
                )}
              </div>
              {record.logs && record.logs.length > 0 && (
                <div className="hidden @md:flex flex-row gap-1">
                  <WrenchScrewdriverIcon className="w-4" />
                  <div>{record.logs.length}</div>
                </div>
              )}
              {record.desc && (
                <div className="hidden @md:flex flex-row gap-1">
                  <ChatBubbleLeftEllipsisIcon className="w-4" />
                  <div className="flex-1 max-w-xs truncate">{record.desc}</div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* Status */}
        <div className="relative">
          <Badge
            style={{
              color: record ? getContrast(record.status.color) : "white",
              backgroundColor: record?.status.color || "black",
            }}
            onClick={toggleOpen}
            size="sm"
            className={`uppercase hover:brightness-125 cursor-pointer ${
              loading && "opacity-50"
            }`}
          >
            {record?.status.name || "missing"}
          </Badge>
          {open && (
            <ListGroup className="absolute right-0 z-10 mt-2">
              {statuses
                ?.filter(stat => stat.id !== inStatus?.id)
                .map(stat => (
                  <ListGroup.Item
                    onClick={() => {
                      changeStat(stat.id);
                    }}
                    key={stat.id}
                  >
                    <Badge
                      style={{
                        backgroundColor: stat.color,
                        color: getContrast(stat.color),
                      }}
                      className={`${
                        stat.id == record?.status.id && "opacity-50"
                      }`}
                    >
                      {stat.name}
                    </Badge>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
