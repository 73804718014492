import { useMutation, useQuery } from "@apollo/client";
import { GET_ORDERS, UPDATE_ORDER_BY_PK } from "./gqls";
import { Button, Spinner, Table } from "flowbite-react";
import { fullNumber } from "../../../utils/fullNumber";
import { useState } from "react";
import isNullish from "../../../utils/isNullish";

export default function NoteConversion() {
  const { data, loading } = useQuery(GET_ORDERS, {
    fetchPolicy: "cache-and-network",
  });
  const orders = data?.workorder;

  const [converted, setConverted] = useState<number[]>([]);
  const [converting, setConverting] = useState<number | undefined>(undefined);

  const [update] = useMutation(UPDATE_ORDER_BY_PK);

  const convert = (id: number) => {
    const order = orders?.find(order => order.id == id);
    if (!order) {
      return;
    }
    setConverting(id);

    const locations = order.order.locations;

    const convertedLocs = locations.map(location => {
      return {
        ...location,
        openings: location.openings.map(opening => {
          let openingNote =
            (!isNullish(opening.note) && opening.note) ||
            (!isNullish(
              opening.options?.find(opt => opt.name.toLowerCase() == "note")
                ?.value
            ) &&
              opening.options?.find(opt => opt.name.toLowerCase() == "note")
                ?.value) ||
            "";
          return {
            ...opening,
            note: openingNote,
            items: opening.items?.map(item => {
              return {
                ...item,
                note:
                  (!isNullish(item.note) && item.note) ||
                  (!isNullish(
                    item.options?.find(opt => opt.name.toLowerCase() == "note")
                      ?.value
                  ) &&
                    item.options?.find(opt => opt.name.toLowerCase() == "note")
                      ?.value) ||
                  "",
              };
            }),
          };
        }),
      };
    });

    // console.log(convertedLocs);

    // return setConverting(undefined);

    update({
      variables: {
        id: order.id,
        set: {
          order: {
            ...order,
            locations: convertedLocs,
          },
        },
      },
      onCompleted: () => {
        setConverted(state => [...state, id]);
        setConverting(undefined);
      },
      onError: err => {
        console.log(err);
        setConverting(undefined);
      },
    });
  };

  return (
    <div className="mx-4">
      <Table striped>
        <Table.Head>
          <Table.HeadCell>id</Table.HeadCell>
          <Table.HeadCell>ref</Table.HeadCell>
          <Table.HeadCell></Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {orders
            ?.filter(order => !converted.includes(order.id))
            .map(order => (
              <Table.Row key={order.id}>
                <Table.Cell>{order.id}</Table.Cell>
                <Table.Cell>
                  {fullNumber(
                    order.process.processType.prefix,
                    order.process.year,
                    order.process.number
                  )}{" "}
                  {order.process.name}
                </Table.Cell>
                <Table.Cell>
                  {converting == order.id ? (
                    <Spinner size="sm" />
                  ) : (
                    <Button
                      outline
                      size="xs"
                      onClick={() => {
                        convert(order.id);
                      }}
                      gradientDuoTone="purpleToBlue"
                    >
                      Convert
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  );
}
