import { HomeIcon, HomeModernIcon } from "@heroicons/react/24/solid";
import { Breadcrumb, Button, Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import {
  MarkerProp,
  NewProcessType,
  processSchema,
  RELATED_CONFIGS,
} from "../types";
import { PROCESS_CORE_FIELDS } from "../fragments";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../store/alertStore";
import { gql, useMutation, useQuery, TypedDocumentNode } from "@apollo/client";
import { PROCESS_STATUS_CORE_FIELDS } from "../../settings/process/processStatuses/fragments";
import { PROCESS_TYPE_CORE_FIELDS } from "../../settings/process/processTypes/fragments";
import { PRODUCT_CORE_FIELDS } from "../../settings/products/fragments";
import { CONTACT_TYPE_CORE_FIELDS } from "../../settings/contact/contactTypes/fragments";
import checkAuth from "../../../../utils/checkAuth";
import { useAuthStore } from "../../../../store/authStore";
import Ids from "./ids";
import Customer from "./customer";
import Contact from "./contact";
import Sales from "./sales";
import Etc from "./etc";
import Status from "./status";
import Map from "./map/map";
import { useState } from "react";
import Schedule from "./schedules/schedule";
import { UserType } from "../../settings/users/types";
import { TEAM_CORE_FIELDS } from "../../settings/teams/fragments";
import { myLineProps } from "./map/line";
import { default as config } from "../../../../config";
import { ContactType, NewContactType } from "../../contact/types";
import { USER_CORE_FIELDS } from "../../settings/users/fragments";
import { PERMISSION_CORE_FIELDS } from "../../settings/auths/permissions/fragments";
import { PAYMENT_STATUS_CORE_FIELDS } from "../../settings/payment/paymentStatuses/fragments";
import NewWindow from "react-new-window";
import useAddContact from "../../contact/addContact/useAddContact";
import { ProcessHistory } from "../types";

export const homeMarker: MarkerProp = {
  position: config.companyLoc,
  label: "Company",
  icon: <HomeModernIcon className="w-6" />,
};

export const GET_PROCESS_RELATED_CONFIGS: TypedDocumentNode<RELATED_CONFIGS> = gql`
  ${PROCESS_STATUS_CORE_FIELDS}
  ${PROCESS_TYPE_CORE_FIELDS}
  ${PRODUCT_CORE_FIELDS}
  ${CONTACT_TYPE_CORE_FIELDS}
  ${TEAM_CORE_FIELDS}
  ${USER_CORE_FIELDS}
  ${PERMISSION_CORE_FIELDS}
  ${PAYMENT_STATUS_CORE_FIELDS}
  query GET_PROCESS_RELATED_CONFIGS {
    processStatus {
      ...ProcessStatusCoreFields
    }
    processType {
      ...ProcessTypeCoreFields
    }
    products {
      ...ProductCoreFields
    }
    contactType {
      ...ContactTypeCoreFields
    }
    sources {
      id
      name
    }
    tags {
      id
      name
    }
    teams {
      ...TeamCoreFields
    }
    users {
      ...UserCoreFields
    }
    permissions {
      ...PermissionCoreFields
    }
    paymentStatus {
      ...PaymentStatusCoreFields
    }
  }
`;

export const INSERT_PROCESS = gql`
  ${PROCESS_CORE_FIELDS}
  mutation INSERT_PROCESS(
    $name: String!
    $year: numeric!
    $number: numeric!
    $typeId: Int!
    $address: String
    $postCode: Int!
    $description: String
    $returning: Boolean
    $salesRep: Int
    $status: Int!
    $from: date
    $due: date
    $products: jsonb
    $tags: jsonb
    $sources: jsonb
    $contacts: jsonb
    $createdBy: Int
    $value: Float
    $schedules: jsonb
  ) {
    insert_process_one(
      object: {
        name: $name
        year: $year
        number: $number
        typeId: $typeId
        address: $address
        postCode: $postCode
        description: $description
        returning: $returning
        salesRep: $salesRep
        status: $status
        from: $from
        due: $due
        products: $products
        tags: $tags
        sources: $sources
        contacts: $contacts
        createdBy: $createdBy
        value: $value
        schedules: $schedules
      }
    ) {
      ...ProcessCoreFields
    }
  }
`;

export default function AddProcess() {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const [insert_process, { loading: inserting }] = useMutation(INSERT_PROCESS);

  const { user } = useAuthStore();
  const teamProcess = user?.team?.processTypes || [];

  const { data: data_config, loading: loading_config } = useQuery(
    GET_PROCESS_RELATED_CONFIGS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (data.processType && data.processType[0]) {
          const types = data.processType.filter(pt =>
            checkAuth([
              "add_process_any_type",
              {
                permission: "setting_access",
                checkGroup: teamProcess,
                conditionGroup: [pt.id],
              },
            ])
          );

          const typeId = types[0].id;
          reset({
            typeId,
          });
        }
      },
      onError(err) {
        console.log(err);
        addAlert({
          message:
            "could not load process configs please refresh and try again",
          type: "failure",
        });
      },
    }
  );

  const defaultValues = {
    name: "",
    year: new Date().getFullYear(),
    number: 0,
    typeId: 0,
    address: "",
    description: "",
    returning: false,
    salesRep: undefined,
    status: 0,
    from: undefined,
    due: undefined,
    products: [],
    tags: [],
    sources: [],
    newContact: undefined,
    contacts: [],
    schedules: [],
  };

  const methods = useForm<NewProcessType>({
    resolver: zodResolver(processSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  if (errors) {
    console.log(errors);
  }

  const { addNewContact, adding_contact } = useAddContact();

  const onSubmit = handleSubmit(async data => {
    const newContact: ContactType = await addNewContact(
      data.newContact as NewContactType
    );

    let contacts = data.contacts?.map(c => c.id) || [];

    if (newContact) {
      contacts.push(newContact.id);
    }

    const history: ProcessHistory = {
      by: user?.id || 0,
      date: new Date().toISOString(),
      message: "created",
      status: data.status,
      type: "status",
    };

    insert_process({
      variables: {
        ...data,
        name: data.name.trim(),
        contacts,
        createdBy: user?.id,
        history: [history],
      },
      update(cache, { data: { insert_process_one: newProcess } }) {
        cache.modify({
          fields: {
            process(existingProcesss = []) {
              const newProcessRef = cache.writeFragment({
                data: newProcess,
                fragment: PROCESS_CORE_FIELDS,
                fragmentName: "ProcessCoreFields",
              });
              return [...existingProcesss, newProcessRef];
            },
          },
        });
      },
      onCompleted: data => {
        addAlert({
          message: "New process successfully added",
          type: "success",
        });

        const id = data.insert_process_one?.id;
        if (id) {
          navigate("/process/detail/" + id);
        } else {
          navigate("/process");
        }
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "something went wrong cannot add process",
          type: "failure",
        });
      },
    });
  });

  const [markers, setMarkers] = useState<MarkerProp[]>([homeMarker]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [sales, setSales] = useState<UserType[]>([]);
  const [lines, setLines] = useState<myLineProps[]>([]);

  return (
    <main className="px-6 pb-10 select-none">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("../");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            Process
          </Breadcrumb.Item>

          <Breadcrumb.Item>Add Process</Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className="relative">
          {/* Top Sections: status, from, due */}
          <Status statuses={data_config?.processStatus || []} />
          {/* Map Section */}
          {isOpen ? (
            <NewWindow
              onUnload={() => {
                setIsOpen(false);
              }}
              onBlock={() => {
                setIsOpen(false);
              }}
              title="map"
              features={{ popup: true, width: 500, height: 500 }}
            >
              <Map
                markers={markers}
                setMarkers={setMarkers}
                showSchedule={showSchedule}
                setShowSchedule={setShowSchedule}
                lines={lines}
                setLines={setLines}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </NewWindow>
          ) : (
            <>
              <div className="absolute w-[105%] left-[50%] translate-x-[-50%]">
                <Map
                  markers={markers}
                  setMarkers={setMarkers}
                  showSchedule={showSchedule}
                  setShowSchedule={setShowSchedule}
                  lines={lines}
                  setLines={setLines}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              </div>
              {/* Void for Map */}
              <div className="w-full h-[60vh] max-h-[600px]" />
            </>
          )}

          {/* Bottom Sections */}
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {/* Schedule */}
            <Schedule
              showSchedule={showSchedule}
              sales={sales}
              markers={markers}
              homeMarker={homeMarker}
              setMarkers={setMarkers}
              setLines={setLines}
            />

            {/* type, year,  number */}
            <Ids
              data={data_config}
              loading={loading_config}
              teamProcess={teamProcess}
            />
            {/* Name, address, description */}
            <Customer />
            {/* Contact */}
            <Contact
              contactTypes={data_config?.contactType || []}
              setMarkers={setMarkers}
            />
            {/* Sales info: salesRep, source, value, returning */}
            <Sales
              setSales={setSales}
              teams={data_config?.teams || []}
              sources={data_config?.sources || []}
            />
            {/* Tags, Products, Description */}
            <Etc
              tags={data_config?.tags || []}
              products={data_config?.products || []}
            />
          </div>
          <div className="flex flex-row justify-end mt-4">
            <Button
              type="submit"
              outline
              gradientDuoTone="purpleToBlue"
              size="sm"
            >
              {(inserting || adding_contact) && (
                <Spinner className="mr-3" size="sm" light={true} />
              )}
              Add Process
            </Button>
          </div>
        </form>
      </FormProvider>
    </main>
  );
}
