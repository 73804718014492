import {
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import Opening from "./opening";
import { Button } from "flowbite-react";
import {
  DocumentDuplicateIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import uuid from "react-uuid";
import { opening } from "../../../types";
import { memo } from "react";
import { useScheme } from "../../../../../../../store/schemeStore";
import { createPortal } from "react-dom";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useState } from "react";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";

interface props {
  locationIndex: number;
}

const Openings = ({ locationIndex }: props) => {
  const { control } = useFormContext();

  const { fields, remove, append, swap } = useFieldArray({
    name: `locations.${locationIndex}.openings`,
    control,
  });

  const addRow = (opening?: opening) => {
    if (opening) {
      return append(
        JSON.parse(
          JSON.stringify({
            ...opening,
            index: undefined,
            checked: false,
            items: opening.items.map(item => ({ ...item, id: uuid() })),
            panel: undefined,
          })
        )
      );
    }
    append({
      name: "",
      hidden: false,
      options: [],
      note: "",
      items: [
        {
          id: uuid(),
          width: 0,
          height: 0,
          images: [],
          selected: false,
          options: [],
          calcs: [],
          note: "",
        },
      ],
    });
  };

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = fields.findIndex(({ id }) => id === active.id);
      const overIndex = fields.findIndex(({ id }) => id === over.id);
      swap(activeIndex, overIndex);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const { scheme } = useScheme();

  // console.log("openings re-rendered");
  return (
    <div className="mt-2 ml-4">
      <section className="flex flex-col mb-2">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext
            items={fields.map(field => field.id)}
            strategy={verticalListSortingStrategy}
          >
            {fields.map((item, index) => {
              return (
                <Opening
                  id={item.id}
                  key={item.id}
                  locationIndex={locationIndex}
                  index={index}
                  remove={() => {
                    remove(index);
                  }}
                  addRow={addRow}
                />
              );
            })}
          </SortableContext>
          {createPortal(
            <DragOverlay className={`${scheme == "dark" ? "dark" : ""}`}>
              {theActive?.data?.current?.index !== undefined && (
                <Opening
                  id={theActive.id as string}
                  locationIndex={locationIndex}
                  index={theActive.data.current.index as number}
                  remove={() => {
                    // @ts-expect-error
                    remove(theActive.data.current.index as number);
                  }}
                  addRow={addRow}
                  isOverlay={true}
                />
              )}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </section>

      <div className="flex flex-row relative justify-center mt-2">
        <hr className="absolute w-full top-[50%] border-plum" />
        <div className="flex flex-row gap-2 px-2 dark:bg-dark z-10">
          <Button
            onClick={() => {
              addRow();
            }}
            color="purple"
            size="xs"
          >
            <PlusIcon className="w-4 mr-1" />
            opening
          </Button>
        </div>
      </div>
      <OpeningActions
        remove={remove}
        append={append}
        locationIndex={locationIndex}
      />
    </div>
  );
};

export default memo(Openings);

interface openingActions {
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FieldValues, `locations.${number}.openings`>;
  locationIndex: number;
}

const OpeningActions = ({ locationIndex, remove, append }: openingActions) => {
  const { control, setValue } = useFormContext();

  const openings: opening[] = useWatch({
    name: `locations.${locationIndex}.openings`,
    control,
  });

  const selected = openings
    .map((l, i) => ({ ...l, index: i }))
    .filter(l => l.checked);

  const { scheme } = useScheme();

  if (selected.length < 1) {
    return null;
  }

  const _delete = () => {
    const selectedIndexes = selected.map(s => s.index);
    remove(selectedIndexes);
  };

  const uncheck = () => {
    for (const opening of selected) {
      setValue(
        `locations.${locationIndex}.openings.${opening.index}.checked`,
        false
      );
    }
  };

  const duplicate = () => {
    append(
      JSON.parse(
        JSON.stringify(
          selected.map(opening => ({
            ...opening,
            index: undefined,
            checked: false,
            items: opening.items.map(item => ({ ...item, id: uuid() })),
            panel: undefined,
          }))
        )
      )
    );
    uncheck();
  };

  return (
    <>
      {createPortal(
        <div className={`${scheme}`}>
          <div className="absolute left-0 w-full bottom-0 px-4 py-2 flex flex-row gap-2 bg-dark text-white dark:text-black dark:bg-gray-50 border-t-2 border-plum">
            {/* delete */}
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={_delete}
            >
              <TrashIcon className="w-5 text-red-500" />
              <div>Delete {selected.length} Openings</div>
            </div>
            {/* duplicate */}
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={duplicate}
            >
              <DocumentDuplicateIcon className="w-5 text-grass" />
              <div>Duplicate {selected.length} Openings</div>
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};
