import { Breadcrumb, Spinner } from "flowbite-react";
import { HomeIcon } from "@heroicons/react/24/solid";
import { useParams, useNavigate } from "react-router-dom";
import { USER_CORE_FIELDS } from "../fragments";
import { gql, useQuery, TypedDocumentNode } from "@apollo/client";
import { UserType } from "../types";
import { addAlert } from "../../../../../store/alertStore";
import Color from "./color";
import NameAvatar from "./nameAvatar";
import IdInfo from "./ids";
import Basic from "./basic";
import CalId from "./calId";
import Optional from "./optional";
import Terms from "./terms";
import Leaves from "./leaves";

interface UserByPk {
  users_by_pk: UserType;
}

export const GET_USER_BY_ID: TypedDocumentNode<UserByPk> = gql`
  ${USER_CORE_FIELDS}
  query GET_USER_BY_ID($id: Int!) {
    users_by_pk(id: $id) {
      ...UserCoreFields
    }
  }
`;

export default function UserDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_USER_BY_ID, {
    variables: { id },
  });

  if (error) {
    console.log(error);
    addAlert({ message: "cannot load user", type: "failure" });
  }

  const user = data?.users_by_pk;

  return (
    <div className="mb-1 flex-grow  mx-auto">
      <section className="pb-4 space-y-4">
        <nav className="w-full">
          <Breadcrumb className="w-full shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
            <Breadcrumb.Item
              className="cursor-pointer"
              onClick={() => {
                navigate("../../");
              }}
            >
              <HomeIcon className="w-5 mr-2" />
              Settings
            </Breadcrumb.Item>

            <Breadcrumb.Item
              className="cursor-pointer"
              onClick={() => {
                navigate("../users");
              }}
            >
              Users
            </Breadcrumb.Item>
            <Breadcrumb.Item>User</Breadcrumb.Item>
          </Breadcrumb>
        </nav>
        {loading && (
          <div className="flex justify-center items-center">
            <Spinner className="mx-auto mt-4" color="purple" size="xl" />
          </div>
        )}
        {user && (
          <main className="flex flex-col px-6 pb-10">
            <div className="flex flex-col items-center mt-10">
              {/* Avatar, Colour */}
              <section className="flex flex-col relative items-center">
                <Color user={user} />
                <NameAvatar user={user} />
              </section>
              {/* Row 1 */}
              <section className="flex flex-row flex-wrap justify-betwen mt-10 w-[768px] max-w-full gap-8">
                {/* Email, Name Edit */}
                <IdInfo user={user} />
                {/* Basic Info */}
                <Basic user={user} />
              </section>
              {/* Row 2 */}
              <section className="flex flex-row flex-wrap justify-between w-[768px] mb-5 max-w-full">
                <Optional user={user} />
              </section>
              {/* Row 3 */}
              <section className="flex flex-row flex-wrap justify-between w-[768px] mb-5 max-w-full">
                <CalId user={user} />
              </section>
              {/* Row 4 */}
              <section className="flex flex-row flex-wrap justify-between w-[768px] mb-5 max-w-full">
                <Terms user={user} />
              </section>
              <section className="flex flex-row flex-wrap justify-between w-[768px] mb-5 max-w-full">
                <Leaves user={user} />
              </section>
              {/* TODO: Add permission, subUser */}
            </div>
          </main>
        )}
      </section>
    </div>
  );
}
