import { useFormContext, useWatch } from "react-hook-form";
import { layoutConfig, optionValues } from "../../../../types";
import { TextInput, Tooltip } from "flowbite-react";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/solid";
import {
  itemWarning,
  calc,
  layoutCode,
  location,
  option,
} from "../../../types";
import { memo, useEffect, useRef, useState } from "react";
import SelectComp from "./select";
import isNullish from "../../../../../../../utils/isNullish";
import { sort } from "fast-sort";
import { myDeepEqual } from "../../../../../../../utils/arrayObjMethods";

interface optProps {
  locationIndex: number;
  openingIndex: number;
  itemIndex: number;
  index: number;
  layoutFull: layoutCode | undefined;
  layout: layoutConfig | undefined;
  warnings?: itemWarning[];
}

const Option = ({
  locationIndex,
  openingIndex,
  itemIndex,
  index,
  layoutFull,
  layout,
  warnings,
}: optProps) => {
  const { register, control, setValue, getValues } = useFormContext();

  const optionCoord = `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}.options.${index}`;

  const optionValue = useWatch({
    control,
    name: `${optionCoord}.value`,
  });

  const option: option = getValues(`${optionCoord}`);

  const itemCoord = `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}`;

  const optionsCoord = `locations.${locationIndex}.openings.${openingIndex}.items.${itemIndex}.options`;

  const showOptionals = useWatch({
    name: "showOptionals",
    control,
    exact: true,
  });

  const product: number = useWatch({
    name: `locations.${locationIndex}.openings.${openingIndex}.product`,
    control,
    exact: true,
  });

  const applyAll = () => {
    const proceed = confirm("apply option to all items?");
    if (!proceed) {
      return;
    }

    const allLocation: location[] = getValues("locations");

    setValue(
      "locations",
      allLocation.map(l => ({
        ...l,
        openings: l.openings.map(opening => ({
          ...opening,
          items: opening.items.map(item => ({
            ...item,
            options: item.options.map(opt => {
              if (opt.name == option?.name && opening.product == product) {
                return { ...opt, value: optionValue };
              } else {
                return opt;
              }
            }),
          })),
        })),
      }))
    );
  };

  const disabled = () => {
    if (!layout) {
      return false;
    }

    for (const optionConfig of layout.config) {
      if (option.id === Number(optionConfig.option)) {
        return true;
      }
    }

    if (layoutFull?.layout?.parent) {
      for (const po of layoutFull.layout.parent) {
        if (option.id === Number(po.option)) {
          return true;
        }
      }
    }

    return false;
  };

  const [focus, setFocus] = useState(false);

  const parentCheck = !useWatch({
    name: `${optionCoord}.noCalc`,
  });

  const name = isNullish(option.displayName)
    ? option.name
    : (option.displayName as string);

  const [lineBreak, setLineBreak] = useState(false);

  useEffect(() => {
    if (option.source !== "deductionPreset") {
      return;
    }

    const currentCalcs: calc[] = getValues(`${itemCoord}.calcs`);

    const calcs = currentCalcs || [];

    const newCalcs = sort(
      calcs.filter(c => Number(c.optionId) !== Number(option.id))
    ).asc("name");

    // @ts-ignore
    if (!parentCheck && !myDeepEqual(calcs, newCalcs)) {
      setValue(`${itemCoord}.calcs`, newCalcs);
    }
  }, [parentCheck]);

  const isWarningPresent = warnings && warnings.length > 0;

  const myWarnings =
    warnings
      ?.map(
        warning =>
          `${warning?.presetOption ? `${warning?.presetOption}: ` : ""}${
            warning?.value
          }`
      )
      .join("\n") || "";

  return (
    <>
      {parentCheck && option.name.toLowerCase() !== "note" && (
        <>
          {lineBreak && <div className="basis-full h-0" />}
          <Tooltip
            className={`${isWarningPresent ? "" : "hidden"}`}
            content={<pre>{myWarnings}</pre>}
          >
            <div
              className={`relative group/option my-[5px] mr-1 ${
                !showOptionals && option.optional && "hidden"
              } ${isWarningPresent && "ring-rose-300 ring-1 rounded-md"}`}
              style={{
                minWidth: `${name.length * 10}px`,
              }}
              onBlur={() => {
                setFocus(false);
              }}
              onFocus={() => {
                setFocus(true);
              }}
            >
              <div
                className={`text-grass w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none ${
                  (optionValue == undefined || optionValue == "") &&
                  !option.disableAutoSelect &&
                  !focus
                    ? "left-2 top-2"
                    : "left-[50%] -translate-x-[50%] -top-[8px]"
                }`}
              >
                {name}
              </div>
              {option.type !== "select" ? (
                <TextInput
                  className={`w-28`}
                  style={{
                    minWidth: `${name.length * 10}px`,
                  }}
                  type={option.type == "number" ? "number" : "text"}
                  sizing="sm"
                  {...register(`${optionCoord}.value`)}
                  onKeyDown={e => {
                    if (e.key == "ArrowUp" || e.key == "ArrowDown")
                      e.preventDefault();
                  }}
                />
              ) : (
                <>
                  <SelectComp
                    optionName={option.name}
                    // optionValue={optionValue}
                    disabled={disabled()}
                    productIndex={`locations.${locationIndex}.openings.${openingIndex}.product`}
                    itemCoord={itemCoord}
                    optionCoord={optionCoord}
                    optionsCoord={optionsCoord}
                  />
                </>
              )}
              <div
                className="absolute right-1 top-1 hidden group-hover/option:block cursor-pointer hover:text-grass"
                onClick={applyAll}
              >
                <ClipboardDocumentListIcon className="w-5" />
              </div>
            </div>
          </Tooltip>
        </>
      )}
      <MemoizedOptionsSideEffects
        optionCoord={optionCoord}
        optionsCoord={optionsCoord}
        setLineBreak={setLineBreak}
        lineBreak={lineBreak}
        index={index}
      />
    </>
  );
};

interface optionsSEProps {
  optionsCoord: string;
  optionCoord: string;
  setLineBreak: React.Dispatch<React.SetStateAction<boolean>>;
  lineBreak: boolean;
  index: number;
}

const OptionsSideEffects = ({
  optionsCoord,
  optionCoord,
  setLineBreak,
  lineBreak,
  index,
}: optionsSEProps) => {
  const { control, getValues, setValue } = useFormContext();

  const option: option = getValues(`${optionCoord}`);
  const options: option[] = getValues(optionsCoord);
  const optionParents: optionValues[] = useWatch({
    control,
    name: `${optionCoord}.parent`,
    exact: true,
  });

  const { rowNum } = option;

  const optionsBeforeMe = options
    .map((o, i) => ({ ...o, index: i }))
    .filter((o, i) => i < index);

  const indexes = options
    .map((o, i) => ({ index: i, id: o.id }))
    .filter(
      o =>
        optionParents?.some(p => p.option == o.id) ||
        optionsBeforeMe.find(ob => ob.id == o.id)
    )
    .map(o => `${optionsCoord}.${o.index}`);

  const formOptions: option[] =
    useWatch({
      name: indexes,
      control,
    }) || [];

  const [current, setCurrent] = useState<string>("");

  const isMounted = useRef(false);

  useEffect(() => {
    const itemCoord = optionsCoord.split(".").slice(0, -1).join(".");
    const calcs = getValues(`${itemCoord}.calcs`);
    if (
      !isMounted.current &&
      calcs &&
      Array.isArray(calcs) &&
      calcs.length > 0
    ) {
      isMounted.current = true;
      return;
    }

    const stringifiedOptions = options
      .map(o => `${o.id}::${o.value}::${o.noCalc}`)
      .join("|");
    if (stringifiedOptions == current) {
      return;
    }
    setCurrent(stringifiedOptions);

    const poCheck =
      formOptions &&
      formOptions
        .filter(o => optionParents?.some(p => p.option == o.id))
        .every(
          p =>
            optionParents?.find(po =>
              po.values.map(v => v.toString()).includes(p.value.toString())
            ) && !p.noCalc
        );
    if (isMounted.current && poCheck == !getValues(`${optionCoord}.noCalc`)) {
      return;
    }
    setValue(`${optionCoord}.noCalc`, !poCheck);

    isMounted.current = true;
  }, [JSON.stringify(formOptions)]);

  const optionBeforeMe = sort(
    formOptions.filter(
      fo =>
        !fo.noCalc && !fo.optional && optionsBeforeMe.find(ob => ob.id == fo.id)
    )
  ).desc(["rowNum", "position"])[0];

  useEffect(() => {
    // Line Break Check

    let breakCheck = false;

    if (optionBeforeMe && optionBeforeMe.rowNum > 0) {
      breakCheck = optionBeforeMe.rowNum !== rowNum;
    }

    if (lineBreak !== breakCheck) {
      setLineBreak(breakCheck);
    }
  }, [JSON.stringify(optionBeforeMe)]);

  return null;
};

const MemoizedOptionsSideEffects = memo(OptionsSideEffects);

export default memo(Option);
