import { useEffect, useState } from "react";
import { Event } from "../../../../types/calendarTypes";
import { getSchedule } from "../../../../utils/calendarMethods";
import { ProcessType } from "../../process/types";
// import { useMutation } from "@apollo/client";
import { sort } from "fast-sort";
// import { UPDATE_PROCESS_BY_PK } from "../../process/processDetail/gqls";
import Item from "./item";
import { Spinner } from "flowbite-react";

interface props {
  schedules: string[];
  process?: ProcessType;
}
export default function ScheduleList({ schedules, process }: props) {
  const [events, setEvents] = useState<Event[]>([]);

  // const [update_process] = useMutation(UPDATE_PROCESS_BY_PK);

  const [loading, setLoading] = useState(false);

  // const deleteSchedule = (schedule: string) => {
  //   if (!process) {
  //     return;
  //   }

  //   const newSchedules = schedules.filter(s => s !== schedule);

  //   update_process({
  //     variables: {
  //       id: process.id,
  //       set: {
  //         schedules: newSchedules,
  //       },
  //     },
  //     onError(error) {
  //       console.log(error);
  //     },
  //   });
  // };

  const loadSchedules = async () => {
    setLoading(true);
    const _events = await Promise.all(
      schedules.map(async schedule => {
        const event = await getSchedule(schedule);
        // if (!event || event.status == "cancelled") {
        //   deleteSchedule(schedule);
        //   return false;
        // }
        return event;
      })
    );

    setEvents(_events);
    setLoading(false);
  };

  useEffect(() => {
    loadSchedules();
  }, [schedules]);

  const sortedEvents = sort(events)
    .desc(e => e.start?.date || e.start?.dateTime)
    .filter(e => e);

  return (
    <div className="flex flex-col gap-2">
      {loading && <Spinner color="purple" />}
      {sortedEvents.length == 0 && !loading && <div>No Schedules found</div>}
      {sortedEvents.map((event, i) => (
        <Item
          key={event.id}
          schedule={event}
          process={process}
          prvItem={sortedEvents[i - 1]}
          cb={loadSchedules}
        />
      ))}
    </div>
  );
}
