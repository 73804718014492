import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ProductionStatusType } from "./types";
import { useState, Dispatch, SetStateAction } from "react";
import { Modal, Button, Label, Spinner, TextInput } from "flowbite-react";
import { useScheme } from "../../../../../store/schemeStore";
import checkAuth from "../../../../../utils/checkAuth";
import { useMutation, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";
import { productionStatusSchema } from "./types";
import { PRODUCTION_STATUS_CORE_FIELDS } from "./fragments";
import { ProductType } from "../../products/types";

export const UPDATE_PRODUCTION_STATUS = gql`
  ${PRODUCTION_STATUS_CORE_FIELDS}
  mutation UPDATE_PRODUCTION_STATUS(
    $id: Int!
    $description: String!
    $name: String!
    $color: String!
  ) {
    update_productionStatus_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, color: $color }
    ) {
      ...ProductionStatusCoreFields
    }
  }
`;

export const DELETE_PRODUCTION_STATUS = gql`
  ${PRODUCTION_STATUS_CORE_FIELDS}
  mutation DELETE_PRODUCTION_STATUS($id: Int!) {
    delete_productionStatus_by_pk(id: $id) {
      id
    }
  }
`;

export default function ProductionStatus({
  ps,
  product,
  updating,
  setSortedProductionStatus,
}: {
  ps: ProductionStatusType;
  product?: ProductType;
  updating?: boolean;
  setSortedProductionStatus?: Dispatch<SetStateAction<ProductionStatusType[]>>;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: ps.priority });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging || updating ? 0.5 : 1,
  };

  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow(!show);
  };

  const { scheme } = useScheme();

  const [update_production_status, { loading, error }] = useMutation(
    UPDATE_PRODUCTION_STATUS
  );
  if (error) {
    console.log(error);
    addAlert({ message: "could not update status", type: "failure" });
  }

  const [
    delete_production_status,
    { error: delete_error, loading: delete_loading },
  ] = useMutation(DELETE_PRODUCTION_STATUS);
  if (delete_error) {
    console.log(delete_error);
    addAlert({ message: "could not delete status", type: "failure" });
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(productionStatusSchema),
    defaultValues: {
      name: ps.name,
      description: ps.description,
      color: ps.color || "",
    },
  });

  const onSubmit = handleSubmit(async data => {
    if (ps.new) {
      if (setSortedProductionStatus) {
        const { name, description, color } = data;
        setSortedProductionStatus(prev =>
          prev.map(p => {
            if (p.id == ps.id) {
              return {
                ...p,
                name,
                description,
                color,
              };
            } else {
              return p;
            }
          })
        );
      }
      toggleModal();
      return;
    }

    update_production_status({
      variables: { id: ps.id, ...data },
      onCompleted(data) {
        if (data.update_productionStatus_by_pk) {
          const { color, name, description } =
            data.update_productionStatus_by_pk;

          reset({
            color,
            name,
            description,
          });

          toggleModal();
          addAlert({ message: "status updated successfully", type: "success" });
        }
      },
    });
  });

  const filterOut = () => {
    if (setSortedProductionStatus) {
      setSortedProductionStatus(prev => prev.filter(s => s.id !== ps.id));
    }
  };

  const deleteStatus = () => {
    if (ps.new) {
      toggleModal();
      filterOut();
      return;
    }

    const proceed = confirm("Delete Status?");
    if (proceed) {
      delete_production_status({
        variables: { id: ps.id },
        update(cache, { data: { delete_productionStatus_by_pk: deletedPS } }) {
          const normalizedId = cache.identify({
            id: deletedPS.id,
            __typename: "productionStatus",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
        onCompleted(data) {
          if (data.delete_productionStatus_by_pk) {
            toggleModal();
            filterOut();
          }
        },
      });
    }
  };

  return (
    <div className={`${scheme ? scheme : ""}`}>
      <div
        ref={setNodeRef}
        style={style}
        className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 flex flex-row justify-between items-center"
      >
        <div className="flex flex-row items-center gap-3">
          <div
            className="rounded-full ring-1 w-4 h-4 ring-black dark:ring-white"
            style={{
              backgroundColor: `${ps?.color ? ps.color : "#CCCCCC"}`,
            }}
          />

          <h3>{ps.name}</h3>
        </div>
        <div className="flex flex-row items-center gap-4">
          {checkAuth("setting_production_edit") && (
            <>
              {ps.new && (
                <TrashIcon
                  onClick={filterOut}
                  className="w-4 mt-1 cursor-pointer"
                />
              )}
              <PencilIcon
                className="w-4 mt-1 cursor-pointer"
                onClick={toggleModal}
              />
            </>
          )}
          <button {...attributes} {...listeners}>
            ⣿
          </button>
        </div>
      </div>
      <Modal show={show} onClose={toggleModal} className={`${scheme}`}>
        <Modal.Header>Edit Production Status</Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} className="space-y-3">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Name" />
              </div>
              <TextInput
                type="text"
                placeholder="name is required"
                color={errors.name?.message ? "failure" : undefined}
                helperText={errors.name?.message || ""}
                {...register("name")}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="description" value="Description" />
              </div>
              <TextInput
                type="text"
                placeholder="description is required"
                color={errors.description?.message ? "failure" : undefined}
                helperText={errors.description?.message || ""}
                {...register("description")}
              />
            </div>

            <div>
              <Label htmlFor="color" value="Color" />
              <input
                type="color"
                {...register("color")}
                className="w-full h-10 border-2 p-0 bg-transparent border-none"
              />
              <div> {errors.color?.message || ""}</div>
            </div>
            {/* Action Buttons */}
            <div className="flex flex-row justify-end gap-2 items-center">
              {delete_loading && <Spinner size="sm" light={true} />}
              <TrashIcon
                className="w-5 text-red-500 cursor-pointer"
                onClick={deleteStatus}
              />
              <Button
                gradientDuoTone="purpleToBlue"
                size="sm"
                onClick={toggleModal}
              >
                Cancel
              </Button>
              <Button
                outline
                gradientDuoTone="purpleToBlue"
                size="sm"
                type="submit"
              >
                {loading && (
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                )}
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
