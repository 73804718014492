import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_PRODUCT_SET_OPTION,
  DELETE_PRODUCT_SET_OPTION,
  GET_PRODUCT_OPTIONS_BY_PID,
  UPDATE_PRODUCT_SET_OPTION_BY_PK,
} from "../../../workorder/gqls";
import {
  ChevronUpDownIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import { Button } from "flowbite-react";
import { NewProductSetOptionType, optionProps } from "../../../workorder/types";
import { sort } from "fast-sort";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { createPortal } from "react-dom";
import { CSS } from "@dnd-kit/utilities";
import EditProductSetOption from "./editProductSetOption";
import { PRODUCT_SET_OPTIONS_FIELDS } from "../../../workorder/fragments";
import { useScheme } from "../../../../../store/schemeStore";

interface props {
  productSetId: number;
}

export default function ProductSetOptions({ productSetId }: props) {
  const [sortedOptions, setSortedOptions] = useState<optionProps[]>([]);

  useQuery(GET_PRODUCT_OPTIONS_BY_PID, {
    variables: {
      id: productSetId,
    },
    onCompleted(data) {
      if (data) {
        setSortedOptions(
          sort(data?.productSetOptions)
            .asc("position")
            .filter(option => option.productSetID == productSetId)
        );
      }
    },
  });

  const [adding, setAdding] = useState(false);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedOptions.find(item => item.id === theActive?.id),
    [theActive, sortedOptions]
  );

  const [touched, setTouched] = useState(false);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedOptions.findIndex(({ id }) => id === active.id);
      const overIndex = sortedOptions.findIndex(({ id }) => id === over.id);
      const movedArray = arrayMove(sortedOptions, activeIndex, overIndex);
      setSortedOptions(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const resetSort = () => {
    setSortedOptions(sort(sortedOptions).asc("position"));
    setTouched(false);
  };

  const [update] = useMutation(UPDATE_PRODUCT_SET_OPTION_BY_PK);

  const applySort = () => {
    const positionMappedOptions = sortedOptions.map((option, i) => ({
      ...option,
      position: i,
    }));
    setSortedOptions(positionMappedOptions);
    positionMappedOptions.map(option => {
      update({
        variables: {
          id: option.id,
          set: {
            position: option.position,
          },
        },
      });
    });
    setTouched(false);
  };

  const [insert] = useMutation(ADD_PRODUCT_SET_OPTION);

  const addOption = (data: NewProductSetOptionType) => {
    insert({
      variables: {
        object: {
          ...data,
          values: data.optionCode ? null : data.values,
          default: data.disableAutoSelect ? null : data.default,
          productSetID: productSetId,
          position: sortedOptions.length,
        },
      },
      onCompleted() {
        setAdding(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            productSetOptions(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_productSetOptions_one,
                fragment: PRODUCT_SET_OPTIONS_FIELDS,
                fragmentName: "ProductSetOptionsFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center">
        <h3>Options & Params</h3>
        {adding ? (
          <MinusCircleIcon
            className="w-5 text-red-500 cursor-pointer"
            onClick={() => {
              setAdding(false);
            }}
          />
        ) : (
          <PlusCircleIcon
            className="w-5 text-grass cursor-pointer"
            onClick={() => {
              setAdding(true);
            }}
          />
        )}
      </div>
      {adding && (
        <div className="p-2 border-[1px] rounded-md flex flex-col gap-2">
          <EditProductSetOption
            productSetId={productSetId}
            submitFunction={addOption}
            cancelFunction={() => {
              setAdding(false);
            }}
          />
        </div>
      )}
      {/* List */}
      <div className="select-text flex flex-col gap-1">
        <div className="flex flex-row rounded-t-md dark:bg-gray-600 bg-gray-100 py-2 font-semibold uppercase">
          <div className="text-xs px-2 w-10">id</div>
          <div className="text-xs px-2 flex-1">name</div>
          <div className="text-xs px-2 flex-1">dpName</div>
          <div className="text-xs px-2 w-20">type</div>
          <div className="text-xs px-2 w-20">optional</div>
          <div className="text-xs px-2 flex-1">source</div>
          <div className="text-xs px-2 flex-1">optionCode</div>
          <div className="text-xs px-2 flex-1">group</div>
          <div className="text-xs px-2 flex-1">rowNum</div>
          <div className="text-xs px-2 w-16">position</div>
          <div className="p-0 w-20" />
        </div>
        <div className="text-black dark:text-white">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]}
          >
            <SortableContext
              items={sortedOptions.map(opt => opt.id || 0)}
              strategy={verticalListSortingStrategy}
            >
              {sortedOptions.map((option, i) => (
                <Option key={option.id} index={i} option={option} />
              ))}
            </SortableContext>
            {activeItem && (
              <>
                {createPortal(
                  <DragOverlay>
                    <Option isOverlay={true} option={activeItem} />
                  </DragOverlay>,
                  document.body
                )}
              </>
            )}
          </DndContext>
        </div>
      </div>
      {touched && (
        <>
          <hr />
          <div className="flex flex-row justify-end gap-2 items-center">
            <Button size="xs" color="purple" onClick={resetSort}>
              reset sorting
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone="greenToBlue"
              onClick={applySort}
            >
              apply sorting
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

interface option {
  option: optionProps;
  isOverlay?: boolean;
  index?: number;
}

const Option = ({ option, isOverlay, index }: option) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: option.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const [editing, setEditing] = useState(false);

  const { scheme } = useScheme();

  const cancel = () => {
    setEditing(false);
  };

  const [update] = useMutation(UPDATE_PRODUCT_SET_OPTION_BY_PK);

  const save = (data: NewProductSetOptionType) => {
    update({
      variables: {
        id: option.id,
        set: {
          ...data,
          values: data.optionCode ? null : data.values,
          default: data.disableAutoSelect ? null : data.default,
          id: undefined,
        },
      },
      onCompleted() {
        setEditing(false);
      },
    });
  };

  const [remove, { loading: deleting }] = useMutation(
    DELETE_PRODUCT_SET_OPTION
  );

  const _delete = () => {
    const proceed = confirm("delete productSet option?");
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id: option.id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: option.id,
          __typename: "productSetOptions",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  return (
    <>
      <div
        className={`flex flex-row ${
          index && index % 2 !== 0 && "dark:bg-gray-600 bg-gray-100"
        } ${isOverlay ? "text-grass" : ""}`}
        ref={setNodeRef}
        style={style}
      >
        <div className="w-10 py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.id}
        </div>
        <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.name}
        </div>
        <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.displayName || ""}
        </div>
        <div className="w-20 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.type}
        </div>
        <div className="w-20 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.optional ? "true" : "false"}
        </div>
        <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.source}
        </div>
        <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.optionCode}
        </div>

        <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.group}
        </div>
        <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {option.rowNum}
        </div>
        <div
          className="w-16 group truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500 flex flex-row justify-between items-center"
          {...attributes}
          {...listeners}
        >
          {option.position}
          <ChevronUpDownIcon className="w-4 hidden group-hover:block" />
        </div>

        <div className="py-[5px] w-20">
          <div className="flex flex-row gap-3 w-full justify-center">
            <PencilIcon
              onClick={() => {
                setEditing(true);
              }}
              className="w-5 text-grass cursor-pointer"
            />
            <TrashIcon
              className="w-5 text-red-500 cursor-pointer"
              onClick={_delete}
            />
          </div>
        </div>
      </div>
      {editing && (
        <>
          {createPortal(
            <div
              className={`${scheme} absolute w-screen h-screen top-0 left-0 p-10 backdrop-blur-xl flex flex-col gap-2 overflow-x-hidden`}
            >
              <h3>Edit Option</h3>
              <EditProductSetOption
                cancelFunction={cancel}
                submitFunction={save}
                productSetId={option.productSetID}
                exValues={option}
              />
            </div>,
            document.body
          )}
        </>
      )}
    </>
  );
};
