import { create } from "zustand";

export interface searchResult {
  header: string;
  desc: string;
  link?: string;
}

type Store = {
  searchString: string;
  setSearchString: (string: string) => void;
  searchFunction?: (str: string) => void | undefined;
  setSearchFunction: (fn: (str?: string) => void) => void;
  clearSearchFunction: () => void;
  searchResults: searchResult[];
  setSearchResults: (res: searchResult[]) => void;
  loading: boolean;
  setLoading: (bool: boolean) => void;
};

export const useSearchStore = create<Store>(set => ({
  searchString: "",
  setSearchString: searchString =>
    set(state => ({
      ...state,
      searchString,
    })),
  searchFunction: undefined,
  setSearchFunction: fn =>
    set(state => ({
      ...state,
      searchFunction: fn,
    })),
  clearSearchFunction: () =>
    set(state => ({
      ...state,
      searchFunction: undefined,
    })),
  searchResults: [],
  setSearchResults: (res: any[]) =>
    set(state => ({
      ...state,
      searchResults: res,
    })),
  loading: false,
  setLoading: (bool: boolean) => set(state => ({ ...state, loading: bool })),
}));
