import { processDetailSubProps } from "../types";
import { Button, Modal } from "flowbite-react";
import { useScheme } from "../../../../store/schemeStore";
import DynamicHeroIcon from "../../../../comps/hIcon";
import { useQuery } from "@apollo/client";
import { sort } from "fast-sort";
import { GET_ORDERS_CONDITIONAL } from "../../workorder/gqls";
import { useNavigate } from "react-router-dom";
import Order from "./order";
import checkAuth from "../../../../utils/checkAuth";
import { useOrderStore } from "../../../../store/orderStore";
import { useBoolean } from "usehooks-ts";
import getContrast from "../../../../utils/getContrast";

interface props extends processDetailSubProps {
  title: string;
  promeasureTypes: string[];
  defaultHidden: boolean;
}

export default function ProcessOrders({
  process,
  title,
  promeasureTypes,
  defaultHidden,
}: props) {
  const { data } = useQuery(GET_ORDERS_CONDITIONAL, {
    variables: {
      where: {
        processId: { _eq: process.id },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const orders = data?.workorder
    ? sort(data.workorder)
        .desc("created_at")
        .filter(
          p =>
            p.process?.id == process.id &&
            p.type &&
            promeasureTypes.includes(p.type.name)
        )
    : undefined;

  if (defaultHidden && (!orders || orders.length < 1)) {
    return null;
  }

  return (
    <div
      className="col-span-1 
     flex flex-col gap-2"
    >
      <div className="flex flex-row items-center justify-between">
        <h2 className="mb-2">{title}</h2>
      </div>
      <NewOrder processId={process.id} promeasureTypes={promeasureTypes} />
      {/* List */}
      {orders?.map(order => (
        <Order key={order.id} order={order} />
      ))}
    </div>
  );
}

const NewOrder = ({
  processId,
  promeasureTypes,
}: {
  processId: number;
  promeasureTypes: string[];
}) => {
  const navigate = useNavigate();

  const goToAdd = (type: string) => {
    navigate(`/order/new/${type}/${processId}`);
  };

  const { orderTypes } = useOrderStore();

  const availableOrderTypes = orderTypes.filter(t => {
    if (!promeasureTypes.includes(t.name)) {
      return false;
    }
    if (t.permission) {
      return checkAuth(t.permission);
    }

    return true;
  });

  const { value: open, setFalse, toggle } = useBoolean(false);

  const onNewClick = () => {
    if (availableOrderTypes.length == 1) {
      return goToAdd(availableOrderTypes[0].name);
    }

    toggle();
  };

  const { scheme } = useScheme();

  if (availableOrderTypes.length < 1) {
    return null;
  }

  return (
    <>
      <Button
        onClick={onNewClick}
        size="sm"
        color={scheme == "dark" ? "dark" : "purple"}
      >
        <DynamicHeroIcon class="w-5" icon="PlusCircleIcon" />
      </Button>
      <Modal
        show={open}
        onClose={setFalse}
        className={`${scheme} ${scheme == "dark" && "bg-gray-800"}`}
      >
        <Modal.Header className={`${scheme == "dark" && "bg-gray-800"}`}>
          Select Type of Document
        </Modal.Header>
        <Modal.Body className={`${scheme == "dark" && "bg-gray-800"}`}>
          <div
            className={`${
              scheme == "dark" ? "text-white" : "text-black"
            } flex flex-row gap-2 justify-evenly`}
          >
            {availableOrderTypes.map(type => (
              <Button
                key={type.id}
                size="xs"
                style={{
                  backgroundColor: type.color,
                  color: getContrast(type.color),
                }}
                onClick={() => {
                  goToAdd(type.name);
                }}
              >
                {type.name}
              </Button>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`${scheme == "dark" && "bg-gray-800"} justify-end gap-1`}
        >
          <Button size="xs" color="purple" outline onClick={setFalse}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
