import { Button, Spinner } from "flowbite-react";
import { QUOTE_PARAMS_FIELDS } from "../fragments";
import { ProductSetType } from "../../../workorder/types";
import { QuoteParams } from "../types";
import { ADD_QUOTE_PARAM, UPDATE_QUOTE_PARAM_BY_PK } from "../gqls";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

interface props {
  productSet: ProductSetType;
  compulsoryParams: QuoteParams[];
}

export default function ProductSetSqmControl({
  productSet,
  compulsoryParams,
}: props) {
  const existingParam = compulsoryParams.find(
    p => p.name == "sqmPrice" && p.productSetId == productSet.id
  );

  const sqmPrice = existingParam?.value || {
    wholesale: 0,
    retail: 0,
  };

  const defaultValues = {
    sqmPrice,
  };

  const [update, { loading: updating }] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);
  const [insert, { loading: inserting }] = useMutation(ADD_QUOTE_PARAM);

  const loading = updating || inserting;

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const onSubmit = handleSubmit(data => {
    if (existingParam) {
      update({
        variables: {
          id: existingParam.id,
          set: {
            value: data.sqmPrice,
          },
        },
        onCompleted() {
          reset({
            sqmPrice: data.sqmPrice,
          });
        },
      });
    } else {
      insert({
        variables: {
          object: {
            name: "sqmPrice",
            value: data.sqmPrice,
            productSetId: productSet.id,
          },
        },
        onCompleted() {
          reset({
            sqmPrice: data.sqmPrice,
          });
        },
        update(cache, { data: { insert_quoteParams_one: newParam } }) {
          cache.modify({
            fields: {
              quoteParams(existing = []) {
                const newParamRef = cache.writeFragment({
                  data: newParam,
                  fragment: QUOTE_PARAMS_FIELDS,
                  fragmentName: "QuoteParamsFields",
                });
                return [...existing, newParamRef];
              },
            },
          });
        },
      });
    }
  });

  const cancel = () => {
    reset({
      sqmPrice: sqmPrice,
    });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="p-2 border-b-[1px] flex flex-row items-center justify-between"
    >
      <h3>{productSet.name}</h3>
      <div className="flex flex-row items-center gap-1">
        <input
          type="number"
          className="p-1 text-plum text-lg font-semibold bg-transparent border-none w-12 text-center outline-none"
          placeholder="0"
          {...register("sqmPrice.wholesale")}
        />
        /
        <input
          type="number"
          className="p-1 text-plum text-lg font-semibold bg-transparent border-none w-12 text-center outline-none"
          placeholder="0"
          {...register("sqmPrice.retail")}
        />
        AUD
        {isDirty && (
          <div className="flex flex-row items-center gap-1">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone={"purpleToBlue"}
              type="submit"
            >
              update
              {loading && <Spinner size="xs" className="ml-1" color="purple" />}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
