import { Button, Label, Spinner } from "flowbite-react";
import { vacancyList, vacancyType } from "../../../../../utils/calendarMethods";
import { UserType } from "../../../settings/users/types";
import VacancyList from "./vacancyList";

interface props {
  loading: boolean;
  selectedSales: UserType[];
  vacancies: vacancyList[];
  vacancy?: vacancyType;
  setVacancy: (vacancy?: vacancyType) => void;
  configs: {
    preferEarliest: boolean;
  };
}

export default function Vacancies({
  vacancies,
  loading,
  selectedSales,
  vacancy,
  setVacancy,
  configs,
}: props) {
  return (
    <div className="pt-2 space-y-2">
      {selectedSales.map(ss => (
        <VacancyList
          key={ss.id}
          user={ss}
          vacancies={vacancies}
          preference={configs.preferEarliest}
          vacancy={vacancy}
          setVacancy={setVacancy}
          loading={loading}
        />
      ))}
    </div>
  );
}
