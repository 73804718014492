import { Badge, Table } from "flowbite-react";
import { UserType } from "./types";
import { useNavigate } from "react-router-dom";
import checkAuth from "../../../../utils/checkAuth";

export default function User({ user }: { user: UserType }) {
  const navigate = useNavigate();

  const check = checkAuth([
    "setting_users_edit",
    {
      permission: "setting_users_edit_same_team",
      checkGroup: "userTeam",
      conditionGroup: [user.team?.id || 0],
    },
  ]);

  const moveToDetailPage = () => {
    check && navigate("./" + user.id);
  };

  return (
    <Table.Row
      onClick={moveToDetailPage}
      className={`bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer ${
        !user.active && "opacity-50"
      }`}
    >
      <Table.Cell>{user.team?.name || "none"}</Table.Cell>
      <Table.Cell>{user.auth?.name || "none"}</Table.Cell>
      <Table.Cell>
        {user.firstName} {user.sirName}
      </Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{new Date(user.created_at).toLocaleDateString()}</Table.Cell>
      <Table.Cell>
        <Badge color={user.active ? "purple" : "warning"} className="w-fit">
          {user.active ? "Active" : "InActive"}
        </Badge>
      </Table.Cell>
    </Table.Row>
  );
}
