import { gql } from "@apollo/client";
import { MATERIALS_CORE_FIELDS } from "../materials/fragments";
import { PRODUCT_CORE_FIELDS } from "../settings/products/fragments";
import { PROCESS_CORE_FIELDS } from "../process/fragments";

export const WORKORDER_CORE_FIELDS = gql`
  ${PROCESS_CORE_FIELDS}
  fragment WorkorderCoreFields on workorder {
    id
    createdBy
    user {
      id
      firstName
      sirName
      mobile
    }
    order
    description
    process {
      ...ProcessCoreFields
    }
    created_at
    updated_at
    due
    isDraft
    productionIds
    type {
      id
      name
      color
      priority
      permission
    }
    status {
      id
      name
      color
      priority
    }
    lastUpdatedUser {
      id
      firstName
      sirName
    }
    orderRef
  }
`;

export const DEDUCTION_PRESET_CORE_FIELDS = gql`
  fragment DeductionPresetCoreFields on deductionPreset {
    id
    name
    deductions
    formulas
    optionCode
    optionCondition
    desc
    for
    labour
    position
    productSet {
      id
      name
    }
  }
`;

export const PRODUCT_SET_OPTIONS_FIELDS = gql`
  fragment ProductSetOptionsFields on productSetOptions {
    id
    default
    conditions
    modifiers
    name
    displayName
    optional
    parent
    productSetID
    source
    type
    value
    values
    valuesQty
    optionCode
    optionCondition
    position
    group
    rowNum
    presetID
    allowCustomInput
    nameScope
    disableAutoSelect
  }
`;

export const ORDER_SUMMARY_COLUMN_FIELDS = gql`
  fragment OrderSummaryColumnFields on orderSummaryColumn {
    id
    name
    position
    options
    default
  }
`;

export const PRODUCT_SET_CORE_FIELDS = gql`
  ${PRODUCT_CORE_FIELDS}
  ${PRODUCT_SET_OPTIONS_FIELDS}
  fragment ProductSetCoreFields on productSets {
    id
    name
    productSetOptions {
      ...ProductSetOptionsFields
    }
    calcs
    sizeLimit
    qtyCalc
    hideItems
    product {
      ...ProductCoreFields
    }
    orderPresets
    position
    warnings
  }
`;

const DEDUCTION_REAL_CORE_FIELDS = gql`
  ${MATERIALS_CORE_FIELDS}
  ${PRODUCT_SET_CORE_FIELDS}
  fragment DeductionRealCoreFields on deductions {
    id
    name
    inventory {
      ...MaterialsCoreFields
    }
    width
    height
    widthAddition
    heightAddition
    usedItems
    additionalValue
    imageTexts
    optionCode
    optionCondition
    pending
  }
`;

export const DEDUCTION_CORE_FIELDS = gql`
  ${DEDUCTION_REAL_CORE_FIELDS}
  fragment DeductionCoreFields on deductions {
    ...DeductionRealCoreFields
    image
    parentDeduction {
      ...DeductionRealCoreFields
    }
    productSet {
      ...ProductSetCoreFields
    }
  }
`;
export const DEDUCTION_FIELDS_MIN = gql`
  ${DEDUCTION_REAL_CORE_FIELDS}
  fragment DeductionFieldsMin on deductions {
    ...DeductionRealCoreFields
    parentDeduction {
      ...DeductionRealCoreFields
    }
    productSet {
      id
      name
    }
  }
`;
export const LAYOUT_CORE_FIELDS = gql`
  ${PRODUCT_SET_CORE_FIELDS}
  fragment LayoutCoreFields on layouts {
    id
    productSet {
      ...ProductSetCoreFields
    }
    image
    code
    name
    config
    parent
  }
`;

export const PRODUCT_IMAGES_CORE_FIELDS = gql`
  fragment ProductImagesCoreFields on productImages {
    id
    productSetId
    productSet {
      id
      name
    }
    image
    options
    desc
    invert
  }
`;
