import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { processDetailSubProps } from "../types";
import { gql, useQuery, TypedDocumentNode } from "@apollo/client";
import { CONTACT_CORE_FIELDS } from "../../contact/fragments";
import { useRef, useState } from "react";
import { ListGroup } from "flowbite-react";
import { ContactArrayType } from "../../contact/types";
import { useOnClickOutside } from "usehooks-ts";

export const GET_CONTACTS_BY_IDS: TypedDocumentNode<ContactArrayType> = gql`
  ${CONTACT_CORE_FIELDS}
  query GET_CONTACTS_BY_IDS($ids: [Int!]) {
    contacts(where: { id: { _in: $ids } }) {
      ...ContactCoreFields
    }
  }
`;

export default function ProcessActions({ process }: processDetailSubProps) {
  const { contacts } = process;

  const { data: data_contacts } = useQuery(GET_CONTACTS_BY_IDS, {
    variables: { ids: contacts },
    fetchPolicy: "cache-and-network",
  });

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
    setMailOpen(false);
  });

  const processContacts = data_contacts?.contacts || [];
  const processContactWithNumbers = processContacts.filter(
    c => c.number && c.number !== ""
  );
  const processContactWithEmails = processContacts.filter(
    c => c.mail && c.mail !== ""
  );

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
    setMailOpen(false);
  };

  const call = (num?: string) => {
    if (!num) {
      return;
    }
    window.location.href = `tel:${num}`;
    setOpen(false);
  };

  const callOrList = () => {
    if (processContactWithNumbers.length < 1) {
      return;
    }
    if (processContactWithNumbers.length == 1) {
      call(processContactWithNumbers[0].number);
    }
    if (processContactWithNumbers.length > 1) {
      toggleOpen();
    }
  };

  const [mailOpen, setMailOpen] = useState(false);
  const toggleMailOpen = () => {
    setMailOpen(!mailOpen);
    setOpen(false);
  };

  const mail = (address?: string) => {
    if (!address) {
      return;
    }
    window.location.href = `mailto:${address}`;
    setMailOpen(false);
  };

  const mailOrList = () => {
    if (processContactWithEmails.length < 1) {
      return;
    }
    if (processContactWithEmails.length == 1) {
      mail(processContactWithEmails[0].mail);
    }
    if (processContactWithEmails.length > 1) {
      toggleMailOpen();
    }
  };

  return (
    <div ref={ref} className="flex flex-row items-center gap-3 z-30">
      <div className="relative">
        <EnvelopeIcon
          className="w-5 cursor-pointer hover:text-grass"
          onClick={mailOrList}
        />
        {mailOpen && (
          <ListGroup className="absolute top-[100%] mt-2 w-max right-0">
            {processContactWithEmails?.map(contact => (
              <ListGroup.Item
                key={contact.id}
                onClick={() => {
                  mail(contact.mail);
                }}
              >
                {contact.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
      <div className="relative">
        <PhoneIcon
          className="w-5 cursor-pointer hover:text-grass"
          onClick={callOrList}
        />
        {open && (
          <ListGroup className="absolute top-[100%] mt-2 w-max right-0">
            {processContactWithNumbers?.map(contact => (
              <ListGroup.Item
                key={contact.id}
                onClick={() => {
                  call(contact.number);
                }}
              >
                {contact.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    </div>
  );
}
