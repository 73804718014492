import { sort } from "fast-sort";
import { AssignmentType } from "./types";
import ListItem from "./listItem";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import { useScheme } from "../../../store/schemeStore";
import { useNavStore } from "../../../store/navStore";
import { useIsMounted } from "usehooks-ts";

interface props {
  assignment: AssignmentType[];
}

export default function AssignmentList({ assignment }: props) {
  const { scheme } = useScheme();
  const length = assignment.length;

  const sortedAssignment = sort(assignment).asc([
    p => p.type.priority,
    p => p.status.priority,
    p => p.process.name,
  ]);
  const renderRow: ListRowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style} className="row pb-[10px]">
        <ListItem assignment={sortedAssignment[index]} />
      </div>
    );
  };
  const mounted = useIsMounted();

  const { containerRef } = useNavStore();

  return (
    <section className="px-6 h-[calc(100vh-62px)] mt-1">
      <AutoSizer className={scheme}>
        {({ width, height }) => (
          <List
            onScroll={() => {
              if (containerRef && mounted()) {
                containerRef.scrollTo({
                  behavior: "smooth",
                  top: containerRef.scrollHeight,
                });
              }
            }}
            height={height}
            width={width}
            rowCount={length}
            rowHeight={109.77 + 10}
            rowRenderer={renderRow}
            overscanRowCount={3}
          />
        )}
      </AutoSizer>
    </section>
  );
}
