import { create } from "zustand";
import axios from "axios";
import { useDeviceStore } from "./deviceStore";
import { addAlert } from "./alertStore";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
// @ts-expect-error
import md5 from "md5";
import { z } from "zod";
import { TeamType } from "../routes/protected/settings/teams/types";
import { Coord } from "../utils/geocode";
import { TermType } from "../routes/protected/settings/users/types";
import { useNotificationStore } from "./notificationStore";
import { useScheduleStore } from "./scheduleStore";

const API = import.meta.env.VITE_API;

type HEX = `#${string}`;

export type INewUser = {
  firstName: string;
  sirName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  teamID?: string;
  calId?: string;
  color?: HEX;
  address?: string;
  baseLocation?: Coord;
  mobile?: string;
};

export type User = {
  id: number;
  firstName: string;
  sirName: string;
  email: string;
  created_at: string;
  calId?: string;
  color?: string;
  active: boolean;
  auth: {
    id: number;
    name: string;
  };
  team?: TeamType;
  hours: TermType;
  subUsers: number[];
  permissions: Permission[];
  avatarUrl?: string;
  sessionId?: number;
  checked: boolean;
  address?: string;
  mobile?: string;
  baseLocation?: Coord;
};

export type LoginUser = {
  email: string;
  password: string;
  persist?: boolean;
};

export type Permission = {
  id: number;
  name: string;
  description: string;
};

export type Auth = {
  id: number;
  name: string;
  description: string;
  permissions?: number[];
};

type AuthStore = {
  user?: User;
  setUser: (user: User) => void;
  removeUser: () => void;
  loading: boolean;
  setLoading: (bool: boolean) => void;
  loggingIn: boolean;
  setLoggingIn: (bool: boolean) => void;
};

export const useAuthStore = create<AuthStore>(set => ({
  loading: true,
  setLoading: bool =>
    set(state => ({
      ...state,
      loading: bool,
    })),
  user: undefined,
  setUser: user =>
    set(state => ({
      ...state,
      user: user,
      loading: false,
    })),
  removeUser: () => {
    localStorage.removeItem("processFilters");
    localStorage.removeItem("orderFilters");
    localStorage.removeItem("processView");
    localStorage.removeItem("recordFilters");
    localStorage.removeItem("productionFilters");
    localStorage.removeItem("notifications");
    localStorage.removeItem("assignmentFilters");
    localStorage.removeItem("scheduleFilters");
    localStorage.setItem("persist", "false");
    return set(state => ({
      ...state,
      user: undefined,
      loading: false,
    }));
  },
  loggingIn: false,
  setLoggingIn: bool =>
    set(state => ({
      ...state,
      loggingIn: bool,
    })),
}));

export const useLoadUser = () => {
  const { setLoading, setUser, setLoggingIn, removeUser } = useAuthStore();
  const { token: pushToken } = useNotificationStore();

  return async () => {
    try {
      setLoading(true);
      setLoggingIn(true);

      const { location, ip, agent } = useDeviceStore.getState();

      const res = await axios.post(API + "/auth/loadUser", {
        location,
        ip,
        agent: agent?.summary,
        pushToken,
      });

      const user = res.data?.user;

      if (user) {
        let avatarUrl = `https://www.gravatar.com/avatar/${md5(
          user.email
        )}?d=mp`;

        sessionStorage.setItem("accessToken", res.data.token);
        setUser({ ...user, avatarUrl });
      }

      setLoading(false);
      setLoggingIn(false);
      return true;
    } catch (error: any) {
      if (error?.response?.data?.msg) {
        const errorMessage = error?.response?.data?.msg;
        addAlert({
          type: "failure",
          message: errorMessage,
        });
      } else {
        console.log(error);
      }

      setLoading(false);
      setLoggingIn(false);

      localStorage.setItem("persist", "false");
      removeUser();

      return false;
    }
  };
};

export const useLogin = () => {
  const { setLoggingIn, setUser, removeUser } = useAuthStore();
  const { location, ip, agent } = useDeviceStore();
  const { token: pushToken } = useNotificationStore();

  return async (user: LoginUser) => {
    setLoggingIn(true);

    try {
      const res = await axios.post(API + "/auth/login", {
        ...user,
        location,
        ip,
        agent: agent?.summary,
        pushToken,
      });

      const User = res.data?.user;

      if (User) {
        const avatarUrl = `https://www.gravatar.com/avatar/${md5(
          User.email
        )}?d=mp`;

        sessionStorage.setItem("accessToken", res.data.token);
        localStorage.setItem("persist", user.persist ? "true" : "false");
        setUser({ ...User, avatarUrl });
      }

      setLoggingIn(false);
      return true;
    } catch (error: any) {
      if (error?.response?.data?.msg) {
        const errorMessage = error?.response?.data?.msg;
        addAlert({
          type: "failure",
          message: errorMessage,
        });
      } else {
        console.log(error);
      }
      setLoggingIn(false);
      removeUser();
      sessionStorage.removeItem("accessToken");
      return false;
    }
  };
};

export const newUserSchema = z
  .object({
    firstName: z.string().min(1, { message: "First Name is required" }),
    sirName: z.string().min(1, { message: "Sir Name is required" }),
    email: z.string().email({ message: "Please input a valid email" }),
    password: z
      .string()
      .min(8, { message: "Please input a password longer than 8 characters" }),
    passwordConfirm: z.string(),
    teamID: z.string(),
    calId: z.string().optional(),
    color: z.string().optional(),
    address: z.string().optional(),
    mobile: z.string().optional(),
    baseLocation: z
      .object({
        lat: z.number(),
        lng: z.number(),
      })
      .optional(),
  })
  .refine(data => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ["passwordConfirm"],
  })
  .refine(data => data.teamID !== "0", {
    message: "Please select your team",
    path: ["teamID"],
  });

export const useRegister = () => {
  const { setLoggingIn, setUser } = useAuthStore();
  const { location, ip, agent } = useDeviceStore.getState();
  const { token: pushToken } = useNotificationStore();
  const navigate = useNavigate();

  return async (user: INewUser) => {
    try {
      setLoggingIn(true);
      const res = await axios.post(API + "/auth/register", {
        user,
        location,
        ip,
        agent: agent?.summary,
        pushToken,
      });

      if (res.data) {
        addAlert({
          type: "success",
          message: "User created successfully, please await for activation",
        });

        // const User = res.data.newUser;

        // const avatarUrl = `https://www.gravatar.com/avatar/${md5(
        //   User.email
        // )}?d=mp`;

        // setUser({ ...User, avatarUrl });

        // sessionStorage.setItem("accessToken", res.data.token);
        // localStorage.setItem("persist", "true");
      }
      setLoggingIn(false);
      // navigate("/");
      return true;
    } catch (error: any) {
      if (error?.response?.data?.msg) {
        const errorMessage = error?.response?.data?.msg;
        addAlert({
          type: "failure",
          message: errorMessage,
        });
      } else {
        console.log(error);
      }
      setLoggingIn(false);
      return false;
    }
  };
};

export const recover = async (email: string) => {
  try {
    await axios.post(API + "/auth/recover", {
      email,
    });
  } catch (error: any) {
    if (error?.response?.data?.msg) {
      const errorMessage = error?.response?.data?.msg;
      addAlert({
        type: "failure",
        message: errorMessage,
      });
    } else {
      console.log(error);
    }
  }
};

export const reset = async (token: string, password: string) => {
  try {
    if (token == "") {
      return addAlert({ type: "warning", message: "token is invalid" });
    }

    const res = await axios.post(API + "/auth/reset", { token, password });

    if (res.data) {
      addAlert({
        type: "success",
        message: "password has been successfully reset, please log in",
      });
      return true;
    } else {
      addAlert({
        type: "failure",
        message: "something went wrong, please check with system admin",
      });
      return false;
    }
  } catch (error: any) {
    if (error?.response?.data?.msg) {
      const errorMessage = error?.response?.data?.msg;
      addAlert({
        type: "failure",
        message: errorMessage,
      });
    } else {
      console.log(error);
    }

    return;
  }
};

const DELETE_DEVICES = gql`
  mutation delete_devices($device: String!, $userId: Int!, $ip: String!) {
    delete_devices(
      where: {
        device: { _eq: $device }
        userId: { _eq: $userId }
        ip: { _eq: $ip }
      }
    ) {
      affected_rows
    }
  }
`;

export const useLogOut = () => {
  const [delete_devices] = useMutation(DELETE_DEVICES);
  const { agent, ip } = useDeviceStore();
  const { setCalendars, setUserFilter } = useScheduleStore();

  const { user, removeUser } = useAuthStore();

  const variables = {
    device: agent?.summary,
    userId: user?.id,
    ip,
  };

  return async () => {
    try {
      removeUser();
      setCalendars([]);
      setUserFilter([]);
      await delete_devices({ variables });
      sessionStorage.removeItem("accessToken");
    } catch (error) {
      console.log(error);
      return false;
    }
  };
};
