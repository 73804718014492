import { useEffect, useState } from "react";
import Heading from "../../../../comps/heading";
import { Button, Modal, Select, Spinner } from "flowbite-react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import { GET_ORDERS_AGG, STREAM_ORDER } from "../gqls";
import { useQuery } from "@apollo/client";
import Filters from "../filters";
import { cloneDeep } from "apollo-utilities";
import { useOrderStore, refineFilters } from "../../../../store/orderStore";
import { useAuthStore } from "../../../../store/authStore";
import { sort } from "fast-sort";
import OrderItem from "./orderItem";
import { useNavigate } from "react-router-dom";
import { ListRowRenderer } from "react-virtualized";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";
import { useScheme } from "../../../../store/schemeStore";
import { useNavStore } from "../../../../store/navStore";
import { useBoolean, useIsMounted } from "usehooks-ts";
import checkAuth from "../../../../utils/checkAuth";
import getContrast from "../../../../utils/getContrast";

export default function OrderList() {
  const { filters, cursor, setCursor } = useOrderStore();

  const [limit] = useState(100);
  const [totalLength, setTotalLength] = useState(0);

  const { user } = useAuthStore();

  const { data, loading, fetchMore, subscribeToMore } = useQuery(
    GET_ORDERS_AGG,
    {
      variables: {
        where: refineFilters(filters, user).where,
        offset: 0,
        limit,
      },
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        setCursor(new Date().toISOString());
        setTotalLength(data.workorder_aggregate?.aggregate?.count || 0);
      },
    }
  );

  const ids = data?.workorder.map(p => p.id);

  const loadMore = () => {
    const currentLength = data?.workorder.length || 0;
    fetchMore({
      variables: {
        where: refineFilters(filters, user).where,
        offset: currentLength,
        limit,
      },
    });
  };

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: STREAM_ORDER,
      variables: {
        where: {
          _or: [
            { _and: refineFilters(filters).where._and },
            { id: { _in: ids || [] } },
          ],
        },
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.workorder);
        const newFeedItem = subscriptionData.data.workorder_stream;
        const existing = previousData.filter(
          p => !newFeedItem.find(i => i.id == p.id)
        );

        return Object.assign({}, previous, {
          workorder: [...newFeedItem, ...existing],
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [filters, ids]);

  const orders = data?.workorder;

  const orderList = (
    orders
      ? sort(orders).asc([
          order => order.process.createdUser?.team?.name,
          order => order.status.priority,
          order => order.due,
          order => order.created_at,
        ])
      : []
  ).filter(order => user?.auth.name !== "dealer" || order.createdBy == user.id);

  const orderLength = orderList ? orderList.length : 0;

  const renderRow: ListRowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style} className="row pb-[10px]">
        <OrderItem order={orderList[index]} />
      </div>
    );
  };

  const { scheme } = useScheme();

  const { containerRef } = useNavStore();
  const mounted = useIsMounted();

  return (
    <main className="select-none flex flex-col flex-1">
      <Headers
        loadMore={loadMore}
        loading={loading}
        orderLength={orderLength}
        totalLength={totalLength}
      />
      {/* Content Part */}
      <section className="px-6 block h-[calc(100vh-62px)] mt-1">
        <AutoSizer className={scheme}>
          {({ width, height }) => (
            <List
              onScroll={() => {
                if (containerRef && mounted()) {
                  containerRef.scrollTo({
                    behavior: "smooth",
                    top: containerRef.scrollHeight,
                  });
                }
              }}
              height={height}
              width={width}
              rowCount={orderLength}
              rowHeight={101.77 + 10}
              rowRenderer={renderRow}
              overscanRowCount={3}
            />
          )}
        </AutoSizer>
      </section>
    </main>
  );
}

interface headersProps {
  loadMore: () => void;
  orderLength: number;
  totalLength: number;
  loading: boolean;
}

const Headers = ({
  loadMore,
  orderLength,
  totalLength,
  loading,
}: headersProps) => {
  const [filtering, setFiltering] = useState(false);
  const toggleFiltering = () => {
    setFiltering(!filtering);
  };

  const { orderTypes } = useOrderStore();

  const orderRouteName = () => {
    const filteredTypes = orderTypes.filter(ot => {
      if (ot.permission == null) {
        return false;
      }
      return checkAuth(ot.permission);
    });

    if (filteredTypes?.length === 1) {
      return filteredTypes[0].name;
    }

    return "ProMeasure";
  };

  return (
    <>
      {/* Header Part */}
      <div className="px-6 mb-1">
        <div className="flex flew-row justify-between h-8">
          <Heading name={orderRouteName()} />
          <NewOrder />
        </div>
        <div className="flex flex-row text-grass mb-10">
          quotes, measures, wholesale orders, workorders
        </div>
        {/* Actions */}
        <div className="flex flex-row justify-between gap-2 flex-wrap items-center">
          <div className="flex flex-row gap-2">
            <div
              onClick={toggleFiltering}
              className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
            >
              <AdjustmentsHorizontalIcon className="w-4" />
              Filters
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-2 flex-1 h-8">
            {loading && <Spinner light color="purple" />}
            <div className="text-sm">
              {orderLength}
              <span className="dark:text-grass text-plum"> of </span>
              {totalLength < (orderLength || 0) ? orderLength : totalLength}
            </div>
            {orderLength < totalLength && (
              <Button size="xs" onClick={loadMore} color="purple" outline>
                more
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* Filter */}
      <Filters filtering={filtering} />
    </>
  );
};

const NewOrder = () => {
  const navigate = useNavigate();

  const goToAdd = (type: string) => {
    navigate(`/order/new/${type}`);
  };

  const { orderTypes } = useOrderStore();

  const availableOrderTypes = orderTypes.filter(t => {
    if (t.permission) {
      return checkAuth(t.permission);
    }
    return false;
  });

  const { value: open, setTrue, setFalse, toggle } = useBoolean(false);

  const onNewClick = () => {
    if (availableOrderTypes.length == 1) {
      return goToAdd(availableOrderTypes[0].name);
    }

    toggle();
  };

  const { scheme } = useScheme();

  if (availableOrderTypes.length < 1) {
    return null;
  }

  return (
    <>
      <Button
        size="sm"
        outline
        gradientDuoTone="purpleToBlue"
        onClick={onNewClick}
      >
        NEW
      </Button>
      <Modal
        show={open}
        onClose={setFalse}
        className={`${scheme} ${scheme == "dark" && "bg-gray-800"}`}
      >
        <Modal.Header className={`${scheme == "dark" && "bg-gray-800"}`}>
          Select Type of Document
        </Modal.Header>
        <Modal.Body className={`${scheme == "dark" && "bg-gray-800"}`}>
          <div
            className={`${
              scheme == "dark" ? "text-white" : "text-black"
            } flex flex-row gap-2 justify-evenly`}
          >
            {availableOrderTypes.map(type => (
              <Button
                key={type.id}
                size="xs"
                style={{
                  backgroundColor: type.color,
                  color: getContrast(type.color),
                }}
                onClick={() => {
                  goToAdd(type.name);
                }}
              >
                {type.name}
              </Button>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`${scheme == "dark" && "bg-gray-800"} justify-end gap-1`}
        >
          <Button size="xs" color="purple" outline onClick={setFalse}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
