import { Button, Label, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Link } from "react-router-dom";
import { recover } from "../../store/authStore";
import { useEffect, useState } from "react";
import { addAlert } from "../../store/alertStore";

const userSchema = z.object({
  email: z.string().email({ message: "Please input a valid email" }),
});

export default function Recover() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(userSchema),
    defaultValues: {
      email: "",
    },
  });

  const [restrict, setRestrict] = useState(false);

  useEffect(() => {
    if (restrict) {
      setTimeout(() => {
        setRestrict(false);
      }, 5000);
    }
  }, [restrict]);

  const onSubmit = handleSubmit(async data => {
    if (!restrict) {
      recover(data.email);

      addAlert({
        type: "success",
        message: "recovery mail sent",
      });

      setRestrict(true);
    } else {
      addAlert({
        type: "warning",
        message: "Please wait before making another request",
      });
    }
  });

  return (
    <div className="h-full flex flex-col items-center">
      <div className="font-bold text-2xl mt-20 mb-10 select-none">
        Send Recovery Mail
      </div>
      <form
        onSubmit={onSubmit}
        className="w-[576px] max-w-[80%] shadow-md bg-white dark:bg-gray-800 rounded-md p-8  flex flex-col gap-4"
      >
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Email" />
          </div>
          <TextInput
            type="email"
            placeholder="name@example.com"
            color={errors.email?.message ? "failure" : undefined}
            helperText={errors.email?.message || ""}
            {...register("email")}
          />
        </div>

        <div className="flex gap-2 justify-end mt-5">
          <Button gradientDuoTone="purpleToBlue" size="sm" type="submit">
            Send Email
          </Button>
          <Link to="/login" relative="path">
            <Button outline={true} gradientDuoTone="purpleToBlue" size="sm">
              Login
            </Button>
          </Link>
        </div>
      </form>
    </div>
  );
}
