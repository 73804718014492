import { Select } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import { option } from "../../../types";
import DeductionsSelect from "./deductionsSelect";
import MaterialSelect from "./materialSelect";
import PresetSelect from "./PresetSelect";
import {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";

interface props {
  optionCoord: string;
  optionsCoord: string;
  itemCoord?: string;
  optionName: string;
  disabled: boolean;
  productIndex: string;
}

export default function SelectComp({
  optionCoord,
  optionsCoord,
  optionName,
  itemCoord,
  disabled,
  productIndex,
}: props) {
  const { register, setValue, control, getValues } = useFormContext();

  const [formOptions, setFormOptions] = useState<option[]>(
    getValues(optionsCoord) || []
  );

  const OptionValue: option["value"] = useWatch({
    name: `${optionCoord}.value`,
    control,
  });

  const optionValues: option["values"] = useWatch({
    name: `${optionCoord}.values`,
    control,
  });
  const optionConditions: option["conditions"] = useWatch({
    name: `${optionCoord}.conditions`,
    exact: true,
    control,
  });
  const optionSource: option["source"] = useWatch({
    name: `${optionCoord}.source`,
    exact: true,
    control,
  });
  const optionDefault: option["default"] = useWatch({
    name: `${optionCoord}.default`,
    exact: true,
    control,
  });

  const availableOptions =
    !optionConditions || !formOptions || !Array.isArray(formOptions)
      ? optionValues
      : optionValues?.filter(v => {
          const conditionsForThisValue = optionConditions?.filter(
            c => c.value?.toString() == v.toString()
          );

          if (!conditionsForThisValue || conditionsForThisValue.length < 1) {
            return true;
          }

          const check = conditionsForThisValue.every(condition => {
            return condition.conditions.every(condition => {
              const optionToCheck = formOptions.find(
                o => o.id == condition.option
              );
              if (!optionToCheck) {
                return false;
              }
              if (condition.param) {
                return (
                  optionToCheck.populatedValue?.params &&
                  optionToCheck.populatedValue.params.find(
                    // @ts-ignore
                    p =>
                      // @ts-ignore
                      p.name == condition.param.split("::")[0] &&
                      // @ts-ignore
                      p.value == condition.param.split("::")[1]
                  )
                );
              }

              return condition.values
                .map(v => v.toString())
                .includes(optionToCheck.value.toString());
            });
          });
          return check;
        });

  // if (optionName == "Colour") {
  //   console.log(availableOptions);
  // }

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (optionSource) {
      return;
    }

    if (!availableOptions) {
      return;
    }

    if (availableOptions.length == 0) {
      if (OptionValue !== "") {
        setValue(`${optionCoord}.value`, "");
      }
      return;
    }

    const currentValue = getValues(`${optionCoord}.value`);
    const isTouched = getValues(`${optionCoord}.isTouched`);

    if (
      !isTouched ||
      !availableOptions.find(o => o.toString() == OptionValue.toString())
    ) {
      const newVal =
        availableOptions.find(o => o == optionDefault) || availableOptions[0];

      if (newVal.toString() == currentValue?.toString()) {
        return;
      }
      return setValue(`${optionCoord}.value`, newVal.toString() || "");
    }
  }, [availableOptions]);

  return (
    <>
      {!optionSource && (
        <Select
          {...register(`${optionCoord}.value`)}
          sizing="sm"
          disabled={disabled}
          onClick={() => {
            setValue(`${optionCoord}.isTouched`, true);
          }}
        >
          {availableOptions?.map((v, i) => (
            <option key={v || i} value={v}>
              {v}
            </option>
          ))}
        </Select>
      )}
      {optionSource === "deductions" && (
        <DeductionsSelect
          availableOptions={availableOptions}
          disabled={disabled}
          optionCoord={optionCoord}
          formOptions={formOptions}
          optionValue={OptionValue}
          optionName={optionName}
          optionValues={optionValues}
          optionDefault={optionDefault}
        />
      )}
      {optionSource === "inventory" && (
        <MaterialSelect
          availableOptions={availableOptions}
          disabled={disabled}
          optionCoord={optionCoord}
          formOptions={formOptions}
          optionValue={OptionValue}
          optionName={optionName}
          optionValues={optionValues}
          optionDefault={optionDefault}
        />
      )}
      {optionSource === "deductionPreset" && (
        <PresetSelect
          availableOptions={availableOptions}
          disabled={disabled}
          optionCoord={optionCoord}
          itemCoord={itemCoord}
          productIndex={productIndex}
          formOptions={formOptions}
          optionName={optionName}
          optionValue={OptionValue}
          optionValues={optionValues}
          optionDefault={optionDefault}
        />
      )}
      <OptionsSideEffects
        optionName={optionName}
        optionsCoord={optionsCoord}
        setFormOptions={setFormOptions}
        optionCoord={optionCoord}
      />
    </>
  );
}

interface optionsSEProps {
  optionsCoord: string;
  optionCoord: string;
  optionName: string;
  setFormOptions: Dispatch<SetStateAction<option[]>>;
}

const OptionsSideEffects = ({
  optionsCoord,
  optionCoord,
  setFormOptions,
  optionName,
}: optionsSEProps) => {
  const { control } = useFormContext();
  const formOptions: option[] =
    useWatch({
      name: optionsCoord,
      control,
    }) || [];

  const formOptionsNotMe = formOptions.filter(
    (fo, i) => i !== Number(optionCoord.split(".").pop())
  );

  const [current, setCurrent] = useState<string>(
    formOptionsNotMe
      .map(
        o =>
          `${o.id}::${o.value}::${JSON.stringify(o.populatedValue)}::${
            o.noCalc
          }`
      )
      .join("|")
  );

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const stringifiedOptions = formOptionsNotMe
      .map(
        o =>
          `${o.id}::${o.value}::${JSON.stringify(o.populatedValue)}::${
            o.noCalc
          }`
      )
      .join("|");

    if (stringifiedOptions == current) {
      return;
    }

    setCurrent(stringifiedOptions);
    setFormOptions(formOptionsNotMe);
  }, [JSON.stringify(formOptionsNotMe)]);

  return null;
};

const MemoizedOptionsSideEffects = memo(OptionsSideEffects);
