import { useQuery } from "@apollo/client";
import Group from "./group";
import {
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
  TouchSensor,
  Active,
  DragOverlay,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useState } from "react";
import { useScheme } from "../../../../store/schemeStore";
import { createPortal } from "react-dom";
import BoardItem from "./boardItem";
import { gql, useMutation } from "@apollo/client";
import { PRODUCTION_CORE_FIELDS } from "../../shared/productions/fragments";
import { useProcessStore } from "../../../../store/processStore";
import { useAuthStore } from "../../../../store/authStore";
import { ProductionStatusType } from "../../settings/production/productionStatuses/types";
import { ProductionType } from "../../shared/productions/types";
import { useProductionStore } from "../../../../store/productionStore";
import { GET_PRODUCTION_STATUSES_NO_PID } from "../../settings/production/productionStatuses/productionStatuses";

interface props {
  production: ProductionType[];
}

export interface statusGroup {
  status: ProductionStatusType;
  items: ProductionType[];
}

export const UPDATE_PRODUCTION_STATUS = gql`
  ${PRODUCTION_CORE_FIELDS}
  mutation UPDATE_PRODUCTION_STATUS($id: Int!, $status: Int!) {
    update_productions_by_pk(
      pk_columns: { id: $id }
      _set: { statusID: $status }
    ) {
      ...ProductionCoreFields
    }
  }
`;

export default function ProductionBoard({ production }: props) {
  const { user } = useAuthStore();

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 20,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const [active, setActive] = useState<Active | undefined>(undefined);
  const [updating, setUpdating] = useState<number | undefined>(undefined);
  const { scheme } = useScheme();
  const { filters } = useProductionStore();

  const validStatuses: number[] =
    filters?.find(f => f.name == "Status")?.values || [];

  const [update_production_status] = useMutation(UPDATE_PRODUCTION_STATUS);
  const { data } = useQuery(GET_PRODUCTION_STATUSES_NO_PID);
  if (!data?.productionStatus) {
    return null;
  }

  const handleDragStart = ({ active }: { active: Active }) => {
    if (updating) {
      return;
    }
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const dropStatus = over?.data?.current as ProductionStatusType;
    const item = active.data?.current as ProductionType;

    setActive(undefined);
    if (!dropStatus || !item) {
      return;
    }
    if (dropStatus.id == item.productionStatus.id) {
      return;
    }

    setUpdating(item.id);

    update_production_status({
      variables: {
        id: item.id,
        status: dropStatus.id,
      },
      optimisticResponse: {
        update_productions_by_pk: {
          __typename: "productions",
          ...item,
          productionStatus: dropStatus,
        },
      },
      onCompleted() {
        setUpdating(undefined);
      },
    });
  };

  const handleDragCancel = () => {
    setActive(undefined);
  };

  const statuses: ProductionStatusType[] = data.productionStatus.filter(f =>
    validStatuses.includes(f.id)
  );

  const sortedProduction = statuses?.reduce<statusGroup[]>((prev, cur) => {
    const items = production.filter(p => p.productionStatus.id == cur.id);
    return [...prev, { status: cur, items }];
  }, []);
  // TODO : filter out none selected statuses

  return (
    <section className="h-[calc(100vh-64px)] relative px-6 gap-4 flex flex-row overflow-scroll md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700">
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        modifiers={[restrictToWindowEdges]}
      >
        {sortedProduction.map(sp => (
          <Group
            key={sp.status.id}
            group={sp}
            active={active}
            updating={updating}
          />
        ))}
        {createPortal(
          <DragOverlay className={`${scheme == "dark" ? "dark" : ""}`}>
            {active?.data.current ? (
              <BoardItem
                item={active.data.current as ProductionType}
                active={active}
                overlay={true}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </section>
  );
}
