import { gql, useQuery, TypedDocumentNode } from "@apollo/client";
import { PRODUCT_CORE_FIELDS } from "./fragments";
import { ProductArrayType } from "./types";
import { addAlert } from "../../../../store/alertStore";
import { Breadcrumb, Spinner } from "flowbite-react";
import {
  HomeIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import checkAuth from "../../../../utils/checkAuth";
import { useState } from "react";
import Product from "./product";
import AddProduct from "./addProduct";
import { useNavigate } from "react-router-dom";

export const GET_PRODUCTS: TypedDocumentNode<ProductArrayType> = gql`
  ${PRODUCT_CORE_FIELDS}
  query GET_PRODUCTS {
    products {
      ...ProductCoreFields
    }
  }
`;

export default function Products() {
  const { data, error, loading } = useQuery(GET_PRODUCTS);
  if (error) {
    console.log(error);
    addAlert({ message: "could not load products", type: "failure" });
  }

  const [adding, setAdding] = useState(false);

  const toggleAdding = () => {
    setAdding(!adding);
  };

  const editAuth = checkAuth("setting_products_edit");
  const viewAuth = checkAuth("setting_products_access");

  const navigate = useNavigate();

  return (
    <div className="mb-1 flex-grow mx-auto">
      <section className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-4">
        <nav className="w-full">
          <Breadcrumb className="w-full">
            <Breadcrumb.Item
              className="cursor-pointer"
              onClick={() => {
                navigate("../");
              }}
            >
              <HomeIcon className="w-5 mr-2" />
              Settings
            </Breadcrumb.Item>

            <Breadcrumb.Item>Products</Breadcrumb.Item>
          </Breadcrumb>
        </nav>
        <main className="space-y-4">
          <div className="mb-1 flex-grow  mx-auto">
            <div className="flex flex-row justify-between items-center">
              <h1>Products</h1>
              {editAuth && (
                <div onClick={toggleAdding} className="w-6 cursor-pointer">
                  {adding ? (
                    <MinusCircleIcon className="text-red-500" />
                  ) : (
                    <PlusCircleIcon className="text-grass hover:animate-pulse" />
                  )}
                </div>
              )}
            </div>
            {loading && !data && (
              <div className="flex flex-row justify-center mt-4 mb-6">
                <Spinner color="purple" size="xl" />
              </div>
            )}
            <section className="my-4 space-y-2">
              {adding && <AddProduct toggleAdding={toggleAdding} />}
              {!viewAuth && "no access"}
              {viewAuth &&
                data?.products?.map(product => (
                  <Product key={product.id} product={product} />
                ))}
            </section>
          </div>
        </main>
      </section>
    </div>
  );
}
