import { Checkbox, Label, ToggleSwitch } from "flowbite-react";
import { Dispatch, SetStateAction } from "react";
import { ContactFilter } from "./types";

interface filterProps {
  filter: ContactFilter;
  newFilters: ContactFilter[];
  setNewFilters: Dispatch<SetStateAction<ContactFilter[]>>;
  setTouched: (bool: boolean) => void;
}

const Filter = ({
  filter,
  newFilters,
  setNewFilters,
  setTouched,
}: filterProps) => {
  const setNew = (revisedFilter: ContactFilter) => {
    setTouched(true);
    setNewFilters(
      newFilters.map(f => {
        if (f.name == filter.name) {
          return revisedFilter;
        } else {
          return f;
        }
      })
    );
  };

  const toggleOption = (value: any) => {
    let newValues = [];
    // @ts-expect-error
    if (filter.values?.find(v => v == value)) {
      // @ts-expect-error
      newValues = filter.values?.filter(v => v !== value);
    } else {
      newValues = filter.values?.concat(value);
    }

    setNew({
      ...filter,
      values: newValues,
    });
  };

  return (
    <div className="flex flex-col flex-wrap min-w-[250px] flex-1">
      <div className="flex flex-row text-sm items-center gap-2 justify-between">
        {filter.name}
        <div className="flex flex-row flex-wrap gap-2 p-2">
          {filter.options?.map(o => (
            <div
              key={o.name}
              onClick={() => {
                toggleOption(o.value);
              }}
              className="flex flex-row gap-1 items-center"
            >
              <Checkbox
                className="checked:bg-plum dark:checked:bg-plum"
                checked={
                  // @ts-expect-error
                  (filter.values && filter.values?.find(f => f == o.value)) ||
                  false
                }
                readOnly
              />
              <Label value={o.name} />
            </div>
          ))}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Filter;
