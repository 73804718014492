import { useFieldArray, useFormContext } from "react-hook-form";
import { useScheme } from "../../../../store/schemeStore";
import { deductionPresetScheme, formula } from "../../workorder/types";
import { useState } from "react";
import { TextInput } from "flowbite-react";
import CheckboxWithLabel from "../../../../comps/CheckboxWithLabel";
import {
  ArrowTopRightOnSquareIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import Formula from "./Formula";
import { sort } from "fast-sort";
import z from "zod";

export default function Formulas() {
  const { control } = useFormContext<z.infer<typeof deductionPresetScheme>>();
  const { fields, append, remove, replace } = useFieldArray({
    name: "formulas",
    control,
  });

  const defaultValues: formula = {
    name: "",
    displayName: undefined,
    show: true,
    formula: "",
    priority: 0,
  };

  const nullishValues = ["", null, 0];

  const [newFormula, setNewFormula] = useState(defaultValues);

  const add = () => {
    if (
      nullishValues.includes(newFormula.name) ||
      nullishValues.includes(newFormula.formula)
    ) {
      return;
    }
    append({ ...newFormula, priority: fields.length });
    setNewFormula(defaultValues);
  };

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const sortedColumns = sort(fields as formula[]).asc("priority");

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedColumns.findIndex(
        ({ name }) => name === active.id
      );
      const overIndex = sortedColumns.findIndex(({ name }) => name === over.id);
      const movedArray = arrayMove(sortedColumns, activeIndex, overIndex).map(
        (calc, i) => ({
          ...calc,
          priority: i,
        })
      );
      replace(movedArray);
    }
  };

  const { scheme } = useScheme();

  return (
    <div className={`${scheme} flex flex-col gap-2 flex-wrap`}>
      {/* New Formula */}
      <div className="flex flex-row gap-2 items-center">
        <TextInput
          sizing="sm"
          addon="Name"
          placeholder="Name"
          className="w-40"
          value={newFormula.name}
          onChange={e => {
            setNewFormula({ ...newFormula, name: e.target.value });
          }}
        />
        <TextInput
          sizing="sm"
          addon="DPName"
          placeholder="Display Name"
          className="w-48"
          value={newFormula.displayName || undefined}
          onChange={e => {
            setNewFormula({ ...newFormula, displayName: e.target.value });
          }}
        />
        <CheckboxWithLabel
          name="Show"
          checked={newFormula.show}
          onChange={() => {
            setNewFormula({
              ...newFormula,
              show: !newFormula.show,
            });
          }}
        />
        <div className="flex-1 relative items-center">
          <TextInput
            className="w-full"
            sizing="sm"
            addon="F"
            placeholder="Formla"
            value={newFormula.formula}
            onChange={e => {
              setNewFormula({ ...newFormula, formula: e.target.value });
            }}
          />
          <ArrowTopRightOnSquareIcon
            onClick={() => {
              window.open("https://mathnotepad.com/");
            }}
            className="absolute cursor-pointer right-2 top-1/2 -translate-y-1/2 w-5 dark:text-white text-gray-600"
          />
        </div>

        <PlusCircleIcon
          className="w-6 text-grass cursor-pointer"
          onClick={add}
        />
      </div>
      {fields.length > 0 && (
        <div className="flex flex-col dark:text-white">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-t-md flex flex-row gap-2 items-center">
            <div className="px-2 py-[5px] w-52">Name</div>
            <div className="px-2 py-[5px] w-52">DPName</div>
            <div className="px-2 py-[5px] w-16 flex flex-row justify-center">
              Show
            </div>
            <div className="px-2 py-[5px] flex-1">Formula</div>
            <div className="px-2 py-[5px] w-10" />
          </div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]}
          >
            <SortableContext
              items={sortedColumns.map(calc => calc.name)}
              strategy={verticalListSortingStrategy}
            >
              {sortedColumns.map((item, index) => (
                <Formula
                  key={`${item.name}${index}`}
                  formula={item}
                  index={index}
                  remove={remove}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      )}
    </div>
  );
}
