import { gql } from "@apollo/client";

export const TEAM_CORE_FIELDS = gql`
  fragment TeamCoreFields on teams {
    id
    name
    inHouse
    type {
      id
      name
    }
    subTeams
    processTypes
    products
  }
`;
