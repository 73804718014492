import { gql, useQuery, TypedDocumentNode } from "@apollo/client";
import { Spinner } from "flowbite-react";
import Role from "./role";
import AddRole from "./addRole";
import { AuthArrayType } from "./types";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { AUTH_CORE_FIELDS } from "./fragments.js";
import checkAuth from "../../../../../utils/checkAuth";
import { sort } from "fast-sort";

export const GET_AUTHS: TypedDocumentNode<AuthArrayType> = gql`
  ${AUTH_CORE_FIELDS}
  query GET_AUTHS {
    auths {
      ...AuthCoreFields
    }
  }
`;

export default function Roles() {
  const { data, loading, error } = useQuery(GET_AUTHS, {
    fetchPolicy: "cache-and-network",
  });

  const auths = data?.auths ? sort(data.auths).asc(a => a.id) : false;

  if (error) {
    console.log(error);
  }

  const [adding, setAdding] = useState(false);

  const toggleAdding = () => {
    setAdding(!adding);
  };

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <h1>Roles</h1>
        {checkAuth("setting_auth_edit") && (
          <div onClick={toggleAdding} className="w-6 cursor-pointer">
            {adding ? (
              <MinusCircleIcon className="text-red-500" />
            ) : (
              <PlusCircleIcon className="text-grass hover:animate-pulse" />
            )}
          </div>
        )}
      </div>
      {loading && !data && (
        <div className="flex flex-row justify-center mt-4 mb-6">
          <Spinner color="purple" size="xl" />
        </div>
      )}
      <section className="mt-2 space-y-2">
        {adding && <AddRole toggleAdding={toggleAdding} />}
        {auths && auths.map(auth => <Role key={auth.id} auth={auth} />)}
      </section>
    </div>
  );
}
