import { Badge } from "flowbite-react";
import BoardItem from "./boardItem";
import { statusGroup } from "./processBoard";
import getContrast from "../../../../utils/getContrast";
import { Active, useDroppable } from "@dnd-kit/core";
import Draggable from "./Draggable";

interface props {
  group: statusGroup;
  active?: Active;
  updating: undefined | number;
}

export default function Group({ group, active, updating }: props) {
  const { status, items } = group;

  const { setNodeRef, isOver } = useDroppable({
    id: status.id,
    data: status,
  });

  const bgColor = status.color;
  const textColor = getContrast(bgColor);
  const badgeStyle = {
    backgroundColor: bgColor,
    color: textColor,
    border: isOver ? "" : "",
  };

  return (
    <div
      ref={setNodeRef}
      className={`w-[280px] min-w-[280px] items-center relative`}
    >
      <div className="sticky top-0 bg-gray-100 dark:bg-dark flex flex-row gap-2 items-center justify-between pt-2">
        <Badge size="md" style={badgeStyle}>
          {status.name}
        </Badge>
        <div className="text-sm text-gray-400">{items.length}</div>
      </div>
      <div
        className={`space-y-2 mt-1 pb-6 border-t-4 pt-1 border-transparent ${
          isOver && active?.data?.current?.processStatus?.id !== status.id
            ? "border-plum dark:border-grass"
            : ""
        }`}
      >
        {items.map(i => (
          <Draggable id={i.id} key={i.id} data={i}>
            <BoardItem
              item={i}
              active={active}
              overlay={false}
              updating={updating}
            />
          </Draggable>
        ))}
      </div>
    </div>
  );
}
