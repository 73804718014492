import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import { useAuthStore, useLoadUser } from "../../store/authStore";
import Loading from "../../comps/loading";
// @ts-expect-error
import useReactIpLocation from "react-ip-details";
import { useEffect } from "react";
import { isBrowser, isMobile, useDeviceData } from "react-device-detect";
import { useDeviceStore } from "../../store/deviceStore";

export default function Container() {
  const { loading, setLoading, loggingIn } = useAuthStore();

  const loadUser = useLoadUser();

  const {
    setIp,
    setAgent,
    setLocation,
    ipLoaded,
    agentLoaded,
    locationLoaded,
  } = useDeviceStore();

  // get user ip info
  const { ipResponse } = useReactIpLocation({ numberToConvert: 100 });

  useEffect(() => {
    if (ipResponse?.IPv4 && setIp) {
      setIp(ipResponse.IPv4);
      useDeviceStore.setState({ ipLoaded: true });
    } else {
      useDeviceStore.setState({ ipLoaded: true });
    }
  }, [ipResponse, setIp]);

  // get user location, device info

  useEffect(() => {
    let unsubscribe = false;

    const agentData = useDeviceData("");

    if (!unsubscribe && setAgent && setLocation) {
      // set agent data
      const summary = `${
        isMobile
          ? `mobile ${agentData.device?.vendor} ${agentData.device?.model}`
          : `pc ${agentData.os?.name} ${agentData.os?.version}`
      }`;

      setAgent({
        isBrowser,
        isMobile,
        os: agentData.os?.name
          ? agentData.os?.name + agentData.os?.version
          : "",
        browser: agentData.browser?.name || "",
        device: `${agentData.device?.vendor} ${agentData.device?.model}`,
        summary,
      });

      useDeviceStore.setState({ agentLoaded: true });
      // set location data
      if (navigator && "geolocation" in navigator) {
        try {
          navigator.geolocation.getCurrentPosition(function (position) {
            if (position.coords?.latitude && position.coords?.longitude) {
              setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            }
          });
          useDeviceStore.setState({ locationLoaded: true });
        } catch (error) {
          console.log(error);
          useDeviceStore.setState({ locationLoaded: true });
        }
      } else {
        useDeviceStore.setState({ locationLoaded: true });
      }
    }

    return () => {
      unsubscribe = true;
    };
  }, [setAgent, setLocation]);

  useEffect(() => {
    const persist = localStorage.getItem("persist");

    if (
      persist == "true" &&
      !loggingIn &&
      ipLoaded &&
      agentLoaded &&
      locationLoaded
    ) {
      loadUser();
    }

    if (persist !== "true") {
      setLoading(false);
    }
  }, [ipLoaded, agentLoaded, locationLoaded]);

  return (
    <div className="flex flex-col flex-1 h-full">
      <Header />
      <div className="flex-1 bg-gray-100 dark:bg-dark">
        {loading ? <Loading /> : <Outlet />}
      </div>
      <Footer />
    </div>
  );
}
