import {
  ClockIcon,
  HomeIcon,
  MinusCircleIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import {
  Badge,
  Breadcrumb,
  Button,
  Label,
  Spinner,
  TextInput,
  Textarea,
  Timeline,
} from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_RECORD_BY_PK,
  GET_RECORD_STATUS,
  UPDATE_RECORD,
  DELETE_RECORD,
} from "../shared/record/gqls";
import { useMutation, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import RecordMap from "./recordMap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthStore } from "../../../store/authStore";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import getContrast from "../../../utils/getContrast";
import checkAuth from "../../../utils/checkAuth";
import { sort } from "fast-sort";
import { hoursToTimeStamp } from "../../../utils/dateMethods";
import { coerceNumber } from "../process/types";
import { push } from "../../../utils/pushAPI";
import { useGetUserScope } from "../../../utils/getCertainUsers";
import RecordStatus from "./recordStatus";
import RecordType from "./recordType";
import Folder from "../shared/files/folder";

const resolver = z
  .object({
    start: z.string(),
    end: z.string(),
    break: coerceNumber,
    desc: z.string().min(2, { message: "please enter description" }),
  })
  .refine(
    data => {
      return hoursToTimeStamp(data.start) < hoursToTimeStamp(data.end);
    },
    {
      message: "invalid timeframe",
      path: ["end"],
    }
  );

export default function RecordDetail() {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const { id } = useParams();

  const [update, { loading: updating }] = useMutation(UPDATE_RECORD);
  const [deleteRecord, { loading: deleting }] = useMutation(DELETE_RECORD);

  const { data: data_status } = useQuery(GET_RECORD_STATUS);
  const statuses = data_status?.recordStatus
    ? sort(data_status?.recordStatus).asc(stat => stat.priority)
    : [];

  const pendingStatus = statuses.find(stat => stat.name == "pending");

  const { data, loading } = useQuery(GET_RECORD_BY_PK, { variables: { id } });

  const record = data?.records_by_pk;

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const { directAdmins } = useGetUserScope(
    record?.user?.id || -1,
    "records_action_team"
  );

  const directAdminsIds = directAdmins?.map(a => a.id);

  const startTime = dayjs(record?.start).format("HH:mm");
  const endTime = record?.end ? dayjs(record.end).format("HH:mm") : "Missing";

  const defaultValues = {
    start: startTime,
    end: endTime,
    break: record?.break,
    desc: record?.desc || "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues,
    resolver: zodResolver(resolver),
  });

  const onSubmit = handleSubmit(async data => {
    if (!isDirty || !record) {
      return;
    }
    const { id } = record;
    const logs = record.logs ? [...record.logs] : [];

    const newLog = {
      date: new Date().toISOString(),
      content: `Record changed from ${startTime} - ${endTime} (${record.break}) to ${data.start} -${data.end} (${data.break})`,
    };

    logs.push(newLog);

    const newStartTime = data.start.split(":");
    const newEndTime = data.end.split(":");

    const newStart = new Date(
      new Date(record.start).setHours(
        Number(newStartTime[0]),
        Number(newStartTime[1])
      )
    ).toISOString();

    const newEnd = new Date(
      new Date(record.end).setHours(
        Number(newEndTime[0]),
        Number(newEndTime[1])
      )
    ).toISOString();

    update({
      variables: {
        id,
        set: {
          statusID: pendingStatus?.id,
          start: newStart,
          end: newEnd,
          break: data.break,
          desc: data.desc,
          logs,
        },
      },
      onCompleted() {
        setEditing(false);
        if (directAdminsIds) {
          const user = record.user;
          const title = `${user?.firstName} ${user?.sirName} - Record Approval Pending`;
          const body = `Please review and action on the user's record`;
          const pathname = `records/detail/${record.id}`;
          push({
            title,
            body,
            ids: directAdminsIds,
            // TODO : push not going through with data why??
          });
        }
      },
    });
  });

  useEffect(() => {
    const defaultValues = {
      start: dayjs(record?.start).format("HH:mm"),
      end: dayjs(record?.end).format("HH:mm"),
      break: record?.break,
      desc: record?.desc || "",
    };
    reset(defaultValues);
  }, [record]);

  const cancel = () => {
    setEditing(false);
    reset();
  };

  const canApprove =
    record &&
    checkAuth([
      "records_action_all",
      {
        permission: "records_action_team",
        checkGroup: "userTeam",
        conditionGroup: [record.user.teamID || 0],
      },
    ]);

  const canEdit = user?.id == record?.user.id || canApprove;

  const changeStat = (id: number) => {
    if (!record) {
      return;
    }
    update({
      variables: {
        id: record.id,
        set: {
          statusID: id,
        },
      },
    });
  };

  const changeType = (id: number) => {
    if (!record) {
      return;
    }
    update({
      variables: {
        id: record.id,
        set: {
          typeID: id,
        },
      },
    });
  };

  const remove = () => {
    const id = record?.id;
    if (!id) {
      return;
    }
    const proceed = confirm("do you really want to delete this record?");
    if (!proceed) {
      return;
    }
    deleteRecord({
      variables: { id },
      update(cache) {
        const normalizedId = cache.identify({
          id: id,
          __typename: "records",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
        navigate("/records");
      },
    });
  };

  const D = dayjs(record?.start).format("YYYY-MM-DD").toLowerCase();

  const path: string[] = [
    "EZILUX",
    "26 HR",
    "Users DO NOT DELETE",
    String(record?.user?.id.toString()),
    "records",
    D,
  ];

  return (
    <main className="px-6 pb-10 select-none flex flex-col h-full">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("../");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            Record
          </Breadcrumb.Item>
          <Breadcrumb.Item className="capitalize">Detail</Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      {loading && !record && (
        <div className="flex-1 flex justify-center items-center">
          <Spinner size="xl" light color="purple" />
        </div>
      )}

      {record && (
        <article className="mt-3">
          <div className="flex flex-row items-center gap-2">
            <h2 className="capitalize">
              {record.user.firstName} {record.user.sirName} -{" "}
              {dayjs(record.start).format("DD MMM")}
            </h2>
          </div>
          <div
            className="grid grid-cols-1 
            @md:grid-cols-2 supports-[not(container-type:inline-size)]:md:grid-cols-2
            @lg:grid-cols-3 supports-[not(container-type:inline-size)]:lg:grid-cols-3
            gap-4 mt-2"
          >
            <RecordMap record={record} />
            <form
              onSubmit={onSubmit}
              className="col-span-1 space-y-2 flex flex-col"
            >
              <div className="flex flex-row items-center gap-2 justify-between @md:justify-start"></div>
              <h2>Details</h2>
              <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
                <RecordType
                  canApprove={!!canApprove}
                  type={record.type}
                  changeType={changeType}
                />
                <RecordStatus
                  canApprove={!!canApprove}
                  status={record.status}
                  changeStat={changeStat}
                />
                <div className="flex flex-col gap-2">
                  <Label className="flex-1" value="Checked In" />
                  <TextInput
                    icon={ClockIcon}
                    sizing="sm"
                    {...register("start")}
                    disabled={loading || !editing}
                    color={errors.start?.message ? "failure" : undefined}
                    style={{ opacity: 1 }}
                    type="time"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Label className="flex-1" value="Checked Out" />
                  <TextInput
                    icon={ClockIcon}
                    sizing="sm"
                    {...register("end")}
                    disabled={loading || !editing}
                    color={errors.end?.message ? "failure" : undefined}
                    style={{ opacity: 1 }}
                    type="time"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Label className="flex-1" value="Break" />
                  <TextInput
                    icon={MinusCircleIcon}
                    sizing="sm"
                    {...register("break")}
                    disabled={loading || !editing}
                    color={errors.break?.message ? "failure" : undefined}
                    style={{ opacity: 1 }}
                    type="number"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Label className="flex-1" value="Desc" />
                  <Textarea
                    {...register("desc")}
                    disabled={loading || !editing}
                    color={errors.desc?.message ? "failure" : undefined}
                    style={{ opacity: 1 }}
                    className="resize-none text-sm"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Label className="flex-1" value="Files" />
                  <Folder path={path} />
                </div>
                <div className="flex justify-end flex-row items-center gap-2 mt-2">
                  {editing && (
                    <>
                      {canApprove && (
                        <Button size="xs" color="failure" onClick={remove}>
                          delete
                        </Button>
                      )}
                      <Button size="xs" color="purple" onClick={cancel}>
                        cancel
                      </Button>
                      <Button
                        size="xs"
                        gradientDuoTone="purpleToBlue"
                        type="submit"
                        outline
                      >
                        {updating && (
                          <Spinner size="xs" light className="mr-1" />
                        )}
                        update
                      </Button>
                    </>
                  )}
                  {!editing && canEdit && (
                    <Button
                      size="xs"
                      gradientDuoTone="purpleToBlue"
                      onClick={toggleEditing}
                      outline
                    >
                      <PencilIcon className="w-3 mr-2" /> Edit
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="mt-2 space-y-2">
            <h2>History</h2>

            <Timeline>
              {record.logs &&
                sort(record.logs)
                  .desc(r => r.date)
                  .map(log => (
                    <Timeline.Item key={log.date}>
                      <Timeline.Point />
                      <Timeline.Content>
                        <Timeline.Time>
                          {dayjs(log.date).format("DD MMM YYYY")}
                        </Timeline.Time>

                        <Timeline.Body>{log.content}</Timeline.Body>
                      </Timeline.Content>
                    </Timeline.Item>
                  ))}
            </Timeline>
          </div>
        </article>
      )}
    </main>
  );
}
