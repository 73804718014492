import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Modal, Button, Label, Spinner, TextInput } from "flowbite-react";
import { useScheme } from "../../../../../store/schemeStore";
import checkAuth from "../../../../../utils/checkAuth";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";
import { processTypeSchema } from "./types";
import { PROCESS_TYPE_CORE_FIELDS } from "./fragments";
import { ProcessTypeType } from "./types";
import { useState } from "react";
import PathEdit from "./pathEdit";
import { GET_PROCESS_STATUSES } from "../processStatuses/processStatuses";
import { ProcessStatusType } from "../processStatuses/types";

export const UPDATE_PROCESS_TYPE = gql`
  ${PROCESS_TYPE_CORE_FIELDS}
  mutation UPDATE_PROCESS_TYPE(
    $id: Int!
    $description: String!
    $name: String!
    $color: String!
    $prefix: String
    $path: jsonb
    $statuses: jsonb
  ) {
    update_processType_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        description: $description
        color: $color
        prefix: $prefix
        path: $path
        statuses: $statuses
      }
    ) {
      ...ProcessTypeCoreFields
    }
  }
`;

export const DELETE_PROCESS_TYPE = gql`
  ${PROCESS_TYPE_CORE_FIELDS}
  mutation DELETE_PROCESS_TYPE($id: Int!) {
    delete_processType_by_pk(id: $id) {
      id
    }
  }
`;

export default function ProcessType({ pt }: { pt: ProcessTypeType }) {
  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow(!show);
  };

  const { scheme } = useScheme();

  const { data: data_statuses } = useQuery(GET_PROCESS_STATUSES);
  const statuses = data_statuses?.processStatus;

  const [update_process_type, { loading, error }] =
    useMutation(UPDATE_PROCESS_TYPE);
  if (error) {
    console.log(error);
    addAlert({ message: "could not update type", type: "failure" });
  }

  const [
    delete_process_type,
    { error: delete_error, loading: delete_loading },
  ] = useMutation(DELETE_PROCESS_TYPE);
  if (delete_error) {
    console.log(delete_error);
    addAlert({ message: "could not delete type", type: "failure" });
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(processTypeSchema),
    defaultValues: {
      name: pt.name,
      description: pt.description,
      color: pt.color,
      path: pt.path || [],
      prefix: pt.prefix,
      statuses: pt.statuses || [],
    },
  });

  const formStatuses = watch("statuses");
  const toggleStatus = (status: ProcessStatusType) => {
    if (formStatuses.find(fs => fs == status.id)) {
      setValue(
        "statuses",
        formStatuses.filter(fs => fs !== status.id)
      );
    } else {
      setValue("statuses", formStatuses.concat(status.id));
    }
  };

  const path = watch("path");

  const onSubmit = handleSubmit(async data => {
    update_process_type({
      variables: { id: pt.id, ...data },
      onCompleted(data) {
        if (data.update_processType_by_pk) {
          const { color, name, description, path, prefix, statuses } =
            data.update_processType_by_pk;

          reset({
            color,
            name,
            description,
            prefix,
            path,
            statuses,
          });

          toggleModal();
          addAlert({ message: "type updated successfully", type: "success" });
        }
      },
    });
  });

  const deleteType = () => {
    const proceed = confirm("Delete Type?");
    if (proceed) {
      delete_process_type({
        variables: { id: pt.id },
        update(cache, { data: { delete_processType_by_pk: deletedPT } }) {
          const normalizedId = cache.identify({
            id: deletedPT.id,
            __typename: "processType",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
        onCompleted(data) {
          if (data.delete_processType_by_pk) {
            toggleModal();
          }
        },
      });
    }
  };

  const cancel = () => {
    reset();
    toggleModal();
  };

  return (
    <>
      <div className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <div
            className="rounded-full ring-1 w-4 h-4 ring-black dark:ring-white"
            style={{
              backgroundColor: `${pt?.color ? pt.color : "#CCCCCC"}`,
            }}
          />
          <h3>
            {pt.name} {pt.prefix ? `[${pt.prefix}]` : ""}
          </h3>
        </div>
        {checkAuth("setting_process_type_edit") && (
          <>
            <PencilIcon
              className="w-4 mt-1 cursor-pointer"
              onClick={toggleModal}
            />
          </>
        )}
      </div>
      <Modal show={show} onClose={toggleModal} className={`${scheme}`}>
        <Modal.Header>Edit Process Type</Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} className="space-y-3">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Name" />
              </div>
              <TextInput
                type="text"
                placeholder="type name"
                color={errors.name?.message ? "failure" : undefined}
                helperText={errors.name?.message || ""}
                {...register("name")}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="description" value="Description" />
              </div>
              <TextInput
                type="text"
                placeholder="type description"
                color={errors.description?.message ? "failure" : undefined}
                helperText={errors.description?.message || ""}
                {...register("description")}
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label htmlFor="prefix" value="Prefix" />
              </div>
              <TextInput
                type="text"
                placeholder="type prefix"
                color={errors.prefix?.message ? "failure" : undefined}
                helperText={errors.prefix?.message || ""}
                {...register("prefix")}
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label htmlFor="path" value="Path" />
              </div>
            </div>

            <PathEdit setValue={setValue} path={path} />

            <div>
              <Label htmlFor="color" value="Color" />
              <input
                type="color"
                {...register("color")}
                className="w-full h-10 border-2 p-0 bg-transparent border-none"
              />
              <div> {errors.color?.message || ""}</div>
            </div>
            <div>
              <Label htmlFor="statuses" value="Preferred Statuses" />
              <div className="max-h-[300px] overflow-y-scroll md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-800">
                {statuses?.map(stat => (
                  <div
                    key={stat.id}
                    onClick={() => {
                      toggleStatus(stat);
                    }}
                    className={`${
                      !formStatuses.includes(stat.id) && "opacity-50"
                    } group cursor-pointer rounded-md p-3 select-none dark:bg-gray-700 bg-gray-100 flex flex-row justify-between items-center`}
                  >
                    <div className="flex flex-row items-center gap-3">
                      <div
                        className="rounded-full ring-1 w-4 h-4 ring-black dark:ring-white"
                        style={{
                          backgroundColor: `${
                            stat?.color ? stat.color : "#CCCCCC"
                          }`,
                        }}
                      />
                      <h3 className="group-hover:text-plum">{stat.name}</h3>
                    </div>
                  </div>
                ))}
              </div>
              <div> {errors.statuses?.message || ""}</div>
            </div>
            {/* Action Buttons */}
            <div className="flex flex-row justify-end gap-2 items-center">
              {delete_loading && <Spinner size="sm" light={true} />}
              <TrashIcon
                className="w-5 text-red-500 cursor-pointer"
                onClick={deleteType}
              />
              <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
                Cancel
              </Button>
              <Button
                outline
                gradientDuoTone="purpleToBlue"
                size="sm"
                type="submit"
              >
                {loading && (
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                )}
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
