import { MapPinIcon } from "@heroicons/react/24/solid";
import {
  OverlayView,
  OverlayViewF,
  OverlayViewProps,
} from "@react-google-maps/api";
import { MarkerProp } from "../../types";
import { useScheme } from "../../../../../store/schemeStore";
import { useEffect, useState } from "react";

interface MarkerProps
  extends Omit<OverlayViewProps, "mapPaneName" | "position"> {
  marker: MarkerProp;
}

export default function MapMarker(props: MarkerProps) {
  const { position, label, icon, onClick, info } = props.marker;
  const { scheme } = useScheme();
  const shadowColor = scheme == "dark" ? "#000" : "#FFF";
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    setShow(false);
  }, [position]);

  return (
    <OverlayViewF
      {...props}
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      zIndex={30}
    >
      <div
        className="relative ml-[-50%] mt-[-50%] cursor-pointer animate-[pulse_1s_ease-in-out] hover:brightness-125 flex flex-col items-center"
        onClick={() => {
          if (onClick) {
            onClick();
          }
          toggleShow();
        }}
      >
        <div className="text-plum dark:text-white">
          {icon ? icon : <MapPinIcon className="w-7" />}
        </div>

        {label ? (
          <div
            style={{
              textShadow: `-1px -1px 0 ${shadowColor}, 1px -1px 0 ${shadowColor},-1px 1px 0 ${shadowColor},1px 1px 0 ${shadowColor}`,
            }}
            className="absolute w-max bottom-[-20px] text-center text-plum dark:text-white"
          >
            {label}
          </div>
        ) : null}

        {info && show && <div className="absolute left-6 bottom-6">{info}</div>}
      </div>
    </OverlayViewF>
  );
}
