import { z } from "zod";

type HEX = `#${string}`;

export const processStatusSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  description: z
    .string()
    .min(2, { message: "description cannot be empty" })
    .trim(),
  color: z.string(),
  dueMatters: z.boolean(),
  // priority: z.number(),
});

export interface NewProcessStatusType {
  name: string;
  description?: string;
  color: HEX;
  priority: number;
  new?: boolean;
  dueMatters: boolean;
}

export interface ProcessStatusType extends NewProcessStatusType {
  id: number;
}

export interface ProcessStatusArrayType {
  processStatus: ProcessStatusType[];
}
