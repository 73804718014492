import { Button, Label, TextInput, Spinner } from "flowbite-react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Link } from "react-router-dom";
import { reset } from "../../store/authStore";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";

const userSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: "Please input a password longer than 8 characters" }),
    passwordConfirm: z.string(),
  })
  .refine(data => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ["passwordConfirm"],
  });

export default function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(userSchema),
    defaultValues: {
      password: "",
      passwordConfirm: "",
    },
  });

  const { token, email } = useParams();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = handleSubmit(async data => {
    setLoading(true);
    const res = await reset(token || "", data.password);
    if (res) {
      navigate("/login");
    }
    setLoading(false);
  });

  return (
    <div className="h-full flex flex-col items-center">
      <div className="font-bold text-2xl mt-20 mb-10">Reset Password</div>
      <form
        onSubmit={onSubmit}
        className="w-[576px] max-w-[80%] shadow-md bg-white dark:bg-gray-800 rounded-md p-8  flex flex-col gap-4"
      >
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Email" />
          </div>
          <TextInput type="email" disabled value={email} />
        </div>
        {/* Password */}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="New Password" />
          </div>
          <TextInput
            type="password"
            placeholder="password here"
            color={errors.password?.message ? "failure" : undefined}
            helperText={errors.password?.message || ""}
            {...register("password")}
          />
        </div>
        {/* Password Confirm*/}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="passwordConfirm" value="New Password Confirm" />
          </div>
          <TextInput
            type="password"
            placeholder="confirm your password"
            color={errors.passwordConfirm?.message ? "failure" : undefined}
            helperText={errors.passwordConfirm?.message || ""}
            {...register("passwordConfirm")}
          />
        </div>

        <div className="flex gap-2 justify-end mt-5">
          <Button gradientDuoTone="purpleToBlue" size="sm" type="submit">
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Reset
          </Button>
          <Link to="/login" relative="path">
            <Button outline={true} gradientDuoTone="purpleToBlue" size="sm">
              Login
            </Button>
          </Link>
        </div>
      </form>
    </div>
  );
}
