import { useFormContext, useWatch } from "react-hook-form";
import useGetPrice from "../../summary/getPrice";
import { opening } from "../../../types";
import { TextInput } from "flowbite-react";
import { useIsMounted } from "usehooks-ts";
import { useEffect } from "react";
import isNullish from "../../../../../../../utils/isNullish";

interface props {
  openingCoord: string;
  itemCoord?: string;
  showPrice: boolean;
}

export default function Price({ openingCoord, itemCoord, showPrice }: props) {
  const { control, getValues, setValue, register } = useFormContext();

  const isMounted = useIsMounted();

  const opening: opening = useWatch({
    name: openingCoord,
    control,
    exact: true,
  });

  const width = useWatch({
    name: `${itemCoord}.width`,
    control,
    defaultValue: 0,
  });

  const height = useWatch({
    name: `${itemCoord}.height`,
    control,
    defaultValue: 0,
  });

  const materials = useWatch({
    name: `${itemCoord}.materials`,
    control,
    defaultValue: [],
  });

  const currentPrice = useWatch({
    name: `${itemCoord}.price`,
    control,
    defaultValue: 0,
  });

  const customInstall = useWatch({
    name: `${itemCoord}.customInstall`,
    control,
    defaultValue: undefined,
  });

  const options = useWatch({
    name: `${itemCoord}.options`,
    control,
    defaultValue: [],
  });

  const itemSalesParams = useWatch({
    name: `${itemCoord}.salesParams`,
    control,
  });

  const salesParams = useWatch({
    name: "salesParams",
    control,
  });

  const orderType = getValues("type");
  const orderedUserId = getValues("user") as number;

  const getPrice = useGetPrice();

  let sqm = Number(((width * height) / 1000000).toFixed(1));
  if (sqm < 0.5) {
    sqm = 0.5;
  }

  const isRetail = orderType?.id !== 4;

  useEffect(() => {
    if (!isMounted || !options) {
      return;
    }

    const item = getValues(`${itemCoord}`);

    const sum = getPrice({
      isOpening: false,
      item,
      opening,
      sqm,
      productSetId: Number(opening.product),
      isRetail,
      userId: orderedUserId,
      itemSalesParams,
      salesParams,
    });

    const currentInstall = item.install;

    const install = isNullish(customInstall)
      ? sum?.install || 0
      : Number(customInstall);

    const total = (sum?.price || 0) + install;

    if (currentInstall !== sum?.install) {
      setValue(`${itemCoord}.install`, sum?.install);
      setValue(`${itemCoord}.customInstall`, undefined);
    }

    if (currentPrice !== total) {
      setValue(`${itemCoord}.price`, total);
    }
  }, [
    width,
    height,
    JSON.stringify(materials),
    JSON.stringify(options),
    itemSalesParams,
    salesParams,
    customInstall,
  ]);

  return (
    <>
      {isRetail && (
        <div
          className={`relative mr-1 group/option w-20 my-[5px] ${
            !showPrice && "hidden"
          }`}
        >
          <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
            Install
          </div>
          <Install coord={itemCoord as string} />
        </div>
      )}
      <div
        className={`relative mr-1 group/option w-20 my-[5px] ${
          !showPrice && "hidden"
        }`}
      >
        <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
          Price
        </div>
        <TextInput
          addon="$"
          className="w-full"
          value={Math.ceil(currentPrice)}
          onChange={() => {}}
          sizing="sm"
        />
      </div>
    </>
  );
}

interface installProps {
  coord: string;
}
const Install = ({ coord }: installProps) => {
  const { setValue, control } = useFormContext();

  const updateInstall = (price: number) => {
    setValue(`${coord}.customInstall`, price);
  };

  const installValue = useWatch({
    name: `${coord}.install`,
    control,
    defaultValue: 0,
  });

  const customInstallValue = useWatch({
    name: `${coord}.customInstall`,
    control,
    defaultValue: undefined,
  });

  const value = isNullish(customInstallValue)
    ? installValue
    : customInstallValue;

  return (
    <TextInput
      addon="$"
      className="w-full"
      value={value}
      onChange={e => {
        updateInstall(Number(e.target.value));
      }}
      sizing="sm"
    />
  );
};
