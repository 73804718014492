import { z } from "zod";

type HEX = `#${string}`;

export const productionStatusSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  description: z
    .string()
    .min(2, { message: "description cannot be empty" })
    .trim(),
  color: z.string(),
  productID: z.string().optional(),
  // priority: z.number(),
});

export interface NewProductionStatusType {
  name: string;
  description?: string;
  color: HEX;
  priority: number;
  productID?: number;
  new?: boolean;
}

export interface ProductionStatusType extends NewProductionStatusType {
  id: number;
}

export interface ProductionStatusArrayType {
  productionStatus: ProductionStatusType[];
}
