import { useMutation, gql } from "@apollo/client";
import { Button, Spinner, TextInput } from "flowbite-react";
import { NewTagType, TagType, tagsSchema } from "./types";
import { TAGS_CORE_FIELDS } from "./fragments";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";

export const INSERT_TAG = gql`
  ${TAGS_CORE_FIELDS}
  mutation INSERT_TAG($name: String!) {
    insert_tags_one(object: { name: $name }) {
      ...TagsCoreFields
    }
  }
`;

export default function AddTag({
  toggleAdding,
  tags,
}: {
  toggleAdding: () => void;
  tags?: TagType[];
}) {
  const [insert_tag, { loading, error }] = useMutation(INSERT_TAG);
  if (error) {
    console.log(error);

    addAlert({
      message: "something went wrong cannot add tag",
      type: "failure",
    });
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewTagType>({
    resolver: zodResolver(tagsSchema),
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = handleSubmit(async data => {
    if (tags && tags.find(t => t.name == data.name)) {
      return addAlert({
        message: "you cannot add duplicate tag",
        type: "warning",
      });
    }
    insert_tag({
      variables: { ...data, name: data.name.toLowerCase().replaceAll(" ", "") },
      update(cache, { data: { insert_tags_one: newTag } }) {
        cache.modify({
          fields: {
            tags(existingTags = []) {
              const newTagRef = cache.writeFragment({
                data: newTag,
                fragment: TAGS_CORE_FIELDS,
              });
              return [...existingTags, newTagRef];
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          message: "New Tag successfully added",
          type: "success",
        });
        toggleAdding();
      },
    });
  });

  const cancel = () => {
    reset();
    toggleAdding();
  };

  return (
    <form
      onSubmit={onSubmit}
      className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 mb-5 space-y-2"
    >
      <div>
        <TextInput
          type="text"
          placeholder="Enter the name for new tag, no space"
          {...register("name")}
          color={errors.name?.message ? "failure" : undefined}
          helperText={errors.name?.message || ""}
        />
      </div>

      <div className="flex flex-row justify-end gap-2">
        <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
          Cancel
        </Button>
        <Button gradientDuoTone="purpleToBlue" outline size="sm" type="submit">
          {loading && (
            <div className="mr-3">
              <Spinner size="sm" light={true} />
            </div>
          )}
          Add
        </Button>
      </div>
    </form>
  );
}
