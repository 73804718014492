import { BreifEvent } from "../../../../../types/calendarTypes";
import { vacancyType } from "../../../../../utils/calendarMethods";
import dayjs from "dayjs";
import ScheduleItem from "./scheduleItem";
import VacancyItem from "./vacancyItem";
import "./day.css";

interface props {
  vacancies: vacancyType[];
  schedules: BreifEvent[];
  date: Date;
  i: number;
  seeVacancies: boolean;
  setVacancy: (vacancy?: vacancyType) => void;
  setSchedule: (schedule?: BreifEvent) => void;
  selectedSchedule?: BreifEvent;
  selectedVacancy?: vacancyType;
}

export default function Day({
  setVacancy,
  vacancies,
  setSchedule,
  schedules,
  date,
  seeVacancies,
  selectedSchedule,
  selectedVacancy,
  i,
}: props) {
  return (
    <div className="flex-1 min-w-[200px] items-center relative">
      <div className="z-20 text-center sticky top-0 left-0 right-0 py-2 h-14 border-b-[1px] bg-gray-50 border-gray-500 dark:bg-gray-700">
        <div className="text-sm">{dayjs(date).format("ddd")}</div>
        <div className="text-sm">
          {dayjs(date).format("DD")}
          {(i == 0 || date.getDate() == 1) && " " + dayjs(date).format("MMM")}
        </div>
      </div>
      <div className=" h-[960px] border-r-[1px] border-gray-500 w-full relative day">
        {seeVacancies ? (
          <>
            {vacancies.map((vacancy, index) => (
              <VacancyItem
                setVacancy={setVacancy}
                key={vacancy.id}
                index={index}
                vacancies={vacancies}
                vacancy={vacancy}
                selectedVacancy={selectedVacancy}
              />
            ))}
          </>
        ) : (
          <>
            {schedules.map((schedule, index) => (
              <ScheduleItem
                key={schedule.id}
                index={index}
                schedules={schedules}
                schedule={schedule}
                setSchedule={setSchedule}
                selectedSchedule={selectedSchedule}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
