import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ORDER_SUMMARY_COLUMN,
  UPDATE_ORDER_SUMMARY_COLUMN_BY_PK,
} from "../../../workorder/gqls";
import { Button } from "flowbite-react";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useMemo, useState } from "react";
import { orderSummaryColumn } from "../../../workorder/types";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { sort } from "fast-sort";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { createPortal } from "react-dom";
import Column from "./column";
import Addate from "./addate";

export default function OrderSummaryColumns() {
  const [sortedColumns, setSortedColumns] = useState<orderSummaryColumn[]>([]);

  useQuery(GET_ORDER_SUMMARY_COLUMN, {
    onCompleted(data) {
      if (data?.orderSummaryColumn) {
        setSortedColumns(sort(data.orderSummaryColumn).asc("position"));
      }
    },
  });

  const [adding, setAdding] = useState(false);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedColumns.find(item => item.id === theActive?.id),
    [theActive, sortedColumns]
  );

  const [touched, setTouched] = useState(false);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedColumns.findIndex(({ id }) => id === active.id);
      const overIndex = sortedColumns.findIndex(({ id }) => id === over.id);
      const movedArray = arrayMove(sortedColumns, activeIndex, overIndex);
      setSortedColumns(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const resetSort = () => {
    setSortedColumns(sort(sortedColumns).asc("position"));
    setTouched(false);
  };

  const [update] = useMutation(UPDATE_ORDER_SUMMARY_COLUMN_BY_PK);

  const applySort = () => {
    const positionMappedColumns = sortedColumns.map((column, i) => ({
      ...column,
      position: i,
    }));
    setSortedColumns(positionMappedColumns);
    positionMappedColumns.map(column => {
      update({
        variables: {
          id: column.id,
          set: {
            position: column.position,
          },
        },
      });
    });
    setTouched(false);
  };

  const newPosition = sortedColumns.length + 1;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-end">
        <Button
          onClick={() => {
            setAdding(true);
          }}
          gradientDuoTone="purpleToBlue"
          outline
          size="xs"
        >
          new
        </Button>
      </div>
      <div className="select-text flex flex-col gap-1">
        <div className="flex flex-row rounded-t-md dark:bg-gray-600 bg-gray-100 py-2 font-semibold uppercase">
          <div className="text-xs px-2 w-10">id</div>
          <div className="text-xs px-2 w-40">name</div>
          <div className="text-xs px-2 flex-1">options</div>
          <div className="text-xs px-2 w-16">position</div>
          <div className="p-0 w-20" />
        </div>
        <div className="text-black dark:text-white">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]}
          >
            <SortableContext
              items={sortedColumns.map(opt => opt.id)}
              strategy={verticalListSortingStrategy}
            >
              {sortedColumns.map((column, i) => (
                <Column
                  isOverlay={false}
                  key={column.id}
                  index={i}
                  column={column}
                />
              ))}
            </SortableContext>
            {activeItem && (
              <>
                {createPortal(
                  <DragOverlay>
                    <Column isOverlay={true} index={0} column={activeItem} />
                  </DragOverlay>,
                  document.body
                )}
              </>
            )}
          </DndContext>
        </div>
      </div>
      {touched && (
        <>
          <hr />
          <div className="flex flex-row justify-end gap-2 items-center">
            <Button size="xs" color="purple" onClick={resetSort}>
              reset sorting
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone="greenToBlue"
              onClick={applySort}
            >
              apply sorting
            </Button>
          </div>
        </>
      )}
      <Addate setShow={setAdding} show={adding} newPosition={newPosition} />
    </div>
  );
}
