import { useEffect, useState } from "react";
import { mkdir, exists } from "../../../../utils/fileAPI";
import Folder from "../../shared/files/folder";
import { processDetailSubProps } from "../types";
import { default as config } from "../../../../config";
import { Spinner } from "flowbite-react";

interface props extends processDetailSubProps {
  jobPath: false | string[];
}

export default function ProcessFiles({ jobPath }: props) {
  const mandatoryDirs = config.mandatoryDirs;

  const [path, setPath] = useState(jobPath);
  const [checking, setChecking] = useState(false);

  const initDirectory = async () => {
    if (checking) {
      return;
    }

    setChecking(true);
    if (!jobPath) {
      setChecking(false);
      return;
    }

    try {
      await Promise.all(
        mandatoryDirs.map(async dir => {
          const mandatoryDir = jobPath.concat(dir);
          const mandatoryDirExists = await exists(mandatoryDir);
          if (!mandatoryDirExists) {
            // setPath(false);
            await mkdir(mandatoryDir);
            return;
          } else {
            return;
          }
        })
      );

      setPath(jobPath);
      setChecking(false);
    } catch (error) {
      setPath(jobPath);
      setChecking(false);
    }
  };

  useEffect(() => {
    initDirectory();
  }, [jobPath]);

  if (!path) {
    return null;
  }

  return (
    <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 ">
      {checking && <Spinner color="purple" size="lg" />}
      {!checking && <Folder path={path} />}
    </div>
  );
}
