import { gql, TypedDocumentNode } from "@apollo/client";
import { PROCESS_CORE_FIELDS } from "../fragments";
import { ProcessArrayType, ProcessType } from "../types";

interface update_process_by_pk_type {
  update_process_by_pk: ProcessType;
}

export const UPDATE_PROCESS_BY_PK: TypedDocumentNode<update_process_by_pk_type> = gql`
  ${PROCESS_CORE_FIELDS}
  mutation UPDATE_PROCESS_BY_PK($id: Int!, $set: process_set_input!) {
    update_process_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ProcessCoreFields
    }
  }
`;

export const REMOVE_PROCESS = gql`
  mutation REMOVE_PROCESS($id: Int!) {
    delete_process_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_PROCESS_CONDITIONAL: TypedDocumentNode<ProcessArrayType> = gql`
  ${PROCESS_CORE_FIELDS}
  query GET_PROCESS_CONDITIONAL($where: process_bool_exp!) {
    process(where: $where) {
      ...ProcessCoreFields
    }
  }
`;
