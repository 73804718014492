import { useAuthStore } from "../../../store/authStore";
import { Spinner } from "flowbite-react";
import { gql, useSubscription, TypedDocumentNode } from "@apollo/client";
import { addAlert } from "../../../store/alertStore";
import Device from "./device";

export interface DeviceType {
  device: string;
  ip: string;
  lastActive: string;
  expiresAt: string;
  location: string;
  id: number;
  pushToken?: string;
}

interface DeviceData {
  devices: DeviceType[];
}

interface DeviceVar {
  id?: number;
}

const SUB_DEVICES: TypedDocumentNode<DeviceData, DeviceVar> = gql`
  subscription SUB_DEVICES($id: Int!, $today: timestamptz!) {
    devices(where: { userId: { _eq: $id }, expiresAt: { _gte: $today } }) {
      device
      ip
      lastActive
      location
      id
      expiresAt
      pushToken
    }
  }
`;

const today = new Date().toISOString();

export default function Devices() {
  const { user } = useAuthStore();

  const { data, error, loading } = useSubscription(SUB_DEVICES, {
    variables: { id: user?.id, today },
    skip: !user?.id || !today,
  });

  if (error) {
    addAlert({ message: "could not load devices", type: "warning" });
    console.log(error);
  }

  return (
    <main className="mb-1 flex-grow  mx-auto">
      <h1 className=" flex flex-row justify-between  mb-2">Active Devices</h1>
      <section className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-2 ">
        {loading && (
          <div className="flex flex-row justify-center mt-4 mb-6">
            <Spinner color="purple" size="xl" />
          </div>
        )}
        {(!data || data.devices.length < 1) &&
          !loading &&
          "No device session has been logged"}
        {data && data.devices.map(d => <Device key={d.id} device={d} />)}
      </section>
    </main>
  );
}
