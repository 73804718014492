import { gql } from "@apollo/client";
import { PAYMENT_STATUS_CORE_FIELDS } from "../../settings/payment/paymentStatuses/fragments";

export const PAYMENT_LOGS_CORE_FIELDS = gql`
  ${PAYMENT_STATUS_CORE_FIELDS}
  fragment PaymentLogsCoreFields on paymentLogs {
    id
    user {
      id
      firstName
      sirName
      email
    }
    created_at
    updated_at
    received_at
    processID
    paymentMethod {
      id
      name
      iconName
    }
    amount
    comment
  }
`;
