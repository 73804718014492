import { Label, TextInput } from "flowbite-react";
import { nestedFilterProps } from "./types";
import { addDays } from "../../../../utils/dateMethods";
import dayjs from "dayjs";
import { useEffect } from "react";

const FilterRangeType = ({ setNew, filter }: nestedFilterProps) => {
  const rangeType = filter.rangeType || "number";

  useEffect(() => {
    if (!filter.values?.from || filter.values?.to) {
      setNew({
        ...filter,
        values: {
          from:
            rangeType == "date" ? dayjs(new Date()).format("YYYY-MM-DD") : 0,
          to:
            rangeType == "date"
              ? dayjs(addDays(new Date(), 20)).format("YYYY-MM-DD")
              : 1000,
        },
      });
    }
  }, []);

  if (filter.values?.from == undefined || filter.values?.to == undefined) {
    return null;
  }

  const from =
    rangeType == "date"
      ? dayjs(filter.values?.from).format("YYYY-MM-DD")
      : filter.values?.from;

  const to =
    rangeType == "date"
      ? dayjs(filter.values?.to).format("YYYY-MM-DD")
      : filter.values?.to;

  const handleChange = (where: string, value: string) => {
    if (rangeType == "date") {
      if (where == "from" && dayjs(value).isAfter(dayjs(to))) {
        return;
      }
      if (where == "to" && dayjs(value).isBefore(dayjs(from))) {
        return;
      }
    } else {
      if ((where == "from" && value > to) || (where == "to" && value < from)) {
        return;
      }
    }

    setNew({
      ...filter,
      values: {
        ...filter.values,
        [where]: value,
      },
    });
  };

  return (
    <div className="flex flex-row flex-wrap gap-2 ">
      <div className="space-y-2 flex-1 shadow-md bg-white dark:bg-gray-800 rounded-md col-span-1 z-20">
        <Label value="From" />
        <TextInput
          type={rangeType}
          value={from}
          onChange={e => {
            handleChange("from", e.target.value);
          }}
          sizing="sm"
        />
      </div>
      <div className="space-y-2 flex-1 shadow-md bg-white dark:bg-gray-800 rounded-md col-span-1 z-20">
        <Label value="To" />
        <TextInput
          type={rangeType}
          value={to}
          onChange={e => {
            handleChange("to", e.target.value);
          }}
          sizing="sm"
        />
      </div>
    </div>
  );
};

export default FilterRangeType;
