import { useDraggable } from "@dnd-kit/core";

export default function Draggable(props: any) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
    data: props.data,
  });

  return (
    <div className="visible" ref={setNodeRef} {...listeners} {...attributes}>
      {props.children}
    </div>
  );
}
