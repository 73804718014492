import { Bars3Icon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { Checkbox } from "flowbite-react";
import { useFormContext } from "react-hook-form";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { formula } from "../../workorder/types";

interface props {
  index: number;
  remove: (number: number) => void;
  formula: formula;
}

export default function Formula({ formula, index, remove }: props) {
  const { register } = useFormContext();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: formula.name });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      className="rounded-md flex flex-row gap-2 items-center group"
      ref={setNodeRef}
      style={style}
    >
      <div className="px-[2px] " {...attributes} {...listeners}>
        <Bars3Icon className="w-4 text-transparent group-hover:text-inherit" />
      </div>
      <div className="px-2 py-[5px] w-52">
        <input
          className="bg-transparent w-full"
          {...register(`formulas.${index}.name`)}
        />
      </div>
      <div className="px-2 py-[5px] w-52">
        <input
          className="bg-transparent w-full"
          {...register(`formulas.${index}.displayName`)}
          placeholder="Optional DP Name"
        />
      </div>
      <div className="px-2 py-[5px] w-16 flex flex-row justify-center">
        <Checkbox
          {...register(`formulas.${index}.show`)}
          className="checked:bg-plum dark:checked:bg-plum"
        />
      </div>
      <div className="px-2 py-[5px] flex-1">
        <input
          className="bg-transparent w-full"
          {...register(`formulas.${index}.formula`)}
        />
      </div>
      <div className="px-2 py-[5px] w-10 flex flex-row gap-2">
        <MinusCircleIcon
          className="text-red-500 w-5 cursor-pointer"
          onClick={() => {
            remove(index);
          }}
        />
      </div>
    </div>
  );
}
