import { gql } from "@apollo/client";
import { PROCESS_CORE_FIELDS } from "../process/fragments";

export const ASSIGNMENT_STATUS_FIELDS = gql`
  fragment AssignmentStatusFields on assignmentStatus {
    id
    priority
    name
    color
  }
`;

export const ASSIGNMENT_TYPE_FIELDS = gql`
  fragment AssignmentTypeFields on assignmentType {
    id
    priority
    name
    color
  }
`;

export const ASSIGNMENT_CORE_FIELDS = gql`
  fragment AssignmentCoreFields on assignment {
    id
    processId
    userId
    createdById
    statusId
    typeId
    created_at
    updated_at
    value
    desc
    due
    orders
    products
  }
`;

export const ASSIGNMENT_ESSENTIAL_FIELDS = gql`
  ${ASSIGNMENT_CORE_FIELDS}
  ${ASSIGNMENT_STATUS_FIELDS}
  ${ASSIGNMENT_TYPE_FIELDS}
  fragment AssignmentEssentialFields on assignment {
    ...AssignmentCoreFields
    user {
      firstName
      sirName
      id
      email
    }
    createdBy {
      firstName
      sirName
      id
      email
    }
    status {
      ...AssignmentStatusFields
    }
    type {
      ...AssignmentTypeFields
    }
  }
`;

export const ASSIGNMENT_ALL_FIELDS = gql`
  ${ASSIGNMENT_CORE_FIELDS}
  ${ASSIGNMENT_STATUS_FIELDS}
  ${ASSIGNMENT_TYPE_FIELDS}
  ${PROCESS_CORE_FIELDS}
  fragment AssignmentAllFields on assignment {
    ...AssignmentCoreFields
    process {
      ...ProcessCoreFields
    }
    user {
      firstName
      sirName
      id
      email
    }
    createdBy {
      firstName
      sirName
      id
      email
    }
    status {
      ...AssignmentStatusFields
    }
    type {
      ...AssignmentTypeFields
    }
  }
`;
