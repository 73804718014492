import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USERS } from "../routes/protected/settings/users/gql";
import { GET_PERMISSIONS } from "../routes/protected/settings/auths/permissions/permissions";

export const useGetUserScope = (userId: number, permission: string) => {
  const { data: data_users } = useQuery(GET_ACTIVE_USERS);
  const { data: data_permissions } = useQuery(GET_PERMISSIONS);

  const users = data_users?.users;
  const permissionList = data_permissions?.permissions;

  const usersWithPermissions = users?.map(u => {
    const userPermissions = u.permissions || [];
    const authPermissions = u.auth?.permissions || [];

    const permissions: string[] = userPermissions
      .concat(authPermissions)
      .map(p => permissionList?.find(pm => pm.id == p)?.name || "");

    return {
      ...u,
      permissions,
    };
  });

  const user = usersWithPermissions?.find(u => u.id == userId);

  let userTeams = [user?.team?.id || 0];
  if (user?.team?.subTeams) {
    userTeams = userTeams.concat(user.team.subTeams);
  }

  const adminUsers = usersWithPermissions
    ?.filter(
      u =>
        u.team?.id == user?.team?.id ||
        u.team?.subTeams?.includes(user?.team?.id || -1)
    )
    .filter(u => u.permissions.includes(permission))
    .filter(u => u.id !== user?.id);

  const directAdmins = usersWithPermissions
    ?.filter(u => u.team?.id == user?.team?.id)
    .filter(u => u.permissions.includes(permission))
    .filter(u => u.id !== user?.id);

  const subUsers = usersWithPermissions
    ?.filter(
      u =>
        userTeams.includes(u.team?.id || -1) &&
        user?.permissions.includes(permission)
    )
    .filter(u => u.id !== user?.id);

  return { adminUsers, directAdmins, subUsers };

  // users under administration of this user?
};
