import { GET_MATERIALS } from "../../../materials/gqls";
import { useMutation, useQuery } from "@apollo/client";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Button, Select, Table, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DeductionType, deductionSchema } from "../../../workorder/types";
import {
  ADD_DEDUCTION,
  GET_DEDUCTIONS,
  GET_PRODUCT_SETS,
} from "../../../workorder/gqls";
import { DEDUCTION_CORE_FIELDS } from "../../../workorder/fragments";
import { sort } from "fast-sort";
import usePopulateOption from "../usePopulateOption";
import CheckboxWithLabel from "../../../../../comps/CheckboxWithLabel";
import DeductionImage from "./deductionImage";
import OptionCondition from "../optionCondition";
import UsedMats from "./usedMats";
import Deduction from "./deduction";

interface NewDeductionType {
  productSetID?: number | null;
  itemId?: number | null;
  name: string;
  width?: number | null;
  height?: number | null;
  parent?: number | null;
  widthAddition?: number | null;
  heightAddition?: number | null;
  optionCode?: string | null;
  usedItems?:
    | {
        id: number;
        qty: string;
        unit: string;
      }[]
    | null;
  image?: string | null;
  imageTexts?:
    | {
        scope: string;
        rotation: number;
        coord: {
          x: number;
          y: number;
        };
      }[]
    | null;
  pending: boolean;
  optionCondition?:
    | {
        option: number;
        value: number | string;
        displayValue?: number | string;
      }[]
    | null;
}

interface OptionPopulatedDeductionType extends DeductionType {
  populatedOptionCondition?: {
    option: number | string;
    value: string | number;
  }[];
}

export default function Deductions() {
  const { data: data_mats } = useQuery(GET_MATERIALS);
  const { data, loading } = useQuery(GET_DEDUCTIONS);

  const [filter, setFilter] = useState("");

  const [populatedDeductions, setPopulatedDeductions] = useState<
    OptionPopulatedDeductionType[]
  >([]);

  const deductions = populatedDeductions.filter(
    ded =>
      filter.trim() == "" ||
      `${ded.name} ${ded.productSet?.name} ${ded.optionCode} ${
        ded.populatedOptionCondition &&
        sort(ded.populatedOptionCondition)
          .by([{ asc: o => o.option }, { asc: o => o.value }])
          .map(oc => `${oc.option}:${oc.value}`)
          .join()
      }`
        .toLowerCase()
        .includes(filter.trim().toLocaleLowerCase())
  );

  const populate = usePopulateOption();

  const populateOptions = async (deductions: DeductionType[]) => {
    return await Promise.all(
      deductions.map(async deduction => {
        const populatedOptionCondition = deduction.optionCondition
          ? await Promise.all(
              deduction.optionCondition.map(async option => {
                const populated = await populate(option);

                return populated;
              })
            )
          : deduction.optionCondition;

        return { ...deduction, populatedOptionCondition };
      })
    );
  };

  useEffect(() => {
    if (!data?.deductions || data?.deductions.length < 1) {
      return;
    }

    populateOptions(data?.deductions).then(d => {
      // @ts-ignore
      setPopulatedDeductions(d);
    });
  }, [data?.deductions]);

  const { data: data_products } = useQuery(GET_PRODUCT_SETS);

  const [insert, { loading: inserting }] = useMutation(ADD_DEDUCTION);

  const defaultValues: NewDeductionType = {
    productSetID: null,
    itemId: null,
    name: "",
    width: 0,
    height: 0,
    parent: null,
    widthAddition: null,
    heightAddition: null,
    optionCode: null,
    usedItems: [],
    image: null,
    imageTexts: [],
    pending: false,
    optionCondition: [],
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(deductionSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const productSetID = useWatch({
    name: "productSetID",
    control,
  });

  const selectedProduct = data_products?.productSets.find(
    p => p.id == Number(productSetID)
  );

  const optionCodes = selectedProduct?.productSetOptions
    ?.filter(option => option.optionCode && option.source == "deductions")
    .map(option => option.optionCode)
    .reduce<string[]>((prev, cur) => {
      if (!cur || prev.includes(cur)) {
        return prev;
      }
      return prev.concat(cur);
    }, []);

  const availableMats =
    data_mats?.materials &&
    sort(
      data_mats.materials.filter(
        mat => mat.product.id == selectedProduct?.product.id
      )
    ).asc([m => m.brand, m => m.name, m => m.color, m => m.size]);

  const onSubmit = handleSubmit(async data => {
    insert({
      variables: {
        object: {
          ...data,
          itemId: data.itemId == 0 ? null : data.itemId,
          parent: data.parent == 0 ? null : data.parent,
          optionCode: data.optionCode == "" ? null : data.optionCode,
        },
      },
      onCompleted() {
        reset();
        setAdding(false);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            deductions(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_deductions_one,
                fragment: DEDUCTION_CORE_FIELDS,
                fragmentName: "DeductionCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  });

  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const cancel = () => {
    reset();
    setAdding(!adding);
  };

  const pending = useWatch({
    name: "pending",
    control,
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-end items-center gap-2">
        <TextInput
          onChange={e => setFilter(e.target.value)}
          value={filter}
          placeholder="search"
          type="search"
          name="searchMaterial"
          autoComplete="off"
          icon={MagnifyingGlassIcon}
          sizing="sm"
        />
        {!adding && (
          <Button
            gradientDuoTone="purpleToBlue"
            outline
            size="xs"
            onClick={toggleAdding}
          >
            new
          </Button>
        )}
      </div>
      {adding && (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className="mb-4">
            <div className="flex flex-col gap-2">
              <h3>New Deduction</h3>
              <div className="flex flex-row justify-between gap-2 flex-wrap">
                <div className="flex flex-row gap-2 flex-wrap">
                  <Select
                    addon="Product"
                    {...register("productSetID")}
                    sizing="sm"
                  >
                    <option value="">Select product</option>

                    {data_products?.productSets.map(p => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Select>
                  {/* <Select addon="Mat" {...register("itemId")} sizing="sm">
                    <option value="">Related Material (optional)</option>

                    {availableMats?.map(p => (
                      <option key={p.id} value={p.id}>
                        {p.brand} {p.name} {p.color}
                      </option>
                    ))}
                  </Select> */}
                </div>
                <CheckboxWithLabel
                  name="pending"
                  checked={pending}
                  onChange={() => {
                    setValue("pending", !pending);
                  }}
                />
              </div>
              <div className="flex flex-row gap-2 flex-wrap">
                <TextInput
                  {...register("name")}
                  sizing="sm"
                  placeholder="Enter Name"
                  addon="Name"
                />
                <TextInput
                  {...register("width")}
                  sizing="sm"
                  addon="W"
                  placeholder="Width"
                  className="w-32"
                  step="any"
                  type="number"
                />
                <TextInput
                  {...register("height")}
                  sizing="sm"
                  addon="H"
                  placeholder="Height"
                  className="w-32"
                  type="number"
                  step="any"
                />
                <Select
                  {...register("optionCode")}
                  sizing="sm"
                  addon="OC"
                  placeholder="option code"
                >
                  <option value="">None</option>
                  {optionCodes?.map(optionCode => (
                    <option key={optionCode}>{optionCode}</option>
                  ))}
                </Select>
              </div>
              {productSetID && (
                <>
                  <h4 className="font-semibold">Option Conditions</h4>
                  <OptionCondition productSetID={productSetID} />
                  <hr />
                </>
              )}
              <h4 className="font-semibold">
                Deduction Image (Opening Product Only)
              </h4>
              <hr />
              <DeductionImage />
              <h4 className="font-semibold">Used Materials</h4>
              <UsedMats availableMats={availableMats} />
              <hr />
              <h4 className="font-semibold dark:text-white">
                Parent Deduction
              </h4>
              <div className="flex flex-row gap-2 flex-wrap">
                <Select addon="Parent" {...register("parent")} sizing="sm">
                  <option value="">Parent Deduction (optional)</option>

                  {deductions?.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Select>
                <TextInput
                  {...register("widthAddition")}
                  sizing="sm"
                  addon="addW"
                  placeholder="W Addition"
                  className="w-40"
                  type="number"
                />
                <TextInput
                  {...register("heightAddition")}
                  sizing="sm"
                  addon="addH"
                  placeholder="H Addition"
                  className="w-40"
                  type="number"
                />
              </div>
              <hr />
              <div className="flex flex-row justify-end items-center gap-2">
                <Button size="xs" color="purple" onClick={cancel}>
                  cancel
                </Button>
                <Button
                  size="xs"
                  gradientDuoTone="purpleToBlue"
                  outline
                  type="submit"
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
      <Table striped hoverable className="select-text">
        <Table.Head>
          <Table.HeadCell className="px-2">id</Table.HeadCell>
          <Table.HeadCell className="px-2">product</Table.HeadCell>
          <Table.HeadCell className="px-2">name</Table.HeadCell>
          <Table.HeadCell className="px-2">width</Table.HeadCell>
          <Table.HeadCell className="px-2">height</Table.HeadCell>
          <Table.HeadCell className="px-2">option Code</Table.HeadCell>
          <Table.HeadCell className="px-2">Option Conditions</Table.HeadCell>
          <Table.HeadCell className="px-2">mats</Table.HeadCell>
          <Table.HeadCell className="px-2">image</Table.HeadCell>
          <Table.HeadCell className="p-0" />
        </Table.Head>
        <Table.Body className="text-black dark:text-white">
          {deductions &&
            sort(deductions)
              .by([
                { desc: d => d.pending },
                { asc: d => d.productSet?.name },
                { asc: d => d.optionCode },
                {
                  asc: d =>
                    d.optionCondition &&
                    sort(d.optionCondition)
                      .by([{ asc: o => o.option }, { asc: o => o.value }])
                      .map(oc => `${oc.option}:${oc.value}`)
                      .join(),
                },
                { asc: d => d.name },
              ])
              ?.map(item => (
                <Deduction
                  key={item.id}
                  item={item}
                  materials={data_mats?.materials}
                  productSets={data_products?.productSets}
                  deductions={deductions}
                />
              ))}
        </Table.Body>
      </Table>
    </div>
  );
}
