import { useRef } from "react";
import { Modal } from "flowbite-react";
import { useScheme } from "../../../store/schemeStore";
import { useOnClickOutside } from "usehooks-ts";

import Booking from "../shared/schedules/booking";

interface props {
  adding?: boolean;
  cb?: () => void;
}

export default function NewSchedule({ adding, cb }: props) {
  const onClose = () => {
    if (cb) {
      cb();
    }
  };

  const ref = useRef<HTMLDivElement>(null);
  // useOnClickOutside(ref, () => {
  //   onClose();
  // });

  const { scheme } = useScheme();

  return (
    <Modal
      show={adding}
      onClose={onClose}
      className={`${scheme} ${scheme == "dark" && "text-white"} `}
    >
      <div className="mt-16 md:mt-0" ref={ref}>
        <Booking cancelFunc={onClose} cb={onClose} />
      </div>
    </Modal>
  );
}
