import {
  ArrowDownTrayIcon,
  ClipboardIcon,
  PencilIcon,
  ScissorsIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { Button, ListGroup, Modal, Spinner } from "flowbite-react";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useScheme } from "../../../../store/schemeStore";
import { get, remove, rename } from "../../../../utils/fileAPI";
import { FileType } from "./type";
import { useForm } from "react-hook-form";

interface props {
  path: string[];
  file: FileType;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPath: React.Dispatch<React.SetStateAction<string[]>>;
  clipboard: {
    path: string[];
    copy: boolean;
  };
  setClipboard: React.Dispatch<
    React.SetStateAction<{
      path: string[];
      copy: boolean;
    }>
  >;
}

export default function Menu({
  path,
  file,
  setShowMenu,
  setCurrentPath,
  setClipboard,
}: props) {
  const { scheme } = useScheme();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const toggleModal = () => {
    setShow(!show);
  };
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    !show && setShowMenu(false);
  });

  const defaultValues = {
    name: file.name,
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const [downloading, setDownloading] = useState(false);

  const location = path.slice(0, path.length - 1);

  const download = async () => {
    setDownloading(true);
    await get(path);
    setDownloading(false);
  };

  const removeItem = async () => {
    const proceed = confirm(
      "Do you really want to delete the item? " + file.name
    );
    if (proceed) {
      setLoading(true);
      await remove(path);
      setCurrentPath(location);
      setLoading(false);
    }
  };

  const changeName = handleSubmit(async data => {
    setLoading(true);
    const newPath = [...location, data.name];
    await rename(path, newPath);
    setCurrentPath(location);
    setLoading(false);
  });

  const cancel = () => {
    toggleModal();
    reset();
  };

  const copy = () => {
    setClipboard({
      path,
      copy: true,
    });
    setShowMenu(false);
  };

  const cut = () => {
    setClipboard({
      path,
      copy: false,
    });
    setShowMenu(false);
  };

  return (
    <div ref={ref} className="absolute right-0 top-[100%] mt-2 z-10">
      <ListGroup>
        {file.type !== "d" && (
          <ListGroup.Item>
            <div
              className="flex flex-row gap-2 items-center"
              onClick={download}
            >
              <ArrowDownTrayIcon className="w-4" /> download
              {downloading && <Spinner light color="purple" size="sm" />}
            </div>
          </ListGroup.Item>
        )}

        <ListGroup.Item onClick={toggleModal}>
          <div className="flex flex-row gap-2 items-center">
            <PencilIcon className="w-4" /> rename
          </div>
        </ListGroup.Item>
        {file.type !== "d" && (
          <ListGroup.Item onClick={copy}>
            <div className="flex flex-row gap-2 items-center">
              <ClipboardIcon className="w-4" /> copy
            </div>
          </ListGroup.Item>
        )}

        <ListGroup.Item onClick={cut}>
          <div className="flex flex-row gap-2 items-center">
            <ScissorsIcon className="w-4" /> cut
          </div>
        </ListGroup.Item>
        <ListGroup.Item onClick={removeItem}>
          <div className="flex flex-row gap-2 items-center text-red-500">
            <TrashIcon className="w-4" /> delete
          </div>
        </ListGroup.Item>
      </ListGroup>
      <Modal show={show} onClose={toggleModal} className={`${scheme}`}>
        <Modal.Header>Rename</Modal.Header>
        <Modal.Body>
          <form onSubmit={changeName} className="space-y-4">
            <input
              {...register("name")}
              className="bg-transparent dark:text-white w-full rounded-md p-[5px] px-[8px] border-none outline-none text-lg"
            />
            <div className="flex flex-row justify-end gap-2">
              <Button size="sm" gradientDuoTone="purpleToBlue" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="sm"
                gradientDuoTone="purpleToBlue"
                outline
                type="submit"
              >
                update
                {loading && (
                  <Spinner light size="sm" className="ml-2" color="purple" />
                )}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
