import axios from "axios";
import { ProcessType, special } from "../routes/protected/process/types";
import { fullNumber } from "./fullNumber";
const API = import.meta.env.VITE_API;
const ISDEVSTR = import.meta.env.VITE_FILE_TEST;
const ISDEV = ISDEVSTR == "TRUE";

export const exists = async (path: string[]) => {
  try {
    const res = await axios.post(API + "/file/exists", { path });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const mkdir = async (path: string[]) => {
  try {
    await axios.post(API + "/file/mkdir", { path });
    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const rename = async (oldPath: string[], newPath: string[]) => {
  try {
    await axios.post(API + "/file/rename", { oldPath, newPath });
    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const copy = async (oldPath: string[], newPath: string[]) => {
  try {
    await axios.post(API + "/file/copy", { oldPath, newPath });
    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const list = async (path: string[]) => {
  try {
    const res = await axios.post(API + "/file/list", { path });
    return res.data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export const get = async (path: string[], use?: boolean) => {
  try {
    const response = await axios.post(
      API + "/file/get",
      { path },
      { responseType: "blob" }
    );

    if (!response) {
      return;
    }

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));

    if (use) {
      return fileURL;
    }

    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    const fileName = path[path.length - 1];
    fileLink.setAttribute("download", fileName);
    fileLink.setAttribute("target", "_blank");
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();

    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const remove = async (path: string[]) => {
  try {
    await axios.post(API + "/file/remove", { path });
    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const upload = async (doc: string, path: string[]) => {
  try {
    await axios.post(API + "/file/upload", { doc, path });
    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createPathArr = (process: ProcessType) => {
  const fullNum = `${fullNumber(
    process.processType.prefix,
    process.year,
    process.number,
    process.salesRepUser
  )}`;

  const hasPath =
    process.processType.path && process.processType.path.length > 0;

  const folderName = `${fullNum} ${process.name}`
    .replaceAll(special, "")
    .replaceAll(/  +/g, " ")
    .trim();

  const jobPath: false | string[] = hasPath
    ? ISDEV
      ? [
          "JNC",
          "94 Peter",
          "Test",
          ...process.processType.path,
          process.year.toString(),
          folderName,
        ]
      : [...process.processType.path, process.year.toString(), folderName]
    : false;

  return jobPath;
};
