import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  addEvent,
  newEventType,
  updateEvent,
} from "../../../../utils/calendarMethods";
import {
  TextInput,
  Textarea,
  Label,
  ToggleSwitch,
  Select,
  Button,
  Spinner,
  Badge,
} from "flowbite-react";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import { useAuthStore } from "../../../../store/authStore";
import { UserType } from "../../settings/users/types";
import { z } from "zod";
import { fullNumber } from "../../../../utils/fullNumber";
// import { GET_PROCESS_TYPES } from "../../settings/process/processTypes/processTypes";
import { ProcessType } from "../../process/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { GET_CONTACTS_BY_IDS } from "../../process/processDetail/processActions";
import { UPDATE_PROCESS_BY_PK } from "../../process/processDetail/gqls";
import { addAlert } from "../../../../store/alertStore";
import {
  ExclamationTriangleIcon,
  PlusCircleIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import {
  Autocomplete,
  AutocompleteProps,
  LoadScriptProps,
  useLoadScript,
} from "@react-google-maps/api";
import { default as configs } from "../../../../config";
import { Event } from "../../../../types/calendarTypes";
import { addDays } from "../../../../utils/dateMethods";
import { GET_USERS } from "../../settings/users/gql";
import checkAuth from "../../../../utils/checkAuth";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
const companyLoc = configs.companyLoc;
const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];
// import { useDebounce } from "usehooks-ts";
import { GET_PRODUCTS } from "../../settings/products/products";
import { ContactType } from "../../contact/types";
import { sort } from "fast-sort";
import isNullish from "../../../../utils/isNullish";

interface props {
  process?: ProcessType;
  schedule?: Event;
  cancelFunc?: () => void;
  cb?: () => void;
}

const bookingSchema = z
  .object({
    summary: z.string().min(1, { message: "title is required" }),
    description: z.string().optional(),
    allDay: z.boolean(),
    calIds: z.array(z.string()).min(1, { message: "user must be selected" }),
    startTime: z.string(),
    endTime: z.string(),
    address: z.string(),
  })
  .refine(
    booking => {
      return (
        new Date(booking.startTime).getTime() <
          new Date(booking.endTime).getTime() || booking.allDay
      );
    },
    {
      message: "invalid timeframe",
      path: ["time"],
    }
  );

interface bookingProp {
  summary: string;
  description: string;
  startTime: string;
  endTime: string;
  allDay: boolean;
  address: string;
  calIds: string[];
}

export default function Booking({ process, schedule, cancelFunc, cb }: props) {
  const { user } = useAuthStore();
  const { data: data_products } = useQuery(GET_PRODUCTS);
  const products = data_products?.products;

  let regex = /\[[^\]]*\]/i;

  const exSummary = process
    ? schedule?.summary
        ?.replace(regex, "")
        .replace(`${process.name} / `, "")
        .trimStart() || ""
    : schedule?.summary || "";

  const exAllDay = schedule?.start?.date !== undefined;

  const exStartTime = schedule
    ? dayjs(
        new Date(schedule.start?.date || schedule.start?.dateTime || "")
      ).format(`${exAllDay ? "YYYY-MM-DD" : "YYYY-MM-DDTHH:mm"}`)
    : dayjs(new Date().setHours(8, 0, 0, 0)).format("YYYY-MM-DDTHH:mm");

  const exEndTime = schedule
    ? dayjs(
        addDays(
          new Date(schedule.end?.date || schedule.end?.dateTime || ""),
          exAllDay ? -1 : 0
        )
      ).format(`${exAllDay ? "YYYY-MM-DD" : "YYYY-MM-DDTHH:mm"}`)
    : dayjs(new Date().setHours(10, 0, 0, 0)).format("YYYY-MM-DDTHH:mm");

  const exAddress = schedule
    ? schedule.location || ""
    : process
    ? process.address || ""
    : "";

  const exCalIds = schedule ? [schedule.organizer?.email || ""] : [];

  const defaultValues: bookingProp = {
    summary: exSummary,
    description: schedule ? schedule.description || "" : "",
    startTime: exStartTime,
    endTime: exEndTime,
    allDay: schedule ? exAllDay : false,
    address: exAddress,
    calIds: exCalIds,
  };

  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues,
    resolver: zodResolver(bookingSchema),
  });

  const formDescription = watch("description");

  const [calIdToAdd, setCalIdToAdd] = useState<null | string>(null);
  const [contactToAdd, setContactToAdd] = useState<undefined | ContactType>();

  const allDay = watch("allDay");
  const endTime = watch("endTime");
  const startTime = watch("startTime");
  const calIds = watch("calIds");

  // const debouncedEndTime = useDebounce(endTime, 2000);
  // const debouncedStartTime = useDebounce(startTime, 2000);

  // useEffect(() => {
  //   if (new Date(endTime).getTime() < new Date(startTime).getTime()) {
  //     if (allDay) {
  //       setValue("endTime", startTime, {
  //         shouldValidate: true,
  //         shouldDirty: true,
  //         shouldTouch: true,
  //       });
  //     } else {
  //       const newEndTime = dayjs(startTime)
  //         .add(2, "hours")
  //         .format("YYYY-MM-DDTHH:mm");
  //       setValue("endTime", newEndTime, {
  //         shouldValidate: true,
  //         shouldDirty: true,
  //         shouldTouch: true,
  //       });
  //     }
  //   }
  // }, [debouncedStartTime]);

  // useEffect(() => {
  //   if (new Date(endTime).getTime() < new Date(startTime).getTime()) {
  //     if (allDay) {
  //       setValue("startTime", endTime, {
  //         shouldValidate: true,
  //         shouldDirty: true,
  //         shouldTouch: true,
  //       });
  //     } else {
  //       const newStartTime = dayjs(endTime)
  //         .subtract(2, "hours")
  //         .format("YYYY-MM-DDTHH:mm");
  //       setValue("startTime", newStartTime, {
  //         shouldValidate: true,
  //         shouldDirty: true,
  //         shouldTouch: true,
  //       });
  //     }
  //   }
  // }, [debouncedEndTime]);

  const [loading, setLoading] = useState(false);

  const [update_process, { loading: updating }] =
    useMutation(UPDATE_PROCESS_BY_PK);
  const { data: data_user, error } = useQuery(GET_USERS);
  // const { data: data_process_type, error: error_pt } =
  //   useQuery(GET_PROCESS_TYPES);
  // if (error_pt) {
  //   console.log(error_pt);
  // }

  const { data: data_process_contacts } = useQuery(GET_CONTACTS_BY_IDS, {
    variables: { ids: process?.contacts || [] },
  });
  const contacts = data_process_contacts?.contacts;

  const users: UserType[] =
    (data_user?.users &&
      sort(
        data_user?.users
          ?.filter(
            u =>
              (checkAuth([
                "schedule_view_all",
                {
                  permission: "schedule_view_team",
                  checkGroup: "userTeam",
                  conditionGroup: [u.team?.id || -1],
                },
              ]) ||
                u.id == user?.id) &&
              u.active
          )
          .filter(u => !isNullish(u.calId))
      ).asc("firstName")) ||
    [];

  useEffect(() => {
    if (isDirty) {
      return;
    }

    if (users.length == 1) {
      reset({
        calIds: [users[0].calId],
      });
    }
  }, [users.length]);

  if (error) {
    console.log(error);
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const autoCompleteLoaded = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const { formatted_address: address } = place;

      if (address) {
        setValue("address", address.replace(", Australia", "").trim());
      }
    }
  };

  const autocompleteOptions: AutocompleteProps["options"] = {
    componentRestrictions: { country: "au" },
    bounds: {
      north: companyLoc.lat + 3,
      south: companyLoc.lat - 3,
      east: companyLoc.lng + 3,
      west: companyLoc.lng - 3,
    },
  };

  const onSubmit = handleSubmit(async data => {
    const { calIds, summary, description, startTime, endTime, address } = data;

    if (loading) {
      return;
    }

    setLoading(true);

    let _summary = summary;

    if (process) {
      const fullNum = `[${fullNumber(
        process.processType.prefix,
        process.year,
        process.number,
        process.salesRepUser
      )}]`;
      _summary = `${fullNum} ${process.name} / ${summary}`;
    }

    const _description = `${description}${"\n"}Organised by ${
      user?.firstName
    } ${user?.sirName}`;

    const start = allDay
      ? {
          date: startTime,
        }
      : { dateTime: new Date(startTime) };

    const end = allDay
      ? {
          date: endTime,
        }
      : { dateTime: new Date(endTime) };

    if (schedule) {
      const updatedEvents = await Promise.all(
        calIds.map(async (calId, i) => {
          const event: newEventType = {
            summary: _summary,
            calId: calId,
            start,
            end,
            description: _description,
            location: address,
          };
          if (i == 0) {
            try {
              const updatedEvent = await updateEvent({
                event: {
                  ...event,
                  description: description,
                  id: schedule.id || "",
                },
                prevId: schedule.organizer?.email || "",
              });

              const id = `${updatedEvent.calId}::${updatedEvent.id}`;

              return id;
            } catch (error) {
              console.log(error);
              return "error";
            }
          } else {
            try {
              const newEvent = await addEvent(event);
              const id = `${newEvent.calId}::${newEvent.id}`;
              return id;
            } catch (error) {
              console.log(error);
              return "error";
            }
          }
        })
      );

      if (process) {
        const _updatedEvents: string[] = updatedEvents.filter(
          e => e !== "error"
        );

        const schedules = process.schedules
          ? process.schedules
              .filter(
                s =>
                  s !==
                  `${schedule.organizer?.email || ""}::${schedule.id || ""}`
              )
              .concat(_updatedEvents)
          : _updatedEvents;

        update_process({
          variables: {
            id: process.id,
            set: {
              schedules,
            },
          },
          onError(error) {
            console.log(error);
            addAlert({
              message: "cannot update schedules",
              type: "failure",
            });
          },
          onCompleted() {
            reset();
            setLoading(false);
            if (cb) {
              cb();
            }
          },
        });
      } else {
        if (cb) {
          setLoading(false);
          cb();
        }
      }
    } else {
      const addedEvents = await Promise.all(
        calIds.map(async calId => {
          const event: newEventType = {
            summary: _summary,
            calId: calId,
            start,
            end,
            description: _description,
            location: address,
          };

          try {
            const newEvent = await addEvent(event);
            const id = `${newEvent.calId}::${newEvent.id}`;
            return id;
          } catch (error) {
            console.log(error);
            return "error";
          }
        })
      );

      if (process) {
        const _addedEvents: string[] = addedEvents.filter(e => e !== "error");
        const schedules = process.schedules
          ? process.schedules.concat(_addedEvents)
          : _addedEvents;
        update_process({
          variables: {
            id: process.id,
            set: {
              schedules,
            },
          },
          onError(error) {
            console.log(error);
            addAlert({
              message: "cannot update schedules",
              type: "failure",
            });
          },
          onCompleted() {
            reset();
            if (cb) {
              cb();
            }
          },
        });
        setLoading(false);
      } else {
        if (cb) {
          cb();
        }
        setLoading(false);
      }
    }
  });

  const addCalId = () => {
    if (calIdToAdd) {
      setValue("calIds", calIds.concat(calIdToAdd));
      setCalIdToAdd(null);
    }
  };

  const deleteCalId = (id: string) => {
    setValue(
      "calIds",
      calIds.filter(i => i !== id)
    );
  };

  const cancel = () => {
    if (cancelFunc) {
      cancelFunc();
    }
  };

  const addProductInfo = () => {
    if (!process?.products || process.products.length < 1) {
      return;
    }

    const newDesc = formDescription.concat(
      `\n${process.products.map(p => {
        const product = products?.find(pro => pro.id == p.productId);
        return `${product?.name || "Deleted Product"} ${p.qty} `;
      })}`
    );

    setValue("description", newDesc);
  };

  const addContact = () => {
    if (!contactToAdd) {
      return;
    }

    const newDesc = `${contactToAdd.name} ${contactToAdd.number}\n`.concat(
      formDescription
    );

    setValue("description", newDesc);
  };

  return (
    <form onSubmit={onSubmit} className="space-y-2 w-full">
      <div className="shadow-md flex-1 bg-white dark:bg-gray-800 rounded-md p-4 space-y-2">
        {/* Title */}
        <div className="space-y-2">
          <Label value="Title" />
          <TextInput
            type="text"
            sizing="sm"
            {...register("summary")}
            color={errors.summary?.message ? "failure" : undefined}
            placeholder="Enter title"
            helperText={errors.summary?.message || ""}
          />
        </div>
        {/* All day? */}
        <div className="space-y-2 pt-1 flex justify-end">
          <ToggleSwitch
            label="All day"
            //@ts-expect-error
            color="purple"
            checked={allDay}
            className="mb-[-10px]"
            onChange={() => {
              setValue("allDay", !allDay);
              setTimeout(() => {
                if (!allDay) {
                  setValue("startTime", dayjs(startTime).format("YYYY-MM-DD"));
                  setValue("endTime", dayjs(endTime).format("YYYY-MM-DD"));
                } else {
                  setValue(
                    "startTime",
                    dayjs(startTime).format("YYYY-MM-DDTHH:mm")
                  );
                  setValue(
                    "endTime",
                    dayjs(endTime).format("YYYY-MM-DDTHH:mm")
                  );
                }
              }, 10);
            }}
          />
        </div>
        {/* Time */}

        <div className="flex flex-row flex-wrap gap-2">
          {/* Start */}
          <div className="space-y-2 flex-1">
            <Label value="Start" />
            <TextInput
              type={allDay ? "date" : "datetime-local"}
              sizing="sm"
              step={allDay ? 0 : 60 * 15}
              // max={allDay ? undefined : endTime}
              {...register("startTime")}
              color={errors.startTime?.message ? "failure" : undefined}
              helperText={errors.startTime?.message || ""}
            />
          </div>
          {/* End */}
          <div className="space-y-2 flex-1">
            <Label value="End" />
            <TextInput
              type={allDay ? "date" : "datetime-local"}
              sizing="sm"
              step={allDay ? 0 : 60 * 15}
              // min={allDay ? undefined : startTime}
              {...register("endTime")}
              color={errors.endTime?.message ? "failure" : undefined}
              helperText={errors.endTime?.message || ""}
            />
          </div>
        </div>

        {/* Address */}
        <div className="space-y-2">
          <Label value="Address" />
          {isLoaded && (
            <Autocomplete
              onLoad={autoCompleteLoaded}
              onPlaceChanged={onPlaceChanged}
              options={autocompleteOptions}
              fields={[
                "formatted_address",
                "geometry.location",
                "address_component",
              ]}
              className="flex-[2]"
            >
              <TextInput
                sizing="sm"
                {...register("address")}
                color={errors.address?.message ? "failure" : undefined}
              />
            </Autocomplete>
          )}
        </div>
        {/* Desc */}
        <div className="space-y-2">
          <Label value="Desc" />
          <Textarea
            rows={3}
            {...register("description")}
            color={errors.description?.message ? "failure" : undefined}
            helperText={errors.description?.message || ""}
            className="text-sm md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-800"
          />
          <div className="flex flex-row justify-between items-center flex-wrap">
            {process?.products && process.products.length > 0 && (
              <div
                onClick={addProductInfo}
                className="flex flex-row cursor-pointer hover:text-plum gap-2 items-center"
              >
                <PlusCircleIcon className="w-4" /> product info
              </div>
            )}
          </div>
          {contacts && contacts.length > 0 && (
            <div className="flex flex-row justify-end items-center flex-wrap gap-2">
              <UserPlusIcon className="w-4 " />
              <select
                className="text-sm p-0 bg-transparent border-none outline-none capitalize flex-1 w-0"
                value={contactToAdd?.id}
                onChange={e => {
                  const contact = contacts.find(
                    c => c.id.toString() == e.target.value
                  );
                  setContactToAdd(contact);
                }}
              >
                <option className="dark:bg-gray-700" value={undefined}>
                  select contact
                </option>
                {contacts.map(contact => (
                  <option
                    key={contact.id}
                    value={contact.id}
                    className="capitalize dark:bg-gray-700"
                  >
                    {contact.name}
                  </option>
                ))}
              </select>
              <Button
                size="xs"
                outline
                gradientDuoTone="purpleToBlue"
                onClick={addContact}
              >
                add
              </Button>
            </div>
          )}
        </div>
        {/* By */}
        {users.length > 1 && (
          <div className="space-y-2">
            <Label value="By" />
            <div className="flex flex-row gap-2 items-center">
              <Select
                sizing="sm"
                className="flex-1"
                onChange={e => {
                  setCalIdToAdd(e.target.value);
                }}
              >
                <option value="">Select user</option>
                {users
                  .filter(u => u.calId && !calIds.includes(u.calId))
                  .map(u => (
                    <option
                      key={u.id}
                      value={u.calId?.toString()}
                      className="capitalize"
                    >
                      {u.firstName} {u.sirName}
                    </option>
                  ))}
              </Select>
              <Button
                size="xs"
                outline
                gradientDuoTone="purpleToBlue"
                onClick={addCalId}
              >
                add
              </Button>
            </div>
            <div className="flex flex-row gap-2 flex-wrap">
              {calIds.length == 0 && (
                <Badge color="purple">
                  <div className="flex flex-row gap-1 items-center">
                    <ExclamationTriangleIcon className="w-3" /> Add at least one
                    user
                  </div>
                </Badge>
              )}
              {calIds.map(calId => (
                <Badge
                  color="purple"
                  key={calId}
                  className="cursor-pointer"
                  onClick={() => {
                    deleteCalId(calId);
                  }}
                >
                  <div className="flex flex-row gap-1 items-center capitalize">
                    {users?.find(u => u.calId == calId)?.firstName}
                    <TrashIcon className="w-3" />
                  </div>
                </Badge>
              ))}
            </div>
          </div>
        )}
        {/* Submit */}
        <div className="pt-2 flex flex-row justify-end gap-2">
          {cancelFunc && (
            <Button size="xs" gradientDuoTone="purpleToBlue" onClick={cancel}>
              cancel
            </Button>
          )}
          <Button
            size="xs"
            gradientDuoTone="purpleToBlue"
            outline
            type="submit"
          >
            {(loading || updating) && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            {schedule ? "Amend" : "Send"} Booking
          </Button>
        </div>
      </div>
    </form>
  );
}
