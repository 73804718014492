import { gql, useQuery, TypedDocumentNode, useMutation } from "@apollo/client";
import { PROCESS_STATUS_CORE_FIELDS } from "./fragments";
import { ProcessStatusArrayType, ProcessStatusType } from "./types";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
  TouchSensor,
  DragOverlay,
  Active,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useState, useMemo } from "react";
import { createPortal } from "react-dom";
import ProcessStatus from "./processStatus";
import { addAlert } from "../../../../../store/alertStore";
import { sort } from "fast-sort";
import { Spinner, Button } from "flowbite-react";
import checkAuth from "../../../../../utils/checkAuth";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

export const GET_PROCESS_STATUSES: TypedDocumentNode<ProcessStatusArrayType> = gql`
  ${PROCESS_STATUS_CORE_FIELDS}
  query GET_PROCESS_STATUSES {
    processStatus {
      ...ProcessStatusCoreFields
    }
  }
`;

export const SORT_PROCESS_STATUS = gql`
  ${PROCESS_STATUS_CORE_FIELDS}
  mutation SORT_PROCESS_STATUSES($id: Int!, $priority: Int!) {
    update_processStatus_by_pk(
      pk_columns: { id: $id }
      _set: { priority: $priority }
    ) {
      ...ProcessStatusCoreFields
    }
  }
`;

export const INSERT_PROCESS_STATUS = gql`
  ${PROCESS_STATUS_CORE_FIELDS}
  mutation INSERT_PROCESS_STATUSES(
    $name: String!
    $description: String!
    $color: String!
    $priority: Int!
  ) {
    insert_processStatus_one(
      object: {
        name: $name
        description: $description
        color: $color
        priority: $priority
      }
    ) {
      ...ProcessStatusCoreFields
    }
  }
`;

export default function ProcessStatuses() {
  const [sort_process_status] = useMutation(SORT_PROCESS_STATUS);
  const [insert_process_status, { error: insert_error }] = useMutation(
    INSERT_PROCESS_STATUS
  );

  const [sortedProcessStatus, setSortedProcessStatus] = useState<
    ProcessStatusType[]
  >([]);

  const [touched, setTouched] = useState(false);

  const { data, loading, error } = useQuery(GET_PROCESS_STATUSES, {
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      if (data.processStatus) {
        const sortedProcessStatus = sort(data?.processStatus)
          .asc(ps => ps.priority)
          .map((ps, i) => ({ ...ps, priority: i + 1 }));
        setSortedProcessStatus(sortedProcessStatus);
      }
    },
  });

  if (error) {
    console.log(error);
    addAlert({ message: "could not load process statuses", type: "failure" });
  }

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedProcessStatus.find(item => item.priority === theActive?.id),
    [theActive, sortedProcessStatus]
  );

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedProcessStatus.findIndex(
        ({ priority }) => priority === active.id
      );
      const overIndex = sortedProcessStatus.findIndex(
        ({ priority }) => priority === over.id
      );

      const movedArray = arrayMove(sortedProcessStatus, activeIndex, overIndex);

      setSortedProcessStatus(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const reset = () => {
    if (data?.processStatus) {
      const sortedProcessStatus = sort(data?.processStatus)
        .asc(ps => ps.priority)
        ?.map((ps, i) => ({ ...ps, index: i + 1 }));

      setSortedProcessStatus(sortedProcessStatus);
    }

    setTouched(false);
  };

  const [updating, setUpdating] = useState(false);

  const save = async () => {
    setUpdating(true);

    const newSortedStatus = sortedProcessStatus.map((ps, i) => ({
      ...ps,
      priority: i + 1,
    }));

    setSortedProcessStatus(newSortedStatus);

    for (const ps of newSortedStatus) {
      if (ps.new) {
        await insert_process_status({
          variables: { ...ps, id: undefined },
          update(cache, { data: { insert_processStatus_one: newStatus } }) {
            cache.modify({
              fields: {
                processStatus(existingStatus = []) {
                  const newStatusRef = cache.writeFragment({
                    data: newStatus,
                    fragment: PROCESS_STATUS_CORE_FIELDS,
                  });
                  return [...existingStatus, newStatusRef];
                },
              },
            });
          },
          onError(error) {
            console.log(error);
            addAlert({ message: "could not add new status", type: "failure" });
          },
        });
        continue;
      }
      await sort_process_status({
        variables: {
          id: ps.id,
          priority: ps.priority,
        },
      });
    }

    setUpdating(false);
    setTouched(false);
  };

  const addStatus = () => {
    const newPS: ProcessStatusType = {
      color: "#000000",
      id: Math.random(),
      priority: sortedProcessStatus.length + 1,
      name: "new processStatus",
      description: "please give precise description",
      dueMatters: true,
      new: true,
    };

    setSortedProcessStatus(prev => prev.concat(newPS));
    setTouched(true);
  };

  // auths
  const viewAuth = checkAuth("setting_process_status_access");
  const editAuth = checkAuth("setting_process_status_edit");

  return (
    <div className="mb-1 flex-grow  mx-auto">
      <div className="flex flex-row justify-between items-center">
        <h1>Process Statuses</h1>
        {editAuth && (
          <PlusCircleIcon
            className="text-grass hover:animate-pulse w-6 cursor-pointer"
            onClick={addStatus}
          />
        )}
      </div>
      {loading && !sortedProcessStatus && (
        <div className="flex flex-row justify-center mt-4 mb-6">
          <Spinner color="purple" size="xl" />
        </div>
      )}

      {sortedProcessStatus && viewAuth && (
        <section className="my-4 space-y-2">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]}
          >
            <SortableContext
              disabled={!editAuth}
              items={sortedProcessStatus.map(ps => ps.priority)}
              strategy={verticalListSortingStrategy}
            >
              {sortedProcessStatus.map(ps => (
                <ProcessStatus
                  updating={updating}
                  setSortedProcessStatus={setSortedProcessStatus}
                  key={ps.id}
                  ps={ps}
                />
              ))}
            </SortableContext>
            {activeItem && (
              <>
                {createPortal(
                  <DragOverlay>
                    <ProcessStatus ps={activeItem} />
                  </DragOverlay>,
                  document.body
                )}
              </>
            )}
          </DndContext>
        </section>
      )}

      {!viewAuth && "no access"}

      {touched && (
        <div className="flex space-x-2 justify-end">
          <Button gradientDuoTone="purpleToBlue" size="sm" onClick={reset}>
            Reset
          </Button>

          <Button
            onClick={save}
            outline
            gradientDuoTone="purpleToBlue"
            size="sm"
          >
            {updating && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
