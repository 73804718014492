import { gql } from "@apollo/client";

export const PAYMENT_STATUS_CORE_FIELDS = gql`
  fragment PaymentStatusCoreFields on paymentStatus {
    id
    name
    description
    color
    priority
  }
`;
