import { Table } from "flowbite-react";
import { RecordType } from "../shared/record/types";
import { range } from "./types";
import { sort } from "fast-sort";
import TableItem from "./tableItem";
import { useQuery } from "@apollo/client";
import { GET_RECORD_STATUS } from "../shared/record/gqls";
import dayjs from "dayjs";

interface props {
  records: RecordType[];
  range: range;
  dates: Date[];
}

export default function RecordTableView({ records, range, dates }: props) {
  const sorted = sort(records).by([
    { asc: record => record.user },
    { asc: record => record.start },
  ]);

  const { data } = useQuery(GET_RECORD_STATUS);
  const statuses = data?.recordStatus;
  const rejectedStatus = statuses?.find(stat => stat.name == "rejected");
  const pendingStatus = statuses?.find(stat => stat.name == "pending");

  const totalH = records.reduce<number>((prv, cur) => {
    if (!cur.end) {
      return prv;
    }
    if (
      cur.status.id == rejectedStatus?.id ||
      cur.status.id == pendingStatus?.id
    ) {
      return prv;
    }

    const diff = dayjs(cur.end).diff(dayjs(cur.start));
    const mins = Math.round(diff / 60000 - (cur.break || 0));
    let hour = Math.floor(mins / 60);
    const min = mins % 60;

    if (min < 25) {
      hour = hour;
    } else if (min < 50) {
      hour = hour + 0.5;
    } else {
      hour++;
    }

    if (hour < 0) {
      hour = 0;
    }
    return prv + hour;
  }, 0);

  return (
    <>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Date</Table.HeadCell>
          <Table.HeadCell className="hidden @md:table-cell supports-[not(container-type:inline-size)]:md:table-cell">
            In
          </Table.HeadCell>
          <Table.HeadCell className="hidden @md:table-cell supports-[not(container-type:inline-size)]:md:table-cell">
            Out
          </Table.HeadCell>
          <Table.HeadCell className="hidden @lg:table-cell supports-[not(container-type:inline-size)]:lg:table-cell">
            Break
          </Table.HeadCell>
          <Table.HeadCell className="hidden @sm:table-cell supports-[not(container-type:inline-size)]:sm:table-cell">
            Hour
          </Table.HeadCell>
          <Table.HeadCell className="hidden @lg:table-cell supports-[not(container-type:inline-size)]:lg:table-cell">
            Location
          </Table.HeadCell>
          <Table.HeadCell className="hidden @xl:table-cell supports-[not(container-type:inline-size)]:xl:table-cell">
            Fixes
          </Table.HeadCell>
          <Table.HeadCell className="hidden @xl:table-cell supports-[not(container-type:inline-size)]:xl:table-cell">
            Desc
          </Table.HeadCell>
          <Table.HeadCell className="hidden @sm:table-cell supports-[not(container-type:inline-size)]:sm:table-cell">
            Type
          </Table.HeadCell>
          <Table.HeadCell className="hidden @xs:table-cell supports-[not(container-type:inline-size)]:xs:table-cell">
            Status
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {sorted.map(record => (
            <TableItem key={record.id} record={record} />
          ))}
        </Table.Body>
      </Table>
      <div className="flex flex-row justify-end">Total H {totalH}</div>
    </>
  );
}
