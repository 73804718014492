import { useFormContext, useWatch } from "react-hook-form";
import { useOrderStore } from "../../../../../store/orderStore";
import { Button, TextInput } from "flowbite-react";
import { ChangeEvent, useEffect, useState } from "react";
import { MaterialsType } from "../../../materials/types";
import { useScheme } from "../../../../../store/schemeStore";
import { sort } from "fast-sort";
import { useMutation } from "@apollo/client";
import { UPDATE_MATERIAL_MANY } from "../../../materials/gqls";
import { addAlert } from "../../../../../store/alertStore";

interface props {
  mat?: MaterialsType;
}

export default function Prices({ mat }: props) {
  const { units } = useOrderStore();
  const { materials } = useOrderStore();

  const {
    control,
    setValue,
    reset,
    formState: { isDirty },
  } = useFormContext();

  const prices: MaterialsType["prices"] = useWatch({
    name: "prices",
    control,
    defaultValue: [],
  });

  const [selectedId, setSelectedId] = useState(units[0]?.id || 1);
  const selected = prices?.find(p => p?.unit == selectedId);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (selected) {
      setValue(
        `prices`,
        prices.map(p => {
          if (p?.unit == selectedId) {
            return {
              ...p,
              price: value,
            };
          }
          return p;
        }),
        { shouldDirty: true }
      );
    } else {
      setValue(`prices`, [...prices, { unit: selectedId, price: value }], {
        shouldDirty: true,
      });
    }
  };

  const { scheme } = useScheme();

  useEffect(() => {
    const sortedUnits = sort(units).by({
      desc: u => prices.find(p => p.unit == u?.id)?.price || 0,
    });

    setSelectedId(sortedUnits[0]?.id);
  }, []);

  const sameNamedMats = materials.filter(
    m =>
      mat?.product.id == m.product.id &&
      m.name.toLowerCase() == mat?.name.toLowerCase()
  );

  const [updateMany] = useMutation(UPDATE_MATERIAL_MANY);

  const applyAll = () => {
    const updates = sameNamedMats.map(m => {
      return {
        where: {
          id: { _eq: m.id },
        },
        _set: {
          prices,
        },
      };
    });
    updateMany({
      variables: { updates },
      onCompleted() {
        reset({
          prices,
        });
        addAlert({
          message: "prices successfully updated",
          type: "success",
        });
      },
    });
  };

  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="flex flex-row gap-2 items-center relative">
        <TextInput
          sizing="sm"
          type="number"
          placeholder="price"
          addon="$"
          value={selected?.price || 0}
          onChange={onChange}
          className="w-40"
        />
        <select
          className={`${
            scheme !== "dark" ? "text-black" : "text-white"
          } bg-transparent absolute right-1 border-none outline-none text-sm py-0 px-0 mb-[2px]`}
          value={selectedId}
          onChange={e => {
            setSelectedId(Number(e.target.value));
          }}
        >
          {units.map(u => (
            <option
              key={u?.id}
              value={u?.id}
              className={`${scheme == "dark" ? "text-black" : "text-dark"}`}
            >
              {u.name}
            </option>
          ))}
        </select>
      </div>
      {isDirty && sameNamedMats.length > 0 && (
        <Button size="xs" color="purple" onClick={applyAll}>
          apply all
        </Button>
      )}
    </div>
  );
}
