import { BreifEventList, BreifEvent } from "../../../../../types/calendarTypes";
import { vacancyList, vacancyType } from "../../../../../utils/calendarMethods";
import { UserType } from "../../../settings/users/types";
import { sort } from "fast-sort";
import { addDays, isSameDay } from "../../../../../utils/dateMethods";
import Day from "./day";
import { useEffect, useRef, useState } from "react";
import { Button } from "flowbite-react";

interface props {
  selectedSales: UserType[];
  schedules: BreifEventList[];
  vacancies: vacancyList[];
  setVacancy: (vacancy?: vacancyType) => void;
  setSchedule: (schedule?: BreifEvent) => void;
  selectedSchedule?: BreifEvent;
  selectedVacancy?: vacancyType;
}

export default function Calendar({
  selectedSales,
  schedules,
  vacancies,
  setVacancy,
  setSchedule,
  selectedSchedule,
  selectedVacancy,
}: props) {
  const [seeVacancies, setSeeVacancies] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 40 * 6,
        behavior: "smooth",
      });
    }
  }, []);

  let allSchedules: BreifEvent[] = [];
  schedules.forEach(sl => {
    if (selectedSales.find(ss => ss.id == sl.user.id)) {
      allSchedules = allSchedules.concat([...sl.events]);
    }
  });

  allSchedules = sort(allSchedules).asc(event => new Date(event.start || ""));

  // if (allSchedules.length < 2) {
  //   return <div className="text-sm p-2">No schedules to show</div>;
  // }

  const firstDay = allSchedules[0]?.start
    ? new Date(allSchedules[0]?.start)
    : new Date();
  const lastDay = allSchedules[allSchedules.length - 1]?.start
    ? new Date(allSchedules[allSchedules.length - 1]?.start || "")
    : addDays(firstDay, 14);

  let dates: Date[] = [];

  for (
    let i = firstDay;
    i.getTime() < addDays(lastDay, 1).getTime();
    i = addDays(i, 1)
  ) {
    dates.push(i);
  }

  if (dates.length < 1) {
    return null;
  }

  let allVacancies: vacancyType[] = [];
  vacancies.forEach(v => {
    if (selectedSales.find(ss => ss.id == v.user.id)) {
      allVacancies = allVacancies.concat([...v.vacancies]);
    }
  });

  allVacancies = sort(allVacancies).asc(event => new Date(event.start || ""));

  return (
    <>
      <div
        ref={containerRef}
        className="h-[500px] border-gray-500 border-[1px] relative flex flex-row max-h-screen overflow-scroll md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700"
      >
        {/* Indicators */}
        <div className="sticky left-0 z-30">
          <div className="text-center sticky top-0 left-0 h-14 border-b-[1px] border-gray-500 bg-gray-50 dark:bg-gray-700" />
          {[...Array(24)].map((x, i) => (
            <Indicator i={i} key={i} />
          ))}
        </div>
        {dates.map((date, i) => (
          <Day
            key={date.toISOString()}
            i={i}
            date={date}
            vacancies={allVacancies.filter(s =>
              isSameDay(new Date(s.start || ""), date)
            )}
            setVacancy={setVacancy}
            setSchedule={setSchedule}
            schedules={allSchedules.filter(s =>
              isSameDay(new Date(s.start || ""), date)
            )}
            seeVacancies={seeVacancies}
            selectedSchedule={selectedSchedule}
            selectedVacancy={selectedVacancy}
          />
        ))}
      </div>
      <div className="flex flex-row justify-end">
        <Button.Group>
          <Button
            color={`${!seeVacancies ? "purple" : "gray"}`}
            size="xs"
            className="py-1"
            onClick={() => {
              setSeeVacancies(false);
            }}
          >
            Schedules
          </Button>
          <Button
            color={`${seeVacancies ? "purple" : "gray"}`}
            size="xs"
            className="py-1"
            onClick={() => {
              setSeeVacancies(true);
            }}
          >
            Vacancies
          </Button>
        </Button.Group>
      </div>
    </>
  );
}

const Indicator = ({ i }: { i: number }) => {
  return (
    <div className="h-[40px] pl-2 pr-1 text-right text-xs flex items-end border-r-[1px] bg-gray-100 dark:bg-gray-700 border-gray-500">
      {i == 23 ? "" : i + 1}
    </div>
  );
};
