import { useNavigate } from "react-router-dom";
import { AssignmentType } from "../../assignment/types";
// @ts-expect-error
import md5 from "md5";
import dayjs from "dayjs";
import { Avatar, Badge } from "flowbite-react";
import getContrast from "../../../../utils/getContrast";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../../settings/products/products";

interface props {
  assignment: AssignmentType;
}
export default function AssignmentItem({ assignment }: props) {
  const user = assignment.user;

  let avatarUrl = `https://www.gravatar.com/avatar/${md5(user.email)}?d=mp`;

  const navigate = useNavigate();

  const goToAssignment = () => {
    navigate("/assignment/detail/" + assignment.id);
  };

  const { data: data_products } = useQuery(GET_PRODUCTS);

  const products = assignment.products.map(p => {
    const Product = data_products?.products.find(pro => pro.id == p.productId);
    return {
      ...p,
      product: Product,
    };
  });

  return (
    <div
      className={`shadow-md bg-white dark:bg-gray-800 rounded-md p-3 flex flex-col gap-2 dark:hover:bg-gray-700 hover:bg-gray-50`}
    >
      <div
        className="flex flex-row gap-2 items-center justify-between cursor-pointer"
        onClick={goToAssignment}
      >
        {/* Avatar */}
        <div className="flex flex-row items-center gap-2">
          <Avatar
            img={avatarUrl}
            rounded
            size="md"
            className="cursor-pointer"
          />
          <div className="flex flex-col self-stretch items-start gap-1">
            <div>
              {user.firstName} {user.sirName}
            </div>
            <div className="flex flex-row gap-2 items-center">
              <Badge
                style={{
                  backgroundColor: assignment.type.color,
                  color: getContrast(assignment.type.color),
                }}
              >
                {assignment.type.name}
              </Badge>

              <div className="flex flex-row gap-2 items-center">
                {products.map((product, i) => {
                  const Product = product.product;
                  if (!Product) {
                    return null;
                  }
                  return (
                    <div
                      key={product.productId + " " + i}
                      className="flex flex-row gap-1 items-center"
                    >
                      {Product.image && (
                        <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                          <img src={Product.image} className="w-3" />
                        </div>
                      )}
                      <div className="leading-[0]">{product.qty}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-2 items-center relative">
          <h4>
            {assignment.due ? dayjs(assignment.due).format("DD/MM/YYYY") : ""}
          </h4>
          <Badge
            style={{
              backgroundColor: assignment.status.color,
              color: getContrast(assignment.status.color),
            }}
          >
            {assignment.status.name}
          </Badge>
        </div>
      </div>
    </div>
  );
}
