import { useState, MouseEvent } from "react";
import { useAuthStore } from "../../../store/authStore";
import { TextInput, Label, Button, Spinner } from "flowbite-react";
// @ts-expect-error
import bcrypt from "bcryptjs";
import { PencilIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { addAlert } from "../../../store/alertStore";

const newPasswordSchema = z
  .object({
    previousPassword: z
      .string()
      .min(8, { message: "Please input your current password" }),
    password: z
      .string()
      .min(8, { message: "Please input a password longer than 8 characters" }),
    passwordConfirm: z.string(),
  })
  .refine(data => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ["passwordConfirm"],
  });

const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!, $id: Int!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { password: $password }) {
      id
    }
  }
`;

const GET_PASSWORD = gql`
  query getPassword($id: Int!) {
    users_by_pk(id: $id) {
      password
    }
  }
`;

export default function ChangePassword() {
  const { user } = useAuthStore();

  const [change_password, { error }] = useMutation(CHANGE_PASSWORD);
  if (error) {
    console.log(error);
  }

  const [get_password] = useLazyQuery(GET_PASSWORD);

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(newPasswordSchema),
    defaultValues: {
      previousPassword: "",
      password: "",
      passwordConfirm: "",
    },
  });

  const onSubmit = handleSubmit(async data => {
    setLoading(true);
    const res = await get_password({
      variables: { id: user?.id },
      fetchPolicy: "no-cache",
    });

    if (!res?.data?.users_by_pk?.password) {
      setLoading(false);
      return;
    }

    const { previousPassword } = data;

    const isMatch = await bcrypt.compare(
      previousPassword,
      res?.data?.users_by_pk?.password
    );

    if (!isMatch) {
      addAlert({
        message: "your current password does not match",
        type: "warning",
      });
      setLoading(false);
      return;
    }

    const salt = await bcrypt.genSalt(10);
    const password = await bcrypt.hash(data.password, salt);

    change_password({
      variables: { id: user?.id, password },
      onCompleted(data) {
        if (data?.update_users_by_pk) {
          addAlert({
            message: "your password has been updated successfully",
            type: "success",
          });
          setLoading(false);
          reset();
        }
      },
    });
    setEditing(state => !state);
  });

  const clickEdit = (e: MouseEvent) => {
    if (!editing) {
      e.preventDefault();
      setEditing(state => !state);
    }
  };

  const clickCancle = () => {
    setEditing(false);
    reset();
  };

  return (
    <form className="mb-1 flex-grow  mx-auto" onSubmit={onSubmit}>
      <div className="text-2xl font-bold mb-2 flex flex-row justify-between">
        Change Password
      </div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 ">
        <div className="mb-2 block mt-2">
          <Label htmlFor="previousPassword" value="Current Password" />
        </div>
        <TextInput
          type="password"
          placeholder="•••••••••••••"
          disabled={!editing}
          {...register("previousPassword")}
          color={errors.previousPassword?.message ? "failure" : undefined}
          helperText={errors.previousPassword?.message || ""}
        />

        <div className="mb-2 block mt-2">
          <Label htmlFor="password" value="New Password" />
        </div>
        <TextInput
          type="password"
          placeholder="•••••••••••••"
          disabled={!editing}
          {...register("password")}
          color={errors.password?.message ? "failure" : undefined}
          helperText={errors.password?.message || ""}
        />

        <div className="mb-2 block mt-2">
          <Label htmlFor="passwordConfirm" value="New Password Confirm" />
        </div>
        <TextInput
          type="password"
          placeholder="•••••••••••••"
          disabled={!editing}
          {...register("passwordConfirm")}
          color={errors.passwordConfirm?.message ? "failure" : undefined}
          helperText={errors.passwordConfirm?.message || ""}
        />

        <hr className="my-4 border-gray-300 dark:border-gray-500" />
        <div className="flex space-x-2 justify-end">
          {(editing || loading) && (
            <Button
              gradientDuoTone="purpleToBlue"
              size="sm"
              onClick={clickCancle}
            >
              Cancel
            </Button>
          )}

          <Button
            onClick={clickEdit}
            outline
            type="submit"
            gradientDuoTone="purpleToBlue"
            size="sm"
          >
            {!editing && !loading && <PencilIcon className="w-4 mr-2" />}
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            {editing || loading ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
    </form>
  );
}
