import { create } from "zustand";

type Store = {
  show: boolean;
  folded: boolean;
  setShow: (show: boolean) => void;
  setFolded: (folded: boolean) => void;
  containerRef: HTMLDivElement | null;
  setContainerRef: (div: HTMLDivElement) => void;
};

export const useNavStore = create<Store>(set => ({
  show: true,
  folded: false,
  setShow: (show: boolean) =>
    set(state => ({
      ...state,
      show,
    })),
  setFolded: (folded: boolean) =>
    set(state => ({
      ...state,
      folded,
    })),
  containerRef: null,
  setContainerRef(div) {
    set(state => ({
      ...state,
      containerRef: div,
    }));
  },
}));
