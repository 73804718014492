import { Outlet, useRoutes } from "react-router-dom";
import RecordsLoader from "./recordsLoader";
import RecordDetail from "./recordDetail";
import Converter from "./converter";

const RecordWrapper = () => {
  return <Outlet />;
};

export default function Records() {
  const recordRoutes = useRoutes([
    {
      path: "/",
      element: <RecordWrapper />,
      children: [
        {
          path: "/",
          element: <RecordsLoader />,
        },
        {
          path: "/detail/:id",
          element: <RecordDetail />,
        },
        {
          path: "/converter",
          element: <Converter />,
        },
      ],
    },
  ]);
  return recordRoutes;
}
