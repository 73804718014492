import { useFormContext, useWatch } from "react-hook-form";
import { TextInput } from "flowbite-react";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/solid";
import { location, openingScope, option } from "../../../types";
import { memo, useEffect, useRef, useState } from "react";
import Pelmetover from "./pelmetover";
import Trackover from "./trackover";
import Multiple from "./multiple";
import Midrail from "./midrail";
import Division from "./division";
import SelectComp from "../options/select";
import { optionValues } from "../../../../types";
import MultiSelect from "../options/multiSelect";
import MultiNameNumber from "../options/multiNameNumber";
import { useOrderStore } from "../../../../../../../store/orderStore";
import { myDeepEqual } from "../../../../../../../utils/arrayObjMethods";

interface optProps {
  locationIndex: number;
  openingIndex: number;
  index: number;
}

const OpeningOption = ({ locationIndex, openingIndex, index }: optProps) => {
  const { register, control, setValue, getValues } = useFormContext();
  const optionsCoord = `locations.${locationIndex}.openings.${openingIndex}.options`;
  const optionCoord = `${optionsCoord}.${index}`;

  const optionValue = useWatch({
    control,
    name: `${optionCoord}.value`,
  });
  const optionType = useWatch({
    control,
    name: `${optionCoord}.type`,
    exact: true,
  });
  const optionDisplayName = useWatch({
    control,
    name: `${optionCoord}.displayName`,
    exact: true,
  });
  const optionName = useWatch({
    control,
    name: `${optionCoord}.name`,
    exact: true,
  });
  const isOptional = useWatch({
    control,
    name: `${optionCoord}.optional`,
    exact: true,
  });

  const productIndex = `locations[${locationIndex}].openings[${openingIndex}].product`;

  const applyAll = () => {
    const proceed = confirm("apply option to all items?");
    if (!proceed) {
      return;
    }

    const allLocation: location[] = getValues("locations");
    const product: number = getValues(productIndex);

    setValue(
      "locations",
      allLocation.map(l => ({
        ...l,
        openings: l.openings.map(opening => ({
          ...opening,
          options: opening.options?.map(opt => {
            if (opt.name == optionName && opening.product == product) {
              return { ...opt, value: optionValue };
            } else {
              return opt;
            }
          }),
        })),
      }))
    );
  };

  const [focus, setFocus] = useState(false);
  const parentCheck = !useWatch({
    name: `${optionCoord}.noCalc`,
  });
  const showOptionals = useWatch({ name: "showOptionals", control });
  return (
    <>
      {parentCheck && optionName?.toLowerCase() !== "note" && (
        <div
          className={`relative group/option min-w-[80px]
          ${!showOptionals && isOptional && "hidden"}
      ${optionType == "division" && !optionValue && "hidden"}
       ${optionType == "param" && "hidden"}`}
          onBlur={() => {
            setFocus(false);
          }}
          onFocus={() => {
            setFocus(true);
          }}
        >
          <div
            className={`text-grass w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none ${
              optionValue == undefined && !focus
                ? "left-2 top-2"
                : "left-[50%] -translate-x-[50%] -top-[8px]"
            }`}
          >
            {optionDisplayName || optionName}
          </div>
          {optionType == "multi-select" && (
            <MultiSelect optionCoord={optionCoord} />
          )}
          {optionType == "multi-name-number" && (
            <MultiNameNumber optionCoord={optionCoord} />
          )}
          {optionType == "multiple" && (
            <Multiple optionValue={optionValue} optionCoord={optionCoord} />
          )}
          {optionType == "select" && (
            <SelectComp
              optionsCoord={optionsCoord}
              productIndex={productIndex}
              disabled={false}
              // optionValue={optionValue}
              optionName={optionName}
              optionCoord={optionCoord}
            />
          )}
          {(optionType == "number" || optionType == "text") && (
            <TextInput
              className={`${optionType == "number" ? "w-28" : "w-28"}`}
              type={optionType == "number" ? "number" : "text"}
              sizing="sm"
              {...register(`${optionCoord}.value`)}
              onKeyDown={e => {
                if (e.key == "ArrowUp" || e.key == "ArrowDown")
                  e.preventDefault();
              }}
            />
          )}
          {/* Shutter only options */}

          {optionType == "division" && (
            <Division
              optionValue={optionValue}
              optionCoord={optionCoord}
              optionsCoord={optionsCoord}
            />
          )}
          {optionType == "midrail" && (
            <Midrail
              optionValue={optionValue}
              optionCoord={optionCoord}
              optionsCoord={optionsCoord}
            />
          )}
          {optionType == "trackover" && (
            <Trackover
              optionType={optionType}
              optionValue={optionValue}
              optionsCoord={optionsCoord}
              optionCoord={optionCoord}
            />
          )}
          {optionType == "pelmetover" && (
            <Pelmetover
              optionsCoord={optionsCoord}
              register={register}
              optionCoordChained={optionCoord}
            />
          )}

          <div
            className="absolute right-1 top-1 hidden group-hover/option:block cursor-pointer hover:text-grass"
            onClick={applyAll}
          >
            <ClipboardDocumentListIcon className="w-5" />
          </div>
        </div>
      )}
      <MemoizedOptionSideEffects
        optionsCoord={optionsCoord}
        optionValue={optionValue}
        optionCoordChained={optionCoord}
      />
      <MemoizedOptionsSideEffects
        optionsCoord={optionsCoord}
        optionCoordChained={optionCoord}
        optionValue={optionValue}
      />
    </>
  );
};

interface seProps {
  optionCoordChained: string;
  optionsCoord: string;
  optionValue: any;
}

const OptionsSideEffects = ({ optionsCoord, optionCoordChained }: seProps) => {
  const { control, getValues, setValue } = useFormContext();

  const options: option[] = getValues(optionsCoord);
  const optionParents: optionValues[] = useWatch({
    control,
    name: `${optionCoordChained}.parent`,
    exact: true,
  });

  const indexes = options
    .map((o, i) => ({ index: i, id: o.id }))
    .filter(o => optionParents?.some(p => p.option == o.id))
    .map(o => `${optionsCoord}.${o.index}`);

  const formOptions =
    useWatch({
      name: indexes,
      control,
    }) || [];

  const [current, setCurrent] = useState<string>("");

  const isMounted = useRef(false);

  useEffect(() => {
    const openingCoord = optionsCoord.split(".").slice(0, -1).join(".");
    const calcs = getValues(`${openingCoord}.calcs`);
    if (
      !isMounted.current &&
      calcs &&
      Array.isArray(calcs) &&
      calcs.length > 0
    ) {
      isMounted.current = true;
      return;
    }
    const stringifiedOptions = options
      .map(o => `${o.id}::${o.value}::${o.noCalc}`)
      .join("|");
    if (stringifiedOptions == current) {
      return;
    }
    setCurrent(stringifiedOptions);

    const poCheck =
      formOptions &&
      formOptions.every(
        p =>
          optionParents?.find(po =>
            po.values.map(v => v.toString()).includes(p.value.toString())
          ) && !p.noCalc
      );
    if (
      isMounted.current &&
      poCheck == !getValues(`${optionCoordChained}.noCalc`)
    ) {
      return;
    }
    setValue(`${optionCoordChained}.noCalc`, !poCheck);

    isMounted.current = true;
  }, [JSON.stringify(formOptions)]);

  return null;
};

const MemoizedOptionsSideEffects = memo(OptionsSideEffects);

const OptionSideEffects = ({ optionCoordChained, optionValue }: seProps) => {
  const { setValue, control, getValues } = useFormContext();

  const source = useWatch({
    control,
    name: `${optionCoordChained}.source`,
    exact: true,
  });

  const { deductions } = useOrderStore();

  const [current, setCurrent] = useState<any>();

  const isMounted = useRef(false);

  const [count, setCount] = useState(0);

  useEffect(() => {
    const openingCoord = optionCoordChained.split(".").slice(0, -2).join(".");
    const calcs = getValues(`${openingCoord}.calcs`);
    if (
      !isMounted.current &&
      calcs &&
      Array.isArray(calcs) &&
      calcs.length > 0
    ) {
      isMounted.current = true;
      return;
    }

    isMounted.current = true;

    if (JSON.stringify(current) == JSON.stringify(optionValue)) {
      return;
    }
    setCurrent(optionValue);

    const currentScope = getValues(`${optionCoordChained}.scope`);

    if (!optionValue) {
      if (count == 0) {
        return setCount(state => state + 1);
      }
      if (currentScope !== undefined) {
        setValue(`${optionCoordChained}.scope`, undefined);
        // console.log("hello1", currentScope, optionValue);
      }
      return;
    }
    let scope: openingScope | undefined = undefined;

    if (source == "deductions") {
      const deduction = deductions?.find(
        d => d.id.toString() == optionValue.toString()
      );
      if (deduction) {
        scope = {
          value: deduction.name,
          width: deduction.width,
          height: deduction.height,
          additionalValue: deduction.additionalValue,
        };
      }
    } else {
      scope = optionValue;
    }

    if (!myDeepEqual(currentScope, scope as object)) {
      setValue(`${optionCoordChained}.scope`, scope);
      // console.log("hello2", currentScope, scope);
    }
  }, [optionValue]);

  return null;
};

const MemoizedOptionSideEffects = memo(OptionSideEffects);

export default memo(OpeningOption);
