import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useOrderStore } from "../../../../../store/orderStore";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Badge, ListGroup, TextInput } from "flowbite-react";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

export default function Params() {
  const { control } = useFormContext();
  const product = useWatch({
    name: "product",
    control,
  });

  const optionCode = useWatch({
    name: "optionCode",
    control,
  });

  const productId = useWatch({ name: "productId", control });

  const { materials } = useOrderStore();

  const myMaterials = materials.filter(
    mat =>
      mat.product.id == (product?.id || productId) &&
      mat.optionCode == optionCode
  );

  const existingParams = myMaterials.reduce<any[]>((prev, cur) => {
    let newParams = prev;
    if (cur.params) {
      cur.params.map(param => {
        if (newParams.find(p => p.name == param.name)) {
          newParams = newParams.map(np => {
            if (np.name == param.name) {
              return {
                ...np,
                values: [...new Set(np.values.concat(param.value))],
              };
            } else {
              return np;
            }
          });
        } else {
          newParams = newParams.concat({
            name: param.name,
            values: [param.value],
          });
        }
      });
    }

    return newParams;
  }, []);

  const params: { name: string; value: string }[] | undefined = useWatch({
    name: "params",
    control,
  });

  const { append, remove } = useFieldArray({
    name: "params",
    control,
  });

  const defaultParam = {
    name: "",
    value: "",
  };
  const [newParam, setNewParam] = useState(defaultParam);

  const add = () => {
    if (
      newParam.name == "" ||
      params?.find(p => p.name == newParam.name) ||
      newParam.value == ""
    ) {
      return;
    }
    append(newParam);
    setNewParam(defaultParam);
  };

  const [focus, setFocus] = useState<null | string>(null);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setFocus(null);
  });

  return (
    <div className="flex flex-col gap-2">
      {/* new */}
      <div className="flex flex-row gap-2 items-center" ref={ref}>
        <div className="relative">
          <TextInput
            placeholder="name"
            value={newParam.name}
            onChange={t => setNewParam({ ...newParam, name: t.target.value })}
            sizing="sm"
            onFocus={() => {
              setFocus("name");
            }}
          />
          {focus == "name" && (
            <ListGroup className="absolute w-full mt-1 z-10">
              {existingParams
                ?.filter(p => !params?.find(pa => pa.name == p.name))
                .map(p => (
                  <ListGroup.Item
                    key={p.name}
                    onClick={() => {
                      setNewParam({ ...newParam, name: p.name });
                      setFocus(null);
                    }}
                  >
                    {p.name}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          )}
        </div>
        <div className="relative flex flex-row items-center gap-2">
          <TextInput
            placeholder="value"
            value={newParam.value}
            onChange={t => setNewParam({ ...newParam, value: t.target.value })}
            sizing="sm"
            onFocus={() => {
              setFocus("value");
            }}
          />
          <PlusCircleIcon
            onClick={add}
            className="w-6 text-grass cursor-pointer"
          />
          {focus == "value" && (
            <ListGroup className="absolute w-full top-[100%] z-10 mt-1">
              {existingParams
                ?.find(p => p.name == newParam.name)
                // @ts-expect-error
                ?.values.map(v => (
                  <ListGroup.Item
                    key={v}
                    onClick={() => {
                      setNewParam({ ...newParam, value: v });
                      setFocus(null);
                    }}
                  >
                    {v}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          )}
        </div>
      </div>
      {/* added */}
      <div className="flex flex-row gap-2 flex-wrap">
        {params?.map((param, i) => (
          <Badge
            color="purple"
            key={param.name}
            onClick={() => {
              remove(i);
            }}
            className="cursor-pointer"
          >
            <div className="flex flex-row gap-1 items-center text-xs">
              {param.name}:{param.value}
              <XMarkIcon className="w-4" />
            </div>
          </Badge>
        ))}
      </div>
    </div>
  );
}
