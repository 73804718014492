import { useNavigate } from "react-router-dom";
import { fullNumber } from "../../../../utils/fullNumber";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ORDER_STATUS,
  GET_PRODUCT_SETS,
  UPDATE_ORDER_BY_PK,
} from "../gqls";
import dayjs from "dayjs";
import { Badge, ListGroup, Spinner, Tooltip } from "flowbite-react";
import {
  CakeIcon,
  HashtagIcon,
  UserIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";
import { STREAM_PRODUCTIONS } from "../../production/gql";
import checkAuth from "../../../../utils/checkAuth";
import { GET_PRODUCTIONS_BY_PID } from "../../process/processDetail/processProduction";
import { useEffect, useRef, useState } from "react";
import { cloneDeep } from "apollo-utilities";
import isNullish from "../../../../utils/isNullish";
import { OrderType } from "../types";
import getContrast from "../../../../utils/getContrast";
import { useAuthStore } from "../../../../store/authStore";
import { addAlert } from "../../../../store/alertStore";
import { useOnClickOutside } from "usehooks-ts";

interface props {
  order: OrderType;
}

export default function OrderItem({ order }: props) {
  const navigate = useNavigate();
  const goToJob = () => {
    if (!checkAuth("process_access")) {
      return;
    }
    navigate(`/process/detail/${order.process.id}/prog`);
  };
  const { process } = order;
  const fullNum =
    process &&
    fullNumber(
      process.processType.prefix,
      process.year,
      process.number,
      process.salesRepUser
    );

  const { data: product_data } = useQuery(GET_PRODUCT_SETS);
  const ProductSets = product_data?.productSets;

  const {
    data: production_data,
    subscribeToMore,
    loading,
  } = useQuery(GET_PRODUCTIONS_BY_PID, {
    variables: {
      pid: order.process.id,
    },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: STREAM_PRODUCTIONS,
      variables: {
        where: {
          processID: { _eq: order.process.id },
        },
        cursor: {
          initial_value: { updated_at: dayjs().format("YYYY-MM-DD") },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.productions);
        const newFeedItem = subscriptionData.data.productions_stream;
        const existing = previousData.filter(
          p => !newFeedItem.find(i => i.id == p.id)
        );

        return Object.assign({}, previous, {
          productions: [...newFeedItem, ...existing],
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const myProductions = production_data?.productions.filter(production =>
    order.productionIds?.includes(production.id)
  );

  const locations = order.order.locations.map((l, i) => ({
    ...l,
    index: i + 1,
    openings: l.openings.map((o, ix) => ({
      ...o,
      items: o.items.map((item, iz) => ({ ...item, index: iz + 1 })),
      index: ix + 1,
    })),
  }));

  const openings = locations?.map(l => l.openings).flat();

  const products = openings?.reduce<
    { name: string; qty: number; openingProduct: boolean; img: string }[]
  >((prev, cur) => {
    const Product = ProductSets?.find(p => p.id == cur.product);
    if (!Product) {
      return prev;
    }

    const name = Product?.name;
    const isOpeningProduct = Product.hideItems;
    const qty = isOpeningProduct ? 1 : cur.items.length;

    if (prev.find(p => p.name == name)) {
      return prev.map(pro => {
        if (pro.name == name) {
          return { ...pro, qty: pro.qty + qty };
        } else return pro;
      });
    } else {
      return prev.concat({
        name,
        qty,
        openingProduct: Product.hideItems,
        img: Product.product.image,
      });
    }
  }, []);

  const pastDue = () => {
    if (order.status.name == "completed") {
      return false;
    }
    return dayjs().isAfter(dayjs(order.due));
  };

  const [update] = useMutation(UPDATE_ORDER_BY_PK);

  const { user } = useAuthStore();

  const [editing, setEditing] = useState(false);

  const checkEditing = () => {
    const minDiff = dayjs(order.updated_at).diff(dayjs(), "minute");
    setEditing(minDiff > -2 && order.lastUpdatedUser?.id !== user?.id);
  };

  useEffect(() => {
    const interval = setInterval(() => checkEditing(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const goToDetail = () => {
    if (editing) {
      addAlert({
        message: `${order.lastUpdatedUser?.firstName} ${order.lastUpdatedUser?.sirName} is editing the workorder`,
        type: "warning",
      });
      return;
    }
    navigate(`/order/detail/${order.id}`);
  };

  const { data: data_statuses } = useQuery(GET_ORDER_STATUS);
  const statuses = data_statuses?.workorderStatus;

  const [update_order, { loading: updating }] = useMutation(UPDATE_ORDER_BY_PK);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const changeStat = (id: number) => {
    if (!order) {
      return;
    }

    update_order({
      variables: {
        id: order.id,
        set: {
          statusId: id,
        },
      },
    });
    toggleOpen();
  };

  return (
    <div className="border-[1px] shadow-sm bg-white dark:bg-gray-800 dark:border-gray-700 rounded-md flex flex-col p-3">
      <div className="flex flex-row gap-2 items-center justify-between">
        <div className="flex flex-row flex-[1] items-center gap-2">
          {/* Name, Number */}
          <div className="flex flex-col flex-[1] gap-1">
            {/* Top */}
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col flex-1 gap-1 ">
                <div
                  className="hover:text-grass cursor-pointer"
                  onClick={goToJob}
                >
                  {fullNum}
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <h3
                    className="truncate flex-1 w-0 hover:text-grass cursor-pointer"
                    onClick={goToDetail}
                  >
                    {process?.name}
                  </h3>
                </div>
              </div>
              <div className="flex flex-row items-center gap-2 p-2">
                {/* Due */}
                <div
                  className={`flex flex-col items-center justify-center p-2 ${
                    pastDue() && "text-red-500"
                  }`}
                >
                  <div>
                    {order.due
                      ? `${dayjs(order.due).format("DD")} / ${dayjs(
                          order.due
                        ).format("MMM")}`
                      : ""}
                  </div>
                </div>
                {/* Status */}
                <div className="relative" ref={ref}>
                  <Badge
                    style={{
                      backgroundColor: order.status.color,
                      color: getContrast(order.status.color),
                    }}
                    size="md"
                    className="cursor-pointer"
                    onClick={toggleOpen}
                  >
                    {order.status.name}
                  </Badge>
                  {open && (
                    <ListGroup className="absolute right-0 z-10 mt-2">
                      {statuses
                        ?.filter(stat => stat.id !== order.status?.id)
                        .map(stat => (
                          <ListGroup.Item
                            onClick={() => {
                              changeStat(stat.id);
                            }}
                            key={stat.id}
                          >
                            <Badge
                              style={{
                                backgroundColor: stat.color,
                                color: getContrast(stat.color),
                              }}
                            >
                              {stat.name}
                            </Badge>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  )}
                </div>
              </div>
            </div>
            {/* Bottom */}
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-2">
                <Badge
                  style={{
                    backgroundColor: order.type.color,
                    color: getContrast(order.type.color),
                  }}
                >
                  {order.type.name}
                </Badge>
                {/* createdBy */}
                <Tooltip
                  content={`${order.user?.firstName} ${order.user?.sirName}`}
                  placement="bottom"
                >
                  <div className="flex flex-row gap-2 items-center">
                    <UserIcon className="w-4" />
                    <span className="capitalize">
                      {order.user?.firstName} {order.user?.sirName[0]}
                    </span>
                  </div>
                </Tooltip>
                {/* team */}
                {/* <div className="flex flex-row gap-2 items-center">
                  <BuildingStorefrontIcon className="w-4" />
                  {order.process.createdUser?.team?.name}
                </div> */}
                {/* Desc */}
                {/* {order.description && (
                  <div className="hidden @md:flex supports-[not(container-type:inline-size)]:md:flex flex-row gap-1 items-center">
                    <ChatBubbleLeftEllipsisIcon className="w-4" />
                    <div className="max-w-[400px] truncate">
                      {order.description}
                    </div>
                  </div>
                )} */}
                {/* orderRef */}
                {!isNullish(order.orderRef) && (
                  <div className="hidden @sm:flex supports-[not(container-type:inline-size)]:sm:flex flex-row gap-1 items-center">
                    <HashtagIcon className="w-4" />
                    <div>{order.orderRef}</div>
                  </div>
                )}

                {/* from */}
                <div className="hidden @sm:flex supports-[not(container-type:inline-size)]:sm:flex flex-row gap-1 items-center">
                  <CakeIcon className="w-4" />
                  <div>{dayjs(order.created_at).format("DD/MM/YY")}</div>
                </div>
                {/* products */}
                <div className="flex flex-row gap-2 items-center">
                  {products &&
                    products.length > 0 &&
                    products.map((product, i) => {
                      return (
                        <div
                          key={i}
                          className="flex flex-row items-center gap-1"
                        >
                          {product.img && (
                            <img
                              src={product.img}
                              className="w-5 dark:invert"
                            />
                          )}

                          <h4>{product.qty}</h4>
                        </div>
                      );
                    })}
                </div>
                {editing && (
                  <WrenchScrewdriverIcon className="w-4 text-yellow-400" />
                )}
              </div>

              <div className="flex flex-row gap-4">
                {/* production */}
                {checkAuth(["production_view_all", "production_view_mine"]) && (
                  <div className="flex flex-row gap-2 items-center">
                    {myProductions &&
                      myProductions.length > 0 &&
                      myProductions?.map(production => {
                        return (
                          <Badge
                            className="cursor-pointer group"
                            key={production.id}
                            style={{
                              backgroundColor:
                                production.productionStatus.color,
                            }}
                          >
                            <div className="flex flex-row items-center gap-2">
                              <span>
                                {production.product.name} -{" "}
                                {production.productionStatus.name}
                              </span>
                            </div>
                          </Badge>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
