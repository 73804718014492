import { Badge, Button, ListGroup, Spinner } from "flowbite-react";
import { processDetailSubProps } from "../types";
import { GET_TAGS } from "../../settings/process/tags/tags";
import { useQuery } from "@apollo/client";
import { TagType } from "../../settings/process/tags/types";
import { useCallback, useEffect, useState } from "react";
import { UPDATE_PROCESS_BY_PK } from "./gqls";
import { useMutation } from "@apollo/client";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { coerceNumber, ProcessHistory } from "../types";
import { INSERT_TAG } from "../../settings/process/tags/addTag";
import { TAGS_CORE_FIELDS } from "../../settings/process/tags/fragments";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";

const resolver = z.object({
  tags: z.array(coerceNumber),
});

interface props extends processDetailSubProps {
  page: string;
}

export default function ProcessTags({ process, baseHistory, page }: props) {
  const { data: data_tags } = useQuery(GET_TAGS);
  const [update_process, { loading }] = useMutation(UPDATE_PROCESS_BY_PK);
  const [insert_tag, { loading: inserting_tag }] = useMutation(INSERT_TAG);
  const [editing, setEditing] = useState(false);
  const [newTag, setNewTag] = useState("");

  const defaultValues = useCallback(
    () => ({ tags: process.tags || [] }),
    [process]
  );

  const { reset, handleSubmit, setValue, watch } = useForm({
    resolver: zodResolver(resolver),
    defaultValues: defaultValues(),
  });

  useEffect(() => {
    reset(defaultValues());
  }, [process]);

  const allTags = data_tags?.tags;

  if (!allTags) {
    return null;
  }

  const formTags = watch("tags");

  const tags: TagType[] = allTags.filter(t => formTags?.includes(t.id));

  const appendTag = (id: number) => {
    setValue("tags", formTags.concat(id));
    setNewTag("");
  };

  const removeTag = (id: number) => {
    setValue(
      "tags",
      formTags.filter(tag => tag !== id)
    );
  };

  const onSubmit = handleSubmit(async data => {
    const { tags } = data;
    const history: ProcessHistory = {
      ...baseHistory,
      message: `tags updated`,
      type: "detail",
    };

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(history)
      : [history];

    update_process({
      variables: {
        id: process.id,
        set: {
          tags,
          histories,
        },
      },
      onCompleted() {
        reset({ tags });
        toggleEditing();
      },
    });
  });

  const addTag = () => {
    insert_tag({
      variables: {
        name: newTag,
      },
      update(cache, { data: { insert_tags_one: newTag } }) {
        cache.modify({
          fields: {
            tags(existingTags = []) {
              const newTagRef = cache.writeFragment({
                data: newTag,
                fragment: TAGS_CORE_FIELDS,
              });
              return [...existingTags, newTagRef];
            },
          },
        });
      },
      onCompleted(data) {
        const tag = data.insert_tags_one;
        setNewTag("");
        setValue("tags", formTags.concat(tag.id));
      },
    });
  };

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const cancel = () => {
    setEditing(false);
    setNewTag("");
    reset();
  };

  const tagCandidates = allTags?.filter(
    t => !formTags?.includes(t.id) && t.name.includes(newTag) && newTag !== ""
  );

  return (
    <form
      onSubmit={onSubmit}
      onClick={() => {
        if (editing == true) {
          return;
        }
        setEditing(true);
      }}
      className={`${
        page !== "info" ? "hidden" : "flex"
      } z-20  flex-grow min-w-[80%] flex-row relative justify-between p-1 gap-2 ${
        editing ? "dark:bg-gray-700 bg-gray-200" : ""
      } hover:dark:bg-gray-700 hover:bg-gray-200 rounded-md cursor-pointer`}
    >
      <div className="flex-1 flex flex-row gap-2 items-center">
        {tags.length == 0 && (
          <Badge color="purple">
            <div className="flex flex-row items-center gap-1 w-max">
              <PlusIcon className="w-4" />
              add tags
            </div>
          </Badge>
        )}
        {tags.map(tag => (
          <Badge
            color="success"
            key={tag.id}
            onClick={() => {
              removeTag(tag.id);
            }}
          >
            <div className="flex flex-row items-center gap-1">
              {tag.name} {editing && <XMarkIcon className="w-3" />}
            </div>
          </Badge>
        ))}
        {editing && (
          <input
            onChange={e => {
              setNewTag(e.target.value.replaceAll(" ", ""));
            }}
            value={newTag}
            autoFocus
            className="bg-transparent outline-none border-none w-full"
          />
        )}
      </div>
      {editing && (
        <>
          <div className="flex flex-row items-center gap-2">
            <Button size="xs" color="gray" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              gradientDuoTone="purpleToBlue"
              type="submit"
              outline
            >
              {loading && <Spinner size="xs" light className="mr-1" />}
              update
            </Button>
          </div>
          <ListGroup className="absolute top-[100%] mt-2 w-max left-0">
            {tagCandidates.map(tag => (
              <ListGroup.Item
                key={tag.id}
                onClick={() => {
                  appendTag(tag.id);
                }}
              >
                <Badge color="success">{tag.name}</Badge>
              </ListGroup.Item>
            ))}
            {newTag &&
              newTag.trim() !== "" &&
              !allTags.find(t => t.name == newTag) && (
                <ListGroup.Item onClick={addTag}>
                  <div className="items-center flex flex-row gap-2">
                    add <Badge color="success">{newTag}</Badge>{" "}
                    {inserting_tag && (
                      <Spinner size="xs" light className="mr-1" />
                    )}
                  </div>
                </ListGroup.Item>
              )}
          </ListGroup>
        </>
      )}
    </form>
  );
}
