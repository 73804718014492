import { create } from "zustand";
import { useAuthStore } from "./authStore";
import checkAuth from "../utils/checkAuth";
import { useQuery } from "@apollo/client";
import { GET_PROCESS_RELATED_CONFIGS } from "../routes/protected/process/addProcess/addProcess";
import { RELATED_CONFIGS } from "../routes/protected/process/types";
import { useCallback, useEffect, useState } from "react";
import { haveMatches } from "../utils/checkAuth";
import { processQueryParams } from "../routes/protected/process/filter/types";
import { put } from "../utils/arrayObjMethods";
import { ProcessFilter } from "../routes/protected/process/filter/types";
import dayjs from "dayjs";

export type processViewType = "board" | "list" | "table";

type Store = {
  filters: ProcessFilter[];
  setFilters: (filters: ProcessFilter[]) => void;
  view: processViewType;
  changeView: () => void;
  setView: (view: processViewType) => void;
  cursor: string;
  setCursor: (str: string) => void;
};

export const useProcessStore = create<Store>(set => ({
  view: "board",
  changeView: () => {
    const views = ["board", "list", "table"];
    return set(state => {
      const nextView =
        views[views.findIndex(v => v == state.view) + 1] || "board";
      localStorage.setItem("processView", nextView);
      return {
        ...state,
        view: nextView as processViewType,
      };
    });
  },
  setView: (view: processViewType) => {
    return set(state => ({
      ...state,
      view,
    }));
  },
  filters: [],
  setFilters: filters => {
    if (filters.length == 0) {
      localStorage.removeItem("processFilters");
    }
    localStorage.setItem("processFilters", JSON.stringify(filters));
    return set(state => ({
      ...state,
      filters,
    }));
  },
  cursor: new Date().toISOString(),
  setCursor(cursor: string) {
    return set(state => ({
      ...state,
      cursor,
    }));
  },
}));

export const useDefaultFilters = () => {
  const [configs, setConfigs] = useState<undefined | RELATED_CONFIGS>();
  const [loading, setLoading] = useState(true);
  const { user } = useAuthStore();

  useQuery(GET_PROCESS_RELATED_CONFIGS, {
    onCompleted(data) {
      setConfigs(data);
    },
  });

  const { setFilters } = useProcessStore();

  const savedFilters = localStorage.getItem("processFilters");

  useEffect(() => {
    if (savedFilters && savedFilters.length > 0) {
      setFilters(JSON.parse(savedFilters));
      setLoading(false);
      return;
    }
    if (configs && user) {
      setLoading(true);
      const {
        processStatus,
        processType,
        products,
        sources,
        tags,
        teams,
        users,
        permissions,
        paymentStatus,
      } = configs;

      const defaultFilters: ProcessFilter[] = [];

      // Types
      const teamProcess = user?.team?.processTypes || [];

      const types = processType.filter(pt =>
        checkAuth([
          "add_process_any_type",
          {
            permission: "process_access_self",
            checkGroup: teamProcess || [],
            conditionGroup: [pt.id],
          },
          {
            permission: "process_access_team",
            checkGroup: teamProcess || [],
            conditionGroup: [pt.id],
          },
        ])
      );

      const options_type = types.map(t => ({
        name: t.name,
        value: t.id,
      }));

      const defaultValues_type = types.map(t => t.id);

      const typeFilter: ProcessFilter = {
        name: "Type",
        where: "typeId",
        type: "in",
        values: defaultValues_type,
        defaultValues: defaultValues_type,
        options: options_type,
        on: true,
        defaultOn: true,
        required: true,
        sort: {
          where: "processType.name",
          dir: "asc",
          priority: 2,
        },
        defaultSort: {
          where: "processType.name",
          dir: "asc",
          priority: 2,
        },
      };
      defaultFilters.push(typeFilter);

      // Year
      const years = [];

      for (let i = 2014; i <= new Date().getFullYear() + 1; i++) {
        years.push(i);
      }

      const options_year = years.map(y => ({
        name: y.toString(),
        value: y,
      }));

      const yearFilter: ProcessFilter = {
        name: "Year",
        where: "year",
        type: "in",
        values: years.slice(-3),
        defaultValues: years.slice(-3),
        options: options_year,
        on: true,
        defaultOn: true,
        required: true,
        sort: {
          dir: "desc",
          priority: 1,
          where: "year",
        },
        defaultSort: {
          dir: "desc",
          priority: 1,
          where: "year",
        },
      };

      defaultFilters.push(yearFilter);

      // Status
      const options_status = processStatus.map(s => ({
        name: s.name,
        value: s.id,
      }));

      const defaultValues_status = processStatus.map(s => s.id);

      const statusFilter: ProcessFilter = {
        name: "Status",
        where: "status",
        type: "in",
        values: defaultValues_status,
        defaultValues: defaultValues_status,
        options: options_status,
        on: true,
        defaultOn: true,
        required: true,
        depends: "typeId",
        sort: {
          dir: "asc",
          priority: 3,
          where: "processStatus.priority",
        },
        defaultSort: {
          dir: "asc",
          priority: 3,
          where: "processStatus.priority",
        },
        canShowAll: true,
      };

      defaultFilters.push(statusFilter);

      const salesPermissionId =
        permissions.find(p => p.name == "can_sales")?.id || -1;

      // Sales
      const options_sales = users
        .filter(u => {
          if (user.auth?.name == "sales") {
            return u.id == user.id;
          }
          const permissions: number[] = [];
          if (u.auth?.permissions) {
            permissions.push(...u.auth.permissions);
          }
          if (u.permissions) {
            permissions.push(...u.permissions);
          }
          return u.active && permissions.includes(salesPermissionId);
        })
        .map(u => ({
          name: u.firstName + " " + u.sirName,
          value: u.id,
        }));

      const defaultValues_sales = options_sales.map(o => o.value);

      const salesFilter: ProcessFilter = {
        name: "Sales",
        where: "salesRep",
        type: "in",
        values: defaultValues_sales,
        defaultValues: defaultValues_sales,
        options: options_sales,
        depends: "typeId",
        on: false,
        defaultOn: false,
        required: user.auth?.name == "sales",
        sort: {
          dir: "asc",
          priority: 4,
          where: "salesRepUser.firstName",
        },
        defaultSort: {
          dir: "asc",
          priority: 4,
          where: "salesRepUser.firstName",
        },
      };

      defaultFilters.push(salesFilter);

      // Created At
      const createdAt: ProcessFilter = {
        name: "Created",
        where: "created_at",
        type: "range",
        rangeType: "date",
        values: {
          from: undefined,
          to: undefined,
        },
        defaultValues: {
          from: undefined,
          to: undefined,
        },
        on: false,
        defaultOn: false,
        required: false,
      };

      defaultFilters.push(createdAt);

      // From
      const from: ProcessFilter = {
        name: "From",
        where: "from",
        type: "range",
        rangeType: "date",
        values: {
          from: undefined,
          to: undefined,
        },
        defaultValues: {
          from: undefined,
          to: undefined,
        },
        on: false,
        defaultOn: false,
        required: false,
      };

      defaultFilters.push(from);

      // Due
      const due: ProcessFilter = {
        name: "Due",
        where: "due",
        type: "range",
        rangeType: "date",
        values: {
          from: undefined,
          to: undefined,
        },
        defaultValues: {
          from: undefined,
          to: undefined,
        },
        on: false,
        defaultOn: false,
        required: false,
      };

      defaultFilters.push(due);

      // value
      const value: ProcessFilter = {
        name: "Value",
        where: "value",
        type: "range",
        rangeType: "number",
        values: {
          from: undefined,
          to: undefined,
        },
        defaultValues: {
          from: undefined,
          to: undefined,
        },
        on: false,
        defaultOn: false,
        required: false,
      };

      defaultFilters.push(value);

      // sources
      const options_sources = sources.map(p => ({
        name: p.name,
        value: p.id,
      }));

      const sourceFilter: ProcessFilter = {
        name: "Source",
        where: "sources",
        type: "contains",
        values: [],
        defaultValues: [],
        options: options_sources,
        on: false,
        defaultOn: false,
        required: false,
      };

      defaultFilters.push(sourceFilter);

      // tags
      const options_tags = tags.map(p => ({
        name: p.name,
        value: p.id,
      }));

      const tagsFilter: ProcessFilter = {
        name: "Tags",
        where: "tags",
        type: "contains",
        values: [],
        defaultValues: [],
        options: options_tags,
        on: false,
        defaultOn: false,
        required: false,
      };

      defaultFilters.push(tagsFilter);

      // product
      const options_products = products.map(p => ({
        name: p.name,
        value: { productId: p.id },
      }));

      const productFilter: ProcessFilter = {
        name: "Product",
        where: "products",
        type: "contains",
        values: [],
        defaultValues: [],
        options: options_products,
        on: false,
        defaultOn: false,
        required: false,
      };

      defaultFilters.push(productFilter);

      // payStatus
      const options_paymentStatus = paymentStatus.map(p => ({
        name: p.name,
        value: p.id,
      }));

      const paymentStatusFilter: ProcessFilter = {
        name: "Payment",
        where: "payStatus",
        type: "in",
        values: [],
        defaultValues: [],
        options: options_paymentStatus,
        on: false,
        defaultOn: false,
        required: false,
      };

      defaultFilters.push(paymentStatusFilter);

      setFilters(defaultFilters);

      setLoading(false);
    }
  }, [configs, user]);
};

export const refineFilters: (filters: ProcessFilter[]) => processQueryParams = (
  filters: ProcessFilter[]
) => {
  if (!filters) {
    return {
      where: { _and: [] },
      order_by: {},
      offset: 0,
    };
  }

  const where: any = { _and: [] };
  const order_by: object[] = [];

  for (const filter of filters.sort((a, b) =>
    (a.sort?.priority || 0) > (b.sort?.priority || 0) ? 1 : -1
  )) {
    // Filter
    if (filter.on || filter.required) {
      switch (filter.type) {
        case "in":
          where._and.push({ [filter.where]: { _in: filter.values } });
          break;
        case "contains":
          where._and.push({
            _or: [
              { [filter.where]: { _contains: filter.values } },
              { [filter.where]: { _contained_in: filter.values } },
            ],
          });
          break;
        case "range":
          const { from, to } = filter.values;
          if (from && to) {
            where._and.push({
              [filter.where]: {
                _gte: dayjs(filter.values.from)
                  .subtract(1, "day")
                  .format("YYYY-MM-DD"),
                _lte: filter.values.to,
              },
            });
          }
          break;
      }
    }

    // Sort
    if (filter.sort) {
      const sortObj = {};
      put(sortObj, filter.sort.where, filter.sort.dir);
      order_by.push(sortObj);
    }
  }

  // @ts-expect-error
  if (!where._and?.find(w => w.deleted)) {
    where._and.push({ deleted: { _eq: false } });
  }

  return {
    where: where,
    order_by: order_by,
    offset: 0,
  };
};

export const useOptionValidator = (
  where: string,
  filters: ProcessFilter[],
  depends?: string
) => {
  const [configs, setConfigs] = useState<undefined | RELATED_CONFIGS>();

  useQuery(GET_PROCESS_RELATED_CONFIGS, {
    onCompleted(data) {
      setConfigs(data);
    },
  });

  const dependedOnFilter = filters.find(f => f.where == depends);

  const validator = useCallback(
    (value: any) => {
      if (!dependedOnFilter || !configs) {
        return true;
      }

      switch (where) {
        case "status":
          const statusID = value;
          const typeFilter = filters.find(filter => filter.where == "typeId");
          if (!typeFilter) {
            return true;
          }
          const selectedTypes = configs.processType.filter(stat =>
            typeFilter.values.includes(stat.id)
          );
          const preferredStats = selectedTypes.reduce<number[]>((prv, cur) => {
            const statuses = cur.statuses;
            if (!statuses) {
              return prv;
            }
            return [...new Set(prv.concat(...statuses))];
          }, []);

          return preferredStats.includes(statusID);
        case "salesRep":
          const _typeFilter = filters.find(f => f.name == "Type");
          if (!_typeFilter) {
            return true;
          }
          const _selectedTypes = _typeFilter.values;
          const validTeams = configs.teams
            .filter(team =>
              haveMatches(team.processTypes || [], _selectedTypes)
            )
            .map(t => t.id);

          const user = configs.users.find(u => u.id == value);

          return validTeams.includes(user?.team?.id || -1);

        default:
          return true;
      }
    },
    [dependedOnFilter, configs]
  );

  return validator;
};
