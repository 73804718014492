import { sort } from "fast-sort";
import { vacancyType } from "../../../../../utils/calendarMethods";
import { diffDateTime } from "../../../../../utils/dateMethods";
import getContrast from "../../../../../utils/getContrast";

interface props {
  vacancy: vacancyType;
  vacancies: vacancyType[];
  index: number;
  setVacancy: (vacancy?: vacancyType) => void;
  selectedVacancy?: vacancyType;
}

export default function VacancyItem({
  vacancy,
  vacancies,
  index,
  setVacancy,
  selectedVacancy,
}: props) {
  const start = new Date(vacancy.start || "");
  const end = new Date(vacancy.end || "");
  const yPosition = (start.getHours() + start.getMinutes() / 60) * 40;

  const diff = diffDateTime(start, end);

  const overlapVacancies = vacancies
    .filter(s => {
      if (vacancy.id == s.id) {
        return false;
      }
      const sStart = new Date(s.start || "");
      const sEnd = new Date(s.end || "");
      if (
        sStart.getTime() == start.getTime() ||
        (sStart.getTime() < end.getTime() && sStart.getTime() > start.getTime())
      ) {
        return true;
      }
      if (sEnd.getTime() < end.getTime() && sEnd.getTime() > start.getTime()) {
        return true;
      }

      return false;
    })
    .map(os => {
      const i = vacancies.findIndex(s => s.id == os.id);
      return {
        ...os,
        index: i,
      };
    });

  const overlapIndex: number = sort([
    ...overlapVacancies.map(to => to.index),
    index,
  ])
    .asc()
    .findIndex(i => i == index);

  const totalOverlap = overlapVacancies.filter(s => {
    const sStart = new Date(s.start || "");
    const sEnd = new Date(s.end || "");

    if (
      sStart.getTime() == start.getTime() &&
      sEnd.getTime() == end.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  });
  const totalOverlapIndex: number = sort([
    ...totalOverlap.map(to => to.index),
    index,
  ])
    .asc()
    .findIndex(i => i == index);

  let width =
    totalOverlap.length > 0
      ? `${(1 / (totalOverlap.length + 1)) * 100}%`
      : overlapVacancies.length > 0 && overlapIndex == 1
      ? "80%"
      : "100%";

  const left =
    totalOverlap.length > 0
      ? `${(1 / (totalOverlap.length + 1)) * 100 * totalOverlapIndex}%`
      : overlapVacancies.length > 0 && overlapIndex == 1
      ? "20%"
      : 0;

  const opacity =
    overlapVacancies.length > 0 && totalOverlap.length < 1 && overlapIndex == 1
      ? 0.8
      : 1;

  const selected = selectedVacancy?.id == vacancy.id;

  return (
    <div
      onClick={() => {
        if (selected) {
          setVacancy(undefined);
        } else {
          setVacancy(vacancy);
        }
      }}
      className="absolute p-1 justify-center"
      style={{
        top: yPosition,
        left,
        height: `${(diff / 60) * 40}px`,
        width,
        opacity,
      }}
    >
      <div
        className={`w-full h-full p-2 rounded-md ring-plum ${
          selected ? "ring-2" : "ring-1"
        } dark:ring-gray-600 cursor-pointer flex flex-col justify-between ${
          selected ? "animate-pulse" : ""
        }`}
        style={{
          backgroundColor: vacancy.user?.color,
          color: getContrast(vacancy.user?.color || "#CCCCCC"),
        }}
      >
        <span className="truncate overflow-hidden text-xs font-semibold">
          {vacancy.user.firstName}
          🕓{vacancy.efficiency ? (vacancy.efficiency * 60).toFixed() : 0}m
        </span>
      </div>
    </div>
  );
}
