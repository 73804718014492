import { useQuery, useMutation, gql } from "@apollo/client";
import { Badge, Button, Spinner, TextInput, Textarea } from "flowbite-react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { GET_PERMISSIONS } from "../permissions/permissions";
import { authSchema, NewAuthType } from "./types";
import { AUTH_CORE_FIELDS } from "./fragments";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";

export const INSERT_AUTH = gql`
  ${AUTH_CORE_FIELDS}
  mutation INSERT_AUTH(
    $name: String!
    $description: String!
    $permissions: jsonb
  ) {
    insert_auths_one(
      object: {
        name: $name
        description: $description
        permissions: $permissions
      }
    ) {
      ...AuthCoreFields
    }
  }
`;

export default function AddRole({
  toggleAdding,
}: {
  toggleAdding: () => void;
}) {
  const [insert_auth, { loading, error }] = useMutation(INSERT_AUTH);
  if (error) {
    console.log(error);

    addAlert({
      message: "something went wrong cannot add role",
      type: "failure",
    });
  }

  const {
    data,
    error: permissionsError,
    loading: permissionsLoading,
  } = useQuery(GET_PERMISSIONS);

  if (permissionsError) {
    console.log();
  }

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<NewAuthType>({
    resolver: zodResolver(authSchema),
    defaultValues: {
      name: "",
      description: "",
      permissions: [],
    },
  });

  const onSubmit = handleSubmit(async data => {
    insert_auth({
      variables: { ...data, name: data.name.toLowerCase().replaceAll(" ", "") },
      update(cache, { data: { insert_auths_one: newAuth } }) {
        cache.modify({
          fields: {
            auths(existingAuths = []) {
              const newAuthRef = cache.writeFragment({
                data: newAuth,
                fragment: AUTH_CORE_FIELDS,
              });
              return [...existingAuths, newAuthRef];
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          message:
            "New Role successfully added, please bear in mind that the role has to be configured in the db to take effect",
          type: "success",
          persist: true,
        });
        toggleAdding();
      },
    });
  });

  const cancel = () => {
    reset();
    toggleAdding();
  };

  const deletePermission = (id: number) => {
    const permissions = getValues("permissions")?.filter(p => p !== id);
    setValue("permissions", permissions);
  };

  const addPermission = (id: number) => {
    const permissions: number[] = getValues("permissions")?.concat(id);
    setValue("permissions", permissions);
  };

  const permissions = watch("permissions");

  const excludedPermissions =
    Array.isArray(permissions) &&
    data?.permissions?.filter(p => !permissions?.includes(p.id));

  return (
    <form
      onSubmit={onSubmit}
      className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 mb-5"
    >
      <TextInput
        type="text"
        placeholder="Enter the name for new role no space allowed"
        {...register("name")}
        color={errors.name?.message ? "failure" : undefined}
        helperText={errors.name?.message || ""}
      />

      <Textarea
        {...register("description")}
        placeholder="Describe the general access level of the role"
        color={errors.description?.message ? "failure" : undefined}
        helperText={errors.description?.message || ""}
        className="mt-2 mb-4 text-sm"
      />

      <div className="flex flex-row flex-wrap gap-2 mb-2">
        {permissions && data?.permissions ? (
          permissions.map(ap => {
            const permission = data.permissions.find(p => p.id == ap);
            if (permission) {
              return (
                <div key={ap} className="relative">
                  <Badge color="purple">{permission.name}</Badge>

                  <MinusCircleIcon
                    onClick={() => {
                      deletePermission(permission.id);
                    }}
                    className="w-5 cursor-pointer text-red-500 absolute top-[-8px] right-[-8px]"
                  />
                </div>
              );
            }
          })
        ) : (
          <>
            {permissionsLoading ? (
              <Spinner />
            ) : (
              <Badge>no innate permissions</Badge>
            )}
          </>
        )}
      </div>

      <>
        {excludedPermissions && excludedPermissions.length > 0 && (
          <div className="flex flex-row flex-wrap gap-2 p-3 mb-2 rounded-md border-[1px] border-gray-300 dark:border-gray-500">
            {excludedPermissions.map(permission => (
              <div key={permission.id} className="relative">
                <Badge>{permission.name}</Badge>

                <PlusCircleIcon
                  onClick={() => {
                    addPermission(permission.id);
                  }}
                  className="w-5 cursor-pointer text-green-500 absolute top-[-8px] right-[-8px]"
                />
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-row justify-end gap-2">
          <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
            Cancel
          </Button>
          <Button
            gradientDuoTone="purpleToBlue"
            outline
            size="sm"
            type="submit"
          >
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Add
          </Button>
        </div>
      </>
    </form>
  );
}
