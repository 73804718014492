import { create } from "zustand";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { RecordFilter } from "../routes/protected/records/types";
import { queryParams } from "../routes/protected/shared/types";
import dayjs from "dayjs";
import { useAuthStore } from "./authStore";
import checkAuth from "../utils/checkAuth";
import { GET_TEAMS } from "../routes/protected/settings/teams/teamList";
import { TeamType } from "../routes/protected/settings/teams/types";
import { ProductType } from "../routes/protected/settings/products/types";
import { GET_PRODUCTS } from "../routes/protected/settings/products/products";
import { ProductionStatusType } from "../routes/protected/settings/production/productionStatuses/types";
import { GET_PRODUCTION_STATUSES_NO_PID } from "../routes/protected/settings/production/productionStatuses/productionStatuses";
import { processViewType } from "./processStore";

type Store = {
  view: processViewType;
  changeView: () => void;
  setView: (view: processViewType) => void;
  filters: RecordFilter[];
  setFilters: (filters: RecordFilter[]) => void;
  cursor: string;
  setCursor: (str: string) => void;
};

export const useProductionStore = create<Store>(set => ({
  view: "list",
  changeView: () => {
    const views = ["board", "list"];
    return set(state => {
      const nextView =
        views[views.findIndex(v => v == state.view) + 1] || "board";
      localStorage.setItem("productionView", nextView);
      return {
        ...state,
        view: nextView as processViewType,
      };
    });
  },
  setView: (view: processViewType) => {
    return set(state => ({
      ...state,
      view,
    }));
  },
  filters: [],
  setFilters: filters => {
    if (filters.length == 0) {
      localStorage.removeItem("productionFilters");
    }
    localStorage.setItem("productionFilters", JSON.stringify(filters));
    return set(state => ({
      ...state,
      filters,
    }));
  },
  cursor: new Date().toISOString(),
  setCursor(cursor: string) {
    return set(state => ({
      ...state,
      cursor,
    }));
  },
}));

export const useDefaultFilters = () => {
  const { user } = useAuthStore();
  const [teams, setTeams] = useState<undefined | TeamType[]>(undefined);
  useQuery(GET_TEAMS, {
    onCompleted(data) {
      setTeams(data.teams);
    },
  });

  const [products, setProducts] = useState<undefined | ProductType[]>(
    undefined
  );
  useQuery(GET_PRODUCTS, {
    onCompleted(data) {
      setProducts(data.products);
    },
  });

  const [statuses, setStatuses] = useState<undefined | ProductionStatusType[]>(
    undefined
  );
  useQuery(GET_PRODUCTION_STATUSES_NO_PID, {
    onCompleted(data) {
      setStatuses(data.productionStatus);
    },
  });

  const { setFilters } = useProductionStore();

  const savedFilters = localStorage.getItem("productionFilters");

  useEffect(() => {
    if (savedFilters && savedFilters.length > 0) {
      setFilters(JSON.parse(savedFilters));
      return;
    }
    if (!teams || !statuses || !products) {
      return;
    }

    const defaultFilters: RecordFilter[] = [];

    const filteredTeams = teams.filter(
      team =>
        checkAuth([
          "production_view_all",
          {
            permission: "production_view_mine",
            checkGroup: "userTeam",
            conditionGroup: [team.id],
          },
        ]) && team.type?.name == "manufacture"
    );

    // Types
    const options_teams = filteredTeams?.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_teams = filteredTeams?.map(t => t.id);

    const teamFilter: RecordFilter = {
      name: "Team",
      where: "teamID",
      type: "in",
      values: defaultValues_teams,
      defaultValues: defaultValues_teams,
      options: options_teams,
    };

    defaultFilters.push(teamFilter);

    // Status
    const options_status = statuses?.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_status = statuses
      ?.filter(stat => stat.name !== "closed" && stat.name !== "ready")
      .map(t => t.id);

    const statusFilter: RecordFilter = {
      name: "Status",
      where: "statusID",
      type: "in",
      values: defaultValues_status,
      defaultValues: defaultValues_status,
      options: options_status,
    };

    defaultFilters.push(statusFilter);

    const teamProducts = filteredTeams.reduce<number[]>((prv, cur) => {
      const products = cur.products || [];
      return prv.concat(products);
    }, []);

    const filteredProducts = products?.filter(p => teamProducts.includes(p.id));
    // Product
    const options_products = filteredProducts?.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_products = filteredProducts?.map(t => t.id);

    const productFilter: RecordFilter = {
      name: "Product",
      where: "productID",
      type: "in",
      values: defaultValues_products,
      defaultValues: defaultValues_products,
      options: options_products,
    };

    defaultFilters.push(productFilter);

    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    const lastDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );

    const rangeFilter: RecordFilter = {
      name: "Range",
      where: "created_at",
      type: "range",
      rangeType: "date",
      values: {
        from: dayjs(firstDay).format("YYYY-MM-DD"),
        to: dayjs(lastDay).format("YYYY-MM-DD"),
      },
      defaultValues: {
        from: dayjs(firstDay).format("YYYY-MM-DD"),
        to: dayjs(lastDay).format("YYYY-MM-DD"),
      },
    };
    defaultFilters.push(rangeFilter);

    setFilters(defaultFilters);
  }, [teams, statuses, products]);
};

export const refineFilters: (filters: RecordFilter[]) => queryParams = (
  filters: RecordFilter[]
) => {
  if (!filters) {
    return {
      where: { _and: [] },
      offset: 0,
    };
  }

  const where: any = { _and: [] };
  const order_by: object[] = [];

  for (const filter of filters) {
    // Filter
    switch (filter.type) {
      case "in":
        where._and.push({ [filter.where]: { _in: filter.values } });
        break;
      case "contains":
        where._and.push({
          _or: [
            { [filter.where]: { _contains: filter.values } },
            { [filter.where]: { _contained_in: filter.values } },
          ],
        });
        break;
      case "range":
        const { from, to } = filter.values;
        if (from && to) {
          where._and.push({
            [filter.where]: {
              _gte: dayjs(filter.values.from)
                .subtract(1, "day")
                .format("YYYY-MM-DD"),
              _lte: filter.values.to,
            },
          });
        }
        break;
    }
  }

  return {
    where: where,
    order_by: order_by,
    offset: 0,
  };
};
