import { useEffect, useState } from "react";
import totalH from "./totalHCalc";

interface props {
  startTime: string;
  brk?: number;
  endTime?: string;
}

export default function TotalH({ startTime, endTime, brk }: props) {
  const [date, setDate] = useState(new Date());
  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return <>{totalH({ startTime, endTime, date, brk })}</>;
}
