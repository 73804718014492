import { processDetailSubProps, ProcessHistory } from "../types";
import AddPayment from "../../shared/paymentLogs/addPayment";
import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import { PaymentArrayType } from "../../shared/paymentLogs/types";
import { PAYMENT_LOGS_CORE_FIELDS } from "../../shared/paymentLogs/fragments";
import PaymentLog from "../../shared/paymentLogs/payment";
import { currencyFormat } from "../../../../utils/numberMethods";
import { sort } from "fast-sort";
import { Badge, ListGroup, Spinner } from "flowbite-react";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { GET_PAYMENT_STATUSES } from "../../settings/payment/paymentStatuses/paymentStatuses";
import getContrast from "../../../../utils/getContrast";
import { UPDATE_PROCESS_BY_PK } from "./gqls";

export const GET_PAYMENTS_BY_PROCESS: TypedDocumentNode<PaymentArrayType> = gql`
  ${PAYMENT_LOGS_CORE_FIELDS}
  query GET_PAYMENTS_BY_PROCESS($id: Int!) {
    paymentLogs(where: { processID: { _eq: $id } }) {
      ...PaymentLogsCoreFields
    }
  }
`;

export default function ProcessPayment({
  process,
  baseHistory,
}: processDetailSubProps) {
  const { data, loading } = useQuery(GET_PAYMENTS_BY_PROCESS, {
    variables: {
      id: process.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: data_payStatus } = useQuery(GET_PAYMENT_STATUSES);
  const paymentStatus = data_payStatus?.paymentStatus;

  const [selecting, setSelecting] = useState(false);
  const toggleSelecting = () => {
    setSelecting(!selecting);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setSelecting(false);
  });

  const [update_process_payment_status, { loading: updating }] =
    useMutation(UPDATE_PROCESS_BY_PK);

  const changePaymentStatus = (id: number) => {
    const status = paymentStatus?.find(ps => ps.id == id);

    if (!status) {
      return;
    }

    const history: ProcessHistory = {
      ...baseHistory,
      message: `payment status updated to ${status.name}`,
      type: "detail",
    };

    const histories: ProcessHistory[] = process.histories
      ? process.histories.concat(history)
      : [history];

    update_process_payment_status({
      variables: {
        id: process.id,
        set: {
          payStatus: id,
          histories,
        },
      },
      onCompleted() {
        setSelecting(false);
      },
    });
  };

  const paymentLogs = data?.paymentLogs;
  const sum =
    paymentLogs?.reduce<number>((prv, cur) => {
      return prv + cur.amount;
    }, 0) || 0;

  const fullyReceived = sum >= (process?.value || 0);

  return (
    <div ref={ref} className="col-span-1 flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center">
        <h2 className="mb-2">Payment</h2>
        <div className="relative">
          {updating && <Spinner className="w-4" color="purple" light />}
          {!updating && (
            <Badge
              style={{
                backgroundColor: process.paymentStatus
                  ? process.paymentStatus.color
                  : "",
                color: process.paymentStatus
                  ? getContrast(process.paymentStatus.color)
                  : "",
              }}
              className="cursor-pointer"
              onClick={toggleSelecting}
            >
              {process.paymentStatus?.name || "no status"}
            </Badge>
          )}

          {selecting && (
            <ListGroup className="absolute top-[100%] mt-2 right-0 w-max">
              {paymentStatus &&
                sort(paymentStatus)
                  .asc(ps => ps.priority)
                  .map(ps => (
                    <ListGroup.Item
                      key={ps.id}
                      onClick={() => {
                        changePaymentStatus(ps.id);
                      }}
                    >
                      <span style={{ color: ps.color }}>{ps.name}</span>
                    </ListGroup.Item>
                  ))}
            </ListGroup>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-end items-end mt-[-5px]">
        <h1 className={`${fullyReceived ? "dark:text-grass text-grass" : ""}`}>
          {currencyFormat(sum || 0)}
        </h1>
        <h3 className="truncate">/ {currencyFormat(process.value || 0)}</h3>
      </div>
      {paymentLogs &&
        sort(paymentLogs)
          .desc(log => new Date(log.updated_at))
          .map(log => (
            <PaymentLog key={log.id} payment={log} process={process} />
          ))}

      <AddPayment processID={process.id} />
    </div>
  );
}
