import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Badge } from "flowbite-react";
import { Dispatch, SetStateAction } from "react";
import { ProcessFilter } from "./types";
import { useScheme } from "../../../../store/schemeStore";

interface props {
  sorter: ProcessFilter;
  setNewFilters: Dispatch<SetStateAction<ProcessFilter[]>>;
  setSorters: Dispatch<SetStateAction<ProcessFilter[]>>;
  setTouched: (bool: boolean) => void;
}

export default function Sorter({
  sorter,
  setNewFilters,
  setSorters,
  setTouched,
}: props) {
  const { scheme } = useScheme();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: sorter.sort?.priority || 1, data: sorter });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const dir = sorter.sort?.dir || "asc";

  const toggleDir = () => {
    if (!dir || !sorter.sort) {
      return;
    }

    const newDir = dir == "asc" ? "desc" : "asc";

    const newSorter = {
      ...sorter,
      sort: {
        ...sorter.sort,
        dir: newDir as "asc" | "desc",
      },
    };

    setSorters(state =>
      state.map(s => {
        if (s.name == sorter.name) {
          return newSorter;
        } else {
          return s;
        }
      })
    );
    setTouched(true);

    setNewFilters(state =>
      state.map(s => {
        if (s.name == sorter.name) {
          return newSorter;
        } else {
          return s;
        }
      })
    );
  };

  return (
    <div className={`${scheme ? scheme : ""}`}>
      <div
        ref={setNodeRef}
        style={style}
        className="rounded-md p-2 dark:bg-gray-700 bg-gray-100 flex flex-row justify-between items-center"
      >
        <div className="text-sm">{sorter.name}</div>
        <div className="flex flex-row gap-2 items-center">
          <Badge
            className="cursor-pointer"
            onClick={toggleDir}
            color={`${dir == "asc" ? "purple" : "success"}`}
          >
            {dir}
          </Badge>
          <button {...attributes} {...listeners}>
            ⣿
          </button>
        </div>
      </div>
    </div>
  );
}
