import { z } from "zod";

export const authSchema = z.object({
  name: z.string().min(2, { message: "name cannot be empty" }).trim(),
  description: z.string().min(2, { message: "description cannot be empty" }),
  permissions: z.number().array().optional(),
});

export interface AuthType {
  id: number;
  name: string;
  description: string;
  permissions: number[];
}

export interface AuthArrayType {
  auths: AuthType[];
}

export interface NewAuthType extends Omit<AuthType, "id"> {
  id: number;
}
