import {
  ArrowUpTrayIcon,
  EllipsisVerticalIcon,
  FolderPlusIcon,
  WindowIcon,
} from "@heroicons/react/24/solid";
import { sort } from "fast-sort";
import { Modal, Button, Spinner, ListGroup } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";
import DynamicHeroIcon from "../../../../comps/hIcon";
import { addAlert } from "../../../../store/alertStore";
import { useScheme } from "../../../../store/schemeStore";
import { deepEqual } from "../../../../utils/arrayObjMethods";
import { list, mkdir, rename, copy, exists } from "../../../../utils/fileAPI";
import Dir from "./dir";
import File from "./file";
import { FileType } from "./type";
import Upload from "./upload";

interface props {
  path: string[];
  simple?: boolean;
}

export default function Folder({ path, simple }: props) {
  const [items, setItems] = useState<FileType[]>([]);
  const [loading, setLoading] = useState(false);
  const [addingFolder, setAddingFolder] = useState(false);
  const toggleFolder = () => {
    setAddingFolder(!addingFolder);
  };

  const [clipboard, setClipboard] = useState<{ path: string[]; copy: boolean }>(
    {
      path: [],
      copy: false,
    }
  );

  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setShowMenu(false);
  });

  const [uploading, setUploading] = useState(false);
  const toggleUploading = () => {
    setUploading(!uploading);
  };

  const { scheme } = useScheme();

  const files = items.filter(i => i.type !== "d");
  const folders = items.filter(i => i.type == "d");

  const [currentPath, setCurrentPath] = useState(path);
  const returnToRoot = () => {
    setCurrentPath(path);
  };

  const goUp = () => {
    setCurrentPath(currentPath.slice(0, currentPath.length - 1));
  };

  const tailPaths = currentPath.slice(path.length);

  const load = async () => {
    setLoading(true);
    try {
      const ex = await exists(currentPath);
      if (!ex) {
        return setLoading(false);
      }
      const data = await list(currentPath);
      setItems(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    load();
  }, [currentPath]);

  const defaultValues = {
    name: "",
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const addFolder = handleSubmit(async data => {
    setLoading(true);
    try {
      await mkdir([...currentPath, data.name]);
      load();
      setLoading(false);
      toggleFolder();
      reset();
    } catch (error) {
      addAlert({ message: "same folder already exists", type: "failure" });
      setLoading(false);
      toggleFolder();
      reset();
    }
  });

  const cancel = () => {
    toggleFolder();
    reset();
  };

  const onUpload = () => {
    setUploading(false);
    load();
  };

  const clearClipboard = () => {
    setClipboard({
      path: [],
      copy: false,
    });
    load();
  };

  const moveOrPaste = async () => {
    setShowMenu(false);
    const name = clipboard.path[clipboard.path.length - 1];
    if (clipboard.copy) {
      await copy(clipboard.path, currentPath.concat(name));
      clearClipboard();
    } else {
      await rename(clipboard.path, currentPath.concat(name));
      clearClipboard();
    }
  };

  return (
    <>
      <section className="w-full space-y-4">
        {!simple && (
          <nav className="flex flex-row relative group">
            <div className="flex-1 flex flex-row items-center gap-2 flex-wrap">
              <div
                className="cursor-pointer flex flex-row gap-2 items-center"
                onClick={returnToRoot}
              >
                <WindowIcon className="w-6" />
                Root
              </div>
              {tailPaths.map((tp, i) => (
                <div key={tp}>
                  <TailPath
                    index={i}
                    name={tp}
                    path={path}
                    tailPaths={tailPaths}
                    setCurrentPath={setCurrentPath}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-row gap-2 items-center">
              {loading && <Spinner size="sm" color="purple" />}

              <EllipsisVerticalIcon
                className="w-5 @md:hidden @md:group-hover:block hover:text-plum cursor-pointer"
                onClick={toggleMenu}
              />
            </div>

            {showMenu && (
              <div ref={ref} className="absolute right-0 top-[100%] mt-2 z-10">
                <ListGroup>
                  <ListGroup.Item onClick={toggleFolder}>
                    <div className="flex flex-row gap-2 items-center">
                      <FolderPlusIcon className="w-4" />
                      new folder
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item onClick={toggleUploading}>
                    <div className="flex flex-row gap-2 items-center">
                      <ArrowUpTrayIcon className="w-4" />
                      upload
                    </div>
                  </ListGroup.Item>
                  {clipboard.path.length > 0 && (
                    <ListGroup.Item onClick={moveOrPaste}>
                      <div className="flex flex-row gap-2 items-center">
                        <DynamicHeroIcon
                          class="w-4"
                          icon={`${
                            clipboard.copy
                              ? "DocumentDuplicateIcon"
                              : "ArrowRightOnRectangleIcon"
                          }`}
                        />
                        {clipboard.copy ? "paste" : "move"}
                      </div>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </div>
            )}
          </nav>
        )}

        <main className="space-y-3">
          {simple && loading && <Spinner color="purple" />}
          {!loading && (
            <>
              {sort(folders)
                .asc(f => f.name.toLowerCase())
                .map(f => (
                  <Dir
                    key={f.longname}
                    path={currentPath}
                    setCurrentPath={setCurrentPath}
                    folder={f}
                    clipboard={clipboard}
                    setClipboard={setClipboard}
                  />
                ))}
              {sort(files)
                .asc(f => f.name.toLowerCase())
                .map(f => (
                  <File
                    key={f.longname}
                    path={currentPath}
                    file={f}
                    setCurrentPath={setCurrentPath}
                    clipboard={clipboard}
                    setClipboard={setClipboard}
                  />
                ))}
              {items.length == 0 && !deepEqual(currentPath, path) && (
                <div onClick={goUp} className="hover:text-plum cursor-pointer">
                  . . /
                </div>
              )}
            </>
          )}
          {!(simple && items.length == 0) && <hr className="border-gray-500" />}

          <div className="flex flex-row justify-end gap-2">
            {!simple && (
              <Button
                size="xs"
                gradientDuoTone="purpleToBlue"
                onClick={toggleFolder}
              >
                <FolderPlusIcon className="w-4 mr-1" />
                New Folder
              </Button>
            )}

            <Button
              size="xs"
              outline
              gradientDuoTone="purpleToBlue"
              onClick={toggleUploading}
            >
              <ArrowUpTrayIcon className="w-4 mr-1" />
              Upload
            </Button>
          </div>
        </main>
      </section>
      <Modal show={addingFolder} onClose={toggleFolder} className={`${scheme}`}>
        <Modal.Header>New Folder</Modal.Header>
        <Modal.Body>
          <form onSubmit={addFolder} className="space-y-4">
            <div className="flex flex-row gap-2 items-center">
              <FolderPlusIcon className="w-6 dark:text-white" />
              <input
                placeholder="Enter folder name"
                {...register("name")}
                className="bg-transparent dark:text-white w-full rounded-md p-[5px] px-[8px] border-none outline-none text-lg"
              />
            </div>
            <div className="flex flex-row justify-end gap-2">
              <Button size="sm" gradientDuoTone="purpleToBlue" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="sm"
                gradientDuoTone="purpleToBlue"
                outline
                type="submit"
              >
                create
                {loading && (
                  <Spinner light size="sm" className="ml-2" color="purple" />
                )}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={uploading} onClose={toggleUploading} className={`${scheme}`}>
        <Modal.Header>Upload</Modal.Header>
        <Modal.Body>
          <Upload path={currentPath} callback={onUpload} />
        </Modal.Body>
      </Modal>
    </>
  );
}

interface tailPathProps {
  index: number;
  name: string;
  path: string[];
  tailPaths: string[];
  setCurrentPath: React.Dispatch<React.SetStateAction<string[]>>;
}

const TailPath = ({
  index,
  name,
  path,
  tailPaths,
  setCurrentPath,
}: tailPathProps) => {
  const isLast = index == tailPaths.length - 1;
  const move = () => {
    setCurrentPath(path.concat(tailPaths.slice(0, index + 1)));
  };
  return (
    <div
      onClick={move}
      className={`${
        isLast ? "" : "cursor-pointer hover:text-plum"
      } flex flex-row gap-2`}
    >
      <span>{"/"}</span>
      {name}
    </div>
  );
};
