import {
  Checkbox,
  Label,
  Select,
  TextInput,
  ToggleSwitch,
} from "flowbite-react";
import { useFormContext } from "react-hook-form";
import { useQuery, useLazyQuery, gql, TypedDocumentNode } from "@apollo/client";
import { USER_CORE_FIELDS } from "../../settings/users/fragments";
import { UserArrayType, UserType } from "../../settings/users/types";
import { GET_PERMISSIONS } from "../../settings/auths/permissions/permissions";
import { addAlert } from "../../../../store/alertStore";
import { SourceType } from "../../settings/process/sources/types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PermissionType } from "../../settings/auths/permissions/types";
import { TeamType } from "../../settings/teams/types";
import checkAuth from "../../../../utils/checkAuth";
import { useAuthStore } from "../../../../store/authStore";

export const GET_SALES_USERS: TypedDocumentNode<UserArrayType> = gql`
  ${USER_CORE_FIELDS}
  query GET_SALES_USERS(
    $pid: jsonb!
    $validTeams1: [Int!]
    $validTeams2: [jsonb!]
  ) {
    users(
      where: {
        _and: [
          { active: { _eq: true } }
          {
            _or: [
              { auth: { permissions: { _contains: $pid } } }
              { permissions: { _contains: $pid } }
            ]
          }
          {
            _or: [
              { teamID: { _in: $validTeams1 } }
              { team: { subTeams: { _in: $validTeams2 } } }
            ]
          }
        ]
      }
    ) {
      ...UserCoreFields
    }
  }
`;

export default function Sales({
  sources: sourceList,
  teams,
  setSales,
}: {
  sources: SourceType[];
  teams: TeamType[];
  setSales: Dispatch<SetStateAction<UserType[]>>;
}) {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const typeId = watch("typeId");

  const { user } = useAuthStore();

  const [get_sales, { data: data_sales, loading: loading_sales }] =
    useLazyQuery(GET_SALES_USERS, {
      onError(error) {
        console.log(error);
        addAlert({ message: "cannot load sales users", type: "failure" });
      },
    });

  const [permission, setPermission] = useState<PermissionType>();

  const { loading: loading_permissions, error: error_permissions } = useQuery(
    GET_PERMISSIONS,
    {
      onCompleted(data) {
        if (data.permissions) {
          const permission = data.permissions.find(p => p.name == "can_sales");
          setPermission(permission);
        }
      },
    }
  );

  useEffect(() => {
    const validTeams = teams
      .filter(team => team.processTypes?.includes(Number(typeId)))
      .map(team => team.id);

    if (permission && typeId) {
      get_sales({
        variables: {
          pid: permission.id,
          validTeams1: validTeams,
          validTeams2: validTeams,
        },
        onCompleted(data) {
          setSales(data.users);
        },
      });
    }
  }, [typeId, permission]);

  const returning = watch("returning");

  const toggleReturning = () => {
    setValue("returning", !returning);
  };

  const sources: number[] = watch("sources") || [];

  const toggleSource = (id: number) => {
    if (sources.find(s => id == s)) {
      setValue(
        "sources",
        sources.filter(s => s !== id)
      );
    } else {
      setValue("sources", sources.concat(id));
    }
  };

  return (
    <section className="col-span-1 space-y-2 flex flex-col">
      <h2>Sales Info</h2>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-2 flex-1 ">
        <div className="space-y-2">
          <Label value="Sales Rep" />
          <Select {...register("salesRep")} sizing="sm">
            <option value={0}>none</option>
            {data_sales?.users &&
              data_sales.users
                .filter(u =>
                  checkAuth("sales_mine_only") ? u.id == user?.id : true
                )
                .map(u => (
                  <option key={u.id} value={u.id}>
                    {u.firstName} {u.sirName}
                  </option>
                ))}
          </Select>
        </div>
        <div className="space-y-2">
          <Label value="Value" />
          <TextInput
            type="number"
            addon="$"
            placeholder="Enter value"
            sizing="sm"
            {...register("value")}
            helperText={errors.value?.message?.toString() || ""}
            color={errors.value?.message ? "failure" : undefined}
          />
        </div>
        <div className="space-y-2">
          <Label value="Sources" />
          <div className="flex flex-row flex-wrap p-1 mb-2 gap-2">
            {sourceList.map(source => (
              <div
                key={source.id}
                className="flex items-center gap-2"
                onClick={() => {
                  toggleSource(source.id);
                }}
              >
                <Checkbox
                  className="checked:bg-plum dark:checked:bg-plum cursor-pointer"
                  readOnly
                  checked={sources.find(s => s == source.id) ? true : false}
                />
                <Label className="cursor-pointer">{source.name}</Label>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-2 py-2 flex justify-end">
          <ToggleSwitch
            label="Returning"
            //@ts-expect-error
            color="purple"
            checked={returning}
            onChange={toggleReturning}
          />
        </div>
      </div>
    </section>
  );
}
