import { useAlertStore } from "../store/alertStore";
import { Alert as AlertComp } from "flowbite-react";
import {
  InformationCircleIcon,
  BellAlertIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

export default function Alert() {
  const alerts = useAlertStore(state => state.alerts);

  const removeAlert = useAlertStore(state => state.removeAlert);

  return (
    <div className="absolute w-full z-50">
      {alerts.map(a => {
        let icon;

        switch (a.type) {
          case "info":
            icon = InformationCircleIcon;
            break;
          case "success":
            icon = CheckCircleIcon;
            break;
          case "warning":
            icon = ExclamationCircleIcon;
            break;
          case "failure":
            icon = XCircleIcon;
            break;
          default:
            icon = BellAlertIcon;
            break;
        }

        return (
          <AlertComp
            rounded={false}
            withBorderAccent={true}
            icon={icon}
            color={a.type}
            key={a.id}
            onDismiss={() => {
              removeAlert(a.id);
            }}
            className="mb-2"
          >
            <span>{a.message}</span>
            {a.children && a.children}
          </AlertComp>
        );
      })}
    </div>
  );
}
