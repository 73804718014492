import { gql } from "@apollo/client";
import { CONTACT_TYPE_CORE_FIELDS } from "../settings/contact/contactTypes/fragments";

export const CONTACT_CORE_FIELDS = gql`
  ${CONTACT_TYPE_CORE_FIELDS}
  fragment ContactCoreFields on contacts {
    id
    user {
      id
      firstName
      sirName
    }
    organisation {
      id
      name
    }
    contactType {
      ...ContactTypeCoreFields
    }
    name
    number
    mail
    address
    description
    role
    isOrganisation
    deleted
    updated_at
  }
`;
