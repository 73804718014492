import axios from "axios";
import { default as config } from "../config";

export interface Coord {
  lat: number;
  lng: number;
}

export const geocode = async (location: string) => {
  const params = {
    apiKey: "SCfD9D8AjyPIHJXMc_aWJKk008zQS5GqXBqAKzUTJz0",
    q: location,
    in: "countryCode:AUS",
  };
  try {
    const res = await axios.get(
      "https://geocode.search.hereapi.com/v1/geocode",
      {
        params,
        withCredentials: false,
      }
    );

    if (res?.data?.items) {
      const loc = res?.data?.items[0];
      return loc?.position;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const revGeocode = async (coord: Coord) => {
  const params = {
    apiKey: "SCfD9D8AjyPIHJXMc_aWJKk008zQS5GqXBqAKzUTJz0",
    at: `${coord.lat},${coord.lng}`,
    lang: "en-US",
  };
  try {
    const res = await axios.get(
      "https://revgeocode.search.hereapi.com/v1/revgeocode",
      {
        params,
        withCredentials: false,
      }
    );

    if (res?.data?.items) {
      return res.data.items;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const numberCoord = ({
  lat,
  lng,
}: {
  lat: string | number;
  lng: string | number;
}) => {
  return {
    lat: Number(lat),
    lng: Number(lng),
  };
};

const companyLoc = config.companyLoc;

export const matrix = async (origin: Coord, coords: Coord[]) => {
  try {
    const locs = coords.map(c => {
      if (c && c.lat && c.lng) {
        return c;
      } else {
        return companyLoc;
      }
    });
    const body = {
      origins: [origin],
      destinations: locs,
      regionDefinition: {
        type: "world",
      },
      // matrixAttributes: ["seconds"],
      transportMode: "car",
    };

    const params = {
      apiKey: "SCfD9D8AjyPIHJXMc_aWJKk008zQS5GqXBqAKzUTJz0",
    };

    const res = await axios.post(
      "https://matrix.router.hereapi.com/v8/matrix?async=false",
      body,
      {
        params,
        withCredentials: false,
      }
    );
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const calcDistance = (p1: Coord, p2: Coord) => {
  const lat1 = Number(p1.lat);
  const lat2 = Number(p2.lat);

  const lng1 = Number(p1.lng);
  const lng2 = Number(p2.lng);

  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lng2 - lng1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres

  return d;
};

const toRad = (num: number) => {
  return (num * Math.PI) / 180;
};
const toDeg = (num: number) => {
  return num * (180 / Math.PI);
};

export const midPoint = (p1: Coord, p2: Coord) => {
  let lat1 = Number(p1.lat);
  let lat2 = Number(p2.lat);

  let lng1 = Number(p1.lng);
  let lng2 = Number(p2.lng);

  //-- Longitude difference
  const dLng = toRad(lng2 - lng1);

  //-- Convert to radians
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);
  lng1 = toRad(lng1);

  let bX = Math.cos(lat2) * Math.cos(dLng);
  let bY = Math.cos(lat2) * Math.sin(dLng);

  const lat3 = Math.atan2(
    Math.sin(lat1) + Math.sin(lat2),
    Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY)
  );
  const lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

  const lat = {
    lat: toDeg(lat3),
    lng: toDeg(lng3),
  };
  // lng3.toDeg(), lat3.toDeg()

  return lat;
};
