import { gql } from "@apollo/client";

export const QUOTE_PARAMS_FIELDS = gql`
  fragment QuoteParamsFields on quoteParams {
    id
    name
    value
    productSetId
    userId
    updated_at
    created_at
  }
`;

export const PRICING_TABLE_FIELDS = gql`
  fragment PricingTableFields on productSetPricingTable {
    id
    productSetId
    columns
    rows
    updated_at
    created_at
    name
    condition
  }
`;
