import { Button, Spinner } from "flowbite-react";
import { useForm } from "react-hook-form";
import getBase64 from "../../../../utils/getBase64";
import { upload } from "../../../../utils/fileAPI";
import { useState } from "react";

interface props {
  path: string[];
  callback?: () => void;
}
export default function Upload({ path, callback }: props) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      newFile: undefined,
    },
  });

  const onSubmit = handleSubmit(async data => {
    const file = data.newFile && data.newFile[0];
    if (file) {
      setUploading(true);
      const res = (await getBase64(file)) as string;
      // @ts-expect-error
      const finalPath = path.concat(file.name);
      await upload(res, finalPath);
      setUploading(false);
      if (callback) {
        callback();
      }
      reset();
    }
  });

  const [uplaoding, setUploading] = useState(false);

  return (
    <form onSubmit={onSubmit}>
      <input
        {...register("newFile")}
        type="file"
        className="bg-transparent dark:text-white w-full rounded-md p-[5px] px-[8px] border-none outline-none text-lg"
      />
      <div className="flex justify-end">
        <Button type="submit" outline size="sm" gradientDuoTone="purpleToBlue">
          Upload
          {uplaoding && <Spinner size="sm" className="ml-2" />}
        </Button>
      </div>
    </form>
  );
}
