import { ListGroup } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";
import { option } from "../../../types";
import { useOrderStore } from "../../../../../../../store/orderStore";

interface props {
  optionCoord: string;
  optionsCoord: string;
  optionType: string;
  optionValue: option["value"];
}

export default function Trackover({
  optionsCoord,
  optionCoord,
  optionValue,
  optionType,
}: props) {
  const { deductions } = useOrderStore();
  const bottomDeductions = deductions.filter(
    d => d.optionCode == "ShutterBottom"
  );

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const { register, control, setValue, getValues } = useFormContext();

  const formOptions: option[] = useWatch({
    name: optionsCoord,
    control,
    // exact: true,
  });

  const { fields, replace } = useFieldArray({
    name: `${optionCoord}.value`,
    control,
  });

  const botTrackOptions = () => {
    if (optionType !== "trackover") {
      return false;
    }

    const BottomTracks = bottomDeductions;

    const TrackQty = formOptions?.find(o => o.name == "TrackQty")?.value;

    if (!BottomTracks || BottomTracks.length < 1 || !TrackQty) {
      return false;
    }

    const existing = Array.isArray(optionValue) && optionValue;

    const options = Array(Number(TrackQty))
      .fill({
        values: BottomTracks.map(b => ({
          ...b,
          image: undefined,
          parentDeduction: undefined,
          imageTexts: undefined,
          inventory: undefined,
          productSet: undefined,
        })),
      })
      .map((o, i) => {
        const extVal = existing && existing?.find(e => e.position == i)?.value;
        const value = extVal !== undefined ? extVal : 0;

        return { position: i, value: Number(value), ...o };
      });

    return options;
  };

  const wheres = [
    { position: 0, name: "Front" },
    { position: 1, name: "Back" },
    { position: 2, name: "Middle" },
  ];

  const [currentTrackConfig, setCurrentTrackConfig] = useState({
    TrackQty: undefined,
    BottomTrack: undefined,
  });

  useEffect(() => {
    // console.log("hi");
    const newOptions = botTrackOptions();

    const TrackQty = formOptions?.find(o => o.name == "TrackQty")?.value;
    const BottomTrack = formOptions?.find(o => o.name == "BottomTrack")?.value;

    if (
      currentTrackConfig.TrackQty == TrackQty &&
      currentTrackConfig.BottomTrack == BottomTrack
    ) {
      return;
    }

    if (newOptions) {
      replace(newOptions);
      setCurrentTrackConfig({
        TrackQty,
        BottomTrack,
      });
    }
  }, [
    bottomDeductions,
    JSON.stringify(formOptions?.find(o => o.name == "TrackQty")),
    JSON.stringify(formOptions?.find(o => o.name == "BottomTrack")),
  ]);

  useEffect(() => {
    if (!optionValue || !Array.isArray(optionValue)) {
      return;
    }

    if (optionValue.filter(o => o.value !== 0).length == 0) {
      return;
    }

    const displayValue = optionValue
      .map(val => {
        const where = wheres.find(w => w.position == val.position)?.name;

        if (!where) {
          return null;
        }
        // @ts-ignore
        const track = val.values?.find(v => v?.id == val.value)?.name;

        if (!track) {
          return null;
        }
        return `${where}:${track}`;
      })
      .filter(o => o !== null)
      .join(", ");

    const currentDisplayValue = getValues(`${optionCoord}.displayValue`);
    if (currentDisplayValue !== displayValue) {
      setValue(`${optionCoord}.displayValue`, displayValue);
    }
  }, [JSON.stringify(optionValue)]);

  const nullishValues = [undefined, null, "", "0", 0];

  return (
    <div className="relative min-w-[120px]" ref={ref}>
      <ListGroup onClick={toggleOpen}>
        <ListGroup.Item>
          {optionValue &&
          Array.isArray(optionValue) &&
          optionValue.filter(v => !nullishValues.includes(v.value)).length >
            0 ? (
            <div className="text-xs">
              {optionValue.filter(v => !nullishValues.includes(v.value)).length}
            </div>
          ) : (
            <div className="text-xs">None</div>
          )}
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 flex flex-col gap-2 py-2 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
          {fields?.map((val, i) => {
            let name = "";

            switch (i) {
              case 0:
                name = "Front";
                break;
              case 1:
                name = "Back";
                break;
              case 2:
                name = "Middle";
                break;
            }

            return (
              <div
                key={val.id}
                className="group/preset relative flex flex-row gap-2 items-center px-2"
              >
                <div className="w-10">{name}</div>
                <select
                  className="py-1 text-sm text-black"
                  {...register(`${optionCoord}.value[${i}].value`)}
                >
                  <option value={0}>Default</option>
                  {
                    // @ts-expect-error
                    val.values.map(opt => (
                      <option key={opt.id} value={opt.id}>
                        {opt.name}
                      </option>
                    ))
                  }
                </select>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
