import { useAuthStore } from "../../../store/authStore";
import { TextInput, Label } from "flowbite-react";

export default function Basic() {
  const { user } = useAuthStore();

  return (
    <div className="flex-1 min-w-[250px] mx-auto">
      <div className="text-2xl font-bold mb-2">Role</div>
      <div className="shadow-md bg-white mb-5 dark:bg-gray-800 rounded-md p-4 ">
        <div className="mb-2 block">
          <Label htmlFor="Auth" value="Auth" />
        </div>
        <div className={`${user?.auth?.name && "text-plum capitalize"}`}>
          {user?.auth?.name || "not assigned"}
        </div>
        <div className="my-2 block">
          <Label htmlFor="Team" value="Team" />
        </div>
        <div>{user?.team?.name || "not assigned"}</div>
      </div>
      <div className="text-2xl font-bold mb-2">Status</div>
      <div className="shadow-md bg-white mb-5 dark:bg-gray-800 rounded-md p-4 ">
        <div className="mb-2 block">
          <Label htmlFor="singedDate" value="Registered On" />
        </div>
        <div>
          {user?.created_at && new Date(user.created_at).toLocaleDateString()}
        </div>

        <div className="mb-2 block mt-2">
          <Label htmlFor="status" value="Status" />
        </div>
        <div>{user?.active ? "Active" : "Inactive"}</div>
      </div>
    </div>
  );
}
