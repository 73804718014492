import React from "react";
// import { UseFormSetValue } from "react-hook-form";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { Badge, Breadcrumb, Button, TextInput } from "flowbite-react";

export default function PathEdit({
  setValue,
  path,
}: {
  setValue: any;
  path: string[];
}) {
  const initPath = () => {
    setValue("path", ["new path"]);
  };

  const addPath = () => {
    setValue("path", path.concat("path name"));
  };

  const removePath = () => {
    setValue("path", path.slice(0, -1));
  };

  const updatePathName = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    const t = e.target.value;
    const newPath = path.map((p, index) => {
      if (index == i) {
        return t;
      } else {
        return p;
      }
    });

    setValue("path", newPath);
  };

  return (
    <div>
      {!path ||
        (path.length < 1 && (
          <Button
            gradientDuoTone="purpleToBlue"
            size="sm"
            outline
            onClick={initPath}
          >
            Add Path
          </Button>
        ))}
      {path && path.length > 0 && (
        <nav className="flex flex-row gap-2 items-center">
          <Breadcrumb>
            {path?.map((p, i) => (
              <Breadcrumb.Item key={i}>
                <TextInput
                  className="w-fit"
                  value={path.find(pa => pa == p)}
                  onChange={e => {
                    updatePathName(e, i);
                  }}
                />
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <div className="flex flex-row gap-1 items-center">
            {path.length > 1 && (
              <MinusCircleIcon
                className="w-5 text-red-500 cursor-pointer hover:animate-pulse"
                onClick={removePath}
              />
            )}
            <PlusCircleIcon
              className="w-5 text-green-500 cursor-pointer hover:animate-pulse"
              onClick={addPath}
            />
          </div>
        </nav>
      )}
    </div>
  );
}
