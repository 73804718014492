import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { Table } from "flowbite-react";
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  useFormContext,
} from "react-hook-form";

interface props {
  fields: FieldArrayWithId[];
  remove: UseFieldArrayRemove;
}
export default function Columns({ fields, remove }: props) {
  return (
    <Table.Head>
      <Table.HeadCell className="w-24 !p-0 border-r-[1px] dark:border-gray-500">
        <div className="flex flex-col">
          <div className="flex flex-row justify-end pr-4">W</div>
          <div className="flex flex-row justify-start pl-4">H</div>
        </div>
      </Table.HeadCell>
      {fields.map((column, i) => (
        <Column key={column.id} index={i} remove={remove} />
      ))}
      <Table.HeadCell className="w-10 !py-2 !px-1" />
    </Table.Head>
  );
}

const Column = ({
  index,
  remove,
}: {
  index: number;
  remove: UseFieldArrayRemove;
}) => {
  const { register } = useFormContext();

  return (
    <Table.HeadCell className="!p-2 min-w-[80px] border-r-[1px] dark:border-gray-500">
      <div className="flex flex-row gap-1 items-center pl-5">
        <input
          className="bg-transparent w-0 flex-1 text-center text-black dark:text-white outline-none border-none text-sm py-[1px] px-0"
          type="number"
          {...register(`columns.${index}.width`)}
        />
        <MinusCircleIcon
          onClick={() => remove(index)}
          className="text-red-500 w-4 cursor-pointer"
        />
      </div>
    </Table.HeadCell>
  );
};
