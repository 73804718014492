import {
  Button,
  Label,
  TextInput,
  Tooltip,
  Select,
  Spinner,
  Modal,
} from "flowbite-react";
import { useScheme } from "../../store/schemeStore";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useParams } from "react-router-dom";
import { LightBulbIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import {
  INewUser,
  useAuthStore,
  newUserSchema,
  useRegister,
} from "../../store/authStore";
import TnC from "./TnC";
import { gql, useQuery } from "@apollo/client";
import {
  Autocomplete,
  AutocompleteProps,
  LoadScriptProps,
  useLoadScript,
} from "@react-google-maps/api";
import { default as config } from "../../config";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];
const companyLoc = config.companyLoc;

const GET_TEAMS = gql`
  query getTeams {
    teams {
      id
      name
    }
  }
`;

export default function Register() {
  // Init form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<INewUser>({
    resolver: zodResolver(newUserSchema),
    defaultValues: {
      firstName: "",
      sirName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      teamID: "0",
      calId: "",
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      mobile: "",
      address: "",
      baseLocation: undefined,
    },
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });
  const [autocomplete, setAutocomplete] = useState<any | null>(null);

  const autoCompleteLoaded = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };
  const autocompleteOptions: AutocompleteProps["options"] = {
    componentRestrictions: { country: "au" },
    bounds: {
      north: companyLoc.lat + 3,
      south: companyLoc.lat - 3,
      east: companyLoc.lng + 3,
      west: companyLoc.lng - 3,
    },
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const { formatted_address: address, geometry } = place;

      if (address) {
        setValue("address", address.replace(", Australia", "").trim());
      }

      const { lat, lng } = {
        lat: geometry?.location?.lat(),
        lng: geometry?.location?.lng(),
      };

      const loc = lat && lng ? { lat, lng } : null;

      if (loc) {
        setValue("baseLocation", loc);
      }
    }
  };

  // TnC Modal Control
  const [showModal, setShowModal] = useState(false);
  const scheme = useScheme(state => state.scheme); //Pass scheme to modal

  const { loggingIn } = useAuthStore();

  // Load teams
  type team = { name: string; id: number };
  const { loading, error, data } = useQuery(GET_TEAMS);
  if (error) {
    console.log(error);
  }
  const teams: team[] = data?.teams || [];

  const { type } = useParams();

  useEffect(() => {
    if (type == "dealer") {
      setValue("teamID", "20");
    }
  }, []);

  // register action
  const _register = useRegister();

  const onSubmit = handleSubmit(async data => {
    _register(data);
  });

  return (
    <div className="h-full flex flex-col items-center">
      <div className="font-bold text-2xl mt-20 mb-10 select-none">Register</div>
      <form
        onSubmit={onSubmit}
        autoComplete="off"
        className="w-[576px] max-w-[80%] shadow-md bg-white dark:bg-gray-800 rounded-md p-8 flex flex-col gap-4"
      >
        {/* First Name */}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="firstName" value="First Name" />
          </div>
          <TextInput
            type="text"
            placeholder="your first name"
            {...register("firstName")}
            color={errors.firstName?.message ? "failure" : undefined}
            helperText={errors.firstName?.message || ""}
          />
        </div>
        {/* Sir Name */}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="sirName" value="Sir Name" />
          </div>
          <TextInput
            type="text"
            placeholder="your sir name"
            {...register("sirName")}
            color={errors.sirName?.message ? "failure" : undefined}
            helperText={errors.sirName?.message || ""}
          />
        </div>
        {/* Email */}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Email" />
          </div>
          <TextInput
            addon="@"
            type="email"
            autoComplete="off"
            placeholder="name@example.com"
            {...register("email")}
            color={errors.email?.message ? "failure" : undefined}
            helperText={errors.email?.message || ""}
          />
        </div>
        {/* Password */}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="Password" />
          </div>
          <TextInput
            type="password"
            placeholder="password here"
            color={errors.password?.message ? "failure" : undefined}
            helperText={errors.password?.message || ""}
            {...register("password")}
          />
        </div>
        {/* Password Confirm*/}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="passwordConfirm" value="Password Confirm" />
          </div>
          <TextInput
            type="password"
            placeholder="confirm your password"
            color={errors.passwordConfirm?.message ? "failure" : undefined}
            helperText={errors.passwordConfirm?.message || ""}
            {...register("passwordConfirm")}
          />
        </div>
        {/* Team Select */}
        {type !== "dealer" && (
          <div id="select">
            <div className="mb-2 block">
              <Label htmlFor="teams" value="Select your team" />
            </div>
            <Select
              required={true}
              {...register("teamID")}
              color={errors.teamID?.message ? "failure" : undefined}
              helperText={errors.teamID?.message || ""}
            >
              <option value={0}>Select from the list</option>
              {teams?.map(team => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </Select>
          </div>
        )}

        {/* optional divider */}
        <div className="mb-[-15px] block text-right">
          <Label
            htmlFor="optional"
            value="Optional fields"
            className="italic"
          />
        </div>

        {/* Mobile*/}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="mobile" value="Mobile" />
          </div>

          <TextInput
            addon="☏"
            type="tel"
            placeholder="your mobile number"
            color={errors.mobile?.message ? "failure" : undefined}
            helperText={errors.mobile?.message || ""}
            {...register("mobile")}
          />
        </div>

        {/* Address*/}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="address" value="Address" />
          </div>
          {isLoaded && (
            <Autocomplete
              onLoad={autoCompleteLoaded}
              onPlaceChanged={onPlaceChanged}
              options={autocompleteOptions}
              fields={[
                "formatted_address",
                "geometry.location",
                "address_component",
              ]}
            >
              <TextInput
                addon="🏁"
                type="text"
                placeholder="your address"
                color={errors.address?.message ? "failure" : undefined}
                helperText={errors.address?.message || ""}
                {...register("address")}
              />
            </Autocomplete>
          )}
        </div>
        {/* Cal Id*/}
        {type !== "dealer" && (
          <div>
            <div className="mb-2 flex flex-row items-center">
              <Label htmlFor="calendarId" value="Calendar Id" />
              <Tooltip
                content="CalendarID can be found under google calendar setting -> Integrate calendar"
                placement="bottom"
              >
                <LightBulbIcon className="w-4 ml-1" />
              </Tooltip>
            </div>

            <TextInput
              addon="@"
              type="email"
              placeholder="your google calendar Id"
              color={errors.calId?.message ? "failure" : undefined}
              helperText={errors.calId?.message || ""}
              {...register("calId")}
            />
          </div>
        )}

        {/* Color*/}
        <div>
          <div className="mb-2 flex flex-row items-center">
            <Label htmlFor="calendarId" value="Color" />
            <Tooltip
              content="To change color, click the color pallet below"
              placement="bottom"
            >
              <LightBulbIcon className="w-4 ml-1" />
            </Tooltip>
          </div>
          <input
            type="color"
            {...register("color")}
            className="w-full h-10 border-2 p-0 bg-transparent border-none"
          />
        </div>

        <div className="flex gap-2 justify-end mt-10">
          <Link to="/login" relative="path">
            <Button gradientDuoTone="purpleToBlue" size="sm">
              Login
            </Button>
          </Link>
          <Button
            outline={true}
            gradientDuoTone="purpleToBlue"
            size="sm"
            type="submit"
            disabled={loading || error !== undefined}
          >
            {loggingIn && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            Register
          </Button>
        </div>
      </form>
      <div
        onClick={() => {
          setShowModal(true);
        }}
        className="w-[576px] max-w-[80%] text-xs italic mr-1 cursor-pointer text-right text-gray-700 dark:text-gray-200 mt-2 pr-1 mb-20 select-none"
      >
        Terms & Conditions
      </div>

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        className={scheme === "dark" ? "dark" : ""}
      >
        <Modal.Header>Terms of Service</Modal.Header>
        <Modal.Body>
          <TnC />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            I understand
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
