import { gql } from "@apollo/client";

export const TASK_STATUS_CORE_FIELDS = gql`
  fragment TaskStatusCoreFields on taskStatus {
    id
    name
    description
    color
    priority
  }
`;
