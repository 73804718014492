import { useState } from "react";
import { useOrderStore } from "../../../../../store/orderStore";
import { pricingTable, pricingTableScheme } from "../types";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@apollo/client";
import { UPDATE_PRICING_TABLE_BY_PK } from "../gqls";
import { Button, Card, Label, Spinner, Table, TextInput } from "flowbite-react";
import Columns from "./columns";
import Rows from "./rows";

interface props {
  table: pricingTable;
}

export default function PricingTable({ table }: props) {
  const { productSets } = useOrderStore();
  const productSet = productSets.find(ps => ps.id === table.productSetId);

  const [extended, setExtended] = useState(false);

  const defaultValues = table;

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(pricingTableScheme),
  });

  const {
    reset,
    control,
    handleSubmit,
    register,
    formState: { isDirty, errors },
  } = methods;

  const { fields, remove, append } = useFieldArray({
    control,
    // @ts-ignore
    name: "columns",
  });

  const cancel = () => {
    reset();
  };

  const [update, { loading }] = useMutation(UPDATE_PRICING_TABLE_BY_PK);

  const submit = handleSubmit(data => {
    const { columns, rows, name, condition } = data;
    update({
      variables: {
        set: { columns, rows, name, condition },
        id: table.id,
      },
      onCompleted() {
        reset({
          columns,
          rows,
          name,
          condition,
          productSetId: table.productSetId,
        });
      },
    });
  });

  return (
    <div className="bg-gray-100 dark:bg-gray-600 flex flex-col rounded-md ">
      <div
        onClick={() => {
          setExtended(!extended);
        }}
        className="p-3 bg-gray-100 dark:bg-gray-600 flex flex-row items-center justify-between rounded-md cursor-pointer hover:brightness-125"
      >
        <div className="font-semibold text-xl capitalize">
          {productSet?.name} - {table.name}
        </div>

        {extended ? (
          <ChevronDoubleUpIcon className="w-5" />
        ) : (
          <ChevronDoubleDownIcon className="w-5" />
        )}
      </div>
      {extended && (
        <div className="p-3 m-3 flex flex-col gap-1 rounded-md bg-white dark:bg-gray-800 drop-shadow-md">
          <FormProvider {...methods}>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center justify-between">
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  {...register("name")}
                  placeholder="Enter name"
                />
              </div>

              <div className="flex flex-row gap-2 items-center justify-between">
                <Label value="Condition" />
                <TextInput
                  sizing="sm"
                  {...register("condition")}
                  addon="f(x)"
                  placeholder="Optional Category Condition"
                />
              </div>

              <Table striped>
                <Columns fields={fields} remove={remove} />
                <Rows
                  // @ts-ignore
                  appendColumn={append}
                />
              </Table>
              {isDirty && (
                <div className="flex flex-row justify-end gap-2 items-center mt-2">
                  <Button size="xs" color="purple" onClick={cancel}>
                    cancel
                  </Button>
                  <Button
                    size="xs"
                    outline
                    gradientDuoTone="purpleToBlue"
                    onClick={submit}
                  >
                    {loading && <Spinner size="xs" color="purple" />}
                    update
                  </Button>
                </div>
              )}
            </div>
          </FormProvider>
        </div>
      )}
    </div>
  );
}
