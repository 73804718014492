import { sort } from "fast-sort";
import { pricingTable } from "../../../../settings/quote/types";
import refinePricingTable from "./refinePricingTable";

export default function findPriceFromTable({
  width,
  height,
  table,
}: {
  width: number;
  height: number;
  table: pricingTable;
}) {
  const refinedPrices = refinePricingTable(table);

  const filteredPrices = refinedPrices.filter(
    price => price.width >= width && price.height >= height
  );

  const sortedPrices = sort(filteredPrices).by([
    { asc: price => price.height },
    { asc: price => price.width },
  ]);

  const maximumPrice = refinedPrices[refinedPrices.length - 1]?.price;

  return sortedPrices[0]?.price || maximumPrice || 0;
}
