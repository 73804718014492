import { create } from "zustand";
import { Event } from "../types/calendarTypes";

type Store = {
  holidays: Event[];
  setHolidays: (events: Event[]) => void;
};

export const useHolidayStore = create<Store>(set => ({
  holidays: [],
  setHolidays(holidays) {
    set(state => ({
      ...state,
      holidays,
    }));
  },
}));
