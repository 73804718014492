import { useMutation, useQuery, gql } from "@apollo/client";
import { TeamType } from "./types";
import {
  Button,
  Spinner,
  TextInput,
  Label,
  Select,
  Badge,
  ToggleSwitch,
} from "flowbite-react";
import {
  MinusCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { teamSchema } from "./types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TEAM_CORE_FIELDS } from "./fragments";
import checkAuth from "../../../../utils/checkAuth";
import { GET_TEAM_TYPE } from "./teamType";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { GET_PROCESS_TYPES } from "../process/processTypes/processTypes";
import { GET_PRODUCTS } from "../products/products";

export const UPDATE_TEAM = gql`
  ${TEAM_CORE_FIELDS}
  mutation UPDATE_TEAM(
    $id: Int!
    $name: String!
    $inHouse: Boolean!
    $teamType: Int!
    $subTeams: jsonb
    $processTypes: jsonb
    $products: jsonb
  ) {
    update_teams_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        inHouse: $inHouse
        teamType: $teamType
        subTeams: $subTeams
        processTypes: $processTypes
        products: $products
      }
    ) {
      ...TeamCoreFields
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation DELETE_TEAM($id: Int!) {
    delete_teams_by_pk(id: $id) {
      id
    }
  }
`;

export default function Team({
  teams,
  team,
}: {
  teams?: TeamType[];
  team: TeamType;
}) {
  const [update_team, { loading, error }] = useMutation(UPDATE_TEAM);

  if (error) {
    console.log(error);
  }

  const [delete_team, { loading: delete_loading, error: delete_error }] =
    useMutation(DELETE_TEAM);

  if (delete_error) {
    console.log(delete_error);
  }

  const [editing, setEditing] = useState(false);

  const {
    data,
    loading: team_type_loading,
    error: team_type_error,
  } = useQuery(GET_TEAM_TYPE);

  const { data: process_type_data } = useQuery(GET_PROCESS_TYPES);

  const { data: data_products } = useQuery(GET_PRODUCTS);

  if (team_type_error) {
    console.log(team_type_error);
  }

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      ...team,
      teamType: team.type?.id.toString(),
      subTeams: team.subTeams || [],
      processTypes: team.processTypes || [],
      products: team.products || [],
    },
  });

  const teamType = data?.teamType.find(
    t => t.id == Number(watch("teamType")) || 0
  )?.name;

  const onSubmit = handleSubmit(async data => {
    update_team({
      variables: { ...data, id: team.id },
      onCompleted: () => {
        toggleEdit();
      },
    });
  });

  const deleteTeam = () => {
    const res = confirm(
      "you are trying to delete a team which may cause errors for users, are you sure?"
    );
    if (res) {
      delete_team({
        variables: { id: team.id },
        update(cache, { data: { delete_teams_by_pk: deletedTeam } }) {
          const normalizedId = cache.identify({
            id: deletedTeam.id,
            __typename: "teams",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    }
  };

  const cancel = () => {
    reset();
    toggleEdit();
  };

  const inHouse = watch("inHouse");

  const toggleInhouse = () => {
    setValue("inHouse", !inHouse);
  };

  // sub Teams

  const deleteSubTeam = (id: number) => {
    const subTeams = getValues("subTeams")?.filter(p => p !== id);
    setValue("subTeams", subTeams);
  };

  const addSubTeam = (id: number) => {
    const subTeams = getValues("subTeams")?.concat(id);
    setValue("subTeams", subTeams);
  };

  const subTeams = editing ? watch("subTeams") : team.subTeams;

  const excludedSubTeams = teams?.filter(
    p => !subTeams?.includes(p.id) && team.id !== p.id
  );

  // process Types

  const deleteProcessType = (id: number) => {
    const processTypes = getValues("processTypes")?.filter(p => p !== id);
    setValue("processTypes", processTypes);
  };

  const addProcessType = (id: number) => {
    const processTypes = getValues("processTypes")?.concat(id);
    setValue("processTypes", processTypes);
  };

  const processTypes = editing ? watch("processTypes") : team.processTypes;

  const excludedProcessTypes = process_type_data?.processType.filter(
    p => !processTypes?.includes(p.id)
  );

  // products

  const deleteProduct = (id: number) => {
    const products = getValues("products")?.filter(p => p !== id);
    setValue("products", products);
  };

  const addProduct = (id: number) => {
    const products = getValues("products")?.concat(id);
    setValue("products", products);
  };

  const products = editing ? watch("products") : team.products;

  const excludedProducts = data_products?.products.filter(
    p => !products?.includes(p.id)
  );

  const canEdit = checkAuth([
    "setting_team_edit",
    {
      permission: "setting_team_edit_same_team",
      checkGroup: "userTeam",
      conditionGroup: [team.id],
    },
  ]);

  return (
    <section className="rounded-md p-3 dark:bg-gray-700 bg-gray-100">
      {editing ? (
        <form onSubmit={onSubmit}>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="teamName" value="Team Name" />
            </div>
            <TextInput
              type="text"
              placeholder="change team Name"
              color={errors.name?.message ? "failure" : undefined}
              helperText={errors.name?.message || ""}
              {...register("name")}
            />
          </div>
          <div id="select" className="mt-2">
            <div className="mb-2 block">
              <Label htmlFor="teamType" value="Select Team Type" />
            </div>
            <Select
              required={true}
              {...register("teamType")}
              color={errors.teamType?.message ? "failure" : undefined}
              helperText={errors.teamType?.message || ""}
            >
              <option value={0}>
                {team_type_loading ? "...loading" : "select from the list"}
              </option>
              {data?.teamType?.map(teamType => (
                <option key={teamType.id} value={teamType.id}>
                  {teamType.name}
                </option>
              ))}
            </Select>
          </div>

          {teamType && teamType !== "manufacture" && (
            <>
              <div className="flex flex-row flex-wrap gap-2 my-4">
                <Label htmlFor="subTeams" value="Sub Teams" />

                {subTeams && teams ? (
                  subTeams.map(ap => {
                    const subTeam = teams.find(p => p.id == ap);
                    if (subTeam) {
                      return (
                        <div key={ap} className="relative">
                          <Badge color="purple">{subTeam.name}</Badge>
                          <MinusCircleIcon
                            onClick={() => {
                              deleteSubTeam(subTeam.id);
                            }}
                            className="w-5 cursor-pointer text-red-500 absolute top-[-8px] right-[-8px]"
                          />
                        </div>
                      );
                    }
                  })
                ) : (
                  <Badge>no subTeams</Badge>
                )}
              </div>

              {excludedSubTeams && excludedSubTeams.length > 0 && (
                <div className="flex flex-row flex-wrap p-3 mb-2 rounded-md border-[1px] border-gray-300 dark:border-gray-500 gap-2">
                  {excludedSubTeams.map(subTeam => (
                    <div key={subTeam.id} className="relative">
                      <Badge>{subTeam.name}</Badge>

                      <PlusCircleIcon
                        onClick={() => {
                          addSubTeam(subTeam.id);
                        }}
                        className="w-5 cursor-pointer text-green-500 absolute top-[-8px] right-[-8px]"
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {teamType && teamType == "manufacture" && (
            <>
              <div className="flex flex-row flex-wrap gap-2 my-4">
                <Label htmlFor="products" value="Products" />

                {products &&
                  data_products?.products &&
                  products.map(ps => {
                    const product = data_products?.products.find(
                      p => p.id == ps
                    );
                    if (product) {
                      return (
                        <div key={ps} className="relative">
                          <Badge color="purple">{product.name}</Badge>
                          <MinusCircleIcon
                            onClick={() => {
                              deleteProduct(product.id);
                            }}
                            className="w-5 cursor-pointer text-red-500 absolute top-[-8px] right-[-8px]"
                          />
                        </div>
                      );
                    }
                  })}
              </div>

              {excludedProducts && excludedProducts.length > 0 && (
                <div className="flex flex-row flex-wrap p-3 mb-2 rounded-md border-[1px] border-gray-300 dark:border-gray-500 gap-2">
                  {excludedProducts.map(product => (
                    <div key={product.id} className="relative">
                      <Badge>{product.name}</Badge>

                      <PlusCircleIcon
                        onClick={() => {
                          addProduct(product.id);
                        }}
                        className="w-5 cursor-pointer text-green-500 absolute top-[-8px] right-[-8px]"
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          <div className="flex flex-row flex-wrap gap-2 my-4">
            <Label htmlFor="processTypes" value="Process Types" />

            {processTypes &&
              process_type_data?.processType &&
              processTypes.map(ap => {
                const pType = process_type_data?.processType.find(
                  p => p.id == ap
                );
                if (pType) {
                  return (
                    <div key={ap} className="relative">
                      <Badge color="purple">{pType.name}</Badge>
                      <MinusCircleIcon
                        onClick={() => {
                          deleteProcessType(pType.id);
                        }}
                        className="w-5 cursor-pointer text-red-500 absolute top-[-8px] right-[-8px]"
                      />
                    </div>
                  );
                }
              })}
          </div>

          {excludedProcessTypes && excludedProcessTypes.length > 0 && (
            <div className="flex flex-row flex-wrap p-3 mb-2 rounded-md border-[1px] border-gray-300 dark:border-gray-500 gap-2">
              {excludedProcessTypes.map(pType => (
                <div key={pType.id} className="relative">
                  <Badge>{pType.name}</Badge>

                  <PlusCircleIcon
                    onClick={() => {
                      addProcessType(pType.id);
                    }}
                    className="w-5 cursor-pointer text-green-500 absolute top-[-8px] right-[-8px]"
                  />
                </div>
              ))}
            </div>
          )}

          <div id="select" className="mt-4">
            <div className="mb-2 block">
              <ToggleSwitch
                //@ts-expect-error
                color="purple"
                label="In House"
                checked={inHouse}
                onChange={toggleInhouse}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end gap-2 mt-4">
            <TrashIcon
              className="w-5 text-red-500 cursor-pointer"
              onClick={deleteTeam}
            />
            <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
              Cancel
            </Button>
            <Button
              gradientDuoTone="purpleToBlue"
              outline
              size="sm"
              type="submit"
            >
              {loading && (
                <div className="mr-3">
                  <Spinner size="sm" light={true} />
                </div>
              )}
              Save
            </Button>
          </div>
        </form>
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <h3 className="capitalize">{team.name}</h3>
            {canEdit && (
              <PencilIcon className="w-4 cursor-pointer" onClick={toggleEdit} />
            )}
          </div>
          <div className="flex flex-col gap-2 my-2">
            <Label htmlFor="type" value="Team Type" />
            <Badge color="purple" className="w-fit">
              {team.type?.name}
            </Badge>
          </div>
          <div className="flex flex-col gap-2 my-2">
            <Label htmlFor="inHouse" value="In House?" />
            <Badge
              color={team.inHouse ? "purple" : "failure"}
              className="w-fit"
            >
              {team.inHouse ? "true" : "false"}
            </Badge>
          </div>
          {teamType && teamType !== "manufacture" && (
            <>
              <Label htmlFor="subTeams" value="Sub Teams" />
              <div className="flex flex-row flex-wrap gap-2 my-2">
                {subTeams && subTeams?.length > 0 && teams ? (
                  subTeams.map(ap => {
                    const subTeam = teams.find(p => p.id == ap);
                    if (subTeam) {
                      return (
                        <div key={ap} className="relative">
                          <Badge color="purple">{subTeam.name}</Badge>
                        </div>
                      );
                    }
                  })
                ) : (
                  <Badge>no subTeams</Badge>
                )}
              </div>
              <Label htmlFor="processTypes" value="Manageable Process" />
              <div className="flex flex-row flex-wrap gap-2 my-2">
                {team.processTypes &&
                team.processTypes.length > 0 &&
                process_type_data &&
                process_type_data.processType ? (
                  team.processTypes.map(pt => {
                    const pType = process_type_data.processType.find(
                      p => p.id == pt
                    );
                    if (pType) {
                      return (
                        <div key={pt} className="relative">
                          <Badge color="purple">{pType.name}</Badge>
                        </div>
                      );
                    }
                  })
                ) : (
                  <Badge>none</Badge>
                )}
              </div>
            </>
          )}
          {teamType && teamType == "manufacture" && (
            <>
              <Label htmlFor="products" value="Products" />
              <div className="flex flex-row flex-wrap gap-2 my-2">
                {products && products?.length > 0 && data_products?.products ? (
                  products.map(ap => {
                    const product = data_products?.products.find(
                      p => p.id == ap
                    );
                    if (product) {
                      return (
                        <div key={ap} className="relative">
                          <Badge color="purple">{product.name}</Badge>
                        </div>
                      );
                    }
                  })
                ) : (
                  <Badge>no products</Badge>
                )}
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
}
