import { orderSummaryColumn } from "../../../workorder/types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_ORDER_SUMMARY_COLUMN } from "../../../workorder/gqls";
import { useOrderStore } from "../../../../../store/orderStore";
import { Badge } from "flowbite-react";
import {
  ChevronUpDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { sort } from "fast-sort";
import Addate from "./addate";

interface columnProps {
  index: number;
  isOverlay: boolean;
  column: orderSummaryColumn;
}

export default function Column({ index, isOverlay, column }: columnProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: column.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const [editing, setEditing] = useState(false);

  const [remove, { loading: deleting }] = useMutation(
    DELETE_ORDER_SUMMARY_COLUMN
  );

  const _delete = () => {
    const proceed = confirm("delete summary Column?");
    if (!proceed) {
      return;
    }
    remove({
      variables: {
        id: column.id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: column.id,
          __typename: "orderSummaryColumn",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const { productSets, productSetOptions } = useOrderStore();

  const options = productSetOptions
    .filter(po => column.options.includes(po.id))
    .map(p => ({
      ...p,
      productSet: productSets.find(ps => ps.id == p.productSetID),
    }));

  return (
    <>
      <div
        className={`flex flex-row ${
          index && index % 2 !== 0 && "dark:bg-gray-600 bg-gray-100"
        } ${isOverlay ? "text-grass" : ""}`}
        ref={setNodeRef}
        style={style}
      >
        <div className="w-10 py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {column.id}
        </div>
        <div className="w-40 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
          {column.name}
        </div>
        <div className="flex-1 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500 flex flex-row gap-2">
          {options &&
            sort(options)
              .asc("productSetID")
              .map(o => (
                <Badge key={o.id} color="purple">
                  {o?.productSet?.name}:{o.name}
                </Badge>
              ))}
        </div>
        <div
          className="w-16 group truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500 flex flex-row justify-between items-center"
          {...attributes}
          {...listeners}
        >
          {column.position}
          <ChevronUpDownIcon className="w-4 hidden group-hover:block" />
        </div>

        <div className="py-[5px] w-20">
          <div className="flex flex-row gap-3 w-full justify-center">
            <PencilIcon
              onClick={() => {
                setEditing(true);
              }}
              className="w-5 text-grass cursor-pointer"
            />
            <TrashIcon
              className="w-5 text-red-500 cursor-pointer"
              onClick={_delete}
            />
          </div>
        </div>
      </div>
      {/* Editing */}
      <Addate setShow={setEditing} show={editing} column={column} />
    </>
  );
}
