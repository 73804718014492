export const addDays = (date: string | Date, days: number) => {
  const D = new Date(date);
  D.setDate(D.getDate() + days);
  return D;
};

export const addHours = (date: string | Date, h: number) => {
  const D = new Date(date);
  D.setTime(D.getTime() + h * 60 * 60 * 1000);
  return D;
};

export const onlyDate = (date: string | Date) => {
  let dateOnly = new Date(date);
  dateOnly.setHours(0, 0, 0, 0);

  const NewDate = `${dateOnly.getFullYear()}-${
    dateOnly.getMonth() + 1
  }-${dateOnly.getDate()}`;

  return NewDate;
};

export const diffDateTime = (d1: Date, d2: Date, type?: string) => {
  const diffTime = Math.abs(new Date(d2).getTime() - new Date(d1).getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (type == "date") {
    return diffDays;
  } else {
    return diffTime / 60000;
  }
};

export const isSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const hoursToTimeStamp = (str: string) => {
  const arr = str.split(":");
  if (arr.length < 2) {
    return false;
  }

  const [hour, minute] = arr;

  return new Date(new Date().setHours(Number(hour), Number(minute)))?.getTime();
};
