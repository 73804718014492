import { NewPricingTable, pricingTableScheme } from "../types";
import { useState } from "react";
import { DocumentPlusIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  Label,
  Select,
  Spinner,
  Table,
  TextInput,
} from "flowbite-react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Columns from "./columns";
import Rows from "./rows";
import uuid from "react-uuid";
import { useMutation } from "@apollo/client";
import { ADD_PRICING_TABLE } from "../gqls";
import { PRICING_TABLE_FIELDS } from "../fragments";
import { useOrderStore } from "../../../../../store/orderStore";

export default function AddPricingTable() {
  const { productSets } = useOrderStore();

  const [adding, setAdding] = useState(false);

  const initialId = uuid();

  const defaultValues: NewPricingTable = {
    name: "All",
    condition: null,
    productSetId: 0,
    columns: [
      {
        id: initialId,
        width: 100,
      },
    ],
    rows: [
      {
        height: 100,
        prices: [
          {
            id: initialId,
            value: 50,
          },
        ],
      },
    ],
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(pricingTableScheme),
  });

  const { register, reset, control, handleSubmit } = methods;

  const { fields, remove, append } = useFieldArray({
    control,
    // @ts-ignore
    name: "columns",
  });

  const cancel = () => {
    reset();
    setAdding(false);
  };

  const [insert, { loading }] = useMutation(ADD_PRICING_TABLE);

  const submit = handleSubmit(data => {
    if (data.productSetId === 0) return;
    insert({
      variables: {
        object: data,
      },
      update(
        cache,
        { data: { insert_productSetPricingTable_one: newPricingTable } }
      ) {
        cache.modify({
          fields: {
            productSetPricingTable(existing = []) {
              const newTableRef = cache.writeFragment({
                data: newPricingTable,
                fragment: PRICING_TABLE_FIELDS,
                fragmentName: "PricingTableFields",
              });
              return [...existing, newTableRef];
            },
          },
        });
      },
      onCompleted() {
        reset();
        setAdding(false);
      },
    });
  });

  if (productSets.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      {!adding && (
        <div
          className="flex flex-row gap-1 justify-end cursor-pointer hover:text-plum"
          onClick={() => setAdding(true)}
        >
          <DocumentPlusIcon className="w-4" />
          <div>Add Pricing Table</div>
        </div>
      )}
      {adding && (
        <FormProvider {...methods}>
          <Card>
            <div className="flex flex-col gap-2">
              <h2>Add New Pricing Table</h2>

              <div className="flex flex-row gap-2 items-center justify-between">
                <Label value="Product Set" />
                <Select
                  title="productSetId"
                  sizing="sm"
                  {...register("productSetId")}
                >
                  <option value={0}>Select Product Set</option>
                  {productSets.map(ps => (
                    <option key={ps.id} value={ps.id}>
                      {ps.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="flex flex-row gap-2 items-center justify-between">
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  {...register("name")}
                  placeholder="Enter name"
                />
              </div>

              <div className="flex flex-row gap-2 items-center justify-between">
                <Label value="Condition" />
                <TextInput
                  sizing="sm"
                  {...register("condition")}
                  addon="f(x)"
                  placeholder="Optional Category Condition"
                />
              </div>

              <Table striped>
                <Columns fields={fields} remove={remove} />
                <Rows appendColumn={append} />
              </Table>

              <div className="flex flex-row justify-end gap-2 items-center mt-2">
                <Button size="xs" color="purple" onClick={cancel}>
                  cancel
                </Button>
                <Button
                  size="xs"
                  outline
                  gradientDuoTone="purpleToBlue"
                  onClick={submit}
                >
                  {loading && <Spinner size="xs" color="purple" />}
                  submit
                </Button>
              </div>
            </div>
          </Card>
        </FormProvider>
      )}
    </div>
  );
}
