import { gql } from "@apollo/client";
import { AUTH_CORE_FIELDS } from "../auths/roles/fragments";
import { TEAM_CORE_FIELDS } from "../teams/fragments";

export const USER_CORE_FIELDS = gql`
  ${AUTH_CORE_FIELDS}
  ${TEAM_CORE_FIELDS}
  fragment UserCoreFields on users {
    calId
    color
    created_at
    email
    firstName
    hours
    id
    sirName
    subUsers
    auth {
      ...AuthCoreFields
    }
    permissions
    team {
      ...TeamCoreFields
    }
    active
    checked
    mobile
    address
    baseLocation
  }
`;
