import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { sort } from "fast-sort";
import { uniqueById } from "../../../../utils/arrayObjMethods";
import {
  AssignmentArrayType,
  AssignmentStreamType,
} from "../../assignment/types";
import { processDetailSubProps } from "../types";
import { ASSIGNMENT_ESSENTIAL_FIELDS } from "../../assignment/fragments";
import AddAssignment from "./addAssignment";
import AssignmentItem from "./assignmentItem";

export const GET_PROCESS_ASSIGNMENT: TypedDocumentNode<AssignmentArrayType> = gql`
  ${ASSIGNMENT_ESSENTIAL_FIELDS}
  query GET_PROCESS_ASSIGNMENT($processId: Int!) {
    assignment(where: { processId: { _eq: $processId } }) {
      ...AssignmentEssentialFields
    }
  }
`;

export const SUB_PROCESS_ASSIGNMENT: TypedDocumentNode<AssignmentStreamType> = gql`
  ${ASSIGNMENT_ESSENTIAL_FIELDS}
  subscription SUB_PROCESS_ASSIGNMENT(
    $processId: Int!
    $cursor: [assignment_stream_cursor_input]!
  ) {
    assignment_stream(
      where: { processId: { _eq: $processId } }
      cursor: $cursor
      batch_size: 10
    ) {
      ...AssignmentEssentialFields
    }
  }
`;

export default function ProcessAssignment({ process }: processDetailSubProps) {
  const { data, loading, error, subscribeToMore } = useQuery(
    GET_PROCESS_ASSIGNMENT,
    {
      variables: {
        processId: process.id,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  if (error) {
    console.log(error);
  }

  const assignment = data?.assignment;

  subscribeToMore({
    document: SUB_PROCESS_ASSIGNMENT,
    variables: {
      processId: process.id,
      cursor: {
        initial_value: { updated_at: new Date().toISOString() },
        ordering: "ASC",
      },
    },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.assignment_stream;

      const existing = prev.assignment.filter(
        p => !newFeedItem.find(i => i.id == p.id)
      );

      // const filterDeleted = [...newFeedItem, ...existing].filter(
      //   log => !log.deleted
      // );

      return Object.assign({}, prev, {
        assignment: uniqueById([...newFeedItem, ...existing]),
      });
    },
  });

  return (
    <div
      className="col-span-1 
      @lg:row-span-6 supports-[not(container-type:inline-size)]:lg:row-span-6
     flex flex-col gap-2"
    >
      <h2 className="mb-2">Assignment</h2>
      <AddAssignment process={process} />

      {assignment &&
        sort(assignment)
          .by([
            { asc: t => t.type.priority },
            { asc: t => t.status.priority },
            { asc: t => t.created_at },
          ])
          .map(a => <AssignmentItem key={a.id} assignment={a} />)}
    </div>
  );
}
