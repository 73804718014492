import Nav from "./nav/nav";
import Header from "./nav/header";
import { Outlet } from "react-router-dom";
import { useNavStore } from "../../store/navStore";
import { useEffect, useRef, useState } from "react";
import { useScreen } from "usehooks-ts";
import "../react_virtualized.css";

export default function Container() {
  const { show, folded, setShow, setContainerRef } = useNavStore();

  const [scrollPosition, setScrollPosition] = useState(0);
  const screen = useScreen();

  useEffect(() => {
    if (screen && screen.width < 640) {
      setShow(false);
    }
  }, [screen?.width]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current == null) {
      return;
    }
    setContainerRef(containerRef.current);
  }, [containerRef.current]);

  const onMainClick = () => {
    setShow(false);
  };
  return (
    <div className="flex flex-row">
      <Nav />
      <div
        className={`@container h-screen relative flex-1 flex flex-col bg-gray-100 dark:bg-dark overflow-x-hidden overflow-y-auto border-l-[1px] dark:border-gray-700 md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700 md:dark:scrollbar-track-gray-800`}
        onScroll={e => {
          //@ts-expect-error
          setScrollPosition(e.target.scrollTop || 0);
        }}
        ref={containerRef}
      >
        <Header scrollPosition={scrollPosition} />
        <div
          className={`
          ${
            show &&
            !folded &&
            "blur-lg pointer-events-none overflow-hidden md:overflow-visible sm:pointer-events-auto sm:blur-none"
          } flex-1`}
        >
          <Outlet />
        </div>
        {show && !folded && (
          <div
            onClick={onMainClick}
            className="w-full h-full absolute block z-10 sm:hidden sm:pointer-events-none"
          />
        )}
      </div>
    </div>
  );
}
