import { ControllerRenderProps, FieldValues } from "react-hook-form";
import DynamicHeroIcon from "../../../../../../comps/hIcon";
import { useScheme } from "../../../../../../store/schemeStore";

interface ShowHideControlProps {
  field: ControllerRenderProps<FieldValues, any>;
}
export default function ShowHideControl({ field }: ShowHideControlProps) {
  const onClick = () => {
    field.onChange(!field.value);
  };

  const { scheme } = useScheme();

  return (
    <DynamicHeroIcon
      class={`cursor-pointer w-5 ${
        scheme == "dark" ? "text-white" : "text-black"
      } hover:text-grass `}
      icon={field.value ? "EyeSlashIcon" : "EyeIcon"}
      onClick={onClick}
    />
  );
}
