import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { TextInput } from "flowbite-react";
import { useRef, useState } from "react";
import { ToolbarProps } from "react-big-calendar";
import { useOnClickOutside } from "usehooks-ts";
import moment from "moment";

export default function CustomToolbar(toolbar: ToolbarProps) {
  const goToBack = () => {
    toolbar.onNavigate("PREV");
  };

  const goToNext = () => {
    toolbar.onNavigate("NEXT");
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setFullYear(now.getFullYear());
    toolbar.onNavigate("TODAY");
  };

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  return (
    <div className="flex flex-row justify-between items-center leading-[0] py-2 select-none">
      <ChevronDoubleLeftIcon
        className="hover:text-grass w-5 cursor-pointer"
        onClick={goToBack}
      />
      <div className="flex flex-row justify-center relative" ref={ref}>
        <div
          onClick={() => {
            setOpen(true);
          }}
          className="font-semibold text-lg hover:text-grass cursor-pointer"
        >
          {toolbar.label}
        </div>
        {open && (
          <div className="absolute p-1 z-10 top-full">
            <TextInput
              ref={inputRef}
              value={
                toolbar.view == "week" || toolbar.view == "agenda"
                  ? moment(toolbar.date).format("YYYY-[W]WW")
                  : dayjs(toolbar.date).format(
                      toolbar.view == "month" ? "YYYY-MM" : "YYYY-MM-DD"
                    )
              }
              onChange={e => {
                const date = moment(e.target.value).toDate();
                toolbar.onNavigate("DATE", date);
                setOpen(false);
              }}
              sizing="sm"
              type={
                toolbar.view == "month"
                  ? "month"
                  : toolbar.view == "week" || toolbar.view == "agenda"
                  ? "week"
                  : "date"
              }
            />
          </div>
        )}
      </div>
      <ChevronDoubleRightIcon
        className="hover:text-grass w-5 cursor-pointer"
        onClick={goToNext}
      />
    </div>
  );
}
