import { Breadcrumb } from "flowbite-react";
import { HomeIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import TaskStatuses from "./taskStatuses/taskStatuses";

export default function TaskRelated() {
  const navigate = useNavigate();
  return (
    <div className="mb-1 flex-grow mx-auto">
      <section className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-4">
        <nav className="w-full">
          <Breadcrumb className="w-full">
            <Breadcrumb.Item
              className="cursor-pointer"
              onClick={() => {
                navigate("../");
              }}
            >
              <HomeIcon className="w-5 mr-2" />
              Settings
            </Breadcrumb.Item>

            <Breadcrumb.Item>Task</Breadcrumb.Item>
          </Breadcrumb>
        </nav>
        <main className="space-y-4">
          <TaskStatuses />
        </main>
      </section>
    </div>
  );
}
