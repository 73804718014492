import {
  Badge,
  Button,
  Checkbox,
  Label,
  Select,
  TextInput,
} from "flowbite-react";
import { ProductType } from "../../products/types";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { ProductSetOrderPreset, optionProps } from "../../../workorder/types";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

interface props {
  products?: ProductType[];
  cancelCB?: () => void;
  submitCB?: (data: any) => void;
  defaultVals?: ProductSetOrderPreset;
  options?: optionProps[];
  calcs?: string[];
}

export default function ReleaseSettingEdit({
  products,
  cancelCB: cb,
  submitCB,
  defaultVals,
  options,
  calcs,
}: props) {
  const defaultValues: ProductSetOrderPreset = defaultVals || {
    name: "",
    includeComps: false,
    options: [],
    calcs: [],
    productId: 0,
    condition: undefined,
  };

  const methods = useForm({
    defaultValues,
  });

  const { register, handleSubmit, reset } = methods;

  const submit = handleSubmit(data => {
    if (submitCB) {
      submitCB({ ...data, productId: Number(data.productId) });
    }
    reset();
  });

  if (!products) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      {/* <h4 className="font-semibold">New Order Preset</h4> */}
      <div className="flex flex-col gap-2 items-start">
        <Label value="Name" />
        <TextInput sizing="sm" {...register("name")} />
      </div>
      <div className="flex flex-col gap-2 items-start">
        <Label value="Product Category" />
        <Select sizing="sm" {...register("productId")}>
          {products?.map(p => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </Select>
      </div>
      <Options options={options} />
      <Calcs calcs={calcs} />
      <div className="flex flex-row gap-2 items-start">
        <Label value="Include Comps Order" />
        <Checkbox
          className="checked:bg-plum dark:checked:bg-plum cursor-pointer"
          {...register("includeComps")}
        />
      </div>
      <div className="flex flex-col gap-2 items-start">
        <Label value="Condition" />
        <TextInput sizing="sm" {...register("condition")} />
      </div>
      <hr />
      <div className="flex flex-row justify-end gap-2 items-center">
        <Button
          onClick={() => {
            if (cb) {
              cb();
            }
            reset();
          }}
          size="xs"
          color="purple"
        >
          cancel
        </Button>
        <Button
          size="xs"
          gradientDuoTone="purpleToBlue"
          outline
          onClick={submit}
        >
          {defaultVals ? "update" : "add"}
        </Button>
      </div>
    </FormProvider>
  );
}

interface calcsProps {
  options?: optionProps[];
}
const Options = ({ options }: calcsProps) => {
  const { control } = useFormContext();

  const { append, remove } = useFieldArray({
    name: "options",
    control,
  });

  const addedOptions: number[] =
    useWatch({
      name: "options",
      control,
    }) || [];

  const defaultValues: {
    id: undefined | string;
  } = {
    id: undefined,
  };

  const { register, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const add = handleSubmit(data => {
    const id = data.id;
    if (!id) {
      return;
    }
    append(Number(id));
    setValue("id", undefined);
  });

  if (!options) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 items-start">
      <Label value="Options" />
      <div className="flex flex-row items-center gap-2">
        <Select {...register("id")} sizing="sm">
          <option value={undefined}>select option to add</option>
          {options
            ?.filter(o => !addedOptions?.includes(o.id))
            .map(opt => (
              <option key={opt.id} value={opt.id}>
                {opt.name}
              </option>
            ))}
        </Select>
        <PlusCircleIcon
          className="w-5 cursor-pointer text-plum"
          onClick={add}
        />
      </div>
      <div className="flex flex-row gap-2 flex-wrap">
        {addedOptions.map((opt, i) => {
          const option = options?.find(o => o.id == opt);
          return (
            <Badge
              className="cursor-pointer"
              color="purple"
              key={opt}
              onClick={() => {
                remove(i);
              }}
            >
              {option?.name}
            </Badge>
          );
        })}
      </div>
    </div>
  );
};

interface calcsProps {
  calcs?: string[];
}
const Calcs = ({ calcs }: calcsProps) => {
  const { control } = useFormContext();

  const { append, remove } = useFieldArray({
    name: "calcs",
    control,
  });

  const addedCalcs: string[] =
    useWatch({
      name: "calcs",
      control,
    }) || [];

  const defaultValues: {
    name: undefined | string;
  } = {
    name: undefined,
  };

  const { register, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const add = handleSubmit(data => {
    const name = data.name;
    if (!name) {
      return;
    }
    append(name);
    setValue("name", undefined);
  });

  return (
    <div className="flex flex-col gap-2 items-start">
      <Label value="Calcs" />
      <div className="flex flex-row items-center gap-2">
        <Select {...register("name")} sizing="sm">
          <option value={undefined}>select calc to add</option>
          {calcs
            ?.filter(c => !addedCalcs?.includes(c))
            .map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
        </Select>
        <PlusCircleIcon
          className="w-5 cursor-pointer text-plum"
          onClick={add}
        />
      </div>
      <div className="flex flex-row gap-2 flex-wrap">
        {addedCalcs.map((name, i) => (
          <Badge
            className="cursor-pointer"
            color="purple"
            key={name}
            onClick={() => {
              remove(i);
            }}
          >
            {name}
          </Badge>
        ))}
      </div>
    </div>
  );
};
