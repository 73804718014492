import { useQuery } from "@apollo/client";
import { OrderType } from "./types";
import { GET_PRODUCT_SETS } from "./gqls";
import { all, create } from "mathjs";
import { option } from "./createOrder/types";
import { MaterialsType } from "../materials/types";
import isNullish from "../../../utils/isNullish";

export default function useGetOrderProducts() {
  const { data } = useQuery(GET_PRODUCT_SETS);
  const productSets = data?.productSets;

  const math = create(all);

  math.SymbolNode.onUndefinedSymbol = e => {
    // console.log(e);
    return 0;
  };

  return (order: OrderType) => {
    const locations = order.order.locations.map((l, i) => ({
      ...l,
      index: i + 1,
      openings: l.openings.map((o, ix) => ({
        ...o,
        items: o.items.map((item, iz) => ({ ...item, index: iz + 1 })),
        index: ix + 1,
      })),
    }));

    const openings = locations?.map(l => l.openings).flat();

    const products = openings?.reduce<
      {
        name: string;
        qty: number;
        openingProduct: boolean;
        img: string;
        sqm: number;
      }[]
    >((prev, cur) => {
      const Product = productSets?.find(p => p.id == cur.product);
      if (!Product) {
        return prev;
      }

      const name = Product?.name;
      const isOpeningProduct = Product.hideItems;

      const openingOptions: option[] = cur?.options || [];
      const openingScopes = openingOptions?.reduce<any>((prv, curOption) => {
        if (!curOption || !curOption.name) {
          return prev;
        }

        const scopeName = curOption?.name.replaceAll(" ", "");
        let appended = {
          ...prv,
          [scopeName]: curOption?.value,
        };
        if (curOption.source == "inventory") {
          const material = curOption.populatedValue as MaterialsType;
          if (material) {
            appended = {
              ...appended,
              [`${scopeName}_W`]: isNullish(material.widthDeduction)
                ? 0
                : Number(material.widthDeduction),
              [`${scopeName}_H`]: isNullish(material.heightDeduction)
                ? 0
                : Number(material.heightDeduction),
              [`${scopeName}_Size`]: isNullish(material.size)
                ? 0
                : Number(material.size),
            };
          }
        }
        return appended;
      }, {});

      const qty = Number(
        isOpeningProduct
          ? math.evaluate(Product.qtyCalc || "1", openingScopes)
          : cur.items.reduce<number>((prev, cur) => {
              const itemOptions: option[] = cur?.options || [];
              const itemScopes = itemOptions?.reduce<any>((prv, curOption) => {
                if (!curOption || !curOption.name) {
                  return prev;
                }

                const scopeName = curOption?.name.replaceAll(" ", "");
                let appended = {
                  ...prv,
                  [scopeName]: curOption?.value,
                };
                if (curOption.source == "inventory") {
                  const material = curOption.populatedValue as MaterialsType;
                  if (material) {
                    appended = {
                      ...appended,
                      [`${scopeName}_W`]: isNullish(material.widthDeduction)
                        ? 0
                        : Number(material.widthDeduction),
                      [`${scopeName}_H`]: isNullish(material.heightDeduction)
                        ? 0
                        : Number(material.heightDeduction),
                      [`${scopeName}_Size`]: isNullish(material.size)
                        ? 0
                        : Number(material.size),
                    };
                  }
                }
                return appended;
              }, {});
              return prev + math.evaluate(Product.qtyCalc || "1", itemScopes);
            }, 0)
      );

      const width =
        isOpeningProduct && cur.options?.find(o => o?.name == "W")?.value;

      const height =
        isOpeningProduct && cur.options?.find(o => o?.name == "H")?.value;

      const openingSqm = Number(((width * height) / 1000000).toFixed(2));

      let itemsSqm = 0;

      if (!isOpeningProduct) {
        for (const item of cur.items) {
          const { width, height } = item;
          itemsSqm = itemsSqm + Number(((width * height) / 1000000).toFixed(2));
        }
      }

      const sqm = openingSqm + itemsSqm;

      if (prev.find(p => p?.name == name)) {
        return prev.map(pro => {
          if (pro?.name == name) {
            return { ...pro, qty: pro.qty + qty, sqm: pro.sqm + sqm };
          } else return pro;
        });
      } else {
        return prev.concat({
          name,
          qty,
          sqm,
          openingProduct: Product.hideItems,
          img: Product.product.image,
        });
      }
    }, []);

    return products;
  };
}
