import { create } from "zustand";
import { UserType } from "../routes/protected/settings/users/types";
import { CalendarType } from "../types/calendarTypes";

export type view = "month" | "week" | "day" | "agenda";

type Store = {
  filters: {
    userFilter: UserType[];
  };
  setFilters: (filters: any) => void;
  setUserFilter: (users: UserType[]) => void;
  date: Date;
  setDate: (date: Date) => void;
  view: view;
  setView: (view: view) => void;
  calendars: CalendarType[];
  setCalendars: (schedules: CalendarType[]) => void;
  concatCalendars: (schedules: CalendarType[]) => void;
  mergeCalendars: (schedules: CalendarType[], force?: boolean) => void;
  evictCalendar: (calId: string) => void;
};

const mergeItems = (
  existing: CalendarType["events"],
  incoming: CalendarType["events"]
) => {
  const items = existing
    .map(item => {
      const newItem = incoming.find(di => di.id == item.id);
      if (newItem) {
        if (newItem.status == "cancelled") {
          return {
            ...item,
            status: "cancelled",
          };
        } else {
          return newItem;
        }
      } else {
        return item;
      }
    })
    .concat(incoming.filter(i => !existing.find(ci => ci.id == i.id)));

  return items;
};

export const useScheduleStore = create<Store>((set, get) => ({
  filters: {
    userFilter: [],
  },
  setFilters(filters) {
    return set(state => ({
      ...state,
      filters,
    }));
  },
  date: new Date(),
  setDate(date) {
    return set(state => ({
      ...state,
      date,
    }));
  },
  view: "agenda",
  setUserFilter(users) {
    const currentFilters = get().filters;

    const newFilter = {
      ...currentFilters,
      userFilter: users,
    };
    localStorage.setItem("scheduleFilters", JSON.stringify(newFilter));

    return set(state => ({
      ...state,
      filters: newFilter,
    }));
  },
  setView(view) {
    return set(state => ({
      ...state,
      view,
    }));
  },
  calendars: [],
  setCalendars(calendars) {
    return set(state => ({
      ...state,
      calendars: calendars,
    }));
  },
  concatCalendars(calendars) {
    return set(state => ({
      ...state,
      calendars: state.calendars.concat(
        calendars.filter(cal =>
          state.filters.userFilter.find(u => u.calId == cal.calId)
        )
      ),
    }));
  },
  evictCalendar(calId) {
    return set(state => ({
      ...state,
      calendars: state.calendars.filter(cal => cal.calId !== calId),
    }));
  },
  mergeCalendars(calendars, force) {
    return set(state => {
      const existingFiltered = state.calendars.map(cal => {
        const matchingIncomingCalendar = calendars.find(
          c => c.calId == cal?.calId
        );
        return {
          ...cal,
          events: mergeItems(
            cal?.events || [],
            matchingIncomingCalendar?.events || []
          ),
        };
      });

      const remainingCalendars = calendars
        .filter(cal => !existingFiltered.find(ef => ef?.calId == cal.calId))
        .filter(
          cal =>
            force || state.filters.userFilter.find(u => u.calId == cal.calId)
        );

      const merged = existingFiltered.concat(remainingCalendars);

      return {
        ...state,
        calendars: merged,
      };
    });
  },
}));
