import { useMutation, gql } from "@apollo/client";
import { Button, Spinner, TextInput, Label } from "flowbite-react";
import { NewContactTypeType, contactTypeSchema } from "./types";
import { CONTACT_TYPE_CORE_FIELDS } from "./fragments";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addAlert } from "../../../../../store/alertStore";

export const INSERT_CONTACT_TYPE = gql`
  ${CONTACT_TYPE_CORE_FIELDS}
  mutation INSERT_CONTACT_TYPE($name: String!, $color: String!) {
    insert_contactType_one(object: { name: $name, color: $color }) {
      ...ContactTypeCoreFields
    }
  }
`;

export default function AddContactType({
  toggleAdding,
}: {
  toggleAdding: () => void;
}) {
  const [insert_contactType, { loading }] = useMutation(INSERT_CONTACT_TYPE);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewContactTypeType>({
    resolver: zodResolver(contactTypeSchema),
    defaultValues: {
      name: "",
      color: undefined,
    },
  });

  const onSubmit = handleSubmit(async data => {
    insert_contactType({
      variables: { ...data, name: data.name.toLowerCase().replaceAll(" ", "") },
      update(cache, { data: { insert_contactType_one: newContactType } }) {
        cache.modify({
          fields: {
            contactType(existingContactTypes = []) {
              const newContactTypeRef = cache.writeFragment({
                data: newContactType,
                fragment: CONTACT_TYPE_CORE_FIELDS,
              });
              return [...existingContactTypes, newContactTypeRef];
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          message: "New Type successfully added",
          type: "success",
        });
        toggleAdding();
        reset();
      },
      onError(error) {
        console.log(error);

        addAlert({
          message: "something went wrong cannot add type",
          type: "failure",
        });
      },
    });
  });

  const cancel = () => {
    reset();
    toggleAdding();
  };

  return (
    <form
      onSubmit={onSubmit}
      className="rounded-md p-3 dark:bg-gray-700 bg-gray-100 mb-5 space-y-2"
    >
      <div>
        <Label htmlFor="name" value="Name" />
        <TextInput
          type="text"
          placeholder="Enter the name for new type no space allowed"
          {...register("name")}
          color={errors.name?.message ? "failure" : undefined}
          helperText={errors.name?.message || ""}
        />
      </div>

      <div>
        <Label htmlFor="color" value="Color" />
        <input
          type="color"
          {...register("color")}
          className="w-full h-10 border-2 p-0 bg-transparent border-none"
        />
        <div> {errors.color?.message || ""}</div>
      </div>

      <div className="flex flex-row justify-end gap-2">
        <Button gradientDuoTone="purpleToBlue" size="sm" onClick={cancel}>
          Cancel
        </Button>
        <Button gradientDuoTone="purpleToBlue" outline size="sm" type="submit">
          {loading && (
            <div className="mr-3">
              <Spinner size="sm" light={true} />
            </div>
          )}
          Add
        </Button>
      </div>
    </form>
  );
}
