import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { ProductSetType } from "../../../../workorder/types";
import { useEffect } from "react";
import { useOrderStore } from "../../../../../../store/orderStore";
import { Button } from "flowbite-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT_SET_BY_PK } from "../../../../workorder/gqls";
import NewProductSetWarning from "./newProductSetWarning";
import ProductSetWarningControls from "./productSetWarningControls";
import { TrashIcon } from "@heroicons/react/24/solid";

interface props {
  productSetId: number;
}

const resolver = z.object({
  warnings: z.array(
    z.object({
      where: z.string(),
      condition: z.string(),
      message: z.string(),
    })
  ),
});

export default function ProductSetWarnings({ productSetId }: props) {
  const { productSets } = useOrderStore();

  const exWarnings = productSets?.find(
    productSet => productSet.id === productSetId
  )?.warnings;

  const defaultValues: { warnings: ProductSetType["warnings"] } = {
    warnings: [],
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(resolver),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const [update] = useMutation(UPDATE_PRODUCT_SET_BY_PK);

  const onSubmit = handleSubmit(({ warnings }) => {
    update({
      variables: {
        id: productSetId,
        set: {
          warnings,
        },
      },
      onCompleted() {
        reset({ warnings });
      },
    });
  });

  const cancel = () => {
    reset();
  };

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "warnings",
  });

  useEffect(() => {
    if (exWarnings) {
      reset({ warnings: exWarnings });
      replace(exWarnings);
    }
  }, [exWarnings]);

  const add = (warning: ProductSetType["warnings"][number]) => {
    append(warning);
  };

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-2" onSubmit={onSubmit}>
        {/* Title, Add */}
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-semibold">Warnings</h3>
          <NewProductSetWarning add={add} productSetId={productSetId} />
        </div>
        {/* List */}
        {fields.length > 0 && (
          <section className="group border-[1px] shadow-sm bg-white dark:bg-gray-800 dark:border-gray-700 rounded-md flex flex-col gap-2 p-2 relative">
            {fields.map((field, index) => (
              <div key={field.id} className="flex flex-row gap-2">
                <ProductSetWarningControls
                  productSetId={productSetId}
                  registerWhere={register(`warnings.${index}.where`)}
                  registerCondition={register(`warnings.${index}.condition`)}
                  registerMessage={register(`warnings.${index}.message`)}
                />
                <TrashIcon
                  className="w-5 text-red-500 cursor-pointer"
                  onClick={() => {
                    remove(index);
                  }}
                />
              </div>
            ))}
          </section>
        )}
        {/* Submit, Cancel */}
        {isDirty && (
          <div className="flex flex-row justify-end gap-2">
            <Button size="xs" color="purple" onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              size="xs"
              gradientDuoTone="purpleToBlue"
              outline
            >
              Save
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
}
