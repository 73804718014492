import { opening } from "../../types";
import { useOrderStore } from "../../../../../../store/orderStore";
import isNullish from "../../../../../../utils/isNullish";

interface props {
  opening: opening;
  locationIndex: number | string;
  singleLocation: boolean;
  noPrice?: boolean;
  hasItemDiscounts?: boolean;
  separateInstall?: boolean;
  gstInclusive?: boolean;
}

export default function CustomSummaryItem({
  opening,
  locationIndex,
  singleLocation,
  noPrice,
  hasItemDiscounts,
  separateInstall,
  gstInclusive,
}: props) {
  const { orderSummaryColumn } = useOrderStore();

  const index = `${singleLocation ? "" : `${locationIndex}.`}${opening.index}`;

  const tdStyle = "px-2 text-center text-[9pt] font-semibold";

  const noSize =
    isNullish(opening.width, true) && isNullish(opening.height, true);

  return (
    <tr className="h-[22px]">
      <td className="w-[10px] bg-[#c1aa80]" />
      <td className={`${tdStyle} text-center px-1`}>{index}</td>
      <td className={tdStyle}>{opening.name || ""}</td>
      {!noSize && (
        <>
          <td colSpan={2} className={tdStyle}>
            Other
          </td>
          <td className={tdStyle}>{opening.width}</td>
          <td className={tdStyle}>{opening.height}</td>
        </>
      )}
      <td
        className={`${tdStyle} ${noSize && "text-center"}`}
        colSpan={orderSummaryColumn.length + (noSize ? 5 : 0)}
      >
        {opening.customDesc}
      </td>
      {!noSize && (
        <td className={tdStyle}>
          {Math.ceil(
            (Number(opening.width) * Number(opening.height)) / 1000000
          )}
        </td>
      )}
      {!noPrice && (
        <>
          {separateInstall && (
            <td className={tdStyle}>
              $
              {Number(
                gstInclusive ? Number(opening.install) * 1.1 : opening.install
              )
                .toFixed(1)
                .replace(".0", "")}
            </td>
          )}
          <td className={tdStyle}>
            $
            {Number(gstInclusive ? Number(opening.price) * 1.1 : opening.price)
              .toFixed(1)
              .replace(".0", "")}
          </td>
          {hasItemDiscounts && (
            <td className={`${tdStyle} text-red-500`}>
              {opening.salesParams?.discount
                ? `
            $
            ${Number(
              gstInclusive
                ? Number(opening.salesParams?.discount) * 1.1
                : opening.salesParams?.discount
            )
              .toFixed(1)
              .replace(".0", "")}
            `
                : ""}
            </td>
          )}
        </>
      )}
      <td className="w-[10px] bg-[#c1aa80]" />
    </tr>
  );
}
