import { HomeIcon, PencilIcon } from "@heroicons/react/24/solid";
import {
  Badge,
  Breadcrumb,
  Button,
  Checkbox,
  Label,
  ListGroup,
  Spinner,
  Textarea,
} from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthStore } from "../../../store/authStore";
import getContrast from "../../../utils/getContrast";
import checkAuth from "../../../utils/checkAuth";
import { useOnClickOutside } from "usehooks-ts";
import { sort } from "fast-sort";
import { GET_PRODUCTION_BY_PK } from "./gql";
import {
  GET_PRODUCTION_STATUSES_BY_PID,
  GET_PRODUCTION_STATUSES_NO_PID,
} from "../settings/production/productionStatuses/productionStatuses";
import { fullNumber } from "../../../utils/fullNumber";
import { createPathArr, exists, mkdir } from "../../../utils/fileAPI";
import Folder from "../shared/files/folder";
import { UPDATE_PRODUCTION_BY_PID } from "../process/processDetail/production";
import { GET_RELEASED_ORDERS_CONDITIONAL } from "../workorder/orderRelease/gqls";
const ISDEVSTR = import.meta.env.VITE_FILE_TEST;
const ISDEV = ISDEVSTR == "TRUE";

export default function ProductionDetail() {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const { id } = useParams();

  const [update_production, { loading: updating }] = useMutation(
    UPDATE_PRODUCTION_BY_PID
  );

  const { data: data_status } = useQuery(GET_PRODUCTION_STATUSES_NO_PID);

  const statuses = data_status?.productionStatus
    ? sort(data_status?.productionStatus).asc(stat => stat.priority)
    : [];

  const { data, loading } = useQuery(GET_PRODUCTION_BY_PK, {
    variables: { id },
  });

  const production = data?.productions_by_pk;
  const process = production?.process;

  const { data: data_checkList } = useQuery(GET_PRODUCTION_STATUSES_BY_PID, {
    variables: { id: production?.product.id },
  });
  const checkList = data_checkList?.productionStatus;

  const [fileChecking, setFileChecking] = useState(false);
  const [path, setPath] = useState<undefined | string[]>(undefined);

  const initFiles = async () => {
    if (!process) {
      return;
    }
    setFileChecking(true);

    const jobPath = createPathArr(process);

    if (!jobPath) {
      setFileChecking(false);
      return;
    }

    const myPath = jobPath.concat("production", production.id.toString());

    const checkPath = await exists(myPath);

    if (!checkPath) {
      await mkdir(myPath);
      setFileChecking(false);
    } else {
      setFileChecking(false);
    }
    setPath(myPath);
  };

  useEffect(() => {
    if (!process) {
      return;
    }
    initFiles();
  }, [process?.id]);

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const defaultValues = useCallback(
    () => ({
      description: production?.description || "",
    }),
    [production]
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: defaultValues(),
    // resolver: zodResolver(resolver),
  });

  useEffect(() => {
    reset();
  }, [production]);

  const onSubmit = handleSubmit(async data => {
    if (!isDirty || !production) {
      return;
    }
    const { id } = production;
    const { description } = data;

    update_production({
      variables: {
        id,
        set: {
          description,
        },
      },
      onCompleted() {
        setEditing(false);
      },
    });
  });

  useEffect(() => {
    const defaultValues = {
      description: production?.description || "",
    };
    reset(defaultValues);
  }, [production]);

  const cancel = () => {
    setEditing(false);
    reset();
  };

  const canEdit = checkAuth("production_edit");

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    canEdit && setOpen(!open);
  };

  const ref = useRef<HTMLFormElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const changeStat = (id: number) => {
    if (!production) {
      return;
    }

    update_production({
      variables: {
        id: production.id,
        set: {
          statusID: id,
        },
      },
    });
    toggleOpen();
  };

  const goToJob = () => {
    if (!process) {
      return;
    }
    const checkGroup = user?.team?.processTypes || [];
    checkAuth([
      "process_access_all",
      {
        permission: "process_access_self",
        checkGroup,
        conditionGroup: [process.processType.id],
      },
    ]) && navigate("/process/detail/" + process.id + "/prog");
  };

  const check = (num: number) => {
    if (!canEdit) {
      return;
    }
    const progress: number[] = production?.progress || [];

    const newProgress = progress.includes(num)
      ? progress.filter(p => p !== num)
      : progress.concat(num);
    update_production({
      variables: {
        id,
        set: {
          progress: newProgress,
        },
      },
      optimisticResponse: {
        update_productions_by_pk: {
          __typename: "productions",
          ...production,
          progress: newProgress,
        },
      },
    });
  };

  const { data: data_myOrder } = useQuery(GET_RELEASED_ORDERS_CONDITIONAL, {
    variables: {
      where: {
        productionId: { _eq: production?.id },
      },
    },
  });

  const myOrderId = data_myOrder?.releasedOrders[0]?.id;

  const print = () => {
    navigate(`/production/print/${production?.id}/${myOrderId}`);
  };

  return (
    <main className="px-6 pb-10 select-none flex flex-col h-full">
      <nav className="w-full">
        <Breadcrumb className="w-full select-none shadow-md bg-white dark:bg-gray-800 p-4 rounded-md ">
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => {
              navigate("../");
            }}
          >
            <HomeIcon className="w-5 mr-2" />
            Production
          </Breadcrumb.Item>
          <Breadcrumb.Item className="capitalize">
            {production?.process?.name || production?.id}
          </Breadcrumb.Item>
        </Breadcrumb>
      </nav>
      {loading && !production && (
        <div className="flex-1 flex justify-center items-center">
          <Spinner size="xl" light color="purple" />
        </div>
      )}

      {production && (
        <article className="mt-3 mx-auto w-full max-w-[800px]">
          <div className="flex flex-row items-center gap-2">
            <h2 className="capitalize">{/* Name? */}</h2>
          </div>
          <div className="mt-2">
            <form
              ref={ref}
              onSubmit={onSubmit}
              className="space-y-2 flex flex-col"
            >
              <div className="flex flex-row items-center gap-2 justify-between @md:justify-start"></div>
              <h2 className="truncate">
                {production.process?.name || production.description}
              </h2>
              <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 flex flex-col gap-2 flex-1">
                <div className="flex flex-row justify-between items-center">
                  <Label value="Production Id" />

                  {id}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Job Id" />
                  <div
                    className="cursor-pointer hover:text-plum"
                    onClick={goToJob}
                  >
                    {production.process
                      ? fullNumber(
                          production.process.processType.prefix,
                          production.process.year,
                          production.process.number,
                          production.process.salesRepUser
                        )
                      : "not associated"}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Badge" />
                  {production.badge ? production.badge : "none"}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Product" />
                  <div className="flex flex-row gap-2 items-center">
                    {production.product.image && (
                      <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                        <img src={production.product.image} className="w-3" />
                      </div>
                    )}
                    {production.product.name}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Qty" />
                  {production.qty}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Released" />
                  {production.created_at
                    ? dayjs(production.created_at).format("DD/MM/YYYY")
                    : "Nill"}
                </div>
                <div className="flex flex-row justify-between items-center capitalize">
                  <Label value="Released By" />
                  {production.user.firstName} {production.user.sirName}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Due" />
                  {production.due
                    ? dayjs(production.due).format("DD/MM/YYYY")
                    : "Nill"}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <Label value="Address" />
                  {process?.address && process.address}
                </div>
                <div className="flex flex-row justify-between items-center my-1">
                  <Label value="Status" />
                  <div className="relative">
                    <Badge
                      style={{
                        color: getContrast(production.productionStatus.color),
                        backgroundColor: production.productionStatus.color,
                      }}
                      size="sm"
                      onClick={toggleOpen}
                      className={`w-fit hover:brightness-125 cursor-pointer ${
                        updating && "opacity-50"
                      }`}
                    >
                      {production.productionStatus.name}
                    </Badge>
                    {open && (
                      <ListGroup className="absolute right-0 z-10 mt-2">
                        {statuses
                          ?.filter(
                            stat => stat.id !== production.productionStatus?.id
                          )
                          .map(stat => (
                            <ListGroup.Item
                              onClick={() => {
                                changeStat(stat.id);
                              }}
                              key={stat.id}
                            >
                              <Badge
                                style={{
                                  backgroundColor: stat.color,
                                  color: getContrast(stat.color),
                                }}
                              >
                                {stat.name}
                              </Badge>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <Label className="flex-1" value="Progress" />
                  <div className="flex flex-row gap-3 mt-2 flex-wrap">
                    {checkList &&
                      checkList.map(stat => (
                        <div
                          key={stat.id}
                          onClick={() => {
                            check(stat.id);
                          }}
                          className="hover:text-plum cursor-pointer"
                        >
                          <Checkbox
                            className="checked:bg-plum dark:checked:bg-plum"
                            checked={
                              production.progress &&
                              production.progress.find(pr => pr == stat.id)
                                ? true
                                : false
                            }
                            readOnly
                          />{" "}
                          {stat.name}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div
                    onClick={() => {
                      setEditing(true);
                    }}
                    className="flex flex-row justify-between"
                  >
                    <Label className="flex-1" value="Desc" />
                    {!editing && canEdit && (
                      <PencilIcon className="w-4 hover:color-grass cursor-pointer" />
                    )}
                  </div>
                  <Textarea
                    disabled={!editing || loading}
                    style={{ opacity: 1 }}
                    className="resize-none text-sm"
                    {...register("description")}
                  />
                  {editing && (
                    <div className="flex flex-row justify-end gap-2">
                      <Button size="xs" color="purple" onClick={cancel}>
                        cancel
                      </Button>
                      <Button
                        size="xs"
                        gradientDuoTone="purpleToBlue"
                        type="submit"
                        outline
                      >
                        {updating && (
                          <Spinner size="xs" light className="mr-1" />
                        )}
                        update
                      </Button>
                    </div>
                  )}
                </div>
                {myOrderId && (
                  <div className="flex flex-row justify-center items-center relative w-full my-1">
                    <Button
                      outline
                      size="sm"
                      gradientDuoTone="purpleToBlue"
                      className="z-10"
                      onClick={print}
                    >
                      View Order
                    </Button>
                    <hr className="border-plum w-full absolute" />
                  </div>
                )}

                {/* Files */}
                {fileChecking && (
                  <div className="flex flex-row justify-center mt-2">
                    <Spinner size="lg" />
                  </div>
                )}
                {path && !fileChecking && (
                  <div className="flex flex-col gap-2">
                    <Label value="Files" />
                    <div className="dark:bg-gray-700 bg-gray-100 rounded-md px-2 py-3 border-[1px] dark:border-gray-600 border-gray-300">
                      <Folder path={path} simple={true} />
                    </div>
                  </div>
                )}
                <div className="flex justify-end flex-row items-center gap-2 mt-2">
                  {/* {!editing && canEdit && (
                    <Button
                      size="xs"
                      gradientDuoTone="purpleToBlue"
                      onClick={toggleEditing}
                      outline
                    >
                      <PencilIcon className="w-3 mr-2" /> Edit
                    </Button>
                  )} */}
                </div>
              </div>
            </form>
          </div>
        </article>
      )}
    </main>
  );
}
