import { ProcessFilter } from "./types";
import Sorter from "./sorter";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
  TouchSensor,
  DragOverlay,
  Active,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface props {
  sorters: ProcessFilter[];
  newFilters: ProcessFilter[];
  setNewFilters: Dispatch<SetStateAction<ProcessFilter[]>>;
  setTouched: (bool: boolean) => void;
  touched: boolean;
}
export default function Sorters({
  sorters: _sorters,
  newFilters,
  setNewFilters,
  setTouched,
  touched,
}: props) {
  const [theActive, setActive] = useState<ProcessFilter | undefined>();

  const [sorters, setSorters] = useState(_sorters);

  useEffect(() => {
    if (!touched || sorters.length == 0) {
      setSorters(_sorters);
    }
  }, [touched]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active.data.current as ProcessFilter);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sorters.findIndex(
        ({ sort }) => sort?.priority === active.id
      );
      const overIndex = sorters.findIndex(
        ({ sort }) => sort?.priority === over.id
      );

      setSorters(items => {
        return arrayMove(items, activeIndex, overIndex);
      });

      const movedArray = arrayMove(sorters, activeIndex, overIndex).map(
        (sorter, i) => ({
          ...sorter,
          sort: {
            ...sorter.sort,
            priority: i + 1,
          },
        })
      );

      const _filters: ProcessFilter[] = newFilters.map(f => {
        if (f.sort) {
          const movedSorter = movedArray.find(ma => f.where == ma.where);
          return movedSorter as ProcessFilter;
        } else {
          return f;
        }
      });
      setNewFilters(_filters);
      setTouched(true);
    }
    setActive(undefined);
  };

  const handleDragCancel = () => {
    setActive(undefined);
  };

  return (
    <div className="flex flex-col gap-2 ">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToWindowEdges]}
      >
        <SortableContext
          items={sorters.map(s => s.sort?.priority || 1)}
          strategy={verticalListSortingStrategy}
        >
          {sorters.map(s => (
            <Sorter
              key={s.sort?.priority}
              sorter={s}
              setSorters={setSorters}
              setNewFilters={setNewFilters}
              setTouched={setTouched}
            />
          ))}
        </SortableContext>
        {createPortal(
          <DragOverlay>
            {theActive !== undefined && (
              <Sorter
                setNewFilters={setNewFilters}
                setSorters={setSorters}
                sorter={theActive}
                setTouched={setTouched}
              />
            )}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  );
}
