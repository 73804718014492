import dayjs from "dayjs";
import { sort } from "fast-sort";
import { useState } from "react";
import { BreifEvent } from "../../../../../types/calendarTypes";
import { diffDateTime } from "../../../../../utils/dateMethods";
import getContrast from "../../../../../utils/getContrast";

interface props {
  selectedSchedule?: BreifEvent;
  schedule: BreifEvent;
  schedules: BreifEvent[];
  setSchedule: (schedule?: BreifEvent) => void;
  index: number;
}

export default function ScheduleItem({
  selectedSchedule,
  schedule,
  schedules,
  setSchedule,
  index,
}: props) {
  const start = new Date(schedule.start || "");
  const end = new Date(schedule.end || "");
  const yPosition =
    schedule.type == "date"
      ? 0
      : (start.getHours() + start.getMinutes() / 60) * 40;

  const diff = schedule.type == "date" ? 60 : diffDateTime(start, end);

  const overlapSchedules = schedules
    .filter(s => {
      if (schedule.id == s.id) {
        return false;
      }
      const sStart = new Date(s.start || "");
      const sEnd = new Date(s.end || "");
      if (
        sStart.getTime() == start.getTime() ||
        (sStart.getTime() < end.getTime() && sStart.getTime() > start.getTime())
      ) {
        return true;
      }
      if (sEnd.getTime() < end.getTime() && sEnd.getTime() > start.getTime()) {
        return true;
      }

      return false;
    })
    .map(os => {
      const i = schedules.findIndex(s => s.id == os.id);
      return {
        ...os,
        index: i,
      };
    });

  const overlapIndex: number = sort([
    ...overlapSchedules.map(to => to.index),
    index,
  ])
    .asc()
    .findIndex(i => i == index);

  const totalOverlap = overlapSchedules.filter(s => {
    const sStart = new Date(s.start || "");
    const sEnd = new Date(s.end || "");

    if (
      sStart.getTime() == start.getTime() &&
      sEnd.getTime() == end.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  });
  const totalOverlapIndex: number = sort([
    ...totalOverlap.map(to => to.index),
    index,
  ])
    .asc()
    .findIndex(i => i == index);

  let width =
    totalOverlap.length > 0
      ? `${(1 / (totalOverlap.length + 1)) * 100}%`
      : overlapSchedules.length > 0 && overlapIndex == 1
      ? "80%"
      : "100%";

  const height = `${(diff / 60) * 40}px`;

  const left =
    totalOverlap.length > 0
      ? `${(1 / (totalOverlap.length + 1)) * 100 * totalOverlapIndex}%`
      : overlapSchedules.length > 0 && overlapIndex == 1
      ? "20%"
      : 0;

  const opacity =
    overlapSchedules.length > 0 && totalOverlap.length < 1 && overlapIndex == 1
      ? 0.8
      : 1;

  const [hover, setHover] = useState(false);

  const selected = selectedSchedule?.id == schedule.id;

  return (
    <div
      onClick={() => {
        if (selected) {
          setSchedule(undefined);
        } else {
          setSchedule(schedule);
        }
      }}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
      className="absolute p-1 justify-center group"
      style={{
        top: yPosition,
        left,
        height: hover || selected ? "160px" : height,
        width: hover || selected ? "100%" : width,
        opacity: hover || selected ? "1" : opacity,
        zIndex: hover || selected ? 50 : "auto",
      }}
    >
      <div
        className={`w-full h-full p-2 rounded-md ring-1 group-hover:ring-2 ring-plum dark:ring-gray-600 cursor-pointer flex flex-col relative space-y-1 text-xs ${
          selected ? "animate-pulse" : ""
        }`}
        style={{
          backgroundColor: schedule.user?.color,
          color: getContrast(schedule.user?.color || "#CCCCCC"),
        }}
      >
        <span
          className={`${hover || selected ? "" : "truncate"} font-semibold`}
        >
          {schedule.summary}{" "}
          {(hover || selected) && `- ${schedule.user?.firstName}`}
        </span>
        {(selected || hover) && (
          <>
            <span>
              🕓{dayjs(start).format("H:mm")} - {dayjs(end).format("H:mm")}
            </span>

            {schedule.location ? <span>🏴{schedule.location}</span> : null}

            <span className="overflow-y-scroll md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700">
              {schedule.description}
            </span>
          </>
        )}
      </div>
    </div>
  );
}
