import { TypedDocumentNode, gql } from "@apollo/client";
import { AssignmentByPK, AssignmentStatType } from "./types";
import {
  ASSIGNMENT_ALL_FIELDS,
  ASSIGNMENT_STATUS_FIELDS,
  ASSIGNMENT_TYPE_FIELDS,
} from "./fragments";

interface AssignmentTypeArrayType {
  assignmentType: AssignmentStatType[];
}

interface AssignmentStatusArrayType {
  assignmentStatus: AssignmentStatType[];
}

export const GET_ASSIGNMENT_TYPE: TypedDocumentNode<AssignmentTypeArrayType> = gql`
  ${ASSIGNMENT_TYPE_FIELDS}
  query GET_ASSIGNMENT_TYPE {
    assignmentType {
      ...AssignmentTypeFields
    }
  }
`;

export const GET_ASSIGNMENT_STATUS: TypedDocumentNode<AssignmentStatusArrayType> = gql`
  ${ASSIGNMENT_STATUS_FIELDS}
  query GET_ASSIGNMENT_STATUS {
    assignmentStatus {
      ...AssignmentStatusFields
    }
  }
`;

export const GET_ASSIGNMENT_BY_PK: TypedDocumentNode<AssignmentByPK> = gql`
  ${ASSIGNMENT_ALL_FIELDS}
  query GET_ASSIGNMENT_BY_PK($id: Int!) {
    assignment_by_pk(id: $id) {
      ...AssignmentAllFields
    }
  }
`;

export const UPDATE_ASSIGNMENT_BY_PK = gql`
  ${ASSIGNMENT_ALL_FIELDS}
  mutation UPDATE_ASSIGNMENT_BY_PK($id: Int!, $set: assignment_set_input!) {
    update_assignment_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...AssignmentAllFields
    }
  }
`;

export const ADD_ASSIGNMENT_ORDER_BY_PK = gql`
  ${ASSIGNMENT_ALL_FIELDS}
  mutation ADD_ASSIGNMENT_ORDER_BY_PK($id: Int!, $orderId: jsonb!) {
    update_assignment_by_pk(
      pk_columns: { id: $id }
      _append: { orders: $orderId }
    ) {
      ...AssignmentAllFields
    }
  }
`;

export const ADD_ASSIGNMENT = gql`
  ${ASSIGNMENT_ALL_FIELDS}
  mutation ADD_ASSIGNMENT($object: assignment_insert_input!) {
    insert_assignment_one(object: $object) {
      ...AssignmentAllFields
    }
  }
`;

export const DELETE_ASSIGNMENT = gql`
  mutation DELETE_ASSIGNMENT($id: Int!) {
    delete_assignment_by_pk(id: $id) {
      id
    }
  }
`;
