import { TypedDocumentNode, gql } from "@apollo/client";
import { ContactType } from "./types";
import { CONTACT_CORE_FIELDS } from "./fragments";

interface update_contacts_by_pk_type {
  update_contacts_by_pk: ContactType;
}

export const UPDATE_CONTACT_BY_PK: TypedDocumentNode<update_contacts_by_pk_type> = gql`
  ${CONTACT_CORE_FIELDS}
  mutation UPDATE_CONTACT_BY_PK($id: Int!, $set: contacts_set_input!) {
    update_contacts_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ContactCoreFields
    }
  }
`;

interface contacts {
  contacts: ContactType[];
}

export const GET_CONTACT_CONDITIONAL: TypedDocumentNode<contacts> = gql`
  ${CONTACT_CORE_FIELDS}
  query GET_CONTACT_CONDITIONAL($where: contacts_bool_exp!) {
    contacts(where: $where) {
      ...ContactCoreFields
    }
  }
`;
