import { Table } from "flowbite-react";
import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import {
  NewPricingTable,
  pricingTable,
  pricingTableColumn,
  pricingTableRow,
} from "../types";
import { useEffect } from "react";
import uuid from "react-uuid";

interface props {
  appendColumn: UseFieldArrayAppend<NewPricingTable, "columns">;
}

export default function Rows({ appendColumn }: props) {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: "rows",
    control,
  });

  const columns: pricingTable["columns"] = useWatch({
    name: "columns",
    control,
  });

  const add = () => {
    append({
      height: 0,
      prices: columns.map(c => ({
        id: c.id,
        value: 1,
      })),
    });
  };

  return (
    <Table.Body>
      {fields.map((row, i) => (
        <Row
          key={row.id}
          canRemove={fields.length > 1}
          length={fields.length}
          index={i}
          remove={remove}
          columns={columns}
          appendColumn={appendColumn}
        />
      ))}
      <Table.Row>
        <Table.Cell className="bg-white dark:bg-gray-800 border-t-[1px] dark:border-gray-500" />
        <Table.Cell
          className="!py-3 bg-white dark:bg-gray-800 border-t-[1px] dark:border-gray-500"
          colSpan={columns.length}
        >
          <div className="flex flex-row justify-center">
            <PlusCircleIcon
              onClick={add}
              className="text-grass w-4 cursor-pointer"
            />
          </div>
        </Table.Cell>
        <Table.Cell className="bg-white dark:bg-gray-800" />
      </Table.Row>
    </Table.Body>
  );
}

const Row = ({
  index,
  remove,
  columns,
  canRemove,
  length,
  appendColumn,
}: {
  index: number;
  remove: UseFieldArrayRemove;
  columns: pricingTable["columns"];
  canRemove: boolean;
  length: number;
  appendColumn: (col: pricingTableColumn) => void;
}) => {
  const { register, control, getValues } = useFormContext();

  const addColumn = () => {
    appendColumn({
      id: uuid(),
      width: 0,
    });
  };

  const { fields, replace } = useFieldArray({
    name: `rows.${index}.prices`,
    control,
  });

  useEffect(() => {
    const currentPrices: pricingTableRow["prices"] = getValues(
      `rows.${index}.prices`
    );

    const sameLength = columns.length == currentPrices.length;
    const isEvicted = currentPrices.some(p => p.id !== columns[0].id);

    if (!sameLength || isEvicted) {
      const newPrices = columns.map(c => ({
        id: c.id,
        value: currentPrices.find(p => p.id === c.id)?.value || 1,
      }));
      replace(newPrices);
    }
  }, [columns]);

  return (
    <Table.Row>
      <Table.HeadCell className="!p-2 w-24 border-r-[1px] dark:border-gray-500">
        <div className="flex flex-row gap-1 items-center">
          <input
            className="bg-transparent w-0 flex-1 text-center text-black dark:text-white outline-none border-none text-sm py-[1px] px-0"
            type="number"
            {...register(`rows.${index}.height`)}
          />
          {canRemove && (
            <MinusCircleIcon
              onClick={() => remove(index)}
              className="text-red-500 w-4 cursor-pointer"
            />
          )}
        </div>
      </Table.HeadCell>
      {fields.map((price, i) => (
        <Cell key={price.id} rowIndex={index} index={i} />
      ))}
      {index == 0 && (
        <Table.Cell
          rowSpan={length}
          className="w-10 !px-1 dark:bg-gray-800 bg-white dark:border-l-[1px] dark:border-gray-500"
        >
          <div className="flex flex-row justify-center items-center">
            <PlusCircleIcon
              onClick={addColumn}
              className="text-grass w-4 cursor-pointer"
            />
          </div>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

const Cell = ({ index, rowIndex }: { index: number; rowIndex: number }) => {
  const { register } = useFormContext();

  const cellIndex = `rows.${rowIndex}.prices.${index}`;

  return (
    <Table.Cell className="!p-2 border-r-[1px] dark:border-gray-500">
      <input
        className="bg-transparent w-full text-center text-black dark:text-white outline-none border-none text-sm py-[1px] px-0"
        type="number"
        {...register(`${cellIndex}.value`)}
      />
    </Table.Cell>
  );
};
