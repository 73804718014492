import { processDetailSubProps } from "../types";
import Booking from "../../shared/schedules/booking";
import ScheduleList from "../../shared/schedules/scheduleList";
import { useState } from "react";
import { Button } from "flowbite-react";
import DynamicHeroIcon from "../../../../comps/hIcon";
import { useScheme } from "../../../../store/schemeStore";

export default function ProcessSchedule({ process }: processDetailSubProps) {
  const { scheme } = useScheme();
  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };
  return (
    <div
      className="col-span-1
      @lg:row-span-6 supports-[not(container-type:inline-size)]:lg:row-span-6        
      flex flex-col gap-4 justify-start"
    >
      <div className="space-y-4">
        <h2>Schedule</h2>
        <Button
          onClick={toggleAdding}
          size="sm"
          fullSized
          color={scheme == "dark" ? "dark" : "purple"}
        >
          <DynamicHeroIcon
            class="w-5"
            icon={adding ? "MinusCircleIcon" : "PlusCircleIcon"}
          />
        </Button>
        {adding && (
          <Booking
            process={process}
            cancelFunc={toggleAdding}
            cb={toggleAdding}
          />
        )}
        <ScheduleList schedules={process.schedules || []} process={process} />
      </div>
    </div>
  );
}
