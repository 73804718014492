import { create } from "zustand";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_RECORD_TYPE } from "../routes/protected/shared/record/gqls";
import { RecordFilter } from "../routes/protected/records/types";
import { queryParams } from "../routes/protected/shared/types";
import { RecordTypeType } from "../routes/protected/shared/record/types";
import dayjs from "dayjs";
import { useAuthStore } from "./authStore";
import { UserType } from "../routes/protected/settings/users/types";
import checkAuth from "../utils/checkAuth";
import { GET_USERS } from "../routes/protected/settings/users/gql";

type Store = {
  filters: RecordFilter[];
  setFilters: (filters: RecordFilter[]) => void;
  cursor: string;
  setCursor: (str: string) => void;
};

export const useRecordStore = create<Store>(set => ({
  filters: [],
  setFilters: filters => {
    if (filters.length == 0) {
      localStorage.removeItem("recordFilters");
    }
    localStorage.setItem("recordFilters", JSON.stringify(filters));
    return set(state => ({
      ...state,
      filters,
    }));
  },
  cursor: new Date().toISOString(),
  setCursor(cursor: string) {
    return set(state => ({
      ...state,
      cursor,
    }));
  },
}));

export const useDefaultFilters = () => {
  const [types, setTypes] = useState<undefined | RecordTypeType[]>(undefined);
  const [users, setUsers] = useState<undefined | UserType[]>(undefined);
  const { user } = useAuthStore();

  useQuery(GET_RECORD_TYPE, {
    onCompleted(data) {
      setTypes(data.recordType);
    },
  });

  useQuery(GET_USERS, {
    onCompleted(data) {
      setUsers(data.users);
    },
  });

  const { setFilters } = useRecordStore();

  const savedFilters = localStorage.getItem("recordFilters");

  useEffect(() => {
    if (savedFilters && savedFilters.length > 0) {
      setFilters(JSON.parse(savedFilters));
      return;
    }
    if (!types || !users) {
      return;
    }

    const defaultFilters: RecordFilter[] = [];

    // Types
    const options_type = types?.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_type = types?.map(t => t.id);

    const typeFilter: RecordFilter = {
      name: "Type",
      where: "typeID",
      type: "in",
      values: defaultValues_type,
      defaultValues: defaultValues_type,
      options: options_type,
    };
    defaultFilters.push(typeFilter);

    // Users
    const options_user = users
      ?.filter(
        u =>
          (checkAuth([
            "records_view_all",
            {
              permission: "records_view_team",
              checkGroup: "userTeam",
              conditionGroup: [u.team?.id || -1],
            },
          ]) ||
            u.id == user?.id) &&
          u.active &&
          u.team?.inHouse
      )
      .map(u => ({
        name: `${u.firstName} ${u.sirName}`,
        value: u.id,
      }));

    const userFilter: RecordFilter = {
      name: "User",
      where: "who",
      type: "in",
      values: [user?.id],
      defaultValues: [user?.id],
      options: options_user,
    };
    defaultFilters.push(userFilter);

    const firstDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const lastDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );

    const start: RecordFilter = {
      name: "Range",
      where: "start",
      type: "range",
      rangeType: "date",
      values: {
        from: dayjs(firstDay).format("YYYY-MM-DD"),
        to: dayjs(lastDay).format("YYYY-MM-DD"),
      },
      defaultValues: {
        from: dayjs(firstDay).format("YYYY-MM-DD"),
        to: dayjs(lastDay).format("YYYY-MM-DD"),
      },
    };
    defaultFilters.push(start);

    setFilters(defaultFilters);
  }, [types, users]);
};

export const refineFilters: (filters: RecordFilter[]) => queryParams = (
  filters: RecordFilter[]
) => {
  if (!filters) {
    return {
      where: { _and: [] },
      offset: 0,
    };
  }

  const where: any = { _and: [] };
  const order_by: object[] = [];

  for (const filter of filters) {
    // Filter
    switch (filter.type) {
      case "in":
        where._and.push({ [filter.where]: { _in: filter.values } });
        break;
      case "contains":
        where._and.push({
          _or: [
            { [filter.where]: { _contains: filter.values } },
            { [filter.where]: { _contained_in: filter.values } },
          ],
        });
        break;
      case "range":
        const { from, to } = filter.values;
        if (from && to) {
          where._and.push({
            [filter.where]: {
              _gte: dayjs(filter.values.from)
                .subtract(1, "day")
                .format("YYYY-MM-DD"),
              _lte: filter.values.to,
            },
          });
        }
        break;
    }
  }

  return {
    where: where,
    order_by: order_by,
    offset: 0,
  };
};
