import { useState } from "react";
import { ProductSetType } from "../../../workorder/types";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid";
import { useMutation } from "@apollo/client";
import { ADD_QUOTE_PARAM, UPDATE_QUOTE_PARAM_BY_PK } from "../gqls";
import { useForm } from "react-hook-form";
import { TbMathFunction } from "react-icons/tb";
import { Button, Spinner } from "flowbite-react";
import { QUOTE_PARAMS_FIELDS } from "../fragments";
import { QuoteParams } from "../types";
import ProductSalesParams from "./productSalesParams";

interface props {
  productSet: ProductSetType;
  params: QuoteParams[];
}
export default function ProductInstallParams({ productSet, params }: props) {
  const [extended, setExtended] = useState(false);

  const existingSalesParams = params.find(
    p => p.name == "salesParams" && p.productSetId == productSet.id
  );
  const exInstallParam = params.find(
    p => p.name == "install" && p.productSetId == productSet.id
  );
  const exInstallPrice = exInstallParam?.value || "";

  const defaultValues = {
    install: exInstallPrice,
  };

  const {
    register,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const [update, { loading: updating }] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);
  const [insert, { loading: inserting }] = useMutation(ADD_QUOTE_PARAM);

  const loading = updating || inserting;

  const updateBase = () => {
    const installValue = getValues("install");
    if (exInstallParam) {
      update({
        variables: {
          id: exInstallParam.id,
          set: {
            value: installValue,
          },
        },
        onCompleted() {
          reset({
            install: installValue,
          });
        },
      });
    } else {
      insert({
        variables: {
          object: {
            name: "install",
            value: installValue,
            productSetId: productSet.id,
          },
        },
        onCompleted() {
          reset({
            install: installValue,
          });
        },
        update(cache, { data: { insert_quoteParams_one: newParam } }) {
          cache.modify({
            fields: {
              quoteParams(existing = []) {
                const newParamRef = cache.writeFragment({
                  data: newParam,
                  fragment: QUOTE_PARAMS_FIELDS,
                  fragmentName: "QuoteParamsFields",
                });
                return [...existing, newParamRef];
              },
            },
          });
        },
      });
    }
  };

  const cancel = () => {
    reset({
      install: defaultValues.install,
    });
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-600 flex flex-col rounded-md ">
      <div
        onClick={() => {
          setExtended(!extended);
        }}
        className="p-3 bg-gray-100 dark:bg-gray-600 flex flex-row items-center justify-between rounded-md cursor-pointer hover:brightness-125"
      >
        <div className="font-semibold text-xl capitalize">
          {productSet.name}
        </div>

        {extended ? (
          <ChevronDoubleUpIcon className="w-5" />
        ) : (
          <ChevronDoubleDownIcon className="w-5" />
        )}
      </div>
      {extended && (
        <div className="p-3 m-3 flex flex-col gap-1 rounded-md bg-white dark:bg-gray-800 drop-shadow-md">
          <div className="p-1 border-b-[1px] flex flex-row items-center justify-between gap-4">
            <h3>Base Install Fee</h3>
            <div className="flex flex-row items-center gap-1 flex-1">
              <input
                className="p-1 text-grass font-semibold bg-transparent border-none flex-1 text-right outline-none"
                placeholder="Enter number or function"
                {...register("install")}
              />
              <TbMathFunction className="w-5 h-5 ml-2" />
              {isDirty && (
                <div className="flex flex-row items-center gap-1">
                  <Button size="xs" color="purple" onClick={cancel}>
                    cancel
                  </Button>
                  <Button
                    size="xs"
                    outline
                    gradientDuoTone={"purpleToBlue"}
                    onClick={updateBase}
                  >
                    update
                    {loading && (
                      <Spinner size="xs" className="ml-1" color="purple" />
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <ProductSalesParams
            productSet={productSet}
            existingSalesParams={existingSalesParams}
          />
        </div>
      )}
    </div>
  );
}
