import {
  Button,
  Select,
  Spinner,
  Table,
  TextInput,
  ToggleSwitch,
} from "flowbite-react";
import Heading from "../../../comps/heading";
import { useState } from "react";
import {
  TypedDocumentNode,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
  useApolloClient,
} from "@apollo/client";
import { addAlert } from "../../../store/alertStore";
import { createPathArr, list, rename } from "../../../utils/fileAPI";
import { FileType } from "../shared/files/type";
import { GET_PROCESS_TYPES } from "../settings/process/processTypes/processTypes";
import { QUERY_PROCESS_BY_NUMBER } from "./processSearch";
import { UPDATE_PROCESS_BY_PK } from "./processDetail/gqls";
import { special } from "./types";
import { sort } from "fast-sort";

export default function DirConverter() {
  const [dirs, setDirs] = useState<FileType[]>([]);
  const [path, setPath] = useState<string>("");
  const [type, setType] = useState<number | undefined>(undefined);
  const [override, setOverride] = useState(false);
  const toggleOverride = () => {
    setOverride(!override);
  };

  const [updateProcess] = useMutation(UPDATE_PROCESS_BY_PK);

  const { data } = useQuery(GET_PROCESS_TYPES);
  const types = data?.processType;

  const getList = async () => {
    if (!path) {
      return addAlert({ message: "no path entered", type: "warning" });
    }
    const data = await list(path.split("/"));
    if (!data) {
      return;
    }
    // @ts-expect-error
    setDirs(data.filter(d => d.type == "d"));
  };

  const client = useApolloClient();

  const regex = /^[a-zA-Z]/;

  const convertOne = async (item: FileType, noAsk?: boolean) => {
    const name = item.name;
    const head = name.split(" ")[0];
    if (head.length < 5) {
      return;
    }
    const nameOnly = name
      .split(" ")
      .slice(1, 100)
      .join(" ")
      .replaceAll(special, "");

    if (!head || !nameOnly) {
      return;
    }
    const numberOnly = head.replace(regex, "");
    const year = numberOnly.slice(0, 2);
    const number = numberOnly.replace(year, "").replace(/[A-Za-z]+/, "");

    if (!year || !number || !type) {
      return;
    }

    const fullYear = Number(`20${year}`);

    const oldPath = path.concat("/" + name).split("/");

    const { data, errors } = await client.query({
      query: QUERY_PROCESS_BY_NUMBER,
      fetchPolicy: "network-only",
      variables: {
        where: {
          _and: [
            { year: { _eq: fullYear } },
            { number: { _eq: Number(number) } },
            { typeId: { _eq: type } },
          ],
        },
      },
    });

    if (errors) {
      console.log(errors);
      return;
    }

    if (!data || data.process.length !== 1) {
      return;
    }

    const process = data.process[0];
    const jobPath: false | string[] = createPathArr(process);
    const overridePath: false | string[] = createPathArr({
      ...process,
      name: nameOnly,
    });

    if (!jobPath || jobPath.join("/") == oldPath.join("/")) {
      return;
    }
    if (!overridePath || overridePath.join("/") == oldPath.join("/")) {
      return;
    }

    console.log(process.name, oldPath, jobPath, overridePath);

    if (noAsk) {
      if (override) {
        await updateProcess({
          variables: {
            id: process.id,
            set: {
              name: nameOnly,
            },
          },
          onCompleted() {
            rename(oldPath, overridePath).then(_ => {
              setDirs(
                dirs.map(d => {
                  if (d.name == name) {
                    return {
                      ...d,
                      name: overridePath[overridePath.length - 1],
                    };
                  } else {
                    return d;
                  }
                })
              );
              return;
            });
          },
          onError() {
            return;
          },
        });
      } else {
        rename(oldPath, jobPath).then(_ => {
          setDirs(
            dirs.map(d => {
              if (d.name == name) {
                return {
                  ...d,
                  name: jobPath[jobPath.length - 1],
                };
              } else {
                return d;
              }
            })
          );
        });
      }
    } else {
      const proceed = confirm("convert folder name?");
      if (proceed) {
        if (override) {
          await updateProcess({
            variables: {
              id: process.id,
              set: {
                name: nameOnly,
              },
            },
            onCompleted() {
              rename(oldPath, overridePath).then(_ => {
                setDirs(
                  dirs.map(d => {
                    if (d.name == name) {
                      return {
                        ...d,
                        name: overridePath[overridePath.length - 1],
                      };
                    } else {
                      return d;
                    }
                  })
                );
                return;
              });
            },
            onError() {
              return;
            },
          });
        } else {
          rename(oldPath, jobPath).then(_ => {
            setDirs(
              dirs.map(d => {
                if (d.name == name) {
                  return {
                    ...d,
                    name: jobPath[jobPath.length - 1],
                  };
                } else {
                  return d;
                }
              })
            );
          });
        }
      }
    }
  };

  const convertAll = async () => {
    await Promise.all(
      dirs.map(async d => {
        await convertOne(d, true);
      })
    );
    addAlert({ message: "done", persist: true, type: "success" });
  };

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6">
        <div className="flex flew-row h-8">
          <Heading name="Process Directory Converter" />
        </div>
        <div className="flex flex-row text-red-500">
          WARNING, do not proceed if not 100% sure !!!
        </div>
      </div>
      <section className="p-6 gap-2 flex flex-col mt-1">
        <h2>Path</h2>
        <TextInput
          sizing="sm"
          value={path}
          placeholder="enter path to check"
          onChange={e => {
            setPath(e.target.value);
          }}
        />
        <div className="flex flex-row justify-end">
          <Button
            className="w-fit"
            outline
            size="xs"
            gradientDuoTone="purpleToBlue"
            onClick={getList}
          >
            get list
          </Button>
        </div>
        <h2>Job Type</h2>
        <Select
          value={type}
          onChange={e => {
            setType(Number(e.target.value));
          }}
        >
          {types?.map(t => (
            <option key={t.id} value={t.id}>
              {t.name}
            </option>
          ))}
        </Select>
        <div className="flex flex-row justify-between items-center">
          <h2>Items</h2>
          <div>{dirs && dirs.length}</div>
        </div>
        <ToggleSwitch
          checked={override}
          onChange={toggleOverride}
          label="Override"
        />

        {dirs.length > 0 && (
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell>name</Table.HeadCell>
              <Table.HeadCell>action</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {sort(dirs)
                .asc("name")
                .map((item, i) => (
                  <Table.Row
                    onClick={() => {
                      convertOne(item);
                    }}
                    key={i}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800 select-text dark:text-gray-100 cursor-pointer"
                  >
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>
                      <Button
                        size="xs"
                        outline
                        onClick={() => {
                          setDirs(dirs.filter(d => d.name !== item.name));
                        }}
                      >
                        delete
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        )}
        <div className="flex flex-row mt-2 gap-2">
          <Button size="xs" outline onClick={convertAll}>
            convertAll
          </Button>
        </div>
      </section>
    </main>
  );
}
