import {
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import checkAuth from "../../../../../utils/checkAuth";
import { gql, useQuery, TypedDocumentNode, useMutation } from "@apollo/client";
import { TagArrayType } from "./types";
import { TAGS_CORE_FIELDS } from "./fragments";
import { Spinner } from "flowbite-react";
import Tag from "./tag";
import AddTag from "./addTag";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
  Active,
  useDroppable,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { addAlert } from "../../../../../store/alertStore";

export const GET_TAGS: TypedDocumentNode<TagArrayType> = gql`
  ${TAGS_CORE_FIELDS}
  query GET_TAGS {
    tags {
      ...TagsCoreFields
    }
  }
`;

export const DELETE_TAG = gql`
  mutation DELETE_TAG($id: Int!) {
    delete_tags_by_pk(id: $id) {
      id
    }
  }
`;

export default function Tags() {
  const { data, error, loading } = useQuery(GET_TAGS);

  if (error) {
    console.log(error);
    addAlert({ message: "could not load tags", type: "failure" });
  }

  const [delete_tag] = useMutation(DELETE_TAG);

  const [adding, setAdding] = useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const viewAuth = checkAuth("setting_process_tags_access");
  const editAuth = checkAuth("setting_process_tags_edit");

  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => data?.tags.find(tag => tag.id === theActive?.id),
    [theActive, data?.tags]
  );

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const [deleting, setDeleting] = useState(0);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over) {
      setDeleting(active.id);

      const proceed = confirm("delete tag?");
      if (!proceed) {
        setActive(null);
        setDeleting(0);
        return;
      }

      delete_tag({
        variables: { id: active.id },
        update(cache, { data: { delete_tags_by_pk: deletedTag } }) {
          const normalizedId = cache.identify({
            id: deletedTag.id,
            __typename: "tags",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
        onCompleted(data) {
          if (data.delete_tags_by_pk) {
            addAlert({ message: "tag deleted", type: "success" });
            setDeleting(0);
          }
        },
      });
    }

    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  return (
    <div className="mb-1 flex-grow  mx-auto">
      <div className="flex flex-row justify-between items-center">
        <h1>Tags</h1>
        {editAuth && (
          <div onClick={toggleAdding} className="w-6 cursor-pointer">
            {adding ? (
              <MinusCircleIcon className="text-red-500" />
            ) : (
              <PlusCircleIcon className="text-grass hover:animate-pulse" />
            )}
          </div>
        )}
      </div>
      {loading && !data && (
        <div className="flex flex-row justify-center mt-4 mb-6">
          <Spinner color="purple" size="xl" />
        </div>
      )}
      <section className="my-4 space-y-2">
        {adding && <AddTag toggleAdding={toggleAdding} tags={data?.tags} />}
        {!viewAuth && "no access"}
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          modifiers={[restrictToWindowEdges]}
        >
          <div className="flex flex-row flex-wrap gap-2">
            {viewAuth &&
              data?.tags?.map(tag => (
                <Tag deleting={deleting} key={tag.id} tag={tag} />
              ))}
          </div>
          {activeItem && <Drop />}
        </DndContext>
      </section>
    </div>
  );
}

function Drop() {
  const { setNodeRef, isOver } = useDroppable({
    id: "droppable",
  });

  const style = {
    opacity: isOver ? 1 : 0.5,
  };

  return (
    <div
      style={style}
      ref={setNodeRef}
      className="w-full h-20 flex items-center justify-center rounded-md border-red-500 border-dashed border-[1px]"
    >
      <TrashIcon className="text-red-500 w-10" />
    </div>
  );
}
