import { useSearchStore } from "../../../store/searchStore";
import { gql, useLazyQuery, TypedDocumentNode, useQuery } from "@apollo/client";
import { PROCESS_CORE_FIELDS } from "../process/fragments";
import { ProcessType } from "../process/types";
import { GET_PROCESS_TYPES } from "../settings/process/processTypes/processTypes";
import { searchResult } from "../../../store/searchStore";
import { fullNumber } from "../../../utils/fullNumber";
import { GET_PRODUCTIONS } from "./gql";
import checkAuth from "../../../utils/checkAuth";

interface searchResults {
  search_process: ProcessType[];
}

interface processQueryDocument {
  process: ProcessType[];
}

export const QUERY_PROCESS_BY_NUMBER: TypedDocumentNode<processQueryDocument> = gql`
  ${PROCESS_CORE_FIELDS}
  query QUERY_PROCESS($where: process_bool_exp) {
    process(where: $where, limit: 5) {
      ...ProcessCoreFields
    }
  }
`;

export const SEARCH_PROCESS: TypedDocumentNode<searchResults> = gql`
  ${PROCESS_CORE_FIELDS}
  query SEARCH_PROCESS($str: String!) {
    search_process(args: { search: $str }) {
      ...ProcessCoreFields
    }
  }
`;

export const useProductionSearch = () => {
  const { setSearchResults, setLoading } = useSearchStore();

  const { data: data_process_type } = useQuery(GET_PROCESS_TYPES);

  const [searchProcessByNum] = useLazyQuery(QUERY_PROCESS_BY_NUMBER, {
    fetchPolicy: "cache-and-network",
  });

  const [searchProcess] = useLazyQuery(SEARCH_PROCESS, {
    fetchPolicy: "cache-and-network",
  });

  const [getProductions] = useLazyQuery(GET_PRODUCTIONS);

  const processTypes = data_process_type?.processType;

  const searchProducts = (ids: number[]) => {
    getProductions({
      variables: {
        where: { processID: { _in: ids } },
      },
      fetchPolicy: "cache-and-network",
      onCompleted(res) {
        const productions = res.productions.filter(production =>
          checkAuth([
            "production_view_all",
            {
              permission: "production_view_mine",
              checkGroup: "userTeam",
              conditionGroup: [production.team.id],
            },
          ])
        );

        const results: searchResult[] = productions.map(p => ({
          header: p.process
            ? fullNumber(
                p.process.processType.prefix,
                p.process.year,
                p.process.number,
                p.process.salesRepUser
              )
            : p.description,
          desc: `${p.process && `${p.process.name} `}${p.product.name} ${
            p.qty
          }`,
          link: "./production/detail/" + p.id,
        }));
        setSearchResults(results);
      },
      onError(error) {
        console.log(error);
      },
    });
    setLoading(false);
  };

  return (str: string) => {
    if (str && str.trim() !== "") {
      const isFullNumberRegex = /[A-Za-z]+[0-9]+/i;

      const numberPart = str.replace(/^\D+/g, "");
      const prefix = str.replace(numberPart, "");
      const year = Number(numberPart.slice(0, 2));
      const number = Number(numberPart.replace(year.toString(), ""));
      const fullYear = Number(`20${year}`);
      const notNum = isNaN(year) || isNaN(number) || number == 0;

      if (isFullNumberRegex.test(str)) {
        let typeId = 0;
        if (processTypes) {
          typeId =
            processTypes.find(
              pt => pt.prefix?.toLowerCase() == prefix?.toLowerCase()
            )?.id || 0;
        }

        setLoading(true);

        searchProcessByNum({
          variables: {
            where: {
              _and: [
                { year: { _eq: fullYear } },
                { number: { _eq: number } },
                { typeId: { _eq: typeId } },
                { productions_aggregate: { count: { predicate: { _gt: 0 } } } },
              ],
            },
          },
          onCompleted: data => {
            searchProducts(data.process.map(p => p.id));
            return;
          },
          onError(err) {
            console.log(err);
            console.log("searchProcessByFullNum Err");
            setSearchResults([]);
            setLoading(false);
          },
        });
        return;
      }

      const isNumberRegex = /[0-9]+/i;
      if (isNumberRegex.test(str) && !notNum) {
        setLoading(true);
        searchProcessByNum({
          variables: {
            where: {
              _and: [
                { year: { _eq: fullYear } },
                { number: { _eq: number } },
                { productions_aggregate: { count: { predicate: { _gt: 0 } } } },
              ],
            },
          },
          onCompleted: data => {
            searchProducts(data.process.map(p => p.id));
            return;
          },
          onError(err) {
            console.log(err);
            console.log("searchProcessByNum Err");
            setSearchResults([]);
            setLoading(false);
          },
        });
        return;
      }

      setLoading(true);
      searchProcess({
        variables: { str },
        onCompleted: data => {
          searchProducts(data.search_process.map(p => p.id));
          return;
        },
        onError(err) {
          console.log(err);
          setSearchResults([]);
          setLoading(false);
        },
      });
    } else {
      setSearchResults([]);
    }
  };
};
