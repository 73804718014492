import { UserType } from "../../users/types";
import {
  ADD_QUOTE_PARAM,
  GET_QUOTE_PARAMS,
  UPDATE_QUOTE_PARAM_BY_PK,
} from "../gqls";
import { useMutation, useQuery } from "@apollo/client";
import { ProductSetType } from "../../../workorder/types";
import { useForm } from "react-hook-form";
import { QUOTE_PARAMS_FIELDS } from "../fragments";
import { Button, Spinner } from "flowbite-react";
import { QuoteParams } from "../types";

interface props {
  dealer: UserType;
  productSet: ProductSetType;
  params: QuoteParams[];
}

export default function ProductSetDiscountControl({
  dealer,
  productSet,
  params,
}: props) {
  const existingParam = params.find(
    p =>
      p.name == "discount" &&
      p.productSetId == productSet.id &&
      p.userId == dealer.id
  )?.value;

  const defaultValues = {
    discount: existingParam || 0,
  };

  const [update, { loading: updating }] = useMutation(UPDATE_QUOTE_PARAM_BY_PK);
  const [insert, { loading: inserting }] = useMutation(ADD_QUOTE_PARAM);

  const loading = updating || inserting;

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const onSubmit = handleSubmit(data => {
    if (existingParam) {
      update({
        variables: {
          id: existingParam.id,
          set: {
            value: Number(data.discount),
          },
        },
        onCompleted() {
          reset({
            discount: data.discount,
          });
        },
      });
    } else {
      insert({
        variables: {
          object: {
            name: "discount",
            value: Number(data.discount),
            productSetId: productSet.id,
            userId: dealer.id,
          },
        },
        onCompleted() {
          reset({
            discount: data.discount,
          });
        },
        update(cache, { data: { insert_quoteParams_one: newParam } }) {
          cache.modify({
            fields: {
              quoteParams(existing = []) {
                const newParamRef = cache.writeFragment({
                  data: newParam,
                  fragment: QUOTE_PARAMS_FIELDS,
                  fragmentName: "QuoteParamsFields",
                });
                return [...existing, newParamRef];
              },
            },
          });
        },
      });
    }
  });

  const cancel = () => {
    reset({
      discount: defaultValues.discount,
    });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="p-1 border-b-[1px] flex flex-row items-center justify-between"
    >
      <h3>{productSet.name}</h3>
      <div className="flex flex-row items-center gap-1">
        <input
          type="number"
          className="p-1 text-plum text-lg font-semibold bg-transparent border-none w-12 text-center outline-none"
          placeholder="0"
          {...register("discount")}
        />
        %
        {isDirty && (
          <div className="flex flex-row items-center gap-1">
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone={"purpleToBlue"}
              type="submit"
            >
              update
              {loading && <Spinner size="xs" className="ml-1" color="purple" />}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
