import { ListGroup } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import { option } from "../../../types";
import { DeductionType, optionValues } from "../../../../types";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { MagnifyingGlassIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useOrderStore } from "../../../../../../../store/orderStore";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { sort } from "fast-sort";
import AddCustomDeduction from "./addCustomDeduction";

interface props {
  optionCoord: string;
  disabled?: boolean;
  availableOptions: (string | number)[] | undefined;
  formOptions: option[];
  optionValue: any;
  optionValues: option["values"];
  optionDefault: option["default"];
  optionName: string;
}

export default function DeductionsSelect({
  optionCoord,
  disabled,
  availableOptions,
  formOptions,
  optionValue,
  optionValues,
  optionName,
  optionDefault,
}: props) {
  const { setValue, control, getValues } = useFormContext();

  const optionCode = useWatch({
    name: `${optionCoord}.optionCode`,
    exact: true,
    control,
  });

  const { deductions: cachedDeductions } = useOrderStore();
  const deductions = cachedDeductions.filter(cd => {
    if (cd.pending) {
      return false;
    }
    if (optionCode) {
      return cd.optionCode == optionCode;
    } else {
      return optionValues?.includes(cd.id);
    }
  });

  const [loading, setLoading] = useState(true);

  const canAddCustom = useWatch({
    name: `${optionCoord}.allowCustomInput`,
    exact: true,
    control,
  });

  useEffect(() => {
    setLoading(true);

    const deductionsIdOnly = deductions.map(d => d.id);

    if (
      deductions[0] &&
      JSON.stringify(deductionsIdOnly) !== JSON.stringify(optionValues)
    ) {
      setValue(`${optionCoord}.values`, deductionsIdOnly);
    }
    setLoading(false);
  }, [deductions]);

  useEffect(() => {
    if (!optionValue) {
      return;
    }

    const _deduction = deductions.find(
      d => d.id.toString() === optionValue.toString()
    );

    const deduction = {
      id: _deduction?.id,
      name: _deduction?.name,
      width: _deduction?.width,
      height: _deduction?.height,
    };

    const currentPopulatedValue: DeductionType | undefined = getValues(
      `${optionCoord}.populatedValue`
    );

    if (JSON.stringify(deduction) !== JSON.stringify(currentPopulatedValue)) {
      setValue(`${optionCoord}.populatedValue`, deduction);
    }

    const dpValue = deduction?.name;

    const currentDisplayValue = getValues(`${optionCoord}.displayValue`);

    if (currentDisplayValue !== dpValue) {
      setValue(`${optionCoord}.displayValue`, dpValue);
    }
  }, [JSON.stringify(optionValue), JSON.stringify(deductions)]);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(!open);
  };
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const selectedDeduction = deductions.find(
    d => d.id.toString() == optionValue.toString()
  );

  const finalOptions = availableOptions
    ? availableOptions.filter(o => {
        const deduction = deductions.find(p => p.id.toString() == o.toString());
        if (!deduction) {
          return false;
        }
        const conditions = deduction?.optionCondition;

        const reducedConditions = conditions?.reduce<optionValues[]>(
          (acc, cur) => {
            if (acc.find(option => option.option == cur.option)) {
              return acc.map(ov => {
                if (ov.option == cur.option) {
                  return { ...ov, values: ov.values.concat(cur.value) };
                } else {
                  return ov;
                }
              });
            } else {
              return acc.concat({ option: cur.option, values: [cur.value] });
            }
          },
          []
        );

        if (reducedConditions) {
          return reducedConditions.every(c => {
            const myOption = formOptions.find(o => o.id == Number(c.option));
            const values = c.values.map(v => v.toString());
            return values.includes(myOption?.value.toString());
          });
        }

        return true;
      })
    : [];

  const [search, setSearch] = useState("");

  const isMounted = useRef(false);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (finalOptions.length == 0) {
      if (optionValue !== "") {
        setValue(`${optionCoord}.value`, "");
      }
      return;
    }
    if (!finalOptions.find(o => o.toString() == optionValue.toString())) {
      let newVal: any =
        finalOptions.find(o => o.toString() == optionDefault?.toString()) ||
        finalOptions[0];

      newVal = newVal
        ? deductions.find(d => d.id.toString() == newVal.toString())?.id
        : "";

      const disableAutoSelect = getValues(`${optionCoord}.disableAutoSelect`);

      if (disableAutoSelect) {
        newVal = "";
      }

      if (optionValue !== newVal?.toString()) {
        setValue(`${optionCoord}.value`, newVal?.toString());
      }
    }
  }, [JSON.stringify(finalOptions), loading, isMounted.current]);

  const populatedOptions = finalOptions
    ? (finalOptions
        .map(o => deductions.find(p => p.id.toString() == o.toString()))
        .filter(o => !isNullish(o)) as DeductionType[])
    : ([] as DeductionType[]);

  const sortedOptions = sort(populatedOptions)
    .asc("name")
    .filter(
      deduction =>
        search.trim() == "" ||
        deduction.name?.toLowerCase().includes(search.toLowerCase())
    );

  const [adding, setAdding] = useState(false);

  const onCustomDeductionAdd = (deduction: DeductionType) => {
    setAdding(false);
    setSearch("");
    setValue(`${optionCoord}.value`, deduction.id.toString());
  };

  return (
    <div className="relative" ref={ref}>
      <ListGroup
        className={`${disabled ? "brightness-90 dark:brightness-75" : ""}`}
        onClick={toggleOpen}
      >
        <ListGroup.Item>
          <div
            className={`text-xs ${
              optionValue == "" && "text-red-500 animate-pulse"
            }`}
          >
            {selectedDeduction?.name || "Required"}
          </div>
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 pb-10">
          <div className="flex flex-col gap-1 bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
            <div className="relative flex flex-row gap-1 items-center rounded-t-md p-2 backdrop-brightness-90 dark:backdrop-brightness-75">
              {search.trim() == "" && (
                <MagnifyingGlassIcon className="w-4 absolute left-4 text-gray-700 dark:text-gray-500" />
              )}
              <input
                value={search}
                onChange={e => {
                  setSearch(e.target.value);
                }}
                autoFocus
                className="bg-white dark:bg-gray-100 w-full rounded-md px-2 py-1 dark:text-dark text-xs"
              />
            </div>
            {sortedOptions.map(deduction => {
              return (
                <div
                  onClick={() => {
                    setValue(`${optionCoord}.value`, deduction.id);
                    setOpen(false);
                  }}
                  key={deduction.id}
                  className="group/preset relative"
                >
                  <div className="rounded-md px-4 py-2 cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                    {deduction.name}
                  </div>
                </div>
              );
            })}
            {sortedOptions.length == 0 && optionCode && canAddCustom && (
              <div
                onClick={() => {
                  setAdding(true);
                }}
                className="group/preset relative"
              >
                <div className="flex flex-row gap-1 items-center rounded-md px-2 py-2 cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                  <PlusCircleIcon className="w-5 text-grass" /> Add New
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {optionCode && (
        <AddCustomDeduction
          adding={adding}
          setAdding={setAdding}
          optionCode={optionCode}
          deductions={populatedOptions}
          search={search}
          optionName={optionName}
          cb={onCustomDeductionAdd}
        />
      )}
    </div>
  );
}
