import { useState, MouseEvent } from "react";
import { useAuthStore } from "../../../store/authStore";
import { TextInput, Label, Button, Spinner } from "flowbite-react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql, useMutation } from "@apollo/client";

const infoSchema = z.object({
  firstName: z.string().min(2, { message: "Please input valid name" }),
  sirName: z.string().min(2, { message: "Please input valid name" }),
});

export const CHANGE_NAME = gql`
  mutation ChangeName($firstName: String!, $sirName: String!, $id: Int!) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { sirName: $sirName, firstName: $firstName }
    ) {
      id
      firstName
      sirName
    }
  }
`;

export default function IdInfo() {
  const { user, setUser } = useAuthStore();

  const [change_name, { loading, error }] = useMutation(CHANGE_NAME);
  if (error) {
    console.log(error);
  }
  const [editing, setEditing] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(infoSchema),
    defaultValues: {
      firstName: user?.firstName,
      sirName: user?.sirName,
    },
  });

  const onSubmit = handleSubmit(async names => {
    change_name({
      variables: { id: user?.id, ...names },
      onCompleted(data) {
        if (data?.update_users_by_pk) {
          const { firstName, sirName } = data.update_users_by_pk;

          if (user) {
            setUser({
              ...user,
              firstName,
              sirName,
            });
          }

          reset({
            firstName,
            sirName,
          });
        }
      },
    });
    setEditing(state => !state);
  });

  const clickEdit = (e: MouseEvent) => {
    if (!editing) {
      e.preventDefault();
      setEditing(state => !state);
    }
  };

  const clickCancle = () => {
    setEditing(false);
    reset();
  };

  return (
    <form className="mb-1 flex-1 min-w-[250px] mx-auto" onSubmit={onSubmit}>
      <div className="text-2xl font-bold mb-2">Ids</div>
      <div className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 ">
        <div className="mb-2 block">
          <Label htmlFor="email" value="Email" />
        </div>
        <TextInput addon="@" type="text" disabled value={user?.email} />

        <div className="mb-2 block mt-2">
          <Label htmlFor="firstName" value="First Name" />
        </div>
        <TextInput
          type="text"
          disabled={!editing}
          {...register("firstName")}
          color={errors.firstName?.message ? "failure" : undefined}
          helperText={errors.firstName?.message || ""}
        />

        <div className="mb-2 block mt-2">
          <Label htmlFor="lastName" value="Sir Name" />
        </div>
        <TextInput
          type="text"
          disabled={!editing}
          {...register("sirName")}
          color={errors.sirName?.message ? "failure" : undefined}
          helperText={errors.sirName?.message || ""}
        />
        <hr className="my-[27px] border-gray-300 dark:border-gray-500" />
        <div className="flex space-x-2 justify-end">
          {(editing || loading) && (
            <Button
              gradientDuoTone="purpleToBlue"
              size="sm"
              onClick={clickCancle}
            >
              Cancel
            </Button>
          )}

          <Button
            onClick={clickEdit}
            outline
            type="submit"
            gradientDuoTone="purpleToBlue"
            size="sm"
          >
            {!editing && !loading && <PencilIcon className="w-4 mr-2" />}
            {loading && (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            )}
            {editing || loading ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
    </form>
  );
}
