import { useEffect, useState } from "react";
import Heading from "../../../comps/heading";
import { Button, Spinner } from "flowbite-react";
import {
  AdjustmentsHorizontalIcon,
  CalculatorIcon,
  ClipboardIcon,
  QueueListIcon,
  TableCellsIcon,
} from "@heroicons/react/24/solid";
import { GET_PRODUCTIONS, STREAM_PRODUCTIONS } from "./gql";
import { useQuery } from "@apollo/client";
import Filters from "./filters";
import {
  refineFilters,
  useProductionStore,
} from "../../../store/productionStore";
import ProductionBoard from "./productionBoard/productionBoard";
import ProductionLists from "./productionList/productionLists";
import { ProductType } from "../settings/products/types";
import { cloneDeep } from "apollo-utilities";

export default function ProductionList() {
  const { filters, view, changeView, cursor, setCursor } = useProductionStore();

  const [limit] = useState(100);
  const [totalLength, setTotalLength] = useState(0);

  const { data, loading, fetchMore, subscribeToMore } = useQuery(
    GET_PRODUCTIONS,
    {
      variables: {
        where: refineFilters(filters).where,
        offset: 0,
        limit,
      },
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        setCursor(new Date().toISOString());
        setTotalLength(data.productions_aggregate?.aggregate?.count || 0);
      },
    }
  );

  const ids = data?.productions.map(p => p.id);

  const loadMore = () => {
    const currentLength = data?.productions.length || 0;
    fetchMore({
      variables: {
        where: refineFilters(filters).where,
        offset: currentLength,
        limit,
      },
    });
  };

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: STREAM_PRODUCTIONS,
      variables: {
        where: {
          _or: [
            { _and: refineFilters(filters).where._and },
            { id: { _in: ids || [] } },
          ],
        },
        cursor: {
          initial_value: { updated_at: cursor },
          ordering: "ASC",
        },
      },
      updateQuery: (previous, { subscriptionData }) => {
        setCursor(new Date().toISOString());
        if (!subscriptionData.data) return previous;
        const previousData = cloneDeep(previous.productions);
        const newFeedItem = subscriptionData.data.productions_stream;
        const existing = previousData.filter(
          p => !newFeedItem.find(i => i.id == p.id)
        );

        return Object.assign({}, previous, {
          productions: [...newFeedItem, ...existing],
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [filters, ids]);

  const productions = data?.productions;

  const [filtering, setFiltering] = useState(false);
  const toggleFiltering = () => {
    setFiltering(!filtering);
  };

  const [showTotal, setShowTotal] = useState(false);
  const toggleTotal = () => {
    setShowTotal(!showTotal);
  };

  const total = productions
    ? productions?.reduce<{ product: ProductType; qty: number }[]>(
        (prv, cur) => {
          const product = cur.product;
          const qty = cur.qty;

          if (prv.find(p => p.product.name == product.name)) {
            return prv.map(pro => {
              if (pro.product.name == product.name) {
                return {
                  ...pro,
                  qty: pro.qty + qty,
                };
              } else {
                return pro;
              }
            });
          } else {
            return prv.concat({
              product,
              qty,
            });
          }
        },
        []
      )
    : [];

  return (
    <main className="select-none flex flex-col flex-1">
      {/* Header Part */}
      <div className="px-6 mb-1">
        <div className="flex flew-row justify-between h-8">
          <Heading />
        </div>
        <div className="flex flex-row text-grass mb-10">productions</div>
        {/* Actions */}
        <div className="flex flex-row justify-between gap-2 flex-wrap items-center">
          <div className="flex flex-row gap-2">
            <div
              className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
              onClick={changeView}
            >
              {view == "board" && <ClipboardIcon className="w-4" />}
              {view == "list" && <QueueListIcon className="w-4" />}
              {view == "table" && <TableCellsIcon className="w-4" />}
              View
            </div>
            <div
              onClick={toggleFiltering}
              className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
            >
              <AdjustmentsHorizontalIcon className="w-4" />
              Filters
            </div>

            <div
              className="flex flex-row gap-1 cursor-pointer hover:text-grass items-center"
              onClick={toggleTotal}
            >
              <CalculatorIcon className="w-4" />
              Total
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-2 flex-1 h-8">
            {loading && <Spinner light color="purple" />}
            <div className="text-sm">
              {data?.productions.length}
              <span className="dark:text-grass text-plum"> of </span>
              {totalLength < (data?.productions.length || 0)
                ? data?.productions.length
                : totalLength}
            </div>
            {data && data?.productions.length < totalLength && (
              <Button size="xs" onClick={loadMore} color="purple" outline>
                more
              </Button>
            )}
          </div>
        </div>
        {showTotal && (
          <div className="p-2 flex flex-row gap-2">
            {total.map(item => (
              <div key={item.product.name} className="flex flex-row gap-2">
                {item.product.image && (
                  <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                    <img src={item.product.image} className="w-3" />
                  </div>
                )}
                {item.product.name} {item.qty}
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Filter */}
      <Filters filtering={filtering} />
      {/* Content Part */}
      {view == "board" && <ProductionBoard production={productions || []} />}
      {view == "list" && <ProductionLists production={productions || []} />}
      {/* {view == "table" && <ProcessTable production={productions || []} />} */}
    </main>
  );
}
