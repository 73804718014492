import { Fragment, useEffect, useState } from "react";
import { option } from "../../types";
import { Button, Card, Checkbox } from "flowbite-react";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_SETS } from "../../../gqls";
import { sort } from "fast-sort";
import { optionProps } from "../../../types";
import { fullNumber } from "../../../../../../utils/fullNumber";
import { useOrderStore } from "../../../../../../store/orderStore";
import {
  productTab,
  column,
  optionColumn,
  workorderCalc,
  filteredLocation,
} from "./types";
import ItemBasedWorkOrder from "./itemBasedWorkOrder";
import ComponentsOrder from "./componentsOrder";
import CurtainLabels from "./curtainLabels";
import useGetMaterialsWithInventory from "../useGetMaterialsWithInventory";

const ProductTab = ({
  productName,
  locations,
  customer,
  dbOrder,
  orderPreset,
}: productTab) => {
  const { data } = useQuery(GET_PRODUCT_SETS);
  const ProductSets = data?.productSets;

  const product = ProductSets?.find(p => p.name == productName);
  const isOpeningProduct = product?.hideItems;
  const orderPresets = product?.orderPresets;

  const [selectedOrderPreset, setSelectedOrderPreset] = useState(orderPreset);

  const { units } = useOrderStore();

  const fullNum = dbOrder?.process
    ? fullNumber(
        dbOrder?.process.processType.prefix,
        dbOrder?.process.year,
        dbOrder?.process.number,
        dbOrder?.process.salesRepUser
      )
    : "";

  const filteredLocations: filteredLocation[] | undefined = locations?.map(
    l => ({
      ...l,
      openings: l.openings?.filter(o => o.product == product?.id),
    })
  );

  const items = filteredLocations
    ?.map(l => l.openings)
    .flat()
    .map(o => o.items)
    .flat();

  const calcs: workorderCalc[] =
    items
      ?.map(i => i.calcs)
      .flat()
      .map(c => ({
        // optionId: c.optionId,
        name: c.name,
        unit: c.unit,
        size: c.size,
        displayName: c.displayName,
        priority: c.priority,
        show: c.show,
      }))
      ?.filter(
        calc =>
          (!selectedOrderPreset ||
            selectedOrderPreset.calcs.includes(calc.name)) &&
          calc.show
      )
      .reduce<
        {
          // optionId: number;
          displayName?: string;
          name: string;
          unit: string | undefined;
          priority?: number;
        }[]
      >((prev, cur) => {
        if (
          prev.find(
            calc => calc.name == cur.name
            // calc => calc.name == cur.name && calc.optionId == cur.optionId
          )
        ) {
          return prev;
        } else {
          return prev.concat(cur);
        }
      }, []) || [];

  const allItemOptions =
    items
      ?.map(i => i.options)
      .flat()
      ?.filter(o => !o.noCalc) || [];

  const uniqueItemOptions = allItemOptions?.reduce<optionColumn[]>(
    (prev, cur) => {
      if (prev.find(p => p.id == cur.id)) {
        return prev;
      } else {
        return prev.concat({
          ...cur,
          values: undefined,
          value: undefined,
        });
      }
    },
    []
  );

  const presetOptions = uniqueItemOptions?.filter(
    o => o.source == "deductionPreset"
  );
  const presetColumnName =
    presetOptions.length < 2
      ? presetOptions[0]?.name || "Preset"
      : presetOptions[0].group || "Preset";

  const { fixedOptions, variedOptions, groupOptions } =
    uniqueItemOptions.reduce<{
      fixedOptions: optionColumn[];
      variedOptions: optionColumn[];
      groupOptions: optionColumn[];
    }>(
      (prev, cur) => {
        if (cur.name.toLowerCase() == "note") {
          return prev;
        }
        if (cur.source == "deductionPreset") {
          return prev;
        }
        if (
          selectedOrderPreset &&
          !selectedOrderPreset.options.includes(cur.id)
        ) {
          return prev;
        }

        if (cur.group && !prev.groupOptions.find(go => go.group == cur.group)) {
          return {
            ...prev,
            groupOptions: sort(prev.groupOptions.concat(cur)).asc("position"),
          };
        }

        if (cur.group) {
          return prev;
        }

        const valueLength = [
          ...new Set(
            allItemOptions?.filter(o => o.id == cur.id).map(o => o.value)
          ),
        ].length;

        if (valueLength > 1) {
          return {
            ...prev,
            variedOptions: sort(prev.variedOptions.concat(cur)).asc("position"),
          };
        } else {
          return {
            ...prev,
            fixedOptions: sort(
              prev.fixedOptions.concat({
                ...cur,
                value: allItemOptions
                  ?.filter(o => o.id == cur.id)
                  .map(o => o.value)[0],
                displayValue: allItemOptions
                  ?.filter(o => o.id == cur.id)
                  .map(o => o.displayValue)[0],
              })
            ).asc("position"),
          };
        }
      },
      {
        fixedOptions: [],
        variedOptions: [],
        groupOptions: [],
      }
    );

  const allOptionColumns = sort([
    ...fixedOptions,
    ...variedOptions,
    ...groupOptions,
  ]).asc("position");

  const [columns, setColumns] = useState<column[]>([
    { name: "OW" },
    { name: "OH" },
    { name: "note" },
  ]);

  useEffect(() => {
    const newColumns = [{ name: "OW" }, { name: "OH" }, { name: "note" }];
    if (variedOptions) {
      newColumns.push(
        ...variedOptions.map(v => ({
          name: v.displayName || v.name,
        }))
      );
    }
    if (fixedOptions) {
      newColumns.push(
        ...fixedOptions
          .filter(fo => !fo.optional)
          .map(v => ({
            name: v.displayName || v.name,
          }))
      );
    }

    if (groupOptions) {
      newColumns.push(
        ...groupOptions.map(v => ({
          name: v.group || "",
          isGroup: true,
        }))
      );
    }
    if (calcs) {
      newColumns.push(
        ...calcs.map(c => ({
          name: c.displayName || c.name,
        }))
      );
    }
    setColumns(newColumns);
  }, [JSON.stringify(allOptionColumns), JSON.stringify(calcs)]);

  const toggleColumn = (column: column) => {
    if (columns.find(c => c.name == column.name)) {
      setColumns(columns?.filter(c => !(c.name == column.name)));
    } else {
      setColumns(columns.concat(column));
    }
  };

  const getMaterialsWithInventory = useGetMaterialsWithInventory();

  const materialsWithInventory = getMaterialsWithInventory(
    Number(product?.product.id),
    !!isOpeningProduct,
    items
  );

  const getPageMargins = () => {
    return `@page { 
      margin: 10mm 10mm 10mm 10mm !important;
      size: 297mm 210mm; 
    }`;
  };

  const includeComps = !selectedOrderPreset || selectedOrderPreset.includeComps;

  return (
    <div className="flex flex-col gap-4 pb-20">
      {/* Options, Summary */}
      <div className="mx-auto max-w-full w-[1127px] flex flex-row gap-2 items-start flex-wrap">
        <div className="flex flex-col gap-2 min-w-[300px] flex-1">
          {/* Order Presets */}
          {!orderPreset && orderPresets && (
            <Card className="self-stretch">
              <div className="mb-4 flex items-center justify-between">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Order Presets
                </h5>
              </div>
              <div className="flex flex-col gap-2">
                <Button
                  color="purple"
                  size="sm"
                  onClick={() => setSelectedOrderPreset(undefined)}
                  className={`${!selectedOrderPreset ? "" : "opacity-50"}`}
                >
                  All
                </Button>
                {orderPresets.map(o => (
                  <Button
                    key={o.name}
                    color="purple"
                    size="sm"
                    onClick={() => setSelectedOrderPreset(o)}
                    className={`${
                      selectedOrderPreset?.name == o.name ? "" : "opacity-50"
                    }`}
                  >
                    {o.name}
                  </Button>
                ))}
              </div>
            </Card>
          )}
          {/* Comps summary */}
          {includeComps && (
            <Card className="self-stretch">
              <div className="mb-4 flex items-center justify-between">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Total Components
                </h5>
              </div>
              <div className="flex flex-col gap-1">
                {materialsWithInventory &&
                  sort(materialsWithInventory)
                    .by([
                      { asc: mat => mat.inventory?.brand },
                      { asc: mat => mat.inventory?.name },
                      { asc: mat => mat.inventory?.color },
                      { asc: mat => mat.inventory?.size },
                    ])
                    .map(mat => {
                      return (
                        <Fragment key={mat.id}>
                          <div className="flex flex-row justify-between">
                            <h3>
                              {mat.inventory.brand} {mat.inventory.name}{" "}
                              {mat.inventory.color}
                              {mat.inventory.size
                                ? ` ${mat.inventory.size}`
                                : ""}
                            </h3>
                            <h3>
                              {mat.qty.toFixed(2).replace(".00", "")}{" "}
                              {units.find(u => u.id == mat.unit)?.name ||
                                mat.unit}
                            </h3>
                          </div>
                          <hr />
                        </Fragment>
                      );
                    })}
              </div>
            </Card>
          )}
        </div>
        {/* Options and Calcs summary, select */}
        <Card className="min-w-[300px] max-w-[600px] flex-1">
          <div className="mb-4 flex items-center justify-between">
            <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
              All Options & Calcs
            </h5>
          </div>
          <h3 className="text-center font-semibold">Opening</h3>
          <div className="flex flex-row flex-wrap gap-2">
            <div
              className="flex flex-row gap-2 items-center cursor-pointer"
              onClick={() => {
                toggleColumn({
                  name: "OW",
                });
              }}
            >
              <Checkbox
                checked={columns.find(c => c.name == "OW") ? true : false}
                readOnly
                className="checked:bg-plum dark:checked:bg-plum bg-gray-50 mb-[2px]"
              />
              <h3>Width</h3>
            </div>
            <div
              className="flex flex-row gap-2 items-center cursor-pointer"
              onClick={() => {
                toggleColumn({
                  name: "OH",
                });
              }}
            >
              <Checkbox
                checked={columns.find(c => c.name == "OH") ? true : false}
                readOnly
                className="checked:bg-plum dark:checked:bg-plum bg-gray-50 mb-[2px]"
              />
              <h3>Height</h3>
            </div>
            <div
              className="flex flex-row gap-2 items-center cursor-pointer"
              onClick={() => {
                toggleColumn({
                  name: "note",
                });
              }}
            >
              <Checkbox
                checked={columns.find(c => c.name == "note") ? true : false}
                readOnly
                className="checked:bg-plum dark:checked:bg-plum bg-gray-50 mb-[2px]"
              />
              <h3>Note</h3>
            </div>
          </div>

          <h3 className="text-center font-semibold">Fixed</h3>
          <div className="flex flex-col gap-1">
            {fixedOptions?.map((option, i) => (
              <FixedOption
                key={option.id}
                option={option}
                columns={columns}
                toggleColumn={toggleColumn}
              />
            ))}
          </div>
          <h3 className="text-center font-semibold">Varied</h3>
          <div className="flex flex-row flex-wrap gap-2">
            {variedOptions?.map(v => (
              <div
                key={v.id}
                className="flex flex-row gap-2 items-center cursor-pointer"
                onClick={() => {
                  toggleColumn({
                    name: v.displayName || v.name,
                    // optionId: v.id,
                  });
                }}
              >
                <Checkbox
                  checked={
                    columns.find(
                      c =>
                        // c.optionId == v.id &&
                        c.name == (v.displayName || v.name)
                    )
                      ? true
                      : false
                  }
                  readOnly
                  className="checked:bg-plum dark:checked:bg-plum bg-gray-50 mb-[2px]"
                />
                <h3>{v.displayName || v.name}</h3>
              </div>
            ))}
          </div>
          <h3 className="text-center font-semibold">Grouped</h3>
          <div className="flex flex-row flex-wrap gap-2">
            {groupOptions?.map(v => (
              <div
                key={v.id}
                className="flex flex-row gap-2 items-center cursor-pointer"
                onClick={() => {
                  toggleColumn({
                    name: v.group || "",
                    isGroup: true,
                  });
                }}
              >
                <Checkbox
                  checked={columns.find(c => c.name == v.group) ? true : false}
                  readOnly
                  className="checked:bg-plum dark:checked:bg-plum bg-gray-50 mb-[2px]"
                />
                <h3>{v.group}</h3>
              </div>
            ))}
          </div>
          <hr />
          <h3 className="text-center font-semibold">Calcs</h3>
          <div className="flex flex-row flex-wrap gap-2">
            {calcs?.map(c => {
              return (
                <div
                  key={c.name}
                  className="flex flex-row gap-2 items-center cursor-pointer"
                  onClick={() => {
                    toggleColumn({
                      name: c.displayName || c.name,
                    });
                  }}
                >
                  <Checkbox
                    checked={
                      columns.find(
                        col => col.name == (c.displayName || c.name)
                        // && col.optionId == c.optionId
                      )
                        ? true
                        : false
                    }
                    readOnly
                    className="checked:bg-plum dark:checked:bg-plum bg-gray-50 mb-[2px]"
                  />
                  <h3>{c.name}</h3>
                </div>
              );
            })}
          </div>
          <hr />
        </Card>
      </div>
      {/* WorkOrder Comps, Label (Curtain Only) */}
      <div className="mx-auto p-10 flex flex-col gap-8 bg-white drop-shadow-lg rounded-lg dark:text-black">
        <ItemBasedWorkOrder
          fullNum={fullNum}
          customer={customer}
          pageMarginStyle={getPageMargins()}
          selectedOrderPreset={selectedOrderPreset}
          dbOrder={dbOrder}
          fixedOptions={fixedOptions}
          columns={columns}
          presetColumnName={presetColumnName}
          allOptionColumns={allOptionColumns}
          calcs={calcs}
          filteredLocations={filteredLocations}
          locations={locations}
        />
        <hr />
        {/* Components Order */}
        {includeComps && (
          <ComponentsOrder
            columns={columns}
            fullNum={fullNum}
            materialsWithInventory={materialsWithInventory}
            pageMarginStyle={getPageMargins()}
            customer={customer}
            dbOrder={dbOrder}
            filteredLocations={filteredLocations}
            locations={locations}
            productId={product?.product.id}
          />
        )}
        {/* Curtain Labels */}
        {product?.name == "Curtain" && (
          <CurtainLabels
            fullNum={fullNum}
            customer={customer}
            pageMarginStyle={getPageMargins()}
            dbOrder={dbOrder}
            filteredLocations={filteredLocations}
          />
        )}
      </div>
    </div>
  );
};

interface fixedOption {
  option: optionProps | option;
  columns: column[];
  toggleColumn: (column: column) => void;
}

const FixedOption = ({ option, columns, toggleColumn }: fixedOption) => {
  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <div
          className="flex flex-row gap-2 cursor-pointer items-center"
          onClick={() => {
            toggleColumn({
              name: option.displayName || option.name,
              // optionId: option.id,
            });
          }}
        >
          <Checkbox
            checked={
              columns.find(
                c =>
                  // c.optionId == option.id &&
                  c.name == (option.displayName || option.name)
              )
                ? true
                : false
            }
            readOnly
            className="checked:bg-plum dark:checked:bg-plum bg-gray-50 mb-[2px]"
          />
          <h3>{option.displayName || option.name}</h3>
        </div>
        <h3>
          {
            // @ts-ignore
            option.displayValue || option.value
          }
        </h3>
      </div>
      <hr />
    </>
  );
};
export default ProductTab;
