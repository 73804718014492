import { Fragment, useEffect, useRef, useState } from "react";
import { inventory, location, option, order } from "../../types";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_SETS } from "../../../gqls";
import { GET_MATERIALS_CONDITIONAL } from "../../../../materials/gqls";
import { sort } from "fast-sort";
import { useReactToPrint } from "react-to-print";
import { PrinterIcon } from "@heroicons/react/24/solid";
import OpeningDetail from "./OpeningDetail";
import isNullish from "../../../../../../utils/isNullish";
import { OrderType } from "../../../types";
import { fullNumber } from "../../../../../../utils/fullNumber";
import dayjs from "dayjs";

const nullishValues = [undefined, null, "", "0", 0];

interface productTab {
  productName: string;
  locations?: location[];
  customer: string;
  dbOrder?: OrderType;
}

const ProductTabOpening = ({
  productName,
  locations,
  customer,
  dbOrder,
}: productTab) => {
  const { data } = useQuery(GET_PRODUCT_SETS);
  const ProductSets = data?.productSets;

  const product = ProductSets?.find(p => p.name == productName);

  const fullNum = dbOrder?.process
    ? fullNumber(
        dbOrder?.process.processType.prefix,
        dbOrder?.process.year,
        dbOrder?.process.number,
        dbOrder?.process.salesRepUser
      )
    : "";

  const filteredLocations = locations?.map(l => ({
    ...l,
    openings: l.openings.filter(o => o.product == product?.id),
  }));

  const openings = filteredLocations?.map(l => l.openings).flat();

  const calcs = openings?.map(i => i.calcs).flat();

  const allOpeningOptions: option[] =
    openings
      ?.map(i => i.options || [])
      ?.flat()
      .filter(o => {
        if (o.noCalc || !o.value) {
          return false;
        }

        if (
          o.type == "division" &&
          (!Array.isArray(o.value) || o.value.filter(v => v.checked).length < 1)
        ) {
          return false;
        }
        if (
          o.type == "midrail" &&
          (!Array.isArray(o.value) ||
            // @ts-ignore
            o.value.filter(v => !nullishValues.includes(v.value)).length < 1)
        ) {
          return false;
        }
        if (
          o.type == "multiple" &&
          (!Array.isArray(o.value) ||
            // @ts-ignore
            o.value.filter(v => !nullishValues.includes(v)).length < 1)
        ) {
          return false;
        }

        if (
          o.type == "pelmetover" ||
          o.type == "trackover" ||
          o.type == "param"
        ) {
          return false;
        }

        return true;
      }) || [];

  const openingOptions = allOpeningOptions
    ? sort(allOpeningOptions)
        .asc("position")
        .reduce<option[]>((acc, cur) => {
          if (acc.find(a => a.id == cur.id)) {
            return acc;
          } else {
            return acc.concat(cur);
          }
        }, [])
    : [];

  // const productOptions = product?.productSetOptions;

  const [allMaterials, setAllMaterials] = useState<inventory[]>();

  const materialIds = allMaterials?.map(m => m.id);

  const { data: inventory_data } = useQuery(GET_MATERIALS_CONDITIONAL, {
    variables: {
      where: { id: { _in: materialIds } },
    },
  });
  const inventory = inventory_data?.materials;

  const materialsWithInventory = allMaterials
    ?.map(m => ({
      ...m,
      inventory: inventory?.find(i => i.id == m.id),
    }))
    .filter(mat => mat.qty > 0);

  const [columns, setColumns] = useState<string[]>([]);

  const hiddenCols = [
    "TopTrack",
    "BottomTrack",
    "RailOverride",
    "Sill",
    "TrackQty",
    "Fixing",
    "PelmetDepth",
    "FSides",
    "Hinge Type",
    "Hinge Colour",
  ];

  useEffect(() => {
    const newColumns = [];
    if (openingOptions) {
      newColumns.push(
        ...openingOptions
          .filter(
            v =>
              !isNullish(
                v.type == "number" ? Number(v.value) : v.value,
                v.type == "number"
              )
          )
          .map(v => v.name)
      );
    }
    setColumns(newColumns.filter(c => !hiddenCols.includes(c)));
  }, [JSON.stringify(openingOptions), JSON.stringify(calcs)]);

  // const toggleColumn = (name: string) => {
  //   if (columns.includes(name)) {
  //     setColumns(columns.filter(n => n !== name));
  //   } else {
  //     setColumns(columns.concat(name));
  //   }
  // };

  const workOrderRef = useRef(null);
  const printWorkOrder = useReactToPrint({
    content: () => workOrderRef.current,
    documentTitle: customer || fullNum || "no-name",
  });

  // const compOrderRef = useRef(null);
  // const printCompOrder = useReactToPrint({
  //   content: () => compOrderRef.current,
  //   documentTitle: customer || fullNum || "no-name",
  // });

  const getPageMargins = () => {
    return `
    @media all {
      .page-break {
        display: none;
      }
    }
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        display: block;
        page-break-before: always;
      }
      .no-print, .no-print * {
        display:none !important
      }
    }
    
    @page {
      size: 297mm 210mm;
    }
    `;
  };

  return (
    <div className="flex flex-col gap-4 dark:text-black">
      {/* Options, Summary */}
      <div className="flex flex-row gap-2 items-start">
        {/* Comps summary */}
        {/* <Card className="min-w-[300px] flex-1">
          <div className="mb-4 flex items-center justify-between">
            <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
              Total Components
            </h5>
          </div>
          <div className="flex flex-col gap-1">
            {materialsWithInventory?.map((mat, i) => (
              <Fragment key={mat.id}>
                <div className="flex flex-row justify-between">
                  <h3>
                    {mat.inventory?.brand} {mat.inventory?.name}{" "}
                    {mat.inventory?.color}
                  </h3>
                  <h3>
                    {mat.qty.toFixed(2).replace(".00", "")} {mat.unit}
                  </h3>
                </div>
                <hr />
              </Fragment>
            ))}
          </div>
        </Card> */}
      </div>
      {/* WorkOrder */}
      <div className="mx-auto bg-white rounded-lg p-10 drop-shadow-lg">
        <div className="w-[277mm]">
          <div className="flex flex-row justify-end items-center">
            <div
              className="flex flex-row gap-2 items-center cursor-pointer hover:text-plum"
              onClick={printWorkOrder}
            >
              Print
              <PrinterIcon className="w-4" />
            </div>
          </div>
          <div ref={workOrderRef} className="bg-white print:px-6">
            <style>{getPageMargins()}</style>
            <h1 className="my-4 dark:text-black text-center">Work Order</h1>
            <div className="flex flex-row justify-between">
              <h3 className="dark:text-black">
                Due:{" "}
                {dbOrder?.due ? dayjs(dbOrder.due).format("DD/MM/YY") : "None"}
              </h3>
              <table className="border-[1px] border-gray-400">
                <tbody>
                  <tr>
                    <th className="border-[1px] border-gray-400 px-1">
                      Job ID
                    </th>
                    <th className="border-[1px] border-gray-400 px-1">
                      {fullNum}
                    </th>
                    <th className="border-[1px] border-gray-400 px-1">
                      Customer Ref
                    </th>
                    <th className="border-[1px] border-gray-400 px-1">
                      {dbOrder?.orderRef}
                    </th>
                    <th className="border-[1px] border-gray-400 px-1">
                      Dealer ID
                    </th>
                    <th className="border-[1px] border-gray-400 px-1">
                      {dbOrder?.createdBy}
                    </th>
                    <th className="border-[1px] border-gray-400 px-1">
                      Dealer Name
                    </th>
                    <th className="border-[1px] border-gray-400 px-1">
                      {dbOrder?.user.firstName} {dbOrder?.user.sirName}
                    </th>
                  </tr>
                  <tr>
                    <th className="border-[1px] border-gray-400 px-1">
                      Client Name
                    </th>
                    <th
                      colSpan={3}
                      className="border-[1px] border-gray-400 px-1"
                    >
                      {dbOrder?.process?.name}
                    </th>
                    <th className="border-[1px] border-gray-400 px-1">
                      Address
                    </th>
                    <th
                      colSpan={3}
                      className="border-[1px] border-gray-400 px-1"
                    >
                      {dbOrder?.process?.address}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <h3 className="mt-2 dark:text-black">All Items</h3>
            <table className="border-t-[1px] border-l-[1px] border-black w-full">
              <thead className="select-text border-b-[1px]">
                <tr>
                  <th className="px-1 border-b-[1px] border-r-[1px] border-black">
                    #
                  </th>
                  <th className="px-1 border-b-[1px] border-r-[1px] border-black">
                    Loc
                  </th>
                  {openingOptions
                    .filter(o => columns.includes(o.name) && o.name !== "Note")
                    .map((o, i) => (
                      <th
                        className="px-1 border-b-[1px] border-r-[1px] border-black text-xs"
                        key={o.id}
                      >
                        {o.name}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="divide-y">
                {filteredLocations?.map((location, i) => {
                  const locationName =
                    location.name ||
                    `${location.index !== undefined && location.index}`;
                  return (
                    <Fragment key={i}>
                      {location.openings.map((opening, i) => {
                        const openingName =
                          opening.name ||
                          (opening.index !== undefined && opening.index);
                        return (
                          <tr
                            key={`${opening.index} ${i}`}
                            className="text-black"
                          >
                            <td className="px-1 border-b-[1px] border-r-[1px] border-black text-xs">{`${
                              filteredLocations?.length > 1
                                ? `${location.index}.`
                                : ""
                            }${opening.index}`}</td>
                            <td className="px-1 border-b-[1px] border-r-[1px] border-black text-xs">{`${
                              filteredLocations?.length > 1
                                ? `${locationName}.`
                                : ""
                            }${openingName}`}</td>
                            {sort(opening.options as option[])
                              .asc("position")
                              .filter(
                                i =>
                                  columns.includes(i.name) && i.name !== "Note"
                              )
                              .map(option => (
                                <td
                                  key={option.id}
                                  className="px-1 border-b-[1px] border-r-[1px] border-black text-xs"
                                >
                                  {!option.noCalc &&
                                    (option.displayValue ||
                                      (typeof option.value == "object"
                                        ? ""
                                        : option.value))}
                                </td>
                              ))}
                          </tr>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>

            <div className="page-break" />
            <div className="mt-4 flex flex-col gap-2">
              {filteredLocations?.map((location, li) => {
                const locationName =
                  location.name ||
                  `${location.index !== undefined && location.index}`;
                return (
                  <Fragment key={li}>
                    {location.openings.map((opening, i) => {
                      const openingName =
                        opening.name ||
                        (opening.index !== undefined && opening.index);
                      return (
                        <Fragment key={`${locationName} ${openingName} ${i}`}>
                          <OpeningDetail
                            locationIndex={li + 1}
                            multipleLoc={filteredLocations.length > 1}
                            opening={opening}
                            name={`${
                              filteredLocations?.length > 1
                                ? `${locationName}.`
                                : ""
                            }${openingName}`}
                            index={opening.index}
                            jobNum={fullNum}
                          />
                          {li == filteredLocations.length - 1 &&
                          i == location.openings.length - 1 ? null : (
                            <div className="page-break" />
                          )}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTabOpening;
