import { gql } from "@apollo/client";

export const PRODUCTION_STATUS_CORE_FIELDS = gql`
  fragment ProductionStatusCoreFields on productionStatus {
    id
    name
    description
    color
    priority
    product {
      id
      name
      image
    }
  }
`;
